import React from "react";




import { DatePicker,Input,Select,Button, Table, Modal, Form, Image, message} from "antd";


import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined} from '@ant-design/icons';

import { Uploadhh } from "@/components/upload/upload";

import Record from "./record/record";


import './deviceInspectionDeviceList.less';

import {main3, main4} from './echarts';


const {Option} =  Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};



export default class Home extends React.Component {

    imageUrl =  process.env.REACT_APP_IMG_URL

    FormSbmitRef = '';
    refRecord = '';
    state = {
        // 表格分页属性
        pagination: {
        current: 1,
        pageSize: 10,
        total: 200,
        onShowSizeChange: function(current, pageSize) {
            this.state.pagination.pageSize = pageSize;
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        onChange: function(current) {
            this.state.pagination.current = current
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        showTotal: function () {  //设置显示一共几条数据
            return '共 ' + this.state.pagination.total + ' 条'; 
        }.bind(this),
        showQuickJumper: true,
        },


    loading: false,

    isModalVisible: false,

    tableData: [
        
    ],


    deviceInspectionTypeList: [],
    enterpriseAllList: [],
    teamAllList: [],
    userAllList: [],
    doormanAllList: [],
    openStatus: false,
    queryParams: {
        lengthLimit: 10,
        logic: 2,
        offsetLimit: 0,
        installPosition: null, // 设备安装位置
        typeId: null, // 类型
        areaId: null, // 工区
        keyword: null, // 光健字
        state: null, // 状态
    },
    detatiType: false,

    toSaveData: {
        name: '',
        sn: '',
        typeId: '',
        enterpriseId: '',
        teamId: '',
        userId: '',
        areaId: '',
        content: [{state:2, content: ''}],
        img: [],
        installPosition: '' // 详细位置
    },

    count: {
        deviceAbnormal: 0,
        deviceNormal: 0
    },

  }


  columns = [
    {
        title: "序号",
        dataIndex: 'title',
        align: 'center',
        render: (_, item, index) => {
          return (
            <p>{index + 1}</p>
          )
        }
      },
      {
        title: '设备名称',
        dataIndex: 'name',
        align: 'center'
      },
      {
        title: '当前状态',
        dataIndex: 'state',
        align: 'center',
        render: (_) => {
            return (
                <p className={'statusTable' + _}>{_==2? '正常':'异常'}</p>
            )
        }
      },
      {
        title: '设备编号',
        dataIndex: 'sn',
        align: 'center'
      }, 
      {
        title: '设备类型',
        dataIndex: 'typeId',
        align: 'center',
        render: (_) => {
            let name = ''
            let item = this.state.deviceInspectionTypeList.filter(item => item.id == _)
            if(item[0]) {
                name = item[0].name
            } else {

            }

            return (
                <p>{name}</p>
            )
        }
      },
      {
        title: '所在工区',
        dataIndex: 'nameArea',
        align: 'center'
      },
      {
        title: '详细位置',
        dataIndex: 'installPosition',
        align: 'center'
      },
      {
          title: '管理责任人',
          dataIndex: 'nameCard',
          align: 'center'
      },
      {
        title: '操作',
        dataIndex: 'address',
        align: 'center',
        render:(_,item) => {
          return (
            <div className="operation">
                <p onClick={this.listDeatail.bind(this, item)}>编辑</p>
                <p className="jilu" onClick={this.alertRecord.bind(this, item)}>巡检记录</p> 
                <p className="red" onClick={this.removeListDevice.bind(this, item)}>删除</p> 
            </div>
          )
        }
      },
    ]

    componentDidMount() {
        this.getDeviceInspectionDeviceList()

        // 获取设备类型
        this.getDeviceInspectionTypeList()

        // 获取公司
        this.getEnterpriseAll()

        // 工区
        this.getDoormanAll()

        this.getDeviceInspectionLogStatistics()
    }

    // 获取装备巡检记录统计
    getDeviceInspectionLogStatistics() {
        const projectid = React.$getStorage('projectInfo').id
        React.$axios.post('deviceInspectionLogStatistics', {projectId: projectid}).then(res => {

            this.setState({
                count:res.data.count
            })

            main3(res.data.logState)
            main4(res.data.userLog)

        })   
    }
    

    //this.refRecord
    alertRecord(item) {
        this.refRecord.toSerch(item)
    }

    
    // 编辑详情
    listDeatail(item) {

        let params = {
            name: item.name,
            sn: item.sn,
            typeId: item.typeId,
            userId: item.userId,
            areaId: item.areaId,
            content: item.contentMany,
            enterpriseId: item.enterpriseIdInfo,
            teamId: item.teamIdInfo,
            img: item.img,
            id: item.id,
            installPosition: item.installPosition
        }

        let enterpriseItem =  this.state.enterpriseAllList.filter(item2=> item2.id == item.enterpriseIdInfo)
        if(enterpriseItem[0]) {
            if(enterpriseItem[0].teamId) {
                let teamId =  enterpriseItem[0].teamId.split(',')
                this.getTeamAll(teamId)
                this.getUserAll(item.teamIdInfo)
            }
        }

        this.setState({
            toSaveData: params,
            isModalVisible: true,
        })


    }

    

    // 获取设备列表
    getDeviceInspectionDeviceList(type = false) {
        this.setState({
            loading: true
          })
         
          if(type) {
            this.state.pagination.current = 1
            this.state.queryParams.offsetLimit = 0
          }

        React.$axios.post('deviceInspectionDeviceList', this.state.queryParams).then(res => {
            
            res.data.list.map(item => {
                if(item.img) {
                    item.img = item.img.split(',')
                } else {
                    item.img = []
                }
            })
            this.state.pagination.total = res.data.countList
            
            this.setState({
                tableData: res.data.list,
                pagination: this.state.pagination,
                loading: false
            })
        })
    }
    
    // 获取设备类型
    getDeviceInspectionTypeList() {
        React.$axios.post('deviceInspectionTypeList', {logic: 3}).then(res => {
            this.setState({
                deviceInspectionTypeList: res.data.list
            })
        })
    }

    // 获取公司
    getEnterpriseAll() {
        React.$axios.post('enterpriseAll').then(res => {
            this.setState({
                enterpriseAllList: res.data.enterpriseAll
            })

        })
    }
    // 获取部门
    getTeamAll(id) {
        React.$axios.post('teamAll', {id: id}).then(res => {
            this.setState({
                teamAllList: res.data.teamAll
            })
        })
    }

    // 获取负责人
    getUserAll(id) {
        React.$axios.post('userAll', {uiTeamId: id}).then(res => {
            this.setState({
                userAllList: res.data.userAll
            })
        })
    }

    //获取工区
    getDoormanAll() {
        React.$axios.post('doormanAll').then(res => {
            this.setState({
                doormanAllList: res.data.doormanAll
            })
        })
    }

    // 新建列表
    toCreateList() {
        for(let i in this.state.toSaveData) {
            this.state.toSaveData[i] = null
        }
        this.state.toSaveData.img = []
        this.state.toSaveData.content = [{state:2, content: ''}]
        if(this.state.toSaveData.id) {
           delete this.state.toSaveData.id
        }

        console.log(this.state.toSaveData)
        this.setState({
            isModalVisible: true,
            toSaveData: this.state.toSaveData
        })

        // console.log(this.state.toSaveData)
    }



    nChangeT() {

    }

    setDrapDom() {
      if(this.state.openStatus == true) {
          return (
            <UpOutlined/>
          )
        } else {
          return (
            <DownOutlined />
          )
        }
    }

    drapHeight() {
      this.setState({
        openStatus: !this.state.openStatus
      })
    }



      onChangeT(name, value) {
        
        this.state.queryParams[name] = value
      
        this.setState({
            queryParams:  this.state.queryParams
        })
     }

      handleTableChange(pagination) {

        this.state.queryParams.offsetLimit = (pagination.current -1) * pagination.pageSize
        this.state.queryParams.lengthLimit = pagination.pageSize
        this.setState({
          queryParams: this.state.queryParams,
          pagination: pagination
        })

        this.getDeviceInspectionDeviceList()
      }

      handleOk() {
        
        this.FormSbmitRef.submit()
      }

      handleCancel() {
        this.setState({
            isModalVisible:false
        })
      } 

      onFinish(value) {
        let params = {
            name: '',
            content: '',
            img: '',
            installPosition: '',
            areaId: '',
            userId: '',
            sn: '',
            typeId: ''
        }

        for(let i in params) {
            params[i] = this.state.toSaveData[i]
        }
        if( this.state.toSaveData.id) {
            params.id = this.state.toSaveData.id
        }

        React.$axios.post('saveDeviceInspectionDevice', params).then(res => {

            if(res.code == 20000) {
                message.success('保存成功');
                this.setState({
                    isModalVisible: false
                })
                this.getDeviceInspectionDeviceList()
            }
        })

      }


        // 删除
    removeListDevice(item) {
        let params = {
            name: '',
            img: '',
            installPosition: '',
            areaId: '',
            userId: '',
            sn: '',
            typeId: '',
            content: []
        }

        for(let i in params) {
            params[i] = item[i]
        }
        
        params.id = item.id
        params.del = 2
        params.content = item.contentMany
        React.$axios.post('saveDeviceInspectionDevice', params).then(res => {
            if(res.code == 20000) {
                message.success('删除成功');
                this.getDeviceInspectionDeviceList()
            }
        })
    }
 

      onFinishFailed(item) {
        console.log(item)
      }

      handleFinger(data) {
        
        this.state.toSaveData.img.push(data.data.key[0])
        
        
        this.setState({
            toSaveData: this.state.toSaveData
        })
      }

      // 选择公司
      onChangeEnterpriseAll(id, item) {
        this.state.toSaveData.enterpriseId = id

        let id2 = item.item.teamId.split(',')
        this.getTeamAll(id2)
      }

      // 选择部门请求负责人
      onChangeTeamAllList(id) {
        this.state.toSaveData.teamId = id
        this.getUserAll(id)
      }

      // 输入数据
      setInput(index,{target: {value}}) {
    
        this.state.toSaveData.content[index].content = value
        this.setState({
            toSaveData: this.state.toSaveData
        })

      }

      // 添加
      addContent() {
        this.state.toSaveData.content.push({state:2, content: ''})
        this.setState({
            toSaveData: this.state.toSaveData
        })
      }

      // 删除content
      delContent(index) {
          this.state.toSaveData.content.splice(index, 1);
          this.setState({
            toSaveData: this.state.toSaveData
          }) 
      }

      // 改变Content状态
      changeStatus(index, item) {
        if(item.state == 2) {
            item.state = 3
        } else {
            item.state = 2
        }

        this.state.toSaveData.content[index] = item;
        
        this.setState({
            toSaveData: this.state.toSaveData
        })
      }


      // 删除照片
      reMoveImgList(index) {
        this.state.toSaveData.img.splice(index, 1);

        this.setState({
            toSaveData: this.state.toSaveData
        })
      }


      // 获取详情输入框数据
      inputChange(name, {target: {value}}) {
       this.state.toSaveData[name] = value
       this.setState({
        toSaveData: this.state.toSaveData
       })
      }


      // 获取选择后的数据
      selectChange(name, value) {
        this.state.toSaveData[name] = value
        this.setState({
            toSaveData: this.state.toSaveData
        })
      }

      // 查询条件
      serchParamsFun(name, {target: {value}}) {
        this.state.queryParams[name] = value

        this.setState({
            queryParams: this.state.queryParams
        })
      }

      // 点击搜索
      toSerch() {
          this.getDeviceInspectionDeviceList(true)
      }

    
  render() {
    return (
      <div className="deviceInspection">

            <div className="head">
                            <ul>
                                <li>
                                    <h1>设备运行状态</h1>
                                    <div className="number">
                                        <div>
                                            <h2>{this.state.count.deviceNormal}</h2>
                                            <p>正常</p> 
                                        </div>

                                        <div>
                                            <h2 className="blue">{this.state.count.deviceAbnormal}</h2>
                                            <p>异常</p> 
                                        </div>
                                            
                                    </div>

                                </li>
                                <li>
                                    <h1>巡检设备情况 <span>（次）</span></h1>
                                    <div id="main3" className="echarts">

                                    </div>
                                </li>
                                <li>
                                <h1>巡检次数统计 <span>（次）</span></h1>
                                    <div id="main4" className="echarts">

                                    </div>
                                </li>
                            </ul>
                 </div>






                 <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>设备类型:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'typeId')}
                                >
                                    <Option value=" ">全部</Option>    
                                    {
                                        this.state.deviceInspectionTypeList.map(item => {
                                            return (
                                                <Option value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                        </li>
                        <li><span>所在工区:</span>
                        <Select
                                showSearch
                                style={{ width: 200, height:30, marginRight:20,  }}
                                placeholder="请选择"
                                optionFilterProp="children"
                                onChange={this.onChangeT.bind(this, 'areaId')}
                            >
                                <Option value=" ">全部</Option> 
                                {
                                    this.state.doormanAllList.map(item => {
                                        return (
                                            <Option value={item.id}>{item.name}</Option> 
                                        )
                                    })
                                }  
                                
                            </Select>  
                        </li>
                        
       
                        
                        <li><span>当前状态:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'state')}
                                >
                                    <Option value=" ">全部</Option>
                                    <Option value="2">正常</Option>
                                    <Option value="3">异常</Option>
                                </Select>
                            </li>
                            
                        <li><span>关键字:</span> <Input  onChange={this.serchParamsFun.bind(this, 'keyword')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" /></li>

                            <Button  className="btn" onClick={this.toSerch.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>

                            
                    </ul>
                    <Button  className="bt" onClick={this.toCreateList.bind(this)} type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button>
                </div>


                {/* 弹框 */}
                <Record ref={(el) => this.refRecord = el}  />



                <div className="table2">
                {/* <div class="allBtn">
    
                    <Button  className="bt" onClick={this.toCreateList.bind(this)} type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button>
                    </div> */}


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }}  columns={this.columns} dataSource={this.state.tableData}  />
                </div>
 
                <Modal title="新增"  width="60%" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                    <Form 
                    name="basic"
                    onFinish={this.onFinish.bind(this)}
                    onFinishFailed={this.onFinishFailed.bind(this)}
                    ref={(el) => this.FormSbmitRef = el}>
                          <div className="deviceInspectionDeviceListModle">
                          <div className="deviceInspectionFormStyle">
                              <div>
                                <Form.Item
                                        label="设备名称"
                                        // name="name"
                                    >
                                        <Input style={{ width: 210,}} value={this.state.toSaveData.name} onChange={this.inputChange.bind(this, 'name')}   placeholder="请输入设备名称" />
                                    </Form.Item>
                              </div>

                              <div>
                                <Form.Item
                                        label="设备编号"
                                        // name="sn"
                                        
                                    >
                                        <Input style={{ width: 210,}} value={this.state.toSaveData.sn} onChange={this.inputChange.bind(this, 'sn')}  placeholder="请输入设备编号" />
                                    </Form.Item>
                              </div>

                              <div>
                                <Form.Item
                                        label="设备类型"
                                        // name="typeId"
                                        style={{ width: '100%' }}
                                    >
                                        <Select
                                            placeholder="请选择"
                                            optionFilterProp="children"
                                            onChange={this.selectChange.bind(this, 'typeId')}
                                            value={this.state.toSaveData.typeId}
                                            style={{ width: 210,}}
                                        >
                                            {
                                                this.state.deviceInspectionTypeList.map(item => {
                                                    return (
                                                        <Option value={item.id}>{item.name}</Option> 
                                                    )
                                                })
                                            }


                                        </Select> 



                                    </Form.Item>
                              </div>
                            
                          </div>

                          <div className="deviceInspectionFormStyle">
                              <div>
                                <Form.Item
                                        label="公司"
                                        // name="enterpriseId"
                                        
                                    >
                                        <div className="setSelect">
                                        <Select
                                            placeholder="请选择"
                                            optionFilterProp="children"
                                            onChange={this.onChangeEnterpriseAll.bind(this)}
                                            value={this.state.toSaveData.enterpriseId}
                                            style={{ width: 210,}}
                                        >
                                            {
                                                this.state.enterpriseAllList.map(item => {
                                                    return (
                                                        <Option value={item.id} item={item}>{item.name}</Option> 
                                                    )
                                                })
                                            }
                                        </Select>
                                        </div>     
                                    </Form.Item>
                              </div>

                              <div>
                                <Form.Item
                                        label="部门"
                                        // name="teamId"
                                        
                                    >
                                        <Select
                                            placeholder="请选择"
                                            optionFilterProp="children"
                                            onChange={this.onChangeTeamAllList.bind(this)}
                                            value={this.state.toSaveData.teamId}
                                            style={{ width: 210,}}
                                        >
                                            {
                                                this.state.teamAllList.map(item => {
                                                    return (
                                                        <Option value={item.id}>{item.name}</Option> 
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                              </div>

                              <div>
                                <Form.Item
                                        label="负责人"
                                        // name="userId"
                                    >
                                         <Select
                                            placeholder="请选择"
                                            optionFilterProp="children"
                                            onChange={this.selectChange.bind(this, 'userId')}
                                            value={this.state.toSaveData.userId}
                                            style={{ width: 210,}}
                                            
                                        >
                                            {
                                                this.state.userAllList.map(item => {
                                                    return (
                                                        <Option value={item.id}>{item.uicName}</Option> 
                                                    )
                                                })
                                            }
                                        </Select>

                                    </Form.Item>
                              </div>
                            
                          </div> 

                          <div className="deviceInspectionFormStyle">
                              <div>
                                <Form.Item
                                        label="所在工区"
                                        // name="doormanId"
                                        onChange={this.selectChange.bind(this, 'areaId')}
                                        defaultValue={this.state.toSaveData.areaId}
                                        
                                    >
                                        <Select
                                            placeholder="请选择"
                                            optionFilterProp="children"
                                            style={{ width: 210,}}
                                        >
                                            {
                                                this.state.doormanAllList.map(item => {
                                                    return (
                                                        <Option value={item.id}>{item.name}</Option> 
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                              </div>

                              <div>
                                <Form.Item
                                        label="详细位置"
                                        // name="installPosition"
                                    >
                                        <Input style={{ width: 210,}} value={this.state.toSaveData.installPosition} onChange={this.inputChange.bind(this, 'installPosition')}  placeholder="请输入详细位置" />
                                    </Form.Item>
                              </div> 
                              <div>
 
                              </div>                    
                              

                          </div> 

                           <div className="flex">
                                <div className="bodyOne set50">
                                    <div className="setFromTilte">巡检内容设置</div> 

                                    {
                                        this.state.toSaveData.content.map((item, index) => {
                                            return (
                                                <div className="setInput flex">
                                                    <div >
                                                        <span className={item.state == 2? 'colorCir': 'gay' + ' colorCir'} onClick={this.changeStatus.bind(this, index, item)}></span>
                                                        <input placeholder="请输入巡检内容" value={item.content} onChange={this.setInput.bind(this, index)} />
                                                    </div>
                                                    <p onClick={this.delContent.bind(this, index)}>删除</p>
                                                </div>
                                            )
                                        })
                                    } 
                                    
                                    <div className="addBtn" onClick = {this.addContent.bind(this)}>点击添加</div>


                                </div>

                                <div className="bodyTwo">
                                        <div className="setFromTilte">设备照片</div>
                                        <p>不上传则不显示，最多上传四张，左右轮播展示。</p> 

                                        <div className="flex">
                                                {
                                                    <ul className="setFingerList flex">
                                                        <Image.PreviewGroup>
                                                        {
                                                            this.state.toSaveData.img.map((item, index) => {
                                                                return (
                                                                    <span style={{marginRight: 20, position: 'relative'}} key={index}>
                                                                        <span className="removeImgList" onClick={()=> this.reMoveImgList(index, 'fingerList')}>×</span>
                                                                    <Image
                                                                        width={100}
                                                                        height={100}
                                                                        src={this.imageUrl + item}
                                                                    />
                                                                    </span>
                                                                )
                                                            })
                                                        } 
                                                        </Image.PreviewGroup>    
                                                    </ul> 
                                                }
                                
                                                

                                                {
                                                function () {
                                                    if(this.state.detatiType) {
                                                    
                                                        } else {
                                                            return (
                                                                <Uploadhh  listStatus={true}   data={{type: 53}}  width={100} height={100} handleClick={this.handleFinger.bind(this)} />
                                                            )
                                                        }
                                                    }.bind(this)()
                                                }  
                                            </div>

                                </div>
                           </div> 
                         
                           </div>
                    </Form>
                </Modal>


      </div>
    );
  }
}
