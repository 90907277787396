import React, { useState, useEffect, useImperativeHandle } from "react";

// import { withRouter } from "react-router-dom";

import {getStorage} from '@/utils/storage';


import Style from './signCanvas.module.less';
import { message } from "antd";



const addPath = process.env.REACT_APP_BASE_URL;
const SignCanvas = React.forwardRef((props, ref) => {
  
 const [ctx, setCtx] = useState(null);
 const [canvas, setCanvas] = useState(null);

  useEffect(() => {

    init();


  }, []);


  const init = ()=> {

    const canvas=document.querySelector('#' + props.id);//获取画布元素

    const ctx = canvas.getContext('2d');
    setCanvas(canvas);
    setCtx(ctx);

    ctx.fillStyle  = '#fff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = '#000';
    let isDrawing = false;
    let lastX, lastY;

   

 
// 鼠标按下事件
    function handleMouseDown(e) {
  
    isDrawing = true;
    [lastX, lastY] = [e.layerX, e.layerY];

    }
    
    
    // 鼠标移动事件
    function handleMouseMove(e) {
    if (!isDrawing) return; // 如果鼠标没有按下，则退出函数
       
    const x = e.layerX;
    const y = e.layerY;

    ctx.beginPath();
    ctx.moveTo(lastX, lastY);
    ctx.lineTo(x, y);
    ctx.stroke();
    
    [lastX, lastY] = [x, y];
    }
    
    // 鼠标释放事件
    function handleMouseUp() {
    isDrawing = false;
    }
    

    
    // 绑定事件
    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);

  }


    function base64ToBlob(base64Data) {
        let arr = base64Data.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        
        while(n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new Blob([u8arr], {type: mime});
      }

    function toUpload() {
        return new Promise((resolve, reject) => {
            const projectId =  getStorage('projectInfo').id;
            
            let dataURL = canvas.toDataURL('image/png');
            let blob = base64ToBlob(dataURL);
            let formData = new FormData();
            formData.append('image[]', blob);
            // formData.append('fileName', 'test.png');
            // formData.append('fileType', 'png');
            // formData.append('pixel', 1);
            formData.append('type', 71);
            // formData.append('logic', 2);
            formData.append('name', 'image[]');
            formData.append('projectId', projectId);
            // formData.append('file', projectId);


            fetch(addPath + '/fileUploadOss2', {
                method: 'POST',
                body: formData
              })
              .then(response => response.json())
              .then(data =>  {
                if(data.code === 20000) {
                    resolve(data.data)
                } else {
                    message.error(data.message);
                    resolve(null);
                }

              })
              .catch(error => console.error(error));

        })    

    }

     // 清除签名
    function clearSignature() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle  = '#fff';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    }


    useImperativeHandle(ref, () => ({
        toUpload,
        clearSignature
    }));



  return (
        <div className={Style.signCanvas}>
      
            <canvas id={props.id} width={props.width} height={props.height}>


            </canvas>

            

            <span className="cler" onClick={clearSignature}>清除</span>
        </div>
  )
});

export default SignCanvas;

