


import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Form, Button, DatePicker, Table, Input, Select, Tabs, message  } from 'antd';

import { SearchOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatTime } from '@/utils/util.js'

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

import image1 from '@/assets/img/coordination/11.png';

import * as echarts from 'echarts';

import './billDay.less';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;


let jsonFieldsList =  {
    '0_日期': {
        field: 'FRq',
            callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
            }
        },
    '1_任务单':'FRwds',
    "2_进料单": 'FJlds',
    "3_砼品种": 'FTpzs',
    "4_总车数":"FLjcs",
    "5_总方量": 'FLjfs',
    "6_司机": 'FSjs',
    "7_车辆数": "FGcs",
    "7_公里数": "FGls",
    "8_统计时间": {
        field: 'FTjsj',
        callback: value => {
            return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
        }
    },
    "9_统计人" : "FTjr",
    "10_余方量" : "FRvA",
    "11_拌方量": "FRvB",
    "12_票方量": "FRvC",
    "13_退方量": "FQvA"
  }



function BillDay() {
        const columns = [
            {
                title: '日期',
                dataIndex: 'FRq',
                align: 'center',
                render: (_) => {
                    let time;
                    if(_) {
                        time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                    }
                    return (
                        <p>{time}</p>
                    )
                }
            },
            {
                title: '任务单',
                dataIndex: 'FRwds',
                align: 'center',
            },
            {
                title: '进料单',
                dataIndex: 'FJlds',
                align: 'center',
            },
            {
                title: '砼品种',
                dataIndex: 'FTpzs',
                align: 'center',
            },
            {
                title: '总车数',
                dataIndex: 'FLjcs',
                align: 'center',
            },

            {
                title: '总方量',
                dataIndex: 'FLjfs',
                align: 'center',
            },
            {
                title: '司机',
                dataIndex: 'FSjs',
                align: 'center',
            },
            {
                title: '车辆数',
                dataIndex: 'FGcs',
                align: 'center',
            },

            {
                title: '公里数',
                dataIndex: 'FGls',
                align: 'center',
            },
            {
                title: '统计时间',
                dataIndex: 'FTjsj',
                align: 'center',
                render: (_) => {
                    let time;
                    if(_) {
                        time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                    }
                    return (
                        <p>{time}</p>
                    )
                }
            },
            {
                title: '统计人',
                dataIndex: 'FTjr',
                align: 'center',
            },
            {
                title: '余方量',
                dataIndex: 'FRvA',
                align: 'center',
            },
            {
                title: '拌方量',
                dataIndex: 'FRvB',
                align: 'center',
            },
            {
                title: '票方量',
                dataIndex: 'FRvC',
                align: 'center',
            },
            {
                title: '退方量',
                dataIndex: 'FQvA',
                align: 'center',
            },

            {
                title: '操作',
                dataIndex: 'value',
                align: 'center',
                render: (_, item) => {
                    return (
                        // <Button onClick={() => {onExpandedRowsChange(item)} }>操作</Button>
                        <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
                    )
                }
            },
        ];

        
        
        const sliceColumns =  [
            {
                title: '日期',
                dataIndex: 'FRq',
                align: 'center',
                render: (_) => {
                    let time;
                    if(_) {
                        time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                    }
                    return (
                        <p>{time}</p>
                    )
                }
            },
            {
                title: '任务单',
                dataIndex: 'FRwds',
                align: 'center',
            },
            {
                title: '进料单',
                dataIndex: 'FJlds',
                align: 'center',
            },
            {
                title: '砼品种',
                dataIndex: 'FTpzs',
                align: 'center',
            },
            {
                title: '总车数',
                dataIndex: 'FLjcs',
                align: 'center',
            },

            {
                title: '总方量',
                dataIndex: 'FLjfs',
                align: 'center',
            },
            {
                title: '操作',
                dataIndex: 'value',
                align: 'center',
                render: (_, item) => {
                    return (
                        // <Button onClick={() => {onExpandedRowsChange(item)} }>操作</Button>
                        <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
                    )
                }
            },
        ]  


        const projectId = React.$getStorage('projectInfo').id

        let loadExcelRef = null

        const [deviceList, setDeviceList] = useState([])
        let trbbListParams = {
            projectId:projectId,
            logic: 2,
            offsetLimit: 0,
            lengthLimit: 10,
            sn: '',
            FRq: ''
        }

        const [snFrq, setSnFrq] = useState({
            sn: '',
            FRq: '',
            logic: 3,
        })

        const [tableList, setTableList] = useState([])

        
        const [ExcelName, setExcelName] = useState('考试');
        const [columns2, setColumn] =  useState(columns);
        const [loading, setLoading] = useState(false)
        const [allParams, setAllParams] = useState({
            logic: 2,
            keyword: null,
            sn: null,
            FJzbw: null,
            FTpz: null,
            FZt: null,
            FJhrqStart: null,
            FJhrqEnd: null,
            offsetLimit: 0,
            lengthLimit: 10,
            projectId: projectId,
            FNo: null,
        })
    
        const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
        })

        // 任务单统计
        const [TaskStatistics, setTaskStatistics] = useState([])
        // 原材料统计
        const [materialStatistics, setMaterialStatistics] = useState([])
        // 砼品种
        const [tongVarieties, setTongVarieties] = useState([])

        const [widthStatus, setWidthStatus] = useState(false)

        const [widthStatus2, setWidthStatus2] = useState(false)

        const [headParams, setHeadParams] = useState({
            FRq: '',
            FLjfs: '',
            FTpzs: '',
            FRvB: '',
        })
        const [tableHeadParams, setTableHeadParams] = useState({
            FRq: '',
            FLjfs: '',
            FTpzs: '',
            FRvB: '',
        })


        useEffect(() => {
            getDeviceList()
          }, []);

          
          // 获取设备列表
          function getDeviceList() {
            let params = {
                logic: 3,
                projectId: projectId
            }
            React.$axios.post('deviceListMixingStation', params).then(res => {
                if(res.data.list.length == 0) {
                    return
                }
                setDeviceList(res.data.list)
                let list =res.data.list
                trbbListParams.sn = list[0].sn


                snFrq.sn = list[0].sn

                setSnFrq(snFrq)

                getList()
                // 获取上方更新数据
                getHeadDetail()
            })

          }

          function changeTabsHead(index) {
            trbbListParams.sn = deviceList[index].sn

            snFrq.sn = deviceList[index].sn

            setSnFrq(snFrq)

            setWidthStatus2(false)
            setWidthStatus(false)
            getList()
            // 获取上方更新数据
            getHeadDetail()
          }
          

          // 获取列表
          function getList(type) {

                if(type) {
                    pagination.current = 1;
                    setPagination(pagination)
                } 

                trbbListParams.offsetLimit = (pagination.current - 1) * 10
                trbbListParams.lengthLimit = pagination.pageSize
                trbbListParams.sn = snFrq.sn
                trbbListParams.FRq = snFrq.FRq

                setLoading(true)
                React.$axios.post('trbbListMixingStation', trbbListParams).then(res => {

                setTableList(res.data.list)
                setpage(res)
                }).finally(() => {
                setLoading(false)
                })

          }


          function setpage(res) {

            pagination.current = pagination.current;
            pagination.pageSize = pagination.pageSize;
            pagination.total = res.data.countList;
            let json = JSON.parse(JSON.stringify(pagination)); 
            json.onShowSizeChange = function(current, pageSize) {
                pagination.pageSize = pageSize;
                let json = JSON.parse(JSON.stringify(pagination)); 
                setPagination(json)
                onPaginationChange(json)
            }
            json.onChange = function(current) {
                pagination.current = current
                let json = JSON.parse(JSON.stringify(pagination)); 
                setPagination(json)
                onPaginationChange(json)
            }
            json.showTotal = function () {  //设置显示一共几条数据
                return '共 ' + pagination.total + ' 条'; 
            }
            setPagination(json)
        }



        
        // 点击展开
        function onExpandedRowsChange(item) {
            // console.log(111)
            toSee(true)

            // setEcharts()

            getTrwdListMixingStation(item)
            getTrbbylListMixingStation(item)
            getTrbbclListMixingStation(item)
            
            // 更新图形
            getTableHeadDetail(item)

            getHeadDetail(item.FRq)

        }


        // 获取上方详情信息
    function getTableHeadDetail(item) {
        
        console.log(item)
        let params = {
            logic: 4,
            sn: item.sn,
            FRq: item.FRq,
            projectId: item.projectId
        }
        
        React.$axios.post('trbbListMixingStation', params).then(res => {
            
            let day2 = new Date(item.FRq * 1000)

                let arr = []

                res.data.Trbbyl.map(item => {
                    arr.push({
                        name: item.FYlmc,
                        value: item.FUse
                    })

                })

                
                if(res.data.Trbb) {
                    setTableHeadParams({
                        FRq: day2.getFullYear() + '-' + (day2.getMonth()+1) + '-' + day2.getDate(),
                        FLjfs: res.data.Trbb.FLjfs,
                        FTpzs: res.data.Trbb.FTpzs,
                        FRvB: res.data.Trbb.FRvB
                    })

                    setHeadParams({
                        FRq: day2.getFullYear() + '-' + (day2.getMonth()+1) + '-' + day2.getDate(),
                        FLjfs: res.data.Trbb.FLjfs,
                        FTpzs: res.data.Trbb.FTpzs,
                        FRvB: res.data.Trbb.FRvB
                    })

                } else {
                    setTableHeadParams({
                        FRq: day2.getFullYear() + '-' + (day2.getMonth()+1) + '-' + day2.getDate(),
                        FLjfs: '',
                        FTpzs: '',
                        FRvB: ''
                    })

                    setHeadParams({
                        FRq: day2.getFullYear() + '-' + (day2.getMonth()+1) + '-' + day2.getDate(),
                        FLjfs: '',
                        FTpzs: '',
                        FRvB: ''
                    })
                }

                setEcharts(arr)
        })
    }




        // 任务单列表
        function getTrwdListMixingStation(item) {
            let params = {
                logic: 3,
                sn: item.sn,
                FRwdh: item.FRwds,
                projectId:projectId,
                FRq: item.FRq
            }
            React.$axios.post('trwdListMixingStation', params).then(res => {
                setTaskStatistics(res.data.list)
            })
        }


        // 原材料消耗列表
        function getTrbbylListMixingStation(item) {
            let params = {
                logic: 3,
                projectId: projectId,
                sn: item.sn,
                FRq: item.FRq,
            }

            React.$axios.post('trbbylListMixingStation', params).then(res => {
                setMaterialStatistics(res.data.list)
            })
        }

        // 砼品种
        function getTrbbclListMixingStation(item) {
            let params = {
                logic: 3,
                projectId: projectId,
                sn: item.sn,
                FRq: item.FRq,
            }
            React.$axios.post('trbbclListMixingStation', params).then(res => {
                setTongVarieties(res.data.list)
            })
        }

        // 设置图形
        function setEcharts(data) {
            var myChart = echarts.init(document.getElementById('echartsTime'));
            let option = {
                tooltip: {
                    trigger: 'item'
                },
                
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 30,
                    top: 10,
                    bottom: 20,
                },
                series: [
                    {
                        name: '材料',
                        type: 'pie',
                        // colorBy: '#ff0000',
                        // borderWidth: '',

                      
                        radius: ['50%', '90%'],
                        x:-180,//靠左
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: data
                    }
                ]
            };
            
            
            myChart.setOption(option);
        }


        function toSee(status, item, recove) {
  
            if(status) {
                setColumn(sliceColumns)
            } else {
                setColumn(columns)
            }
    
         setWidthStatus(status)


        if(!status) {
            setTimeout(() => {
                setWidthStatus2(status)
            }, 1000)
        } else {
            setWidthStatus2(status)
        }
        
      }


    function onPaginationChange(row) {
        setPagination(row)
        getList()
    }

    // 获取上方详情信息
    function getHeadDetail(time) {

        let params = {
            logic: 4,
            sn: snFrq.sn,
            FRq: '',
            projectId: projectId
        }

        if(time) {
            params.FRq = time
        }

        
        React.$axios.post('trbbListMixingStation', params).then(res => {
            console.log(res)
            let arr1 = []
            let arr2 = []

            res.data.Trbbyl.map(item => {
                arr1.push(item.FYlmc)
                arr2.push(item.FUse)
            })

            
            if(res.data.Trbb) {
                let day2 = new Date(res.data.Trbb.FRq * 1000)

                setHeadParams({
                    FRq: day2.getFullYear() + '-' + (day2.getMonth()+1) + '-' + day2.getDate(),
                    FLjfs: res.data.Trbb.FLjfs,
                    FTpzs: res.data.Trbb.FTpzs,
                    FRvB: res.data.Trbb.FRvB
                })
            }

            




            setechart2(arr1, arr2)
        })
    }

    // 时间转换
    function onChangeTime(time) {
        if(time) {
            let date = time._d
            let year = date.getFullYear()
            let Moath = date.getMonth() + 1
            let day = date.getDate()

            let setNewTime = new Date(year + '/' + Moath + '/' + day + ' ' + '00:00:00');
            
            trbbListParams.FRq =   parseInt((setNewTime.getTime()  / 1000))  
        } else {
            trbbListParams.FRq = null
        }

        snFrq.FRq = trbbListParams.FRq

        setSnFrq(snFrq)
    }


    function toSerch() {
        getList(true)
    }


    function setechart2(arr1, arr2) {
        var myChart = echarts.init(document.getElementById('main2'));
        let option = {
        //   legend: {
        //     data: ['质量'],
        //     left: 'right'
        // },
        tooltip: {
            trigger: 'item'
        },
    
          textStyle: {
            fontWeight: 'normal',
            color: '#7BABFF',
          },
          grid: {
            left: '1%',
            right: '4%',
            // containLabel: true
          },
          xAxis: [
            {
                type: 'category',
                data: arr1,
                axisLabel: {  
                    interval:0,  
                    rotate:-40
                }, 
                axisLine: {
                    lineStyle: {
                      color: '#D9D9D9'
                    }
                  },
                axisTick: {
                    alignWithLabel: true
                },
                // axisLabel: {
                //   interval: 0,
                //   formatter: function(value) {
                //     let month =  value.split('月')
                //     let str = ''
                //     if(month[1]) {
                //       str = month[0] + '月' + '\n' + month[1]
                //     }else {
                //       str = month[0] + '月'
                //     }
                //     return str
                //   }
                // },
                　splitLine:{
                　　　　show:false
                　　}
            }
        ],
    
          yAxis: [{
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#fff'
              }
            },
            　splitLine:{
            　　　　show:false
            　　}
          }],
    
          series: [
            {
                name: '材料',
                type: 'bar',
                data: arr2,  //data1,
                symbolSize: 0,
                // barWidth: 5,
                // barMaxWidth:30,
                color: ['#106CFF'],
                areaStyle:{
                    normal: {
                        color: '#ff0000'
                    }
                },

            },
        ],
        };

         myChart.setOption(option);
      }



    // 导出方法
    function fetchFun() {
        
        setExcelName(formatTime(new Date(), '日账单yyyy-MM-dd hh:mm:ss'))

        return new Promise((resolve) => {
            let newParams = Object.assign({}, snFrq);
            newParams.logic = 3
            newParams.projectId = projectId
            
            React.$axios.post('trbbListMixingStation', newParams).then(res => {
                if(res.data.list.length == 0) {
                    message.success('没有查找到数据')
                } else {
                    resolve(res.data.list)
                }
                
            })
        })
      }




    return (
        <div className="billDay">

            
            <div className="flex tableWarp">

            

            <div className="setWidth">
            <div className="head">
                <Tabs defaultActiveKey="0" onChange={changeTabsHead}>

                    {
                        deviceList.map((item, index) => {
                            return (
                                <TabPane tab={item.name} key={index}>
     
                                </TabPane>
                            )
                        })
                    }

      
                </Tabs>

                <div className="flex tabWarp">
                            <ul>
                                <li>
                                    <div className="flex">
                                    <span>生产时间</span>
                                    <div className="context">{headParams.FRq}</div>
                                    <span>总&ensp;方&ensp;量</span>
                                    <div className="context setW2">{headParams.FLjfs}</div>
                                    </div>

                                    <div className="flex">
                                    <span>砼&ensp;品&ensp;种</span>
                                    <div className="context">{headParams.FTpzs}</div>
                                    <span >拌&ensp;方&ensp;量</span>
                                    <div className="context setW2">{headParams.FRvB}</div>
                                    </div>

                                </li>
                            </ul>


                            <div className="echart">

                            <div id="main2" className="setEchart setEchart2"></div>   
                            </div>

                                <div style={{display: widthStatus2? 'none': 'block'}}>
                                <div className="serchData serchBtn">

                                    <i>日期</i> 
                                    <div className="date"><DatePicker onChange={onChangeTime} /></div>
                                    <Button className="setMargin styleBtnYellow" type="primary" onClick={toSerch}>搜索</Button>

                                    <DownloadExcel ref={(el) => loadExcelRef = el}  name={ExcelName} fields={jsonFieldsList}  fetch={fetchFun} btnName="导出">
                                        <Button  type="primary styleBtnGreen">导出</Button>
                                    </DownloadExcel>   
                                    </div>
                                </div>
                                
   
                        </div>

            
            </div>    





                <Table
                    bordered
                    loading={loading}
                    columns={columns2}
                    dataSource={tableList}
                    pagination={pagination}
                />

            </div>



            

            <div className={`detail  ${widthStatus? 'addWidth': ''}`}>
                
                <div className={`reactive ${widthStatus? 'ShowBlock': ''}`}    onClick={() => toSee(false)}>
                    ×
                </div>
                {/* <Tabs defaultActiveKey="1">
                    <TabPane tab="2021-11-12日报表统计信息" key="1"> */}
                    <h1 className="title">日报表统计信息</h1>
                    {/* <h1 className="title">2021-11-12日报表统计信息</h1> */}

                    <div className="statisticalInfo">
                        <div>
                            <div className="li">
                                <span>生产时间</span>
                                {tableHeadParams.FRq}
                            </div>
                            <div className="li">
                                <span>砼&ensp;品&ensp;种</span>
                                {tableHeadParams.FTpzs}
                            </div>
                            <div className="li">
                                <span>总&ensp;方&ensp;量</span>
                                {tableHeadParams.FLjfs}
                            </div>
                            <div className="li">
                                <span>拌&ensp;方&ensp;量</span>
                                {tableHeadParams.FRvB}
                            </div>
                        </div>


                        <div className="echartsTime">
                            <div id="echartsTime" className="echartsTime"></div>
                        </div>        

                    </div>                    

                    <div className="setMaxHeight">

                    <Tabs defaultActiveKey="1">
                        <TabPane tab="按任务单统计" key="1" >
                        <ul className="setdetailedInfo" >

                                <table>
                                <tr className="firstTr">
                                    <th>任务单号</th>
                                    <th>单位名称</th>
                                    <th>工程名称</th>
                                    <th>浇筑部位</th>
                                    <th>浇筑方式</th>
                                    <th>砼品种</th>
                                    <th>砼数量</th>
                                    <th>任务性质</th>
                                    <th>任务来源</th>
                                    <th>状态</th>
                                    <th>合同编号</th>
                                    <th>工程级别</th>
                                    <th>施工地点</th>
                                    <th>公里数</th>
                                    <th>计划日期</th>
                                    <th>坍落度</th>
                                    <th>水泥品种</th>
                                    <th>石子规格</th>
                                    <th>计划方量</th>
                                    <th>生产拌台</th>
                                    <th>累计车数</th>
                                    <th>下达任务</th>
                                    <th>操作员</th>
                                    <th>登录日期</th>
                                    <th>施工配合比号</th>
                                    <th>砂浆配合比号</th>
                                    <th>试验员1</th>
                                    <th>搅拌时间</th>
                                    <th>出砼门控制参数</th>
                                    <th>试验员2</th>
                                    <th>砼单价</th>
                                    <th>砼金额</th>
                                    <th>泵送数量</th>
                                    <th>泵送单价</th>
                                    <th>泵送金额</th>
                                    <th>容重</th>
                                    <th>外援总方数</th>
                                    <th>外援总车数</th>
                                    <th>设备编号</th>
                                </tr>

                                {

                                    TaskStatistics.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.FRwdh}</td>
                                                <td>{item.FHtdw}</td>
                                                <td>{item.FGcmc}</td>
                                                <td>{item.FJzbw}</td>
                                                <td>{item.FJzfs}</td>
                                                <td>{item.FTpz}</td>
                                                <td>{item.FWcsl}</td>
                                                <td>{item.FRwxz}</td>
                                                <td>{item.FRwly}</td>
                                                <td>{item.FZt}</td>
                                                <td>{item.FHtbh}</td>
                                                <td>{item.FGcjb}</td>
                                                <td>{item.FGcdz}</td>
                                                <td>{item.FGls}</td>
                                                <td>{formatTime(new Date( item.FJhrq * 1000), "yyyy-MM-dd")}</td>
                                                <td>{item.FTld}</td>
                                                <td>{item.FSnbh}</td>
                                                <td>{item.FSzgg}</td>
                                                <td>{item.FJhsl}</td>
                                                <td>{item.FScbt}</td>
                                                <td>{item.FLjcs}</td>
                                                <td>{item.FXdrw}</td>
                                                <td>{item.FCzy}</td>
                                                <td>{formatTime(new Date( item.FDlrq * 1000), "yyyy-MM-dd")}</td>
                                                <td>{item.FSgpb}</td>
                                                <td>{item.FSjpb}</td>
                                                <td>{item.FSyy1}</td>
                                                <td>{item.FJbsj}</td>
                                                <td>{item.FClsjNo}</td>
                                                <td>{item.FSyy2}</td>
                                                <td>{item.FTdj}</td>
                                                <td>{item.FTje}</td>
                                                <td>{item.FBssl}</td>
                                                <td>{item.FBsdj}</td>
                                                <td>{item.FBsje}</td>
                                                <td>{item.FRz}</td>
                                                <td>{item.Fwyzfs}</td>
                                                <td>{item.Fwyzcs}</td>
                                                <td>{item.sn}</td>
                                            </tr>
                                        )
                                    })

                                }
                                </table> 
                            </ul>   


                        </TabPane>
                        <TabPane tab="按原材料消耗统计" key="2">
                        <ul className="setdetailedInfo" >
                            <table>
                                <tr className="firstTr">
                                    <th>日期</th>
                                    <th>设备编号</th>
                                    <th>仓库</th>
                                    <th>原料名称</th>
                                    <th>品种规格</th>
                                    <th>实际数量</th>
                                    <th>配比数量</th>
                                    <th>生产拌台</th>
                                    
                                </tr>

                                {

                                    materialStatistics.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{formatTime(new Date( item.FRq * 1000), "yyyy-MM-dd")}</td>
                                                <td>{item.sn}</td>
                                                <td>{item.FCkbh}</td>
                                                <td>{item.FYlmc}</td>
                                                <td>{item.FPzgg}</td>
                                                <td>{item.FUse}</td>
                                                <td>{item.FPbsl}</td>
                                                <td>{item.FRvA}</td>
                                            </tr>
                                        )
                                    })

                                }
                            </table> 
                            </ul>         
                                

                            
                        </TabPane>
                        <TabPane tab="按砼品种统计" key="3">
                            
                        {/*  */}
                        <ul className="setdetailedInfo" >
                            <table>
                                <tr className="firstTr">
                                    <th>日期</th>
                                    <th>设备编号</th>
                                    <th>生产拌台</th>
                                    <th>砼品种</th>
                                    <th>累计车数</th>
                                    <th>累计方数</th>
                                    <th>余方量</th>
                                    <th>拌方量</th>
                                    <th>票方量</th>
                                    <th>退方量</th> 
                                </tr>

                                {

                                    tongVarieties.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{formatTime(new Date( item.FRq * 1000), "yyyy-MM-dd")}</td>
                                                <td>{item.sn}</td>
                                                <td>{item.FScbt}</td>
                                                <td>{item.FTpz}</td>
                                                <td>{item.FLjcs}</td>
                                                <td>{item.FLjfs}</td>
                                                <td>{item.FRvA}</td>
                                                <td>{item.FRvB}</td>
                                                <td>{item.FRvC}</td>
                                                <td>{item.FQvA}</td>
                                            </tr>
                                        )
                                    })

                                }
                            </table> 
                        </ul>       

                        </TabPane>
                    </Tabs>
                        
                    </div>    

                    {/* </TabPane>
                </Tabs> */}

         
            </div>

        </div>



        </div>
    )

}


export default withRouter(BillDay);