import React, {useState, useEffect } from "react";

import { withRouter } from 'react-router-dom';

import { Tabs, Modal, Button} from 'antd';

import { CloseOutlined, PlusOutlined, MinusOutlined, ExpandOutlined, DownloadOutlined } from '@ant-design/icons';


// import PDF from 'react-pdf-js';

import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';



// import 'react-pdf/dist/Page/AnnotationLayer.css' // 加入这个css可以显示pdf中的批注以及连接



import style from  './DrawingList.module.less';

import fileImg from '@/assets/img/DrawingList/文件夹 (7).png';
import pdfImg from '@/assets/img/DrawingList/pdf (2).png';
import { downloadFile } from "image-conversion";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;




let imgUrl = process.env.REACT_APP_IMG_URL


const { TabPane } = Tabs;

function DrawingList() {
    let projectId = React.$getStorage('projectInfo').id

    let dataList = []

    const [DrawList, setList] = useState([]);

    const [typeIndex, setTypeIndex] = useState(null);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [activeIndex, setActive] = useState(0);

    const [showTiltleList, setTitleList] = useState([]);

    const [address, setAddress] = useState(null);

    
    const [numPages, setNumPages] = useState(null);


    const [scalcPdf, setScalcPdf] =  useState(2);



    const [params, setParams] = useState({
        logic: 3,
        pid: 0,
        projectId: projectId,
        type: null
    });



    useEffect(() => {
         getList();
    }, [params]);

    useEffect(() => {
        let actve = 0
        if(showTiltleList.length > 0) {
            if(activeIndex >= showTiltleList.length) {
                actve = showTiltleList.length - 1
            } else {
                actve = activeIndex
            }

            let addr =  imgUrl + showTiltleList[actve].url
            setActive(actve)   
            setAddress(addr)
        } else {
            setAddress(null)
        }
    }, [showTiltleList])    





    const getList = () => {
        React.$axios.post('listDrawing', params).then(res => {
            res.data.list.map(item => {
                item.status = 2
                item.childList = []
            })

            dataList = res.data.list

             setList(res.data.list)
        }) 
    }


    const toGetMiniList =(item, index)=> {
        
       
        const arr = [...DrawList];

        // console.log(index)
        // arr.map(item.status =)


        if(item.status == 2) {
            item.status = 3
        } else {
            item.status = 2
        }

        if(item.requers) {
            setList(arr)
            return
        }


        

        item.requers = true
        getPaper(item.id).then(res => {
            item.childList = res.data.list
            setList(arr)
        })
    }

    // 获取图纸
    const getPaper = (id) => {
        let params = {
            projectId: projectId,
            pid: id,
            logic: 3,
            type: typeIndex
        }
        
        return new Promise(reslove => {
            React.$axios.post('listDrawing', params).then(res => {
                reslove(res)
            })
        })

        
    }


    const getShowList = (item) => {
        let arr = [...showTiltleList];
        if(arr.length >= 4) {
            setIsModalVisible(true)
            return
        }


        let status = false

        arr.map((item2,index) =>  {
            if(item2.id == item.id) {
                status = true
                changeActive(index)
            }
        })

        if(status) {
            return
        }


      
        arr.push(item)
        setTitleList(arr);
        setActive(arr.length - 1);
    }

    const handleOk = () => {
        handleCancel()
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const changeActive = (index, item) => {
        
        setActive(index)
        let addr =  imgUrl + showTiltleList[index].url
        setAddress(addr)   
    }




   const callback =(value) => {
    if(value == '1') {
        value = null
    }
     let allParams =  JSON.parse(JSON.stringify(params)); 
    setParams(allParams)

    setTypeIndex(value)
   }


   function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);

    console.log(numPages)

  }


  function toScalc(type) {
    let num = scalcPdf
    if(type) {
        num += 0.2
    } else {
        num -= 0.2
    }

    setScalcPdf(num)
  }

  function windowOpen(path) {
    window.open(path)
  }

  function downloadPath(path) {

    let name =  showTiltleList[activeIndex].name

    let a = document.createElement('a');
    a.setAttribute('download', name + '.pdf');
    a.setAttribute('href', path);
     a.click()
  }

   


   const setPdf =(value) => {
        // console.log(value)
        if(address) {

            return (
                <div>
                    <div className={style.setBtn}>

                        <div>
                        <Button size="small" type="text" shape="circle" onClick={() => {downloadPath(address)}} icon={<DownloadOutlined />} />
                        </div>
                        <div>
                        <Button size="small" type="dashed" onClick={() => {windowOpen(address)}} shape="circle" icon={<ExpandOutlined />} />
                        </div>

                        <div>
                          <Button size="small" onClick={() => {toScalc(true)}}  shape="circle" icon={  <PlusOutlined />} />
                        </div>
                        <div>
                         <Button  size="small" onClick={() => {toScalc(false)}} shape="circle" icon={<MinusOutlined />} />
                        </div>
                      
                    </div>

                    <Document
                        file={address}
                        
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {/* <Page pageNumber={pageNumber} /> */}

                        {
                            new Array(numPages).fill('').map((item, index) => {
                                index += 1
                                return <Page scale = {scalcPdf} key={index} pageNumber={index} />
                            })
                        }
                    </Document>
                </div>


            )
            
        }
   }

   // 删除头部信息
   const toRemoveTitle = (index, e) => {
    let arr = [...showTiltleList];

    arr.splice(index, 1)
    setTitleList(arr);
    e.stopPropagation()
   }


    return (
        <div className={style.DrawingListWarp}>
            <div className={style.leftWarp}>
            <Tabs defaultActiveKey="1" tabBarGutter={70} tabBarStyle={{marginRight: 50}} centered={true} onChange={callback}>
                <TabPane tab="全部" key="1">
                
                </TabPane>
                <TabPane tab="正式" key="2">
                   
                </TabPane>
                <TabPane tab="变更" key="3">
                   
                </TabPane>
                <TabPane tab="送审" key="4">
                    
                </TabPane>
            </Tabs>

            <div className={style.table}>
                <ul>
                    {
                        DrawList.map((item,index) => {
                        return (
                            <li>
                            <div className={style.list} onClick={() => { toGetMiniList(item, index)}}>
                                    <div className={style.text}>
                                        <p>{item.name}</p>
                                        <p className={style.random}>
                                            {item.remark}
                                        </p>   
                                    </div>
                                    <div className={style.img}>
                                        <img src={fileImg} alt="" />
                                    </div>
                                </div>
                                <div className = {style['status' + item.status]}>
                                    {
                                        item.childList.map(item2 => {
                                            return (
                                                <div className={style.list + ' ' + style.noShow } onClick={() =>{getShowList(item2)}}>
                                                        <div className={style.text}>
                                                            <p><span className={style['setType' + item2.type]}>{item2.type == 2? '正式': item2.type == 3? '变更': '送审'}</span> {item2.name}</p>
                                                            <p>
                                                            编号：{item2.numbering}
                                                            </p>   
                                                        </div>
                                                        <div className={style.img}>
                                                            <img src={pdfImg} alt="" />
                                                        </div>         
                                                </div>
                                            )

                                        })
                                    }
                                    
                                </div>
                            </li>
                        )
                        })
                    } 
                </ul>

            </div>


            <Modal title="提示" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>最多同时打开四张</p>
            </Modal>

            </div>


   

            <div className={style.rightWarp}>
                <ul className={style.tableChange}>
                    
                    {
                        showTiltleList.map((item, index) => {
                            return (
                                <li onClick={() => changeActive(index)} className={ activeIndex == index ?style.active: ''}><p><span className={style.setSpan +' '+ style['setType' + item.type]}>{item.type == 2? '正式': item.type == 3? '变更': '送审'}</span>{item.name}</p>  <i onClick={(e) => toRemoveTitle(index, e)}><CloseOutlined /></i></li>
                            )
                        })
                    }
                </ul>


                <div className={style.drawing}>
                    {
                        setPdf()
                    }

                </div>

            </div>

        </div>
    )
}


export default withRouter(DrawingList);
