import React from 'react'

import { LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import { Upload, Image, message } from 'antd';


import * as imageConversion from "image-conversion";

import './upload.less'



let loading = false
    

const uploadButton = (
    <div >
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}></div>
    </div>
    );

export const Uploadhh = (
    class Uploadhh extends React.Component {
        accessLogin = ''
        state = {
            visible: true,
            imageUrl: '',
            listImg: []
        }

        imgUrl = process.env.REACT_APP_IMG_URL  // 图片地址

        static defaultProps = {
            width: 104,
            height: 104,
            // url: 
            action: process.env.REACT_APP_BASE_URL,
            path: 'fileUploadOss', // 默认
            name: 'image[]', // 默认参数
            listStatus: false, // 开启图片列表
            data: {}, // 传递参数
            left: 0, 
            imageUrl: '',
            setStyle: {},
            accept: '',

            pdfStatus: false, // 不需要压缩
            beforeFun: null,
          }
          
        componentWillMount() {
            let accessLogin =  React.$getStorage('accessLogin')
            // console.log(111111111)
            this.accessLogin = accessLogin
        }

        beforeUpload(file) {
            let _this =this
            loading = true

            // 执行上传前的回调
            if(this.props.beforeFun) {
              let status =   this.props.beforeFun(file)
                
              if(status == false) {
                return false
              } else {
                
              }
            }

           
            return (new Promise((resolve) => {
           
                let isLt2M = file.size / 1024 / 1024 < 2;
                
                if (isLt2M || _this.props.pdfStatus) {
                  resolve(file);
                }
        
                let size = 200
                if(file.size >= 4512834) {
                  size = 600
                }

                  imageConversion.compressAccurately(file, {
                    size: size,
                    scale: 0.5,
                  }).then(res => { 
                     console.log(res)
                    resolve(res);
                  }).catch((e) => {
                    console.error(e);
                  })
              }));

        }

        handleChange(e) {
            this.setState({
                loading: true
            })


            if(e.file.response) {
                const { handleClick } = this.props;
                if(e.file.response.code != 20000) {
                    message.error(e.file.response.message)
                    if(this.props.beforeFun) {
                        this.props.beforeFun(e.file, true)
                      }
                    return
                }

                let img =  handleClick(e.file.response, e.file)
                if(!this.props.listStatus) {
                    this.setState({
                        imageUrl: img 
                    })
                } else {
                    this.state.listImg.push(img)
                    this.setState({
                        listImg:  this.state.listImg
                    })
                }
            }

            

        }

        setImgStatus() {
            this.state.imageUrl = this.props.imageUrl
            // this.setState({
            //     imageUrl: this.props.imageUrl
            // })



            if(this.props.listStatus) {
                return (
                    <span>
                        {uploadButton}
                    </span>
                )
            } else {
                return (
                    <span>
                        
                    {this.state.imageUrl ? <img src={this.imgUrl + this.state.imageUrl} alt="avatar" style={{ width: this.props.width, height: this.props.height }} /> : uploadButton}
                    
                    </span>
                )
            }

        }


        render() {
            return <div className="div">
                {/* {this.setImgStatus2()}  */}

                <div style={{width: this.props.width, height: this.props.height, marginLeft: this.props.left}}>
                    <Upload
                            name={this.props.name}
                            listType="picture-card"
                            className="avatar-uploader hhLoad"
                            data={this.props.data}
                            style= {this.props.setStyle}
                            showUploadList={false}
                            accept={this.props.accept}
                            headers = {
                                {
                                    accesstoken:'$2y$10$z.0Fz2z0r9XKJ5M3.TowhOEzBGjQndjo2hijvAZQmdLjztiJkbUZu',
                                    accessLogin: this.accessLogin
                                }
                            }
                            action= {this.props.action +  this.props.path}
                            beforeUpload={this.beforeUpload.bind(this)}
                            onChange={this.handleChange.bind(this)}
                        >
                        
                        {this.setImgStatus()}
                    </Upload>
                </div>

        
                    
            </div>
        }
    }
)
