import React from "react";


import './home.less';

// import * as Cesium from "cesium";
// import "cesium/Build/Cesium/Widgets/widgets.css";

import img1 from '@/assets/img/home/1.jpg';
import img2 from '@/assets/img/home/2.jpg';
import img3 from '@/assets/img/home/3.jpg';
import img4 from '@/assets/img/home/4.jpg';




// window.CESIUM_BASE_URL = '/'; // 静态配置地址

 //Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2NGE3ZGE4MC0yYmUyLTRlZmYtYjA2OS03MmRjMjBmM2M5OTkiLCJpZCI6NjQ1MTgsInNjb3BlcyI6WyJsZ24iLCJwciIsImFjdyIsImFzbCIsImFzciIsImFzdyIsImFzcyIsImdjIiwibHIiLCJsdyIsInRyIiwidHciLCJ1c2ciXSwiaWF0IjoxNjI5NTI5ODM4LCJleHAiOjE2MzAxMzQ2Mzh9.FcoeIGWyRvQTrSpPkHa6KtgAd5OZjqP7fHGGD4Dm068';

export default class Home extends React.Component {

  state = {
    herfEarth: ''
  }

  componentDidMount() {
    console.log(window.location)

    this.setState({
      herfEarth: window.location.origin + '/Earth' 
    })


    // console.log(Cesium)

    // const viewer = new Cesium.Viewer('cesiumContainer', {
    //   terrainProvider: Cesium.createWorldTerrain()
    // }); 
    
    // const buildingTileset = viewer.scene.primitives.add(Cesium.createOsmBuildings());

    

    // viewer.camera.flyTo({
    //   destination : Cesium.Cartesian3.fromDegrees(-122.4175, 37.655, 400),
    //   orientation : {
    //     heading : Cesium.Math.toRadians(0.0),
    //     pitch : Cesium.Math.toRadians(-15.0),
    //   }
    // });


    // {
    //   var worldTerrain = Cesium.createWorldTerrain({});

    
    //   var viewer = new Cesium.Viewer("cesiumContainer", {
    //     terrainProvider: worldTerrain,
    //   });
  
  
    //   viewer.scene.globe.enableLighting = true;
    // }
  


    //   let dataPoint = { longitude: 116.45487, latitude: 39.89137, height: 1684.76 };
    // let pointEntity = viewer.entities.add({
    //   description: `第一个数据点 (${dataPoint.longitude}, ${dataPoint.latitude})`,
    //   position: Cesium.Cartesian3.fromDegrees(dataPoint.longitude, dataPoint.latitude, dataPoint.height),
    //   point: { pixelSize: 10, color: Cesium.Color.RED }
    // })

    // viewer.flyTo(pointEntity);

    


    // setTimeout(() => {


    //   var tileset = new Cesium.Cesium3DTileset({
    //     //相对路径
    //       url: 'https://oss.1wx.cn/newData2/tileset.json',
    //     });


    //     console.log(tileset)

    //     viewer.scene.primitives.add(tileset);
    
    //     viewer.zoomTo(tileset);
        
    // }, 10000)


//     var ellipsoidProvider = new Cesium.EllipsoidTerrainProvider();

//     var vrTheWorldProvider = new Cesium.VRTheWorldTerrainProvider({
//       url: "http://www.vr-theworld.com/vr-theworld/tiles1.0.0/73/",
//       credit: "Terrain data courtesy VT MÄK",
//     });





//     var arcGisProvider = new Cesium.ArcGISTiledElevationTerrainProvider({
//       url:"https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer",
//     });


// var customHeightmapWidth = 32;
// var customHeightmapHeight = 32;
// var customHeightmapProvider = new Cesium.CustomHeightmapTerrainProvider(
//   {
//     width: customHeightmapWidth,
//     height: customHeightmapHeight,
//     callback: function (x, y, level) {
//       var width = customHeightmapWidth;
//       var height = customHeightmapHeight;
//       var buffer = new Float32Array(width * height);

//       for (var yy = 0; yy < height; yy++) {
//         for (var xx = 0; xx < width; xx++) {
//           var u = (x + xx / (width - 1)) / Math.pow(2, level);
//           var v = (y + yy / (height - 1)) / Math.pow(2, level);

//           var heightValue = 4000 * (Math.sin(8000 * v) * 0.5 + 0.5);

//           var index = yy * width + xx;
//           buffer[index] = heightValue;
//         }
//       }

//       return buffer;
//     },
//   }
// );


// var target = new Cesium.Cartesian3(
//   300770.50872389384,
//   5634912.131394585,
//   2978152.2865545116
// );
// var offset = new Cesium.Cartesian3(
//   6344.974098678562,
//   -793.3419798081741,
//   2499.9508860763162
// );
// viewer.camera.lookAt(target, offset);
// viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);



// setTimeout(() => {
//   viewer.terrainProvider = vrTheWorldProvider;
// }, 10000)




  }

  toPath(path) {
    if(path) {
      window.open(window.location.origin + path)
    } else {
      window.open('http://1wx.cn/overview')
    }
    
  }

  render() {
    return (
      <div className="Home">

        {/* <iframe width="100%" height="100vh" src="https://webpro.raykite.com/share/s21vX0bU?s=094842&p=2f6117" frameborder="0"></iframe> */}


          <div id="cesiumContainer">

          </div>



        <div className="toPath">
          <a onClick={this.toPath.bind(this, '/Earth')}>
            <h1>进入地球</h1>
            <img src={img1} alt="" />
          </a>

          <a onClick={this.toPath.bind(this, false)}>
          <h1>进入大屏首页</h1>
          <img src={img2} alt="" />
          </a>

          <a onClick={this.toPath.bind(this, '/Test')}>
          <h1>进入地图</h1>
          <img src={img3} alt="" />
          </a>


          <a>
          {/* <h1>进入视频轮询</h1>
          <img src={img4} alt="" /> */}
          </a>


          {/* <a onClick={this.toPath.bind(this, '/VideoTurning')}>
          <h1>进入视频轮询</h1>
          <img src={img4} alt="" />
          </a> */}
        </div>

        
      </div>
    );
  }
}
