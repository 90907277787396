import React from "react";
import { Layout, Menu, PageHeader, Badge, Avatar, Popover, List,  Dropdown } from "antd";
import { Link, Redirect, Route, Switch, withRouter , HashRouter as Router} from "react-router-dom";
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import * as Icon from '@ant-design/icons';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
import "./homeMixingPlant.less";
import menuList from "@/router/menuConfig";
import routerList from "@/router/routerConfig";
import { setCollapse } from '@/redux/actions/setting';

// BIM 看板组件
import BIDataKanban2 from '@/pages/MixingPlant/BIDataKanban/BIDataKanban2'; // BI 数据看板
import TaskList from '@/pages/MixingPlant/TaskList/TaskList2'; // 任务单
import ProductionTransportation from '@/pages/MixingPlant/ProductionTransportation/ProductionTransportation'; // 砼生产情况和运输记录
import MixingBill from '@/pages/MixingPlant/MixingBill/MixingBill'; // 搅拌站账单
import MixSerch from '@/pages/MixingPlant/MixSerch/MixSerch';
import Invoice from '@/pages/MixingPlant/Invoice/Invoice';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;



// console.log(React.$getStorage('accessLogin'))
class HomeMixingPlant extends React.Component {
  state = {
    selectedMenuItem: [
       {
           path: '/BIDataKanban2',
           name: 'BI数据看板',
           component: BIDataKanban2
       },
       {
           path: '/TaskList',
           name: '任务单',
           component: TaskList 
       },
    //    {
    //     path: '/ProductionTransportation',
    //     name: '砼生产情况和运输记录',
    //     component: ProductionTransportation 
    //    },
    //    {
    //      path: '/MixingBill',  
    //      name: '账单/(日、月)',
    //      component: MixingBill
    //    },
    //    {
    //      path: '/Invoice',
    //      name: '发货单查询', 
    //      component: Invoice
    //     },
    //    {
    //     path: '/MixSerch',
    //     name: '配合比查询',
    //     component: MixSerch   
    //    }
    ],
    currentPath: '/BIDataKanban2',

    scale: 1,
    width: window.innerWidth,
    height: window.innerHeight,

  };

  componentDidMount() {

    // window.addEventListener('resize', this.setScale.bind(this))

    let currentPath =  React.$getStorage('currentPathHH')

    this.setRem()
    window.addEventListener('resize', this.setRem.bind(this));
    
    if(currentPath) {
        this.setState({
            currentPath: currentPath
        })
    }
}


 // 设置rem
 setRem() {

    this.setScale()
   
    var html = document.querySelector('html');

    var width = html.getBoundingClientRect().width;
    let height = html.getBoundingClientRect().height;

    html.style.fontSize =  width / 19.2 + 'px';
 }


setScale() {
    let _this = this
    debounce(() => {
        let scale= _this.getScale()
        console.log(scale)
        _this.setState({
            scale: scale
        })
    }, 500)()
}


getScale() {
    let ww=window.innerWidth/this.state.width
    let wh=window.innerHeight/this.state.height
    return ww<wh?ww: wh
}



  toNavigate(path) {
    this.setState({
        currentPath: path 
    })

    React.$setStorage('currentPathHH', path)

    let local = window.location
    
    window.location.href = local.origin + local.pathname + '#' +  path
    // this.props.history.push(path)
  }




  render() {
    return (
      <div className="scale-boxhh">
      <Layout className="layout hhlayout">

            <PageHeader className={'sitePageHeader'} title={'拌合站监测'}>
              <ul>
                {
                  this.state.selectedMenuItem.map((item, index) => {
                    return <li className={ item.path == this.state.currentPath?'active': ''}  key={index} onClick={this.toNavigate.bind(this, item.path)}>{item.name}</li>
                  })
                }
              </ul>
            </PageHeader> 

            <Router> 
                <Switch>
                    {/* <Route  exact path="/BIDataKanban2" component={BIDataKanban2}></Route> */}
                    {
                        this.state.selectedMenuItem.map((item, index) => {
                        return (<Route exact component={item.component} key={index} path={item.path} />)
                        })
                    }

                </Switch>
            </Router>    
      </Layout >
      </div>
    );
  }
}

export default connect()(withRouter(HomeMixingPlant));