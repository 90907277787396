import React from 'react'

import './downloadExcel.less'

import download from "downloadjs";

import { Button} from 'antd';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';




export const DownloadExcel = (
    class DownloadExcel extends React.Component {

        state = {
            visible: true,
            imageUrl: '',
            loading: false
        }

        static defaultProps = {
            type: "xls",
              // Json to download
              data: [],

              btnName: '导出',

              fields: false,

              exportFields: false,

              defaultValue: false,
              title: null,
              footer: null,
              name: "data.xls",
              fetch: null,
              meta: [], 
              worksheet: 'Sheet1',
              beforeGenerate:{
                type: Function,
              },
              beforeFinish:{
                type: Function,
              },
          }

        downloadFields() {
            if (this.props.fields) return this.props.fields;

            if (this.props.exportFields) return this.props.exportFields;
        }


        componentDidMount() {

        }

        async setDownLoadData() {

            let data = this.props.data
            let fetch = this.props.fetch


            if(fetch) {
                data = await this.props.fetch()
                
            }

            if (!data || !data.length) {
                return;
            }

            
            let json = this.getProcessedJson(data, this.downloadFields());

            

            return this.export(
                this.jsonToXLS(json),
                this.props.name,
                "application/vnd.ms-excel"
            );
        }


        jsonToXLS(data) {
            let xlsTemp =
                '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>br {mso-data-placement: same-cell;}</style></head><body><table>${table}</table></body></html>';
            let xlsData = "<thead>";
            const colspan = Object.keys(data[0]).length;
            let _self = this;

            if (this.props.title != null) {
                this.props.title.map(item => {
                  xlsData += '<tr><th '+ item.thStyle +' colspan="' + colspan + '">'+ item.html +'</th></tr>'
              })

            }
            

            
            xlsData += "<tr>";
            for (let key in data[0]) {
              let t = key.replace(/\d+_/, "")
              key = t;
              if(/\d+/g.test(key)) {
                xlsData += "<th><div style='width:20px;'>" +  key + "</div></th>";
              } else {
                xlsData += "<th>" + key + "</th>";
              }
            }
            xlsData += "</tr>";
            xlsData += "</thead>";


            xlsData += "<tbody>";
            data.map(function(item, index) {
                xlsData += "<tr>";
                for (let key in item) {
                let keyVal = item[key]
                if(keyVal == null || keyVal == undefined) {
                  keyVal = ' '
                }
  
                xlsData += "<td style=mso-number-format:\@ align='center'>" + _self.valueReformattedForMultilines(keyVal) + "</td>";
                }
                xlsData += "</tr>";
            });
            xlsData += "</tbody>";

            if (this.props.footer != null) {
                xlsData += "<tfoot>";

                this.props.footer.filter(item => {
                  if(Array.isArray(item)) {
    
                    xlsData += '<tr>'
                    item.forEach(val => {
                      if(val.colspan) {
                         xlsData += '<td   '+ val.thStyle +' colspan="' + val.colspan + '"  style=mso-number-format:\@>' + val.html + "</td>";
                      } else {
                         xlsData += '<td '+ val.thStyle +' colspan="' + colspan + '" style=mso-number-format:\@>' + val.html + "</td>";
                      }
                    })
    
                    xlsData += "</tr>";
    
                  } else {
                   if(item.colspan) {
                      xlsData += '<tr><th '+ item.thStyle +' colspan="' + item.colspan + '">'+ item.html +'</th></tr>'
                    } else {
                      xlsData += '<tr><th '+ item.thStyle +' colspan="' + colspan + '">'+ item.html +'</th></tr>'
                    }
                  }
              })

                // xlsData += this.parseExtraData(
                //   this.props.footer,
                //   '<tr><td colspan="' + colspan + '">${data}</td></tr>'
                // );
                xlsData += "</tfoot>";
              }

              return xlsTemp.replace("${table}", xlsData).replace("${worksheet}", this.props.worksheet);
        }


        parseExtraData(extraData, format) {
          
          let parseData = "";
          if (Array.isArray(extraData)) {
            for (var i = 0; i < extraData.length; i++) {
              parseData += format.replace("${data}", extraData[i]);
            }
          } else {
            parseData += format.replace("${data}", extraData);
          }
          return parseData;
        }


        valueReformattedForMultilines(value) {
          if (typeof(value)=="string") return(value.replace(/\n/ig,"<br/>"));
          else return(value);
        }


       async export(data, filename, mime) {
            let blob = this.base64ToBlob(data, mime);
            if(typeof this.beforeFinish === 'function')
              await this.beforeFinish();
            download(blob, filename, mime);
        }


        base64ToBlob(data, mime) {
            let base64 = window.btoa(window.unescape(encodeURIComponent(data)));
            let bstr = atob(base64);
            let n = bstr.length;
            let u8arr = new Uint8ClampedArray(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
          }



          getProcessedJson(data, header) {
            let keys = this.getKeys(data, header);
            

            let newData = [];
            let _self = this;
            data.map(function(item, index) {
                let newItem = {};
                for (let label in keys) {
                  let property = keys[label];

                  newItem[label] = _self.getValue(property, item);
                }
                newData.push(newItem);
              });
            
            return newData;
        }

        getKeys(data, header) {
            if (header) {
                return header;
            }
        
            let keys = {};
            for (let key in data[0]) {
                keys[key] = key;
            }
            return keys;
        }


        getValue(key, item) {
            const field = typeof key   !== "object" ? key : key.field;
            let indexes = typeof field !== "string" ? []  : field.split(".");
            let value   = this.defaultValue;
          
            if (!field)
                value = item;
            else if( indexes.length > 1 )
              value = this.getValueFromNestedItem(item, indexes);
            else
              value = this.parseValue(item[field]);
            
            if( key.hasOwnProperty('callback'))
              value = this.getValueFromCallback(value, key.callback);
            
            return value;
        }



        getValueFromNestedItem(item, indexes){
            let nestedItem = item;
            for (let index of indexes) {
              if(nestedItem)
                nestedItem = nestedItem[index];
            }
            return this.parseValue(nestedItem);
          }


        parseValue(value){
        return value || value === 0 || typeof value === 'boolean'
            ? value
            : this.defaultValue;
        }

        getValueFromCallback(item, callback){
            if(typeof callback !== "function")
              return this.defaultValue
            const value = callback(item);
            return this.parseValue(value);
          }
 

        render() {
            return <div >
                {
                  function () {
                    if(this.props.children) {

                      return (
                        <span onClick={this.setDownLoadData.bind(this)}>
                          { this.props.children}
                        </span>
                      )

                    } else {
                      return (
                        <Button type="primary" onClick={this.setDownLoadData.bind(this)} icon={<VerticalAlignBottomOutlined />}>
                            {this.props.btnName}
                        </Button>
                      )
                      
                    }


                  }.bind(this)()
                } 


               
            </div>
        }
    }
)

