import React, { Component } from 'react';


import "./Earth.less";


import * as THREE from './three.module.js';

import { OrbitControls } from './OrbitControls.js';

import img from '@/assets/img/images/earth2-9.jpg';
// import img2 from '@/assets/img/images/nationalFlag.png';
import img3 from '@/assets/img/images/halo-4.png';
import img4 from '@/assets/img/images/smallEarth.png';
import img5 from '@/assets/img/images/i.png';
import img6 from '@/assets/img/images/icon1.png'

import img7 from '@/assets/img/images/biaoji1.svg'
import img8 from '@/assets/img/images/biaoji2.svg'

import img9 from '@/assets/img/images/earth2-91.png' // 云

import project1Img1 from '@/assets/img/images/project1.svg'; 

import bannerImg from '@/assets/img/images/banner.png';

// import lensflare0 from '@/assets/img/images/lensflare0.png'

import {EchartsSankey} from './EchartsSankey/EchartsSankey'

import {ProjectOverview} from './ProjectOverview/ProjectOverview'



let rayCaster = new THREE.Raycaster();


let renderer, camera, scene, controls,  group, radius = 5, groupDots, groupLines, groupHalo, aGroup, yGroup, particleGroup;
group = new THREE.Group();
yGroup = new THREE.Group();
groupDots = new THREE.Group();
groupLines = new THREE.Group();
groupHalo = new THREE.Group();
aGroup = new THREE.Group();
particleGroup = new THREE.Group(); // 粒子容器
const materials = [];
let parameters = [
    [[ 1.0, 0.2, 0.5 ],  1 ],
    [[ 0.95, 0.1, 0.5 ], 2],
    [[ 0.90, 0.05, 0.5 ],  1.5 ],
    [[ 0.85, 0, 0.5 ],  0.1 ],
    [[ 0.80, 0, 0.5 ],  0.5 ]
];



const posArr = [{ "x": 0.5738958419746141, "y": -0.44114968930852216, "z": 4.9473255920938985 }, { "x": -0.9326350073394328, "y": 2.8399222968004114, "z": -4.00812091773949 }, { "x": 3.469198597393574, "y": 1.2295167303380952, "z": -3.3842206934036057 }, { "x": -2.4019084876611916, "y": -2.190220428765315, "z": 3.7991801866087123 }, { "x": -2.49363689878109, "y": -4.099696049856375, "z": 1.4050862307450966 }, { "x": -2.3729307780326305, "y": 2.840227787960863, "z": 3.3618901878497454 }, { "x": -2.0636200279017873, "y": 0.7444294629976027, "z": -4.493027615657812 }, { "x": 0.47725894517680106, "y": 2.4327372143508037, "z": -4.34212085796347 }, { "x": -2.4777001955161246, "y": -1.2092952460724242, "z": 4.171163716394502 }, { "x": -0.03915748918627658, "y": -0.008362945319338826, "z": 4.999839672648135 }, { "x": 1.5223738738260317, "y": -1.032865814102439, "z": -4.649254348640267 }, { "x": -0.26640112020426315, "y": -4.314854187280748, "z": 2.5121830716848077 }, { "x": -4.031470206741836, "y": -2.606648761952297, "z": -1.3973654511134501 }, { "x": 0.8544382232162094, "y": 1.5274953155132989, "z": 4.683662390031124 }, { "x": 3.0409624989238546, "y": 1.76433738825175, "z": -3.555230043268055 }, { "x": -4.721251023266457, "y": 1.2354922989397954, "z": -1.0878177947459262 }, { "x": 2.1518961827021106, "y": 3.891904027152385, "z": -2.285262755638206 }, { "x": 0.8501960736517479, "y": -2.851729208821255, "z": -4.018060123480341 }, { "x": 2.5631840141785176, "y": 4.263234820997851, "z": -0.5048926326370041 }, { "x": -0.4580143454812531, "y": -2.6523265200067385, "z": 4.213714144386437 }];

export default class Earth extends Component {
    
  btnRef = ''; // ref
  width = '';
  height = '';
  timer = ''
  state = {
    name: '',
    password: '',
    status: '1',
    clickShow: false,
    statusList: 1,

    clicked: false,

    posX: '',
    posY: '',
    moStatus: false,
    moveStatus: {
        ta1: false,
        ta2: false
    }
  }

  componentDidMount () {


    let Dom = this.btnRef;

   this.width = Dom.clientWidth
  this.height = Dom.clientHeight;

   
    this.initRenderer()
    this.initCamera();
    this.initScene();
    this.initLight();
    this.initObject();
    this.initControls();
    this.animate();
    
  }


 



  /**
 * @desc 3d球面取点
 * @param <number> radius ...
 * @param <number> a ...
 * @param <number> b ...
 * @return <object> x,y,z
 */
  getPos(radius, a, b) {
    var x = radius * Math.sin(a) * Math.cos(b);
    var y = radius * Math.sin(a) * Math.sin(b);
    var z = radius * Math.cos(a);
    return { x, y, z };
  }

 /**
 * @desc 随机设置点
 * @param <Group> group ...
 * @param <number> radius ...
 */
setRandomDot(group, radius) {
     posArr.map(pos => {
        var dotGeo = new THREE.SphereGeometry(0.1, 0.2, 0.2);
        var dotMater = new THREE.MeshPhongMaterial({ color: 'tomato' });
        var dotMesh = new THREE.Mesh(dotGeo, dotMater);
        // var pos = getPos(radius, Math.PI * 2 * Math.random(), Math.PI * 2 * Math.random());
        dotMesh.position.set(pos.x, pos.y, pos.z);
        group.add(dotMesh);
    })
}


// 添加线条
addLines(v0, v3) {
    // 夹角
    var angle = (v0.angleTo(v3) * 1.8) / Math.PI / 0.1; // 0 ~ Math.PI
    var aLen = angle * 0.4,
        hLen = angle * angle * 12;
    var p0 = new THREE.Vector3(0, 0, 0);

    // 开始，结束点
    // var v0 = groupDots.children[0].position;
    // var v3 = groupDots.children[1].position;

    // 法线向量
    var rayLine = new THREE.Ray(p0, this.getVCenter(v0.clone(), v3.clone()));

    // 顶点坐标
    var vtop = rayLine.at(hLen / rayLine.at(1).distanceTo(p0));

    // 控制点坐标
    var v1 = this.getLenVcetor(v0.clone(), vtop, aLen);
    var v2 = this.getLenVcetor(v3.clone(), vtop, aLen);

    // 绘制贝塞尔曲线
    var curve = new THREE.CubicBezierCurve3(v0, v1, v2, v3);
    var geometry = new THREE.Geometry();
    geometry.vertices = curve.getPoints(50);

    var material = new THREE.LineBasicMaterial({
        // color: '#FF0000',
        vertexColors: true
    });
    geometry.colors = curve.getPoints(50).map((item, index) => index > 25 ? new THREE.Color(0xFAE161) : new THREE.Color(0xFF0000))

    // Create the final object to add to the scene
    return {
        curve: curve,
        lineMesh: new THREE.Line(geometry, material)
    };
}


getVCenter(v1, v2) {
    let v = v1.add(v2);
    return v.divideScalar(2);
}

getLenVcetor(v1, v2, len) {
    let v1v2Len = v1.distanceTo(v2);
    return v1.lerp(v2, len / v1v2Len);
}


initRenderer() {
    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(this.width, this.height);


    const containerDom = this.btnRef;

    renderer.domElement.setAttribute('id', 'eachCanvas')

    containerDom.appendChild(renderer.domElement);
}

initCamera() {

    camera = new THREE.PerspectiveCamera(45, this.width / this.height, 1, 10000); 
    // camera.position.set(-2, -3, 10);	
    camera.position.set(-2, -3,  11)   
}

initScene() {
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000);
    // scene.fog = new THREE.Fog(0xa0a0a0, 200, 1000);
    window.scene = scene;

    // ground
    // var ground = new THREE.Mesh(new THREE.PlaneBufferGeometry(2000, 2000), new THREE.MeshPhongMaterial({ color: 0xff0000, depthWrite: false }));
    // ground.rotation.x = - Math.PI / 2;
    // ground.receiveShadow = true;
    // scene.add(ground);
}


initControls() {
    controls = new OrbitControls(camera, renderer.domElement);

    controls.setHandleMouseMovePan({
        clientX: -400,
        clientY: 240
    })

    // 如果使用animate方法时，将此函数删除
    // controls.addEventListener( 'change', render );
    // 使动画循环使用时阻尼或自转 意思是否有惯性
    controls.enableDamping = true;
    //动态阻尼系数 就是鼠标拖拽旋转灵敏度
    //controls.dampingFactor = 0.25;
    //是否可以缩放
    controls.enableZoom = true;
    

    //是否自动旋转
    controls.autoRotate = false;
    controls.autoRotateSpeed = 2;
    //设置相机距离原点的最远距离
    // controls.minDistance = 2;
    //设置相机距离原点的最远距离
    // controls.maxDistance = 1000;
    //是否开启右键拖拽
    controls.enablePan = false;
}


createPosition(lnglat) {
    let spherical = new THREE.Spherical;
    spherical.radius = radius;
    const lng = lnglat[0];
    const lat = lnglat[1];
    const theta = (lng + 90) * (Math.PI / 180);
    const phi = (90 - lat) * (Math.PI / 180);
    spherical.phi = phi;             //方位角
    spherical.theta = theta;         //倾斜角
    let position = new THREE.Vector3();
    position.setFromSpherical(spherical);
    return position;
}



initLight() {
    const ambientLight = new THREE.AmbientLight(0xcccccc, 1.1);
    scene.add(ambientLight);

    var directionalLight = new THREE.DirectionalLight(0xffffff, 0.2);
    directionalLight.position.set(1, 0.1, 0).normalize();
    var directionalLight2 = new THREE.DirectionalLight(0xff2ffff, 0.2);
    directionalLight2.position.set(1, 0.1, 0.1).normalize();
    scene.add(directionalLight);
    scene.add(directionalLight2);

    var hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 0.2);
    hemiLight.position.set(0, 1, 0);
    scene.add(hemiLight);

    var directionalLight = new THREE.DirectionalLight(0xffffff);
    directionalLight.position.set(1, 500, -20);
    directionalLight.castShadow = true;
    directionalLight.shadow.camera.top = 18;
    directionalLight.shadow.camera.bottom = - 10;
    directionalLight.shadow.camera.left = - 52;
    directionalLight.shadow.camera.right = 12;
    scene.add(directionalLight);
}


/**
 * @description 初始化添加物体
 */

initObject() {


    var globeTextureLoader = new THREE.TextureLoader();


    // 光环
    globeTextureLoader.load(img3, function (texture) {
        var geometry = new THREE.PlaneGeometry(14, 14);//矩形平面
        var material = new THREE.MeshLambertMaterial({
            map: texture,//给纹理属性map赋值
            transparent: true,
            side: THREE.DoubleSide//两面可见
        });//材质对象
        var mesh = new THREE.Mesh(geometry, material);//网格模型对象
        groupHalo.add(mesh);
    });

    // 小地球
    globeTextureLoader.load(img4, function (texture) {
        var geometry = new THREE.Geometry();//声明一个空几何体对象
        var p1 = new THREE.Vector3(-7, 0, 0);//顶点1坐标
        var p2 = new THREE.Vector3(7, 0, 0);//顶点2坐标
        geometry.vertices.push(p1, p2); //顶点坐标添加到geometry对象
        var material = new THREE.PointsMaterial({
            map: texture,//给纹理属性map赋值
            transparent: true,
            // side: THREE.DoubleSide,//两面可见
            size: 1, //点对象像素尺寸
        });//材质对象
        var points = new THREE.Points(geometry, material);//点模型对象
        groupHalo.add(points);//点对象添加到场景中

    });
    groupHalo.rotation.set(1.9, 0.5, 1);
    scene.add(groupHalo)

    // 地球
    globeTextureLoader.load(img, function (texture) {
        console.log(222222222222)
        var globeGgeometry = new THREE.SphereGeometry(radius, 50, 50);
        var globeMaterial = new THREE.MeshStandardMaterial({ map: texture });
        var globeMesh = new THREE.Mesh(globeGgeometry, globeMaterial);
        group.rotation.set(0.5, 3.1, 0.1)
        globeMesh.scale.set(1.2,1.2,1.2);
        group.add(globeMesh);

    }, function(error) {
        console.log(error)
    });

    // 加载云
    // 地球
    globeTextureLoader.load(img9, function (texture) {
        var globeGgeometry = new THREE.SphereGeometry(radius, 50, 50);
        var globeMaterial = new THREE.MeshStandardMaterial({ map: texture, transparent: true });
        let globeMesh = new THREE.Mesh(globeGgeometry, globeMaterial);

        globeMesh.scale.set(1.21,1.21,1.21);
        yGroup.add(globeMesh);

    }, function(error) {
        console.log(error)
    });
    



    // 增加两个点
    globeTextureLoader.load(img7, function (texture) {
            
        var spriteMaterial = new THREE.SpriteMaterial( { map: texture, color: 0xffffff} );

        var sprite = new THREE.Sprite( spriteMaterial );

        sprite.name = 1
        sprite.position.z = -5.1
        sprite.position.x = -1.0
        sprite.position.y = 3.2
        sprite.scale.set(0.2, 0.25, 0.2)

        group.add( sprite ); 

    });

    // 增加两个点
    globeTextureLoader.load(img8, function (texture) {
        var spriteMaterial = new THREE.SpriteMaterial( { map: texture, color: 0xffffff} );
        var sprite = new THREE.Sprite( spriteMaterial);
        sprite.name = 2
        sprite.position.z = -5.3
        sprite.position.x = -2.15
        sprite.position.y = 2.6
        sprite.scale.set(0.2, 0.25, 0.2)
        group.add( sprite ); 

    });


    // 增加粒子效果
    this.getParticleGroup(globeTextureLoader)


    scene.add(aGroup);
    scene.add(group);
    scene.add(yGroup);
    particleGroup.position.z = -600
    scene.add(particleGroup);
}


getParticleGroup(globeTextureLoader) {
    // const sprite1 =  globeTextureLoader.load(lensflare0,);
    const geometry = new THREE.BufferGeometry();
    const vertices = [];

    for ( let i = 0; i < 5000; i ++ ) {
        const x = Math.random() * 2000 - 1000;
        const y = Math.random() * 2000 - 1000;
        const z = Math.random()  *  100;
        vertices.push( x, y, z );
    }

    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

    for ( let i = 0; i < parameters.length; i ++ ) {

        const color = parameters[ i ][ 0 ];
        const size = parameters[ i ][ 1 ];
        materials[ i ] = new THREE.PointsMaterial( { size: size,  blending: THREE.AdditiveBlending, transparent: true } );
        materials[ i ].color.setHSL( color[ 0 ], color[ 1 ], color[ 2 ] );

        const particles = new THREE.Points( geometry, materials[ i ] );

        particles.rotation.x = Math.random() * 6;
        particles.rotation.y = Math.random() * 6;
        particles.rotation.z = Math.random() * 6;

         particleGroup.add(particles)
    }


    // console.log(particleGroup)

}

// onWindowResize() {
//     camera.aspect = innerWidth / innerHeight;
//     camera.updateProjectionMatrix();
//     this.renders();
//     renderer.setSize(innerWidth, innerHeight);
// }



onMouseMove(e) {
    this.initRay(e)
}

onMouseOver(e) {
    console.log(e)
    
}


initRay(event) {

    var mouse = new THREE.Vector2();
    
    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    
    

    // 创建射线投射器对象

    rayCaster.setFromCamera( mouse, camera );

    // // 返回射线选中的对象数组(第二个参数默认值是false，意为是否遍历图形内部的所有子图形)
    let intersects = rayCaster.intersectObjects(group.children, true);
    if (intersects.length > 0) {
        // 射线拾取的首个对象
        let currObj = intersects[0];

        if(currObj.object.name != '') {
            if(this.state.clickShow) {
                
            } else {
                var canvasHeight = document.querySelector('#eachCanvas').height

                console.log(canvasHeight)
                // console.log(document.querySelectorAll('canvas')[1])
                // console.log(document.querySelector('#container canvas'))


                this.setState({
                    clickShow: true,
                    posX: event.clientX,
                    posY: canvasHeight -  event.clientY - 30
                })


            }

        } else {

            if(!this.state.moStatus) {
                this.setState({
                    clickShow: false,
                })
            }

            
        }


        // 改变被拾取对象的材质颜色(随机)
        // let currcolor = `rgb(${Math.floor(Math.random() * 256).toString()},${Math.floor(Math.random() * 256).toString()},${Math.floor(Math.random() * 256).toString()})`
        // currObj.object.material.color.set(currcolor);
    }
}


renders() {
    renderer.clear();
    renderer.render(scene, camera);
    // renderer.setScissorTest(false);
}

animate() {

    let _this = this
    window.requestAnimationFrame(() => {
        const time = Date.now() * 0.00005;


        if (controls) controls.update();
        groupHalo.rotation.z = groupHalo.rotation.z + 0.01;
        
        
        let params = controls.getHHAngle()
        
        if(!_this.state.clickShow) {
        
            if(params.x != 0 || params.y != 0) {
                group.rotation.y += (params.x/ 500)
                // group.rotation.x += (params.y/ 500)
            } else {
                // group.rotation.y = group.rotation.y + 0.001;
            }
            
        }


            
        groupDots.rotation.y = groupDots.rotation.y + 0.001;
        groupLines.rotation.y = groupLines.rotation.y + 0.001;
        aGroup.rotation.y = aGroup.rotation.y + 0.001;
        yGroup.rotation.y = yGroup.rotation.y + 0.001;

        for ( let i = 0; i < materials.length; i ++ ) {

            const color = parameters[ i ][ 0 ];

            const h = ( 360 * ( color[ 0 ] + time ) % 360 ) / 360;
            materials[ i ].color.setHSL( h, color[ 1 ], color[ 2 ] );
            
            materials[ i ].size = materials[ i ].size * (Math.random() * 1);

        }
  
        
        

        _this.renders();
        _this.animate();
    });
}

toPath(e) {
    console.log(e)
    this.setState({
        status: e
    })

    // console.log(this.state.status)
}



PopoverMouseMove() { // 移出事件
    this.state.moveStatus.ta1 = false
    this.setState({
        moveStatus: this.state.moveStatus
    })

    
    let timer2 = setTimeout(() => {
        
        if(this.state.moveStatus.ta1 == false && this.state.moveStatus.ta2 == false){
            this.setState({
                moStatus:false
            })
        }
        clearTimeout(timer2)
    }, 100)
}



PopoverMouseOver() {
    this.state.moveStatus.ta1 = true
    this.setState({
        moveStatus: this.state.moveStatus
    })


    this.setState({
        moStatus:true
    })



}

PopoverMouseOver2() {
   
    this.state.moveStatus.ta2 = true
    this.setState({
        moveStatus: this.state.moveStatus
    })

}

PopoverMouseMove2() {
    this.state.moveStatus.ta2 = false
    this.setState({
        moveStatus: this.state.moveStatus
    })
    let timer2 = setTimeout(() => {
        if(this.state.moveStatus.ta1 == false && this.state.moveStatus.ta2 == false){
            this.setState({
                moStatus:false
            })
        }
        clearTimeout(timer2)
    }, 100)

}


 
clickMe(e) {

    this.setState({
        statusList: e,
        clickShow: false
    })
}





  render() {

    return (
      <div id="container"  className="container Earth" ref={(el) => this.btnRef = el} onMouseMove={(e) => this.onMouseMove(e)}>
        <div className="head">
            <img className="iconImg" src={img5} alt=""/>

            <div className="bannerImg"><img src={bannerImg} alt="" /></div>
        </div>

        <div className="foot">

            <ul>
                <li onClick={() => this.toPath('0')}><img src="" alt=""/> <div>工程概览</div></li>
                <li onClick={() => this.toPath('1')}><img src="" alt=""/> <div>人员监管</div></li>
                <li onClick={() => this.toPath('2')}><img src="" alt=""/> <div>质量监管</div></li>
                <li onClick={() => this.toPath('3')}><img src="" alt=""/> <div>安全监管</div></li>
                <li onClick={() => this.toPath('4')}><img src="" alt=""/><div>工程进展</div></li>
            </ul>
        </div> 

        
   
        <div className="pos" >
            <div  style={{display: this.state.status === '0'? 'block': 'none', zIndex: 100}}>
                {
                    function() {
                        if(this.state.status === '0') {
                            return (
                                <ProjectOverview  /> 
                            )
                        }
                    }.bind(this)()
                }                
            </div>

            <div  style={{display: this.state.status === '1'? 'block': 'none', zIndex: 100}}>
            <h1>人员监管</h1> 
            <p className="English">Personnel supervision</p> 

            <div className="smallTitle">
                <img src={img6} alt=""/>
                重点人员监管
            </div>

            <div className="lv">

                <ul>
                    <li>
                        <p>今日到岗率</p>
                        <p className="color1">78.9%</p>
                    </li>
                    <li>
                        <p>昨日到岗率</p>
                        <p className="color2">78.9%</p>
                    </li>
                    <li>
                        <p>上月全勤率</p>
                        <p className="color3">78.9%</p>
                    </li>
                </ul>

            </div>

            <div className="cub">
                <EchartsSankey/>
                {/* <ul>
                    <li>
                        <p>工程现场人员</p>
                        <p>789000</p>
                    </li>
                    <li className="p2">
                        <div>
                            <p>管理人员</p>
                            <p>789000</p>
                        </div>
                        <div>
                            <p>未经教育</p>
                            <p>789000</p>
                        </div>
                        <div>
                            <p>劳务人员</p>
                            <p>789000</p> 
                        </div>
                        <div>
                            <p>三级教育</p>
                            <p>789000</p>
                        </div>
                    </li>
                </ul> */}
            </div> 


            <div className="Statistics">
                <h2>项目人员统计</h2>

                <ul>
                    <li>
                        <p>1.滇中引水石鼓水源工程</p>
                        <div>5000</div>
                        <div className="progress"></div>
                    </li>
                    <li>
                        <p>2.滇中引水石鼓水源工程</p>
                        <div>5000</div>
                        <div className="progress"></div>
                    </li>
                    <li>
                        <p>3.滇中引水石鼓水源工程</p>
                        <div>5000</div>
                        <div className="progress"></div>
                    </li>

                    <li>
                        <p>4.滇中引水石鼓水源工程</p>
                        <div>5000</div>
                        <div className="progress"></div>
                    </li>
                    <li>
                        <p>5.深圳轨道交通十二号线</p>
                        <div>5000</div>
                        <div className="progress"></div>
                    </li>
                </ul>

            </div>  


            </div>
        </div>

         <div className="Popover" onMouseOver={() => this.PopoverMouseOver()}  onMouseOut={()=> this.PopoverMouseMove()}  style={{display:this.state.clickShow?'block':'none', left: this.state.posX, bottom: this.state.posY}}>
                <div className="province">云南省</div>
                <ul  onMouseOut={()=> this.PopoverMouseMove2()}  onMouseOver ={this.PopoverMouseOver2.bind(this)}>
                    <li className={this.state.statusList ==1?'liColor': ''}  onClick={() => this.clickMe(1)}> <img src={project1Img1} alt="" /> <p>滇中引水石鼓水源工程</p></li>
                    <li className={this.state.statusList ==2?'liColor': ''} onClick={() => this.clickMe(2)}> <img src={project1Img1} alt="" /> <p>滇中引水大理二标工程</p></li>
                </ul>
         </div>           

      </div>
    );
  }
}



