import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import debounce from 'lodash.debounce'
import './PersonnelSupervision.less'

import videoPlay from '@/assets/img/videoReturning/2.png';
import img1 from '@/assets/img/videoReturning/1.png';
import img5 from '@/assets/img/earth/5.png';
import * as echarts from 'echarts';




function PersonnelSupervision() {


    const [echartStatus, setEchartStatus] = useState(true)


    useEffect(() => {



        // 设置图形
        setTimeout(() => {
            setEchart()
            setEchart2()
        }, 200)
        
        return () => {
            
        }
    }, []);


    // 设置图形
    function setEchart() {
        let chartDom = document.getElementById('barChart');
        let myChart  = echarts.init(chartDom);
        let option;


        option = {
            xAxis: {
                type: 'category',
                data: ['滇中引水石鼓水源工程', '滇中引水石大理1段2标', '郑州轨迹交通8号线', '成都轨迹交通19号线', '深圳轨迹交通12号线'],
                axisLabel: {  
                    interval:0,  
                    rotate:-20
                }, 
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: false
                }
            },

            grid: {
                left: '1%',
                right: '4%',
                bottom: '20%',
                top: '10%',
                containLabel: true
            },

            series: [{
                data: [120, {
                    value: 200,
                    itemStyle: {
                        color: '#5B76F7'
                    }
                }, 150, 80, 70],
                type: 'bar',
                barWidth: 20,
                color: ['#58CDFE'],
            }]
        };
        
    
        option && myChart.setOption(option);  

    }


    function setEchart2() {
        let chartDom = document.getElementById('roundChart');
        let myChart = echarts.init(chartDom);


        var option;

        option = {
            
            legend: {
              top: '5%',
              left: 'center'
            },
            
            series: [
              {
                name: 'Access From',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: false,
                    fontSize: '40',
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                itemStyle:{
                    borderWidth:5, //设置border的宽度有多大
                    borderColor:'#0A182C',
                },    
                data: [
                  { value: 1048, color: '#57CCFF'},
                  { value: 100,  color: '#BDBDBD'},
                ],
                color: ['#57CCFF', '#BDBDBD'],
              }
              
            ]
          };

        option && myChart.setOption(option);

    }


  return (
     
    <div className='PersonnelSupervision'> 

                <div className="body flex">
                    
                    <div className="leftData" >
              

                    </div>

                    <div className="rightData">

                    <div className="title PersonnelSupervisionFistTitle">
                        <p>
                            <img src={img1} alt="" />   项目人员统计
                        </p>

                        <div className="PersonnelSupervisionPersonTotal">
                            
                            <div className="borderA">
                                <div className="setTitle">
                                   <p>在线总人数</p>  
                                    <p>10500 <i>人</i></p> 
                                </div>
                                <div className="type">

                                    <p>
                                     <b></b>   劳务 <i>19410</i>
                                    </p>
                                    <p>
                                     <b></b>  管理 <i>120</i>
                                    </p>
                                </div>

                                <div className="echartsPerson" id="roundChart">

                                </div>
                                <div className="setIMG">
                                    <img src={img5} alt="" />    
                                </div>
                            </div>
                            


                        </div>

                    </div>

                    <div className="title">
                            <p>
                            <img src={img1} alt="" />   实名制人数统计
                            </p>
                            <div className="PersonnelSupervisionPerson">
                                <p>
                                    <div className="name">
                                         <b>1</b>       
                                        滇中引水石鼓水源工程    
                                    </div>
                                    
                                    <i>2316</i>
                                </p>
                                <p>
                                    <div className="name">
                                         <b>2</b>       
                                        滇中引水石大理1段2标    
                                    </div>
                                    
                                    <i>1290</i>
                                </p>
                                <p>
                                    <div className="name">
                                         <b>3</b>       
                                        郑州轨迹交通8号线    
                                    </div>
                                    
                                    <i>1125</i>
                                </p>
                                <p>
                                    <div className="name">
                                         <b>4</b>       
                                        成都轨迹交通19号线 
                                    </div>
                                    
                                    <i>961</i>
                                </p>
                                <p>
                                    <div className="name">
                                         <b>5</b>       
                                        深圳轨迹交通12号线  
                                    </div>
                                    
                                    <i>758</i>
                                </p>
                            </div>


                            {/* <ul className="setUl">
                                <li>佩戴安全帽 <span>11.6 %</span></li>
                                <li>反光衣 <span>12.7 %</span></li>
                                <li>陌生拜访 <span>23.0 %</span></li>
                                <li className="colorActive">滞留 <span>30.6 %</span></li>
                                <li>其他 <span>21.92 %</span></li>
                            </ul> */}

                        </div>



                        <div className="title">
                            <p>
                              <img src={img1} alt="" /> 劳务今日出勤数量

                               <span>人</span>         
                            </p>

                           {
                               function() {
                                   if(echartStatus) {
                                    return (
                                        <div id="barChart" className="echartBar">
                                        </div>  
                                    )

                                         
                                   } else {
                                    return (
                                        <span></span>
                                    )
                                   }
                               }()
                           }

                              
                            
                        </div>
                        
                    </div>
                
                </div>    

    </div >
  )
}

export default withRouter(PersonnelSupervision);