import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';

import MonitoringDetails from './monitoringDetails';



function EnvironmentalMonitoring() {



 

  return (
    <div className="environmentalMonitoring">
     
      <MonitoringDetails  />          
      
    </div >
  )
}

export default withRouter(EnvironmentalMonitoring);