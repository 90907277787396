import React from "react";

import {Button, Table, message, Image, Select, Input, DatePicker} from "antd";
// 添加furon
import { SearchOutlined} from '@ant-design/icons';

import './machineRecord.less';





import {formatTime} from '@/utils/util'

import img from '@/assets/img/newReviseImg/imgAndIcon/jiaoyu.jpg';

import {main2} from './echarts';

const {Option} =  Select;

const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 16},
};


export default class MachineRecord extends React.Component {
  FormSbmitRef = null
  imageUrl = process.env.REACT_APP_IMG_URL
  state = {
    status: 0,
    loading: false,
    tableData: [],
    pagination: {
        current: 1,
        pageSize: 10,
        total: 200,
        onShowSizeChange: function(current, pageSize) {
            this.state.pagination.pageSize = pageSize;
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        onChange: function(current) {
            this.state.pagination.current = current
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        showTotal: function () {  //设置显示一共几条数据
            return '共 ' + this.state.pagination.total + ' 条'; 
        }.bind(this),
        showQuickJumper: true,
        },
    params : {
        lengthLimit: 10,
        offsetLimit: 0,
        deviceKey: null,
        name: null,
        nameIdCard: null,
        status: null,
        addTimeStart: null,
        addTimeStop: null 
    },
    cargoNo: null,
    machineDeviceList: [],
    deviceInspectionTypeList: [],


    count: {
        commodity: 0,
        onlineDevice: 0
    },
  } 
  


 columns = [
    {
        title: "序号",
        dataIndex: 'title',
        align: 'center',
        render: (_, item, index) => {
          return (
            <p>{index + 1}</p>
          )
        }
      },
    {
      title: '商品名称',
      dataIndex: 'nameCommodity',
      align: 'center',
    },
    {
       title: '商品图片',
       dataIndex: 'img',
       align: 'center',
       render: (_) => {
            return (
                <Image
                width={30}
                src={this.imageUrl + _}
                />
            )
        }
    },
    {
        title: '时间',
        dataIndex: 'addTime',
        align: 'center',
        render: (_) => {
            let time = null
            if(!_) {
                time = ''
            } else {
                time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')
            }
            return (
                <p>{time}</p>
            )
        }
    },
    {
        title: '姓名',
        dataIndex: 'nameIdCard',
        align: 'center'
    },
    {
        title: '兑换人图片',
        dataIndex: 'avatarKeyInfo',
        align: 'center',
        render: (_) => {
            return (
                <Image
                width={30}
                src={this.imageUrl + _}
                />
            )
        }
    },
    {
        title: '部门',
        dataIndex: 'teName',
        align: 'center'
    },
    {
        title: '兑换状态',
        dataIndex: 'status',
        align: 'center',
        render: (_) => {
            return (
                <p className={'defindStatus status' + _}>{_==3? '兑换成功': _==2?'兑换中':'兑换失败'} </p>
            )
        }
    },   

  ]


  handleTableChange(pagination) {
    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize
    this.setState({
      params: this.state.params,
      pagination: pagination
    })

    // // 执行更新列表
    this.getList()
  }


  

  componentDidMount() {
    this.getList()
    this.getMachineDeviceAll()

    
    this.getStatisticsVending()
  }



 
  // 获取售货机统计
 getStatisticsVending() {
    const projectId = React.$getStorage('projectInfo').id
    React.$axios.post('statisticsVending', {projectId: projectId}).then(res => {
        this.setState({
            count: res.data.count
        })

        main2(res.data.exchange);
    })
 }

  


  getMachineDeviceAll() {
      React.$axios.post('machineDeviceAll').then(res => {
        this.setState({
            machineDeviceList: res.data.machineDeviceAll
        })

        // this.state.params.deviceKey = res.data.machineDeviceAll[1].deviceKey
        // this.getList();
      })
  }

  

  getList(type = false) {
    this.setState({
        loading: true
    })

    if(type) {
        this.state.params.offsetLimit = 0;
        this.state.pagination.current = 1;
        this.setState({
            params: this.state.params,
            pagination: this.state.pagination
        })
    }


    React.$axios.post('machineDeviceList', this.state.params).then(res => {
         this.state.pagination.total = res.data.countList
        this.setState({
            tableData: res.data.list,
            loading: false,
        })

    })
  }




  clierFormSbmitRef() {
    if(!this.FormSbmitRef) {
        setTimeout(() => {
          
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
  }




 

  // 去查询
  toSerch() {
    this.getList(true)
  }

  handleOk() {
    this.FormSbmitRef.submit()
  }



  handleCancel() {
    this.setState({
        isModalVisible:false
    })
  }

  // 改变时间
  onChangeTime(val) {
    this.state.params.addTimeStart = parseInt((+val[0]._d)/1000) 
    this.state.params.addTimeStop = parseInt((+val[1]._d)/1000) 
  }

  // 商品名称

  setInput(str,val) {
    this.state.params[str] = val.target.value
  }

  onChangeT(type,value) {
    this.state.params[type] = value
  }

  render() {
    return (
      <div className="machineCommodityAll">

                <div className="head">
                            <ul>
                                <li>
                                    <h1>车辆在场情况</h1>
                                    <div className="number">
                                        <div>
                                            <h2>{this.state.count.onlineDevice}</h2>
                                            <p>在线超市</p> 
                                        </div>

                                        <div>
                                            <h2 className="blue">{this.state.count.commodity}</h2>
                                            <p>商品种类</p> 
                                        </div>
                                            
                                    </div>

                                </li>
                                <li>
                                    <h1>兑换次数 <span>（次）</span></h1>
                                    <div id="main3" className="echarts">

                                    </div>

                                </li>
                                <li>
                                    
                                    <img src={img} alt="" /> 

                                </li>
                            </ul>
                        </div>


        <div className="warp1">
                <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>设备:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'deviceKey')}
                                >
                                    <Option value=" ">全部</Option>    
                                    {
                                        this.state.machineDeviceList.map(item => {
                                            return (
                                                <Option value={item.deviceKey}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                        </li>
                        <li><span>商品名称:</span> 
                            <Input style={{ width: 200, height:30, marginRight:20, }} onChange={this.setInput.bind(this, 'name')}  placeholder="请输入商品名称" />
                        </li>

                        <li><span>姓名:</span> 
                            <Input style={{ width: 200, height:30, marginRight:20, } } onChange={this.setInput.bind(this, 'nameIdCard')}  placeholder="请输入商品名称" />
                        </li>

                        <li><span>兑换状态:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'status')}
                                >
                                    <Option value=" " key="0">全部</Option>    
                                    <Option value="2" key="1">兑换中</Option>
                                    <Option value="3" key="2">兑换成功</Option>
                                    <Option value="4" key="3">兑换失败</Option>
                                </Select>
                        </li>    

                        <li><span>日期: </span>

                            <RangePicker style={{ width: 250, height:30, marginRight:20,  }} onChange={this.onChangeTime.bind(this)} />       
                        </li>

                            <Button  className="btn" onClick={this.toSerch.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>
                    </ul>
                </div>


                <div className="table">
                <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id}   columns={this.columns} dataSource={this.state.tableData}  />

                </div>
            
            </div>

      </div>

    );
  }
}
