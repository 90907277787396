import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import './safeEducation.less'

import {Button} from 'antd';


import EducationOverview from './EducationOverview/educationOverview'
import EducationLog from './EducationLog/educationLog'
import ExamLog from './ExamLog/examLog'
import QuestionBank from './QuestionBank/QuestionBank'


function SafeEducation() {
  const navList = ['教育概览', '教育记录', '考试记录', '题库管理']
  const [navIndex, setNavIndex] = useState(null);
  const [childBtn, setchildBtn] = useState([]);
  useEffect(() => {
    let childBtn = React.$getStorage('menuchildBtn');
    for(let i = 0; i < childBtn.length; i++) {
      if(childBtn[i].show) {
        let item = childBtn[i];
        toSelecteNav(item.index);
        break;
      }
    }

    setchildBtn(childBtn)
  }, [])
  function toSelecteNav(index) {
    setNavIndex(index)
  }
  return (
    <div className="diy-container safeEducation">
      <ul className="nav-list">
        {
          childBtn.map(((item, index) => {
            if(item.show) {
              return <Button  type={navIndex === item.index? 'primary': ''}  key={index} className="setBtn" onClick={() => toSelecteNav(item.index)}>{item.name}</Button>
            }
            
            
            //<li className={navIndex === index ? 'active' : ''} key={index} onClick={() => toSelecteNav(index)} >{item}</li>
          }))
        }
      </ul>
      {navIndex === 0 && <EducationOverview />}
      {navIndex === 1 && <EducationLog />}
      {navIndex === 2 && <ExamLog />}
      {navIndex === 3 && <QuestionBank />}
    </div>
  )
}

export default withRouter(SafeEducation);