import React from "react";


import {Button, Table,  Modal, Select, message, Form, Input} from "antd";

import './Authority.less';

const {Option} =  Select;

const layout = {
    labelCol: { span: 4 },
  };



export default class Authority extends React.Component {
  
    columns = [
        {
            title: '名称',
            dataIndex: 'newName',
            // align: 'center',
        },
        {
            title: '路径',
            dataIndex: 'newPath',
            // align: 'center',
        },

        {
            title: '状态',
            dataIndex: 'enable',
            align: 'center',
            render:(_) => {
                let text = null;
                if(_ == 2) {
                    text = '启用'
                } else {
                    text = '禁用'
                }
                return (
                    <p className={'setColor' + _}>{text}</p>
                )
            }
        },

        {
            title: 'boss功能',
            dataIndex: 'bossFeatures',
            align: 'center',
            render:(_) => {
                let text = null;
                if(_ == 2) {
                    text = '是'
                } else {
                    text = '否'
                }
                return (
                    <p className={'setColor' + _}>{text}</p>
                )
            }
        },

        {
            title: '权重',
            dataIndex: 'weigh',
            align: 'center'
        },

        {
            title: '是否为菜单',
            dataIndex: 'menu',
            align: 'center',
            render:(_) => {
                let text = null;
                if(_ == 2) {
                    text = '是'
                } else {
                    text = '否'
                }
                return (
                    <p className={'setColor' + _}>{text}</p>
                )
            }
        },

        {
          title: '操作',
          dataIndex: 'address',
          align: 'center',
          width: 200,
          render:(_,item) => {
            return (
              <div className="operation">
                  <p onClick={this.clickFun.bind(this, item)} className="centp">编辑</p>
              </div>
            )
          }
        },
      ]


    FormSbmitRef = ''  

    state = {
        herfEarth: '',
        status: 1,
        loading: false,
        tableData:[],

        pagination: {
            current: 1,
            pageSize: 10,
            total: 200,
            onShowSizeChange: function(current, pageSize) {
                this.state.pagination.pageSize = pageSize;
                this.handleTableChange(this.state.pagination)
            }.bind(this),
            onChange: function(current) {
                this.state.pagination.current = current
                this.handleTableChange(this.state.pagination)
            }.bind(this),
            showTotal: function () {  //设置显示一共几条数据
                return '共 ' + this.state.pagination.total + ' 条'; 
            }.bind(this),
            showQuickJumper: true,
            },


        FormParams: {
            pid: null,
            path: null,
            name: null,
            icon: null,
            menu: null,
            weigh: null,
            enable: null,
            bossFeatures: null
        },
        roleAllList: [],
    
        isModalVisible: false,
        projectId:null
      }

  componentDidMount() {
        this.getRule()
  }

  handleTableChange () {
      
  }



  getRule() {
       this.setState({
           loading: true
       }) 

      React.$axios.post('ruleAll').then(res => {
        
        //
        let result = res.data.rules

        result.map(item => {
            item.newName = null
            item.newPath = null
            item = this.changeData(item)
        })

        this.setState({
            tableData: res.data.rules,
            loading: false
        })
      })
  }

  changeData(data) {
      switch (data.level) {
          case 0:
              data.newName = data.name;
              data.newPath = data.path;
              break;
          case 1:
              data.newName = `| - - ${data.name}`;
              data.newPath = `| - - ${data.path}`;
              break;
          case 2:
              data.newName = `| - - - - - - ${data.name}`;
              data.newPath = `| - - - - - - ${data.path}`;
              break;
        }
        return data
  }


  
  changeStatus(type) {
    this.setState({
        status: type
    })
  }

  addAdmin() {
    for(let i in this.state.FormParams) {
        this.state.FormParams[i] = null
    }
    this.state.FormParams.projectId = this.state.projectId;
    if(this.state.FormParams.id) {
        delete this.state.FormParams.id
        delete this.state.FormParams.icon
    }

    if(!this.FormSbmitRef) {
        console.log(this.FormSbmitRef)
        setTimeout(() => {
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }



    this.setState({
        isModalVisible: true,
        FormParams: this.state.FormParams
    })
  }

  clickFun(item) {
    for(let i in this.state.FormParams) {
        this.state.FormParams[i] = item[i]
    }

    this.state.FormParams.id = item.id
    this.state.FormParams.icon = item.icon
    let _this = this
    if(!this.FormSbmitRef) {
        
        setTimeout(() => {
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }




    this.setState({
        isModalVisible: true,
        FormParams: this.state.FormParams
    })
  }

  handleOk() {
    this.FormSbmitRef.submit()
  }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  onFinish(value) {
    for (let i in value) {
        this.state.FormParams[i] = value[i]
    }

    React.$axios.post('ruleEditSave', this.state.FormParams).then(res => {
        if(res.code == 20000) {
            message.success('保存成功')
            this.handleCancel()

            this.getRule()
        } 
    }) 


  }

  onChangeSelect() {

  }



  render() {
    return (
      <div className="Authority">
            <div className="btn">
                <Button type="primary" onClick={this.addAdmin.bind(this)}>
                   添加权限
                </Button> 
            </div>


            <Table  align="center" bordered rowClassName="editable-row"
                    loading={this.state.loading} rowKey={record => record.id} pagination={this.state.pagination}  columns={this.columns} dataSource={this.state.tableData}  />


            <Modal title="添加" width="40%" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                                    <Form
                                        onFinish={this.onFinish.bind(this)}
                                        ref={(el) => this.FormSbmitRef = el}
                                        {...layout}
                                        initialValues = {this.state.FormParams}
                                    >
                                        <Form.Item label="顶级规则" name="pid" rules={[{ required: true }]} >
                                            <Select
                                                showSearch
                                                style={{ width: 200,  marginRight:20,  }}
                                                placeholder="请选择"
                                                optionFilterProp="children"
                                            >
                                                <Option value={0}>顶级菜单</Option>
                                            {
                                                this.state.tableData.map(item => {
                                                    return (
                                                        <Option value={item.id}>{item.newName}</Option>
                                                    )
                                                })
                                            }    
                                            
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label="状态" name="enable" rules={[{ required: true }]} >
                                            <Select
                                                showSearch
                                                style={{ width: 200,  marginRight:20,  }}
                                                placeholder="请选择"
                                                optionFilterProp="children"
                                            >

                                            <Option value={2}>启用</Option>
                                            <Option value={3}>禁用</Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label="是否为菜单" name="menu" rules={[{ required: true }]} >
                                            <Select
                                                showSearch
                                                style={{ width: 200,  marginRight:20,  }}
                                                placeholder="请选择"
                                                optionFilterProp="children"
                                            >

                                            <Option value={2}>是</Option>
                                            <Option value={3}>否</Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label="boos功能" name="bossFeatures" rules={[{ required: true }]} >
                                            <Select
                                                showSearch
                                                style={{ width: 200,  marginRight:20,  }}
                                                placeholder="请选择"
                                                optionFilterProp="children"
                                            >

                                            <Option value={2}>是</Option>
                                            <Option value={3}>否</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="地址" name="path" >
                                        <Input placeholder="请输入地址" />
                                        </Form.Item>

                                        <Form.Item label="名称" name="name" >
                                        <Input placeholder="请输入名称" />
                                        </Form.Item>

                                        {/* <Form.Item label="图标" name="name"  rules={[{ required: true }]}>
                                        <Input placeholder="请输入名称" />
                                        </Form.Item> */}

                                        <Form.Item label="权重" name="weigh" >
                                        <Input placeholder="请输入权重" />
                                        </Form.Item>



                                    </Form>

                    </Modal> 



      </div>
    );
  }
}
