import React from "react";
import './VehiclePositioning.less';

import {Button, DatePicker, Select} from "antd";

import moment from 'moment';



import img2 from '@/assets/img/car/2.png';
import img3 from '@/assets/img/car/3.png';
import img4 from '@/assets/img/car/4.png';
import img5 from '@/assets/img/car/5.png';
import car from  '@/assets/img/car/car.png';


import img22 from '@/assets/img/car/22.png';
import img23 from '@/assets/img/car/23.png';
import img24 from '@/assets/img/car/24.png';
import img25 from '@/assets/img/car/25.png';



const { RangePicker } = DatePicker;
const { Option } = Select;

let imgWarp = {
    2: img2,
    3: img3,
    4: img5,
    5: img4,
    6: img4
}

let imgWarp2 = {
    2: img22,
    3: img23,
    4: img25,
    5: img24,
    6: img24
}


let CarMsgList = [
    {
        id: '',
        name: '全部'
    },
    {
        id: 2,
        name: '办公车'
    },
    {
        id: 3,
        name: '罐车'
    },
    {
        id: 4,
        name: '渣土车'
    },
    {
        id: 5,
        name: '洒水车'
    },
    {
        id:6,
        name: '扫地车'
    }
]

function getCarType(type) {
    let car = type == 2?'办公车': type == 3? '泵车': type == 4? '渣土车': type==6?'扫地车': '洒水车'
    return car
}



const TMap = window.TMap
let map = null

export default class VehiclePositioning extends React.Component {
 
    state = {
        status: null,
        index: 0,
        VehicleParams: {
          logic: 4,
          pType: 3,
          type: ''
        },
        curentItem: {},
        curentIndex: 0,
        curentStatus: false,
        VehicleList: [],
        vehiclePositioningStatisticsParams:{
          offline: 0,
          online: 0,
          still: 0,
        },
        
        carIndex: 0,
        CarMsgListIndex: 0,
        markerList: [],
        polylineLayerList: [],
        uploadStatus: false,
        changeTimeIndex: 0,
        clickTrajectoryStatus: false,
        
        defineTime: {
            time: null,
            time2: null
        },
  
        startTime: '',
        endTime: '',
        projectId: '',
        childBtn: []
    }

  

    componentDidMount() {
         let id = React.$getStorage('projectInfo').id
     
         let childBtn = React.$getStorage('menuchildBtn');
         for(let i = 0; i < childBtn.length; i++) {
           if(childBtn[i].show) {
             let item = childBtn[i];
            //  this.changeStatus(item.index);
             break;
           }
         }
       
     
         this.setState({
           childBtn: childBtn
         })
        

     
         this.state.projectId = id
     
        
     
         this.getVehicleList()
         this.getVehiclePositioningStatistics(id)
     

       }

     
       // 销毁实列
       componentWillUnmount() {
           map = null
       }
     
       
       getVehiclePositioningStatistics(id) {
         React.$axios.post('vehiclePositioningStatistics', {projectId: id}).then(res => {
             this.setState({
                 vehiclePositioningStatisticsParams: res.data
             })
         })
       }
     
       changeCarMsg(index, item) {
         this.setState({
             CarMsgListIndex: index,
             carIndex: 0
             // uploadStatus: true
         })


         this.state.VehicleParams.type = item.id
     
     
     
         this.getVehicleList()
       }
     
       getVehicleList() {
         this.state.VehicleParams.projectId = this.state.projectId;
     
     
         React.$axios.post('vehicleList2', this.state.VehicleParams).then(res => {
             this.setState({
                 VehicleList: res.data.list,
                 // carIndex: 0
             })
     
             this.initMap(res.data.list[0])
         })
       }
     
     
       initMap(item) {
             if(item == null) {
                 item = {}
                 item.pLat =  1
                 item.pLbd = 1
                 
             }
             // 如果没有创建 实列则创建实列
             if(!map) {
                 map = new TMap.Map(document.getElementById('container'), {
                     center: new TMap.LatLng(item.pLat, item.pLbd),//地图显示中心点
                         zoom:16//缩放级别
                 });
                 this.setCar()
             } else { // 数据初始化
                 
                 // this.clerData()
             }
             this.changeLi(this.state.carIndex, this.state.VehicleList[this.state.carIndex])
       }
     
     
       // 清空车辆数据
       clerData() {
             
             if(this.state.VehicleList[0]) { // 如果存在指向中间
                 map.setCenter(new TMap.LatLng(this.state.VehicleList[0].pLat,this.state.VehicleList[0].pLbd)); 
             }
         
             // 清空线
             this.state.polylineLayerList.map((item) => { 
                     if(item) {
                         item.remove('pl_1')
                     } 
             })
     
             let list = null
             list = this.state.VehicleList[this.state.carIndex]
     
             if(!list) {
                 return
             }
     
                 // 清空标记
             this.state.markerList.map(item => {
                 item.setGeometries([])
                 item.stopMove()
     
                 
                //  item.setStyles({
                //      'car-down': new TMap.MarkerStyle({
                //              'width': 40,  //小车图片宽度（像素）
                //              'height': 40, //高度
                //              'src': imgWarp2[list.type],
                //              'anchor': {   //图片中心的像素位置（小车会保持车头朝前，会以中心位置进行转向）
                //                  x: 20,y: 20,
                //              },
                //              'faceTo': 'map',  //取’map’让小车贴于地面，faceTo取值说明请见下文图示
                //              'rotate': 0,  
                //          })
                //      })
             })
     
     
             // 重新加载数据
             this.state.VehicleList.map((item, index) => {
                     item.status = true
                     let geometries =  [{    //小车marker的位置信息
                         id: 'car' + index,      //因MultiMarker支持包含多个点标记，因此要给小车一个id
                         styleId: 'car-down',    //绑定样式
                         position: new TMap.LatLng(item.pLat, item.pLbd),//初始坐标位置
                         item: item
                     }]
                 
                 this.state.markerList[index].add(geometries)
     
             })
       }
     
     
     
     
       setCar() {
           let _this = this
     
         //初始化infoWindow  弹框
         let infoWindow = new TMap.InfoWindow({
             map: map,
             position: new TMap.LatLng(39.984104, 116.307503),
             offset: { x: 0, y: -32 } //设置信息窗相对position偏移像素
         }); 
         infoWindow.close();//初始关闭信息窗关闭  
     
     
         this.state.VehicleList.map((item, index) => {
            let  polylineLayer = null
            item.index = index
            item.status = true
            item.polylineLayer = null

            console.log(item.type)
            
        
            let marker = new TMap.MultiMarker({
              map,
              styles: { //样式设置
                 'car-down': new TMap.MarkerStyle({
                   'width': 40,  //小车图片宽度（像素）
                   'height': 40, //高度
                   'anchor': {   //图片中心的像素位置（小车会保持车头朝前，会以中心位置进行转向）
                     x: 20,y: 20,
                   },
                   'faceTo': 'map',  //取’map’让小车贴于地面，faceTo取值说明请见下文图示
                   'rotate': 0,    //初始小车朝向（正北0度，逆时针一周为360度，180为正南）
                   'src': imgWarp2[item.type],   //小车图片（图中小车车头向上，即正北0度）
                 })
               },
               geometries: [{    //小车marker的位置信息
                 id: 'car' + index,      //因MultiMarker支持包含多个点标记，因此要给小车一个id
                 styleId: 'car-down' + index,    //绑定样式
                 position: new TMap.LatLng(item.pLat, item.pLbd),//初始坐标位置
                 item: item
               }]
            })
     
            this.state.markerList.push(marker)

    
     
            var clickHandler = function (evt) {
                 let x = 20, width = 40 
                 
                 let currentStatus =  _this.state.VehicleList.filter(item2 => item2.id == evt.geometry.item.id)
                 
                 item.status = currentStatus[0].status
     
                  infoWindow.open(); //打开信息窗
                  infoWindow.setPosition(evt.latLng);//设置信息窗位置
                  infoWindow.setContent(`<div class='alertPPP'>
                         <p style="font-weight: bold">
                             SN： ${currentStatus[0].pSN}
                         </p>
                         <p style="color:#63ABFF;">
                             ${currentStatus[0].plateNumber}
                         </p>
                         <p>
                             类型： ${getCarType(currentStatus[0].type)}
                         </p>
                         <p>
                             状态： ${currentStatus[0].pStatus == 2? '离线': '在线'}
                         </p>
                         <p>
                             时速： ${currentStatus[0].pIsStop}km/h
                         </p>
                  </div>`); 
     
                //  console.log(car)
                //  marker.setStyles({
                //      'car-down': new TMap.MarkerStyle({
                //          'width': width,  //小车图片宽度（像素）
                //          'height': width, //高度
                //          'src': car,
                //          'anchor': {   //图片中心的像素位置（小车会保持车头朝前，会以中心位置进行转向）
                //              x: x,y: x,
                //          },
                //          'faceTo': 'map',  //取’map’让小车贴于地面，faceTo取值说明请见下文图示
                //          'rotate': 0,  
                //        })
                //  })
    
                 item.status = !item.status
                 currentStatus[0].status = item.status
             }
     
             marker.on("click", clickHandler)
         })

         console.log(this.state.markerList)
         this.setState({
            markerList: this.state.markerList
         })
       }
     
       // 切换li
       changeLi(index, item) {
        this.setState({
            carIndex : index
        })
        let width = 40
         this.clerData()
         if(!item) {
             return
         }
         map.setCenter(new TMap.LatLng(item.pLat,item.pLbd));
         
         // 进来清掉上次的数据
         let marker2 = this.state.markerList[this.state.carIndex]
         marker2.stopMove()
         let polylineLayer2 =  this.state.VehicleList[this.state.carIndex].polylineLayer
         if(polylineLayer2) {
             polylineLayer2.remove('pl_1')
         }
         
        

         
     
         let marker = this.state.markerList[index]


          marker.setStyles({
                'car-down': new TMap.MarkerStyle({
                    'width': width,  //小车图片宽度（像素）
                    'height': width, //高度
                    'src': imgWarp2[marker.geometries[0].item.type],
                    // 'anchor': {   //图片中心的像素位置（小车会保持车头朝前，会以中心位置进行转向）
                    //     x: x,y: x,
                    // },
                    'faceTo': 'map',  //取’map’让小车贴于地面，faceTo取值说明请见下文图示
                    'rotate': 0,  
                })
            })

         
         let _this = this;
     
         let polylineLayer = this.state.polylineLayerList[index]
            

         _this.getVehiclePositioningList(item).then(path => {
     
             marker.moveAlong({ 
                 ["car" + index]: {    //设置让"car"沿"path"移动，速度70公里/小时
                     path,
                     speed: 500
                 }
                 }, {
                     autoRotation:true   //车头始终向前（沿路线自动旋转）
                 }
             )
             
             if(polylineLayer) {
                 polylineLayer.add({//新的折线添加到图层中
                     'id': 'pl_1',   
                     'styleId': 'style_blue',
                     'paths': path
                 })                        
                 return
             } else {
                 polylineLayer = new TMap.MultiPolyline({
                     id: 'polyline-layer' + index, //图层唯一标识
                     map: map,//设置折线图层显示到哪个地图实例中
                     //折线样式定义
                     styles: {
                         'style_blue': new TMap.PolylineStyle({
                             'color': '#3777FF', //线填充色
                             'width': 6, //折线宽度
                             'borderWidth': 5, //边线宽度
                             'borderColor': '#FFF', //边线颜色
                             'lineCap': 'butt' //线端头方式
                         }),
                     },
                     //折线数据定义
                     geometries: [
                         {//第1条线
                             'id': 'pl_1',//折线唯一标识，删除时使用
                             'styleId': 'style_blue',//绑定样式名
                             'paths': path
                         },
                     ]
                 });
     
                 _this.state.polylineLayerList[index] = polylineLayer
                 _this.setState({
                     polylineLayerList: _this.state.polylineLayerList
                 })
             }
             
     
     
         })
     
     
     
     
     
     
     
     
     
     
         // console.log(item)
     
       }
     
       // 获取车辆定位列表
       getVehiclePositioningList(item) {
         return new Promise(reslove => {
     
             let time = +new Date()
             let time2 =   time -  3600000 
     
             let params = {
                 logic:3,
                 locateMode: 5,
                 plateNumber: item.plateNumber,
                 sn: item.pSN,
                 monitorTimeStart:parseInt(time2 / 1000) ,
                 monitorTimeEnd: parseInt(time / 1000) ,
                 order: {
                     monitorTime: 'ASC' 
                 },
                 projectId: this.state.projectId
             }
     
             // 获取转换后的时间
             if(this.state.curentStatus) {
                 let getTime =  this.getDateStatusTime()
                 params.monitorTimeStart = parseInt(getTime.time2 / 1000)
                 params.monitorTimeEnd = parseInt(getTime.time / 1000)
                 
                 this.state.curentStatus = false
             }
             
             
     
             React.$axios.post('vehiclePositioningList2', params).then(res => {
                      let list = res.data.list
                      let path = []
                      
                      list.map(item => {
                          try {
                             path.push(
                                 new TMap.LatLng(item.latitude, item.longitude)
                             )
                          } catch(e) {
     
                          }
                          
                      })
     
     
                     reslove(path)
             })
         })
     
       }
     
       // 获取当前时间
       getDateStatusTime(index) {
          if(index != undefined || index != null) {
             this.state.changeTimeIndex = index
          }
         let time = +new Date() // 开始时间
         let time2 =  null // 结束时间
         if(this.state.changeTimeIndex == 0) { //昨天时间
             time2 =  time -  3600000 
         } else if(this.state.changeTimeIndex == 1) {
             time2 = time -  3600000 * 3 
         } else if(this.state.changeTimeIndex == 2) {
             time2 = time-24*60*60*1000
         } else if(this.state.changeTimeIndex == 3) {
             time = this.state.defineTime.time
             time2 = this.state.defineTime.time2
             return false
         }
     
         return {time: time, time2: time2}
       }
       
     
       // 切换页面
       changeStatus(e) {
           this.setState({
             status: e
           })
       }
     
       // 轨迹
       clickTrajectory(){
        

         let time = this.getDateStatusTime()
         let startTime = null
         let endTime = null
         if(!time) {
         } else {
             startTime = new Date(time.time)
             endTime = new Date(time.time2)
             this.setState({
                 startTime: startTime,
                 endTime: endTime  
             })
         }
         this.setState({
             clickTrajectoryStatus:true,
             
         })
     
       }
     
     
       changeTime(index) {
         let time = this.getDateStatusTime(index);
         let startTime = null
         let endTime = null
         if(!time) {
         } else {
             startTime = new Date(time.time)
             endTime = new Date(time.time2)
             this.setState({
                 startTime: startTime,
                 endTime: endTime
             })
         }
     
           this.setState({
             changeTimeIndex: index,
           })
     
       }
     
       serchPath() {
     
         this.setState({
             clickTrajectoryStatus:false,
             carIndex: this.state.curentIndex,
             
         })

         this.state.curentStatus = true
          
         this.changeLi(this.state.curentIndex, this.state.curentItem)
       }
     
       // 自定义时间
       toSbmitTime(val,val2) {
         // console.log(val2)
         if(val == 0) {
             this.state.defineTime.time2 = parseInt(+val2._d) 
             
             this.setState({
                 startTime: val2._d
             })
     
         } else if(val == 1) {
             this.state.defineTime.time = parseInt(+val2._d)
             this.setState({
                 endTime: val2._d
             })
         }
     
       }
     
       clickClose() {
         this.setState({
             clickTrajectoryStatus:false
         })
       }
     
     
       handleChange(item, val) {
         
         this.setState({
             curentItem: val.item,
             curentIndex: val.index
         })
       }
     
  render() {
    return (
      <div className= "VehiclePositioning">

          <div>

          <div className="btn">
           {/* <Button  type={this.state.status==true? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, true)}>实时监控</Button> */}
           {/* <Button className="setBtn" type={this.state.status==false? 'primary': ''}  onClick={this.changeStatus.bind(this, false)}>轨迹查询</Button> */}
           {
            this.state.childBtn.map(item => {
                if(item.show) {
                    if(item.index === 1) {
                        return (
                            <Button className="setBtn"  onClick={this.clickTrajectory.bind(this)}>轨迹查询</Button>
                        )
                    } else {
                        return (
                            <Button  type='primary'  className="setBtn">实时监控</Button>
                        )
                    }
                }
            })
           }
           
          </div>
          
        
          {/* <div style={{display: this.state.status==true? 'block': 'none'}}>   */}
          <div >
            <div className="carStatistics">
                <div className="leftData">
                    
                    <div  className={this.state.clickTrajectoryStatus?'moduleChangeTime': 'moduleChangeTime setNone'}>
                        <h2>历史查询</h2>

                        <div className="close" onClick={this.clickClose.bind(this)}>
                            ×
                        </div>

                        <ul>
                        {/* changeTimeIndex  timeActive*/} 
                            <li className={this.state.changeTimeIndex == 0? 'timeActive':''} onClick={this.changeTime.bind(this, 0)}>1小时</li>
                            <li className={this.state.changeTimeIndex == 1? 'timeActive':''} onClick={this.changeTime.bind(this, 1)}>3小时</li>
                            <li className={this.state.changeTimeIndex == 2? 'timeActive':''} onClick={this.changeTime.bind(this, 2)}>昨天</li>
                            <li className={this.state.changeTimeIndex == 3? 'timeActive':''} onClick={this.changeTime.bind(this, 3)}>自定义</li>
                        </ul>



                        
                            <div className="timeWarp">
                            <div className="setDiv">
                                    <h3>车辆选择</h3>
                                    <Select  placeholder="请选择车辆"  onChange={this.handleChange.bind(this)}>
                                        {
                                            this.state.VehicleList.map((item, index) => {
                                                return (
                                                    <Option  value={item.id} index={index} item = {item}>{item.plateNumber}</Option>
                                                )
                                                
                                            })
                                        }
                                            
                                    </Select>
                                </div>


                                <div className="setDiv">
                                    <h3>开始时间</h3>
                                    <DatePicker
                                    disabled = {this.state.changeTimeIndex == 3?false: true}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    placeholder="请选择开始时间"
                                    
                                    value = {moment(this.state.startTime , 'YYYY/MM/DD HH:mm:ss')}
                                    onOk = {this.toSbmitTime.bind(this, 0)}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                    />
                                </div>

                                {/* startTime: time.time,
        endTime: time.time2  */}

                                <div className="setDiv">
                                    <h3>结束时间</h3>
                                <DatePicker
                                    disabled = {this.state.changeTimeIndex == 3?false: true}
                                    format="YYYY-MM-DD HH:mm:ss"
                                  
                                    placeholder="请选择结束时间"
                                    onOk = {this.toSbmitTime.bind(this, 1)}
                                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                                    
                                    value = {moment(this.state.endTime , 'YYYY/MM/DD HH:mm:ss')} 
                                    />
                                </div>

                                

                            </div>


                            <div className="submitBtn">

                                <Button  type='primary' onClick={this.serchPath.bind(this)}>查询</Button>

                            </div>

                    </div>
                    
                    
                    <div className="Statistics">
                        <h1>车辆统计</h1>


                        <div className="statusWarp">
                            <div>
                                <p>在线</p>
                                <p className="color1">{this.state.vehiclePositioningStatisticsParams.online}</p>
                            </div>
                            <div className="border2">
                                <p>静止</p>
                                <p className="color2">{this.state.vehiclePositioningStatisticsParams.still}</p>
                            </div>
                            <div>
                                <p>离线</p>
                                <p className="color3">{this.state.vehiclePositioningStatisticsParams.offline}</p>
                            </div>
                        </div>

                    </div>


                    <div className="setul">
                        <ul>

                            {
                                CarMsgList.map((item,index) => {
                                    return (
                                        <li onClick={this.changeCarMsg.bind(this, index, item)}  className={this.state.CarMsgListIndex == index? 'active': ''}>{item.name}</li>
                                    )
                                })
                            }


                        </ul>
                    </div>


                    <div className="carList">
                        <ul>
                            {
                                this.state.VehicleList.map((item,index) => {
                                    return (
                                        <li onClick={this.changeLi.bind(this, index, item)}>
                                            <div className="imgType">
                                            <img src={imgWarp[item.type]} alt="" />
                                            </div>
                                            
                                            <div className="carMsg">
                                                <p><span className="se1"><b className={'statusB' + item.pStatus}>{item.pStatus == 2? '离线': '在线'}</b></span> <span className="h3">{item.plateNumber}</span></p>
                                                <p><i className="se1">#{getCarType(item.type)}</i> <span className="h4">#SN:{item.pSN}</span></p>
                                            </div>
                                            <div className={index == this.state.carIndex?'status  active':'status'}>
                                                <p>状态: {item.pStatus == 2? '静止': item.pIsStop == 2?'禁止': '运动'}</p>
                                                <p>时速: {item.pStatus == 2? '0': item.pSpeed}km/h</p>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                           

                        </ul>
                    </div>

                </div>

                <div className="rightData">
                    <div id="container">

                    </div>


                </div>
            </div>
          </div> 

      
          {/* <div style={{display: this.state.status==false? 'block': 'none'}}>
            
          </div> */}
          

      </div>
          

      </div>
    );
  }
}
