import React from "react";

import './720panorama.less'
import {formatTime} from '../../utils/util';
import { Input,Select,Button, DatePicker, Space, message} from "antd";
// console.log(formatTime)
import { SearchOutlined} from '@ant-design/icons';
const {Option} =  Select;

const { RangePicker } = DatePicker;




export default class Home extends React.Component {

    imgUrl = process.env.REACT_APP_IMG_URL
    state = {
        roleAllList: [],
        loading: false,
        total: 0,
        params: {
            lengthLimit: 30,
            offsetLimit: 0,
            logic: 2,
            order: {
                time: 'DESC'
            } 
        },
        currentPath: '' // iframe 地址
    }

    componentDidMount() {
       this.getList() 
    }

  
  getList(type) {

    if(type) {
        this.state.params.offsetLimit = 0
        this.setState({
            params: this.state.params
        })
    }

    this.setState({ loading: true });
    React.$axios.post('planList', this.state.params).then(({ data }) => {
        if(this.state.params.offsetLimit == 0) {
            this.state.roleAllList = data.list
            if(data.list.length > 0) {
              this.state.currentPath = data.list[0].originalUrl
            }
           
        } else {
            this.state.roleAllList = [...this.state.roleAllList, ...data.list]
        }
        
        
        this.setState({
        loading: false,
        roleAllList: this.state.roleAllList,
        total : data.countList
        })

    })

  }

  toPath(item) {
    window.open(item.originalUrl)
  }

  onChangeT(value) {
    this.state.params.order.time = value

    this.setState({
        params: this.state.params
    })
  }

  getTime(value) {
    this.state.params.timeStart =parseInt(+value[0]._d / 1000) 
    this.state.params.timeEnd = parseInt(+value[0]._d / 1000)  
    this.setState({
      params: this.state.params
    })
  }

  setInput(name, {target:{value}}) {
    this.state.params[name] = value
    this.setState({
      params: this.state.params
    })
  }


  serchData() {
    this.getList(true)
  }

  onScroll(value) {
    let dom = value


    if(dom.target.scrollTop + dom.target.clientHeight >= dom.target.scrollHeight) {
      this.state.params.offsetLimit += 30

      if(this.state.params.offsetLimit >= this.state.total) {
        message.success('没有更多数据了')
      } else {
        this.getList()
      }
    }

  }


  

  render() {
    return (
      <div className="maxWarp" onScroll={this.onScroll.bind(this)}>
        
         <div className = "titleImg">
            {/* <img src={img} alt=""/> */}
            <iframe src={this.state.currentPath} frameborder="0"></iframe>

         </div>


         <div className="bodyWarp">

           <div className="btn">
            {/* <p>
            <Button type="primary">按最新排序</Button>
            </p>
            <p>
            <Button>按最先排序</Button>
            </p> */}
            <Select
                showSearch
                style={{ width: 200, height:30, marginRight:20,  }}
                placeholder="请选择排序"
                value={this.state.params.order.time}
                defaultValue={this.state.params.order.time}
                optionFilterProp="children"
                onChange={this.onChangeT.bind(this)}
                >
                <Option value="ASC">按升序</Option>
                <Option value="DESC">按降序</Option>
            </Select>

            <Space direction="vertical" style={{ marginRight:20 }} size={12}>
                <RangePicker placeholder={['开始时间', '结束时间']} onChange={this.getTime.bind(this)}  />
            </Space>

            <Input onChange={this.setInput.bind(this, 'name')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="请输入标题名称" /> 

                
            <Button className="btn23" onClick={this.serchData.bind(this)} style={{ height:30,  }}>
                <SearchOutlined/>
            </Button>

           </div>


            <ul className="imgWarp">
                
            {
                this.state.roleAllList.map((item, index) => {
                    return (
                        <li>
                            <div className="img" onClick={this.toPath.bind(this, item)}>
                                <img src={this.imgUrl + item.image} alt=""/>
                            </div>

                            <div className="imgFoot">
                                <p>{item.name}</p>
                                <p>{formatTime(new Date(item.time*1000), 'yyyy年MM月dd日')}</p>
                            </div>


                        </li>
                        )
                    })
            }



            </ul>


                
        </div>   



      </div>
    );
  }
}
