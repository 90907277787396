import React, { useState, useEffect } from "react";
import { Form, DatePicker, Select, Button, Table } from 'antd';
import { withRouter } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { formatTime } from '@/utils/util.js'
import './productionDetails.less'


function ProductionDetails() {
  const [searchForm] = Form.useForm();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [blenderStationList, setBlenderStationList] = useState([]);
  const [blenderDeviceList, setBlenderDeviceList] = useState([]);
  const [tableList, setTableList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })
  const columns = [
    {
      title: '序号',
      align: 'center',
      render: (text, record, index) => {
        return <span>{index + 1}</span>
      }
    },
    {
      title: '生产号',
      dataIndex: 'prodNo',
      align: 'center',
    },
    {
      title: '搅拌站',
      dataIndex: 'nameStation',
      align: 'center',
    },
    {
      title: '搅拌机',
      dataIndex: 'nameDevice',
      align: 'center',
    },
    {
      title: '强度等级',
      dataIndex: 'grdOfConcrete',
      align: 'center',
    },
    {
      title: '浇筑部位',
      dataIndex: 'pouringObject',
      align: 'center',
    },
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      align: 'center',
    },
    {
      title: '出料方量',
      dataIndex: 'amount',
      align: 'center',
    },
    {
      title: '生产开始时间',
      dataIndex: 'grdOfConcrete',
      align: 'center',
      render: (text, record, index) => {
        return <span>{formatTime(new Date(record.produceTime * 1000), "yyyy-MM-dd hh:mm:ss")}</span>
      }
    },
    {
      title: '生产结束时间',
      dataIndex: 'grdOfConcrete',
      align: 'center',
      render: (text, record, index) => {
        return <span>{formatTime(new Date(record.completeTime * 1000), "yyyy-MM-dd hh:mm:ss")}</span>
      }
    },
    // {
    //   title: '操作',
    //   align: 'center',
    //   width: "200px",
    //   render: (text, record, index) => {
    //     return (
    //       <span></span>
    //     )
    //   }
    // }
  ];

  useEffect(() => {
    getBlenderStationList();
    getProduceList(true);
  }, []);
  useEffect(() => {
    getProduceList();
  }, [pagination]);
  function stationChange() {
    searchForm.setFieldsValue({
      deviceKey: ""
    })
    getBlenderDeviceList();
  }
  function onFinish() {
    getProduceList();
  }
  function onPaginationChange(page) {
    // console.log(page)
    setPagination(page)
  }
  function getBlenderStationList() {
    const requestData = {
      logic: 2,
    };
    React.$axios.post("blenderStationList", requestData).then(({ data }) => {
      setBlenderStationList(data.list)
    })
  };
  function getBlenderDeviceList() {
    const requestData = {
      logic: 2,
      stationId: searchForm.getFieldValue('stationId'),
    };
    React.$axios.post("blenderDeviceList", requestData).then(({ data }) => {
      setBlenderDeviceList(data.list)
    });
  }
  function getProduceList(type = false) {
    // console.log(pagination)
    const { stationId, deviceKey, startTime, endTime } = searchForm.getFieldsValue()
    const produceTimeStart = startTime ? parseInt(new Date(startTime[0]._d).getTime() / 1000) : ""
    const produceTimeStop = startTime ? parseInt(new Date(startTime[1]._d).getTime() / 1000) : ""
    const completeTimeStart = endTime ? parseInt(new Date(endTime[0]._d).getTime() / 1000) : ""
    const completeTimeStop = endTime ? parseInt(new Date(endTime[1]._d).getTime() / 1000) : ""
    const requestData = {
      offsetLimit: pagination.current,
      lengthLimit: pagination.pageSize,
      stationId,
      deviceKey,
      produceTimeStart,
      produceTimeStop,
      completeTimeStart,
      completeTimeStop
    };
    setLoading(true)
    React.$axios.post("produceList", requestData).then(({ data }) => {
      setTableList(data.list)
      if (type) {
        setPagination({
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: data.countList
        })
      }
    }).finally(() => {
      setLoading(false)
    })
  }
  return (
    <div className="productionDetails">
      <div className="search-wrap">
      <Form form={searchForm} layout="inline" onFinish={onFinish}>
          <Form.Item
            label="搅拌站"
            name="stationId"
          >
            <Select style={{ width: 156 }} onChange={stationChange}>
              {
                blenderStationList.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>{item.name}</Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="搅拌机"
            name="deviceKey"
          >
            <Select style={{ width: 156 }}>
              {
                blenderDeviceList.map((item, index) => {
                  return (
                    <Option value={item.deviceKey} key={index}>{item.name}</Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="生产开始时间"
            name="startTime"
          >
            <RangePicker />
          </Form.Item>

          <Form.Item
            label="生产结束时间"
            name="endTime"
          >
            <RangePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon={<SearchOutlined />} htmlType="submit"></Button>
          </Form.Item>
        </Form>
      </div>
      <div className="table-wrap">
        <Table
          bordered
          loading={loading}
          columns={columns}
          dataSource={tableList}
          pagination={pagination}
          onChange={onPaginationChange}
        />
      </div>
    </div>
  )
}

export default withRouter(ProductionDetails);