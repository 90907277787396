import React from "react";


import { DatePicker,Input,Select,Button, Table, Form, message, Modal} from "antd";


import { SearchOutlined} from '@ant-design/icons';

import './BehaviorType.less';

import {formatTime} from '@/utils/util';

const {Option} =  Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


let imgUrl = process.env.REACT_APP_IMG_URL

export const BehaviorType = (

    class BehaviorType extends React.Component {
    FormSbmitRef = null;     
    columns = [
      {
        title: '序号',
        dataIndex: 'nameCard',
        align: 'center',
        render: (_,item, index) =>{
          return (
            <p>{index+1}</p>
          )
        }
      },
      {
        title: '名称',
        dataIndex: 'name',
        align: 'center',
      },
      {
        title: '分数',
        dataIndex: 'fraction',
        align: 'center',
      },

 
      {
        title: '奖罚',
        align: 'center',
        dataIndex: 'type',
        render:(_) => {
          return (
              <p className={"colorP" + _}>
                  {_ ==2?'奖':'罚'}
              </p> 
          ) 
        }
      },
      {
        title: '用户安全行为之星奖罚总数',
        align: 'center',
        dataIndex: 'safeStarCount'
      },
      {
        title: 'AI摄像头抓拍不戴安全帽扣分',
        align: 'center',
        dataIndex: 'aiCamera',
        render:(_) => {
            return _ ==2?'是':'否'
        }
      },  
      {
          title: '权重',
          align: 'center',
          dataIndex: 'weigh',
      },  
        
        {
            title: '操作',
            dataIndex: 'remark',
            align: 'center',
            render:(_,item) => {
                return (
                    <p onClick={this.showModule.bind(this, item)} style={{color: '#6A9BF4', cursor:'pointer'}}>编辑</p>
                )
            }
        },
      ]

      columnsRebar = [
        {
            title: '序号',
            dataIndex: 'batchNo',
            align: 'center',
            render: (_, item, index) => {
                return (
                    <p>{index+1}</p>
                )
            }
          },
          {
            title: '姓名',
            dataIndex: 'nameCard',
            align: 'center'
          },
          
          {
            title: '岗位',
            dataIndex: 'nameWork',
            align: 'center',
          },

          {
            title: '分数',
            dataIndex: 'fraction',
            align: 'center',
          },

          {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            render: (_) => {
              return (
                <p>{_ == 2? '待平分': '完成'}</p>
              )
            }
          },
          // {
          //     title: '图片',
          //     dataIndex: 'img',
          //     align: 'center',
          //     render: (_) => {
          //         return (
          //           <Image
          //           width={50}
          //           src={imgUrl + _}
          //           /> 
          //         )
          //     }
          // }
      ]

  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    TableParams: {
        keyword: "",
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 2,
        projectId: null,
        status: '',
    },

    paginationRebar: {
        current: 1,
        pageSize: 10,
        total: 0,
    },

    tableDataRebar: [],


    isModalVisible: false,

    teamMany: [], // 当前队伍的数量
    teamManyValue: 0,
    status: true,
    addItem: {
        name: null,
        type: null,
        weigh: 0,
        fraction: 0,
        aiCamera: null,
        id: null
    }
  }


  componentDidMount() {
    const projectId = React.$getStorage('projectInfo').id

    this.state.TableParams.projectId = projectId;


    this.getTableData()
  }

  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

      React.$axios.post('safeStarTypeAll', {}).then(res => {
        console.log(res)    

        this.state.pagination.total = res.data.safeStarTypeAll.length;

        
        this.setState({
            tableData: res.data.safeStarTypeAll,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    this.state.TableParams.base = value
  }

  onChangeWork(name, value) {
    this.state.TableParams.type = value
  }

  toSerch2() {
    this.getTableData(true)
  }

  showModule(item) {
 
      this.state.addItem = {
        name:  item.name,
        type:  item.type,
        weigh: item.weigh,
        fraction: item.fraction,
        aiCamera: item.aiCamera,
        id: item.id
      }

      
      console.log(this.state.addItem)

      
      this.setState({
        addItem: this.state.addItem,
        isModalVisible: true,
      })


      let time = setTimeout(() => {
        clearTimeout(time)
        this.clierFormSbmitRef()  
      }, 200)

       
  }

  handleOk() {

        this.FormSbmitRef.submit()

  }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  getChangeTime(val) {
    this.state.TableParams.timeStart =  parseInt((+val[0]._d) / 1000) 
    this.state.TableParams.timeStop = parseInt((+val[1]._d) / 1000)
  }


  handleTableChange(value) {
    this.state.pagination = value
    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }


  onChangeT(val, value) {

    this.state.TableParams.status = value;

    this.setState({
      TableParams: this.state.TableParams
    })


  }

 

  changeStatus(val) {
    this.setState({
      status: val
    })
  }

  // 提交验证
  onFinishItem(val) {
    console.log(val)

    val.id = this.state.addItem.id

    React.$axios.post('safeStarTypeSave', val).then(res => {

        if(res.code == 20000) {
            message.success('保存成功')
            this.setState({
                isModalVisible: false
            })
        }

        this.getTableData(true)
    })

   

  }

  // 新增
  addListFun() {
    this.state.addItem = {
        name:  null,
        type:  2,
        weigh: 0,
        fraction: 0,
        aiCamera: 3,
        id: null
      }

      this.setState({
        addItem: this.state.addItem,
        isModalVisible: true,
      })


      let time = setTimeout(() => {
        clearTimeout(time)
        this.clierFormSbmitRef()  
      }, 200)
  }

    // 清除默认数据
    clierFormSbmitRef() {
        
        if(!this.FormSbmitRef) {
            setTimeout(() => {
                this.FormSbmitRef.resetFields()
            }, 500)
        } else {
            this.FormSbmitRef.resetFields()
        }
    }



  render() {
    return (
      <div className="BehaviorType">
          <div className="backg">

          
          <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'} >
                        <li><span>关键字:</span> 
                        <Input onChange={this.serchCar.bind(this, 'keyword')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                        
                        </li>
                        {/* <li><span>时间:</span>  
                        <RangePicker onChange={this.getChangeTime.bind(this)} style={{  height:30, marginRight:20,  }} />  
                        </li> */}

                        <li><span>状态:</span> 
                          {/* <Input onChange={this.serchCar.bind(this, 'keyword')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" /> */}

                          <Select
                                showSearch
                                style={{ width: 200, height:30, marginRight:20,  }}
                                placeholder="请选择角色"
                                optionFilterProp="children"
                                onChange={this.onChangeT.bind(this, 'statusWage')}
                            >
                            <Option value=" ">全部</Option>
                            <Option value="2">待评分</Option>
                            <Option value="3">完成</Option>
                          </Select>

                        </li>    




                          <Button  className="btn" onClick={this.toSerch2.bind(this)}>
                              <SearchOutlined  className="icon"/>
                          </Button>

                          
                          
                          
                    </ul>
                    
                    <div className="addBtn">
                        <Button type="primary" onClick={this.addListFun.bind(this)}>新增</Button>
                    </div>

                </div>



                <div className="table2">

                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    expandable={{ expandedRowRender: this.expandedRowRender }}
                    loading={this.state.loading} rowKey={record => record.id} columns={this.columns} dataSource={this.state.tableData}  />
                </div>


                </div>



                                                                                               
                <Modal title="行为类型"  width="30%" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)}  onCancel={this.handleCancel.bind(this)}>
                    <div className='BehaviorTypeModal'>
                    <Form
                        name="basic"
                        labelCol={{ span: 5 }}
                        ref={(el) => this.FormSbmitRef = el}
                        initialValues={this.state.addItem}
                        onFinish={this.onFinishItem.bind(this)}
                        >

                        <Form.Item
                            label="名称"
                            name="name"
                            rules={[{ required: true, message: '请输入名称' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item name="type" label="奖罚" rules={[{ required: true }]}>
                        <Select
                            placeholder="请选择"
                            onChange={this.onGenderChange}
                            allowClear
                        >
                            <Option value={2}>奖</Option>
                            <Option value={3}>罚</Option>   
                        </Select>
                        </Form.Item>


                        <Form.Item
                            label="分数"
                            name="fraction"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input />
                        </Form.Item>   

                        <Form.Item
                            label="权重"
                            name="weigh"
                        >
                            <Input />
                        </Form.Item>  

                        <Form.Item
                            label="摄像头抓拍扣分"
                            name="aiCamera"
                        >
                           <Select
                            placeholder="请选择"
                            allowClear
                        >
                            <Option value={2}>是</Option>
                            <Option value={3}>否</Option>   
                        </Select>
                        </Form.Item>         


                        </Form>


                    </div>
                </Modal>


      </div>
    );
  }
})
