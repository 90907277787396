import React from 'react';
import PageRouter from '@/router/pageRouter'
import { Provider } from 'react-redux';
import store from '@/redux/store';
import { hot } from 'react-hot-loader/root';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

class App extends React.Component {
	render() {
		return (
			<ConfigProvider locale={zhCN}>
				<Provider store={store}>
					<PageRouter />
				</Provider>
			</ConfigProvider>
		);
	}
}

export default hot(App);
