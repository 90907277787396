import React from "react";




import { DatePicker,Input,Select,Button, Table} from "antd";


import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';


import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

import {formatTime} from '@/utils/util'



import './monitorLogList.less';

const {Option} =  Select;
const { RangePicker } = DatePicker;


export default class Home extends React.Component {

  loadExcelRef = null
    state = {
        // 表格分页属性
      pagination: {
        current: 1,
        pageSize: 10,
        total: 200,
        onShowSizeChange: function(current, pageSize) {
            this.state.pagination.pageSize = pageSize;
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        onChange: function(current) {
            this.state.pagination.current = current
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        showTotal: function () {  //设置显示一共几条数据
            return '共 ' + this.state.pagination.total + ' 条'; 
        }.bind(this),
        showQuickJumper: true,
        },

    loading: false,

    tableData: [
        
    ],
    openStatus: false,
    queryParams: {
        lengthLimit: 10,
        offsetLimit: 0,
        deviceKey: "",
    },

    electricDeviceAllList: [],
    ExcelName: '电箱监测',
    jsonFieldsList: {
      '时间': 'time',
      '漏电流(mA)': 'il',
      'A相电压(V)': 'ua',
      'B相电压(V)': 'ub',
      'C相电压(V)': 'uc',
      'A相温度': 'ta',
      'B相温度': 'tb',
      'C相温度': 'tc',
      '环境温度': 'te',
      'A相电流(A)': 'ia',
      'B相电流(A)': 'ib',
      'C相电流(A)': 'ic'
    },

  }


  columns = [
    {
      title: "序号",
      dataIndex: 'title',
      align: 'center',
      render: (_, item, index) => {
        return (
          <p>{index + 1}</p>
        )
      }
    },
      {
        title: '监测时间',
        dataIndex: 'time',
        align: 'center',
        render: (_,item) => {
          return (
            <p>
              {formatTime(new Date(_*1000), 'yyyy-MM-dd hh:mm:ss')}
            </p>
          )
        }
      },
      {
        title: '漏电流(mA)',
        dataIndex: 'il',
        align: 'center',
      },
      {
        title: 'A相电压(V)',
        dataIndex: 'ua',
        align: 'center'
      }, 
      {
        title: 'B相电压(V)',
        dataIndex: 'ub',
        align: 'center'
      },
      {
        title: 'C相电压(V)',
        dataIndex: 'uc',
        align: 'center'
      },
      {
        title: 'A相温度',
        dataIndex: 'ta',
        align: 'center'
      },
      {
          title: 'B相温度',
          dataIndex: 'tb',
          align: 'center'
      },
      {
          title: 'C相温度',
          dataIndex: 'tc',
          align: 'center'
      },
      {
          title: '环境温度',
          dataIndex: 'te',
          align: 'center'
      },
      {
         title: 'A相电流(A)',
          dataIndex: 'ia',
          align: 'center'
      },
      {
          title: 'B相电流(A)',
          dataIndex: 'ib',
          align: 'center'
      },
      {
          title: 'C相电流(A)',
          dataIndex: 'ic',
          align: 'center'
      },
      
    ]

    componentDidMount() {
      this.getElectricDeviceAll()
      this.getDeviceInspectionDeviceList()
        
    }

    // 获取设备id
    getElectricDeviceAll() {
      React.$axios.post('electricDeviceAll').then(res => {
        this.setState({
          electricDeviceAllList: res.data.electricDeviceAll
        })

        // console.log(this.state.electricDeviceAllList)
      })
    }

    getDeviceInspectionDeviceList(type) {
      this.setState({
        loading: true
      })
      if(type) {
        this.state.queryParams.offsetLimit = 0
        this.state.pagination.current = 1
      }
        React.$axios.post('monitorLogList', this.state.queryParams).then(res => {
            this.state.pagination.total = res.data.countList
            this.setState({
                tableData: res.data.list,
                pagination: this.state.pagination,
                loading: false
            })
            

        })
    }

    nChangeT() {

    }

    setDrapDom() {
      if(this.state.openStatus == true) {
          return (
            <UpOutlined/>
          )
        } else {
          return (
            <DownOutlined />
          )
        }
    }

    drapHeight() {
      this.setState({
        openStatus: !this.state.openStatus
      })
    }


      onChangeT(value, item) {
        this.state.queryParams.deviceKey= item.item.deviceID
        this.setState({
          queryParams: this.state.queryParams
        })
      }

      // 选择时间
      changeTime(value) {
        this.state.queryParams.timeStart = parseInt((+value[0]._d) / 1000) 
        this.state.queryParams.timeStop = parseInt((+value[1]._d) / 1000) 
        
        this.setState({
          queryParams: this.state.queryParams
        })
      }


      toSerch() {
        this.getDeviceInspectionDeviceList(true)
      }

      handleTableChange(value) {
        this.state.queryParams.offsetLimit = (value.current - 1) * value.pageSize
        this.state.queryParams.lengthLimit = value.pageSize

        this.setState({
          pagination: value,
          queryParams: this.state.queryParams
        })

        

        this.getDeviceInspectionDeviceList()
      }


      fetchFun() {
        return new Promise(resolve => {
          let params = this.state.queryParams
          params.export = 2

          React.$axios.post('monitorLogList', params).then(res => {
             res.data.list.map(item => {
               item.time = formatTime(new Date(item.time * 1000), 'yyyy-MM-dd hh:mm:ss')
             })

              resolve(res.data.list)
              })
          })      
       }

       exportExcel() {
        
        this.loadExcelRef.setDownLoadData()
       } 

    
  render() {
    return (
      <div className="deviceInspection">
        <div style={{display: 'none'}}>
        <DownloadExcel  ref={(el) => this.loadExcelRef = el}  name={this.state.ExcelName} fields={this.state.jsonFieldsList}  fetch={this.fetchFun.bind(this)} btnName="确定" />
        </div>
                 <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                      
                        <li><span>设备:</span> 
                        <Select
                            showSearch
                            style={{ width: 200, height:30, marginRight:20,  }}
                            placeholder="请选择"
                            optionFilterProp="children"
                            onChange={this.onChangeT.bind(this)}
                        >
                          {
                            this.state.electricDeviceAllList.map(item => {
                              
                              return (
                                <Option value={item.id} item={item}>{item.name}</Option>
                              )
                            })
                          }
                          {/* <Option value=" ">全部</Option>
                          <Option value="2">劳务人员</Option>
                          <Option value="3">项目管理人员</Option> */}
                        </Select>
                        </li>

                        <li style={{marginRight: 20}}>
                          <span>时间:</span>
                          <RangePicker onChange={this.changeTime.bind(this)} />
                        </li>

           
                        <Button  className="btn" onClick={this.toSerch.bind(this)} >
                            <SearchOutlined className="icon"/>
                        </Button>
                    </ul>


                    <Button  className="bt" type="primary" onClick={this.exportExcel.bind(this)} icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button>
                </div>


                <div className="table2">
       
                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }} columns={this.columns} dataSource={this.state.tableData}  />
                </div>

      </div>
    );
  }
}
