import React from "react";
import { Input,Select,Button, Table, DatePicker,Space, Modal, message} from "antd";

import './roster.less';
import { SearchOutlined, DownOutlined,  UpOutlined, PlusOutlined, BorderLeftOutlined,  SyncOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined} from '@ant-design/icons';

import { Modeal } from '../../User/modal/modeal';

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

import { Uploadhh } from "@/components/upload/upload";

import {ManualFace} from '@/components/ManualFace/ManualFace';

// 错误信息模板
import ErrorTable from './errorTable';


import XLSX from "xlsx";

import {formatTime} from '@/utils/util';


const {Option} =  Select;

const { RangePicker } = DatePicker;

let width = 120// 设置图片大小
let height = 100
let imgUrl = process.env.REACT_APP_IMG_URL
let ManualFaceDom = ''; 

export default class Roster extends React.Component {
  imgUrl = process.env.REACT_APP_IMG_URL
    // 弹框的ref
    ModealRef = ''
    errorNumberArrWindow = []// 错误信息

  
    // 下载excel
    loadExcelRef=''
    loadExcelRef2 = ''
    jsonFieldsList2 = {
      '0_姓名': 'name',
      '1_身份证号':{
        field: 'number',
            callback: value => {
              return `${value}`
            }
        },
      '2_错误信息': 'message',
    }


    state = {
      childBtn: [],
      status: null,
      showErrorModal: false, // 展示错误信息
      showErrorList: [], // 错误信息数组
      RoleAllList: [], // 角色权限 
          // 表格分页属性
     pagination: {
        current: 1,
        pageSize: 10,
        total: 200,
        onChange(current) {  //点击改变页数的选项时调用函数，current:将要跳转的页数
          // self.gotoThispage(current, self.state.queryInfo.pageSize);
        },
        onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
        }.bind(this),
        onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
        }.bind(this),
        showTotal: function () {  //设置显示一共几条数据
            return '共 ' + this.state.pagination.total + ' 条'; 
        }.bind(this),
        showQuickJumper: true,
      },

      params: {
        lengthLimit: 10,
        offsetLimit: 0,
        uiEnterpriseId: [],
        uiTeamId: [],
        uiWorkId: [],
        witnessMatching: 2,
      },

      loading: false,

      tableData: [
          
      ],

      enterpriseAllList:[],
      teamAllList: [],
      workAllList: [],

      openStatus: false, // 展开状态

      visible: false,

      jsonFieldsList: {},

      ExcelName: '人员列表导出.xls',

      changeUpload : [
        {label: '姓名', prop: 'uicName', status:true},
        {label: '性别', prop: 'uicSex', status:true},
        {label: '民族', prop: 'uicNation', status:true},
        { label: "身份证号",prop: 'uicNumber', status:true, callback: function(value) {
            return `${value}&nbsp;`
        }},
        {label: "年龄",prop: 'uicAge', status:true},
        {label: '劳务企业',prop: 'eName', status:true},
        {label: '工种',prop: 'nameWork', status:true},
        {label: '班组',prop: 'teName', status:true},
        {label: '手机号',prop: 'phone', status:true},
        {label: '文化程度',prop: 'uiCulture', status:true},
        {label: '政治面貌',prop: 'uiPolitical', status:true},
        {label: '地址',prop: 'uicAddress', status:true},
        {label: "用工状态", prop: "uiEnterStatus", status: true, callback: function(value) {
          let status = value == 2? '进场': '退场'
          return status
        }},
        {label: '进场时间',prop: 'uiEnterTime', status:true},
        {label: '退场时间',prop: 'uiExitTime', status:true},
        {label: '合同编号',prop: 'uiContractNo', status:true},
        {label: '银行卡号',prop: 'ubcBankCardNumber', status:true},
        {label: '开户行名称',prop: 'ubcBankName', status:true},
        {label: '发证机关',prop: 'uicIssuingAuthority', status:true},
        {label: '有效起始日期',prop: 'uicEffectiveStartTime', status:true,callback: function(value) {
          let time = value
          // if(value) {
          //   time = formatTime(new Date(value * 1000), 'yyyy-MM-dd hh:mm:ss')
          // } else {
          //   time = 0
          // }
          return `<div>${time}</div>`
      } },
        {label: '有效截止日期',prop: 'uicEffectiveCutoffTime', status:true, callback: function(value) {
          let time = value
          // if(value) {
          //   = formatTime(new Date(value * 1000), 'yyyy-MM-dd hh:mm:ss')
          // } else {
          //   time = 0time 
          // }
          return `<div>${time}</div>`
      }},
        {label: '卡号',prop: 'uiIcNumber', status:true},
        {label: '人员类别',prop: 'uiPersonnelCategory', status: false},
        
        {label: '现场照片', prop: 'uiAvatarKey',status:false, callback: function(value) {
          value = imgUrl + value
          return '<div style="display:inline; width:100px; height:130px;"><img src=\'' + value + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>'
        }},
        {label: '身份证图片正面', prop: 'uicPositiveKey', status:false, callback: function(value) {
          value = imgUrl + value
          return '<div style="display:inline; width:100px; height:130px;"><img src=\'' + value + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>'
        }},
        {label: '身份证图片背面', prop: 'uicReverseSideKey', status:false, callback: function(value) {
          value = imgUrl + value
          return '<div style="display:inline; width:100px; height:130px;"><img src=\'' + value + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>'
        }},
        {label: '签名图片', prop: 'uiSignatureKey', status: false, callback: function(value) {
            value = imgUrl + value
            return '<div style="display:inline; width:100px; height:130px;"><img src=\'' + value + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>'
        }},
        {label: '指纹图片', prop: 'uiFingerprintKey', status: false, callback: function(value) {
          value = imgUrl + value  
          return '<div style="display:inline; width:100px; height:130px;"><img src=\'' + value + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>'
        }},

        // {label: '特种作业证书到期时间', prop: 'specialOperationExpireDate', status: false},
        // {label: '特种作业证书扫描件', prop: 'uiCertificate', status: false, callback: function(value) {
        //     value = JSON.parse(value)
        //     let div = '<div>'
        //     for(let i = 0; i < value.length; i++) {
        //         div += `<div>名称：${value[i].name} <span style="padding-left:20px;">开始时间： ${value[i].timeStart}</span> <span style="padding-left:20px;">到期时间： ${value[i].timeEnd}</span></div>`
        //         div += '<div>'
        //         value[i].img.map(item => {
        //             item = imgUrl + item
        //             div += '<img src=\'' + item + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
        //         })
        //         div += '</div>'
        //     }
        
        //     div += '</div>'
        //     return div
        // }},
        {label: '银行卡照片', prop: 'imgKeyBankCard', status:false, callback:function(value) {
            value = imgUrl + value
            return '<div style="display:inline; width:100px; height:130px;"><img src=\'' + value + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>'
        }},
        {
          label: '安全教育', prop: 'uiSafeEducation', status: false, callback: function(value) {
            return `<div>${value == 2? '是':'否'}</div>`
          }
        },
        {label: '安全教育时间', prop: 'uiSafetyEducationTime', status: false, callback: function(value) {
            let time = null
            if(value!= ' ' && value!= '' && value!=0 && value) {
              console.log(value)
              time = formatTime(new Date(value * 1000), 'yyyy-MM-dd hh:mm:ss')
            } else {
              time = 0
            }
            return `<div>${time}</div>`
        }},
        {label: '安全考试', prop: 'uiExamEducation', status: false, callback: function(value) {
           return `<div>${value == 2? '是':'否'}</div>`
        }},
        {label: '安全考试时间', prop: 'uiExamEducationTime', status: false, callback: function(value) {
          let time = null
          if(value!= ' ' && value!= '' && value!=0 && value) {
            console.log(value)
            time = formatTime(new Date(value * 1000), 'yyyy-MM-dd hh:mm:ss')
          } else {
            time = 0
          }
          return `<div>${time}</div>`
        }},
      ]


    }


    columns = [
        {
          title: '序号',
          dataIndex: 'key',
          align: 'center'
        },
        {
          
          title: '姓名',
          dataIndex: 'uicName',
          align: 'center',
          render:(_,item) => {
            return (
              <div>{!_?'-':_}</div>
            )
          }
        },
        {
          title: '身份证号',
          dataIndex: 'uicNumber',
          align: 'center',
          render:(_,item) => {
            return (
              <div>{!_?'-':_}</div>
            )
          }
        }, 
        // {
        //   title: '性别',
        //   dataIndex: 'uicSex',
        //   align: 'center',
        //   render:(_,item) => {
        //     return (
        //       <div >
        //           {_==2? '男': _==3?'女': '-'}
        //       </div>
        //     )
        //   }
        // },
        {
          title: '安全教育',
          dataIndex: 'uiSafeEducation',
          align: 'center',
          render:(_,item) => {
            return (
              <div className={'statusuiSa' + _}>
                  {_==2? '是': _==3?'否': '-'}
              </div>
            )
          }
        },
        {
          title: '安全考试',
          dataIndex: 'uiExamEducation',
          align: 'center',
          render:(_,item) => {
            return (
              <div className={'statusuiSa' + _}>
                  {_==2? '是': _==3?'否': '-'}
              </div>
            )
          }
        },
        // {
        //   title: '民族',
        //   dataIndex: 'uicNation',
        //   align: 'center',
        //   render:(_,item) => {
        //     return (
        //       <div>{!_?'-':_}</div>
        //     )
        //   }
        // },
        // {
        //   title: '籍贯',
        //   dataIndex: 'uicIssuingAuthority',
        //   align: 'center',
        //   render:(_,item) => {
        //     return (
        //       <div>{!_?'-':_}</div>
        //     )
        //   }
        // },
        {
            title: '手机号码',
            dataIndex: 'phone',
            align: 'center',
            render:(_,item) => {
              return (
                <div>{!_?'-':_}</div>
              )
            }
        },
        {
            title: '公司',
            dataIndex: 'eName',
            align: 'center',
            render:(_,item) => {
              return (
                <div>{!_?'-':_}</div>
              )
            }
        },
        
          {
            title: '部门',
            dataIndex: 'teName',
            width: 230,
            align: 'center',
            render:(_,item) => {
              return (
                <div>{!_?'-':_}</div>
              )
            }
          },
          {
            title: '岗位',
            dataIndex: 'nameWork',
            align: 'center',
            render:(_,item) => {
              return (
                <div>{!_?'-':_}</div>
              )
            }
          },
          {
            title: '允许登录',
            dataIndex: 'uiRole',
            align: 'center',
            render:(_,item) => {
              return (
                <div >
                    {_ == 2? '否': _==3?'是': '-'}
                </div>
              )
            }
          },
          {
            title: '用工状态',
            dataIndex: 'uiEnterStatus',
            align: 'center',
            render: (_, item) => {
              return (
                <div>
                    {_ == 2? '已进场':_==3?'已退场':'-'}
                </div>
              )
            }
          },
        {
          title: '操作',
          dataIndex: 'address',
          align: 'center',
          render:(_,item) => {
            return (
              <div className="operation">
                  {/* <p onClick={() => this.clickFun(item, '2')}>详情</p> */}
                  <p onClick={() => this.clickFace(item)} style={{color: 'green'}}>手动刷脸</p>

                  <p onClick={() => this.clickFun(item)} className="centp">编辑</p>
                  {
                    function(){
                      if(item.uiEnterStatus == 2) {
                        return (
                          <p className="enterStatus" onClick={this.changeEnterStatus.bind(this, item)}>退场</p>  
                        )
                      } else {
                        return (
                          <p onClick={this.changeEnterStatus.bind(this, item)}>进场</p>  
                        )
                      }
                    }.bind(this)()
                  }

                  <p onClick={this.changeEnterStatus2.bind(this, item)} className="del">删除</p>  
                  
              </div>
            )
          }
        },
      ]



      componentDidMount() {
        let childBtn = React.$getStorage('menuchildBtn');
        for(let i = 0; i < childBtn.length; i++) {
          if(childBtn[i].show) {
            let item = childBtn[i];
            this.changeStatus(item.index);
            break;
          }
        }
        
        this.setState({
          childBtn: childBtn
        })

       

        this.getEnterpriseAll()

        this.setState({
          ExcelName:  '花名册' + formatTime(new Date(), 'yyyyMMdd') + '.xls'
        })

        this.getRoleAll()

      }

    clickFace(item) {
      ManualFaceDom.edit(item)
    }

    
    // 进出场状态判断  
    changeEnterStatus(row) {
      let a =  row.uiEnterStatus == 2? 3:2
      let params = {
        id: row.id,
        enterStatus: a
      }
      React.$axios.post('enterStatusUserEditSave', params).then(res => {

        if(res.code == 20000) {
          message.success('保存成功')
          
          this.getList()
        }
        
      })
    }
    
    changeEnterStatus2(row) {
      let a =  3
      let params = {
        id: row.id,
        enterStatus: a,
        del: 2
      }
      React.$axios.post('enterStatusUserEditSave', params).then(res => {
        if(res.code == 20000) {
          message.success('删除成功')
          this.getList()
        }
        
      })
    }
     
      
    // 获取列表  
    getList(type = false) {
      
      if(type) {
        this.state.params.offsetLimit = 0
        this.state.pagination.current = 1
        this.setState({
          params: this.state.params,
          pagination: this.state.pagination
        })
      }


      this.setState({ loading: true });
      React.$axios.post('userList', this.state.params).then(({ data }) => {
        data.list.map((item,index) => {
          item.key = index + 1
        }) 
  
        this.state.pagination.total =  data.countList
  
        this.setState({
          loading: false,
          tableData: data.list,
          pagination: this.state.pagination
        })
  
      })
    }



      
    handleTableChange(pagination) {
      this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
      this.state.params.lengthLimit = pagination.pageSize
      this.setState({
        params: this.state.params,
        // pagination: pagination
      })
      
      // 执行更新列表
      this.getList()
    }


    getResult() {
      this.getList()
    }

    // 点击编辑和详情
    clickFun(e, type) {
       this.ModealRef.setCancel(e, type)
    }

    // 搜索选择类型
    onChangeT(name, value) {
      this.state.params[name] = value
      this.setState({
        params: this.state.params
      })
    }
    
    // 点击查询列表  
    serchClick() {
      this.getList(true)
    }

    // 设置input 值的变化
    setInput(name, {target:{value}}) {
      this.state.params[name] = value
      this.setState({
        params: this.state.params
      })
    }

    // 点击按钮拉伸长度
    drapHeight() {
      this.setState({
        openStatus: !this.state.openStatus
      })
    }

    // 设置下拉或者上拉的图标
    setDrapDom() {
      if(this.state.openStatus == true) {
          return (
            <UpOutlined/>
          )
        } else {
          return (
            <DownOutlined />
          )
        }
    }

    // 获取时间参数
    getTime(value) {
      
      
      if(!value) {
        this.state.params.addTimeStart =value
      this.state.params.addTimeStop = value
      } else {
        this.state.params.addTimeStart =parseInt(+value[0]._d / 1000) 
        this.state.params.addTimeStop = parseInt(+value[1]._d / 1000)  
      }

      this.setState({
        params: this.state.params
      })
    }

    // 获取岗位


   //点击打开导出弹框 
   handleCancel() {
    this.setState({
      visible: false
    })
   }


   exportExcel() {
     this.setState({
      visible: true
     })
   }
  
   // 点击关闭导出弹框
   handleOk() {

    let arr = {}
    this.state.changeUpload.map((item,index) => {
      if(item.status) {
        
        if(item.callback) {
          arr[index + '_' + item.label] = {
            field: item.prop,
            callback: item.callback
          }
        } else {
          arr[index + '_' + item.label] = item.prop
        }
        
      }
    })


    this.setState({
      visible: false,
      jsonFieldsList: arr
    })


    // 点击触发导出
    this.loadExcelRef.setDownLoadData()
   }

   // 设置选中导出按钮
   setSelectStatus(item, index) {
    item.status = !item.status

    this.setState({
      changeUpload: this.state.changeUpload
    })
   }

   // excel数据回调
   fetchFun() {
    return new Promise(resolve => {
      let params = {}
      for(let i in this.state.params) {
          params[i] = this.state.params[i]
      }

      params.export = 2
      React.$axios.post('userList', params).then(res => {
          res.data.list.map(item => {
            for(let i in item) {
              if(!item[i]) {
                item[i] = ' '
              } 
            }

            item.uicSex =item.uicSex == 2? '男': '女'
            item.uiEnterTime = item.uiEnterTime != 0? formatTime(new Date(item.uiEnterTime * 1000), 'yyyy-MM-dd') : '0'
            item.uiExitTime = item.uiExitTime != 0? formatTime(new Date(item.uiExitTime * 1000), 'yyyy-MM-dd') : '0'
            item.uiCulture = this.getCulture(item.uiCulture)  //文化程度
            item.uiPolitical =this.getPolitical(item.uiPolitical) // 政治面貌

            
            console.log(item.uiPersonnelCategory);
            item.uiPersonnelCategory = this.getPersonnelCategory(item.uiPersonnelCategory)
            
            // item.uiExamEducation = item.uiExamEducation ==2? '是': '否'
            // item.uiSafeEducation = item.uiSafeEducation ==2? '是': '否'
          })

          resolve(res.data.list)
          })
      })      
   }

  getPersonnelCategory(type) {
    let name = ''
    switch(type) {
      case type == 2:
        name =  '一般作业人员'
        break;
      case type == 3:
        name = '特种作业人员'
        break;
      case type == 4:
        name = '项目管理人员'
        break;
      case type == 5:
        name = '其它人员'
        break;
      default:
        name = '其他'
        break;
    }
    return name;
  }

  getPolitical(type) {
    let name = ''
    switch(type) {
      case type == 2:
        name =  '中共党员'
        break;
      case type == 3:
        name = '中共预备党员'
        break;
      case type == 4:
        name = '共青团员'
        break;
      case type == 5:
        name = '民革党员'
        break;
      case type == 6:
        name = '共青团员'
        break;
      case type == 7:
        name = '民盟盟员'
        break;
      case type == 8:
        name = '民建会员'
        break;
      case type == 9:
        name = '民进会员'
        break;
      case type == 10:
        name = '农工党党员'
        break;
      case type == 11:
        name = '致公党党员'
        break;
      case type == 12:
        name = '台盟盟员'
        break;
      case type == 13:
        name = '无党派人士'
        break;
      case type == 14:
        name = '群众'
        break;
      default:
        name = '其他'
        break;
    }
    return name;
  } 


   // 文化
   getCulture(type) {
    let name = '';
     switch(type) {
       case type == 2:
        name = '小学'
        break;
       case type == 3:
        name = '中专'
         break;
       case type == 4:
        name = '高中' 
         break;
       case type == 5:
        name = '专科'
         break;
       case type == 6:
        name = '本科'
         break;
       case type == 7:
        name = '硕士研究生' 
         break;
       case type == 8:
        name = '博士研究生'
         break;
         default:
          name = '其他'
        break;
     }
     return name;
   }


   // 获取公司
   getEnterpriseAll() {
    React.$axios.post('enterpriseAll').then(res => {
      this.setState({
        enterpriseAllList: res.data.enterpriseAll
      })
    })
   }

  // 获取部门
   getTeamAll(id) {

    let params = {
       id: id
     }
    React.$axios.post('teamAll', params).then(res => {
      this.setState({
        teamAllList: res.data.teamAll,
        workAllList: []
      })  
    })
  }

  // 获取工种
  getWorkAll(params) {
    React.$axios.post('workAll', {id: params}).then(res => {
      this.setState({
        workAllList: res.data.workAll
      })
    })
  }

  // 选择公司
  onChangeEnterprise(value, item) {

    this.state.params.uiEnterpriseId = value
    this.state.params.uiTeamId = []
    this.state.params.uiWorkId = []

    this.setState({
      params: this.state.params,
      teamAllList: [],
      workAllList: []
    })

    let arr = []
    item.map(item2 => {
      if(!item2.item.teamId) {

      } else {
        let teamId = item2.item.teamId.split(',')
        arr = [...arr, ...teamId]
      }
      
    })

     this.getTeamAll(arr)
  }

  onChangeTeam(value, item) {
    let arr = []
    item.map(item2 => {
      if(!item2.item.workId) {

      } else {
        let workId = item2.item.workId.split(',')
       arr = [...arr, ...workId]
      }
      
    })

    this.state.params.uiTeamId = value
    this.state.params.uiWorkId = []

    this.setState({
      params: this.state.params,
      workAllList: []
    })

    this.getWorkAll(arr)
  }

  onChangeWorkAll(value) {
    this.state.params.uiWorkId = value
    this.setState({
      params: this.state.params
    })
  }


  // 获取角色权限
  getRoleAll() {
    React.$axios.post('roleAll', {enable: 2}).then(res => {
      this.setState({
        RoleAllList: res.data.roleAll
      })
    })
  }

  // 导入模板
  inportExcel() {

  }

 
  // 导入后更新
  handleFront(res, fileParams) {


    if(res.data.error.length > 0) {
      this.setState({
        showErrorList: res.data.error,
        showErrorModal: true,
      })
    } 
    
   this.getList(true)
  }

  // 关闭错误信息弹框
  closeErrorTable() {
    this.setState({
      showErrorModal: false
    })
  }

  // 更新错误列表数据
  setErrorList(data) {
    if(data) {
      this.setState({
        showErrorList: data
      })
    } else {
      this.getList(true)
    }
  }



  // 批量退场
  BatchExit(e) {
    const files = e.target.files[0];

    if(!files) {
      message.error('请选择文件')
      return
    }

    let reader = new FileReader();

  
      reader.onload = (e) => {
        let result = e.target.result;
        let wb = XLSX.read(result, {
          type: "binary",
        });
        // 获取表1中的数据，转换为数组
        let data = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        
        
        let errorNumberArr = []        

        if(!data[0]['身份证号']) {
          message.error('不存在身份证号信息');
          return
        }


        let index = 0;
        let _this = this

        function toReq(index) {
          let item = data[index]
          let number = item['身份证号'].toString() 
          let name = item['姓名']
          let params = {
            numberIdCard: number,
            enterStatus: 3
          }
          
          React.$axios.post('enterStatusUserEditSave', params).then(res => {
            if(data.length-1 == index) {
              _this.getList()
              message.success('批量退场执行完成')
              _this.errorNumberArrWindow = errorNumberArr
              _this.loadExcelRef2.setDownLoadData() // 触发错误信息导出
            } else {
              index++;
              toReq(index);
            }
          }).catch(e => {
            
            errorNumberArr.push({number: params.numberIdCard, message: e.message, name: name})
            if(data.length-1 == index) {
              _this.getList()
              _this.errorNumberArrWindow = errorNumberArr
              _this.loadExcelRef2.setDownLoadData() // 触发错误信息导出
              message.success('批量退场执行完成')
            } else {
              index++;
              toReq(index);
            }

          }) 
        }

        toReq(index)



       
        // while (index<data.length)
        // {
       
        //   let item = data[index]
        //   let number = item['身份证号'].toString() 
        //   let name = item['姓名']

        //   let params = {
        //     numberIdCard: number,
        //     enterStatus: 3
        //   }

        //   console.log(index);

        //   current = index;

        //   React.$axios.post('enterStatusUserEditSave', params).then(res => {
        //     if(data.length-1 == index) {
        //       this.getList()
        //       message.success('批量退场执行完成')
        //       this.errorNumberArrWindow = errorNumberArr
        //       this.loadExcelRef2.setDownLoadData() // 触发错误信息导出
        //     }
        //     index++;
        //   }).catch(e => {
            
        //     errorNumberArr.push({number: params.numberIdCard, message: e.message, name: name})
        //     if(data.length-1 == index) {
        //       this.getList()
        //       this.errorNumberArrWindow = errorNumberArr
        //       this.loadExcelRef2.setDownLoadData() // 触发错误信息导出
        //       message.success('批量退场执行完成')
        //     }
        //     index++;
        //     console.log(index, data.length)
        //   }) 
        // }
      };

      reader.readAsBinaryString(files);
  }

  // 导出错误信息
  fetchFun2() {
    let _this = this
    return new Promise(resolve => {
    setTimeout(() => { //  隔一秒后导出  
          resolve(_this.errorNumberArrWindow)  
      }, 1000) 
    })  
  }


  changeStatus(status) {
    this.setState({
      status: status
    })

    this.state.params.witnessMatching = status=== 0? 2: 3;

    this.getList(true)
  }





  render() {
    return (
      <div className="roster">
            <div className="btn">
            {/* <Button style={{display: this.state.childBtn[0].show?'block': 'none'}} type={this.state.status==true? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, true)}>人证已审核</Button>
            <Button style={{display: this.state.childBtn[1].show?'block': 'none'}} className="setBtn" type={this.state.status==false? 'primary': ''}  onClick={this.changeStatus.bind(this, false)}>人证待审核</Button>
             */}
            {
              this.state.childBtn.map((item, index) => {
                if(item.show) {
                  return (
                    <Button  key={index} type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                  )
                }
              })
            }
            
            </div>   



            <div className="a1">
              <ManualFace  ref={(el) => ManualFaceDom = el} />
            </div>
            

            <div className="table rosterAll">
                <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>关键字:</span> <Input onChange={this.setInput.bind(this, 'keyword')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="姓名, 身份证号, 籍贯, 手机号" /></li>
                        <li><span>允许登录:</span>  
                        <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20, marginBottom: 20 }}
                                    placeholder="请选择是否允许登录"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'uiRole')}
                                >
                                <Option value=" ">全部</Option>
                                <Option value="2">否</Option>
                                <Option value="3">是</Option>
                                </Select>
                            </li>
                         
                           

                            <li><span>用工状态:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  marginBottom: 20 }}
                                    placeholder="请选择用工状态"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'uiEnterStatus')}
                                >
                                <Option value=" ">全部</Option>
                                <Option value="2">进场</Option>
                                <Option value="3">退场</Option>
                                </Select>
                            </li>


                            <li ><span>拥有图片:</span>  
                                  <Select
                                      showSearch
                                      style={{ width: 200, height:30, marginRight:20, marginBottom: 20  }}
                                      placeholder="请选择用工状态"
                                      optionFilterProp="children"
                                      onChange={this.onChangeT.bind(this, 'uiAvatarKeyStatus')}
                                  >
                                  <Option value=" ">全部</Option>
                                  <Option value="2">有</Option>
                                  <Option value="3">无</Option>
                                  </Select>
                              </li>

                            <Button  className="btn" style={{display: this.state.openStatus?'none':''}} onClick={this.serchClick.bind(this)}>
                                <SearchOutlined className="icon"/>
                            </Button>

                          <li> <span>公司:</span> 
                          {/* mode="multiple"  */}
                                <Select
                                    showSearch
                                    style={{ width: 200, marginRight:20, }}
                                    placeholder="请选择公司"
                                    optionFilterProp="children"
                                    mode="multiple"
                                    onChange={this.onChangeEnterprise.bind(this)}
                                >
                                 {
                                   this.state.enterpriseAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }

                                </Select>
                            </li>


                            <li> <span>部门:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择部门"
                                    optionFilterProp="children"
                                    mode="multiple"
                                    value = {this.state.params.uiTeamId}
                                    onChange={this.onChangeTeam.bind(this)}
                                >                         
                                {
                                   this.state.teamAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }

                                </Select>
                            </li>

                            <li > <span>岗位:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择公司"
                                    optionFilterProp="children"
                                    mode="multiple"
                                    value = {this.state.params.uiWorkId}
                                    onChange={this.onChangeWorkAll.bind(this)}
                                >
                                {
                                   this.state.workAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }
                             
                                </Select>
                            </li>
                          
                              <li >
                                <span>时间区间:</span>
                                <Space direction="vertical" style={{  marginBottom:20,marginRight:20 }} size={12}>
                                  <RangePicker placeholder={['开始时间', '结束时间']} onChange={this.getTime.bind(this)}  />
                                </Space> 
                              </li>

                              <li >
                                <span>安全教育:</span>
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择安全教育"
                                    optionFilterProp="children"
                                    
                                    onChange={this.onChangeT.bind(this, 'uiSafeEducation')}
                                >
                                  <Option value=" ">全部</Option>
                                  <Option value="2">是</Option>
                                  <Option value="3">否</Option>
                             
                                </Select>
                              </li>

                              <li >
                                <span>安全考试:</span>
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择安全考试"
                                    optionFilterProp="children"
                          
                                    onChange={this.onChangeT.bind(this, 'uiExamEducation')}
                                >
                                  <Option value=" ">全部</Option>
                                  <Option value="2">是</Option>
                                  <Option value="3">否</Option>
                             
                                </Select>
                              </li>

                              <li >
                                <span>角色:</span>
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择角色"
                                    optionFilterProp="children"
                          
                                    onChange={this.onChangeT.bind(this, 'uiRole')}
                                >
                                  <Option value=" ">全部</Option>
                                  <Option value="2">劳务人员</Option>
                                  <Option value="3">项目管理人员 </Option>
                             
                                </Select>
                              </li>

                              <li >
                                <span>角色权限:</span>
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择角色权限"
                                    optionFilterProp="children"
                          
                                    onChange={this.onChangeT.bind(this, 'uiRoleId')}
                                >
                                  <Option value=" ">全部</Option>
                                  <Option value="0">超级管理员</Option>    
                                  {
                                    this.state.RoleAllList.map(item => {
                                      return (
                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                      )
                                    })
                                  }
                                                           
                                </Select>
                              </li>   


                              <Button style={{marginBottom: 20, display: this.state.openStatus?'block':'none'}}  className="btn" onClick={this.serchClick.bind(this)}>
                                  <SearchOutlined className="icon"/>
                              </Button>

                            {/* </div>   */}

                            

                    </ul>
                    
                    <p className="drapBottom" onClick={this.drapHeight.bind(this)}>
                        {
                          this.setDrapDom()
                        }
                    </p>
                </div>


       
            <div className="table2">
            <div className="allBtn">
                <Button  className="bt" type="primary" onClick={this.clickFun.bind(this, false)} icon={<PlusOutlined />}>
                    录入
                </Button>
                <Button className="bt"  type="primary" icon={<BorderLeftOutlined />}>
                    <a href="https://project.1wx.cn/static/excel/import/人员导入模板.xlsx" download>下载模板</a>
                </Button>
                <Button  className="bt" type="primary"   icon={<VerticalAlignBottomOutlined />}>
                    导入


                    <div className="setUPloda">   
                       <Uploadhh accept = '.xlsx' path="userImport" pdfStatus={true}  name="file" width={80} height={30}   handleClick={this.handleFront.bind(this)} />
                    </div>     
                </Button>
                <Button  className="bt" type="primary" onClick={this.exportExcel.bind(this)} icon={<VerticalAlignTopOutlined />}>
                    导出
                </Button>
                <Button className="bt"  type="primary" icon={<SyncOutlined />}>
                    同步
                </Button>
                <Button className="bt" type="primary" > 
                批量退场
                  <div className="setUPloda">  
                       <input type="file" onChange={this.BatchExit.bind(this)} />

                  </div>      
                </Button>
                </div>
                          
                {/* onChange={this.handleTableChange.bind(this)} */}
                <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }}  columns={this.columns} dataSource={this.state.tableData}  />
            </div>

            </div>


            <Modeal ref={(el) => this.ModealRef = el} getResult = {this.getResult.bind(this)} />

             <p style={{display: 'none'}}>           
            <DownloadExcel ref={(el) => this.loadExcelRef = el}  name={this.state.ExcelName} fields={this.state.jsonFieldsList}  fetch={this.fetchFun.bind(this)} btnName="确定" />
            <DownloadExcel ref={(el) => this.loadExcelRef2 = el}  name="错误文件" fields={this.jsonFieldsList2}  fetch={this.fetchFun2.bind(this)} btnName="确定" />
             
             </p>

             {/* 导入弹框            */}
            <Modal
              visible={this.state.visible}
              title="需要导出数据"
              width="800px"
              onOk={this.handleOk.bind(this)}
              onCancel={this.handleCancel.bind(this)}
              footer={[
                <Button key="back" onClick={this.handleCancel.bind(this)}>
                  取消
                </Button>,
                
                 
                <Button key="submit" type="primary"  onClick={this.handleOk.bind(this)}>
                  确定
                </Button>
                ,
              ]}
            >

          <div className="needWarp">
           <h1><p><span></span>需要导出</p>  <p className="p2"><span></span></p>不需要导出</h1>
              <ul>
                  {
                    this.state.changeUpload.map((item,index) => {
                      return (
                        <li onClick={this.setSelectStatus.bind(this, item, index)} key={index}>
                            <Button type={item.status?'primary': ''}>
                              {item.label}
                            </Button>
                        </li>
                      )
                    })
                  }
              </ul>
              
          {/* <ul>
              <li v-for="(item, index) in changeUpload" :key="index" @click="item.status = !item.status">
                  <el-button  size="small" :type="item.status?'primary':''">
                      {{item.label}}
                  </el-button>
              </li>
          </ul> */}
        </div>
            
        </Modal> 
      
        
        <ErrorTable show={this.state.showErrorModal} toClose={this.closeErrorTable.bind(this)} setErrorList={this.setErrorList.bind(this)} list={this.state.showErrorList} />  

      </div>
    );
  }
}
