


import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Form, Button, DatePicker, Table, Input, Select, Tabs, message  } from 'antd';

import { SearchOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatTime } from '@/utils/util.js'

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

import image1 from '@/assets/img/coordination/11.png';

import * as echarts from 'echarts';

import './billMonth.less';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;


let jsonFieldsList =  {
    '0_起始日期': {
        field: 'FRq1',
        callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
            }
        },
    '1_结束日期':{
        field: 'FRq2',
            callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
            }
        },
    "2_月份": {
        field: 'FYue',
            callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd")
            }
        },
    "3_任务单": 'FRwds',
    "4_进料单":"FJlds",
    "5_砼品种": 'FTpzs',
    "6_总车数": 'FLjcs',
    "7_总方量": "FLjfs",
    "7_司机": "FSjs",
    "8_车辆数": "FGcs",
    "9_公里数" : "FGls",
    "10_统计时间" : "FQvA",
    "11_统计人": "FTjr",
    "12_余方量": "FRvA",
    "13_拌方量": "FRvB",
    "14_票方量": "FRvC",
    "15_退方量": "FQvA",
  }



function BillMonth() {
    const columns = [
        {
            title: '起始日期',
            dataIndex: 'FRq1',
            align: 'center',
            render: (_) => {
                let time;
                if(_) {
                    time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                }
                return (
                    <p>{time}</p>
                )
            }
        },
        {
            title: '结束日期',
            dataIndex: 'FRq2',
            align: 'center',
            render: (_) => {
                let time;
                if(_) {
                    time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                }
                return (
                    <p>{time}</p>
                )
            }
        },
        {
            title: '月份',
            dataIndex: 'FYue',
            align: 'center',
            render: (_) => {
                let time;
                if(_) {
                    time = formatTime(new Date(_ * 1000), 'yyyy-MM')
                }
                return (
                    <p>{time}</p>
                )
            }
        },
        {
            title: '任务单',
            dataIndex: 'FRwds',
            align: 'center',
        },
        {
            title: '进料单',
            dataIndex: 'FJlds',
            align: 'center',
        },
        {
            title: '砼品种',
            dataIndex: 'FTpzs',
            align: 'center',
        },
        {
            title: '总车数',
            dataIndex: 'FLjcs',
            align: 'center',
        },

        {
            title: '总方量',
            dataIndex: 'FLjfs',
            align: 'center',
        },
        {
            title: '司机',
            dataIndex: 'FSjs',
            align: 'center',
        },
        {
            title: '车辆数',
            dataIndex: 'FGcs',
            align: 'center',
        },

        {
            title: '公里数',
            dataIndex: 'FGls',
            align: 'center',
        },
        {
            title: '统计时间',
            dataIndex: 'FTjsj',
            align: 'center',
            render: (_) => {
                let time;
                if(_) {
                    time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                }
                return (
                    <p>{time}</p>
                )
            }
        },
        {
            title: '统计人',
            dataIndex: 'FTjr',
            align: 'center',
        },
        {
            title: '余方量',
            dataIndex: 'FRvA',
            align: 'center',
        },
        {
            title: '拌方量',
            dataIndex: 'FRvB',
            align: 'center',
        },
        {
            title: '票方量',
            dataIndex: 'FRvC',
            align: 'center',
        },
        {
            title: '退方量',
            dataIndex: 'FQvA',
            align: 'center',
        },

        {
            title: '操作',
            dataIndex: 'value',
            align: 'center',
            render: (_, item) => {
                return (
                    // <Button onClick={() => {onExpandedRowsChange(item)} }>操作</Button>
                    <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
                )
            }
        },
    ];


        
        
        const sliceColumns =  [
            {
                title: '起始日期',
                dataIndex: 'FRq1',
                align: 'center',
                render: (_) => {
                    let time;
                    if(_) {
                        time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                    }
                    return (
                        <p>{time}</p>
                    )
                }
            },
            {
                title: '结束日期',
                dataIndex: 'FRq2',
                align: 'center',
                render: (_) => {
                    let time;
                    if(_) {
                        time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                    }
                    return (
                        <p>{time}</p>
                    )
                }
            },
            {
                title: '月份',
                dataIndex: 'FYue',
                align: 'center',
                render: (_) => {
                    let time;
                    if(_) {
                        time = formatTime(new Date(_ * 1000), 'yyyy-MM')
                    }
                    return (
                        <p>{time}</p>
                    )
                }
            },
            {
                title: '任务单',
                dataIndex: 'FRwds',
                align: 'center',
            },
            {
                title: '进料单',
                dataIndex: 'FJlds',
                align: 'center',
            },
            {
                title: '砼品种',
                dataIndex: 'FTpzs',
                align: 'center',
            },
            {
                title: '总车数',
                dataIndex: 'FLjcs',
                align: 'center',
            },
    
            {
                title: '总方量',
                dataIndex: 'FLjfs',
                align: 'center',
            },
                {
                    title: '操作',
                    dataIndex: 'value',
                    align: 'center',
                    render: (_, item) => {
                        return (
                            // <Button onClick={() => {onExpandedRowsChange(item)} }>操作</Button>
                            <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
                        )
                    }
                },
        ]  


        const projectId = React.$getStorage('projectInfo').id

        let loadExcelRef = null

        const [deviceList, setDeviceList] = useState([])
        let trbbListParams = {
            projectId:projectId,
            logic: 2,
            offsetLimit: 0,
            lengthLimit: 10,
            sn: '',
            FYue: ''
        }

            

        const [ExcelName, setExcelName] = useState('考试');
        const [status, setStatus] = useState(0)
        const [columns2, setColumn] =  useState(columns);
        const [tableList, setTableList] = useState([])
        const [loading, setLoading] = useState(false)
        const [allParams, setAllParams] = useState({
            projectId:projectId,
            logic: 3,
            sn: '',
            FYue: ''
        })
    
        const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
        })


         // 任务单统计
         const [TaskStatistics, setTaskStatistics] = useState([])
         // 原材料统计
         const [materialStatistics, setMaterialStatistics] = useState([])
         // 砼品种
         const [tongVarieties, setTongVarieties] = useState([])
        
    
        const [widthStatus, setWidthStatus] = useState(false)

        const [widthStatus2, setWidthStatus2] = useState(false)

        const [headParams, setHeadParams] = useState({
            FRq1: '', // 开始时间
            FRq2: '', // 结束时间
            FLjfs: '', //总方量
            FRvB: '', // 拌方量
        })

        useEffect(() => {

            getDeviceList()
          }, []);

        
        function getDeviceList() {
            let params = {
                logic: 3,
                projectId: projectId
            }
            React.$axios.post('deviceListMixingStation', params).then(res => {
                if(res.data.list.length == 0) {
                    return
                }
                setDeviceList(res.data.list)
                let list =res.data.list
                trbbListParams.sn = list[0].sn
                allParams.sn = list[0].sn
                setAllParams(allParams)
                
                getList()
                // 获取上方更新数据
                getHeadDetail()
            })

        }

        


        // 获取列表
        function getList(type) {

            if(type) {
                pagination.current = 1;
                setPagination(pagination)
            } 


            trbbListParams.offsetLimit = (pagination.current - 1) * 10
            trbbListParams.lengthLimit = pagination.pageSize
            trbbListParams.sn = allParams.sn
            trbbListParams.FYue = allParams.FYue


            console.log(trbbListParams)


            setLoading(true)
            React.$axios.post('tybbListMixingStation', trbbListParams).then(res => {

            setTableList(res.data.list)
            setpage(res)
            }).finally(() => {
            setLoading(false)
            })

      }

      function setpage(res) {

        pagination.current = pagination.current;
        pagination.pageSize = pagination.pageSize;
        pagination.total = res.data.countList;
        let json = JSON.parse(JSON.stringify(pagination)); 
        json.onShowSizeChange = function(current, pageSize) {
            pagination.pageSize = pageSize;
            let json = JSON.parse(JSON.stringify(pagination)); 
            setPagination(json)
            onPaginationChange(json)
        }
        json.onChange = function(current) {
            pagination.current = current
            let json = JSON.parse(JSON.stringify(pagination)); 
            setPagination(json)
            onPaginationChange(json)
        }
        json.showTotal = function () {  //设置显示一共几条数据
            return '共 ' + pagination.total + ' 条'; 
        }
        setPagination(json)
    }


    function changeTabsHead(index) {
        trbbListParams.sn = deviceList[index].sn
        allParams.sn = deviceList[index].sn

        setAllParams(allParams)
        setWidthStatus2(false)
        setWidthStatus(false)
        getList()
        // 获取上方更新数据
        getHeadDetail()
    }


    function getHeadDetail(time) {
        let params = {
            logic: 4,
            sn: trbbListParams.sn,
            FYue: '',
            projectId: projectId
        }

        if(time) {
            params.FYue = time
        }

        
        React.$axios.post('tybbListMixingStation', params).then(res => {
            console.log(res)
            let arr1 = []
            let arr2 = []

            res.data.Tybbyl.map(item => {
                arr1.push(item.FYlmc)
                arr2.push(item.FUse)
            })


            if(res.data.Tybb) {
                setHeadParams({
                    FRq1: res.data.Tybb.FRq1,
                    FRq2: res.data.Tybb.FRq2,
                    FLjfs: res.data.Tybb.FLjfs,
                    FRFRvBB: res.data.Tybb.FRvB
                })
            }

            setechart2(arr1, arr2)        
        })
    }
          


        
        // 点击展开
        function onExpandedRowsChange(item) {
            // console.log(111)
            toSee(true)

            getTrwdListMixingStation(item)
            getTrbbylListMixingStation(item)
            getTrbbclListMixingStation(item)
            getTableHeadDetail(item)

            getHeadDetail(item.FYue)
        }


        function getTableHeadDetail(item) {
        
            console.log(item)
            let params = {
                logic: 4,
                sn: item.sn,
                FYue: item.FYue,
                projectId: item.projectId
            }
            
            React.$axios.post('tybbListMixingStation', params).then(res => {
                    let arr = []
                    res.data.Tybbyl.map(item => {
                        arr.push({
                            name: item.FYlmc,
                            value: item.FUse
                        })
                    })

                    setEcharts(arr)
            })
        }


          // 任务单列表
          function getTrwdListMixingStation(item) {
            let params = {
                logic: 3,
                sn: item.sn,
                FRwdh: item.FRwds,
                projectId:projectId,
                FYue: item.FYue
            }
            React.$axios.post('trwdListMixingStation', params).then(res => {
                setTaskStatistics(res.data.list)
            })
        }


        // 原材料消耗列表
        function getTrbbylListMixingStation(item) {
            let params = {
                logic: 3,
                projectId: projectId,
                sn: item.sn,
                FYue: item.FYue,
            }

            React.$axios.post('tybbylListMixingStation', params).then(res => {
                setMaterialStatistics(res.data.list)
            })
        }

        // 砼品种
        function getTrbbclListMixingStation(item) {
            let params = {
                logic: 3,
                projectId: projectId,
                sn: item.sn,
                FYue: item.FYue,
            }
            React.$axios.post('tybbclListMixingStation', params).then(res => {
                setTongVarieties(res.data.list)
            })
        }


        // 设置图形
        function setEcharts(data) {
            var myChart = echarts.init(document.getElementById('echartsTime'));
            let option = {
                tooltip: {
                    trigger: 'item'
                },
                
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 30,
                    top: 10,
                    bottom: 20,
                },
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        radius: ['50%', '90%'],
                        x:-180,//靠左
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: data
                    }
                ]
            };
            
            
            myChart.setOption(option);
        }


        function toSee(status, item, recove) {
  
            if(status) {
                setColumn(sliceColumns)
            } else {
                setColumn(columns)
            }
    
         setWidthStatus(status)


        if(!status) {
            setTimeout(() => {
                setWidthStatus2(status)
            }, 900)
        } else {
            setWidthStatus2(status)
        }
        
      }


      function onPaginationChange(row) {
        setPagination(row)
        getList()
    }

    function onChangeTime(time) {
        if(time) {
            let date = time._d
            let year = date.getFullYear()
            let Moath = date.getMonth() + 1

            let setNewTime = new Date(year + '/' + Moath + '/' + '01' + ' ' + '00:00:00');
            
            console.log(setNewTime)

            trbbListParams.FYue =   parseInt((setNewTime.getTime()  / 1000))  
        } else {
            trbbListParams.FYue = null
        }


        allParams.FYue = trbbListParams.FYue
        
        setAllParams(allParams)

    }

    function toSerch() {
        getList(true)
    }


    function setechart2(arr1, arr2) {
        var myChart = echarts.init(document.getElementById('main2'));
        let option = {
        tooltip: {
            trigger: 'item'
        },
    
          textStyle: {
            fontWeight: 'normal',
            color: '#7BABFF',
          },
          grid: {
            left: '1%',
            right: '4%',
            // containLabel: true
          },
          xAxis: [
            {
                type: 'category',
                data: arr1,
                // axisLabel: {  
                //     interval:0,  
                //     rotate:-40
                // }, 
                axisLine: {
                    lineStyle: {
                      color: '#D9D9D9'
                    }
                  },
                axisTick: {
                    alignWithLabel: true
                },
                　splitLine:{
                　　　　show:false
                　　}
            }
        ],
    
          yAxis: [{
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#fff'
              }
            },
            　splitLine:{
            　　　　show:false
            　　}
          }],
    
          series: [
            {
                name: '材料',
                type: 'bar',
                data: arr2,  //data1,
                symbolSize: 0,
                // barWidth: 5,
                // barMaxWidth:30,
                color: ['#106CFF'],
                areaStyle:{
                    normal: {
                        color: '#ff0000'
                    }
                },

            },
        ],
        };

         myChart.setOption(option);
      }



    // 导出方法
    function fetchFun() {

        setExcelName(formatTime(new Date(), '月账单yyyy-MM-dd hh:mm:ss'))
    
        return new Promise((resolve) => {
            let newParams = Object.assign({}, allParams);
            newParams.logic = 3
            React.$axios.post('tybbListMixingStation', newParams).then(res => {

                if(res.data.list.length > 0) {
                    resolve(res.data.list)
                } else {
                    message.success('没有查找到数据')
                }

                
            })
        })
      }




    return (
        <div className="billMonth">

            
            <div className="flex tableWarp">

            

            <div className="setWidth">
            <div className="head">
            <Tabs defaultActiveKey="0" onChange={changeTabsHead}>

                {
                    deviceList.map((item, index) => {
                        return (
                            <TabPane tab={item.name} key={index}>

                            </TabPane>
                        )
                    })
                }
            </Tabs>

                <div className="flex tabWarp">
                            <ul>
                                <li>
                                    <div className="flex">
                                    <span>生产时间</span>
                                    <div className="context">{headParams.FRq1?formatTime(new Date(headParams.FRq1 * 1000), 'yyyy-MM-dd'):'' }</div>
                                    <span>总&ensp;方&ensp;量</span>
                                    <div className="context setW2">{headParams.FLjfs}</div>
                                    </div>

                                    <div className="flex">
                                    <span>结束时间</span>
                                    <div className="context">{headParams.FRq2?formatTime(new Date(headParams.FRq2 * 1000), 'yyyy-MM-dd'): ''}</div>
                                    <span >拌&ensp;方&ensp;量</span>
                                    <div className="context setW2">{headParams.FRvB}</div>
                                    </div>

                                </li>
                            </ul>

                                



                            <div className="echart">

                            <div id="main2" className="setEchart setEchart2"></div>   
                            </div>

                                <div style={{display: widthStatus2? 'none': 'block'}}>
                                <div className="serchData serchBtn">

                                    <i>日期</i> 
                                    <div className="date"><DatePicker picker="month" onChange={onChangeTime} /></div>
                                    <Button className="setMargin styleBtnYellow" type="primary" onClick={toSerch}>搜索</Button>

                                    <DownloadExcel ref={(el) => loadExcelRef = el}  name={ExcelName} fields={jsonFieldsList}  fetch={fetchFun} btnName="导出">
                                        <Button  type="primary styleBtnGreen">导出</Button>
                                    </DownloadExcel>   
                                    </div>
                                </div>
                                
   
                        </div>

            
            </div>    





                <Table
                    bordered
                    loading={loading}
                    columns={columns2}
                    dataSource={tableList}
                    pagination={pagination}
                />

            </div>



            

            <div className={`detail  ${widthStatus? 'addWidth': ''}`}>
                
                <div className={`reactive ${widthStatus? 'ShowBlock': ''}`}    onClick={() => toSee(false)}>
                    ×
                </div>
                {/* <Tabs defaultActiveKey="1">
                    <TabPane tab="2021-11-12日报表统计信息" key="1"> */}
                    {/* <h1 className="title">2021-11-12日报表统计信息</h1> */}
                    <h1 className="title">日报表统计信息</h1>

                    <div className="statisticalInfo">

                        
                        <div className="echartsTime">
                            <div id="echartsTime" className="echartsTime"></div>
                        </div>        

                    </div>                    

                    <div className="setMaxHeight">

                    <Tabs defaultActiveKey="1">
                        <TabPane tab="按任务单统计" key="1" >
                        <ul className="setdetailedInfo" >

                                <table>
                                <tr className="firstTr">
                                    <th>任务单号</th>
                                    <th>单位名称</th>
                                    <th>工程名称</th>
                                    <th>浇筑部位</th>
                                    <th>浇筑方式</th>
                                    <th>砼品种</th>
                                    <th>砼数量</th>
                                    <th>任务性质</th>
                                    <th>任务来源</th>
                                    <th>状态</th>
                                    <th>合同编号</th>
                                    <th>工程级别</th>
                                    <th>施工地点</th>
                                    <th>公里数</th>
                                    <th>计划日期</th>
                                    <th>坍落度</th>
                                    <th>水泥品种</th>
                                    <th>石子规格</th>
                                    <th>计划方量</th>
                                    <th>生产拌台</th>
                                    <th>累计车数</th>
                                    <th>下达任务</th>
                                    <th>操作员</th>
                                    <th>登录日期</th>
                                    <th>施工配合比号</th>
                                    <th>砂浆配合比号</th>
                                    <th>试验员1</th>
                                    <th>搅拌时间</th>
                                    <th>出砼门控制参数</th>
                                    <th>试验员2</th>
                                    <th>砼单价</th>
                                    <th>砼金额</th>
                                    <th>泵送数量</th>
                                    <th>泵送单价</th>
                                    <th>泵送金额</th>
                                    <th>容重</th>
                                    <th>外援总方数</th>
                                    <th>外援总车数</th>
                                    <th>设备编号</th>
                                </tr>

                                {

                                    TaskStatistics.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.FRwdh}</td>
                                                <td>{item.FHtdw}</td>
                                                <td>{item.FGcmc}</td>
                                                <td>{item.FJzbw}</td>
                                                <td>{item.FJzfs}</td>
                                                <td>{item.FTpz}</td>
                                                <td>{item.FWcsl}</td>
                                                <td>{item.FRwxz}</td>
                                                <td>{item.FRwly}</td>
                                                <td>{item.FZt}</td>
                                                <td>{item.FHtbh}</td>
                                                <td>{item.FGcjb}</td>
                                                <td>{item.FGcdz}</td>
                                                <td>{item.FGls}</td>
                                                <td>{formatTime(new Date( item.FJhrq * 1000), "yyyy-MM-dd")}</td>
                                                <td>{item.FTld}</td>
                                                <td>{item.FSnbh}</td>
                                                <td>{item.FSzgg}</td>
                                                <td>{item.FJhsl}</td>
                                                <td>{item.FScbt}</td>
                                                <td>{item.FLjcs}</td>
                                                <td>{item.FXdrw}</td>
                                                <td>{item.FCzy}</td>
                                                <td>{formatTime(new Date( item.FDlrq * 1000), "yyyy-MM-dd")}</td>
                                                <td>{item.FSgpb}</td>
                                                <td>{item.FSjpb}</td>
                                                <td>{item.FSyy1}</td>
                                                <td>{item.FJbsj}</td>
                                                <td>{item.FClsjNo}</td>
                                                <td>{item.FSyy2}</td>
                                                <td>{item.FTdj}</td>
                                                <td>{item.FTje}</td>
                                                <td>{item.FBssl}</td>
                                                <td>{item.FBsdj}</td>
                                                <td>{item.FBsje}</td>
                                                <td>{item.FRz}</td>
                                                <td>{item.Fwyzfs}</td>
                                                <td>{item.Fwyzcs}</td>
                                                <td>{item.sn}</td>
                                            </tr>
                                        )
                                    })

                                }
                                </table> 
                            </ul>   


                        </TabPane>
                        <TabPane tab="按原材料消耗统计" key="2">
                        <ul className="setdetailedInfo" >
                            <table>
                                <tr className="firstTr">
                                    <th>日期</th>
                                    <th>设备编号</th>
                                    <th>仓库</th>
                                    <th>原料名称</th>
                                    <th>品种规格</th>
                                    <th>实际数量</th>
                                    <th>配比数量</th>
                                    <th>生产拌台</th>
                                    
                                </tr>

                                {

                                    materialStatistics.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{formatTime(new Date( item.FRq * 1000), "yyyy-MM-dd")}</td>
                                                <td>{item.sn}</td>
                                                <td>{item.FCkbh}</td>
                                                <td>{item.FYlmc}</td>
                                                <td>{item.FPzgg}</td>
                                                <td>{item.FUse}</td>
                                                <td>{item.FPbsl}</td>
                                                <td>{item.FRvA}</td>
                                            </tr>
                                        )
                                    })

                                }
                            </table> 
                            </ul>         
                                

                            
                        </TabPane>
                        <TabPane tab="按砼品种统计" key="3">
                            
                        {/*  */}
                        <ul className="setdetailedInfo" >
                            <table>
                                <tr className="firstTr">
                                    <th>日期</th>
                                    <th>设备编号</th>
                                    <th>生产拌台</th>
                                    <th>砼品种</th>
                                    <th>累计车数</th>
                                    <th>累计方数</th>
                                    <th>余方量</th>
                                    <th>拌方量</th>
                                    <th>票方量</th>
                                    <th>退方量</th> 
                                </tr>

                                {

                                    tongVarieties.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{formatTime(new Date( item.FRq * 1000), "yyyy-MM-dd")}</td>
                                                <td>{item.sn}</td>
                                                <td>{item.FScbt}</td>
                                                <td>{item.FTpz}</td>
                                                <td>{item.FLjcs}</td>
                                                <td>{item.FLjfs}</td>
                                                <td>{item.FRvA}</td>
                                                <td>{item.FRvB}</td>
                                                <td>{item.FRvC}</td>
                                                <td>{item.FQvA}</td>
                                            </tr>
                                        )
                                    })

                                }
                            </table> 
                        </ul>       

                        </TabPane>
                    </Tabs>
                        
                    </div>      

                    {/* </TabPane>
                </Tabs> */}

         
            </div>

        </div>



        </div>
    )

}


export default withRouter(BillMonth);