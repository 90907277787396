import React from "react";


import {Button, Table,  Modal, Select, message, Form, Input, Checkbox} from "antd";
import './RoleAuthority.less';


const {Option} =  Select;

const layout = {
    labelCol: { span: 4 },
  };

export default class RoleAuthority extends React.Component {
    columns = [
        {
          title: '角色名',
          dataIndex: 'name',
          align: 'center',
        },
        {
            title: '状态',
            dataIndex: 'enable',
            align: 'center',
            render:(_) => {
                let text = null;
                if(_ == 2) {
                    text = '启用'
                } else {
                    text = '禁用'
                }
                return (
                    <p className={'setColor' + _}>{text}</p>
                )
            }
        },
        {
            title: '类型',
            dataIndex: 'type',
            align: 'center',
            render:(_) => {
                return (
                    <p>{_==0?'普通角色':'云开仓'}</p>
                )
            }
        },
        {
          title: '操作',
          dataIndex: 'address',
          align: 'center',
          width: 200,
          render:(_,item) => {
            return (
              <div className="operation">
                  <p onClick={this.clickFun.bind(this, item)} className="centp">编辑</p>
              </div>
            )
          }
        },
      ]

      FormSbmitRef = ''

    state = {
        herfEarth: '',
        status: 1,
        loading: false,
        tableData:[],

        pagination: {
            current: 1,
            pageSize: 10,
            total: 200,
            onShowSizeChange: function(current, pageSize) {
                this.state.pagination.pageSize = pageSize;
                this.handleTableChange(this.state.pagination)
            }.bind(this),
            onChange: function(current) {
                this.state.pagination.current = current
                this.handleTableChange(this.state.pagination)
            }.bind(this),
            showTotal: function () {  //设置显示一共几条数据
                return '共 ' + this.state.pagination.total + ' 条'; 
            }.bind(this),
            showQuickJumper: true,
            },
        paramsAdmin: {
            lengthLimit: 10,
            offsetLimit: 0
        },

        FormParams: {
            name: null,
            enable: null,
            ruleId: [],
            type: 0,
            projectId:null
        },
        roleAllList: [],
    
        isModalVisible: false,
        projectId:null
      }

  componentDidMount() {
    let id =  React.$getStorage('projectInfo').id

    this.state.FormParams.projectId = id
    this.state.projectId = id


    this.getRoleList()

    this.getRuleAll()
  }

  getRoleList() {
    React.$axios.post('roleList', this.state.paramsAdmin).then(res => {
        this.state.pagination.total = res.data.count
        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination
        })
    })
  }


 getRuleAll() {
    React.$axios.post('ruleAll').then(res => {
        this.setState({
            roleAllList: res.data.rules
        })
    })
 }

  addAdmin() {
    
    this.setState({
        isModalVisible:true
    })

    if(this.state.FormParams.id) {
        delete this.state.FormParams.id
    }

    for(let i in this.state.FormParams) {
        this.state.FormParams[i] = null
    }
    this.state.FormParams.projectId = this.state.projectId
    this.state.FormParams.ruleId = [] 

    this.clierFormSbmitRef()
    this.defindParams()
  }

  defindParams() {
    this.state.roleAllList.map(item => {
        item.status = false
    })

    this.setState({
        roleAllList: this.state.roleAllList
    })

  }

  clierFormSbmitRef() {
    if(!this.FormSbmitRef) {
        setTimeout(() => {
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
  }


  handleTableChange(value) {

    this.state.pagination = value

    this.state.paramsAdmin.offsetLimit = (value.current-1) * value.pageSize;
    this.state.paramsAdmin.lengthLimit = value.pageSize;
    
    this.getRoleList()
  }




  clickFun(item) {
    if(!item.ruleId) {
        item.ruleId = ''
    }
    let ruleId = item.ruleId.split(',')
    
    for(let i in this.state.FormParams) {
        this.state.FormParams[i] = item[i]
    }

    this.state.FormParams.id = item.id
    this.state.FormParams.projectId = this.state.projectId;
    this.state.FormParams.ruleId = ruleId
    
    this.state.roleAllList.map(item => {
        item.status = false
        ruleId.map(item2 => {
            if(item2 == item.id) {
                item.status = true
            }
        })
    })

    this.setState({
        roleAllList: this.state.roleAllList
    })


    this.setState({
        isModalVisible: true
    })

    this.clierFormSbmitRef()

  }

  handleOk() {
    
    this.FormSbmitRef.submit()

    


  }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  onFinish(value) {
    this.state.FormParams.name = value.name
    this.state.FormParams.enable = value.enable
    this.state.FormParams.type = value.type


    React.$axios.post('roleEditSave', this.state.FormParams).then(res => {
            if(res.code == 20000) {
                message.success('保存成功')
                this.getRoleList()
                this.setState({
                    isModalVisible: false
                })
            }
    })


  }

  onChangeCheckbox(item) {
    
    item.status = !item.status
    

   let index =  this.state.FormParams.ruleId.indexOf(item.id.toString())
   if(index == -1) {
       this.state.FormParams.ruleId.push(item.id.toString())
   } else {
        this.state.FormParams.ruleId.splice(index, 1)
   }
  
   this.setState({
     FormParams: this.state.FormParams
   })

  }



  render() {
    return (
      <div className="RoleAuthority">
          <div className="btn">
                <Button type="primary" onClick={this.addAdmin.bind(this)}>
                   添加角色
                </Button> 
            </div>

            <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }} columns={this.columns} dataSource={this.state.tableData}  />


<Modal title="添加" width="40%"  visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                        <Form
                            onFinish={this.onFinish.bind(this)}
                            ref={(el) => this.FormSbmitRef = el}
                            {...layout}
                            initialValues = {this.state.FormParams}
                        >
                            <Form.Item label="角色名" name="name"  rules={[{ required: true }]}>
                            <Input placeholder="请输入名称" />
                            </Form.Item>
                            <Form.Item label="状态" name="enable" rules={[{ required: true }]} >
                            <Select
                                showSearch
                                style={{ width: 200,  marginRight:20,  }}
                                placeholder="请选择"
                                optionFilterProp="children"
                            >

                            <Option value={2}>启用</Option>
                            <Option value={3}>禁用</Option>
                            </Select>

                            </Form.Item>

                            <Form.Item label="类型" name="type" rules={[{ required: true }]} >
                            <Select
                                showSearch
                                style={{ width: 200,  marginRight:20,  }}
                                placeholder="请选择"
                                optionFilterProp="children"
                            >

                            <Option value={0}>普通角色</Option>
                            <Option value={1}>云开仓</Option>
                            </Select>

                            </Form.Item>

                            <div className="RoleAuthoritySetHeight">
                            <Form.Item label="选择权限" name="roleId" >
                                {
                                    this.state.roleAllList.map((item, index) => {
                                        return (
                                            <div style={{marginLeft: item.level * 25, lineHeight: '30px'}}>
                                                <Checkbox defaultChecked={item.status} onChange={this.onChangeCheckbox.bind(this, item, index)}>{item.name}</Checkbox>
                                            </div>
                                        )
                                    })
                                }

                            </Form.Item>
                            </div>
                        </Form>

        </Modal> 


      </div>
    );
  }
}
