


import * as THREE from '../three.module';



const vertex = `
      uniform float time;
      uniform float size;
      varying vec3 iPosition;

      void main(){
          iPosition = vec3(position);
          float pointsize = 1.;
          if(position.x > time && position.x < (time + size)){
              pointsize = (position.x - time) / size;
          }
          gl_PointSize = pointsize * 3.0;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
      }
    `


    const fragment = `
    uniform float time;
    uniform float size;
    uniform vec3 colorf;
    uniform vec3 colort;

    varying vec3 iPosition;

    void main( void ) {
        float end = time + size;
        vec4 color;
        if(iPosition.x > end || iPosition.x < time){
            discard;
            //color = vec4(0.213,0.424,0.634,0.3);
        }else if(iPosition.x > time && iPosition.x < end){
            float step = fract((iPosition.x - time)/size);

            float dr = abs(colort.x - colorf.x);
            float dg = abs(colort.y - colorf.y);
            float db = abs(colort.z - colorf.z);

            float r = colort.x > colorf.x?(dr*step+colorf.x):(colorf.x -dr*step);
            float g = colort.y > colorf.y?(dg*step+colorf.y):(colorf.y -dg*step);
            float b = colort.z > colorf.z?(db*step+colorf.z):(colorf.z -db*step);

            color = vec4(r,g,b,1.0);
        }
        float d = distance(gl_PointCoord, vec2(0.5, 0.5));
        if(abs(iPosition.x - end) < 0.2 || abs(iPosition.x - time) < 0.2){
            if(d > 0.5){
                discard;
            }
        }
        gl_FragColor = color;
    }
  `

   //创建ShaderMaterial纹理的函数
   function createMaterial() {
        var vertShader = vertex; //获取顶点着色器的代码
        var fragShader = fragment; //获取片元着色器的代码

        //配置着色器里面的attribute变量的值
        var attributes = {};
        //配置着色器里面的uniform变量的值
        var uniforms = {
            time: {type: 'f', value: -70.0},
            size:{type:'f',value:35.0},
        };

        var meshMaterial = new THREE.ShaderMaterial({
            uniforms: uniforms,
            defaultAttributeValues : attributes,
            vertexShader: vertShader,
            fragmentShader: fragShader,
            transparent: true
        });

        return meshMaterial;
    }



  /**
   * 
   * @param {*} minx x 其实位置 
   * @param {*} maxx x 落点位置
   * @param {*} colorf x 尾部线条颜色
   * @param {*} colort x 头部线条颜色
   * @returns  一个mesh
   */  

  function FlyLine(minx,maxx, colorf, colort) {
    colorf = colorf?new THREE.Color(colorf): new THREE.Color('#F1C773')
    colort = colort?new THREE.Color(colort): new THREE.Color('#E67145')
    
    var curve = new THREE.CubicBezierCurve3(
        new THREE.Vector3( minx, 0, 30 ),
        new THREE.Vector3( minx/2, maxx % 20+ 20, 30 ),
        new THREE.Vector3( maxx/2, maxx % 20 + 20, 30 ),
        new THREE.Vector3( maxx, 0, 30 )
    );


    var points = curve.getPoints( (maxx - minx) * 5  );
    var geometry = new THREE.Geometry();

    geometry.vertices = points;
    

    var material = createMaterial("vertex-shader", "fragment-shader-7");
    var flyline = new THREE.PointCloud( geometry, material );   



    flyline.material.uniforms.time.value = minx;

    flyline.material.uniforms.colorf = {
        type:'v3',
        value:new THREE.Vector3(colorf.r,colorf.g,colorf.b)
    };
    
    flyline.material.uniforms.colort = {
        type:'v3',
        value:new THREE.Vector3(colort.r,colort.g,colort.b)            
    };

    flyline.minx = minx;
    flyline.maxx = maxx;


    // lineObj.add(flyline);

    // linegroup.push(flyline);

    return flyline;
  }


  export {FlyLine};




