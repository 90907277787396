import React, { useState, useEffect } from "react";
import { Carousel } from 'antd';
import { withRouter } from 'react-router-dom';
import './educationOverview.less'
import moment from 'moment';
import {
  ArrowUpOutlined
} from '@ant-design/icons';
import * as echarts from 'echarts';
import { formatTime } from '@/utils/util'

const imgUrl = process.env.REACT_APP_IMG_URL

function EducationOverview() {
  const [navIndex, setNavIndex] = useState(0);
  const [overviewStatistics, setOverviewStatistics] = useState({});
  const [trainingUser, setTrainingUser] = useState([]);
  const [examPaperUser, setExamPaperUser] = useState([]);
  const [swiperList, setSwiperList] = useState([]);
  useEffect(() => {
    getOverviewStatistics();
    getTrainingUser();
    getExamPaperUser();
  }, []);
  useEffect(() => {
    if (trainingUser.length && examPaperUser.length) {
      let index = 0;
      let _swiperList = []
      while (index < 5) {
        let  examPaperUser2  = examPaperUser.filter(item => item.pass === 2)[index]
        if(!examPaperUser2) {
          examPaperUser2 = {}
        }

        _swiperList.push({
          trainingUser: trainingUser[index],
          examPaperUser: examPaperUser2
        })
        index++;
      }
      setSwiperList(_swiperList)
    }
  }, [trainingUser, examPaperUser]);
  function toSelecteNav(index) {
    setNavIndex(index)
  }

  function drawEductionChart(chartData) {
    const chartXData = Object.values(chartData).map(item => item.name)
    const chartEducationData = Object.values(chartData).map(item => item.trainingUser)
    const chartExamData = Object.values(chartData).map(item => item.examUser)
    // console.log(chartXData, chartEducationData, chartExamData)
    try {
      const myChart = echarts.init(
        document.getElementById("educationChart")
      );
      myChart.setOption({
        color: [
          new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 1,
              color: "#00B050",
            },
            // {
            //   offset: 1,
            //   color: "#fff",
            // },
          ]),
          new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 1,
              color: "#399DF2",
            },
            // {
            //   offset: 1,
            //   color: "#fff",
            // },
          ]),
        ],
        // title: {
        //   text: '安全教育统计',
        //   left: "2%",
        //   top: "15"
        // },
        legend: {
          right: 40,
          icon: 'circle',
          data: ['安全培训', '安全考试']
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            formatter: function (value) {
              return value.split("").join("\n");
            },
          },
          data: chartXData
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '安全培训',
          data: chartEducationData,
          type: 'bar'
        }, {
          name: '安全考试',
          data: chartExamData,
          type: 'bar'
        }]
      });
    } catch(e) {

    }
   
  }

  function getOverviewStatistics() {
    const requestData = {
      projectId: React.$getStorage('projectInfo').id
    }
    React.$axios.post('educationOverviewStatistics', requestData).then(({ data }) => {
      setOverviewStatistics(data)
      drawEductionChart(data.countTeam);
    })
  }
  function getTrainingUser() {
    const requestData = {
      offsetLimit: 0,
      lengthLimit: 20,
      logic: 2
    }
    React.$axios.post('listEducationTrainingUser', requestData).then(({ data }) => {
      setTrainingUser(data.list)
    })
  }
  function getExamPaperUser() {
    const requestData = {
      projectId: React.$getStorage('projectInfo').id,
      offsetLimit: 0,
      lengthLimit: 20,
      logic: 2
    }
    React.$axios.post('listEducationExamPaperUser', requestData).then(({ data }) => {
      setExamPaperUser(data.list)
    })
  }
  return (
    <div className="educationOverview">
      <div className="overview-left">
        <div className="statistics-wrap">
          <h2>安全教育概览</h2>

          <div className="list">

            <div className="statistics-item">
              <p>实名登记人数</p>
              <p>
                <span className="num">{overviewStatistics.userCount}</span>
              </p>
            </div>
            <div className="statistics-item">
              <p>岗前安全培训</p>
              <p>
                <span className="num">{overviewStatistics.trainingUserCount}</span>
                <span>人次</span>
              </p>
            </div>
            <div className="statistics-item">
              <p>安全教育考试</p>
              <p>
                <span className="num">{overviewStatistics.examUserCount}</span>
                <span>人次</span>
              </p>
            </div>
            <div className="statistics-item">
              <p>安全教育覆盖率</p>
              <p>
                <span className="num">{overviewStatistics.trainingUserRatio}</span>
                <span>%</span>
                <ArrowUpOutlined style={{ marginLeft: '20px', color: '#16E9EC' }} />
              </p>
            </div>


          </div>
        
        </div>
        <Carousel autoplay={true} dots={false}>
          {
            swiperList.map((item, index) => {
              return (
                <div>
                  <div className="education-message">
                    <div className="message-left">
                      <p>
                        <span>安全教育</span>
                        <span>Safety education</span>
                      </p>
                      <p>
                        <span>{item.trainingUser.name}</span>
                      </p>
                      <p className="red-point">刚通过安全教育培训</p>
                      <p className="blue-point">培训内容：{item.trainingUser.nameCourse}</p>
                      <p className="green-point">培训时间：{formatTime(new Date(item.trainingUser.addTime * 1000), 'yyyy-MM-dd hh:mm:ss')}</p>
                      <img className="avatar" src={imgUrl + item.trainingUser.avatarKeyCutoutInfo} alt="" />
                    </div>
                    <div className="message-right">
                      <p>
                        <span>安全考试</span>
                        <span>Safety education</span>
                      </p>
                      <p>
                        <span>{item.examPaperUser.name}</span>
                        <span className="yellow-point">成绩：{item.examPaperUser.fraction}分</span>
                      </p>
                      <p className="red-point">刚通过安全教育考试</p>
                      <p className="blue-point">考试内容：{item.examPaperUser.nameExamPaper}</p>
                      <p className="green-point">考试时间：{formatTime(new Date(item.examPaperUser.addTime * 1000), 'yyyy-MM-dd hh:mm:ss')}</p>
                      <img className="avatar" src={imgUrl + item.examPaperUser.avatarKeyCutoutInfo} alt="" />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </Carousel>
        <div className="chart-wrap">
          <h2>安全教育统计</h2>

          <div id="educationChart" className="educationChart"></div>
        </div>
      </div>
      <div className="overview-right">
        <div className="btn-list">
          <span className={navIndex === 0 ? 'active' : ''} onClick={() => toSelecteNav(0)}>安全教育培训</span>
          <span className={navIndex === 1 ? 'active' : ''} onClick={() => toSelecteNav(1)}>安全教育考试</span>
        </div>
        <div className="education-list">
          {
            navIndex === 0 && trainingUser.map((item, index) => {
              return (
                <div className="education-item" key={index}>
                  <div className="left">
                    <img src={imgUrl + item.avatarKeyCutoutInfo} alt="" />
                  </div>
                  <div className="right">
                    <p>
                      <span>{item.name}</span>
                    </p>
                    <p>
                      <span>考试内容：{item.nameCourse}</span>
                      <span>{formatTime(new Date(item.addTime * 1000), 'yyyy-MM-dd')}</span>
                    </p>
                  </div>
                </div>
              )
            })
          }
          {
            navIndex === 1 && examPaperUser.map((item, index) => {
              return (
                <div className="education-item" key={index}>
                  <div className="left">
                    <img src={imgUrl + item.avatarKeyCutoutInfo} alt="" />
                  </div>
                  <div className="right">
                    <p>
                      <span>{item.name}</span>
                      <span className="fraction">{item.fraction}分</span>
                    </p>
                    <p>
                      <span>考试内容：{item.nameExamPaper}</span>
                      <span>{formatTime(new Date(item.addTime * 1000), 'yyyy-MM-dd')}</span>
                    </p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default withRouter(EducationOverview);