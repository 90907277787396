import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import './earth.less'


import * as THREE from './three.module.js';



import {
    OrbitControls
} from "three/examples/jsm/controls/OrbitControls";

import { OBJLoader } from './threeSouce/OBJLoader.js';
import { MTLLoader } from './threeSouce/MTLLoader.js';
import { DDSLoader } from './threeSouce/DDSLoader.js';

import objModle1 from './accets/China01.obj';
import objModle2 from './accets/China02.obj';
import objModle3 from './accets/China03.obj';
import obj02 from './accets/02.obj';
import img1 from './accets/01.png';

import PyramidObj from '@/assets/img/modle/biaoji/Pyramid.obj';
import Pyramidimg from '@/assets/img/modle/biaoji/01.jpg';


import mtl from './accets/02.mtl';
import img2 from './accets/1.png';
import huan from './accets/huan.png'; // 圆环图片
import centerImg from './accets/02.png'; // 机电中心图片 

import CylinderObj from './accets/Cylinder001.obj' // 圆柱模型;

import {Alldata, FlyData} from './accets/province';
import {canvasFont, canvasAddImgFont, canvasCenter} from './threeSouce/earthCanvas';

import {FlyLine} from './method/flyLineVersion2';

// 发光的方法
// import {EffectComposer} from './threeSouce/EffectComposer';
// import {RenderPass} from './threeSouce/RenderPass';
// import {ShaderPass} from './threeSouce/ShaderPass';
// import {UnrealBloomPass} from './threeSouce/UnrealBloomPass';




let FontObje = null, opacity = 1,rotationY = 0, canvasList=[], linegroup = [], circleGroup = [];
let finalComposer = null,composer = null, bloomPass = null,bloomComposer = null, bloomLayer = null, materials = {}, darkMaterial= null; // 发光材料;
const ENTIRE_SCENE = 0, BLOOM_SCENE = 1;
function Earth(props) {
    let container  = null
    let map = null,
    renderer = null,
    scene = null,
    camera = null,
    light1 = null,
    light2 = null,
    controls = null,
    circleObj = new THREE.Object3D(),
    ObjGroup = new THREE.Object3D(),
    fontGroup = new THREE.Object3D(),
    imgFontGroup = new THREE.Object3D(),
    labelRenderer = null,
    AnimationFrameRender = null,
 
    AnimationFrameAll = null;
    

    let changePos = {x: '51',y:12, z: 16}

    const [propsState, setPropsState] = useState(props);


    useEffect(() => {

        getgetThreeEarth()
        return () => {
            // cancelAnimationFrame(AnimationFrameRender); 
            cancelAnimationFrame(AnimationFrameAll); 

            clearDom()
    
          }


    }, propsState)

    const clearDom = () => {

    }

    const getgetThreeEarth = () => {

      container = document.getElementById("containerHHback");

      renderer = new THREE.WebGLRenderer();
      
      renderer.setClearColor('#5C6E78', 1.0);
      

      renderer.setSize(window.innerWidth, window.innerHeight);
      // renderer.toneMapping = THREE.ReinhardToneMapping; // 曝光值
      container.appendChild(renderer.domElement);
      

      scene = new THREE.Scene();
      
      scene.position.y = -100;

      scene.background = '#5C6E78';  

      scene.add(circleObj)
      scene.add(ObjGroup);
      scene.add(fontGroup);
      scene.add(imgFontGroup);

      camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 10000);
      camera.position.set(0, 350, 250);
      camera.lookAt(0, 0, 0);


      let ambientLight = new THREE.AmbientLight(0xffffff); // 环境光
      scene.add(ambientLight);
      controls = new OrbitControls(camera, renderer.domElement);

      // 创建一个发光的图层

      // const params = {
			// 	exposure: 5,
			// 	bloomStrength: 1.5,
			// 	bloomThreshold: 0,
			// 	bloomRadius: 0
			// };

      // const renderScene = new RenderPass( scene, camera );

      // bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
      // bloomPass.threshold = params.bloomThreshold;
      // bloomPass.strength = params.bloomStrength;
      // bloomPass.radius = params.bloomRadius;

      // composer = new EffectComposer( renderer );
			// composer.addPass( renderScene );
			// composer.addPass( bloomPass );


      // var geometry = new THREE.BoxGeometry( 10, 10, 10 );
      // const material = new THREE.MeshLambertMaterial( {color: 0xff0000, emissive: 0x00ff00 ,  emissiveIntensity: 1.0} );
      // const cube = new THREE.Mesh( geometry, material );
      // scene.add( cube );



        
        
      
      loadModle(objModle3, 0xAF9346)  
      loadModle(objModle1, 0x181818)
      loadModle2(objModle2, 0xCFAB52)   
 
    //  scatterCircle(1, 0.1, 0.1, new THREE.Vector3(0, 1, 1), 0.004);  
    
    // 加载全部对应的字
     for(let i = 0; i < Alldata.length; i++) {
         let item = Alldata[i];
         item.Position.x = item.Position.x  - 10;
         item.Position.rotationZ = item.rotationZ;
        addFont(item.name, item.Position)
     }

    addFont('台湾', {x: 225 ,y:12, z: 20, rotationZ: 5}, '#ffffff')
    let ctx =  addFont('海南', {x: 45 ,y:12, z: 97, rotationZ: 5}, '#ffffff')
    
    
    canvasList.push(ctx);

     //  加载背部模型
     addPlant(img1, obj02, 2, true).then(object => {
      ObjGroup.add(object);
     });

    //   setRaycaster();
        // 设置动画

    // 加载金字塘形状
    addPlant(Pyramidimg, PyramidObj).then(obj => {
        obj.scale.set(0.3,0.3,0.3);
        obj.position.y = 15;
        obj.position.x = -10;
        // 全部数据容器
        let currnetObjGroup = new THREE.Group();
        ObjGroup.add(currnetObjGroup);
          // 添加带图片的文字容器
        currnetObjGroup.position.z = 0;
        currnetObjGroup.position.x = -120;

        // 加载圆
        createCircle(0x4B4B4B).then(group => {
          group.scale.set(2,2,2);
          group.position.y = -15;
          circleGroup.push(group);
          currnetObjGroup.add(group);

          // 圆柱模型加载
          addPlant(Pyramidimg, CylinderObj).then(object => {
            object.scale.set(0.4,0.4, 0.4);
            object.position.x = -10;
            currnetObjGroup.add(object)
          })
        });

        // 加载飞线
        for(let i = 0; i < FlyData.length; i++) {
            let item =FlyData[i]; 
             let mesh =  FlyLine(0, item.length, '#363E6F', '#1381AC')
            currnetObjGroup.add(mesh);
            linegroup.push({value: item.speed, mesh : mesh});
            mesh.position.z = item.z;
            mesh.position.x = item.x;
            mesh.position.y = 10;
            mesh.rotateY(-Math.PI/180 * item.rotateY);
        }


        // 加载圆和文字
        allLoadCircle(obj,  {name: '滇中引水昆明段', z: 30, x: -120})
        allLoadCircle(obj.clone(), {name: '滇中引水石鼓水源工程', z: -30, x: -145});
        allLoadCircle(obj.clone(), {name: '拉哇水电站C4标项目', z: -100, x: -145});
        allLoadCircle(obj.clone(), {name: '成都轨道交通19号线机电4工区', z: -100, x: -70});
        allLoadCircle(obj.clone(), {name: '宜昭高速施工总承包工程', z: -48, x: -93});
        allLoadCircle(obj.clone(), {name: '郑州市轨道交通8号楼一期工程', z: -150, x: 70});
        allLoadCircle(obj.clone(), {name: '深圳轨道交通十二号线', z: 20, x: 100});

        // 加载机电中心
        addloadCanvasCenter();
    }); 



    animate();

    }


    // 机电中心
    const addloadCanvasCenter = () => {
      canvasCenter(centerImg).then((canvas) => {
        let texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;

        let spriteMaterial = new THREE.SpriteMaterial({map:texture});
        let sprite =new THREE.Sprite(spriteMaterial);
        sprite.scale.set(100,13,1);
        sprite.position.set(0, 40, 0);

        let currnetObjGroup = new THREE.Group();
        currnetObjGroup.add(sprite);
        ObjGroup.add(currnetObjGroup);

        
        currnetObjGroup.position.z = 0;
        currnetObjGroup.position.x = -120;

      })
    }


     // 加载圆和文字
     const allLoadCircle = (obj, item) => {
        let currnetObjGroup = new THREE.Group();
        ObjGroup.add(currnetObjGroup);
        currnetObjGroup.add(obj);

        addImgFont(img2, item.name).then(imgObj => {
          // imgObj.
          currnetObjGroup.add(imgObj);
          currnetObjGroup.add(obj);
          currnetObjGroup.position.z = item.z;
          currnetObjGroup.position.x = item.x;
        });

        createCircle().then(group => {
          circleGroup.push(group);
          currnetObjGroup.add(group);
        });
    }


    // 添加图片文字
    const addImgFont = (img1, text) => {
        return new Promise((resolve) => {
          canvasAddImgFont(img1, text).then(canvas => {
              let texture = new THREE.Texture(canvas);
              texture.needsUpdate = true;
    
              let spriteMaterial = new THREE.SpriteMaterial({map:texture});
              let sprite =new THREE.Sprite(spriteMaterial);
              sprite.scale.set(80,10,1);

              
              sprite.position.set(0, 30, 0);
              resolve(sprite);
          })
        })
        
    }

  
    // // 添加文字
    const addFont = (text, pos ,color="#636363") => {
        let canvas = document.createElement("canvas");
        canvas.width =1000;
        canvas.height = 200;
        let ctx = canvas.getContext("2d");
        canvasFont(ctx, text, pos, color)

        // canvasList.push(ctx);

        let texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;
        // let material = new THREE.SpriteMaterial({map:texture});
        // let text = new THREE.Sprite(material);
        const geometry = new THREE.PlaneGeometry( 1000, 200);
        const material = new THREE.MeshLambertMaterial( {map: texture, side:THREE.DoubleSide, transparent:true} );
        const plane = new THREE.Mesh( geometry, material );
        plane.scale.set(0.1, 0.1, 0.1);
        plane.position.set(pos.x,12,pos.z);
        plane.rotateX(-Math.PI/180 * 90);

        plane.rotation.z = Math.PI/180 * pos.rotationZ; 

        // let data = Math.PI/180 * e.target.value;
        // FontObje.rotation.z = data;  // ();
        // FontObje = plane;
        fontGroup.add( plane);

        return {ctx: ctx,  mesh: plane};
    }


    // 创建圆
    const createCircle = (color=0x21BCFF) => {
      return new Promise((resolve) => {
        const geometry = new THREE.CircleGeometry( 2, 32 );
        const material = new THREE.MeshBasicMaterial( { color: color } );
        const circle = new THREE.Mesh( geometry, material );
        circle.position.y = 13; 
        circle.rotateX(-Math.PI/180 * 90);
  
        new THREE.TextureLoader().load(huan, (texture) => {
          const geometryImg = new THREE.CircleGeometry( 5, 32 );
          const materialImg = new THREE.MeshBasicMaterial( { map: texture, transparent:true} );
          const circleImg = new THREE.Mesh( geometryImg, materialImg );
          circleImg.position.y = 13; 
          circleImg.rotateX(-Math.PI/180 * 90);
          const cicleGroup = new THREE.Group();
          cicleGroup.add(circleImg)
          cicleGroup.add(circle)
          cicleGroup.position.x = -10;
          resolve(cicleGroup);

        })




      })
    }


        // 射线
    const setRaycaster = ()=> {

            let raycaster =  new THREE.Raycaster();
            let mouse = new THREE.Vector2();
            let eventOffset = {};
      
            function onMouseMove(event) {
              mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
              mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    
                try {
                    raycaster.setFromCamera(mouse, camera);
    
                    let Child =  raycaster.intersectObjects(scene.children, true);
                    
                    console.log(Child);
    
                }catch(e) {
                    console.log(e)
                }
            }
    
             window.addEventListener('click', onMouseMove, false)
      
    }

    


    // 加载图片模型
    const addPlant = (url, objModle1, index = 1, color = false) => {
            return new Promise((resolve) => {
                new THREE.TextureLoader().load(url,
                  (texture) => {
                      texture.wrapS = THREE.RepeatWrapping;
                      texture.wrapT = THREE.RepeatWrapping;
                      texture.repeat.set(index, index);
                      const manager = new THREE.LoadingManager();
                      manager.addHandler( /\.dds$/i, new DDSLoader() );

                      new MTLLoader( manager )
                          .load( mtl, function ( materials ) {
                              materials.preload();
                              new OBJLoader( manager )
                                  .setMaterials( materials )
                                  .load( objModle1, function ( object ) {

                                      object.traverse( function ( child ) {
                                          if ( child instanceof THREE.Mesh ) {
                                                  //每个储位单独赋予一个基础材质
                                                      if(color) { // 背景图其他面积颜色 一般不修改
                                                        child.material=
                                                        new THREE.MeshBasicMaterial({color: 0x2C2C2C});
                                                      }                                 
                                                      
                                                      child.material.map = texture;
                                              }
                                      } );
                                      
                                      resolve(object)
                                      // ObjGroup.add(object)
                              } );
                          } );
                  }
              );
            })
    }

    // 加载模型
    const loadModle = (objModle1, color) => {
      loadModleDefind(objModle1, mtl).then(object => {
        object.traverse( function ( child ) {
            if ( child instanceof THREE.Mesh ) {
                    //每个储位单独赋予一个基础材质                                   
                        child.material=
                        new THREE.MeshBasicMaterial({color: color});
                        // child.material.map = texture;
                }
        } ); 

        ObjGroup.add(object)
      })
    }

    const loadModle2 =  (objModle1, color) => {
      loadModleDefind(objModle1, mtl).then(object => {
        object.traverse( function ( child ) {
            if ( child instanceof THREE.Mesh ) {
                    //每个储位单独赋予一个基础材质                                   
                        child.material=
                        new THREE.MeshBasicMaterial({color: color});
                        // child.material.map = texture;
                }
        } ); 

        ObjGroup.add(object);

      })
    }


    // 加载自定义模型文件
    const loadModleDefind = (objModle1, mtl) => {
      return new Promise((resolve) => {
        const manager = new THREE.LoadingManager();
          manager.addHandler( /\.dds$/i, new DDSLoader() );
          new MTLLoader( manager )
            .load( mtl, function ( materials ) {
                materials.preload();
                new OBJLoader( manager )
                    .setMaterials( materials )
                    .load( objModle1, function ( object ) {
                    resolve(object)   
                } );
            } );
        })
    } 


    // 加载圆圈
    const scatterCircle = (r, init, ring, color, speed) => {
        

        const uniform = {
          u_color: { value: color },
          u_r: { value: init },
          u_ring: {
            value: ring,
          },
        };
      
        var vs = `
          varying vec3 vPosition;
          void main(){
            vPosition=position;
            gl_Position	= projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `;
        var fs = `
          varying vec3 vPosition;
          uniform vec3 u_color;
          uniform float u_r;
          uniform float u_ring;
      
          void main(){
            float pct=distance(vec2(vPosition.x,vPosition.y),vec2(0.0));
            if(pct>u_r || pct<(u_r-u_ring)){
              gl_FragColor = vec4(1.0,0.0,0.0,0);
            }else{
              float dis=(pct-(u_r-u_ring))/(u_r-u_ring);
              gl_FragColor = vec4(u_color,dis);
            }
          }
        `;
        const geometry = new THREE.CircleGeometry(r, 120);
        var material = new THREE.ShaderMaterial({
          vertexShader: vs,
          fragmentShader: fs,
          side: THREE.DoubleSide,
          uniforms: uniform,
          transparent: true,
          depthWrite: false,
        });
        const circle = new THREE.Mesh(geometry, material);
        circle.scale.x = circle.scale.y = circle.scale.z = 200;
        // circleObj.rotateX(-Math.PI/180 * 90);
        circleObj.add(circle)


      
        function render() {
          uniform.u_r.value += speed || 0.1;
          if (uniform.u_r.value >= r) {
            uniform.u_r.value = init;
          }

       
          AnimationFrameRender = requestAnimationFrame(render);
        }
        render();
        return circle;
      }


    function animate() { // 设置动画

        const time = Date.now() * 0.0005;
  
        AnimationFrameAll =  requestAnimationFrame(animate);

        rotationY += 0.005; 

        // if(opacity > 1) {
        //     opacity = 0;
        // }


        // for(let i = 0; i < canvasList.length; i++) {
        //     let ctx = canvasList[i].ctx;
        //     let mesh = mesh;
        //     ctx.clearRect(0, 0, 1000, 200);
        //     canvasFont(ctx, '海南', {x: 45 ,y:12, z: 97, rotationZ: 5}, '#ffffff')
        // }


        if(linegroup.length){
          for(var i = 0;i<linegroup.length;i++){
              var flyline = linegroup[i].mesh;
              if(flyline && flyline.material.uniforms){
                  var time2 = flyline.material.uniforms.time.value;
                  var size = flyline.material.uniforms.size.value;
                  if(time2 > flyline.maxx){
                      flyline.material.uniforms.time.value = flyline.minx - size;
                  }
                  flyline.material.uniforms.time.value += linegroup[i].value;
              }
          }
      }

      if(circleGroup.length) {
        for(let i = 0; i < circleGroup.length; i++) {
          //circleGroup[i].rotateY(-Math.PI/180 * rotationY);
          
          circleGroup[i].rotation.y += 0.01;

          


        }
      }


      // composer.render();

        renderer.render(scene, camera);

        // renderBloom();
        // finalComposer.render();
        // controls.update();
      }

    const  renderBloom = () => {
      scene.traverse( darkenNonBloomed );
			bloomComposer.render();
			scene.traverse( restoreMaterial );
    }

    const darkenNonBloomed = (obj) => {
      if ( obj.isMesh && bloomLayer.test( obj.layers ) === false ) {

        materials[ obj.uuid ] = obj.material;
        obj.material = darkMaterial;

      }
    }

    const restoreMaterial = (obj) => {
      if ( materials[ obj.uuid ] ) {

        obj.material = materials[ obj.uuid ];
        delete materials[ obj.uuid ];

      }
    }


    //   // 
     const inputChangeX = (e) => {
        FontObje.position.x = e.target.value;
       

     } 

     const inputChangeZ = (e) => {
        FontObje.position.z = e.target.value;
     }

    

     const rationChange = (e) => {
        let data = Math.PI/180 * e.target.value;
        FontObje.rotation.z = data;  // ();
     }

    
   
  return (
    <div className="EarthEchart">
        <input type="text" onChange={(e) => {inputChangeX(e, 'x')}} />
        <input type="text" onChange={(e) => {inputChangeZ(e, 'z')}} />
        <input type="text" onChange={(e) => {rationChange(e)}} />

    <div>
        <div id="containerHHback" className="echart" style={{width: '100%', height: '100%'}}>

        </div>
    </div>
    
        

    </div >
  )
}

export default withRouter(Earth);