import React from "react";


import { DatePicker,Input,Select,Button, Table,  Image, message, Modal} from "antd";


import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import './AIRebar.less';

import {formatTime} from '@/utils/util'

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

const {Option} =  Select;
const { RangePicker } = DatePicker;



let imgUrl = process.env.REACT_APP_IMG_URL
let width = 120// 设置图片大小
let height = 100


const jsonFieldsList = {
  '0_时间':  {
    field: 'time',
        callback: value => {
            return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
        }
    },
  '1_姓名': 'nameCard',
  "2_批次号码": 'prodNo',

  "3_生产厂家": 'enterprise',
  "4_偏差情况(%)":{
    field: "deviation",
    callback: value => {
      return Math.floor(value * 100) / 100
    }
  }, 

  "5_理论重量(t)": {
    field: "theoreticalWeight",
    callback: value => {
      return Math.floor(value * 100) / 100
    }
  },
  "5_实际重量(t)": {
    field : "actualWeight",
    callback: value => {
      return Math.floor(value * 1000) / 1000
    }
  },

  "7_备注": {
    field: 'remark',
    callback: value => {
      return value ? value : ' '
    }  
  },
  "8_外观图片": {
    field: 'exteriorImg',
    callback: value => {
      let arr = value.split(',')

      let str = '';
      arr.map(item => {
        str  +=  '<div><img src=\'' + imgUrl + item + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>'
      })

      return '<div style="display:inline; width:200px; height:180px;">'+ str +'</div>'
    }
  },
  "9_清单图片": {
    field: 'checklistImg',
    callback: value => {
      value = imgUrl + value
          return '<div style="display:inline; width:100px; height:130px;"><img src=\'' + value + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>'
    }
  },
}


const jsonFieldsList2 = {
  "0_序号": {
    field: 'batchNo',
    callback: value => {
      return '第' + value + '捆'
    }
  },
  "1_钢筋类型": "nameType",
  "2_根数": "length",
  "3_规格φ（mm）": "proportionSpecification",
  "4_单根长度": "length",
  "5_比重（kg/m）": "nameSpecification",
  "6_偏差情况(%)": "deviation",
  "7_理论重量(t)": {
    field: "theoreticalWeight",
      callback: value => {
        return Math.floor(value * 100) / 100
      }
    },
    "8_实际重量(t)": {
      field : "actualWeight",
      callback: value => {
        return Math.floor(value * 1000) / 1000
      }
    },
}



export default class BehaviorManagement extends React.Component {
  loadExcelRef = null
  
    columns = [
        {
            title: '时间',
            dataIndex: 'time',
            align: 'center',
            render: (_) => {
                return (
                    <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}</p>
                )
            }
        },
        {
          title: '姓名',
          dataIndex: 'nameCard',
          align: 'center'
        },
        {
            title: '批次号码',
            dataIndex: 'prodNo',
            align: 'center'
         },
        {
          title: '生产厂家',
          dataIndex: 'enterprise',
          align: 'center'
        },
        {
          title: '偏差情况(%)',
          dataIndex: 'deviation',
          align: 'center' ,
          render: (_) => {
            return (
              <p>{Math.floor(_ * 100) / 100}</p>
            )
          }   
        },
        {
            title: '理论重量(t)',
            dataIndex: 'theoreticalWeight',
            align: 'center',
            render: (_) => {
              return (
                <p>{Math.floor(_ * 100) / 100}</p>
              )
            }  
        },
        {
            title: '实际重量(t)',
            dataIndex: 'actualWeight',
            align: 'center',
            render: (_) => {
              return (
                <p>{Math.floor(_ * 1000) / 1000}</p>
              )
            }  
        },
        {
            title: '备注',
            dataIndex: 'remark',
            align: 'center'
        },
        {
            title: '操作',
            dataIndex: 'remark',
            align: 'center',
            render:(_,item) => {
                return (
                    <p onClick={this.showModule.bind(this, item.id)} style={{color: '#6A9BF4', cursor:'pointer'}}>查看钢筋列表</p>
                )
            }
        },
      ]

      columnsRebar = [
        {
            title: '序号',
            dataIndex: 'batchNo',
            align: 'center',
            render: (_) => {
                return (
                    <p>第{_}捆</p>
                )
            }
          },
          {
            title: '钢筋类型',
            dataIndex: 'nameType',
            align: 'center'
          },
          
          {
            title: '根数',
            dataIndex: 'quantity',
            align: 'center',
          },
          {
            title: '规格φ（mm）',
            dataIndex: 'proportionSpecification',
            align: 'center'  
          },
          {
             title: "单根长度",
             dataIndex: 'length',
             align: 'center', 
          },
          {
              title: "比重（kg/m）",
              dataIndex: 'nameSpecification',
              align: "center"
          },
          {
            title: '偏差情况(%)',
            dataIndex: 'deviation',
            align: 'center',
            render: (_) => {
              return (
                <p>{Math.floor(_ * 100) / 100}</p>
              )
            }  
          },
          {
              title: '理论重量(t)',
              dataIndex: 'theoreticalWeight',
              align: 'center',
              render: (_) => {
                return (
                  <p>{Math.floor(_ * 100) / 100}</p>
                )
              }  
          },
          {
              title: '实际重量(t)',
              dataIndex: 'actualWeight',
              align: 'center' 
          },
          {
              title: '图片',
              dataIndex: 'img',
              align: 'center',
              render: (_) => {
                  if(_) {
                    return (
                      <Image
                      width={50}
                      src={imgUrl + _}
                      /> 
                    )
                  } else {
                    return (
                      <p>-</p>
                    )
                  }


                  
              }
          }
      ]


  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    TableParams: {
        keyword: "",
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 2,
        timeStart: null,
        timeStop: null
    },

    paginationRebar: {
        current: 1,
        pageSize: 10,
        total: 200,
    },

    tableDataRebar: [],
    isModalVisible: false,

    ExcelName: '导出',
  }




  componentDidMount() {
    this.getTableData()
  }

  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

      React.$axios.post('listRebar', this.state.TableParams).then(res => {
        this.state.pagination.total = res.data.countList

        res.data.list.map(item => {
            item.description =this.setDescription(item);
        })

        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  // 设置描述信息
  setDescription(item) {
    let arr = []
      try {
        arr  = item.exteriorImg.split(',')
      } catch(e) {
        arr = []
      }

      
    return (
        <div className = "setexImg">
            <div>
                <h1>外观图片</h1>
                    {
                        arr.map(item2 => {
                            return (
                                <span>
                                    <Image
                                    width={100}
                                    src={imgUrl + item2}
                                    /> 
                                </span>
                            )
                        })
                    }
            </div>
            <div className="setqindan">
                <h1>清单图片</h1>
                <Image
                    width={100}
                    src={imgUrl + item.checklistImg}
                /> 
            </div>
        </div>
    )
  }


  serchCar(name, {target:{value}}) {
    this.state.TableParams.keyword = value
  }

  onChangeWork(name, value) {
    this.state.TableParams.type = value
  }

  toSerch2() {
    this.getTableData(true)
  }

  // 获取钢筋列表详情
  getListReBarInfo(id) {
    React.$axios.post('listRebar', {id: id, logic: 4}).then(res => {
        this.setState({
            tableDataRebar: res.data.info.batchMany
        })

    })
  }

  showModule(item) {
      this.getListReBarInfo(item)
      this.state.paginationRebar.current = 1
      this.setState({
        isModalVisible: true,
        paginationRebar:this.state.paginationRebar
      })
  }

  handleOk() {
    this.setState({
        isModalVisible: false
    })
  }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  getChangeTime(val) {
    this.state.TableParams.timeStart =  parseInt((+val[0]._d) / 1000) 
    this.state.TableParams.timeStop = parseInt((+val[1]._d) / 1000)
  }


  handleTableChange(value) {
    this.state.pagination = value
    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }

  fetchFun() {
    let parmas = {
      keyword: this.state.TableParams.keyword,
      logic: 3,
      timeStart: this.state.TableParams.timeStart,
      timeStop: this.state.TableParams.timeStop,
    }

    this.setState({
      ExcelName: formatTime(new Date(), 'AI钢筋yyyyMMdd') + '.xls'
    })


    return new Promise((resolve) => {
      React.$axios.post('listRebar', parmas).then(res => {
        let num = 0
        res.data.list.map(item => {
          num += item.actualWeight
        })

        num =  Math.floor(num * 1000) / 1000

        this.setState({
          footerData : [
            {
                thStyle: `style=height:200px;`,
                html: `<div style=' text-align: center; display:black; height:200px;' >总计实际重量(t): ${num}</div>`,
                colspan: 10
            }
          ]
        })


        resolve(res.data.list)
      })
    })
    
  }

  fetchFunKun() {
    return new Promise((resolve) => {
      let num = 0
      let length = 0
      this.state.tableDataRebar.map(item => {
        num += item.actualWeight
        length += item.length
      })

      this.setState({
        ExcelName: formatTime(new Date(), 'AI钢筋yyyyMMdd') + '.xls'
      })


      this.setState({
        footerData : [
          {
              thStyle: `style=height:200px;`,
              html: `<div style=' text-align: center; display:black; height:200px;' >总计实际重量(t): ${num}   总计根数: ${length}</div>`,
              colspan: 10
          }
        ]
      })


      resolve(this.state.tableDataRebar)

    })

  }

  render() {
    return (
      <div className="AIRebar">
          <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>关键字:</span> 
                        <Input onChange={this.serchCar.bind(this, 'keyword')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                        
                        </li>
                        <li><span>时间:</span>  
                        <RangePicker onChange={this.getChangeTime.bind(this)} style={{  height:30, marginRight:20,  }} />
                                  
                                        
                        </li>

                            <Button  className="btn" style={{marginRight: 20}} onClick={this.toSerch2.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>

                            <DownloadExcel ref={(el) => this.loadExcelRef = el}  name={this.state.ExcelName} fields={jsonFieldsList} footer={this.state.footerData}   fetch={this.fetchFun.bind(this)} btnName="导出" />
                    </ul>
                </div>



                <div className="table2">
                {/* <div class="allBtn">
                   
                    <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                        导入
                    </Button>
                    <Button  className="bt" type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button>
                    <Button  className="bt" type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button>
                    </div> */}


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    expandable={{
                        expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
                        rowExpandable: record => record.name !== 'Not Expandable',
                      }}
                    loading={this.state.loading} rowKey={record => record.id} columns={this.columns} dataSource={this.state.tableData}  />
                </div>


                                                                                               
                <Modal title="钢筋列表" width="80%" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} pagination={this.state.paginationRebar}  onCancel={this.handleCancel.bind(this)}>
                    <DownloadExcel   name={this.state.ExcelName} fields={jsonFieldsList2} footer={this.state.footerData}   fetch={this.fetchFunKun.bind(this)} btnName="导出" />
                    <Table  style={{marginTop:20}} align="center" bordered rowClassName="editable-rowRebar" 
                     rowKey={record => record.id} columns={this.columnsRebar} dataSource={this.state.tableDataRebar}  />


                </Modal>


      </div>
    );
  }
}
