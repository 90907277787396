import React from "react";


import { DatePicker,Input,Select,Button, Table,  Image, message, Modal} from "antd";


import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import './consult.less';

import {formatTime} from '@/utils/util'

const {Option} =  Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


let imgUrl = process.env.REACT_APP_IMG_URL


export default class  Consult extends React.Component {
    columns = [
      {
        title: "序号",
        dataIndex: 'title',
        align: 'center',
        render: (_, item, index) => {
          return (
            <p>{index + 1}</p>
          )
        }
      },
        {
          title: '标题',
          dataIndex: 'title',
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'type',
          align: 'center',
          render: (_) => {
            return (
              <p>{_==2? '通知': _==3? '意见征集':_==4?'投票':'表决'}</p>
            )
          }
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            align: 'center',
            render: (_) => {
                return (
                    <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')}</p>
                )
            }
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            align: 'center',
            render: (_) => {
                return (
                    <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')}</p>
                )
            }
        },
        {
            title: '范围',
            dataIndex: 'range',
            align: 'center',
            render: (_) => {
              return (
                <p>{_==2? '党员':_==3?'预备党员':_==4?'群众': '全部'}</p>
              )
            }
         },
        // {
        //   title: '人数',
        //   dataIndex: 'userQuantity',
        //   align: 'center'
        // },
        // {
        //   title: '票数',
        //   dataIndex: 'userQuantity',
        //   align: 'center'
        // },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          render: (_) => {
            return (
              <p className={"setStatusColor" + _}>{_==2? '未开始':_==3?'进行中': '已结束'}</p>
            )
          }
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            align: 'center',
            render: (_) => {
              return (
                <p className="remarks">{_}</p>
              )
            }
        },
        {
            title: '操作',
            dataIndex: 'remark',
            align: 'center',
            render:(_,item) => {
                return (
                    <p onClick={this.showModule.bind(this, item)} style={{color: '#6A9BF4', cursor:'pointer'}}>详情</p>
                )
            }
        },
      ]


  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    TableParams: {
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 2,
        status: "", // 状态
        title: "", // 标题
        type: "" // 类型
    },

    isModalVisible: false,


    typeText: null,
    typeName: null,
    showMoreText: false,
    currnetSurplusTime: null,
    infoText: {
      remarks: null,
      remarks2: null,
      resultMe: {

      },
      optionSetting: []
    },

  }




  componentDidMount() {
    this.getTableData()
  }

  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

      React.$axios.post('listBuildingNegotiate', this.state.TableParams).then(res => {
        this.state.pagination.total = res.data.countList

        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }





  serchCar(name, {target:{value}}) {
    this.state.TableParams.title = value
  }

  onChangeWork(name, value) {
    this.state.TableParams.type = value
  }

  toSerch2() {
    this.getTableData(true)
  }

  // 获取钢筋列表详情
  getListReBarInfo(id, type) {
    console.log(id)
      let params = {
        id: id,
        logic: 4
      }

      if(type == 3 || type == 5) {
        params.resultMe = 2
      }

      if(type == 4) {
        params.optionSetting = 2
      }


      React.$axios.post('listBuildingNegotiate', params).then(res => {

        if(!res.data.info.remarks) {
          res.data.info.remarks = ''
        }
        if(res.data.info.remarks.length > 100) {
          res.data.info.remarks2 = res.data.info.remarks.substring(0, 80) + '......';
          res.data.info.moreStatus = true
        } else {
          res.data.info.remarks2 = res.data.info.remarks
          res.data.info.moreStatus = false
        }

        if(!res.data.info.resultMe) { // 意见
          res.data.info.resultMe = {}
        }

        if(!res.data.info.optionSetting) { // 投票
          res.data.info.optionSetting = []
        }


        let time3 = parseInt(new Date().getTime()/1000)
        let time =  this.surplusTime(time3,res.data.info.endTime)
        let ct =  time.day * 24 + time.hour + 1

        if(ct < 0) {
          ct = 0
        }

        res.data.info.ct = ct


        if(type == 5) {
          let data = this.setRate(res.data.info.agreeQuantity, res.data.info.opposeQuantity)
          res.data.info.left = data.left
          res.data.info.right = data.right
        }

   
        this.setState({
          infoText: res.data.info,
          showMoreText: false
        })

      })
  }


  setRate(a = 50, b = 50) { // 设置比率
    
    if(a==0 &&b == 0) {
      a = 1
      b = 1
    }

    let c = a + b

    let d = a / c * 60 + 20
    let e = b / c * 60 + 20

    return {
      left: d,
      right: e
    }
  }






  surplusTime(oldDate, newDate) { // 时间差
    let second = newDate - oldDate
    let day = Math.floor(second / 86400);
    second = second % 86400;
    let hour = Math.floor(second / 3600);
    return {
      day: day,
      hour: hour
    }
  }

  showModule(item) {
      this.getListReBarInfo(item.id, item.type)

      this.setState({
        isModalVisible: true,
        typeText: item.type,
        typeName: item.type == 2? '通知': item.type == 3? '意见征集': item.type == 4 ? '投票': '表决'
      })
  }

  handleOk() {
    this.setState({
        isModalVisible: false
    })
  }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }




  handleTableChange(value) {
    this.state.pagination = value
    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }

  onChangeT(name, value) {
    this.state.TableParams[name]= value
    this.setState({
      TableParams: this.state.TableParams
    })
  }

  changeText() {
     this.state.showMoreText = !this.state.showMoreText
    this.setState({
      showMoreText: this.state.showMoreText
    })

  }

  render() {
    return (
      <div className="Consult">
          <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>标题:</span> 
                        <Input onChange={this.serchCar.bind(this, 'title')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                        
                        </li>
                        <li><span>类型:</span>  
                          <Select
                                showSearch
                                style={{ width: 200, height:30, marginRight:20,  }}
                                placeholder="请选择"
                                optionFilterProp="children"
                                onChange={this.onChangeT.bind(this, 'type')}
                            >
                            <Option value=" ">全部</Option>
                            <Option value="2">通知</Option>
                            <Option value="3">意见征集</Option>
                            <Option value="4">投票</Option>
                            <Option value="5">表决</Option>
                            </Select>
                        </li>

                        <li><span>状态:</span>  
                          <Select
                                showSearch
                                style={{ width: 200, height:30, marginRight:20,  }}
                                placeholder="请选择"
                                optionFilterProp="children"
                                onChange={this.onChangeT.bind(this, 'status')}
                            >
                            <Option value=" ">全部</Option>
                            <Option value="2">未开始</Option>
                            <Option value="3">进行中</Option>
                            <Option value="4">已结束</Option>
                            </Select>
                        </li>

                            <Button  className="btn" onClick={this.toSerch2.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>
                    </ul>
                </div>

                <div className="table2">
                {/* <div class="allBtn">
                   
                    <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                        导入
                    </Button>
                    <Button  className="bt" type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button>
                    <Button  className="bt" type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button>
                    </div> */}


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} columns={this.columns} dataSource={this.state.tableData}  />
                </div>


                                                                                               
                <Modal title={this.state.typeName} width="40%" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} pagination={this.state.paginationRebar}  onCancel={this.handleCancel.bind(this)}>
                     <div className="consultModal">
                        <ul className="titleUl" style={{display: this.state.typeText == 2? 'none': ''}}>
                          <li>
                            <h3>{this.state.infoText.userQuantity}</h3>
                            <h3>参与人数（人）</h3>
                          </li>
                          <li>
                            <h3>{this.state.infoText.ticketQuantity}</h3>
                            <h3>{this.state.typeText == 3? '累计意见（条）':'累计票数（票）'}</h3>
                            </li>
                          <li>
                            <h3>{this.state.infoText.ct}</h3>
                            <h3>剩余时间（h）</h3>
                          </li>
                        </ul>

                       <h1>{this.state.infoText.title}</h1>

                       <div className="status">
                        <span className={'statusColor' + this.state.infoText.status}>{this.state.infoText.status == 2? '未开始': this.state.infoText.status == 3?'进行中': '已结束'}</span>
                        <span>{this.state.typeName}</span>
                       </div>
                       <h2>{formatTime(new Date(this.state.infoText.startTime * 1000), 'yyyy-MM-dd hh:mm:ss')} 至 {formatTime(new Date(this.state.infoText.endTime * 1000), 'yyyy-MM-dd hh:mm:ss')}</h2> 
                  {
                    function(){
                      if(this.state.typeText == 2) { // 通知
                        return (
                          <p>{this.state.infoText.remarks}</p>
                        )
                      } else { // 意见征集
                        let p = null, status = null, dom = null
                        if(this.state.showMoreText) {
                          p = this.state.infoText.remarks
                          status = '收起'
                        } else {
                          p = this.state.infoText.remarks2
                          status = '查看更多'
                        }

                        if(this.state.typeText == 3) { // 意见征集
                          return (
                            <div>
                               <p>{p} <b onClick={this.changeText.bind(this)} style={{display: this.state.infoText.moreStatus? '': 'none'}}>{status}</b></p>
  
                               <div className="content">
                                {this.state.infoText.resultMe.remarks}
                               </div>
                            </div>
                           
                          )
                        }

                        if(this.state.typeText == 4) { // 投票
                          return (
                            <div>
                               <p>{p} <b onClick={this.changeText.bind(this)} style={{display: this.state.infoText.moreStatus? '': 'none'}}>{status}</b></p>
  
                               <ul className="userList">
                                  {
                                    this.state.infoText.optionSetting.map((item, index) => {
                                      return (
                                        <li >
                                          <h4><span>{index+1}</span>{item.name}</h4>
                                          <h4><i>{item.ticketQuantity}</i> 票</h4>
                                        </li>
                                      )
                                    })
                                  }
                                  
                               </ul>
                            </div>
                          )
                        }
                        
                        if(this.state.typeText == 5) { // 表决
                          return (
                            <div>
                               <p>{p} <b onClick={this.changeText.bind(this)} style={{display: this.state.infoText.moreStatus? '': 'none'}}>{status}</b></p>
  
                               <div className="agreen" >
                                  <div style={{width: this.state.infoText.left + '%'}}>
                                    <h5>同意</h5>
                                    <h5>({this.state.infoText.agreeQuantity}票)</h5>
                                  </div>
                                   
                                  <div style={{width: this.state.infoText.right + '%'}}>
                                    <h5>反对</h5>
                                    <h5>({this.state.infoText.opposeQuantity}票)</h5>
                                  </div>
                               </div>
                            </div>
                          )
                        }  
                      } 
                    }.bind(this)()
                  }
                  </div>    
                 
                </Modal>


      </div>
    );
  }
}
