import React from "react";

import {Button, Table, Modal, Form, Input, message, Image} from "antd";
// 添加furon

 
import { Uploadhh } from "@/components/upload/upload";

import './ProjectConfig.less';

import ProgressNode from "./ProgressNode/ProgressNode"


// import Organization from "./Organization/Organization"


import EnterpriseList from './enterpriseList/enterpriseList';
import TeamList from './teamList/teamList';

import WorkList from './workList/workList';


const { TextArea } = Input;



export default class ProjectConfig extends React.Component {
  FormSbmitRef = null
  imageUrl = process.env.REACT_APP_IMG_URL
  state = {
    status: null,
    loading: false,
    tableData: [],
    childBtn: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    params : {
        lengthLimit: 10,
        offsetLimit: 0
    },
    isModalVisible: false,
    requestParmas: {
        content: null,
        title: null,
        img: [],
        weigh: 0
    },
  } 
  


 columns = [
    {
      title: '标题',
      dataIndex: 'title',
      align: 'center',
      width: 200
    },
    {
      
      title: '权重',
      dataIndex: 'weigh',
      align: 'center',
      width: 100
    },
    {
      title: '内容',
      dataIndex: 'content',
      render:(_,item) => {
        return (
          <div>{!_?'-':_}</div>
        )
      }
    }, 
    {
      title: '操作',
      dataIndex: 'address',
      align: 'center',
      width: 200,
      render:(_,item) => {
        return (
          <div className="operation">
              <p onClick={this.clickFun.bind(this, item)} className="centp">编辑</p>
          </div>
        )
      }
    },
  ]


  handleTableChange(pagination) {

    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize

    this.setState({
      params: this.state.params,
      pagination: pagination
    })


    // 执行更新列表
    this.getList()
  }


  clickFun(item) {

    if(!item.img) {
        item.img = []
    }

    if(typeof(item.img) == 'string') {
        item.img = JSON.parse(item.img)
    }

   
    for(let i in this.state.requestParmas) {
        this.state.requestParmas[i] = item[i]
    }

    this.state.requestParmas.id = item.id

    this.setState({
        isModalVisible: true,
        requestParmas: this.state.requestParmas
    })

    console.log(this.state.requestParmas)
  }

  changeStatus(e) {
    this.setState({
      status: e
    })

    React.$setStorage('pageIndex', e)
 }

 
 componentWillMount () {
  let childBtn = React.$getStorage('menuchildBtn');
  for(let i = 0; i < childBtn.length; i++) {
    if(childBtn[i].show) {
      let item = childBtn[i];
      this.changeStatus(item.index);
      break;
    }
  }


  this.setState({
    childBtn: childBtn
  })


    let t =  React.$getStorage('pageIndex')
    if(t) {
        this.setState({
            status: t
        })
    }
 }

  componentDidMount() {
    this.getList()
  }


  // 组件离开生命周期
  componentWillUnmount() {
    localStorage.removeItem('pageIndex');
  }


  getList() {
    this.setState({
        loading: true
    })
    React.$axios.post('messageList', this.state.params).then(res => {
        this.state.pagination.total = res.data.countList
        this.setState({
            tableData: res.data.list,
            loading: false,
            pagination: this.state.pagination
        })

    })
  }


  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  handleOk() {


    this.FormSbmitRef.submit()

    React.$axios.post('messageEditSave', this.state.requestParmas).then(res => {
        if(res.code == 20000) {
            message.success('保存成功')
            this.handleCancel()
            this.getList()
        }
    })

  }

  // 点击新增
  add() {
      for(let i in this.state.requestParmas) {
        this.state.requestParmas[i] = ''
      }

      this.state.requestParmas.img = []
      this.state.requestParmas.weigh = 0


      this.setState({
        isModalVisible: true,
        requestParmas: this.state.requestParmas
      })
  }

  setInput(name, { target: { value } }) {

    this.state.requestParmas[name] = value
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  handleFinger(item) {
    this.state.requestParmas.img = [...this.state.requestParmas.img, ...item.data.key]
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  // 删除图片
  reMoveImgList(index) {
    this.state.requestParmas.img.splice(index, 1)
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }



  render() {
    return (
      <div className="ProjectConfig">
         <div className="btn">
         {
              this.state.childBtn.map(item => {
                  if(item.show) {
                      return (
                          <Button  type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                          ) 
                  }
                  
              })
          }

           {/* <Button  type={this.state.status==0? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 0)}>项目概览</Button>
           <Button className="setBtn" type={this.state.status==1? 'primary': ''}  onClick={this.changeStatus.bind(this, 1)}>进度节点</Button>
           
           <Button className="setBtn" type={this.state.status==3? 'primary': ''}  onClick={this.changeStatus.bind(this, 3)}>组织架构</Button> */}

           {/* <Button className="setBtn" type={this.state.status==2? 'primary': ''}  onClick={this.changeStatus.bind(this, 2)}>组织管理</Button> */}
           {/* <Button className="setBtn" type={this.state.status==4? 'primary': ''}  onClick={this.changeStatus.bind(this, 4)}>部门管理</Button>
           <Button className="setBtn" type={this.state.status==5? 'primary': ''}  onClick={this.changeStatus.bind(this, 5)}>岗位管理</Button> */}
           {/* <Button className="setBtn" type={this.state.status==6? 'primary': ''}  onClick={this.changeStatus.bind(this, 6)}>考勤管理</Button> */}
          </div>
          

          <div style={{display: this.state.status==0? 'block': 'none'}}>
            <div className="warp1">
                <div className="head">
                    {/* <Button type="error"></Button> */}
                    <button onClick={this.add.bind(this)}>新增</button>
                </div>


                <div className="table">
                <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id}  columns={this.columns} dataSource={this.state.tableData}  />

                </div>
            

            
            </div>
          </div> 

      
          <div style={{display: this.state.status==1? 'block': 'none'}}>
              {
                  function() {
                    if(this.state.status==1) {
                        return (
                            <ProgressNode />
                        )
                    }
                  }.bind(this)()
              }
          </div>

          <div style={{display: this.state.status == 2? 'block': 'none'}}>
              {
                function () {
                    if(this.state.status == 2) {
                        return (
                            <EnterpriseList />
                        )
                    }
                }.bind(this)()
              }
          </div>

          <div style={{display: this.state.status == 4? 'block': 'none'}}>
              {
                function () {
                    if(this.state.status == 4) {
                        return (
                            <TeamList/>
                        )
                    }
                }.bind(this)()
              }
          </div>

          <div style={{display: this.state.status == 5? 'block': 'none'}}>
              {
                function () {
                    if(this.state.status == 5) {
                        return (
                            <WorkList/>
                        )
                    }
                }.bind(this)()
              }
          </div>


        <Modal title="添加" 
        width="40%"
        okText="确定"
        cancelText="取消"
        visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
            <Form
                layout="vertical"
                initialValues = {this.state.requestParmas}
                
                ref={(el) => this.FormSbmitRef = el}
            >
                 <Form.Item
                    label="标题"
                    rules={[{ required: true, message: '请输入标题' }]}
                >
                    <Input placeholder="请输入标题" value={this.state.requestParmas.title} onChange={this.setInput.bind(this, 'title')} />
                </Form.Item>

                
                <Form.Item
                    label="内容"
                    rules={[{ required: true, message: '请输入内容!' }]}
                >
                    <TextArea rows={8} placeholder="请输入内容" value={this.state.requestParmas.content} onChange={this.setInput.bind(this, 'content')} />    
                </Form.Item>


                <Form.Item
                    label="权重(越大越靠前)"
                    rules={[{ required: true, message: '请输入权重' }]}
                >
                    <Input placeholder="请输入权重" value={this.state.requestParmas.weigh} onChange={this.setInput.bind(this, 'weigh')} />
                </Form.Item>


                <Form.Item
                    label="图片"
                    name="img"
                >   
                    <div className="flex">

                    {
                        <ul className="setFingerList flex">
                            <Image.PreviewGroup>
                                {
                                    this.state.requestParmas.img.map((item, index) => {
                                        return (
                                            <span style={{marginRight: 20, position: 'relative'}} key={index}>
                                            <span className="removeImgList2 setStyle"  onClick={()=> this.reMoveImgList(index, 'fingerList')}>×</span>
                                        <Image
                                            width={100}
                                            height={100}
                                            src={this.imageUrl + item}
                                        />
                                        </span>
                                        )
                                    })
                                } 
                            </Image.PreviewGroup>    
                        </ul> 
                    }



                    <Uploadhh  listStatus={true}   data={{type: 48}}  width={100} height={100} handleClick={this.handleFinger.bind(this)} />
                 </div>   
                </Form.Item>


            </Form>





        </Modal>    



      </div>

    );
  }
}
