import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import './AIRebar.less'

import AiRebarList from './page/AiRebar/AIRebar';
import Overview from './page/overview/overview';


function AIRebar() {
  const navList = ['验收概览', '验收记录'];
  const [navIndex, setNavIndex] = useState(null);
  const [childBtn ,setchildBtn] = useState([]);
  useEffect(() => {
    let childBtn = React.$getStorage('menuchildBtn');
      for(let i = 0; i < childBtn.length; i++) {
        if(childBtn[i].show) {
          let item = childBtn[i];
          toSelecteNav(item.index);
          break;
        }
      }
  
      setchildBtn(childBtn)
  }, [])

  function toSelecteNav(index) {

    
    setNavIndex(index)
  }
  return (
    <div className="diy-container safeEducation">
      <ul className="nav-list">
        {
          childBtn.map(((item, index) => {
            if(item.show) {
              return <li className={navIndex === item.index ? 'active' : ''} key={index} onClick={() => toSelecteNav(item.index)} >{item.name}</li>
            }
          }))
        }
      </ul>
      {navIndex === 0 && <Overview />}
      {navIndex === 1 && <AiRebarList />}
    </div>
  )
}

export default withRouter(AIRebar);