
import Home from '@/pages/Home/home'
import Error from '@/pages/Error/error'

// 项目概况
// import ProjectSummary from '@/pages/projectSummary/projectSummary'
import NewsList from '@/pages/projectSummary/newsList/newsList'; // 新闻列表

// 工程全景
import P720panorama from '@/pages/panorama/720panorama'
import Panoramamang from '@/pages/panorama/panoramamang/panoramamang'

// 全景监控
import VideoSurveillance from '@/pages/PanoramicMonitoring/VideoSurveillance/videoSurveillance'
import MonitoringManagement from '@/pages/PanoramicMonitoring/MonitoringManagement/monitoringManagement'
// import ProgressMonitoring from '@/pages/PanoramicMonitoring/ProgressMonitoring/progressMonitoring'

// 人员监管
import Overview from '@/pages/PersonnelSupervision/overview/overview' //人员概览
import Roster from '@/pages/PersonnelSupervision/roster/roster' // 人员花名册
import Attendance from '@/pages/PersonnelSupervision/attendance/attendance' // 考勤管理
import Wages from '@/pages/PersonnelSupervision/wages/wages' // 工资监管
import Certificates from '@/pages/PersonnelSupervision/certificates/certificates' // 人员证照
// import Behavior from '@/pages/PersonnelSupervision/behavior/behavior' // 行为管理
import ServicePoints from '@/pages/PersonnelSupervision/ServicePoints/ServicePoints'  // 劳务积分考评

// 生态监管
import EnvironmentalMonitoring from '@/pages/EcologicalSupervision/EnvironmentalMonitoring/environmentalMonitoring'
import WaterConservation from '@/pages/EcologicalSupervision/waterConservation/waterConservation'; // 水保监测
import FloodPrevention from '@/pages/EcologicalSupervision/FloodPrevention/FloodPrevention'; // 防汛监测
import Gas from '@/pages/EcologicalSupervision/Gas/Gas' // 气体监测


// 智慧党建
import partyHome from '@/pages/WisdomParty/Home/Home' // 首页
import partyOrganization from '@/pages/WisdomParty/organization/organization' // 组织机构
import partyActive from '@/pages/WisdomParty/partyActive/partyActive' // 党建活动
import partyConsult from '@/pages/WisdomParty/consult/consult' // 会议协商


// 安全监管
import SecurityInvestigation from '@/pages/SecurityInvestigation/SecurityInvestigation' // 隐患排查
import BehaviorManagement from '@/pages/SecurityInvestigation/BehaviorManagement/BehaviorManagement'; // 行为管理
import SafeEducation from '@/pages/SafeEducation/safeEducation'
import EmergencyManagement from '@/pages/SafeEducation/emergencyManagement/emergencyManagement';


// 质量监管
import MixingStationOverview from '@/pages/QualitySupervision/MixingStationOverview/mixingStationOverview'
import ProductionDetails from '@/pages/QualitySupervision/ProductionDetails/productionDetails'
import ConstructionProgress from '@/pages/QualitySupervision/ConstructionProgress2/ConstructionProgress2' // 施工进展
// import Cavern from '@/pages/QualitySupervision/Cavern/Cavern' // 洞室
// import DiversionCanal from '@/pages/QualitySupervision/DiversionCanal/DiversionCanal' // 引水渠、进水箱涵

// 设备监管
import VehiclePositioning from '@/pages/EquipmentSupervision/VehiclePositioning/VehiclePositioning' // 车辆定位
import DeviceInspectionDeviceList from '@/pages/EquipmentSupervision/deviceInspectionDeviceList/deviceInspectionDeviceList'; // 设备巡检
import VehicleControlList from '@/pages/EquipmentSupervision/vehicleControlList/vehicleControlList'; // 车辆进出
import MonitorLogList from '@/pages/EquipmentSupervision/monitorLogList/monitorLogList'; // 电箱监控记录
import MachineDeviceAll from '@/pages/EquipmentSupervision/machineDeviceAll/machineDeviceAll'; // 积分超市
import Distinguish from '@/pages/EquipmentSupervision/distinguish/distinguish';
import WaterLevel from '@/pages/EquipmentSupervision/waterLevel/waterLevel';
import Tower from '@/pages/EquipmentSupervision/Tower/Tower';
// import AIRebar from '@/pages/EquipmentSupervision/AIRebar/AiRebar'; 
// import AIRebar from '@/pages/EquipmentSupervision/AIRebar/AIRebar';// AI钢筋

import AIRebar from '@/pages/EquipmentSupervision/AIRebar/AIRebar';


//后台配置
import ProjectConfig from '@/pages/SPRO/ProjectConfig/ProjectConfig'; // 项目配置
import BannerConfig from '@/pages/SPRO/BannerConfig/BannerConfig'; // Banner 配置
import Jurisdiction from '@/pages/SPRO/Jurisdiction/jurisdiction'; // 权限管理
import Tunnel from '@/pages/SPRO/Tunnel/Tunnel'; // 隧道管理
import UseConfig from '@/pages/SPRO/UseConfig/UseConfig';


//图纸管理
import DrawingList from '@/pages/Drawing/DrawingList/DrawingList'; // 图纸列表
import DrawingManage from '@/pages/Drawing/DrawingManage/DrawingManage'; // 图纸管理


// 协同办公
import MeetingSignIn from '@/pages/CollaborativeOffice/MeetingSignIn/MeetingSignIn'; // 会议签到 
import Notice from '@/pages/CollaborativeOffice/Notice/Notice'; // 入场通知
import LeaveRequestForm from '@/pages/CollaborativeOffice/LeaveRequestForm/LeaveRequestForm'; // 请假单


// 拌合站监测
import BIDataKanban from '@/pages/MixingPlant/BIDataKanban/BIDataKanban'; // BI 数据看板
import TaskList from '@/pages/MixingPlant/TaskList/TaskList'; // 任务单
import ProductionTransportation from '@/pages/MixingPlant/ProductionTransportation/ProductionTransportation'; // 砼生产情况和运输记录
import MixingBill from '@/pages/MixingPlant/MixingBill/MixingBill'; // 搅拌站账单
import MixSerch from '@/pages/MixingPlant/MixSerch/MixSerch';
import Invoice from '@/pages/MixingPlant/Invoice/Invoice';


// 云开仓
import CastingPermit from '@/pages/CloudOpening/CastingPermit/CastingPermit'; // 准浇证
import BlankingList from '@/pages/CloudOpening/BlankingList/BlankingList'; // 下料单
import Engineering from '@/pages/CloudOpening/engineering/engineering'; // 工程
import OriginSpecification from '@/pages/CloudOpening/OriginSpecification/OriginSpecification' // 产地规格
import MixProportion from '@/pages/CloudOpening/MixProportion/MixProportion'; // 理论配合比
import ConstructionLog from '@/pages/CloudOpening/ConstructionLog/ConstructionLog'; // 施工日志



const routerList = [
  {
    name: '工作台',
    path: '/',
    show: true,
    component: VideoSurveillance
  },
  // {
  //   name: '项目概况',
  //   path: '/projectSummary/projectSummary',
  //   show: true,
  //   component: ProjectSummary
  // },
  {
    name: '新闻列表',
    path: '/projectSummary/NewsList',
    show: true,
    component: NewsList
  },
  {
    name: '720全景',
    show: true,
    path: '/panorama/720panorama',
    component: P720panorama
  },
  {
    name: '全景管理',
    show: true,
    path: '/panorama/projectSummary',
    component: Panoramamang
  },
  {
    name: '视频监控',
    show: true,
    path: '/PanoramicMonitoring/videoSurveillance',
    component: VideoSurveillance
  },
  {
    name: '监控管理',
    show: true,
    path: '/PanoramicMonitoring/monitoringManagement',
    component: MonitoringManagement
  },
  // {
  //   name: '进度及产值分析',
  //   show: false,
  //   path: '/PanoramicMonitoring/ProgressMonitoring/progressMonitoring',
  //   component: ProgressMonitoring
  // },
  {
    name: '人员概览',
    show: true,
    path: '/PersonnelSupervision/Overview',
    component: Overview
  },

  {
    name: '花名册管理',
    show: true,
    path: '/PersonnelSupervision/roster',
    component: Roster
  },

  {
    name: '考勤管理',
    show: true,
    path: '/PersonnelSupervision/Attendance',
    component: Attendance
  },

  {
    name: '工资监管',
    show: true,
    path: '/PersonnelSupervision/Wages',
    component: Wages
  },
  {
    name: '人员证照',
    show: true,
    path: '/PersonnelSupervision/Certificates',
    component: Certificates
  },
  // {
  //   name: '行为管理',
  //   show: true,
  //   path: '/PersonnelSupervision/Behavior',
  //   component: Behavior
  // },
  {
    name: '劳务积分考评',
    show: true,
    path: '/PersonnelSupervision/ServicePoints',
    component: ServicePoints
  },
  {
    name: '环保监测',
    path: '/EcologicalSupervision/environmentalMonitoring',
    show: true,
    component: EnvironmentalMonitoring
  },
  {
    name: "水保监测",
    path: "/EcologicalSupervision/WaterConservation",
    show: true,
    component: WaterConservation
  },
  {
    name: '防汛监测',
    path: '/EcologicalSupervision/FloodPrevention',
    show: true,
    component: FloodPrevention
  },
  {
    name: '气体监测',
    path: '/EcologicalSupervision/Gas',
    show: true,
    component: Gas
  },
  {
    name: '主页',
    path: '/WisdomParty/Home',
    show: true,
    component: partyHome
  },
  {
    name: '组织机构',
    path: '/WisdomParty/organization',
    show: true,
    component: partyOrganization
  },
  {
    name: '党建活动',
    path: '/WisdomParty/partyActive',
    show: true,
    component: partyActive
  },
  {
    name: '会议协商',
    path: '/WisdomParty/consult',
    show: true,
    component: partyConsult
  },
  {
    name: '隐患排查',
    path: '/SecurityInvestigation',
    show: true,
    component: SecurityInvestigation
  },
  {
    name: '行为管理',
    path: '/SecurityInvestigation/BehaviorManagement',
    show: true,
    component: BehaviorManagement
  },
  {
    name: '安全教育',
    path: '/SafeEducation/safeEducation',
    show: true,
    component: SafeEducation
  },
  {
    name: '应急处理',
    path: '/SafeEducation/emergencyManagement',
    show: true,
    component: EmergencyManagement
  },
  {
    name: '车辆定位',
    path: '/EquipmentSupervision/VehiclePositioning',
    show: true,
    component: VehiclePositioning
  },
  {
    name: '积分超市',
    path: '/EquipmentSupervision/MachineDeviceAll',
    show: true,
    component: MachineDeviceAll
  },
  {
    name: '人脸机',
    path: '/EquipmentSupervision/Distinguish',
    show: true,
    component: Distinguish
  },
  {
    name: '水位监测',
    path: '/EquipmentSupervision/WaterLevel',
    show: true,
    component: WaterLevel
  },
  {
    name: '塔吊检测',
    path: '/EquipmentSupervision/Tower',
    show: true,
    component: Tower
  },
  {
    name: 'AI钢筋',
    path: '/EquipmentSupervision/AIRebar',
    show: true,
    component: AIRebar
  },
  {
   name: '设备巡检',
   path: '/EquipmentSupervision/deviceInspectionDeviceList',
   show: true,
   component: DeviceInspectionDeviceList
  },
  {
    name: '车辆进出',
    path: '/EquipmentSupervision/vehicleControlList',
    show: true,
    component: VehicleControlList
  },
  {
    name: '电箱监测',
    path: '/EquipmentSupervision/monitorLogList',
    show: true,
    component: MonitorLogList
  },
  {
    name: '项目配置',
    path: '/SPRO/ProjectConfig',
    show: true,
    component: ProjectConfig
  },
  {
    name: 'Banner配置',
    path: '/SPRO/BannerConfig',
    show: true,
    component: BannerConfig
  },
  {
    name: '权限管理',
    path: '/SPRO/Jurisdiction',
    show: true,
    component: Jurisdiction
  },
  {
    name: '隧道管理',
    path: '/SPRO/Tunnel',
    show: true,
    component: Tunnel
  },
  {
    name: '拌合站概览',
    path: '/QualitySupervision/mixingStationOverview',
    show: true,
    component: MixingStationOverview
  },
  {
    name: '生产明细',
    path: '/QualitySupervision/productionDetails',
    show: true,
    component: ProductionDetails
  },
  {
    name: '隧洞',
    path: '/QualitySupervision/ConstructionProgress',
    show: true,
    component: ConstructionProgress
  },
  // {
  //   name: '洞室',
  //   path: '/QualitySupervision/Cavern',
  //   show: true,
  //   component: Cavern
  // },
  // {
  //   name: '引水渠、进水箱涵',
  //   path: '/QualitySupervision/DiversionCanal',
  //   show: true,
  //   component: DiversionCanal
  // },
  

  {
    name: '图纸列表',
    path: '/Drawing/DrawingList',
    show: true,
    component: DrawingList
  },
  {
    name: '图纸管理',
    path: '/Drawing/DrawingManage',
    show: true,
    component: DrawingManage
  },
  {
    name: '会议签到',
    path: '/CollaborativeOffice/MeetingSignIn',
    show: true,
    component: MeetingSignIn
  },
  {
    name: '入场通知',
    path: '/CollaborativeOffice/Notice',
    show: true,
    component: Notice
  },
  {
    name: '请假单',
    path: '/CollaborativeOffice/LeaveRequestForm',
    show: true,
    component: LeaveRequestForm
  },
  {
    name: 'BI数据看板',
    path: '/MixingPlant/BIDataKanban',
    show: true,
    component: BIDataKanban
  },
  {
    name: '任务单',
    path: '/MixingPlant/TaskList',
    show: true,
    component: TaskList
  },
  {
    name: '砼生产情况和运输记录',
    path: '/MixingPlant/ProductionTransportation',
    show: true,
    component: ProductionTransportation
  },
  {
    name: '账单/(日、月)',
    path: '/MixingPlant/MixingBill',
    show: true,
    component: MixingBill
  },
  {
    name: '配合比查询',
    path: '/MixingPlant/MixSerch',
    show: true,
    component: MixSerch
  },
  {
    name: '发货单查询',
    path: '/MixingPlant/Invoice',
    show: true,
    component: Invoice
  },
  {
    name: '准浇证',
    path: '/CloudOpening/CastingPermit',
    show: true,
    component: CastingPermit
  },
  {
    name: '下料单',
    path: '/CloudOpening/BlankingList',
    show: true,
    component: BlankingList
  },
  {
    name: '理论配合比',
    path: '/CloudOpening/MixProportion',
    show: true,
    component: MixProportion
  },
  {
    name: '工程',
    path: '/CloudOpening/Engineering',
    show: true,
    component: Engineering
  },
  {
    name: '产地规格',
    path: '/CloudOpening/OriginSpecification',
    show: true,
    component: OriginSpecification
  },
  {
    name: '施工日志',
    path:'/CloudOpening/ConstructionLog',
    show:true,
    component: ConstructionLog
  },
  {
    name: '功能配置',
    path: '/SPRO/useConfig',
    show: true,
    component:  UseConfig
  },
  {
    name: '404',
    path: '/error',
    show: false,
    component: Error
  }
];
export default routerList;