import React from "react";


import {Button,   Image, message} from "antd";

import MachineDeviceList from './machineDeviceList/machineDeviceList';
import MachineCommodityAll from './machineCommodityAll/machineCommodityAll';
import MachineRecord from './machineRecord/machineRecord';
import EmpowerDevice from './empowerDevice/empowerDevice';

export default class Home extends React.Component {


  state = {
    herfEarth: '',
    status: null,
    childBtn: []
  }

  componentWillMount () {
    let childBtn = React.$getStorage('menuchildBtn');
    for(let i = 0; i < childBtn.length; i++) {
      if(childBtn[i].show) {
        let item = childBtn[i];
        this.changeStatus(item.index);
        break;
      }
    }
  

    this.setState({
      childBtn: childBtn
    })


    let t =  React.$getStorage('jurIndexDev')
    if(t) {
        this.setState({
            status: t
        })
    }
 }

 // 组件离开生命周期
 componentWillUnmount() {
    localStorage.removeItem('jurIndexDev');
  }

  componentDidMount() {
    
   
  }


  changeStatus(type) {
    this.setState({
        status: type
    })

    React.$setStorage('jurIndexDev', type) 

  }



  render() {
    return (
      <div className="machineDeviceAll">
          <div className="btn" style={{paddingLeft: 10}}>
          {
              this.state.childBtn.map(item => {
                  if(item.show) {
                      return (
                          <Button  type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                          ) 
                  }
                  
              })
          }
          
          {/* <Button className="setBtn" type={this.state.status==3? 'primary': ''}  onClick={this.changeStatus.bind(this, 3)}>积分兑换</Button>
           <Button className="setBtn" type={this.state.status==2? 'primary': ''}  onClick={this.changeStatus.bind(this, 2)}>售货机商品</Button>
           <Button  type={this.state.status==4? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 4)}>售货机授权日志</Button>
           <Button  type={this.state.status==1? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 1)}>售货机设备</Button> */}
           
          </div>
        <div className="setTable" style={{margin:'0 10px'}}>
          {
            function() {
               if(this.state.status == 3) {
                 return (
                   <MachineDeviceList/>
                 )
               }
               
               if(this.state.status == 1) {
                 return (
                   <MachineCommodityAll/>
                 )
               }

               if(this.state.status == 0) {
                 return (
                   <MachineRecord />
                 )
               }

               if(this.state.status == 2) {
                 return (
                   <EmpowerDevice />
                 )
               }
              
            }.bind(this)()
          }
        </div>





      </div>
    );
  }
}
