import React from 'react'

import ReactEcharts from 'echarts-for-react';




export const EchartsSankey =   (


    class Modeal extends React.Component {
        state = {
            visible: true,
            imageUrl: '',
            loading: false,
        }


        componentDidMount() {

            
        }


        getOtion(){
            const option = {
                series: {
                     type: 'sankey',
                        layout: 'none',
                        emphasis: {
                            focus: 'adjacency'
                        },
                        data: [{
                            name: '工程现场人员'
                        }, {
                            name: '管理人员'
                        },
                        {
                            name: '劳务人员'
                        }, {
                            name: '未经教育'
                        },
                        {
                            name: '三级教育'
                        },
                        
                    ],
                        links: [{
                            source: '工程现场人员',
                            target: '管理人员',
                            value: 10
                        },

                        {
                            source: '工程现场人员',
                            target: '劳务人员',
                            value: 10
                        },
                        
                        {
                            source: '管理人员',
                            target: '未经教育',
                            value: 5
                        },
                        {
                            source: '劳务人员',
                            target: '未经教育',
                            value: 5
                        },
                        {
                            source: '管理人员',
                            target: '三级教育',
                            value: 5
                        },
                        {
                            source: '劳务人员',
                            target: '三级教育',
                            value: 5
                        },
                    ]
                    }
                };
                        return option;
        }
       

        render() {
            return <div>
                    <ReactEcharts
                    option={this.getOtion()}
                    style={{height: '250px',position:'relative',width: '100%',left: '-35px', zIndex: 100}}
                    className='react_for_echarts' />

            </div>
        }
    }
);