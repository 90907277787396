import React from "react";

import {Button, Table, Modal, Form, Input, message, Select, DatePicker} from "antd";



import './enterpriseList.less';

import moment from 'moment';


import {formatTime} from '@/utils/util';

// import TeamList from '../teamList/teamList';

const { Option } = Select;
const layout = {
    labelCol: { span: 16},
  };


export default class EnterpriseList extends React.Component {
  state = {
    status: 0,
    loading: false,
    tableData: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    requestParmas: {
        name: null,
        registeredAddress: null,
        legalRepresentative: null,
        phone: null,
        organizationCode: null,
        registeredCapital: null,
        unitType: null,
        weigh: 0,
        // teamId: []
    },
    params : {
        lengthLimit: 10,
        offsetLimit: 0
    },

    teamAllList: [],
  } 
  FormSbmitRef = null
  
 columns = [
    {
      title: '名称',
      dataIndex: 'name',
      align: 'center',
    },
    {
        title: '电话',
        dataIndex: 'phone',
        align: 'center',
    },
    {
        title: '注册地址',
        dataIndex: 'registeredAddress',
        align: 'center',
    },
    {
        title: '法人代表',
        dataIndex: 'legalRepresentative',
        align: 'center',
    },
    {
        title: '组织机构代码',
        dataIndex: 'organizationCode',
        align: 'center',
    },
    {
        title: '权重',
        dataIndex: 'weigh',
        align: 'center',
    },
    {
        title: '单位类型',
        dataIndex: 'unitType',
        align: 'center',
        render: (_) => {
            let name = this.unitTypeStatus(_)
            return (
                <p>{name}</p>
            )
        }
    },
    {
        title: '注册资金',
        dataIndex: 'registeredCapital',
        align: 'center',
    },
    {
      title: '添加时间',
      dataIndex: 'addTime',
      align: 'center',
      width: 250,
      render: (_) => {
        let date = null
        if(_ != 0) {
          date =formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')  
        } else {
          date = 0
        }
        return (
          <p>
            {
              date
            }
          </p>
        )
      }
    },

    {
      title: '操作',
      dataIndex: 'address',
      align: 'center',
      width: 150,
      render:(_,item) => {
        return (
          <div className="operation">
              <p onClick={() => this.clickFun(item, '2')}>编辑</p>
              <p className="red" onClick={() => this.clickDetail(item)}>删除</p>
          </div>
        )
      }
    },
  ]


  unitTypeStatus(type) {
    let unitTypeStatus = [
        {
            id: 12,
            name: '施工单位'
        }, {
            id: 2,
            name: '勘察测量'
        }, {
            id: 3,
            name: '管理单位'
        }, {
            id: 4,
            name: '设计单位'
        }, {
            id: 5,
            name: '项目管理'
        }, {
            id: 6,
            name: '造价咨询'
        }, {
            id: 7,
            name: '建设单位'
        }, {
            id: 8,
            name: '监理单位'
        }, {
            id: 9,
            name: '劳务公司'
        }, {
            id: 10,
            name: '政府机构'
        }, {
            id: 11,
            name: '其他'
        }
    ]
    if(type) {
        return unitTypeStatus.filter(item => item.id === type)[0].name
    } else {
        return unitTypeStatus
    }
  }


  handleTableChange(pagination) {

    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize

    this.setState({
      params: this.state.params,
      pagination: pagination
    })


  
  }

  // 点击删除
  clickDetail(item) {
    item.del = 2

    let params = {
      coronPushUser: 3,
      corpId: "",
      del: 2,
      id: 112,
      legalRepresentative: "",
      name: "2",
      organizationCode: "",
      permanentCode: "",
      phone: "1",
      projectId: 1,
      registeredAddress: "",
      registeredCapital: "",
      teamId: null,
      test: 0,
      unitType: 12,
      weigh: 0,
    }

    for(let i in params) {
      params[i] = item[i]
    }

    this.saveList(params, '删除成功')
  }


  clickFun(item) {
    this.setState({
        isModalVisible: true
    })


  
    for(let i in this.state.requestParmas) {
      this.state.requestParmas[i] = item[i]
    }

    // this.state.requestParmas.time = moment(item.time * 1000);

    this.state.requestParmas.id = item.id

    this.setState({
      requestParmas: this.state.requestParmas
    })


    this.clierFormSbmitRef()
  }


  clierFormSbmitRef() {
    if(!this.FormSbmitRef) {
        setTimeout(() => {
          
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
  }



  // getList() {
  //   this.setState({
  //       loading: true
  //   })
  //   React.$axios.post('enterpriseList', this.state.params).then(res => {

  //       this.state.pagination.total = res.data.countList
        
  //       // this.state.pagination.total = res.data.countList
  //       this.setState({
  //           tableData: res.data.list,
  //           loading: false,
  //           pagination: this.state.pagination
  //       })

  //   })
  // }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  handleOk() {
    // console.log(this.state.requestParmas)  
    
    this.FormSbmitRef.submit()
  }

  // 点击新增
  add() {
      for(let i in this.state.requestParmas) {
        this.state.requestParmas[i] = null
      }
      this.state.requestParmas.weigh = 0
      delete this.state.requestParmas.id

      this.setState({
        isModalVisible: true,
        requestParmas: this.state.requestParmas
      })

      this.clierFormSbmitRef()

  }

  setInput(name, { target: { value } }) {

    this.state.requestParmas[name] = value
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  // 选择下拉数据
  handleChange(value, item) {
    this.state.requestParmas[value] = item
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }
  

  handleFinger(item) {
    this.state.requestParmas.img = [...this.state.requestParmas.img, ...item.data.key]
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  // 
  onFinish(value) {
  
    for(let i in value) {
      this.state.requestParmas[i] = value[i]
    }


    this.saveList(this.state.requestParmas)    
  }

  // 保存数据
  saveList(params, type) {
    let message2 = '保存成功'
    if(type) {
      message2 = type
    }
    React.$axios.post('editSaveEnterprise', params).then(res => {
      if(res.code == 20000) {
        message.success(message2)

        this.setState({
          isModalVisible: false  
        })

        // 返回状态
        this.props.saveStype()
      }

    })
  }
 



  onChangeSelect() {
    
  }



  render() {
    return (
      <div>
          {/* <div style={{display: this.state.status==0? 'block': 'none'}}>
            <div className="warp1">
                <div className="head">
                    <button onClick={this.add.bind(this)}>新增</button>
                </div>


                <div className="table">
                <Table  align="center"
                 expandable={{
                  onExpand: (status, item) => {
                    console.log(status, item)
                  }, 
                  expandedRowRender: record => {
                    return (
                      <TeamList id={record.teamId} CurrentTitleName={record.name}></TeamList>
                    )
                  },
                }}
                
                bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id}  onChange={this.handleTableChange.bind(this)} columns={this.columns} dataSource={this.state.tableData}  />

                </div>
        
            </div>
          </div> */}


          <Modal title={this.state.requestParmas.id?'编辑':'新增'} width="900px" className="ProgressNodeModal" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                        <Form
                            layout="vertical"
                            onFinish={this.onFinish.bind(this)}
                            ref={(el) => this.FormSbmitRef = el}
                            {...layout}
                            initialValues = {this.state.requestParmas}
                        >   
                            <div className="flex setFlex">
                                <div>
                                    <Form.Item label="名称" name="name"   rules={[{ required: true }]}>
                                    <Input placeholder="请输入名称" />
                                    </Form.Item>
                                    <Form.Item label="注册地址"  name="registeredAddress" >
                                    <Input placeholder="请输入注册地址" />
                                    </Form.Item>
                                    <Form.Item label="法人代表" name="legalRepresentative" >
                                    <Input placeholder="请输入法人代表" />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item label="电话" name="phone"  >
                                    <Input placeholder="请输入电话号码" />
                                    </Form.Item>
                                    <Form.Item label="注册机构代码"  name="organizationCode"  >
                                    <Input placeholder="请输入注册机构代码" />
                                    </Form.Item>
                                    <Form.Item label="注册资金" name="registeredCapital"  >
                                    <Input placeholder="请输入注册资金" />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item label="单位类型" name="unitType"   rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            style={{ width:210,  }}
                                            placeholder="请选择进场状态"
                                            optionFilterProp="children"
                                            >
                                              {
                                                  this.unitTypeStatus().map(item => {
                                                    return (
                                                        <Option value={item.id}>{item.name}</Option>
                                                    )
                                                  })
                                              }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="权重"  name="weigh" rules={[{ required: true }]} >
                                    <Input  placeholder="请输入权重" />
                                    </Form.Item>
                                    {/* <Form.Item label="班组" name="teamId"  rules={[{ required: true }]}>
                                     <Select
                                            showSearch
                                            style={{ width:210,  }}
                                            placeholder="请选择进场状态"
                                            mode="multiple"
                                            optionFilterProp="children"
                                            >
                                                {
                                                    this.state.teamAllList.map(item => {
                                                        return (
                                                            <Option value={item.id.toString()}>{item.name}</Option>
                                                        )
                                                    })
                                                }
                                        </Select>

                                    </Form.Item> */}
                                </div>
                            </div>
                        </Form>

        </Modal> 

      </div>
    );
  }
}
