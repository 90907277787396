import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import './videoDetailSpecial.less';

import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';

function VideoDetailSpecial(props) {
  const videoDetail = JSON.parse(decodeURIComponent(props.location.search.split('detailData=')[1]))
  let player = null
  
  useEffect(() => {
    
    PlayVideo()
    
    return () => {

      if (player) {
          player.dispose();
      }
    }
  }, []);


  function PlayVideo() {
    let params = {
      autoplay: true,
      controls: true,
      sources: [{
          src: videoDetail.urlHttp,
          type: 'application/x-mpegURL'
      }]
    }

    player = videojs('video', params, function onPlayerReady() {      
      console.log('onPlayerReady');
    });

    player.play();
  }



  return (
    // <Player className="videoDetailSpecial">
    //   <source src={videoDetail.urlHttp} />
      
    // </Player>
    <div className="videoDetailSpecial">
    <div data-vjs-player>
        <video id="video" className="video-js vjs-default-skin video" autoplay></video>
    </div>
    </div>

  )
}
export default withRouter(VideoDetailSpecial);