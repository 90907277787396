


import * as echarts from 'echarts';

export function main3(data) {

    // console.log(data);
    let name = []
    let arr1 = []
    let arr2 = []
    for(let i in data) {
        name.push(i);
        arr1.push(data[i].normal);
        arr2.push(data[i].abnormal);
    }


    var myChart = echarts.init(document.getElementById('main3'));

    let option = {
            color: ['#004EA2'],
            legend: {
                data: ['正常', '异常'],
                right: '20',
                top: '0%'
            },
            grid: {
                left: '1%',
                right: '2%',
                bottom: '-7%',
                top: '20%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                data: name,
                axisLine: {
                    lineStyle: {
                      type: 'dashed',
                       color: '#7F7F7F',
                      width: '1'
                    },
                   
                  },
                axisLabel: {  
                    interval:0,  
                    rotate:-20
                }, 
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle:{
                        type:'dashed'
                    }
                },
            },
            series: [{
                name: '正常',
                data: arr1,
                type: 'bar',
                color: ['#0DC076'],
                barWidth: 20,
                barMaxWidth:40,
                label: {
                    formatter: "{c}",
                    show: true,
                    position: 'top',
                    color: '#0DC076'
                }
            },
            {
                name: '异常',
                data: arr2,
                type: 'bar',
                color: ['#C5153A'],
                barWidth: 20,
                barMaxWidth:40,
                label: {
                    formatter: "{c}",
                    show: true,
                    position: 'top',
                    color: '#C5153A'
              }
            }    
        ]
        };
    
        myChart.setOption(option);

  }



  export function main4(data) {

    // console.log(data);
    let name = []
    let arr1 = []
    data.map(item => {
        name.push(item.nameIdCard)
        arr1.push(item.count)
    })


    var myChart = echarts.init(document.getElementById('main4'));

    let option = {
            color: ['#004EA2'],
            grid: {
                left: '1%',
                right: '2%',
                bottom: '15%',
                top: '15%',
                containLabel: true
              },
              xAxis: {
                type: 'category',
                data: name,
                axisLine: {
                    lineStyle: {
                      type: 'dashed',
                       color: '#7F7F7F',
                      width: '1'
                    },
                   
                  },
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle:{
                        type:'dashed'
                    }
                },
            },
            series: [
            {
                name: '名称',
                data: arr1,
                type: 'bar',
                color: ['#004EA1'],
                barWidth: 20,
                barMaxWidth:40,
                label: {
                    formatter: "{c}",
                    show: true,
                    position: 'top',
                    color: '#004EA1'
              }
            }    
        ]
        };
    
        myChart.setOption(option);

  }




 
