import React from "react";


import { Table,Button, Modal, Select,  Input, Form, message, Spin} from 'antd';


import {MyTable} from './component/table';


import { Uploadhh } from "@/components/upload/upload";

import './DrawingManage.less';


const { Option } = Select;


export default class DrawingManage extends React.Component {
    FormSbmitRef = null
  
  state = {
    herfEarth: '',
    isModalVisible: false,
    isModalVisible2: false, // 目录穿件编辑

    titleName: '图纸上传',
    titleNameItem: '新建目录',

    listDrawingList: [], // 目录列表

    params: {
        projectId: null,
        logic: 3,
        pid: 0,
    },

    addItem: { // 目录
        logic: 2,
        name: null,
        remark: null
    },

    addFileParams: {
        type: null,
        pid: null,
        child: [],
        logic: 3,
    },
    
    loading: false
  }

  componentDidMount() {
    let projectId = React.$getStorage('projectInfo').id
    this.state.params.projectId  = projectId
    this.setState({
        projectId: this.state.params
    })

    this.getList()
  }

  

  getList() {
    let params = this.state.params

    this.getAxios(params).then(res => {
        res.data.list.map((item,index) => {
            item.key = index + 1;
            item.description =  <MyTable updataList={this.getList.bind(this)} lenth={item.quantity}  id={item.id} index ={item.key}  />
        })

        this.setState({
            listDrawingList: res.data.list
        })
    })
  }

  getAxios(params) {
    return new Promise(resolove => {
        React.$axios.post('listDrawing', params).then(res => {
            resolove(res)
        })
    })
  }
  


  edit(item, e) { // 编辑目录名称
    this.state.addItem.name = item.name
    this.state.addItem.remark = item.remark
    this.state.addItem.id = item.id

    this.setState({
        addItem: this.state.addItem,
        isModalVisible2: true,
        titleNameItem: '编辑目录'
    })

    this.clierFormSbmitRef()
    e.stopPropagation()
  }

  uploadFile(item, e) { // 上传文件

    console.log(item)

    this.state.addFileParams.pid = item.id

    this.setState({
        isModalVisible: true,
        addFileParams:  this.state.addFileParams,
    })

    e.stopPropagation()
  }



  onExpandedRowsChange(expandedRows) {
    console.log(expandedRows)
    console.log(1111)
  }



  handleOk() {

    console.log(this.state.addFileParams)

    if(this.state.addFileParams.type == null) {
        message.error('请选择图纸分类')
        return
    }


    React.$axios.post('saveDrawing', this.state.addFileParams).then(res => {
        console.log(res)
        if(res.code == 20000) {
            message.success('保存成功')
            this.handleCancel()
            this.getList()
        }
    })
  }

  handleOkItem() { // 创建目录
    this.FormSbmitRef.submit()
  }

  onFinishItem(val) {
    // this.addItem
    for(let i in val) {
        this.state.addItem[i] = val[i];
    }


    React.$axios.post('saveDrawing', this.state.addItem).then(res => {
        if(res.code == 20000) {
            message.success('保存成功')
            this.handleCancel()
            this.getList()
        }
    })
  }



  // 清除默认数据
  clierFormSbmitRef() {
    if(!this.FormSbmitRef) {
        setTimeout(() => {
            
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
}


  handleCancel() {
    this.setState({
        isModalVisible: false,
        isModalVisible2: false
    })
  }




  onChange(name,value) {
    this.state.addFileParams[name] = value;

    this.setState({
        addFileParams: this.state.addFileParams
    })
  }


  // 上传前
  beforeFun(file, type) {
    let isLt50M = file.size / 1024 / 1024 < 50;
    


   

    if(isLt50M) {
        if(type) {
            this.setState({
                loading: false
            })
        } else {
            this.setState({
                loading: true
            })
        }


        return true
    } else {
        message.error('上传图纸不能超过50M')
        return false
    }

    
  }
  


  handleFront(e, file) {
    //console.log(e, file)
    let fileName = file.name
    let name = fileName.split('.')[0]

    console.log(e, file)

   
    this.state.addFileParams.child.push({
      name: name,
      fileName: file.name,
      url:  e.data.key,
      numbering: null  
    })


    this.setState({
        addFileParams: this.state.addFileParams,
        loading: false
    })

  }

  inputChange(name, index, val) { 

    this.state.addFileParams.child[index][name] = val.target.value
    this.setState({
        addFileParams: this.state.addFileParams
    })

  }


  addItem() { // 添加目录
        delete this.state.addItem.id   
      this.setState({
          isModalVisible2: true,
          titleNameItem: '新建目录',
      })
  }

  // 删除图纸列表
  removeList(item, e) {

    let params = {
        id: item.id,
        del: 2,
        logic: 2
    }

    React.$axios.post('saveDrawing', params).then(res => {
        if(res.code == 20000) {
            message.success('删除成功')
            this.getList()
        }
    })

    e.stopPropagation()
  }


  removeImg(index) {
    this.state.addFileParams.child.splice(index, 1);

    this.setState({
        addFileParams: this.state.addFileParams
    })
  }


  
  


  render() {
    const columns = [
        { title: '序号', dataIndex: 'key', align: 'center', key:'key', width: 150, render: (_) => {
              return (
                  <p className="setXUhao"><span>{_}</span></p>
              )  
        }},
        { title: '目录名称', dataIndex: 'name', key:'key', width:"25%" },
        { title: '图纸数量', dataIndex: 'quantity', key:'key',  width:"10%", align: 'center' },
        { title: '目录说明', dataIndex: 'remark', key:'key', align: 'center'},
        { title: '操作', key:'key', width: 300, render: (_, item)=> {
            return (
                <div className='tableCa'>

                    <p  onClick={this.edit.bind(this, item)}  style={{cursor:'pointer', color: '#63ABFF'}} >编辑</p> <span>|</span> 
                    <p onClick={this.removeList.bind(this, item)} style={{cursor:'pointer', color: '#FF0000'}} >删除</p> <span>|</span>
                    <p onClick={this.uploadFile.bind(this, item)} style={{cursor:'pointer', color: '#00B050'}} >上传</p>
                    
                </div>

                )
                
                
        }},
      ];


    return (
      <div className="DrawingManage">
        <p className="posAbs">
            <Button type="primary" onClick={this.addItem.bind(this)}>新建目录</Button> 
        </p> 

        <Table
            columns={columns}
            
            expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
            rowExpandable: record => record.name !== 'Not Expandable',
            expandRowByClick: true,
            expandIconColumnIndex: 4,

              expandIcon: ({ onExpand, record }) => {
                return <p></p>
              },
              onExpandedRowsChange: this.onExpandedRowsChange.bind(this)
            }}
            dataSource={this.state.listDrawingList}
        />

{/* onCancel={this.handleCancel2.bind(this)} */}
{/* onOk={this.handleOkItem.bind(this)} */}
{/* // wrapperCol={{ span: 16 }} */}
      <Modal title={this.state.titleNameItem} width="40%"  visible={this.state.isModalVisible2}  onOk={this.handleOkItem.bind(this)} onCancel={this.handleCancel.bind(this)}>
        <Form
            name="basic"
            labelCol={{ span: 3 }}
            ref={(el) => this.FormSbmitRef = el}
            initialValues={this.state.addItem}
            onFinish={this.onFinishItem.bind(this)}
            >
            <Form.Item
                label="图纸名称"
                name="name"
                rules={[{ required: true, message: '' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="目录说明"
                name="remark"
            >
                <Input />
            </Form.Item>

            </Form>

      </Modal>      
            





      <Modal title={this.state.titleName} width="60%" className='DrawingManageModal' visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
            <div className="setTitle">
                <h1>图纸文件：<span>（仅支持PDF格式图纸文件,文件大小50M以内）</span></h1>
                <Button type="primary">
                   <div className="setUPloda"> <Uploadhh accept = '.pdf' pdfStatus={true}  name="file" data={{type: 68, logic: 3}}  width={85} height={30} beforeFun={this.beforeFun.bind(this)}    handleClick={this.handleFront.bind(this)} />
                   </div>
                     选择文件
                     </Button>
                
            </div>

            <div className="upDataList">
               <Spin spinning={this.state.loading} >

                <ul>
                    <li>文件</li>
                    <li>图纸名称</li>
                    <li>图纸编号</li>
                    <li>操作</li>
                </ul>

                {
                    this.state.addFileParams.child.map((item, index) => {
                        return (
                            <ul>
                                <li>{item.fileName}</li>
                                <li><Input value={item.name} onChange={this.inputChange.bind(this,'name',index)}  />  </li>
                                <li><Input value={item.numbering} onChange={this.inputChange.bind(this,'numbering',index)} /> </li>
                                <li className="delete" style={{cursor:'pointer'}} onClick={this.removeImg.bind(this, index)}>删除</li>
                            </ul>
                        )
                    })
                }

                </Spin> 
                
            </div>


            <div className="from">
                <div>
                <span>目录名称：</span>
                <Select
                    style={{ width: '70%' }}
                    placeholder="请选择"
                    optionFilterProp="children"
                    value = {this.state.addFileParams.pid}
                    onChange={this.onChange.bind(this, 'pid')}
                >
                    {
                        this.state.listDrawingList.map(item => {
                            return (
                                <Option value={item.id}>{item.name}</Option>
                            )
                        })
                    }
                </Select>
                </div>
            
                <div className="towDiv">

                    <span>图纸分类：</span>

                    <Select
                        style={{ width: '70%' }}
                        placeholder="请选择"
                        optionFilterProp="children"
                        value = {this.state.addFileParams.type}
                        onChange={this.onChange.bind(this, 'type')}
                    >
                        <Option value={2}>正式</Option>
                        <Option value={3}>变更</Option>
                        <Option value={4}>送审</Option>
                    </Select>
                </div>
  
            </div>    
      </Modal>     

            

      </div>
    );
  }
}
