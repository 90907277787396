import React from 'react'

import ReactEcharts from 'echarts-for-react';

import { Progress, Slider, Switch} from 'antd';


import './ProjectOverview.less'

// import {VideoDetail} from '../videoDetail/videoDetail'



export const ProjectOverview =   (
    class ProjectOverview extends React.Component {
        state = {
            visible: true,
            imageUrl: '',
            loading: false,
            disabled: false,
        }


        componentDidMount() {

            
        }


        getOtion(){
            const option = {
                grid: [{
                    left: '2%',
                    bottom: '0%',
                    top: '10%',
                    right: '10%'
                }],
              
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                        splitLine:{
                    　　　　show:false
                    　　}
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine:{
                    　　　　show:false
                    　　}
                    },
                    
                ],
                series: [
                    
                    {
                        name: '搜索引擎',
                        type: 'line',
                        stack: '总量',
                        symbol: "none",
                        smooth: true,
                        itemStyle: {
                            normal: {
                                
                                lineStyle: {
                                    color: '#784C5F'
                                }
                            }
                        },

                        areaStyle: {
                            color: '#0D0D0D'
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [10, 20, 30, 15, 10, 7, 9]
                    }
                ]
            };
                return option;
        }
       

        render() {
            return <div className="setWidth ProjectOverview earth">
                     <div className="viewHead">
                        <h1>滇中引水石鼓水源工程</h1>
                     
                        <button>子管理后台</button>
                     </div>

                     <div className="English">
                        Project Overview
                     </div>


                     <div className="p">
                         <p>滇中引水工程是国务院确定的172项节水供水重大水利工程中的标志性工程，也是中国西南地区规模最大、投资最多的水资源配置工程，是我国在建最大的引水工程。</p>    
                        <p>工程由水源工程和输水工程两部分组成，水源工程位于玉龙县石古镇，从石鼓镇上游约1.5公里的金沙江取水，由磊站提水至总渠。输水工程自丽江石古镇望城坡开始，途径丽江市、大理州、楚雄州、昆明市、玉溪市，终点为红河州新坡背。</p>
                        <p>全线各类建筑物总长755.44公里，其中输水总干渠总长664.24公里，公有58座主隧道洞长611.99公里; 有施工值洞120条长91.2公里</p>
                        <p>计划动态投资780.48亿元，计划施工总工期96个月</p>
                    </div>


                    <ul className="ul">
                        <li>
                            <h2>劳务安全教育</h2>
                            <p className="p1">78.9%</p>
                        </li>

                        <li>
                            <h2>今日到岗率</h2>
                            <p className="p2">78.9%</p>
                        </li>
                        <li>
                            <h2>实时劳动力曲线</h2>

                            <ReactEcharts
                                option={this.getOtion()}
                                style={{height: '40px', width: '250px', zIndex: 100}}
                                className='react_for_echarts' />
                        </li>

                        <li className="lastLi">
                            {/* <h2 style={{opacity: 0}}>111</h2> */}

                            <div>管理人员 <div className="setColor">20</div> 人</div>
                            <div>劳务人员 <div className="setColor">1000</div> 人</div>
                        </li>
                    </ul>



                    <div className="jinzhan">

                        <div className = "title">工程进展 <span>（天）</span></div>

                        <div className="rouad">

                        <Progress type="circle" trailColor="#3BEC9F" strokeColor="#20201F" percent={25} />

                        <div className ="propes">
                            <p className="number"><i>250</i>/1200</p>

                            <div className="year"> <p>2021年4月6日</p>  <p>2033年5月6日</p></div>

                            <Slider defaultValue={30} disabled={this.disabled} />
                            
                        </div>


                        </div>

                    </div>


                    <div>
                        {/* <VideoDetail/> */}
                    </div>
                    


                    

            </div>
        }
    }
);