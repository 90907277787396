import React from 'react'

import {Button, Table, message, Image, Select, Modal, Form, Input, DatePicker} from "antd";
import moment from 'moment';

const {Option} =  Select;

export const ManualFace = (
    class ManualFace extends React.Component {
        imageUrl = process.env.REACT_APP_IMG_URL
        state = {
            Component: null,
            isModalVisible: false,
            requestParmas: {},
            userMsg: {},
            deviceAllListAll2: [],
            deviceAllList: [],
            userList: [],
            userValue: null,
            doormanAllList: [],
            newDataHH: {},
            params: {},
        }

        componentDidMount() {
           
            this.getDoormanAll();
            this.getDeviceAll('')
        }


        edit(item) {

            item.showTime = moment(new Date())
        
            this.setState({
                requestParmas: item,
                isModalVisible: true,
                userMsg: item
            })
            this.getDeviceAll(item.logoDoorman, true)
            this.onSearch(item.id).then(res => {
                this.serchPeople(res[0].id, {item: res[0]})
        
            })
        
            // this.clierFormSbmitRef()
           
        }

        // clierFormSbmitRef() {
        //     // if(!this.FormSbmitRef) {
        //     //     setTimeout(() => {
                  
        //     //         this.FormSbmitRef.resetFields()
        //     //     }, 500)
        //     // } else {
        //     //     this.FormSbmitRef.resetFields()
        //     // }
        //   }




        onChangeDoor(status,value) {
    
            if(status) { // 详情
                this.state.requestParmas.logoDoorman = value
                this.state.requestParmas.enterDeviceKey = null
        
                this.setState({
                    requestParmas: this.state.requestParmas
                })
        
            } else { // 搜索条件
                this.state.params.logoDoorman = value
                this.state.params.deviceKey = null
                this.setState({
                    params: this.state.params
                })
            }
        
            
        
            this.getDeviceAll(value, status)
          }

          onChangeT(name, value) {
            if(name) {
               this.state.params[name] = value
               this.setState({
                   params: this.state.params
               })
            } else {
                this.state.requestParmas.enterDeviceKey = value
                this.setState({requestParmas:this.state.requestParmas})
            }
           
       
       }

        // 获取门卫
        getDoormanAll() {
            React.$axios.post('doormanAll').then(res => {
            this.setState({
                doormanAllList: res.data.doormanAll
            })
            })
        }

          // 改变时间
        onChangeTime(value) {
            this.state.newDataHH = value
            
            // this.state.params.startingEditTime = parseInt((+ value._d) / 1000) 
            this.state.requestParmas.showTime = value

            this.setState({
                newDataHH: this.state.newDataHH,
                requestParmas:this.state.requestParmas
            })    
        }

        getPersonnelCategory(type) {
            switch(type) {
            case type == 2:
                return '一般作业人员'
            case type == 3:
                return '特种作业人员'
            case type == 4:
                return '项目管理人员'
            case type == 5:
                return '其它人员'
            default:
                return '其他'
            }
        }


          // 查找人员信息
        serchPeople(val, item) {

            this.setState({
                userValue : val,
                userMsg: item.item
            })
        }


         // 查询
        onSearch(val) {
            return new Promise(resolve => {
                React.$axios.post('userList', {export: 3 ,userId: val}).then(res => {
                    this.setState({
                        userList: res.data.list
                    })
                    resolve(res.data.list)
                })
            })
            
        }

         // 获取设备
        getDeviceAll(value, type) {
            React.$axios.post('deviceAll', {logoDoorman: value}).then(res => {
                if(type) {
                    res.data.deviceAll.map(item => {
                        let direction = item.direction == 1? '出场': '入场'
                        item.name = item.name +  `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0` +  direction
                    })

                    this.setState({
                        deviceAllListAll2: res.data.deviceAll
                    })
                } else {
                    this.setState({
                        deviceAllList: res.data.deviceAll
                    })
                }
            })
        }



        handleOk() {
            let params = {
                deviceKey: this.state.requestParmas.enterDeviceKey,
                idNum: this.state.userMsg.uicNumber,
                imageUrl: null,
                showTime:  parseInt((+this.state.requestParmas.showTime._d) / 1000),
                userId:  this.state.userMsg.id,
            }
            
             React.$axios.post('recognitionManual', params).then(res => {
              if(res.code == 20000) {
                message.success('保存成功')
                this.setState({
                  isModalVisible: false
                })
                // this.getList()
              }
             })
        
            // this.FormSbmitRef.submit()
        }

        handleCancel() {
            this.setState({
                isModalVisible:false
            })
        }

        render() {
            return (
                <div>
                <Modal title="手动刷脸" width="1400px" className="distinguishListModal" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>

                          <div className="modalShow">
    
                            <div className="changeMsg">
                                <h3>选择信息</h3>
                                <ul>
                                    <li>
                                        <p>门卫:</p>
                                        <Select
                                            showSearch
                                            style={{ width: 300, height:30, marginRight:20,  }}
                                            placeholder="请选择"
                                            optionFilterProp="children"
                                            value = {this.state.requestParmas.logoDoorman}
                                            onChange={this.onChangeDoor.bind(this, true)}
                                        >
                                            <Option value="">全部</Option>
                                            {
                                                this.state.doormanAllList.map(item => {
                                                    return (
                                                        <Option value={item.logo}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </li>
                                    <li>
                                        <p>设备:</p>
                                        <Select
                                            showSearch
                                            style={{ width: 300, height:30, marginRight:20,  }}
                                            placeholder="请选择"
                                            optionFilterProp="children"
                                            value = {this.state.requestParmas.enterDeviceKey}
                                            onChange={this.onChangeT.bind(this, false)}
                                        >
                                            {
                                                this.state.deviceAllListAll2.map(item => {
                                                    return (
                                                        <Option value={item.deviceKey}>{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </li>
                                    <li>
                                        <p>识别时间:</p>
                                            <DatePicker showTime value={this.state.requestParmas.showTime} style={{ width: 300, height:30, marginRight:20,  }} onChange={this.onChangeTime.bind(this)} />
                                    </li>
                                </ul>
                            </div>


                            <div className="personMsg">
                                <h3>人员信息</h3>
                                <ul>
                                    <li>
                                        <div className="flex">
                                            <p>人员:</p>

                                            <Select
                                                showSearch
                                                value={this.state.userValue}
                                                style={{ width: 300, height:30, marginRight:20,  }}
                                                placeholder="请选择"
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={false}
                                                onSearch={this.onSearch.bind(this)}
                                                onChange={this.serchPeople.bind(this)}
                                                notFoundContent={null}
                                            >
                                                 {
                                                    this.state.userList.map((item, index) => {
                                                        return (
                                                            <Option value={item.id} item={item} key={index}>{item.uicName}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </div>

                                        <div className="flex">
                                            <p>图片:</p>
                                           <p>
                                                                <Image
                                                                        width={100}
                                                                        height={100}
                                                                        src={this.imageUrl + this.state.userMsg.uiAvatarKey}
                                                                    /></p> 
                                        </div>

                                        <div className="flex">
                                            <p>电话:</p>
                                            <p>{this.state.userMsg.phone}</p>
                                        </div>
                                    </li>
                                    <li>
                                       <div className="flex">
                                            <p>公司:</p>
                                            <p>{this.state.userMsg.eName}</p>
                                       </div>

                                       <div className="flex">
                                            <p>部门:</p>
                                            <p>{this.state.userMsg.teName}</p>
                                       </div>

                                       <div className="flex">
                                            <p>岗位:</p>
                                            <p>{this.state.userMsg.nameWork}</p>
                                       </div>

                                    </li>
                                    <li>
                                        <div className="flex">
                                            <p>身份证号:</p>
                                            <p>{this.state.userMsg.uicNumber}</p>
                                       </div>

                                       <div className="flex">
                                            <p>人员类别:</p>
                                            <p>{this.getPersonnelCategory(this.state.userMsg.uiPersonnelCategory)}</p>
                                       </div>

                                       <div className="flex">
                                            <p>角色:</p>
                                            <p>{this.state.userMsg.uiRole == 3? '管理人员': '劳务人员'}</p>
                                       </div>
                                    </li>
                                </ul>


                            </div>
                          
                          </div>

            </Modal> 
            </div>

            )
        }

    }
);