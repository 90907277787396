import * as imageConversion from "image-conversion";

// 格式化时间
export function formatTime(date, fmt) {
	var o = {
		"M+": date.getMonth() + 1, //月份   
		"d+": date.getDate(), //日   
		"h+": date.getHours(), //小时   
		"m+": date.getMinutes(), //分   
		"s+": date.getSeconds(), //秒   
		"q+": Math.floor((date.getMonth() + 3) / 3), //季度   
		"S": date.getMilliseconds() //毫秒   
	};
	if (/(y+)/.test(fmt))
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt))
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
	return fmt;
}


 // 获取每月多少天
function getMonthDay(year, month) {
    let days = new Date(year, month, 0).getDate()
    return days
}


// 日期处理
export function getCurrentDayLastDay(moment) {
	const dateFormat = 'YYYY/MM/DD';
	let date = new Date();
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let currentday = getMonthDay(year, month)

	return [moment(`${year}/${month}/01`, dateFormat), moment(`${year}/${month}/${currentday}`, dateFormat)];

}


/**
 * ifc 转码	
 * @param {*} str 
 */
export function ifcUtf_8(str) {
	if(!str) {
		return '';
	}

	str =  str.replace(/\\X2\\\w+\\X0\\/g, function(e){
		let twoStr = e.split('\\');
		twoStr = twoStr[2];

		let currentstr = '';
		let currentUtf = null
		for(let i = 0; i < twoStr.length; i+=4) {
			let index1 =  twoStr[i + 0];
			let index2 =  twoStr[i + 1];
			let index3 =  twoStr[i + 2];
			let index4 =  twoStr[i + 3];

			currentUtf = `\\u` + `${index1}${index2}${index3}${index4}`

			currentstr += eval(`'${currentUtf}'`)
		}
		return currentstr;
	});

	return str;
}





/**
 * 
 * @param {string} variable 地址栏参数 
 * @returns 
 */
export function getQueryVariable(variable)
{
	let query =  window.location.hash.split('?')[1]
	
	if(!query) {
		return false
	} 
	

	let vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
			var pair = vars[i].split("=");
			if(pair[0] == variable){return pair[1];}
	}
	return(false);
}



/**
 * 获取秒
 * @param {*} date  
 * @param {*} fmt '23:59:59'
 * @returns 
 */

export function changeTime(date, fmt) {
	let year = date.getFullYear();
    let Month = date.getMonth() + 1;
    let cuurenDate = date.getDate();
    
    let ne = year + '/' + Month + '/' + cuurenDate + ' ' + fmt
    return parseInt(new Date(ne) / 1000)
}




// 上传富文本图片

export function BraftEditorIMGUpload(params, FormDataList = {}, fileName = 'image[]', path = 'fileUploadOss') {
	return new Promise(resolve => {
		const xhr = new XMLHttpRequest
		const fd = new FormData()
		const serverURL = process.env.REACT_APP_BASE_URL  +  path;

		const accessLogin = JSON.parse(localStorage.getItem('accessLogin'))
		
		const successFn = (response) => {
			let data =  JSON.parse(response.target.response) 

			resolve(data)
		}

		const progressFn = (event) => {
			// 上传进度发生变化时调用param.progress
			params.progress(event.loaded / event.total * 100)
		}

		const errorFn = (response) => {
			// 上传发生错误时调用param.error
			params.error({
			msg: 'unable to upload.'
			})
		}

		xhr.upload.addEventListener("progress", progressFn, false)
		xhr.addEventListener("load", successFn, false)
		xhr.addEventListener("error", errorFn, false)
		xhr.addEventListener("abort", errorFn, false)

		let isLt2M = params.file.size / 1024 / 1024 < 2;
		if (isLt2M) {
			toSend(xhr, fd, serverURL, accessLogin, FormDataList, fileName, params.file)
			return
		}

		let size = 200
		if(params.file.size >= 4512834) {
			size = 600
		}

		imageConversion.compressAccurately(params.file, {
		size: size,
		scale: 0.5,
		}).then(res => { 
			toSend(xhr, fd, serverURL, accessLogin, FormDataList, fileName, res)
		}).catch((e) => {
		console.log(e);
		})
		
	})
}

function toSend(xhr, fd, serverURL, accessLogin, FormDataList, fileName, file) {
	fd.append(fileName, file)
	for(let i in FormDataList) {
		fd.append(i, FormDataList[i])
	}

	xhr.open('POST', serverURL, true)

	xhr.setRequestHeader("accesstoken","$2y$10$z.0Fz2z0r9XKJ5M3.TowhOEzBGjQndjo2hijvAZQmdLjztiJkbUZu");
	xhr.setRequestHeader("accesslogin", accessLogin)
	xhr.send(fd)
}



// 防抖
export function debounce(fn, wait) {
    let timeout = null;
    return function() {
        let context = this;
        let args = arguments;
        if (timeout) clearTimeout(timeout);
        // let callNow = !timeout;
        timeout = setTimeout(() => {
            fn.apply(context, args);
            timeout = null;
        }, wait);
        // if (callNow) ;
    };
}

// 节流
export function throttle(fn, delay) {
	let timer = null;
	return function() {
	  if (!timer) {
		timer = setTimeout(() => {
		  fn.apply(this, arguments);
		  timer = null;
		}, delay);
	  }
	}
}






