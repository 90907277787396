import React from "react";

import './attendance.less';

import { Input,Select,Button, Table, DatePicker, Modal, message, Image} from "antd";
import { Company } from "@/components/company/company";
import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

import {formatTime} from '@/utils/util'

import { CheckOutlined} from '@ant-design/icons';

import moment from 'moment';

import img1 from '@/assets/icons/1/垂直对齐_align-vertically.svg';

const {Option} =  Select;



export default class attendance extends React.Component {

      titleData = [
        {
            thStyle: 'style=height:60px; font-size:20px;',
            html: `<div style="font-size:22px;">珠江三角洲水资源配置工程土建施工C1标智慧工程考勤表<br/><span style="font-size:16px; font-weight: normal;">日期：2020-11-01 至 2020-11-30</span></div>`
        }
     ]


     footerData = [
        [{
            thStyle: `style=height:20px;`,
            html: `<div style=' text-align: left; display:black; height:200px;' >申明：此表登记劳务作业人员为我单位本月在该工程全部出勤人数，出勤情况属实；我单位已将此表向全体劳务作业人员公示，均无异议。</div>`,
            colspan: 36
        },
        {
            thStyle: `style=height:20px;`,
            html: `<div style=' text-align: left; display:black; height:200px;' >总计</div>`,
            colspan: 2
        },
        {
            thStyle: `style=height:20px;`,
            html: `<div style=' text-align: left; display:black; height:200px;' >629</div>`,
            colspan: 1
        }],

         [{
            thStyle: `style=height:80px;`,
            html: `<div style=' text-align: left; display:black; height:200px; vertical-align:top;'>班组长签名: </div>`,
            colspan: 3
        },
        {
            thStyle: `style=height:80px;`,
            html: `<div style=' text-align: left; display:black; height:200px; vertical-align:top;' >用工企业劳动<br/>力管理员签字：</div>`,
            colspan: 3
        },
        {
            thStyle: `style=height:80px;`,
            html: `<div style=' text-align: left; display:black; height:200px; vertical-align:top;' >用工企业项目负责人<br/>（授权队长）签字：</div>`,
            colspan: 5
        },
        {
            thStyle: `style=height:80px;`,
            html: `<div style=' text-align: left; display:black; height:200px; vertical-align:top;' >填表时间：</div>`,
            colspan: 14
        },
        {
            thStyle: `style=height:80px; width="20px;"`,
            html: `<div style=' text-align: left; display:black; height:200px; vertical-align:top;'>用工企业盖章：</div>`,
            colspan: 14
        }],
        
    ]

    columns = [
        { title: '姓名',dataIndex: 'uicName',align: 'center', width: '90px' },{title: '手机号码',dataIndex: 'uPhone',align: 'center',},
        // {title: '公司',dataIndex: 'eName',align: 'center',},{title: '部门',dataIndex: 'teName',align: 'center',},
        // {title: '岗位', dataIndex: 'nameWork',align: 'center',},
         {title: '1',dataIndex: 'd1',align: 'center', render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{ title: '2',  dataIndex: 'd2',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},
        {title: '3', dataIndex: 'd3',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '4', dataIndex: 'd4', align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '5', dataIndex: 'd5',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},
        {title: '6', dataIndex: 'd6',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '7', dataIndex: 'd7', align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '8', dataIndex: 'd8',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},
        {title: '9', dataIndex: 'd9',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '10', dataIndex: 'd10', align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '11', dataIndex: 'd11',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},
        {title: '12', dataIndex: 'd12',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '13', dataIndex: 'd13', align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '14', dataIndex: 'd14',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},
        {title: '15', dataIndex: 'd15',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '16', dataIndex: 'd16', align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '17', dataIndex: 'd17',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},
        {title: '18',dataIndex: 'd18',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '19', dataIndex: 'd19',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '20',dataIndex: 'd20', align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},
        {title: '21', dataIndex: 'd21',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '22', dataIndex: 'd22', align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '23', dataIndex: 'd23',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},
        {title: '24', dataIndex: 'd24',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '25', dataIndex: 'd25', align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '26', dataIndex: 'd26',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},
        {title: '27', dataIndex: 'd27',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '28', dataIndex: 'd28', align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '29', dataIndex: 'd29',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},
        {title: '30', dataIndex: 'd30',align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},{title: '31', dataIndex: 'd31', align: 'center',render:(_) => {return(<div>{_==1?<CheckOutlined/>:''}</div>)}},  {title: '合计',dataIndex: 'total',align: 'center', },
        {title: '操作',dataIndex: 'd30', align: 'center', render:(_, itme) => {
            return (
                <p  style={{cursor: 'pointer', color:"#6A9BF4"}} onClick={() => {this.clickDeatil(itme)} }>详情</p>
            )
        }}
      ]


    imageUrl= process.env.REACT_APP_IMG_URL
    loadExcelRef = null   
    btnRef = '' 
    state = {
    // 表格分页属性
    pagination: {
        current: 1,
        pageSize: 10,
        total: 200,
        onShowSizeChange: function(current, pageSize) {
            this.state.pagination.pageSize = pageSize;
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        onChange: function(current) {
            this.state.pagination.current = current
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        showTotal: function () {  //设置显示一共几条数据
            return '共 ' + this.state.pagination.total + ' 条'; 
        }.bind(this),
        showQuickJumper: true,
        },

    loading: false,

    jsonFieldsList: {},

    tableData: [
        
    ],
    dayArray: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
    params: {
        lengthLimit: 10,
        offsetLimit: 0,
        uiEnterpriseId: "",
        uiTeamId: "",
        uiWorkId: "",
        time: '',
        todayTime: '',
        dl: {
            d: [],
            l: '',
            c: '2'
        },
    
    },

    defineTime: '',
    ExcelName: '考勤导出.xls',
    projectName: '',
    manageAttendance: 0,
    laborerAttendance: 0,
    everdayTime: 0,
    changeUpload: [
        {label: '序号', prop: 'aa', status: true},
        {label: '姓名', prop: 'uicName', status:true},
        { label: "公民身份证号码",prop: 'idCard', status:true, callback: function(value) {
            return  value
        }},
        {label: '电话', prop: 'uPhone', status:true},
        {label: '公司', prop: 'eName', status:true},
        {label: '部门', prop: 'teName', status:true},
        {label: '岗位', prop: 'nameWork', status:true},
    ],
    visible: false,
    timeRange: {
        starTime: null,
        endTime: null
    },
    allIndex: 0,  // 导出 excel 的全部数量
    visibleDeatil: false,
    detailList: {
        info: {},
        list: [],
        face: {}
    } // 详情列表
  }


    
    
    
    componentDidMount() {

        let date =  this.getTimeFirstDay()

        this.state.params.time =  parseInt(+date) / 1000 
        
        let name = React.$getStorage('projectInfo').name + '劳务工人考勤表'


        this.state.params.todayTime  =  parseInt(+new Date() / 1000 )
        
        let month = new Date().getMonth()

        this.state.timeRange.starTime = moment(this.getTimeFirstDay(month))  
        this.state.timeRange.endTime = moment(date)


        this.setState({
            defineTime: moment(date),
            params: this.state.params,
            everdayTime:  moment(new Date()),
            projectName: name,
            ExcelName:  formatTime(new Date(), '考勤导出yyyyMMdd') + '.xls',
            timeRange: this.state.timeRange
        })
        
        
        this.getList()
    }

    // 点击详情
    clickDeatil(e) {
        let params = JSON.stringify(this.state.params)
        params = JSON.parse(params) 
        params.export = 3
        params.id = e.id

        React.$axios.post('userAttendanceList', params).then(res => {
           
            let arr = []
            for(let i in res.data.info) {
                let reg = /^d+/g
                if(reg.test(i)) {
                    if(res.data.info[i] == 1) {
                        let regNumber = /d/gi
                        let txt = i.replace(regNumber, '')
                        arr.push(txt)
                    }
                }
            }
    
            res.data.list = arr
            console.log(res.data)

            this.setState({
                detailList: res.data,
                visibleDeatil: true
            })

        })

    }


    handleOk() { // 导出方法
        let time1 =  this.state.timeRange.starTime._d
        let time2 = this.state.timeRange.endTime._d

        let month1 = time1.getMonth()+1
        let month2 = time2.getMonth()+1
        let day1 = time1.getDate()
        let day2 = time2.getDate()   
        let month3 = time1.getMonth();
        let month4 =  time2.getMonth();  
        
        let year1 = time1.getFullYear();
        let year2 = time2.getFullYear();
        if(year1 != year2) {
            month4 = month4 == 0? 12: 0;
        }



        if(parseInt((month4 - month3)) > 1) {
            message.error('月数间隔不能超过两个月')
            return
        }

        let arr = {}
        let currentIndex = 0;


        this.state.changeUpload.map((item,index) => {
        if(item.status) {
            
            if(item.callback) {
            arr[index + '_' + item.label] = {
                field: item.prop,
                callback: item.callback
            }
            } else {
            arr[index + '_' + item.label] = item.prop
            }
            
        }

        currentIndex = index
        })




        if(month1 != month2) { // 如果月份不同
            currentIndex++;
            for(let i = day1; i < 32; i++) {
                
                arr[currentIndex + '_' + month1 + '月' + i] = 'start' + '' + i 
            }

            currentIndex++;

            for(let i = 1; i < day2 + 1; i++) {
                arr[currentIndex + '_' + month2 + '月' + i] = 'end' + '' + i
            }
        } else {
            currentIndex++;
            // let num = day2 - day1
    
            for(let i = day1; i < day2 + 1; i++) {
                arr[currentIndex + '_' + month1 + '月' + i] = 'start' + '' + i 
            }
        }

    
        currentIndex++;
        arr[currentIndex + '_合计'] = 'total'

        let count = Object.keys(arr).length;

        let except = parseInt(count / 5) 
        let mo = count % 5


        this.footerData[0][0].colspan = count - 3

        this.footerData[1][0].colspan = except
        this.footerData[1][1].colspan = except
        this.footerData[1][2].colspan = except
        this.footerData[1][3].colspan = except
        this.footerData[1][4].colspan = except + mo
        


        this.setState({
        visible: false,
        jsonFieldsList: arr,
        allIndex: count
        })

        // 点击触发导出
        this.loadExcelRef.setDownLoadData()
    
          
        // this.handleCancel()
    }
    
    handleCancel() { // 点击取消
        this.setState({
            visible: false,
            visibleDeatil: false
        })
    }
    
   // 设置选中导出按钮
   setSelectStatus(item, index) {
    item.status = !item.status

    this.setState({
      changeUpload: this.state.changeUpload
    })
   }

   getTimeModal(time, val) { // 获取时间
    this.state.timeRange[time] = val

    
        
    this.setState({
        timeRange: this.state.timeRange
    })
   }

   




    exportExcel() {
        this.setState({
         visible: true
        })
    }
    

    getTimeFirstDay(Month) {
        let date = new Date()
        let year = date.getFullYear()
        let month = date.getMonth()+1

        if(Month) {
            month = Month
        }


        let c = year+'/'+month + '/' + '1' + ' ' + '00:00:00'
        
        let current =  new Date(c)
        
        return current
    }


    getList(type = false) {

        if(type) {
            this.state.params.offsetLimit = 0
            this.state.pagination.current = 1
            this.setState({
                params: this.state.params
            })
        }

        this.setState({ loading: true });
        React.$axios.post('userAttendanceList', this.state.params).then(({ data }) => {
            data.list.map((item,index) => {
                item.key = index + 1

            }) 

            this.state.pagination.total =  data.countList   
            
            this.setState({
                loading: false,
                tableData: data.list,
                pagination: this.state.pagination,
                manageAttendance: data.manageAttendance,
                laborerAttendance: data.laborerAttendance
            })

        })
        }
    
    // 搜索
    serchData() {
        this.state.params.uiEnterpriseId =this.btnRef.state.uiEnterpriseId
        this.state.params.uiTeamId =  this.btnRef.state.uiTeamId
        this.state.params.uiWorkId = this.btnRef.state.uiWorkId
        
        
        console.log(this.state.params.dl.l)
        if(this.state.params.dl.d.length != 0 || this.state.params.dl.l != "") {
            if(this.state.params.dl.d.length == 0) {
                message.error('号不能为空')
                return
            } else if(this.state.params.dl.l == "") {
                message.error('出勤不能为空')
                return
            }


        }


        this.setState({
            params: this.state.params
        })
        
        this.getList(true)

    }
    
    // 点击重置
    removeParams() {
        
        for(let i in this.state.params) {
            this.state.params[i] = ''
        }
        this.state.params.lengthLimit =  10
        this.state.params.offsetLimit = 0

        let date =  this.getTimeFirstDay()

        this.state.params.time =  parseInt(+date) / 1000  
        
        this.btnRef.defaultData()    

        this.setState({
            defineTime: moment(date),
            params: this.state.params
        })

        this.getList(true)
    }

    onChange(value) {
        this.state.params.todayTime = parseInt(+value._d / 1000)
        this.setState({
            everdayTime: value,
            params: this.state.params
        })

        this.getList()

    }

    // 搜索选择类型
    onChangeT(name, value) {
        this.state.params[name] = value
        this.setState({
            params: this.state.params
        })
    }

    onChangeTNew(name, parameter, value) {
        this.state.params[name][parameter] = value

        if(parameter == 'l') {
            if(value == '') {
                this.state.params.dl.d = []
                
            }
        }

        this.setState({
            params: this.state.params
        })
    }


    getTime(value) {
        
        this.state.params.time = parseInt(+value._d / 1000)
        this.setState({
            params: this.state.params,
            defineTime: moment(value._d),
        })
    }


    // 设置input 值的变化
    setInput(name, {target:{value}}) {
        this.state.params[name] = value
        this.setState({
          params: this.state.params
        })
      }



    handleTableChange(pagination) {
        this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
        this.state.params.lengthLimit = pagination.pageSize
        this.setState({
          params: this.state.params,
          pagination: pagination
        })
        
        // 执行更新列表
        this.getList()
      }
    
    // 获取 1号  
    getFistDay(date) {
        let year = date.getFullYear()
        let month = date.getMonth()+1
        let c = year+'/'+month + '/' + '1' + ' ' + '00:00:00'
        let current =  new Date(c)
        return current
    }  

      // 方法导出
   fetchFun() {
        return new Promise(resolve => {
            
        let time1 =  this.state.timeRange.starTime._d
        let time2 = this.state.timeRange.endTime._d
      
        let month1 = time1.getMonth()+1
        let month2 = time2.getMonth()+1
        
        let day1 = time1.getDate()
        let day2 = time2.getDate()       

        let time =  formatTime(time1, 'yyyy-MM-dd')
        let strDate = formatTime(time2, 'yyyy-MM-dd')
        this.titleData[0].html = `<div style="font-size:22px;">${this.state.projectName}<br/><span style="font-size:16px; font-weight: normal;">日期：${time} 至 ${strDate}</span></div>`    
        
        let params = {}
        for(let i in this.state.params) {
            params[i] = this.state.params[i]
        }
        params.export = 2
        let getFistTime = this.getFistDay(time1)
        params.time =   parseInt(+getFistTime / 1000) 
        
        let params2 = JSON.parse(JSON.stringify(params))

        let getFistTime2 = this.getFistDay(time2)
        params2.time =   parseInt(+getFistTime2 / 1000)


        let number = /d+/g
        let allList = []
        let sum = 0


        
        React.$axios.post('userAttendanceList', params).then(res => {
            
            res.data.list.map((item,index) => {
                let total = 0
                item.aa = index+1
                for(let i in item) {
                    let a = /^d\d+/g.test(i)
                    if(a) {
                        let ac = i.replace(number,'')
                        item['end' + ac] = null
                        if(item[i] == 1) {
                            item[i] ='√'         
                            item['start' + ac] = '√'
                            
                            if(ac >= day1) {
                                if(month1 == month2) {
                                    if(day2 >= ac) {
                                        total++;
                                    }

                                } else {
                                    total++;
                                }

                                
                            }


                        } else {
                            item[i] = ' '
                            item['start' + ac] = ' '
                        }
                    }
                    item.total = total
                }
                 sum += item.total
            })

            allList = res.data.list

            


            if(month1 == month2) {
                this.footerData[0][2].html =  `<div style=' text-align: left; display:black; height:200px;' >${sum}</div>`;
                resolve(allList)
                
                return
            }


            React.$axios.post('userAttendanceList', params2).then(res2 => {
                let allNum = 0
                res2.data.list.map(item5 => {
                    let status = false
                    allList.map(item3 => {
                        if(item5.idCard == item3.idCard) {
                            status = true
                        } else {
                            
                        }
                    })

                    if(!status) {
                        item5.total = 0
                        allList.push(item5)
                    }
                })


                res2.data.list.map((item, index) => {

                    let list = {d1: " ",d2: " ",d3: " ",d4: " ",d5: " ",d6: " ",d7: " ",d8: " ", d9: " ",d10: " ", d11: " ",d12: " ",d13: " ", d14: " ", d15: " ", d16: " ",d17: " ",d18: " ",d19: " ",d20: " ",d21: " ",d22: " ",d23: " ", d24: " ", d25: " ",d26: " ",d27: " ",d28: " ",d29: " ",d30: " ",d31: " ",
                    end1: " ",end2: " ",end3: " ",end4: " ",end5: " ", end6: " ",end7: " ",end8: " ",end9: " ",end10: " ",end11: " ",end12: " ", end13: " ",end14: " ",end15: " ",end16: " ", end17: " ", end18: " ",end19: " ",end20: " ",end21: " ",end22: " ",end23: " ",end24: " ",end25: " ",end26: " ",end27: " ",end29: " ",end30: " ",end31: " ",
                    total: 0,
                    start1: " ",start2: " ",start3: " ",start4: " ",start5: " ",start6: " ",start7: " ",start8: " ",start9: " ",start10: " ",start11: " ",start12: " ",start13: " ",start14: " ",start15: " ",start16: " ",start17: " ",start18: " ",start19: " ",start20: " ",start21: " ",start22: " ",start23: " ",start24: " ",start25: " ",start26: " ",start27: " ",start28: " ",start29: " ",start30: " ",start31: " ",
                }

                
                    allList.map(item3 => {
                        if(item3.idCard == item.idCard) {
                            list = item3
                        }
                    })

                        

                    for(let i in item) {
                        let a = /^d\d+/g.test(i)
                        if(a) {
                            let ac = i.replace(number,'')
                            
       
                            if(item[i] == 1) {
                                list['end' + ac] = '√'
                                
                                if(ac <= day2) {
                                    list.total++;
                        
                                }
                            } else {

                                try {
                                    list['end' + ac] = ' '

                                   

                                } catch(e) {
                                    list['end' + ac] = ' '
                                }
                                
                            }

                          
                        }
                    }

                    try {
                        allNum += list.total
                    } catch(e) {
                        console.error(e)
                    }
                    
                })

                this.footerData[0][2].html =  `<div style=' text-align: left; display:black; height:200px;' >${allNum}</div>`;
                resolve(allList)
            })


        })

        })    
    }
    
    render() {
    return (
      <div className="attendance">
        <div className="attendanceHead">
            <h1>
                <p><img src={img1} alt=""/>每日在岗</p>
                <DatePicker placeholder="请选择时间" value={this.state.everdayTime}  onChange={this.onChange.bind(this)} />
            </h1>

            <div className="border"></div>


            <div className="projectRate">
                <div>
                    <p>项目管理人员在岗率</p>
                    <p className="rate">{this.state.manageAttendance}%</p>
                </div>
                <div>
                    <p>协作队伍人员在岗率</p>
                    <p className="rate">{this.state.laborerAttendance}%</p>   
                </div>

            </div>

        </div>


        <div className="serchInput">
            <Company Status={true} ref={(el) => this.btnRef = el} />  
            <Select
                showSearch
                style={{ width: 200, height:30, marginRight:20,  }}
                placeholder="请选择进场状态"
                optionFilterProp="children"
                onChange={this.onChangeT.bind(this, 'uiEnterStatus')}
                >
                <Option value=" ">全部</Option>
                <Option value="2">进场</Option>
                <Option value="3">退场</Option>
            </Select>

                


             <DatePicker style={{ width: 200, height:30, marginRight:20,  }} picker="month"  value={this.state.defineTime} defaultValue={this.state.defineTime}  placeholder="请选择时间"   onChange={this.getTime.bind(this)} />


             <Select
                showSearch
                style={{ width: 200, height:30, marginRight:20,  }}
                placeholder="请选择出勤状态"
                optionFilterProp="children"
                onChange={this.onChangeTNew.bind(this, 'dl', 'l')}
                >
                <Option value="">全部</Option>
                <Option value="0">未出勤</Option>
                <Option value="1">出勤</Option>
            </Select>

            <Select
                showSearch
                style={{ width: 100, height:30, marginRight:20,  }}
                placeholder="请选择出勤状态"
                optionFilterProp="children"
                value = {this.state.params.dl.c}
                onChange={this.onChangeTNew.bind(this, 'dl', 'c')}
                >
                <Option value="2">每一天</Option>
                <Option value="3">某一天</Option>
            </Select>


            <span className="setoverflow">
                <Select
                    style={{ width: 80, marginRight:20,  }}
                    placeholder="请选择号"
                    mode="multiple"
                    value = {this.state.params.dl.d}
                    optionFilterProp="children"
                    onChange={this.onChangeTNew.bind(this, 'dl', 'd')}
                    >
                    {
                        this.state.dayArray.map(item => {
                            return (
                                <Option value={'at.d' + item} key={item}>{item}号</Option>
                            )
                        })
                    }

                </Select>
            </span>
            




                <Input  style={{ width: 200, height:30, marginRight:10,  }} onChange={this.setInput.bind(this, 'keyword')}  placeholder="姓名/身份证号/籍贯/ 手机号" />

                
                <Button type="primary" style={{marginRight:10,}} onClick={this.serchData.bind(this)}>查找</Button>
                {/* <Button style={{marginRight:10,}} onClick={this.removeParams.bind(this)}>重置</Button> */}
                <Button style={{marginRight:10,}} type="primary" onClick={this.exportExcel.bind(this)} >考勤导出</Button>


             <p style={{display: 'none'}}>           
            <DownloadExcel ref={(el) => this.loadExcelRef = el}  name={this.state.ExcelName} title={this.titleData} fields={this.state.jsonFieldsList} footer={this.footerData}  fetch={this.fetchFun.bind(this)} btnName="确定" />
             
            {/* <DownloadExcel name={this.state.ExcelName} fields={this.jsonFieldsList} title={this.titleData} footer={this.footerData}  fetch={this.fetchFun.bind(this)} btnName="考勤导出" /> */}
             </p>

             {/* 导入弹框            */}
            <Modal
              visible={this.state.visible}
              title="需要导出数据"
              width="800px"
              onOk={this.handleOk.bind(this)}
              onCancel={this.handleCancel.bind(this)}
              footer={[
                <Button key="back" onClick={this.handleCancel.bind(this)}>
                  取消
                </Button>,
                
                 
                <Button key="submit" type="primary"  onClick={this.handleOk.bind(this)}>
                  确定
                </Button>
                ,
              ]}
            >

          <div className="needWarp">
           <h1><p><span></span>需要导出</p>  <p className="p2"><span></span></p>不需要导出</h1>
              <ul>
                  {
                    this.state.changeUpload.map((item,index) => {
                      return (
                        <li onClick={this.setSelectStatus.bind(this, item, index)}>
                            <Button type={item.status?'primary': ''}>
                              {item.label}
                            </Button>
                        </li>
                      )
                    })
                  }
              </ul>

              <h3>导出日期 <span>(只能在两个月区间)</span></h3>

              <div className="DatePickerHH">
                <DatePicker style={{ width: 200, height:30, marginRight:20,  }}  value={this.state.timeRange.starTime}  placeholder="请选择时间"   onChange={this.getTimeModal.bind(this, 'starTime')} />
                <DatePicker style={{ width: 200, height:30, marginRight:20,  }}  value={this.state.timeRange.endTime}   placeholder="请选择时间"   onChange={this.getTimeModal.bind(this, 'endTime')} />   
              </div>    
   
        </div>
            
        </Modal> 
   
        </div>







        <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }} columns={this.columns} dataSource={this.state.tableData}  />

      
      
      
      
        <Modal
              visible={this.state.visibleDeatil}
              title="打卡记录"
              width="80%"
              
              
              onOk={this.handleCancel.bind(this)}
              onCancel={this.handleCancel.bind(this)}
            >
              <div className="attendanceDetail">

                  

                <div className="head">
                  
                  <Image
                        width={80}
                        src={this.imageUrl + this.state.detailList.info.uiAvatarKey}
                    />

 
                   
                   <div className="flex">
                        <h3 className="setTime">
                            考勤日期: {formatTime(new Date(this.state.detailList.info.time * 1000), 'yyyy-MM')}
                        </h3>

                        <div className="msg">
                            <p>姓名： {this.state.detailList.info.uicName}</p> 
                            
                            <p>身份证： {this.state.detailList.info.idCard}</p> 
                                <p>手机号： {this.state.detailList.info.uPhone}</p>
                        </div>

                        <div className="msg">
                            <p>公司： {this.state.detailList.info.eName}</p>
                            <p>部门： {this.state.detailList.info.teName}</p>
                            <p>岗位： {this.state.detailList.info.nameWork}</p>
                        </div>
                   </div>
                   
                   

                </div>




                <ul>
          
                  {
                      this.state.detailList.list.map(item => {
                          return (
                              <li key={item}>
                                  
                                  <h1>{item}号</h1>
                                  
                                   {
                                       this.state.detailList.face['d' + item].map(item2 => {
                                          let reg = /^https/
                                          let img = null
                                          if(reg.test(item2.image)) {
                                            img = item2.image
                                          } else {
                                            img = this.imageUrl +  item2.image
                                          }

                                        return (
                                            <p>
                                                 <Image
                                                        width={80}
                                                        src={img}
                                                    />
                                                <div className="text">
                                                    {item2.nameDevice}
                                                    <br/>
                                                    { formatTime(new Date(item2.showTime * 1000), 'MM-dd hh:mm:ss') }
                                                    <br/>
                                                </div> 
                                            </p>
                                        )
                                       })
                                   }


                              </li>
                          )
                      })
                  }
                </ul>  



              </div>

              



          
            
        </Modal>              
      
      
      
      
      
      
      
      
      
      </div>

    );
  }
}
