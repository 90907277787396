import React from "react";


import { DatePicker,Input,Select,Button, Table,  Image, message, Modal} from "antd";


import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import './partyActive.less';

import {formatTime} from '@/utils/util'

const {Option} =  Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


let imgUrl = process.env.REACT_APP_IMG_URL


export default class  PartyActive extends React.Component {
    columns = [
      {
        title: "序号",
        dataIndex: 'nameIdCardAdmin',
        align: 'center',
        render: (_, item, index) => {
          return (
            <p>{index + 1}</p>
          )
        }
      },
        {
          title: '姓名',
          dataIndex: 'nameIdCardAdmin',
          align: 'center'
        },
        {
            title: '电话',
            dataIndex: 'phoneUserAdmin',
            align: 'center'
         },
        {
          title: '活动名称',
          dataIndex: 'title',
          align: 'center'
        },
        {
          title: '活动积分',
          dataIndex: 'integral',
          align: 'center'  
        },
        {
          title: '活动地址',
          dataIndex: 'address',
          align: 'center'  
        },
        {
          title: '活动范围',
          dataIndex: 'range',
          align: 'center',
          render: (_) => {
            return (
              <p>{_ == 2?'党员':_==3?'预备党员':_==4?'群众':'全部'}</p>
            )
          }
        },
        {
          title: '活动开始时间',
          dataIndex: 'timeStart',
          align: 'center',
          render: (_) => {
              return (
                  <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}</p>
              )
          }
        },
        {
          title: '活动结束时间',
          dataIndex: 'timeStart',
          align: 'center',
          render: (_) => {
              return (
                  <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}</p>
              )
            }
          },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          render: (_) => {
            return (
              <p>{_==2? '报名未开始': _==3? '报名中':_==4? '签到未开始':_==5?'签到中':_==6?'活动已结束': '报名和签到同时进行'}</p>
            )
          }
        },
        {
            title: '操作',
            dataIndex: 'remark',
            align: 'center',
            render:(_,item) => {
                return (
                    <p onClick={this.showModule.bind(this, item.id)} style={{color: '#6A9BF4', cursor:'pointer'}}>详情</p>
                )
            }
        },
      ]


  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    TableParams: {
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 2,
        base: null,
        range: null,
        status: null
    },

    infoMsg: {
      signIn: [],
      singUp: []
    }, // 详情信息
    isModalVisible: false,

  }




  componentDidMount() {
    this.getTableData()
  }

  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

      React.$axios.post('listBuildingMicroActivity', this.state.TableParams).then(res => {
        this.state.pagination.total = res.data.countList

        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    console.log(name)
    this.state.TableParams.base = value

    console.log(this.state.TableParams)
  }

  toSerch2() {
    this.getTableData(true)
  }

  // 获取钢筋列表详情
  getListReBarInfo(id) {

    React.$axios.post('listBuildingMicroActivity', {id: id, logic: 4, signIn: 2,
      singInMe: 2,
      singUp: 2,
      singUpMe: 2}).then(res => {
        if(typeof(res.data.info.singUp) == "string") {
            res.data.info.singUp = JSON.parse(res.data.info.singUp)
            res.data.info.singUpMe = JSON.parse(res.data.info.singUpMe)
            res.data.info.signIn = JSON.parse(res.data.info.signIn)
        }
        this.setState({
          infoMsg: res.data.info
        })
    })
  }

  showModule(item) {
    this.getListReBarInfo(item)

      this.setState({
        isModalVisible: true,
      })
  }

  handleOk() {
    this.setState({
        isModalVisible: false
    })
  }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

 
  handleTableChange(value) {
    this.state.pagination = value
    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }


  onChangeT(name, value) {
    this.state.TableParams[name] = value;
    this.setState({
      TableParams: this.state.TableParams
    })
  }

  render() {
    return (
      <div className="PartyActive">
          <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>活动名称:</span> 
                        <Input onChange={this.serchCar.bind(this, 'base')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                        
                        </li>
                        <li><span>活动范围:</span>  
                          <Select
                              showSearch
                              style={{ width: 200, height:30, marginRight:20,  }}
                              placeholder="请选择"
                              optionFilterProp="children"
                              onChange={this.onChangeT.bind(this, 'range')}
                          >
                          <Option value=" ">全部</Option>
                          <Option value="2">党员</Option>
                          <Option value="3">预备党员</Option>
                          <Option value="4">群众</Option>
                          </Select>
                        </li>

                        <li><span>状态:</span>  
                          <Select
                              showSearch
                              style={{ width: 200, height:30, marginRight:20,  }}
                              placeholder="请选择"
                              optionFilterProp="children"
                              onChange={this.onChangeT.bind(this, 'status')}
                          >
                            <Option value=" " key="1">全部</Option>
                            <Option value="2" key="2">报名未开始</Option>
                            <Option value="3" key="3">报名中</Option>
                            <Option value="4" key="4">签到未开始</Option>
                            <Option value="5" key="5">签到中</Option>
                            <Option value="6" key="6">活动已结束</Option>
                            <Option value="4" key="7">报名和签到同时进行</Option>
                          </Select>
                        </li>



                            <Button  className="btn" onClick={this.toSerch2.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>
                    </ul>
                </div>



                <div className="table2">
                {/* <div class="allBtn">
                   
                    <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                        导入
                    </Button>
                    <Button  className="bt" type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button>
                    <Button  className="bt" type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button>
                    </div> */}


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                   
                    loading={this.state.loading} rowKey={record => record.id} columns={this.columns} dataSource={this.state.tableData}  />
                </div>


                                                                                               
                <Modal title="活动详情" width="40%" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} pagination={this.state.paginationRebar}  onCancel={this.handleCancel.bind(this)}>
                    <div className="partyActiveModal">
                     <Image
                      width={'100%'}
                      height= {350}
                      src={imgUrl + this.state.infoMsg.img}
                      /> 
                      <h1>{this.state.infoMsg.title}</h1>

                      <p className="reamark">
                        {this.state.infoMsg.introduction}
                      </p>


                      <div className='status'>
                      
                        <span className="span1">{this.state.infoMsg.status==2? '报名未开始': this.state.infoMsg.status==3? '报名中':this.state.infoMsg.status==4? '签到未开始':this.state.infoMsg.status==5?'签到中':this.state.infoMsg.status==6?'活动已结束': '报名和签到同时进行'}</span>
                        <span>{this.state.infoMsg.integral}积分</span>
                      </div>

                      <h2>
                        活动地址
                      </h2>
                      <p>{this.state.infoMsg.address}</p>  

                      <h2>活动时间</h2>
                      <p>{formatTime(new Date(this.state.infoMsg.timeStart * 1000), 'yyyy.MM.dd')} - {formatTime(new Date(this.state.infoMsg.timeEnd * 1000), 'yyyy.MM.dd')}</p>


                      <h2>报名人员</h2>
                      <div className="setList">
                      
                        {
                          this.state.infoMsg.singUp.map(item => {
                            return (
                              <p>{item.nameIdCard}</p>
                            )
                          })
                        }



                      </div>

                      <h2>签到人员</h2>
                      <div className="setList">
                        
                        {
                          this.state.infoMsg.signIn.map(item => {
                            return (
                              <p>{item.nameIdCard}</p>
                            )
                          })
                        }

                      </div>

                    </div>
                </Modal>


      </div>
    );
  }
}
