import React, { useState, useEffect } from "react";
import { Layout, Carousel, Modal } from 'antd';
import { Link, withRouter } from "react-router-dom";


import './HomeRouter.less';

import { formatTime } from '@/utils/util.js';
import oa from '@/assets/img/newReviseImg/imgAndIcon/oa.png';



import {getNewestUserAttendanceCurve, getInspectionStatistics} from './HomeRouter2';

import { CheckSquareFilled } from '@ant-design/icons';


import Header2 from "@/components/Header/Header";


import Qjjk from '@/assets/img/newReviseImg/imageIndex/path1.svg'; // 监控
import Stjg from '@/assets/img/newReviseImg/imageIndex/path3.svg'; // 环境
import Zhdj from '@/assets/img/newReviseImg/imageIndex/path4.svg'; // 党建
import Aqjg from '@/assets/img/newReviseImg/imageIndex/anquan.svg'; // 安全
import Tzjg from '@/assets/img/newReviseImg/imageIndex/path7.svg'; // 图纸
import Xtbg from '@/assets/img/newReviseImg/imageIndex/path9.svg'; // 协同办公


const { Content } = Layout;


function HomeRouter() {


  const [enterSum, setEnterSum] =  useState(0);

  const projectInfo =  React.$getStorage('projectInfo');
  const [projectName, setProjectName] = useState('');

 
  const [allProjectList, setAllProjectList] = useState([]);
  const [coverImg, setCoverImg] = useState([]);
  const [progressList, setProgressList] =  useState([]);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [showDatail, setShowDatail] = useState({});
  const [newsListEPA, setNewsListEPA] = useState([]);
  const [newType, setNewType] = useState(true); // 新闻类型
  const [monitoringList, setMonitoringList] = useState([]);
 

  const imageUrl = process.env.REACT_APP_IMG_URL;
  const currentTime = new Date().getTime();
  

  // 获取新闻列表
  function getNewsList2() {
    let params = {
        lengthLimit: 14,
        offsetLimit: 0,
        projectId: projectInfo.id
    }

    React.$axios.post('newsList2', params).then(res => {
        setCoverImg(res.data.list);
    })
  }

  // 获取企业新闻
  const getNewsListEPA = () => {
    let params = {
        projectId: projectInfo.id,
        offset: 0,
        count: 14,
        logic: 1
    }  
    React.$axios.post('newsListEPA', params).then(res => {

        setNewsListEPA(res.data.permission);
    })
  }



  useEffect(() => {
    getProjectWhereUser()
    getNewsList2()
    setProjectName(projectInfo.name)
    getNewestUserAttendanceCurve(React, projectInfo).then(res => {
       
        setEnterSum(res.laborer + res.manager)
    })
    getInspectionStatistics(React, projectInfo);
    getProgressAll();
    getNewsListEPA();

    getMonitoringList();

  }, []);

  // 首月
 const getFirstDay = () => { //当前月第一天
    var y = new Date().getFullYear(); //获取年份
    var m = new Date().getMonth() + 1; //获取月份
    
    let timer = y + '/' + m + '/' + '01';

    return parseInt(new Date(timer).getTime() / 1000);
}

const getLastDay = () => { //当前月最后一天
    var y = new Date().getFullYear(); //获取年份
    var m = new Date().getMonth() + 1; //获取月份
    var d = new Date(y, m, 0).getDate(); //获取当月最后一日
    
    let timer = y + '/' + m + '/' + d;

    return parseInt(new Date(timer).getTime() / 1000);
}





//  获取环保监测
  const getMonitoringList = () => {

    const startingTime = getFirstDay();
    const endTime = getLastDay();



    let params = {
        lengthLimit: 10,
        monitorTimeStart: startingTime,
        monitorTimeStop: endTime,
        offsetLimit: 0,
    }  
    React.$axios.post('monitoringList', params).then(res => {
        setMonitoringList(res.data.list);
    })

  }


  const changeNews = (type) => {
    setNewType(type)
  }

  function getProgressAll() {
    React.$axios.post('progressAll').then(res => {
        let data = res.data.progressAll.reverse();


        setProgressList(data)

        // 跳到指定位置
        setTimeout(() => {
                let allDom = document.querySelectorAll('#noFinish');
                
                allDom = allDom[allDom.length - 1];
                let ul = document.querySelector('.right .bottom ul');

                let ulHeight = ul.clientHeight / 2; 
                let docHeight =   document.body.clientHeight / 2;

                try {
                    ul.scrollTo( 0 , allDom.offsetTop - (docHeight + ulHeight));
                } catch (e) {
                    console.error(e)
                }
                
            
        }, 200)
    })
  }




  function getProjectWhereUser() {
    React.$axios.post('projectWhereUser').then(res => {
        setAllProjectList(res.data.projectWhereUser)
    })
  }

  function handleCancel() {
    setIsModalVisible2(false)
  }

  function clickLi(item, type) {
    if(type == 'url') {
        window.open(item.url);
        return
    }

    setIsModalVisible2(true)
    setShowDatail(item)
  } 


  return (

    <Header2 index={0}>
        <div className="HomeContentBody">

            <div className="HomeBody">
            <div className="left">
                <div className="top">
                    <div className="newsList">
                        <h1 className="hh_tilte">新闻动态</h1>

                            <div className="imgWarp"> 
                        <Carousel autoplay  className="Carousel">
                                {
                                    newType? coverImg.map((item, index) => {
                                        return (
                                            <div className="content"  key={index}>
                                                <img src={imageUrl + item.cover} alt="" />

                                                <div className="descript">
                                                    {item.title}
                                                </div>
                                            </div>
                                        )
                                    }):
                                    newsListEPA.map((item, index) => {
                                        return (
                                            <div className="content"  key={index}>
                                                <img src={item.thumb_url} alt="" />

                                                <div className="descript">
                                                    {item.title}
                                                </div>
                                            </div>
                                        )
                                       
                                    })
                                }
                            </Carousel>
                            </div>  


                        <div>
                        </div>  

                    </div>
                    <div className="projectList">
                            <div className="newsTitle">
                                <h1 onClick={(e) => {changeNews(true)}} className={newType?'active':''}>项目要闻</h1>
                                <h1 onClick={(e) => {changeNews(false)}} className={newType?'':'active'}>集团要闻</h1>
                            </div>   
                            
                            {/* <h1 className="hh_tilte">集团要闻</h1>  */}

                            <div className="news">
                                <ul>
                                    {
                                        newType? coverImg.map((item, index) => {

                                        return (
                                            // onClick={this.clickLi.bind(this, item)}
                                            <li key={index}  onClick={() => {clickLi(item)}}  className="flex">
                                            <p>{item.title}</p>
                                            <div className="newsTime">[{formatTime(new Date(item.addTime * 1000), 'yyyy-MM-dd')}]</div>
                                            </li>
                                        )
                                        }): 
                                        newsListEPA.map((item, index) => {
                                            return (
                                                <li key={index}  onClick={() => {clickLi(item, 'url')}}  className="flex">
                                                <p>{item.title}</p>
                                                {/* <div className="newsTime">[{formatTime(new Date(item.addTime * 1000), 'yyyy-MM-dd')}]</div> */}
                                                </li>
                                            )
                                           
                                        })
                                    }
                                </ul>
                            </div>     
                            
                    </div>      
                </div>
                <div className="bottom">
                        <div className="Attendance">
                        <h1 className="hh_tilte">人员出勤</h1>
                        <div className="today">
                            <h2>今日出勤</h2>
                            <p><span>{enterSum}</span>  人</p>       
                        </div>    
                        <div className="echartWarp echart2">
                            <div id="main" className="setEchart setEchart2"></div>
                        </div>        
                        </div>
                        <div className="hiddenDanger">
                            <h1 className="hh_tilte">隐患排查</h1>      

                            <div className="echartWarp echart2">
                                <div id="main2" className="setEchart setEchart2"></div>
                            </div>            
                        </div>
                        <div className="notice">
                        <h1 className="hh_tilte">环境监测</h1>
                        <div className="news">
                              <div className="title">
                                  <div>PM10</div>
                                  <div>PM2.5</div>
                                  <div>噪声</div>
                                  <div>TSP</div>
                                  <div>气压</div>
                              </div>      


                                <ul>
                                    {
                                        monitoringList.map((item, index) => {
                                            return (
                                                // onClick={this.clickLi.bind(this, item)}
                                                <li key={index}   className="flex">
                                                    <p>{item.pm10}</p>
                                                    <p>{item.pm2_5}</p>
                                                    <p>{item.noiseDb}</p>
                                                    <p>{item.tsp}</p>
                                                    <p>{item.airPressure}</p>
                                                </li>
                                            )
                                        })
                                    }    
                                </ul>
                            </div>             
                                    

                        </div>      
                </div>
            </div>

            <div className="right">
                <div className="top">
                        <h1 className="hh_tilte">系统导航</h1>

                        <ul>
                            <li><a target="_blank" href="/CollaborativeOffice/MeetingSignIn"><span style={{backgroundColor: '#FFC000'}}><img src={Xtbg} alt="" /></span> 协同办公</a></li>
                            <li><a target="_blank" href="/PanoramicMonitoring/videoSurveillance"><span style={{backgroundColor: '#1677FF'}}><img src={Qjjk} alt="" /></span>  全景监控</a></li>
                            <li><a target="_blank" href="/WisdomParty/Home"><span style={{backgroundColor: '#C71D4C'}}><img src={Zhdj} alt="" /></span> 智慧党建</a></li>
                            <li><a target="_blank" href="/EcologicalSupervision/environmentalMonitoring"><span style={{backgroundColor: '#03BFAB'}}><img src={Stjg} alt="" /></span> 生态监管</a></li>
                            <li><a target="_blank" href="/SecurityInvestigation"><span style={{backgroundColor: '#09C160'}}><img src={Aqjg} alt="" /></span> 安全监管</a></li>
                            <li><a target="_blank" href="/Drawing/DrawingList"><span style={{backgroundColor: '#FDA7B7'}}><img src={Tzjg} alt="" /></span> 图纸管理</a></li>      
                        </ul>        

                </div>
                <div className="bottom">
                        <h1 className="hh_tilte">进度提醒</h1>
                        <ul>
                            {
                                progressList.map((item, index) => {
                                    let status = null
                                    if(currentTime > item.time * 1000) {
                                        status = 0
                                    } else {
                                        status = 1
                                    }

                                    return (
                                        <li key={index} id={status==0?null:'noFinish'}>
                                            <div className="title">{formatTime(new Date(item.time * 1000), 'yyyy-MM-dd')} <span style={{backgroundColor: status==0?'#17B676':''}}>{status == 0 ?'已完成':'计划'}</span></div>
                                            <div className="context">{item.content}</div>

                                            <div className="Line"></div>
                                            <div className="cube">{status == 0?<CheckSquareFilled style={{ color: '#17B676'}} />: <div className="round"></div>}  </div>    
                                        </li>      
                                    )
                                })
                            }       
                        </ul>       

                </div>          
            </div>              

            </div>

            
            <Modal  title={showDatail.title} className='newsListModal' width="50%"  visible={isModalVisible2} footer={null} onCancel={handleCancel}>
                <div className="setHeight" dangerouslySetInnerHTML={{__html: showDatail.content}}>
                        
                </div>
            </Modal>



        </div>
    </Header2>

    
  )
}

export default withRouter(HomeRouter);