import React from "react";




import { DatePicker,Input,Select,Button, Table, Modal, Form, message, Popconfirm} from "antd";


import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined} from '@ant-design/icons';

import './carList.less';

const {Option} =  Select;
const { RangePicker } = DatePicker;


let imgUrl = process.env.REACT_APP_IMG_URL

const layout = {
    labelCol: { span: 16},
  };

const carType = {
    2: '办公车',
    3: '泵车',
    4: '渣土车',
    5: '洒水车',
    6: '扫地车',
    7: '私家车',
    8: '油罐车',
    9: '吊车',
    10: '罐车'        
}


export default class CarList extends React.Component {
    
    FormSbmitRef = null
    
    state = {
        // 表格分页属性
        pagination: {
            current: 1,
            pageSize: 10,
            total: 200,
            onShowSizeChange: function(current, pageSize) {
                this.state.pagination.pageSize = pageSize;
                this.handleTableChange(this.state.pagination)
            }.bind(this),
            onChange: function(current) {
                this.state.pagination.current = current
                this.handleTableChange(this.state.pagination)
            }.bind(this),
            showTotal: function () {  //设置显示一共几条数据
                return '共 ' + this.state.pagination.total + ' 条'; 
            }.bind(this),
            showQuickJumper: true,
        },

    loading: false,

    tableData: [
        
    ],
    enterpriseAllList: [],
    vehicleDoormanAllList: [],
    teamAllList: [],
    workAllList: [],

    teamAllListSelect: [],
    workAllListSelect: [],

    isModalVisible: false,

    openStatus: false,
    queryParams: {
        lengthLimit: 10,
        offsetLimit: 0,
        enterpriseId: [],
        teamId: [],
        workId: [],
        keyword:'',
        type: null
    },
    requestParmas: {
        empMoblie: null,
        empName: null,
        enterpriseId:[],
        plateNumber: null,
        teamId: [],
        workId: [],
        type: null
    },

    status: 0
  }


  columns = [
    {
        title: "序号",
        dataIndex: 'title',
        align: 'center',
        render: (_, item, index) => {
          return (
            <p>{index + 1}</p>
          )
        }
      },
      {
        title: '姓名',
        dataIndex: 'empName',
        align: 'center'
      },
      {
        title: '手机号',
        dataIndex: 'empMoblie',
        align: 'center'
      },
      {
        title: '车牌号',
        dataIndex: 'plateNumber',
        align: 'center'
      },
      {
        title: '类型',
        dataIndex: 'type',
        align: 'center',
        render: (_) => {
            return (
                <div>{carType[_]}</div>  
            )
        }
      },
      {
        title: '公司',
        dataIndex: 'nameEnterprise',
        align: 'center'
      },
      {
        title: '所属部门',
        dataIndex: 'nameTeam',
        align: 'center'
      },
      {
          title: '所属职务',
          dataIndex: 'nameWork',
          align: 'center'
      },
      {
        title: '操作',
        dataIndex: 'address',
        align: 'center',
        width: '150px',
        render:(_,item) => {
          return (
            <div className="operation">
                <p onClick={this.edit.bind(this, item)}>编辑</p>

                <Popconfirm
                    title="确定删除?"
                    onConfirm={this.confirm.bind(this, item)}
                    // onCancel={cancel}
                    okText="是"
                    cancelText="否"
                >
                   <p class="del" style={{color: '#ff0000'}}>删除</p>  
                </Popconfirm>

                {/* <p onClick={this.edit.bind(this, item)}>删除</p>   */}
            </div>
          )
        }
      },
    ]

    componentDidMount() {
        this.getEnterpriseAll()


        this.getList()
    }

    confirm(item) {
        let params = {
            id: item.id,
            del: 2
        }
        React.$axios.post('vehicleEditSave', params).then(res => {
            if(res.code == 20000) {
                message.success('删除成功')
                this.getList()
            }
        })

    }

    // 获取列表
    getList(status) {
        this.setState({
            loading: true,
            tableData: [],
          })

        if(status) {
            this.state.queryParams.offsetLimit = 0
            this.state.pagination.current = 1
            this.setState({
                pagination: this.state.pagination
            })
        }

        React.$axios.post('vehicleList', this.state.queryParams).then(res => {

            this.state.pagination.total = res.data.countList
            this.setState({
                tableData: res.data.list,
                pagination: this.state.pagination,
                loading: false
            })

        })
    }


   // 获取公司 
   getEnterpriseAll()  {
    React.$axios.post('enterpriseAll').then(res => {
        this.setState({
            enterpriseAllList: res.data.enterpriseAll
        })
    })
   }

   // type true 单选
   getTeamAll(id, type = false) {
    return new Promise((resolve) => {
        React.$axios.post('teamAll', {id: id}).then(res => {
            if(type) {
                this.setState({
                    teamAllListSelect: res.data.teamAll
                })
            } else {
                this.setState({
                    teamAllList: res.data.teamAll
                })
            }
            resolve()
        })
    })
    
   }

   getWorkAll(id, type = false) {
    React.$axios.post('workAll', {id: id}).then(res => {
        if(type) {
            this.setState({
                workAllListSelect: res.data.workAll
            })
        } else {
            this.setState({
                workAllList: res.data.workAll
            })
        }
        
    })
   }


    
   onChangeType(value) {
       this.state.queryParams.type = value

       this.setState({
            queryParams: this.state.queryParams
        })
   }


      onChangeT(name, value) {
        this.state.queryParams.logoDoorman = value
        this.setState({
            queryParams: this.state.queryParams
        })

      }

      // 选择岗位
      onChangeWork(value) {
        this.state.queryParams.workId = value

        this.setState({
            queryParams: this.state.queryParams
        })
      }


      onChangeEnterprise(value, item) {
          
        // console.log(name, value, item)

        let newId = this.getNewIdData(item)
        this.state.queryParams.enterpriseId = value;
        this.state.queryParams.teamId = []
        this.state.queryParams.workId = []
        this.setState({
            queryParams: this.state.queryParams
        })


        this.getTeamAll(newId)
      }

      // 获取队伍
      onChangeTeam(value, item) {
        let newId = this.getNewIdData(item)
        this.state.queryParams.teamId = value
        this.state.queryParams.workId = []
        this.getWorkAll(newId)
     }


      getNewIdData(item) {
        let id = []
        item.map(ite => {
            if(ite.item) {
                let allId = ite.item.split(',')
                id = [...id, ...allId]
            }
        })

        var newId = [...new Set(id)]

        return newId
      }

      // 点击放大镜查询
      toSerch() {
        this.getList(true)
      }

      handleTableChange(pagination) {
        this.state.queryParams.offsetLimit = (pagination.current -1) * pagination.pageSize
        this.state.queryParams.lengthLimit = pagination.pageSize

        this.setState({
        queryParams: this.state.queryParams,
        pagination: pagination
        })


        // 执行更新列表
        this.getList()

      }

      // 设置车牌
      selectCar() {

      }

      // 查找车牌号
      serchCar(name, {target: {value}}) {
        this.state.queryParams.keyword = value
        this.setState({
            queryParams: this.state.queryParams
        })
      }

      changeStatus(index) {
        this.setState({
            status: index
        })
      }
      

      handleOk() {
        this.FormSbmitRef.submit()
      }

      handleCancel() {
        this.setState({
            isModalVisible: false
        })
      }

      isStar(str) { // 判断是为有星号键

            let index = str.indexOf("****");

            return index == -1 ?str:"";
      }

      onFinish(val) {


        for(let i in val) {
            this.state.requestParmas[i] = val[i]
        }

        this.state.requestParmas.empMoblie = this.isStar(this.state.requestParmas.empMoblie);

        React.$axios.post('vehicleEditSave', this.state.requestParmas).then(res => {
            if(res.code == 20000) {
                message.success('保存成功')
                this.setState({
                    isModalVisible: false
                })

                this.getList()
            }
        })

      }

      toInput() {
        for(let i in this.state.requestParmas) {
            this.state.requestParmas[i] = null
        }
        this.state.requestParmas.enterpriseId = []
        this.state.requestParmas.teamId = []
        this.state.requestParmas.workId = []

        delete this.state.requestParmas.id

        this.setState({
            requestParmas: this.state.requestParmas
        })

        this.setState({
            isModalVisible: true
        })

        this.clierFormSbmitRef();
      }

    // 清除默认数据
    clierFormSbmitRef() {
        if(!this.FormSbmitRef) {
            setTimeout(() => {
                
                this.FormSbmitRef.resetFields()
            }, 500)
        } else {
            this.FormSbmitRef.resetFields()
        }
    }


      // 弹框添加数据
      onChangeEnterprise2(val, data, item) {
          return new Promise(res => {
            
            let id = item.item.teamId.split(',')
            this.getTeamAll(id, val).then(res2 => {
                res(res2)
            })
            this.setState({
                requestParmas: this.state.requestParmas
            })
          })
      }

      // 弹框部门
      onChangeTeam2(val,data, item) {
       let id = item.item.workId.split(',')
        this.getWorkAll(id, val)
      }

      // 点击编辑
      edit(item) {
        for(let i in this.state.requestParmas) {
            this.state.requestParmas[i] = item[i]
        }

        this.state.requestParmas.id = item.id

        this.state.requestParmas.enterpriseId = this.state.requestParmas.enterpriseId.toString()
        this.state.requestParmas.teamId = this.state.requestParmas.teamId.toString()
        this.state.requestParmas.workId = this.state.requestParmas.workId.toString()

        let teamID = this.state.enterpriseAllList.filter(item2 => item2.id == item.enterpriseId)
        if(teamID[0]) {
            this.onChangeEnterprise2(true,null,{item: teamID[0]}).then(res => {
                let workId = this.state.teamAllListSelect.filter(itme3 => itme3.id == item.teamId)
                this.onChangeTeam2(true, null, {item: workId[0]})
                this.clierFormSbmitRef()
            })
        }
    
        this.setState({
            isModalVisible : true
        })

      }

      
    
  render() {
    return (
      <div className="carList">
                                <div className="page1">
                                <div className="input">
                                   <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                                       <li><span>公司:</span> 
                                           <Select
                                                   showSearch
                                                   style={{ width: 200,  marginRight:20,  }}
                                                   placeholder="请选择"
                                                   optionFilterProp="children"
                                                   mode="multiple"
                                                   onChange={this.onChangeEnterprise.bind(this)}
                                               >
                                                   {
                                                       this.state.enterpriseAllList.map(item => {
                                                           return (
                                                               <Option value={item.id} item={item.teamId}>{item.name}</Option>  
                                                           )
                                                       })
                                                   }
                                               </Select>
                                               
                                       </li>
                                       <li><span>部门:</span> 
                                           <Select
                                                   showSearch
                                                   style={{ width: 200,  marginRight:20,  }}
                                                   placeholder="请选择"
                                                   optionFilterProp="children"
                                                   mode="multiple"
                                                   value = {this.state.queryParams.teamId}
                                                   onChange={this.onChangeTeam.bind(this)}
                                               >
                                                   {
                                                       this.state.teamAllList.map(item => {
                                                           return (
                                                               <Option value={item.id} item={item.workId}>{item.name}</Option>  
                                                           )
                                                       })
                                                   }
                                               </Select>  
                                       </li>
                                       <li><span>岗位:</span>  
                                       <Select
                                                   showSearch
                                                   style={{ width: 200,  marginRight:20,  }}
                                                   placeholder="请选择"
                                                   optionFilterProp="children"
                                                   mode="multiple"
                                                   value = {this.state.queryParams.workId}
                                                   onChange={this.onChangeWork.bind(this)}
                                               >
                                                   {
                                                       this.state.workAllList.map(item => {
                                                           return (
                                                               <Option value={item.id}>{item.name}</Option>
                                                           )
                                                       })
                                                   }

                                               </Select>
                                           </li>
                                        
                                           <li><span>车牌号:</span> <Input onChange={this.serchCar.bind(this, 'keyword')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" /></li>

                                           <li><span>类型:</span>  
                                                <Select
                                                        showSearch
                                                        style={{ width: 200,  marginRight:20,  }}
                                                        placeholder="请选择"
                                                        value = {this.state.queryParams.type}
                                                        onChange={this.onChangeType.bind(this)}
                                                    >
                                                       <Option value={''}>全部</Option> 
                                                      <Option value={2}>办公车</Option>
                                                        <Option value={3}>泵车</Option>
                                                        <Option value={4}>渣土车</Option>
                                                        <Option value={5}>洒水车</Option> 
                                                        <Option value={6}>扫水车</Option>
                                                        <Option value={7}>私家车</Option>          
                                                        <Option value={8}>油罐车</Option>
                                                        <Option value={9}>吊车</Option> 
                                                        <Option value={10}>罐车</Option>          
                                                    </Select>
                                           </li>  
               
                                           <Button  className="btn" onClick={this.toSerch.bind(this)}>
                                               <SearchOutlined  className="icon"/>
                                           </Button>


                                           <div class="allBtn">
                                                <Button  className="bt" type="primary" onClick={this.toInput.bind(this)}  icon={<PlusOutlined />}>
                                                    录入
                                                </Button>             
                                            </div>
                                   </ul>

                               </div>

                               <div className="table2">
                                   <Table  className="table" align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                                   loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }} columns={this.columns} dataSource={this.state.tableData}  />
                               </div>
                               </div>



                            <Modal title="添加" width="50%" className="ProgressNodeModal" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                                            <Form
                                                layout="vertical"
                                                onFinish={this.onFinish.bind(this)}
                                                ref={(el) => this.FormSbmitRef = el}
                                                {...layout}
                                                initialValues = {this.state.requestParmas}
                                            >   
                                                <div className="flex setFlex">
                                                    <div>
                                                        <Form.Item   label="车牌号" name="plateNumber"   rules={[{ required: true }]}>
                                                        <Input style={{ width:200,  }}  placeholder="请输入车牌号" />
                                                        </Form.Item>

                                                    </div>
                                                    
                                                    <div>
                                                        <Form.Item label="车主姓名"   name="empName" rules={[{ required: true }]} >
                                                        <Input style={{ width:200,  }}  placeholder="请输入车主姓名" />
                                                        </Form.Item>
                                                    </div>

                                                    <div>
                                                        <Form.Item   label="手机号码" name="empMoblie"   rules={[{ required: true }]}>
                                                        <Input style={{ width:200,  }}  placeholder="请输入手机号码" />
                                                        </Form.Item>
                                                    </div>

                                                </div>

                                                <div className="flex setFlex">
                                                    <div>
                                                        <Form.Item   label="所属公司" name="enterpriseId"   rules={[{ required: true }]}>
                                                        {/* <Input style={{ width:350,  }}  placeholder="请选择" /> */}
                                                            <Select
                                                                showSearch
                                                                style={{ width:200,  }}
                                                                placeholder="请选择公司"
                                                                optionFilterProp="children"
                                                                onChange={this.onChangeEnterprise2.bind(this, true)}
                                                                >
                                                                    {
                                                                        this.state.enterpriseAllList.map(item => {
                                                                            return (
                                                                                <Option value={item.id.toString()} item={item}>{item.name}</Option>
                                                                            )
                                                                        })
                                                                    }
                                                            </Select>
                                                        </Form.Item>
                                                    </div>

                                                    <div>
                                                        <Form.Item   label="所属部门" name="teamId"   rules={[{ required: true }]}>
                                                            <Select
                                                                    showSearch
                                                                    style={{ width:200,  }}
                                                                    placeholder="请选择部门"
                                                                    optionFilterProp="children"
                                                                    onChange={this.onChangeTeam2.bind(this, true)}
                                                                    >
                                                                        {
                                                                            this.state.teamAllListSelect.map(item => {
                                                                                return (
                                                                                    <Option value={item.id.toString()} item= {item}>{item.name}</Option>
                                                                                )
                                                                            })
                                                                        }
                                                                </Select>
                                                        </Form.Item>
                                                    </div>

                                                    <div>
                                                        <Form.Item   label="所属岗位" name="workId"   rules={[{ required: true }]}>
                                                            <Select
                                                                    showSearch
                                                                    style={{ width:200,  }}
                                                                    placeholder="请选择岗位"
                                                                    optionFilterProp="children"
                                                                    >
                                                                        {
                                                                            this.state.workAllListSelect.map(item => {
                                                                                return (
                                                                                    <Option value={item.id.toString()}>{item.name}</Option>
                                                                                )
                                                                            })
                                                                        }
                                                                </Select>
                                                        </Form.Item>
                                                    </div>

                                                </div> 

                                                 <div className="flex setFlex">
                                                 <div>
                                                        <Form.Item   label="车辆类型" name="type"   rules={[{ required: true }]}>
                                                            <Select
                                                                showSearch
                                                                style={{ width:200,  }}
                                                                placeholder="请选择车辆类型"
                                                                optionFilterProp="children"
                                                                >

                                                                    <Option value={2}>办公车</Option>
                                                                    <Option value={3}>泵车</Option>
                                                                    <Option value={4}>渣土车</Option>
                                                                    <Option value={5}>洒水车</Option> 
                                                                    <Option value={6}>扫水车</Option>
                                                                    <Option value={7}>私家车</Option>          
                                                                    <Option value={8}>油罐车</Option>
                                                                    <Option value={9}>吊车</Option> 
                                                                    <Option value={10}>罐车</Option>       
                                                            </Select>
                                                        </Form.Item>
                                                    </div>                
                                                
                                                </div> 
                                            </Form>

                            </Modal> 
                       
                                                   
                            
                               
      </div>
    );
  }
}
