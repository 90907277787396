import React from "react";

import '../BehaviorManagement.less';

import * as echarts from 'echarts';

import {Carousel,Input,Select,Button, Table,  Image,Tag, Modal, DatePicker} from "antd";

import { SearchOutlined} from '@ant-design/icons';

import {formatTime} from '@/utils/util'

const {Option} =  Select;

const imgUrl = process.env.REACT_APP_IMG_URL;

let currentTime = null;

export default class BehaviorRecord extends React.Component {
 
    columns = [
        {
            title: "序号",
            dataIndex: 'title',
            align: 'center',
            render: (_, item, index) => {
              return (
                <p>{index + 1}</p>
              )
            }
          },
        {
            title: '日期',
            dataIndex: 'addTime',
            align: 'center',
            render: (_) => {
                return (
                    <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')}</p>
                )
            }
        },
        {
          title: '姓名',
          dataIndex: 'nameIdCard',
          align: 'center'
        },
        {
            title: '奖罚类型',
            dataIndex: 'nameStarType',
            align: 'center'
        },
        {
            title: '公司',
            dataIndex: 'nameEnterprise',
            align: 'center'
        },

        {
          title: '部门',
          dataIndex: 'nameTeam',
          align: 'center'
        },
        {
            title: '岗位',
            dataIndex: 'nameWork',
            align: 'center'
        },
        {
          title: '奖罚分数',
          dataIndex: 'fraction',
          align: 'center',
          render: (_, item) => {
            
            if(item.typeStarType == 2) {
                return (
                    <Tag color="#00B150">{_}</Tag>
                )
            } else {
                return (
                    <Tag color="red">{_}</Tag>
                )
            }
          }
        },
        {
            title: '手机号',
            dataIndex: 'phoneUser',
            align: 'center'
        },

          {
                title: '主要事由',
                dataIndex: 'mainReason',
                align: 'center',
                width: 300
            },

            {
                title: '备注',
                dataIndex: 'remark',
                align: 'center',
                width: 180
            },
            {
              title: '操作',
              dataIndex: 'nameIdCardAdd',
              align: 'center',
              render: (_, item) => {
                return (
                  <span className="detailBehavior" onClick={this.getDetailMsg.bind(this, item)}>详细信息</span>
                )
              } 
            },
      ]

      
  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,
    thisMonthParams: false,

    tableData: [
        
    ],
    TableParams: {
        idCaName: "",
        lengthLimit: 10,
        offsetLimit: 0,
    },
    isModalVisible: false,
    setMsg: {
      img: []
    },
    status: true,
    projectId : React.$getStorage('projectInfo').id,


    count: {},
    ranking: {
      userPrize: {},
      userPunish: {}
    },
    currentTime: null
  }




  componentDidMount() {
    
    this.getTableData()

    this.safeStarStatistics(this.state.thisMonthParams);
  }

 // 安全行为之星统计 
 safeStarStatistics(thisMonthParams, time) {
   let params = {
    projectId: this.state.projectId,
    thisMonth:thisMonthParams? 1: '',
    time: thisMonthParams? parseInt(new Date(time).getTime() / 1000) : 0,
}


  React.$axios.post('safeStarStatistics', params).then(res => {
    res.data.ranking.userPrize = res.data.ranking.userPrize?res.data.ranking.userPrize:{}
    res.data.ranking.userPunish = res.data.ranking.userPunish?res.data.ranking.userPunish:{}
    
    this.setState({
      count: res.data.count,
      ranking: res.data.ranking
    })

    this.getEcharts3('main3', res.data.type.prize)
    this.getEcharts3('main4', res.data.type.punish, '#C71D4C')
  })
 }


 getEcharts3(id, arr2, color="#004EA1") {
  let name = []
  let data = []

  for(let i in arr2) {
    name.push(i);
    data.push(arr2[i]);
  }


  var myChart = echarts.init(document.getElementById(id));
  let option = {
      color: [color],
      grid: {
        left: '3%',
        right: '10%',
        bottom: '10%',
        containLabel: true
      },
      tooltip: {
        show: true,
    },


      xAxis: {
          type: 'category',
          data: name,
          axisLine: {
              lineStyle: {
                type: 'dashed',
                 color: '#7F7F7F',
                width: '1'
              }
            }
      },
      yAxis: {
          type: 'value',
          splitLine: {
              show: true,
              lineStyle:{
                  type:'dashed'
              }
          },
      },

      series: [{
          barWidth: 20,
          barMaxWidth:40,
          data: data,
          type: 'bar',
          label: {
              formatter: "{c}",
              show: true,
              position: 'top',
              color: color
         }
      }]
  };

myChart.setOption(option);
}


  getDetailMsg(data) {
    this.setState({
      isModalVisible: true,
      setMsg: data
    })
  }

  handleOk() {
    this.setState({
      isModalVisible: false
    })
  }

  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

      React.$axios.post('safeStarList', this.state.TableParams).then(res => {
        this.state.pagination.total = res.data.countList

        res.data.list.map(item => {
          if(item.img) {
            item.img = JSON.parse(item.img)
          } else {
            item.img = []
          }
          
        })




        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    this.state.TableParams.idCaName = value
  }

  onChangeWork(name, value) {
    this.state.TableParams.type = value
  }

  toSerch2() {
    this.getTableData(true)
  }

  handleTableChange(value) {
    this.state.pagination = value

    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }

  changeStatus(val) {
    this.setState({
      status: val
    })
  }

  changeActive(val) {
    this.setState({
      thisMonthParams: val
    })

    if(!val) {
      this.safeStarStatistics(val)
    } 

  }


  onChange(val) {
    this.setState({
        currentTime: val._d
    })

    currentTime =  val._d
  }

  onOpenChange(val) {
      if(!val) {
        this.safeStarStatistics(true, currentTime);
      }
  }


  render() {
    return (
      <div className="BehaviorRecord">
          <div className="topAllBtn">
            <p className={this.state.thisMonthParams?"active":''}   onClick={this.changeActive.bind(this, true)}>
              {this.state.currentTime?formatTime(this.state.currentTime, 'yyyy年MM月'): '请选择'}
              <DatePicker  picker="month" className="setOpctiy" onOpenChange={this.onOpenChange.bind(this)} onChange={this.onChange.bind(this)}></DatePicker>  
            </p>
            <p className={this.state.thisMonthParams?"":'active'} onClick={this.changeActive.bind(this, false)}>全部</p>
          </div>
        <div className="btn2">
           
          </div>

                  <div className="BehaviorHead">
                      <div className="head">
                      <ul>
                          <li>
                              <h1>排查统计</h1>
                              <div className="number">
                                  <div>
                                      <h2>{this.state.count.prize}</h2>
                                      <p>行为奖励（次）</p> 
                                  </div>

                                  <div>
                                      <h2 className="blue">{this.state.count.punish}</h2>
                                      <p>行为处罚（次）</p> 
                                  </div>
                                      
                              </div>

                          </li>
                          <li>
                            
                          <Carousel autoplay dots={false}>
                              <ul className="person">
                              
                                <li>
                                    <img src={imgUrl + this.state.ranking.userPrize.avatarKeyCutout} alt="" />
                                    

                                    <div className="briefIntroduction">
                                        <h2 className="fontColor">安全文明之星</h2>

                                        <h3 className="fontColor">{this.state.ranking.userPrize.nameIdCard} <i>' '</i> </h3>
                                        <p>{this.state.ranking.userPrize.nameEnterprise} <i>' '</i> </p>
                                        <p>{this.state.ranking.userPrize.nameTeam} <i>' '</i> </p>   

                                        <h3 className="fontColor">共奖励次数 </h3>
                                        <div><span>{this.state.ranking.userPrize.countStar} <i>' '</i> </span>次</div>   
                                    </div>
                                </li>       
                            </ul>

                            <ul className="person backg">
                              
                              <li>
                                    <img src={imgUrl + this.state.ranking.userPunish.avatarKeyCutout} alt="" />
                                    
                                    <div className="briefIntroduction">
                                        <h2 className="fontColor">需关注对象</h2>

                                        <h3 className="fontColor">{this.state.ranking.userPunish.nameIdCard} <i>' '</i> </h3>
                                        <p>{this.state.ranking.userPunish.nameEnterprise} <i>' '</i> </p>
                                        <p>{this.state.ranking.userPunish.nameTeam} <i>' '</i> </p>   

                                        <h3 className="fontColor">共处罚次数</h3>
                                        <div><span>{this.state.ranking.userPunish.countStar} <i>' '</i> </span>次</div>   
                                    </div>
                                </li>        
                            </ul>
                            </Carousel>

                          </li>
                          <li>
                              <h1>行为奖励统计 <span>（次）</span></h1>

                              <div id="main3" className="echarts">

                              </div>

                          </li>
                          <li>
                              <h1>行为处罚统计 <span>（次）</span></h1>
                              <div id="main4" className="echarts">

                              </div>   
                          </li>
                      </ul>
                    </div>


                  <div className="setBack">
                  <div className="input">
                            <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                                <li><span>姓名:</span> 
                                <Input onChange={this.serchCar.bind(this, 'keyword')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                                
                                </li>
                                <li><span>奖罚类型:</span>  
                                <Select
                                            showSearch
                                            style={{ width: 200,  marginRight:20,  }}
                                            placeholder="请选择"
                                            optionFilterProp="children"
                                            onChange={this.onChangeWork.bind(this, 'uiRole')}
                                        >
        
                                        <Option value=" ">全部</Option>
                                        <Option value="2">奖励</Option>
                                        <Option value="3">惩罚</Option>
                                        </Select>
                                    </li>
        
                                    <Button  className="btn" onClick={this.toSerch2.bind(this)}>
                                        <SearchOutlined  className="icon"/>
                                    </Button>
                            </ul>
                        </div>
        
        
        
                        <div className="table2">
                        {/* <div class="allBtn">
                           
          
                            <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                                导入
                            </Button>
                            <Button  className="bt" type="primary" icon={<UploadOutlined className="translate" />}>
                                导出
                            </Button>
                            <Button  className="bt" type="primary" icon={<PlusOutlined />}>
                                新建
                            </Button>
                            </div> */}
        
        
                            <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                            loading={this.state.loading} expandable={{ expandedRowRender: this.expandedRowRender }} columns={this.columns} dataSource={this.state.tableData}  />
                        </div>
        
                        </div>
                        </div>


                <Modal title="详细信息" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleOk.bind(this)}>
                    <div className="BehaviorManagementModal">
                      <div className="flex head">
                          <div className="msgPerson">
                            <div>奖罚对象</div>
                            <p>{this.state.setMsg.nameIdCard}</p>
                          </div>

                          <Image
                              width={100}
                              src={imgUrl + this.state.setMsg.avatarKeyInfo}
                            />
                           
                          
                      </div>

                      <ul>
                        <li><h1>所属部门</h1> <p>{this.state.setMsg.nameTeam}</p></li>
                        <li><h1>执行人员</h1> <p>{this.state.setMsg.nameTeamAdd}/{this.state.setMsg.nameIdCardAdd}</p></li>
                        <li><h1>处理日期</h1> <p>{formatTime(new Date(this.state.setMsg.addTime * 1000), 'yyyy-MM-dd hh:mm:ss')}</p></li>
                        <li><h1>主要事由</h1> <p>{this.state.setMsg.mainReason}</p></li>

                        <div className="merk">{this.state.setMsg.remark}</div>
                        <li><h1>证明人</h1> <p>{this.state.setMsg.nameTeamProve}/{this.state.setMsg.nameIdCardProve}</p></li>
                      </ul>

                      <div className="allImg">
                        {
                          this.state.setMsg.img.map((item, index) => {
                            
                              return (
                                <div key={index}>
                                  <Image
                                    width={100}
                                    src={imgUrl + item}
                                  />
                                </div>
                                
                              )

                          })
                        }
                      </div>

                    </div>
              </Modal>   

      </div>
    );
  }
}
