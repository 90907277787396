import React from "react";




import { Table,Button, Modal, Select,  Input, Form, message, Spin, DatePicker, Checkbox} from 'antd';


import {CloseCircleFilled} from '@ant-design/icons';


import moment from 'moment';

import {formatTime} from '@/utils/util'


import { Uploadhh } from "@/components/upload/upload";


import qrCode from '@/assets/img/coordination/1628581868(1).jpg';
import baImg from '@/assets/img/coordination/12.png'
import tipsImg from '@/assets/img/coordination/11.png'
import pdfImg from '@/assets/img/DrawingList/pdf (2).png';

import exportTemplate from '@/assets/template/exportTemplate.docx'; // 导出模板


import {DownLoadWorld} from '@/components/downLoadWorld/downLoadWorld';


import './MeetingSignIn.less';


const { Option } = Select;

const imageUrl = process.env.REACT_APP_IMG_URL

export default class MeetingSignIn extends React.Component {
    FormSbmitRef = null
    DownLoadWorldRef = null
  state = {
    herfEarth: '',
    isModalVisible: false,
    isModalVisible2: false, // 目录穿件编辑
    widthStatus: false,

    titleName: '图纸上传',
    titleNameItem: '新建签到表',

    listDrawingList: [], // 目录列表
    fileName: '',
    params: {
        projectId: null,
        logic: 2,
        offsetLimit: 0,
        lengthLimit: 20,
    },

    addItem: { // 目录
        // logic: 2,
        name: null,
        place: null,
        timeStart: null,
        timeStop: null,
        background: null,
        material: []
    },

    addFileParams: {
        type: null,
        pid: null,
        child: [],
        logic: 3,
    },
    
    loading: false,
    currentItem: {
        materialArr: []
    },
    RegistrationListUser: []
  }

  componentDidMount() {
    let projectId = React.$getStorage('projectInfo').id
    this.state.params.projectId  = projectId
    this.setState({
        projectId: this.state.params
    })

    this.getList()

  }

  

  getList() {

    let params = this.state.params
    this.getAxios(params).then(res => {
        console.log(res)
        this.setState({
            listDrawingList: res.data.list
        })
    })
  }

  getAxios(params) {
    return new Promise(resolove => {
        React.$axios.post('listConferenceRegistration', params).then(res => {
            res.data.list.map(item => {
                if(!item.material) {
                    item.materialArr = []
                } else {
                    item.materialArr = JSON.parse(item.material) 
                }
            })


            console.log(res.data.list)


            resolove(res)
        })
    })
  }
  


  edit(item, e) { // 编辑目录名称
    this.state.addItem.name = item.name
    this.state.addItem.place = item.place
    this.state.addItem.timeStart =  moment(item.timeStart * 1000)   
    this.state.addItem.timeStop = moment(item.timeStop * 1000) 
    this.state.addItem.id = item.id
    this.state.addItem.background = item.background

    if(!item.material)  {
        item.material = '[]'
    }

    this.state.addItem.material = JSON.parse(item.material) 

    this.clierFormSbmitRef()

    this.setState({
        addItem: this.state.addItem,
        isModalVisible2: true,
        titleNameItem: '编辑目录'
    })

    e.stopPropagation()
  }

  uploadFile(item, e) { // 上传文件

    console.log(item)

    this.state.addFileParams.pid = item.id

    this.setState({
        isModalVisible: true,
        addFileParams:  this.state.addFileParams,
    })

    e.stopPropagation()
  }



  handleOk() {

    // console.log(this.state.addFileParams)

    // if(this.state.addFileParams.type == null) {
    //     message.error('请选择图纸分类')
    //     return
    // }


    // React.$axios.post('saveDrawing', this.state.addFileParams).then(res => {
    //     console.log(res)
    //     if(res.code == 20000) {
    //         message.success('保存成功')
    //         this.handleCancel()
    //         this.getList()
    //     }
    // })
  }

  handleOkItem() { // 创建目录
    this.FormSbmitRef.submit()
  }

  // 打开PDF
  openPdf(item) {
    window.open(imageUrl + item.url)
  }

  onFinishItem(val) {
    for(let i in val) {
        this.state.addItem[i] = val[i];
    }
    this.state.addItem.timeStart =  parseInt(val.timeStart._d.getTime() / 1000)
    this.state.addItem.timeStop = parseInt(val.timeStop._d.getTime() / 1000)

    React.$axios.post('saveConferenceRegistration', this.state.addItem).then(res => {
        if(res.code == 20000) {
            message.success('保存成功')
            this.handleCancel()
            this.getList()
        }
    })
  }



  // 清除默认数据
  clierFormSbmitRef() {
    if(!this.FormSbmitRef) {
        setTimeout(() => {
            
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
}


  handleCancel() {
    this.setState({
        isModalVisible: false,
        isModalVisible2: false
    })
  }




  onChange(name,value) {
    this.state.addFileParams[name] = value;

    this.setState({
        addFileParams: this.state.addFileParams
    })
  }


  // 上传前
  beforeFun(file) {
    let isLt3M = file.size / 1024 / 1024  < 3;
    if(isLt3M) { // 大小不能超过3M
        this.setState({
            loading: true
        })
        return true
    } else {
        message.error('pdf文件大小不能超过3M')
        return false
    }
  }

  // 上传后
  handleFront(res, file){
    if(res.code == 20000) {
        this.state.addItem.material.push({
            name: file.name,
            url: res.data.key
        })

        this.setState({
            addItem: this.state.addItem,
            loading: false
        })

    }
  }

//   addItem: { // 目录
//     // logic: 2,
//     name: null,
//     place: null,
//     timeStart: null,
//     timeStop: null,
//     background: null,
//     material: []
// },



  

  inputChange(name, index, val) { 

    this.state.addFileParams.child[index][name] = val.target.value
    this.setState({
        addFileParams: this.state.addFileParams
    })

  }


  addItemFun() { // 添加目录

      for(let i in this.state.addItem) {
        this.state.addItem[i] = null
      }   

      delete this.state.addItem.id

      this.state.addItem.material = []
      
      this.clierFormSbmitRef()

      this.setState({
          isModalVisible2: true,
          titleNameItem: '新建签到',
      })
  }

  // 
  removeList(item, e) {

    let params = {
        id: item.id,
        del: 2
    }

    React.$axios.post('saveConferenceRegistration', params).then(res => {
        if(res.code == 20000) {
            message.success('删除成功')
            this.getList()
        }
    })

    e.stopPropagation()
  }


  removeImg(index) {
    this.state.addFileParams.child.splice(index, 1);

    this.setState({
        addFileParams: this.state.addFileParams
    })
  }

  //点击查看
  toSee(status, item) {
    this.setState({
        widthStatus:status
    })

    // 展开就发请求
    if(status) {
        this.setState({
            currentItem: item
        })  
        this.getSginName(item)
        
    }
    
  }

  // 查看签到签名
  getSginName(item) {
    let params = {
        logic: 3,
        registrationId: item.id,
        projectId: item.projectId
    }
    React.$axios.post('listConferenceRegistrationUser', params).then(res => {

        res.data.list.map(item => {
            item.image_sign = 'https://gd-c1.oss-cn-shenzhen.aliyuncs.com/' + item.sign
        })

        this.setState({
            RegistrationListUser: res.data.list
        })

    })
  }




  singCode(item) {

    this.setState({
        currentItem: item,
        isModalVisible: true
    })
  }

  // 删除pdf
  removePdf(index) {
    this.state.addItem.material.splice(index, 1)

    this.setState({
        addItem: this.state.addItem
    })
  }


  // 点击导出World 文档
  clickDoloadWorld(item) {


    this.setState({
        fileName:  '会议签到' + formatTime(new Date(item.timeStart * 1000), 'yyyyMMdd') 
    })

    return new Promise(resolve => {
        let  host, recorder;
        let arrList = []
        this.state.RegistrationListUser.map(item => {
            if(item.host == 3) {
                host = item.image_sign
            }
            if(item.recorder == 3) {
                recorder = item.image_sign
            }
            arrList.push(item)
        })

        console.log(host)
        console.log(recorder)


        let allParams = {
            name:  item.name,
            time : formatTime(new Date(item.timeStart * 1000), 'yyyy年MM月dd日'),
            clients : arrList, // clients 遍历模板
            image_host: host,
            image_recorder: recorder,
        }


        // 
        let arr = []
        allParams.clients.map(item => { // 全部转为 base64 的图片
            arr.push(this.DownLoadWorldRef.getBase64(item.image_sign, item, 'sign_base64'))
        })


        Promise.all(arr).then(res => {
            resolve(allParams)
        })
    })
  }

  // 导出之后
  afterGenerate() {
    console.log(1111)
  }


  // 选择主持人
  onChangeCheckbox(index, name) {
    let data = this.state.RegistrationListUser
    let item = data[index]
    data.map(item => {
        item[name] = 2
    })
    
    
    data[index][name] = 3

    this.setState({
        RegistrationListUser: data
    })

    let params = {
        id: item.id,
        host: item.host,
        recorder: item.recorder
    }

    
    React.$axios.post('saveConferenceRegistrationUser', params).then(res => {
        if(res.code == 20000) {
            message.success('保存成功')
        }
    })


  }


  render() {
    const columns = [
        { title: '序号', dataIndex: 'key', align: 'center', key:'key', width: 150, render: (_, item, index) => {
              return (
                  <p className="setXUhao"><span>{index+1}</span></p>
              )  
        }},
        { title: '会议名称', dataIndex: 'name', key:'name', width:"25%" },
        { title: '签到人数', dataIndex: 'userCount', key:'userCount',  width:"10%", align: 'center' },
        { title: '会议时间', dataIndex: 'remark', key:'key', align: 'center', render: (_, item, index) => {
            let str = formatTime(new Date(item.timeStart * 1000), 'yyyy年MM月dd日 hh:mm')
            let str2 = formatTime(new Date(item.timeStop * 1000), 'yyyy年MM月dd日 hh:mm')
            return (
                <span>
                    {str + '~' + str2}
                </span>
            )

        }},
        { title: '操作', key:'key', width: 400, render: (_, item)=> {
            return (
                <div className='tableCa'>

                    <p  onClick={this.edit.bind(this, item)}  style={{cursor:'pointer', color: '#63ABFF'}} >编辑</p> <span>|</span> 
                    <p onClick={this.removeList.bind(this, item)} style={{cursor:'pointer', color: '#FF0000'}} >删除</p> <span>|</span>
                    <p onClick={this.toSee.bind(this, true, item)} style={{cursor:'pointer', color: '#00B050'}} >查看</p> <span>|</span>
                    <p onClick={this.singCode.bind(this, item)} style={{cursor:'pointer', color: '#FFC000'}} >签到码</p>
                </div>

                )
                
                
        }},
      ];


    return (
      <div className="MeetingSignIn">
        
      
        <div className="flex tableWarp">
            <div className="setWidth">
            <p className="posAbs">
                <Button type="primary" onClick={this.addItemFun.bind(this)}>新建签到</Button> 
            </p> 
                <Table
                    columns={columns}
                    dataSource={this.state.listDrawingList}
                />
            </div>
            

            <div className={`detail  ${this.state.widthStatus? 'addWidth': ''}`}>
                
                <div className={`reactive ${this.state.widthStatus? 'ShowBlock': ''}`}    onClick={this.toSee.bind(this, false)}>
                    ×
                </div>
                

                <div className="head" style={{backgroundImage: `url(${baImg})`}}>
                    <h1>签到详情</h1>
                    <p className="tips">无纸化协同在线办公</p>

                    <div className="title">
                        <h2>{this.state.currentItem.name}</h2>
                        <div className="setTime flex">
                            <p>{formatTime(new Date(this.state.currentItem.timeStart * 1000), 'yyyy年MM月dd日 hh:mm') + '~' + formatTime(new Date(this.state.currentItem.timeStop * 1000), 'yyyy年MM月dd日 hh:mm')  }</p>
                            <p>已签到人数 <span>{this.state.currentItem.userCount}</span> 人</p>
                        </div>
                    </div>

                    <ul>
                        <li>
                            <h3><img src={tipsImg} alt="" /> 会议资料</h3>


                             <div className="resources">

                                {
                                    this.state.currentItem.materialArr.map((item, index) => {
                                        return (
                                            <div className="li" key={'materialArr' + index} onClick={this.openPdf.bind(this, item)}>
                                                <div className="setback"></div>
                                                <div className="liWarp flex">
                                                <p>{item.name}</p>
                                                <img src={pdfImg} alt="" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                             </div>                       

                        </li>
                        <li>
                            <h3><img src={tipsImg} alt="" /> 会议签到</h3>
                            <div className="sginWarp">
                                {
                                    this.state.RegistrationListUser.map((item, index) => {
                                        return (
                                            <div className="siginName" key={'regis' + index}>
                                                <div className="flex just">
                                                    <div>
                                                        <p> {item.name}</p>
                                                        <p> {item.phone}</p>
                                                    </div>
                                                </div>
                                               

                                                <img src={imageUrl + item.sign} alt="" />
                                                <div className="flex just">
                                                    <div></div>

                                                     <div>
                                                        <Checkbox checked={item.host == 3? true: false} onChange={this.onChangeCheckbox.bind(this, index, 'host')}>主持人</Checkbox>
                                                        <Checkbox checked={item.recorder == 3} onChange={this.onChangeCheckbox.bind(this, index, 'recorder')}>记录人</Checkbox>
                                                    </div>
                                                </div>
                                                    
                                            </div>    
                                        )
                                    })
                                }
                            </div>

                        </li>
                    </ul>

                </div>
                <DownLoadWorld fileName={this.state.fileName} exportTemplate={exportTemplate}  ref = {(el) => this.DownLoadWorldRef = el} beforeGenerate = {this.clickDoloadWorld.bind(this, this.state.currentItem)} afterGenerate={this.afterGenerate.bind(this)}>
                    <p className="posAbsDeatail">
                        <Button type="primary">导出签到表</Button> 
                    </p>              
                </DownLoadWorld>                   

                          
            </div>

        </div>


      <Modal title="签到码" width="30%" className="MeetingSignInModalCode"  visible={this.state.isModalVisible}  onOk={this.handleOkItem.bind(this)} onCancel={this.handleCancel.bind(this)}>

        
        <div className="warp">
        <div className="body">
            <h1>{this.state.currentItem.name}</h1>

            <p>时间：{formatTime(new Date(this.state.currentItem.timeStart * 1000), 'yyyy年MM月dd日 hh:mm') + '~' + formatTime(new Date(this.state.currentItem.timeStop * 1000), 'yyyy年MM月dd日 hh:mm')  }</p>
            <p>地点：{this.state.currentItem.place}</p>


            <div className="qrCode">
                <img src={imageUrl + this.state.currentItem.qrCode} alt="" />
            </div>
            <h3>扫码签到</h3>

        </div>
        </div>

        <img className="setBg" src={baImg} alt="" />



      
      
      </Modal>

      <Modal title={this.state.titleNameItem} width="40%" className="MeetingSignInModal"  visible={this.state.isModalVisible2}  onOk={this.handleOkItem.bind(this)} onCancel={this.handleCancel.bind(this)}>
      <Form
            name="basic"
            // labelCol={{ span:  8}}
            ref={(el) => this.FormSbmitRef = el}
            initialValues={this.state.addItem}
            onFinish={this.onFinishItem.bind(this)}
            >
            <Form.Item
                label="会议名称"
                name="name"
            >
                <Input  placeholder="请输入会议名称" />
            </Form.Item>

            <Form.Item
                label="会议地点"
                name="place"
            >
                <Input placeholder="请输入会议会议地点" />
            </Form.Item>

            <ul className="time">
                <li>
                <Form.Item
                    label="会议时间"
                    name="timeStart"
                    
                >
                    {/* <Input  placeholder="请选择时间" /> */}

                    <DatePicker onChange={this.onChange.bind(this)} showTime format='YYYY-MM-DD HH:mm' />

                </Form.Item>
                </li>
                <li>
                <Form.Item
                    name="timeStop"
                    label="至"
                >
                    <DatePicker onChange={this.onChange.bind(this)} showTime format='YYYY-MM-DD HH:mm' />
                </Form.Item>
                </li>
            </ul>


            <div className="flex">
                <p>
                    会议文件: <span className="setSize">（仅支持PDF格式、单个文件大小不大于3M）</span>
                </p>

                <p className="posAbs">
                    <Button type="primary">
                    <div className="setUPloda"> <Uploadhh accept = '.pdf' pdfStatus={true}  name="file" data={{type: 71,  logic: 3}}  width={85} height={30} beforeFun={this.beforeFun.bind(this)}    handleClick={this.handleFront.bind(this)} />
                    </div>
                        选择文件
                     </Button>


                    {/* <Button type="primary" onClick={this.addPdf.bind(this)}>
                        
                        选择文件
                        </Button>  */}
                </p> 

            </div>


            <ul className="fileList">
            <Spin spinning={this.state.loading} >
                 {
                     function () {
                        if(this.state.addItem.material.length == 0) {
                            return (
                                <p>请选择需要上传的文件</p>
                            )
                        }

                     }.bind(this)()
                 }
                 
                 {
                     this.state.addItem.material.map((item, index) => {
                         return (
                             <li>
                                <h4>{item.name}</h4>
                                <h5 onClick={this.removePdf.bind(this, index)}>删除</h5> 
                             </li>
                         )
                     })
                 }       
                
            </Spin>
            </ul>     
            </Form>
      </Modal>      
            
      </div>
    );
  }
}
