import React, { useState, useEffect } from "react";
import { Form, Button, Input, DatePicker, Table, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import './educationLog.less'
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { formatTime } from '@/utils/util.js'

const { Option } = Select;

function EducationLog() {
  const [searchForm] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [tableList, setTableList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })
  const [enterpriseAllList, setEnterpriseAllList] = useState([])
  const columns = [
    {
      title: '序号',
      align: 'center',
      render: (text, record, index) => {
        return <span>{index + 1}</span>
      }
    },
    {
      title: '姓名',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '单位',
      dataIndex: 'nameEnterprise',
      align: 'center',
    },
    {
      title: '部门',
      dataIndex: 'nameTeam',
      align: 'center',
    },
    {
      title: '课程名称',
      dataIndex: 'nameCourse',
      align: 'center',
    },
    {
      title: '课程类型',
      dataIndex: 'nameCourseSort',
      align: 'center',
    },
    {
      title: '时长(分)',
      dataIndex: 'duration',
      align: 'center',
      render: (_) => {
        let num = _ / 60
        num = num.toFixed(2)
        return (
          <p>{num}</p>
        )
      }
    },
    {
      title: '培训日期',
      dataIndex: 'grdOfConcrete',
      align: 'center',
      render: (text, record, index) => {
        return <span>{formatTime(new Date(record.addTime * 1000), "yyyy-MM-dd hh:mm:ss")}</span>
      }
    },
    {
      title: '培训员',
      dataIndex: 'nameIdCardAdd',
      align: 'center',
    }
  ];
  useEffect(() => {
    getEnterpriseAll()
    getTrainingUser(true);
  }, []);
  // useEffect(() => {
  //   getTrainingUser();
  // }, [pagination])

  function onPaginationChange(page) {
    setPagination(page)
    pagination.current = page.current
    getTrainingUser();
  }
  // 获取公司 
  function getEnterpriseAll() {
    React.$axios.post('enterpriseAll').then(res => {
      setEnterpriseAllList(res.data.enterpriseAll)
    })
  }
  function getTrainingUser(type) {
    const { keyword, enterpriseId, nameCourse, time } = searchForm.getFieldsValue()
    console.log(time)
    const addTimeStart = time ? parseInt(new Date(time[0]._d).getTime() / 1000) : ""
    const addTimeStop = time ? parseInt(new Date(time[1]._d).getTime() / 1000) : ""
    const requestData = {
      offsetLimit: (pagination.current - 1) * pagination.pageSize,
      lengthLimit: pagination.pageSize,
      logic: 2,
      keyword,
      enterpriseId,
      nameCourse,
      addTimeStart,
      addTimeStop
    }
    // console.log(requestData)
    setLoading(true)
    React.$axios.post('listEducationTrainingUser', requestData).then(res => {
      if (type) {
        setPagination({
          ...pagination,
          total: res.data.countList
        })
      }
      setTableList(res.data.list)
      setpage(res)
    }).finally(() => {
      setLoading(false)
    })
  }

  function toSerch() {
    setPagination({
      current: 1,
      pageSize: 10,
      total: 0
    })
    pagination.current = 1
    getTrainingUser(true)
  }



  function setpage(res) {
    console.log(res)
    pagination.current = pagination.current;
    pagination.pageSize = pagination.pageSize;
    pagination.total = res.data.countList;
    let json = JSON.parse(JSON.stringify(pagination)); 
    json.onShowSizeChange = function(current, pageSize) {
        pagination.pageSize = pageSize;
        let json = JSON.parse(JSON.stringify(pagination)); 
        setPagination(json)
        onPaginationChange(json)
    }
    json.onChange = function(current) {
        pagination.current = current
        let json = JSON.parse(JSON.stringify(pagination)); 
        setPagination(json)
        onPaginationChange(json)
    }
    json.showTotal = function () {  //设置显示一共几条数据
        return '共 ' + pagination.total + ' 条'; 
    }
    setPagination(json)
}






  return (
    <div className="educationLog">
      <div className="search-wrap">
        <Form form={searchForm} layout="inline" onFinish={toSerch}>
          <Form.Item
            name="keyword"
            label="关键字"
          >
            <Input placeholder="模糊查询" allowClear={true} />
          </Form.Item>
          <Form.Item
            name="enterpriseId"
            label="单位"
          >
            <Select
              style={{ width: 200, marginRight: 20, }}
              placeholder="请选择"
              allowClear={true}
            >
              {
                enterpriseAllList.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>{item.name}</Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            name="nameCourse"
            label="课程名称"
          >
            <Input placeholder="请输入课程名称" allowClear={true} />
          </Form.Item>
          <Form.Item
            name="time"
          >
            <RangePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon={<SearchOutlined />} htmlType="submit">搜索</Button>
          </Form.Item>
        </Form>
      </div>
      <div className="table-wrap">
        <Table
          bordered
          loading={loading}
          columns={columns}
          dataSource={tableList}
          pagination={pagination}
        />
      </div>
    </div>
  )
}

export default withRouter(EducationLog);