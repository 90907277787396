import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import debounce from 'lodash.debounce'
import Style from './tower.module.less';



import {formatTime} from '@/utils/util';

import {setEchartDiaozhong, setEchartliju, setEchartDashboardChart} from './echart';



function VideoTurning() {

    const [status, setStatus] = useState(0)
    const [scale, setRate] = useState(0)
    const [indexItem, setIndex] = useState(0)
    const [widthHieghtRate, setWidthHieghtRate] = useState({
        width:window.innerWidth, height:window.innerHeight
    })
    //项目数量
    const [projectCount, setProjectCount] = useState(0)
    //视频个数
    const [shiCount, setShiCount] = useState(0)

    const [isVisible, setIsVisible] = useState(false)
    const [videoParams, setVideoParams] = useState({})
    const [videoList, setVideoList] = useState([])
    const [oneMinuteAll, setOneMinuteAll] = useState({
        year: null,
        minute: null
    })
    const [echartStatus, setEchartStatus] = useState(true)

    useEffect(() => {
        // clearAllTime()
        window.addEventListener('resize', setScale)
        setEchartDiaozhong();
        setEchartliju();
        setEchartDashboardChart();
        
        return () => {
            window.removeEventListener('resize', setScale)
        }

      
        // return () => {
            
        // }
    }, {})


    useEffect(() => {
      let scale =  getScale()

      setRate(scale)

      //  FunSetInterval()


      //  setMniueTime()

      //  oneMniueTime()

        // 设置图形
        // setTimeout(() => {
        //     setEchart()
        //     setEchart2()
        // }, 200)
        


        return () => {
            // clierIntervalFun()
            // clearAllTime()
        }
    }, []);









    // 清除全部定时器
    function clearAllTime() {
        // clearInterval(oneMinute)
        // clearInterval(echartTime)
        // clearInterval(timerPorject)
        // clearInterval(projectTime)
    }




    
    function getScale() {
        let ww=window.innerWidth/widthHieghtRate.width
        let wh=window.innerHeight/widthHieghtRate.height
        
        return wh;
    }


    function setScale() {
        debounce(() => {
            let scale= getScale()
            setRate(scale)
        }, 500)()
    }






  const {width, height} = widthHieghtRate


  return (
     
    <div className={Style.towerBox}> 
      
    
    <div className="scaleBox"
        style={{
            transform: `scale(${scale}) translate(-50%, -50%)`,
            WebkitTransform: `scale(${scale}) translate(-50%, -50%)`,
            width,
            height
        }}>

          
         <div className="header">达州市第三污水处理厂</div> 

        <div className="bodyTower">

        <div className="leftTower">
            <div className="deviceRate">
            <svg style={{width: 540, height: 260}}>
            <path fill="transparent"
            d="
                M 5 20 L 5 10 L 12 3  L 60 3 L 68 10
                L 520 10 L 535 25
                L 535 255 L 20 255
                L 5 240 L 5 20
            " stroke="#6586ec">
              </path><path fill="transparent" stroke-width="3" stroke-linecap="round" stroke-dasharray="10, 5" d="M 16 9 L 61 9" stroke="#6586ec"></path><path fill="transparent" d="M 5 20 L 5 10 L 12 3  L 60 3 L 68 10" stroke="#2cf7fe"></path><path fill="transparent" d="M 535 230 L 535 255 L 510 255" stroke="#2cf7fe"></path>
              </svg>

              <div className="item_title">
                <div className="left"></div>
                <p>设备在线率</p>
                <div className="right"></div>
              </div>

              <div className="context">

              <div className="barChart" id="barChart">
                <img src="http://screen.xiwon588.com/static/img/online_rate.76baea9b.png" alt="" />
                <div>
                  <p>100%</p>
                  <p>在线率</p>
                </div>

                <p className="posP">在线2台</p>
              </div>

              <div className="text">
                <p className="img">
                
                </p>  

                <div className="light">
                  <img src="http://screen.xiwon588.com/static/img/315b426.315b4268.png" alt="" />
                </div>
              
                
              </div>


              </div>

            </div>

            <div className="AverageWeight">
            <svg style={{width: 540, height: 260}}>
            <path fill="transparent"
            d="
                M 5 20 L 5 10 L 12 3  L 60 3 L 68 10
                L 520 10 L 535 25
                L 535 255 L 20 255
                L 5 240 L 5 20
            " stroke="#6586ec">
              </path><path fill="transparent" stroke-width="3" stroke-linecap="round" stroke-dasharray="10, 5" d="M 16 9 L 61 9" stroke="#6586ec"></path><path fill="transparent" d="M 5 20 L 5 10 L 12 3  L 60 3 L 68 10" stroke="#2cf7fe"></path><path fill="transparent" d="M 535 230 L 535 255 L 510 255" stroke="#2cf7fe"></path>
              </svg>

              <div className="item_title">
                <div className="left"></div>
                <p>平均吊重/额重</p>
                <div className="right"></div>
              </div>

              <div className="char" id="diaozhong">
                
              </div>
            </div>

            
            <div className="AverageTorque">
            <svg width="540" height="350" class="dv-border-svg-container"><path fill="transparent" d="
              M 5 20 L 5 10 L 12 3  L 60 3 L 68 10
              L 520 10 L 535 25
              L 535 345 L 20 345
              L 5 330 L 5 20
            " stroke="#6586ec"></path><path fill="transparent" stroke-width="3" stroke-linecap="round" stroke-dasharray="10, 5" d="M 16 9 L 61 9" stroke="#6586ec"></path><path fill="transparent" d="M 5 20 L 5 10 L 12 3  L 60 3 L 68 10" stroke="#2cf7fe"></path><path fill="transparent" d="M 535 320 L 535 345 L 510 345" stroke="#2cf7fe"></path></svg>
              <div className="item_title">
                  <div className="left"></div>
                  <p>平均力矩</p>
                  <div className="right"></div>
              </div>

              <div className="char" id="liju">
                
              </div>

            </div>



            
        </div>

        <div className="centerTower">
          <div className="towerDevice">
          <svg width="720" height="530" class="dv-border-svg-container"><path fill="transparent" d="
            M 5 20 L 5 10 L 12 3  L 60 3 L 68 10
            L 700 10 L 715 25
            L 715 525 L 20 525
            L 5 510 L 5 20
          " stroke="#6586ec"></path><path fill="transparent" stroke-width="3" stroke-linecap="round" stroke-dasharray="10, 5" d="M 16 9 L 61 9" stroke="#6586ec"></path><path fill="transparent" d="M 5 20 L 5 10 L 12 3  L 60 3 L 68 10" stroke="#2cf7fe"></path><path fill="transparent" d="M 715 500 L 715 525 L 690 525" stroke="#2cf7fe"></path></svg>
           

          </div>

          <div className="listTable">
          <svg width="720" height="350" class="dv-border-svg-container"><path fill="transparent" d="
            M 5 20 L 5 10 L 12 3  L 60 3 L 68 10
            L 700 10 L 715 25
            L 715 345 L 20 345
            L 5 330 L 5 20
          " stroke="#6586ec"></path><path fill="transparent" stroke-width="3" stroke-linecap="round" stroke-dasharray="10, 5" d="M 16 9 L 61 9" stroke="#6586ec"></path><path fill="transparent" d="M 5 20 L 5 10 L 12 3  L 60 3 L 68 10" stroke="#2cf7fe"></path><path fill="transparent" d="M 715 320 L 715 345 L 690 345" stroke="#2cf7fe"></path></svg>
            
              <div className="item_title">
                  <div className="left"></div>
                  <p>实时数据</p>
                  <div className="right"></div>
              </div>
              






              <ul>
                <li>
                   <div>记录时间</div>
                   <div>高度(m)</div>
                   <div>幅度(m)</div>
                   <div>力矩(%)</div>
                   <div>载重(t)</div> 
                   <div>回转(°)</div> 
                   <div>报警原因</div>
                </li>
                <li>
                   <div>2023-05-25 14:30:29</div>
                   <div>5.9</div>
                   <div>29</div>
                   <div>42.61</div>
                   <div>1.469</div> 
                   <div>94.4</div> 
                   <div>正常</div>
                </li>
              </ul>
            </div>

        </div>

        <div className="rightTower">
        <div className="deviceRate">
            <svg style={{width: 540, height: 260}}>
            <path fill="transparent"
            d="
                M 5 20 L 5 10 L 12 3  L 60 3 L 68 10
                L 520 10 L 535 25
                L 535 255 L 20 255
                L 5 240 L 5 20
            " stroke="#6586ec">
              </path><path fill="transparent" stroke-width="3" stroke-linecap="round" stroke-dasharray="10, 5" d="M 16 9 L 61 9" stroke="#6586ec"></path><path fill="transparent" d="M 5 20 L 5 10 L 12 3  L 60 3 L 68 10" stroke="#2cf7fe"></path><path fill="transparent" d="M 535 230 L 535 255 L 510 255" stroke="#2cf7fe"></path>
              </svg>

              <div className="item_title">
                  <div className="left"></div>
                  <p>设备信息</p>
                  <div className="right"></div>
              </div>

              <ul>
                <li>
                  <div>出厂设备编号</div>
                  <div>产权备案号</div>
                  <div>产权单位</div>
                </li>
                <li>
                  <div>3059432</div>
                  <div>-</div>
                  <div>-</div>
                </li>
                <li>
                  <div>维保单位</div>
                  <div>生产厂家</div>
                  <div>安装单位</div>
                </li>
                <li>
                  <div>-</div>
                  <div>-</div>
                  <div>-</div>
                </li>
              </ul>
            </div>

            <div className="AverageWeight Dashboard">
            <svg style={{width: 540, height: 260}}>
            <path fill="transparent"
            d="
                M 5 20 L 5 10 L 12 3  L 60 3 L 68 10
                L 520 10 L 535 25
                L 535 255 L 20 255
                L 5 240 L 5 20
            " stroke="#6586ec">
              </path><path fill="transparent" stroke-width="3" stroke-linecap="round" stroke-dasharray="10, 5" d="M 16 9 L 61 9" stroke="#6586ec"></path><path fill="transparent" d="M 5 20 L 5 10 L 12 3  L 60 3 L 68 10" stroke="#2cf7fe"></path><path fill="transparent" d="M 535 230 L 535 255 L 510 255" stroke="#2cf7fe"></path>
              </svg>

              <div className="item_title">
                  <div className="left"></div>
                  <p>当前回转</p>
                  <div className="right"></div>
              </div>

              <div className="DashboardChart" id="DashboardChart">

              </div>
            </div>

            
            <div className="AverageTorque">
            <svg width="540" height="350" class="dv-border-svg-container"><path fill="transparent" d="
              M 5 20 L 5 10 L 12 3  L 60 3 L 68 10
              L 520 10 L 535 25
              L 535 345 L 20 345
              L 5 330 L 5 20
            " stroke="#6586ec"></path><path fill="transparent" stroke-width="3" stroke-linecap="round" stroke-dasharray="10, 5" d="M 16 9 L 61 9" stroke="#6586ec"></path><path fill="transparent" d="M 5 20 L 5 10 L 12 3  L 60 3 L 68 10" stroke="#2cf7fe"></path><path fill="transparent" d="M 535 320 L 535 345 L 510 345" stroke="#2cf7fe"></path></svg>
              <div className="item_title">
                  <div className="left"></div>
                  <p>吊钩可视化</p>
                  <div className="right"></div>
              </div>

              <div className="video">
              <img src="http://screen.xiwon588.com/static/img/player.39c7c1e4.png" alt="" />

              </div>
            </div>

        </div>

        </div>
            
  {/* <svg>
  <path fill="transparent"
   d="
      M 5 20 L 5 10 L 12 3  L 60 3 L 68 10
      L 520 10 L 535 25
      L 535 255 L 20 255
      L 5 240 L 5 20
  " stroke="#6586ec">
    </path><path fill="transparent" stroke-width="3" stroke-linecap="round" stroke-dasharray="10, 5" d="M 16 9 L 61 9" stroke="#6586ec"></path><path fill="transparent" d="M 5 20 L 5 10 L 12 3  L 60 3 L 68 10" stroke="#2cf7fe"></path><path fill="transparent" d="M 535 230 L 535 255 L 510 255" stroke="#2cf7fe"></path>
    </svg> */}



                     
        </div>
    </div >
  )
}

export default withRouter(VideoTurning);