import React from 'react'


import { Select,  Form} from "antd";

import './company.less'

const {Option} =  Select;


const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

export const Company =   (
    class AsyncComponent extends React.Component {
        state = {
            Component: null,
            enterpriseAll: [],
            teamAll: [],
            workAll: [],
            teamAllId: [],
            workAllId: [],
            uiEnterpriseId: [],
            uiTeamId: [],
            uiWorkId: [],
            allParams: {
                uiEnterpriseId: 0,
                uiTeamId: 0,
                uiWorkId: 0,
            },
            
        }

        static defaultProps = {
            fromStatus: true, // 表达提交 默认不使用
            setStyle: {}, // 设置样式
            Status:true, //多选    默认使用
            width: 200,
            defaultStatus:false, // 开启默认选中第一个
            allParams: {
                uiEnterpriseId: '',
                uiTeamId: '',
                uiWorkId: '',
            }
        }

        FormSbmitRef = ''

       

        componentWillMount() {
  
            React.$axios.post('enterpriseAll').then(res => {
                this.state.allParams.uiEnterpriseId = res.data.enterpriseAll[0].id
                this.setState({
                    enterpriseAll: res.data.enterpriseAll,
                })


                //默认参数
                if(this.props.defaultStatus) {
                    this.defaultData(this.props.allParams)
                }

            })

        }

        // 获取部门
        getTeamAll() {
            return new Promise(resolve => {
                  
                React.$axios.post('teamAll', {id: this.state.teamAllId}).then(res => {
                    this.setState({
                     teamAll: res.data.teamAll
                    })
                    resolve(res.data.teamAll)
                 })
            })
            
        }

        // 获取工种
        getworkAll() {
            return new Promise(resolve => {
                React.$axios.post('workAll', {id: this.state.workAllId}).then(res => {
                    this.setState({
                        workAll: res.data.workAll
                    })

                    resolve(res.data.workAll)
                })
            })
            
        }
        // 切换公司
        onChange(e, item2) {
            console.log(item2)
            return new Promise((resolve) => {
                let list = []
                if(!this.props.Status) {
                    item2 = [item2]
                }

                try {
                    item2.map(item => {
                    
                        let split =item.item.teamId.split(',') 
    
                        list = [...list, ...split]
                    })
                } catch(e) {
                    
                }       
                

                this.state.allParams.uiEnterpriseId = e
                this.state.teamAllId = Array.from(new Set(list))
                this.setState({
                    teamAllId: this.state.teamAllId,
                    workAll: [],
                    workAllId: [],
                    uiWorkId: [],
                    uiTeamId: [],
                    uiEnterpriseId: e,
                    allParams: this.state.allParams
                })

                this.getTeamAll().then(res => {
                    resolve(res)
                })
            })
            
        }

        onChange2(e, item2) {
            
            let list = []
            if(!this.props.Status) {
                item2 = [item2]
            }

            item2.map(item => {
                let split =item.item.workId.split(',') 

                list = [...list, ...split]
           })

           this.state.allParams.uiTeamId = e
           this.state.workAllId = Array.from(new Set(list))
           this.setState({
            workAllId: this.state.workAllId,
            uiTeamId: e,
            allParams: this.state.allParams
           })


           this.getworkAll()
        }

        onChange3(e) {
            this.state.allParams.uiWorkId = e
            this.setState({
                uiWorkId: e,
                allParams: this.state.allParams
            })
        }


        // 默认请求
        defaultData(e) {
            
            if(!e) { // 没有默认就清空
                this.setState({
                    uiEnterpriseId: [],
                    uiTeamId:[],
                    uiWorkId: []
                })

            } else {
                this.state.uiEnterpriseId = this.props.allParams.uiEnterpriseId
                this.state.uiTeamId = this.props.allParams.uiTeamId
                this.state.uiWorkId = this.props.allParams.uiWorkId


                this.onChange(this.props.allParams.uiEnterpriseId,{item: e.infoEnterprise})

                this.onChange2(this.props.allParams.uiTeamId, {item: e.infoTeam})
                this.onChange3(this.props.allParams.uiWorkId)
            }
        }

        submit() {
            this.FormSbmitRef.submit()
        }


        setDom() {
            if(this.props.fromStatus) {
                return (
                    <span className="set" style={this.props.setStyle}>

                <div>    
                <Select
                    showSearch
                    mode={this.props.Status?'multiple': false}
                    placeholder="请选择公司"
                    className="select"
                    value={this.state.uiEnterpriseId}
                    optionFilterProp="children"
                    onChange={this.onChange.bind(this)}
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >

                {
                   this.state.enterpriseAll.map((item, index) => {
                    return( <Option item={item} value={item.id} key={index}>{item.name}</Option>)
                   })
                }    

                </Select>
                </div>

                <div className="right">
                <Select
                    showSearch
                    mode={this.props.Status?'multiple': false}
                    
                    placeholder="请选择部门"
                    className="select"
                    value={this.state.uiTeamId}
                    optionFilterProp="children"
                    onChange={this.onChange2.bind(this)}
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    >
                    {
                        this.state.teamAll.map((item, index) => {
                            return (
                                <Option item={item} value={item.id} key={index}>{item.name}</Option>
                            )
                        })
                    }

                </Select>
                </div>

                <div className="right">    
                <Select
                    showSearch
                    mode={this.props.Status?'multiple': false}
                    
                    placeholder="请选择岗位"
                    optionFilterProp="children"
                    className="select"
                    value = {this.state.uiWorkId}
                    onChange={this.onChange3.bind(this)}
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    >
                     {
                        this.state.workAll.map((item, index) => {
                            return (
                                <Option item={item} value={item.id} key={index}>{item.name}</Option>
                            )
                        })
                    }   

                </Select>
                </div>
                </span>   
                )
            } else {

                return (
                    <Form
                            layout="vertical"
                            validateMessages={validateMessages}
                            ref={(el) => this.FormSbmitRef = el} 
                        >
                    <div style={this.props.setStyle}>
                        

                     <Form.Item label="所属公司" required rules={[{ required: true, message: '请选择公司'}]} >
                        <Select 
                            showSearch
                            mode={this.props.Status?'multiple': false}
                            style={{ width: this.props.width, marginRight:20, marginBottom:10}}
                            placeholder="请选择公司"
                            className="select"
                            defaultValue={this.state.uiEnterpriseId}
                            value={this.state.uiEnterpriseId}
                            optionFilterProp="children"
                            onChange={this.onChange.bind(this)}
                        >
                            

                        {
                        this.state.enterpriseAll.map((item, index) => {
                            return( <Option item={item} value={item.id} key={index}>{item.name}</Option>)
                        })
                        }    

                        </Select>
                </Form.Item>

                <Form.Item label="所属部门" required rules={[{ required: true, message: '请选择部门'}]}>         
                <Select
                    showSearch
                    mode={this.props.Status?'multiple': false}
                    style={{ width: this.props.width, marginRight:20, marginBottom:10}}
                    placeholder="请选择部门"
                    className="select"
                    
                    value={this.state.uiTeamId}
                    defaultValue={this.state.uiTeamId}
                    optionFilterProp="children"
                    onChange={this.onChange2.bind(this)}

                    >
                    {
                        this.state.teamAll.map((item, index) => {
                            return (
                                <Option item={item} value={item.id} key={index}>{item.name}</Option>
                            )
                        })
                    }
                </Select>
                </Form.Item>

                <Form.Item label="所属职务" required rules={[{ required: true, message: '请选择职务'}]}>      
                <Select
                    showSearch
                    mode={this.props.Status?'multiple': false}
                    style={{ width: this.props.width, marginRight:20, marginBottom:10 }}
                    placeholder="请选择岗位"
                    value = {this.state.uiWorkId}
                    defaultValue={this.state.uiWorkId}
                    optionFilterProp="children"
                    className="select"
                    onChange={this.onChange3.bind(this)}
                    >
                     {
                        this.state.workAll.map((item, index) => {
                            return (
                                <Option item={item} value={item.id} key={index}>{item.name}</Option>
                            )
                        })
                    }   
                </Select>
                </Form.Item>
                </div>
                </Form>
                )
            }


        }   

        render() {
            return <span className="companyWarp">

                    {this.setDom()}

                </span>

        }
    }
);