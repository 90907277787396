import React, { useState, useEffect } from "react";
import { Image, Button, Carousel } from 'antd';
import {withRouter } from "react-router-dom";


import './MuckMonitoring.less';

import { formatTime } from '@/utils/util.js';

import img1 from '@/assets/img/car/baoan (4).png';
import img2 from '@/assets/img/car/baoan (7).png'; // 向上
import img3 from '@/assets/img/car/baoan (5).png'; // 车
import img4 from '@/assets/img/car/baoan (1).png'; // 时间
import img5 from '@/assets/img/car/baoan (6).png'; // 卸下


const imgUrl = process.env.REACT_APP_IMG_URL;
function MuckMonitoring() {
  const [statisticsVehicleLineLog, setStatisticsVehicleLineLog] = useState({abnormalLog: {}, line: []});
  const [listVehicleLineLog, setListVehicleLineLog] = useState([]);
  const [status, setStatus] = useState('');
  const [LineGroup, setLineGroup] = useState([]);

  const [params, setParams] = useState({
    offsetLimit: 0,
    lengthLimit: 10,
    logic: 3,
    status: ''
  })

  useEffect(() => {
    getStatistics();
    getListVehicleLineLog();
  }, [])
  
  const getStatistics = () => {
    const projectid = React.$getStorage('projectInfo').id
    React.$axios.post('statisticsVehicleLineLog', {projectId: projectid}).then(res => {
        if(res.data) {
            setStatisticsVehicleLineLog(res.data);
            let line = res.data.line;
            let lineGroup = []
            for(let i = 0; i < line.length; i+=2) {
                let itemArr = [];
                let line1 = line[i];
                
                
                line1.num = line1.finishCount + line1.suspectedCount;
                itemArr.push(line1)

                let line2 = line[i + 1];
                if(line2) {
                    line2.num = line2.finishCount + line2.suspectedCount;
                    itemArr.push(line2)
                }
                

                    

              
               
                lineGroup.push(itemArr);
            }
            setLineGroup(lineGroup)

        }
        
    })
  }

 const getListVehicleLineLog = (type) => {
     if(type) {
        params.offsetLimit = 0;
     }

    // listVehicleLineLog

    React.$axios.post('listVehicleLineLog', params).then(res => {
        // console.log(res)
        setListVehicleLineLog(res.data.list);
    })
 }

const clickStatus = (index) => {
    setStatus(index)
    params.status = index;
    setParams(params)
    getListVehicleLineLog(true)
}

  return (

   <div className="MuckMonitoring">

        <div className="head">
            <ul>
                <li>
                    <h1>排查统计</h1>
                    <div className="number">
                        <div>
                            <h2>{statisticsVehicleLineLog.count}</h2>
                            <p>累计发牌</p> 
                        </div>

                        <div>
                            <h2 className="blue">{statisticsVehicleLineLog.todayCount}</h2>
                            <p>今日发牌</p> 
                        </div>

                        <div>
                            <h2 className="red">{statisticsVehicleLineLog.suspectedCount}</h2>
                            <p>疑似排查</p> 
                        </div>
                            
                    </div>

                </li>


                <li>
                     <h1>需要关注的对象</h1>   
                    
                    <h2>{statisticsVehicleLineLog.abnormalLog.carId}</h2>
                    <p>{statisticsVehicleLineLog.abnormalLog.nameEnterprise}</p>
                    <p>{statisticsVehicleLineLog.abnormalLog.nameTeam}</p>

                    <h3>未准确弃渣次数{statisticsVehicleLineLog.abnormalLog.count}项</h3>
                </li>


                <li className="path">

                {/* LineGroup */}
                <Carousel autoplay>
                   
                    {
                        LineGroup.map((item2, index2) => {
                            return (
                                <div> 
                                <div className="warp" key={index2 + 'warp'}>
                                    {
                                        item2.map((item, index) => {
                                            return (
                                                
                                                    <div className="item"  key={index}>
                                                        <h2>{item.name}</h2>

                                                        <div className="pos">
                                                            <p>{item.nameSlag}</p>
                                                            <img src={img1} alt="" />
                                                            <p>{item.nameUnload}</p>
                                                        </div>


                                                        <ul className="process">
                                                            <li><span>装卸完成</span>  <div className="p1"><p style={{width:item.num == 0?0: (item.finishCount / item.num) * 100 + '%'}}></p><i>{item.finishCount}</i></div></li>
                                                            <li><span>疑似排查</span>  <div className="p1"><p style={{width:item.num == 0?0: (item.suspectedCount / item.num) * 100 + '%'}}></p><i style={{color: '#FFAB34'}}>{item.suspectedCount}</i></div></li>
                                                        </ul> 
                                                    </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            )
                        })
                    }
                  

                    
                </Carousel>

               
                    

                </li>


            </ul>
        </div>


        <div className="select">
        {/* type='primary' */}
           <Button  type={status == ''?'primary': ''} onClick={() => {clickStatus('')}  }>全部</Button>
           <Button  type={status == 1?'primary': ''} onClick={() => {clickStatus(1)} }>准确弃渣</Button>
           <Button  type={status == 2?'primary': ''} onClick={() => {clickStatus(2)} }>疑似排查</Button>
        </div>

         {
             listVehicleLineLog.map((item, index) => {
                 return (
                    <div className="listPage" key={index}>
                    <ul>
                        <li className={item.status == 1? 'addGreen': ''}>
                            <div className="icon">
                                <div className="site">
                                    <div className="img">
                                        <p> 装渣</p>
                                        <img src={img2} alt="" />
                                    </div>
                                </div>
                                <div className="point" style={{opacity: item.status == 0?'1':'0'}}>
                                    <div className="img">
                                        <img src={img3} alt="" />
        
                                        <p>{item.carId}</p>   
                                        <p>运输中</p>
                                    </div>
                                    </div>
                                <div className="site">
                                    <div className="img" style={{backgroundColor: item.aiTime != 0?'':'#595959'}}>
                                        <img src={img4} alt="" />
                                    </div>
                                </div>
                                <div className="point" style={{opacity: item.status == 3?'1':'0'}}>
                                    <div className="img">
                                        <img src={img3} alt="" />
        
                                        <p>{item.carId}</p>   
                                        <p>运输中</p>
                                    </div>
                                    </div>
                                <div className="site">
                                    <div className="img" style={{backgroundColor: item.unloadTime != 0?'':'#595959'}}>
                                        <p> 卸渣</p>
                                        <img src={img5} alt="" />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
        
        
                    <div className="content">
                        <div>
                            <h1>{item.nameSlag}</h1>
                            <p>{formatTime(new Date(item.slagTime * 1000), 'yyyy年MM月dd日 hh:mm:ss')}</p>
                            {/* <div className="cube" style={{display: item.slagImage?}}>
                                未打卡
                            </div> */}
                            <div className="cubeImg">
                            <Image
                                width={160}
                                height={80}
                                src={imgUrl + item.slagImage}
                                />
                            </div>
                        </div>
                        <div className="center">
                            <h1>{item.nameAi}</h1>
                            <p>{item.aiTime?formatTime(new Date(item.aiTime * 1000), 'yyyy年MM月dd日 hh:mm:ss'): '未打卡'}</p>
                            <div className="cube" style={{display: item.aiImage?'none': ''}}>
                                未打卡
                            </div>
                            <div className="cubeImg" style={{display: item.aiImage?'': 'none'}}>
                            <Image
                                width={160}
                                height={80}
                                src={imgUrl + item.aiImage}
                                />
                            </div>

                        </div>
                        <div className="right">
                            <h1>{item.nameUnload}</h1>
                            <p>{item.unloadTime?formatTime(new Date(item.unloadTime * 1000), 'yyyy年MM月dd日 hh:mm:ss'): '未打卡'}</p>
                            <div className="cube" style={{display: item.unloadImage?'none': ''}}>
                                未打卡
                            </div>
                            <div className="cubeImg" style={{display: item.unloadImage?'': 'none'}}>
                            <Image
                                width={160}
                                height={80}
                                src={imgUrl + item.unloadImage}
                                />
                            </div>
                        </div>
                    </div>
        
        
        
                </div>
                 )
             })
         }           


        
        
   </div> 

    
  )
}

export default withRouter(MuckMonitoring);