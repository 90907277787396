import React from "react";


import { DatePicker,Input,Select,Button, Table,  Image, message, Modal} from "antd";


import { SearchOutlined} from '@ant-design/icons';

import './ServicePoints.less';

import {formatTime} from '@/utils/util'

const {Option} =  Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


let imgUrl = process.env.REACT_APP_IMG_URL


export default class BehaviorManagement extends React.Component {
    columns = [
      {
        title: '序号',
        dataIndex: 'nameCard',
        align: 'center',
        render: (_,item, index) =>{
          return (
            <p>{index+1}</p>
          )
        }
      },
      {
        title: '姓名',
        dataIndex: 'nameIdCard',
        align: 'center',
      },
      {
        title: '公司',
        dataIndex: 'nameEnterprise',
        align: 'center',
      },
      {
        title: '部门',
        dataIndex: 'nameTeam',
        align: 'center',
      },
      {
        title: '分数',
        dataIndex: 'fraction',
        align: 'center',
      },
      {
        title: '状态',
        align: 'center',
        dataIndex: 'status',
        render:(_) => {
          return _ ==2?'待评分':'完成'
        }
      },
        {
            title: '评选任务时间',
            dataIndex: 'time',
            align: 'center',
            render: (_) => {
                return (
                    <p>{formatTime(new Date(_ * 1000), 'yyyy-MM')}</p>
                )
            }
        },
        // {
        //   title: '评委',
        //   dataIndex: 'nameCard',
        //   align: 'center'
        // },
        {
          title: '创建任务时间',
          dataIndex: 'editTime',
          align: 'center',
          render: (_) => {
              return (
                  <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}</p>
              )
          }
      },
        
        // {
        //     title: '操作',
        //     dataIndex: 'remark',
        //     align: 'center',
        //     render:(_,item) => {
        //         return (
        //             <p onClick={this.showModule.bind(this, item)} style={{color: '#6A9BF4', cursor:'pointer'}}>查看评选任务</p>
        //         )
        //     }
        // },
      ]

      columnsRebar = [
        {
            title: '序号',
            dataIndex: 'batchNo',
            align: 'center',
            render: (_, item, index) => {
                return (
                    <p>{index+1}</p>
                )
            }
          },
          {
            title: '姓名',
            dataIndex: 'nameCard',
            align: 'center'
          },
          
          {
            title: '岗位',
            dataIndex: 'nameWork',
            align: 'center',
          },

          {
            title: '分数',
            dataIndex: 'fraction',
            align: 'center',
          },

          {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            render: (_) => {
              return (
                <p>{_ == 2? '待平分': '完成'}</p>
              )
            }
          },
          // {
          //     title: '图片',
          //     dataIndex: 'img',
          //     align: 'center',
          //     render: (_) => {
          //         return (
          //           <Image
          //           width={50}
          //           src={imgUrl + _}
          //           /> 
          //         )
          //     }
          // }
      ]

  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    TableParams: {
        keyword: "",
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 2,
        projectId: null,
        status: '',
    },

    paginationRebar: {
        current: 1,
        pageSize: 10,
        total: 0,
    },

    tableDataRebar: [],


    isModalVisible: false,

    teamMany: [], // 当前队伍的数量
    teamManyValue: 0,
    status: true
  }




  componentDidMount() {
    const projectId = React.$getStorage('projectInfo').id

    this.state.TableParams.projectId = projectId;


    this.getTableData()
  }

  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

      React.$axios.post('listEvaluationTaskUser', this.state.TableParams).then(res => {
        this.state.pagination.total = res.data.countList

 
        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    this.state.TableParams.base = value
  }

  onChangeWork(name, value) {
    this.state.TableParams.type = value
  }

  toSerch2() {
    this.getTableData(true)
  }


  showModule(item) {

      this.state.paginationRebar.current = 1
      this.setState({
        isModalVisible: true,
        paginationRebar:this.state.paginationRebar,
        teamMany: item.teamMany,
        tableDataRebar: item.teamMany[0].userMany,
        teamManyValue: 0
      })
  }

  handleOk() {
    this.setState({
        isModalVisible: false
    })
  }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  getChangeTime(val) {
    this.state.TableParams.timeStart =  parseInt((+val[0]._d) / 1000) 
    this.state.TableParams.timeStop = parseInt((+val[1]._d) / 1000)
  }


  handleTableChange(value) {
    this.state.pagination = value
    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }


  onChangeT(val, value) {

    this.state.TableParams.status = value;

    this.setState({
      TableParams: this.state.TableParams
    })


  }

  toSerch3() {
    this.setState({
      tableDataRebar: this.state.teamMany[this.state.teamManyValue].userMany
    })
  }

  changeStatus(val) {
    this.setState({
      status: val
    })
  }

  render() {
    return (
      <div className="ServicePoints2">
          <div className="backg">

          <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>关键字:</span> 
                        <Input onChange={this.serchCar.bind(this, 'keyword')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                        
                        </li>
                        {/* <li><span>时间:</span>  
                        <RangePicker onChange={this.getChangeTime.bind(this)} style={{  height:30, marginRight:20,  }} />  
                        </li> */}

                        <li><span>状态:</span> 
                          {/* <Input onChange={this.serchCar.bind(this, 'keyword')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" /> */}

                          <Select
                                showSearch
                                style={{ width: 200, height:30, marginRight:20,  }}
                                placeholder="请选择角色"
                                optionFilterProp="children"
                                onChange={this.onChangeT.bind(this, 'statusWage')}
                            >
                            <Option value=" ">全部</Option>
                            <Option value="2">待评分</Option>
                            <Option value="3">完成</Option>
                          </Select>

                        </li>    




                          <Button  className="btn" onClick={this.toSerch2.bind(this)}>
                              <SearchOutlined  className="icon"/>
                          </Button>
                    </ul>
                </div>



                <div className="table2">
                <div class="allBtn">
                
                    </div>
                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    expandable={{ expandedRowRender: this.expandedRowRender }}
                    loading={this.state.loading} rowKey={record => record.id} columns={this.columns} dataSource={this.state.tableData}  />
                </div>


                </div>



                                                                                               
                <Modal title="评选任务列表"  width="80%" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)}  onCancel={this.handleCancel.bind(this)}>
                    <div className='ServicePointsModal'>
                      <div className="input">
                      <ul>
                              <li><span>队伍:</span> 
                              <Select
                                  showSearch
                                  style={{ width: 200, height:30, marginRight:20,  }}
                                  placeholder="请选择队伍"
                                  optionFilterProp="children"
                                  value={this.state.teamManyValue}
                                  onChange={this.onChangeT.bind(this)}
                              >
                                {/* <Option></Option> */}
                                {
                                  this.state.teamMany.map((item, index) => {
                                    return (
                                      <Option value={index}>{item.nameTeam}</Option>
                                    )
                                  })
                                }
                              </Select>
                              </li>

    

                                <Button  className="btn" onClick={this.toSerch3.bind(this)}>
                                    <SearchOutlined  className="icon"/>
                                </Button>
                          </ul>
                      </div>
                    </div>
                

                    {/* pagination={this.state.paginationRebar}            */}
                    <Table  align="center" bordered rowClassName="editable-rowRebar" 
                     rowKey={record => record.id}  columns={this.columnsRebar} dataSource={this.state.tableDataRebar}/>
                </Modal>


      </div>
    );
  }
}
