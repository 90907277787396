import React, { useState, useEffect } from "react";
// import { Layout, Carousel } from 'antd';
import { withRouter } from "react-router-dom";
import { Table,  message } from 'antd';

import './overview.less';

import img from '@/assets/img/newReviseImg/imgAndIcon/ai.jpg';

import {main1, main2, main3, main4} from './echarts';


function HomeRouter() {
    let exportStatus = false;
 
 const accessLogin =   React.$getStorage('accessLogin')
 const url = process.env.REACT_APP_BASE_URL;

 const [tableList, setTableList] = useState([]);

 const projectId = React.$getStorage('projectInfo').id


 const [cumulativeAcceptance, setCumulativeAcceptance] = useState({
    actualWeight: 0,
    theoreticalWeight: 0,
    deviation: 0
 })

 const [metailList, setMetailList] = useState([]);
 const [metailCont, setMetailCont] = useState(0);
 const [currentMonth, setCurrentMonth] = useState('');
 const [actualWeight, setActualWeight] = useState('');

  useEffect(() => {
    getList();
    getRebarStatistics();
  }, []);

  const getRebarStatistics = () => {
    React.$axios.post('rebarStatistics', {projectId: projectId}).then(res => {
        try {
            res.data.cumulativeAcceptance.actualWeight = Math.floor(res.data.cumulativeAcceptance.actualWeight * 100) / 100;
            res.data.cumulativeAcceptance.theoreticalWeight = Math.floor(res.data.cumulativeAcceptance.theoreticalWeight * 100) / 100;
        } catch(e) {

        }

        
        for(let i in res.data.monthly) {
            let item =  res.data.monthly[i];
            
            item.theoreticalWeight = Math.floor(item.theoreticalWeight * 1000) / 1000;
            item.actualWeight = Math.floor(item.actualWeight * 1000) / 1000;
        }

        for(let i in res.data.thisMonth.sum) {
            let item = res.data.thisMonth.sum[i];
            item = Math.floor(item * 1000) / 1000;
        }

        

        setActualWeight(res.data.actualWeight)
        setCurrentMonth(res.data.thisMonth.yn)

        setCumulativeAcceptance(res.data.cumulativeAcceptance);
        let unm =Math.abs(res.data.cumulativeAcceptance.deviation);
        
        let cont = 0;
        res.data.enterprise.map(item => {
            item.actualWeight = Math.floor(item.actualWeight * 1000) / 1000;
            item.value = Math.floor(item.value * 1000) / 1000;
            cont += item.actualWeight;
        })

        main2(res.data.thisMonth.sum)
        setMetailCont(cont);

        setMetailList(res.data.enterprise)
        
        main1(unm)
         main3(res.data.monthly)
         main4(res.data.enterprise)

    })
  }


  // 获取验收记录列表
  const getList = () => {
    React.$axios.post('listRebar', {lengthLimit: 10, offsetLimit: 0, logic: 2}).then(res => {
        res.data.list.map(item => {
            item.theoreticalWeight = Math.floor(item.theoreticalWeight * 1000) / 1000;
            item.actualWeight = Math.floor(item.actualWeight * 1000) / 1000;
            item.deviation = Math.floor(item.deviation * 1000) / 1000;
        })
        setTableList(res.data.list)
    })
  }


  return (
    <div className="AiRebarOverview" id="AiRebarOverview">
          <div className="head">
            <ul>
                <li>
                    <h1>AI钢筋累计验收 <span>（t）</span> </h1>
                    
                    <div className="textList">
                        <div className="ulList">
                            <h2>理论重量</h2>
                            <p>{cumulativeAcceptance.actualWeight}</p>

                            <h2>实际重量</h2>
                            <p>{cumulativeAcceptance.theoreticalWeight}</p>
                        </div>

                        <div className="echarts" id="main1">

                        </div>

                        <div className="rate">
                            <h3>偏差情况</h3>
                            {/* ((cumulativeAcceptance.actualWeight - cumulativeAcceptance.theoreticalWeight) / cumulativeAcceptance.actualWeight) * 100 */}
                            <p>{cumulativeAcceptance.deviation}%</p>
                        </div>

                    </div>

                </li>
                <li>
                    <h1>本月验收  <span>（t）</span></h1>

                     <h6>{currentMonth}</h6>   

                    <div id="main2" className="echarts">

                    </div>

                </li>
                <li>
                    <img src={img} alt="" />
                </li>
            </ul>
            </div>  


             <div className="warp">       
                <div className="left">

                    <div className="chart">
                        <h1>月度验收情况 （t）</h1>
                        <div id="main3" className="echarts"></div>
                    </div>


                    <div className="TableList">
                        <h1>验收记录<span>（t）</span></h1>


                        <div className="table">
                            <ul>
                                <li className="title">
                                    <p>时间</p>
                                    <p>姓名</p>
                                    <p>批次号码</p>
                                    <p>生产厂家</p>   
                                    <p>偏差情况(%)</p>
                                    <p>理论重量(t)</p>   
                                    <p>实际重量(t)</p>    
                                </li>

                                <div className="scroll">
                                    <div className="move">
                                    {
                                        tableList.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <p>{item.time}</p>
                                                    <p>{item.nameCard}</p>
                                                    <p>{item.prodNo}</p>
                                                    <p>{item.enterprise}</p>   
                                                    <p>{item.deviation}</p>
                                                    <p>{item.theoreticalWeight}</p>   
                                                    <p>{item.actualWeight}</p>  
                                                </li>
                                            )
                                        })
                                    }
                                    </div>


                                    {/* <li>
                                        <p>2021-04-15</p>
                                        <p>吴福成</p>
                                        <p>20210415154250458169</p>
                                        <p>测试厂家</p>   
                                        <p>11157.04(%)</p>
                                        <p>0.1</p>   
                                        <p>12</p>  
                                    </li> */}
                                </div>
                            </ul>
                        </div>


                    </div>
                    

                </div>

                <div className="right">
                    <h1>材料供应商情况</h1>
                    <p className="all">总吨数: <span>{actualWeight}</span></p>
                    <div className="list">
                        <ul>
                            {metailList.map((item,index) => {
                                return (
                                    <li key={index}>
                                        <p>{item.enterprise?item.enterprise:<span>1</span>}</p>
                                        <div className="process">
                                            <div className="gay"></div>
                                            <div className="red" style={{width: (item.actualWeight / metailCont) * 100 + '%'}}> <span>{item.actualWeight}t</span></div>
                                        </div>
                                    </li>
                                )
                            })}        


                            
                        </ul>
                    </div>


                    <div className="echarts" id="main4">

                    </div>


                </div>
            </div>

      
    </div>
  )
}

export default withRouter(HomeRouter);