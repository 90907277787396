import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Layout from "@/pages/Layout/layout"
import Login from "@/pages/Login/login"
import Earth from '@/pages/Earth/Earth'
import Test from '@/pages/Aother/test'

import VideoDetail from '@/pages/PanoramicMonitoring/VideoDetail/videoDetail'
import VideoDetailSpecial from '@/pages/PanoramicMonitoring/VideoDetailSpecial/videoDetailSpecial'
import VehiclePositioning from '@/pages/Earth/VehiclePositioning/VehiclePositioning'
// import VideoTurning from '@/pages/VideoTurning/VideoTurning'; // 视频轮训 
import PageIndex from '@/pages/VideoTurning/pageIndex/pageIndex';
import PageIndex2 from '@/pages/VideoTurning/pageIndex/pageIndex2';
import PartyBuild from '@/pages/VideoTurning/partyBuild/partyBuild'; //党建
// import TestPartyBuild from '@/pages/VideoTurning/partyBuild/testpartyBuild'; //党建
import HomeMixingPlant from '@/pages/MixingPlant/homeMixingPlant/homeMixingPlant'; // BI 数据看板

import HomeRouter from '@/pages/HomeRouter/HomeRouter'; // 首页
import BIMCenter from '@/pages/HomeRouter/BIMCenter/BIMCenter'; // BIM中心
import GIScenter from '@/pages/HomeRouter/GIScenter/GIScenter'; // GIS 中心
import MuckMonitoring from '@/pages/HomeRouter/MuckMonitoring/MuckMonitoring'; // 渣土监测


// 新开单页面
// import PageVideo from '@/pages/AotherPage/pageEarth/pageIndex'; 
// import PageVideo from '@/pages/AotherPage/pageEarth/pageIndex/pageIndex'; // 新的threejs 视频轮训

// 塔吊
import TowerPage from '@/SinglePage/tower/tower';
  
class PageRouter extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/MuckMonitoring" exact component={MuckMonitoring}></Route>
          <Route path="/GIScenter" exact component={GIScenter}></Route>
          <Route path="/BIMCenter" exact component={BIMCenter}></Route>
          <Route path="/HomeRouter" exact component={HomeRouter}></Route>
          <Route path="/HomeMixingPlant" exact component={HomeMixingPlant}></Route>
          {/* <Route path="/HomeMixingPlant" exact component={HomeMixingPlant}></Route> */}
          {/* <Route path="/TestPartyBuild" exact component={TestPartyBuild}></Route> */}
          <Route path="/PartyBuild" exact component={PartyBuild}></Route>
          <Route path="/PageIndex" exact component={PageIndex}></Route>
          <Route path="/PageIndex2" exact component={PageIndex2}></Route>

          <Route path="/TowerPage" exact component={TowerPage}></Route>
          {/* <Route path="/VideoTurning" exact component={VideoTurning}></Route> */}
          {/* <Route path="/PageVideo" exact component={PageVideo}></Route> */}
          <Route path="/Test" exact component={Test}></Route>
          <Route path="/login" exact component={Login}></Route>
          <Route path="/Earth" exact  component={Earth}></Route>
          <Route path="/videoDetail" exact  component={VideoDetail}></Route>
          <Route path="/videoDetailSpecial" exact component={VideoDetailSpecial}></Route>
          <Route path="/VehiclePositioning" exact component={VehiclePositioning}></Route>
          <Route path="/" render={() =>
            React.$getStorage('accessLogin') ? <Layout /> : <Redirect to="/login" />
          }></Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default PageRouter
