import React from "react";


import './BehaviorManagement.less';



import {Carousel,Input,Select,Button, Table,  Image,Tag, Modal, DatePicker} from "antd";

import {BehaviorType} from "./BehaviorType/BehaviorType";
import BehaviorRecord from './BehaviorRecord/BehaviorRecord';

import './BehaviorManagement.less';


export default class BehaviorManagement extends React.Component {
 


      
  state = {
    status: null,
    childBtn: []
  }




  componentDidMount() {
    let childBtn = React.$getStorage('menuchildBtn');
    for(let i = 0; i < childBtn.length; i++) {
      if(childBtn[i].show) {
        let item = childBtn[i];
        this.changeStatus(item.index);
        break;
      }
    }
  

    this.setState({
      childBtn: childBtn
    })


  }



  changeStatus(val) {
    this.setState({
      status: val
    })
  }



  render() {
    return (
      <div className="BehaviorManagement">

          {/* <div className="topAllBtn">
            <p className={this.state.thisMonthParams?"active":''}   onClick={this.changeActive.bind(this, true)}>
              {this.state.currentTime?formatTime(this.state.currentTime, 'yyyy年MM月'): '请选择'}
              <DatePicker  picker="month" className="setOpctiy" onOpenChange={this.onOpenChange.bind(this)} onChange={this.onChange.bind(this)}></DatePicker>  
            </p>
            <p className={this.state.thisMonthParams?"":'active'} onClick={this.changeActive.bind(this, false)}>全部</p>
          </div>   */}


        <div className="btn2">
            {
              this.state.childBtn.map((item => {
                if(item.show) {
                  return (
                    <Button  type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                  )
                }
              }))
            }
           {/* <Button  type={this.state.status==true? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, true)}>行为记录</Button>
           <Button className="setBtn" type={this.state.status==false? 'primary': ''}  onClick={this.changeStatus.bind(this, false)}>奖罚设置</Button> */}
          </div>


          {
            function() {
              if(!this.state.status) {
                return (
                  <BehaviorType/>
                )
                  

              } else {
                return (
                  <BehaviorRecord/>
                )
                
              }


            }.bind(this)()
          }

      </div>
    );
  }
}
