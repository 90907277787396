import React from 'react'

import {Button, Modal, Select,  Input, Form, message } from 'antd';

import './table.less';

// import fileImg from '@/assets/img/DrawingList/文件夹 (7).png';
import pdfImg from '@/assets/img/DrawingList/pdf (2).png';


const { Option } = Select;

export const MyTable = (
    class MyTable extends React.Component {
        FormSbmitRef = null
        state = {
            list: [],
            listDrawingList:[],
            isModalVisible: false,
            addItem: {
                pid: null,
                type: null,
                id: null,
                numbering:null,
                name: null,
                logic: 3,
            }
        }

        static defaultProps = {
            id: null,
            index: null,
            updataList: null
        }

        componentDidMount() {

            this.getList()
            
            // this.listDrawingList()
        }
        
        listDrawingList() {
            let projectId = React.$getStorage('projectInfo').id
            let params =  {
                projectId: projectId,
                logic: 3,
                pid: 0,
            }

            React.$axios.post('listDrawing', params).then(res => {
                this.setState({
                    listDrawingList: res.data.list
                })
            })


        }


        componentWillReceiveProps() {
            this.getList()
        }


        handleOkItem() {
            this.FormSbmitRef.submit()
        }


        handleCancel() {
            this.setState({
                isModalVisible: false
            })
        }


        getList() {

            this.setState({
                index: this.props.index 
            })

            let projectId = React.$getStorage('projectInfo').id
           
            let params = {
                projectId: projectId,
                pid: this.props.id,
                logic: 3
            } 


            React.$axios.post('listDrawing', params).then(res => {

                this.setState({
                    list: res.data.list
                })

            })


        }

        edit(item) {
            // console.log(item)
            for(let i in this.state.addItem) {
                this.state.addItem[i] = item[i]
            }
            this.state.addItem.logic = 3
            

            this.setState({
                isModalVisible: true,
                addItem:  this.state.addItem
            })

            this.clierFormSbmitRef()
        }

        onFinishItem(val) {
            for(let i in val) {
                this.state.addItem[i] = val[i]
            }


            React.$axios.post('saveDrawing', this.state.addItem).then(res => {
                if(res.code == 20000) {
                    message.success('保存成功')
                    this.getList()
                    this.handleCancel()
                    
                    // 如果切换了目录地址 更新首页
                    if(this.props.pid != this.state.addItem.pid) {
                        this.props.updataList()
                    }

                }

            })

        }


          // 清除默认数据
        clierFormSbmitRef() {
            if(!this.FormSbmitRef) {
                setTimeout(() => {
                    
                    this.FormSbmitRef.resetFields()
                }, 500)
            } else {
                this.FormSbmitRef.resetFields()
            }
        }

        remove(item) {
            let params = {
                pid: item.pid,
                id: item.id,
                del: 2,
                logic: 3
            }

            React.$axios.post('saveDrawing', params).then(res => {
                if(res.code == 20000) {
                    message.success('删除成功')
                    this.getList()
                }
            })
        }


        render() {
            return <div className="MyTable">

                <div className="setRight">
                    <table>
                        <tr>
                            <td className="td1">序号</td>
                            <td className="thtd2">图纸名称</td>
                            <td>图纸编号</td>
                            <td className="thTD4">图纸分类</td>
                            <td className="thtd5">操作</td>
                        </tr>


                        {
                            this.state.list.map((item,index) => {
                                return (
                                <tr className="setRound">
                                    <td className="td1 after firstTd"><p>{this.state.index + '.' + (index + 1)}</p></td>
                                    <td className="td2"><p><img src={pdfImg} alt="" />{item.name}</p> </td>
                                    <td>{item.numbering}</td>
                                    <td className="td4"><span className={'setType' + item.type}>{item.type == 2? '正式': item.type == 3? '变更': '送审'}</span> </td>
                                    <td className="thtd5"><i onClick={this.edit.bind(this, item)}>编辑</i> <span>|</span> <b onClick={this.remove.bind(this, item)}>删除</b> </td>
                                </tr>
                                )
                            })
                        }

                    </table>
                </div>




                <Modal title='图纸编辑' width="30%"  visible={this.state.isModalVisible} onOk={this.handleOkItem.bind(this)} onCancel={this.handleCancel.bind(this)}>
                    <Form
                            name="basic"
                            labelCol={{ span: 5 }}
                            ref={(el) => this.FormSbmitRef = el}
                            initialValues={this.state.addItem}
                            onFinish={this.onFinishItem.bind(this)}
                            >
                            <Form.Item
                                label="图纸名称"
                                name="name"
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="图纸编号"
                                name="numbering"
                            >
                                <Input />
                            </Form.Item>


                            <Form.Item
                                label="目录名称"
                                name="pid"
                            >
                                <Select
                                    optionFilterProp="children"
                                >
                                    {
                                        this.state.listDrawingList.map(item => {
                                            return (
                                                <Option value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="图纸分类"
                                name="type"
                            >
                                <Select
                                    optionFilterProp="children"
                                >
                                    <Option value={2}>正式</Option>
                                    <Option value={3}>变更</Option>
                                    <Option value={4}>送审</Option>
                                </Select>
                            </Form.Item>        



                            </Form>

                </Modal>    

                



            </div>
        }
    }
)
