import React from "react";


import { Input,Select,Button, Table, Tree, message, Popconfirm, Modal, Form } from "antd";
import { SearchOutlined, BorderLeftOutlined, PlusOutlined} from '@ant-design/icons';

import { Uploadhh } from "@/components/upload/upload";
import './engineering.less';

const {Option} =  Select;

const imgUrl = process.env.REACT_APP_IMG_URL;

export default class  PartyActive extends React.Component {
  FormSbmitRef = null;
    columns = [
      {
        title: "名称",
        dataIndex: 'name',
        align: 'center',
        // render: (_, item, index) => {
        //   return (
        //     <p>{index + 1}</p>
        //   )
        // }
      },

        {
            title: '操作',
            dataIndex: 'remark',
            align: 'center',
            render:(_,item) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Popconfirm
                            title="是否确认删除?"
                            onConfirm={this.toDel.bind(this, item.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                          <p style={{color: '#ff0000 ', cursor:'pointer', marginRight: 20}}>删除</p>
                        </Popconfirm>

                        

                        {/* <a target="_blank" href={imgUrl + item.word} style={{color: '#6A9BF4', cursor:'pointer'}}>打印预览</a> */}
                    </div>
                    
                    
                )
            }
        },
      ]


  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    TableParams: {
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 0,
        meStatus: '',
        pouringNo: '',
        name: '',
        taskStatus: '',
    },

    infoMsg: {
      signIn: [],
      singUp: []
    }, // 详情信息
    isModalVisible: false,
    allTreeData: [],
    addItem: {
      name: '',
      id: '',
      pid: '',
    }
  }




  componentDidMount() {
    this.getTableData()
    
  }

 

  treeData(item, arr) {
    arr.map(item2 => {
      if(item.id === item2.pid) {
        if(!item.children) {
          item.children = []
        }
        item.children.push(item2);
        this.treeData(item2, arr); 
      }
    })
  }

  

  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  
       let _this = this;
       let projectId =  React.$getStorage('projectInfo').id
      React.$axios.post('openPositions/listEngineering', {...this.state.TableParams, ...{projectId}}).then(res => {
        
        // console.log(res);
        this.state.pagination.total = res.data.length;
        res.data.map((item, index) => {
          // <i className="delbumen"><Popconfirm title="是否确认删除"  onConfirm={() => {this.toDetail.bind(this, item)}}>删除</Popconfirm></i>
            item.title = (<p className="toEditI">{item.name}  <div><i className="adbumen" onClick={this.toCreate.bind(this, item)}>新增</i> <i  className="editbumen" onClick={this.toEdit.bind(this, item)}>编辑</i> </div>  </p>);
            item.key = `${index}`;
        })

        let pid = res.data.filter(item => item.pid === 0)
        pid.map(item => {
          _this.treeData(item, res.data)
        })

       


        console.log(pid);

        this.setState({
            // tableData: res.data,
            pagination: this.state.pagination,
            loading: false,
            allTreeData: pid
        })
      })
  }


  serchCar(name, {target:{value}}) {
    console.log(name)
    this.state.TableParams.name = value
  }

  toSerch2() {
    this.getTableData(true)
  }



  toDetail(id) {
    console.log(1111);
    React.$axios.post('openPositions/saveEngineering', {id, logic: 3}).then(res => {
        if(res.code == 20000) {
            message.success('删除成功')
            this.getTableData();
        } else {
            message.error('删除失败')
        }
    })
  }

  handleOk() {
    this.FormSbmitRef.submit();
  }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

 
  handleTableChange(value) {
    this.state.pagination = value
    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }

  onFinishItem(val) {
    React.$axios.post('openPositions/saveEngineering', {...this.state.addItem, ...val}).then(res => {
      if(res.code === 20000) {
        message.success('保存成功')
        this.getTableData();
        this.handleCancel();
      }
    })
  }

  clierFormSbmitRef() {
    if(this.FormSbmitRef) {
      this.FormSbmitRef.resetFields()
    } else {
      setTimeout(() => {
        this.FormSbmitRef.resetFields()
      }, 500)
    }
  }

  toEdit(item) {
    this.setState({
      isModalVisible: true,
    })
    
    this.state.addItem.id = item.id;
    this.state.addItem.name = item.name;
    this.state.addItem.pid = item.pid;
    this.clierFormSbmitRef();
    this.setState({
      addItem: this.state.addItem
    })
  }

  handleClick(){
    this.getTableData();
  }

  toCreate(params) {
    this.setState({
      isModalVisible: true,
    })
    this.state.addItem.id = null
    this.state.addItem.name = null;
    if(!params) {
      this.state.addItem.pid = 0;
    } else {
      this.state.addItem.pid = params.id;
    }

    this.clierFormSbmitRef();


    this.setState({
      addItem: this.state.addItem
    })

    

  }


  onChangeT(name, value) {
    this.state.TableParams[name] = value;
    this.setState({
      TableParams: this.state.TableParams
    })
  }

  render() {
    return (
      <div className="engineering">
                <div className="table2" style={{marginTop: 10}}>
                <div class="allBtn">
                   

                    

                   
                    <Button style={{marginRight: 20}}   onClick={this.toCreate.bind(this, null)} type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button>

                    <Button type="primary"  style={{marginRight: 20}} >
                        导入
                        <div style={{position: 'absolute', right: 0, top: 0, width: '100%', opacity: 0}}>
                        <Uploadhh  path = "openPositions/importEngineering" handleClick={this.handleClick.bind(this)}  name="file" data={{}}  width={'100%'} height={'100%'} />
                        </div>
                    </Button>


                    <Button    type="primary" icon={<BorderLeftOutlined />}>
                            <a href="https://project.1wx.cn/static/excel/import/开仓证工程导入模板.xlsx" download>下载模板</a>
                      </Button>

                    
                    </div>

                </div>


                <div className="enterpriseList" id="EnterpriseList">
                    <div className="titleName">
                      <p className="p1" >名称</p> 


                      {/* <p className="p2" onClick={addEnterprise}>新建单位</p>  */}
                    </div>

                  <div className="allBody">
                    <Tree multiple  showLine={true}  treeData={this.state.allTreeData} />
                  </div>
            </div>


            <Modal
                    title={this.state.addItem.id?'编辑':'添加'}
                    visible={this.state.isModalVisible}
                    onOk={this.handleOk.bind(this)}
                    width="500px"
                    onCancel={this.handleCancel.bind(this)}
                    >
                    
                    <Form
                        name="名称"
                        labelCol={{ span: 3 }}
                        ref={(el) => this.FormSbmitRef = el}
                        initialValues={this.state.addItem}
                        onFinish={this.onFinishItem.bind(this)}
                        >
                            <Form.Item
                                label="名称"
                                name="name"
                                rules={[{ required: true, message: '请输入名称' }]}
                            >
                                <Input />
                            </Form.Item>

                    </Form> 


                </Modal>




                                                                                            
      </div>
    );
  }
}
