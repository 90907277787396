import React from "react";


import './overview.less';

import { Carousel, Image, Modal, Table} from 'antd';

import * as echarts from 'echarts';

import { LeftOutlined, RightOutlined} from '@ant-design/icons';

// import {im1} from '../../../assets/icons/1/驳回_reject.svg' // 实时现场

import {formatTime} from '@/utils/util'

import img1 from '@/assets/icons/1/安检_enquire.svg';
import img2 from '@/assets/img/newReviseImg/imgAndIcon/mjzj.png';
import img3 from '@/assets/icons/1/出发_set-off.svg';
import img4 from '@/assets/icons/1/人群_peoples.svg';
import img5 from '@/assets/icons/1/人身安全_people-safe.svg';
import img6 from '@/assets/img/newReviseImg/imgAndIcon/ren.png';
import img7 from '@/assets/img/newReviseImg/imgAndIcon/LOGO.png';
// import { color } from "echarts";
// import { parseAsync } from "@babel/core";


export default class Overview extends React.Component {
    projectId = '0'
    CarouselRef = ''
    imgUrl = process.env.REACT_APP_IMG_URL
    state = {
        areaList: [],
        allNewestManagementUserPCList: [], // 实时门禁
        setEducation: {},
        labolAttendance: {
            laborer: 0,
            manager: 0,
            userAll: 0
        },

        OnSitePeopleNumberList: [],
        teamNameList: [],
        labelNameList: {},
        allNum: {},
        personNum: 0,
        toDayNumber: 0, 
        isModalVisible: false,
        tableData: [],
        ModalName:'' 
    }


    columns = [
        {
          title: "序号",
          dataIndex: 'title',
          align: 'center',
          render: (_, item, index) => {
            return (
              <p>{index + 1}</p>
            )
          }
        },
          {
            title: '姓名',
            dataIndex: 'uicName',
            align: 'center',
          },
          {
            
            title: '手机号码',
            dataIndex: 'uPhone',
            align: 'center',
          },
          {
            
              title: '身份证号码',
              dataIndex: 'uicNumber',
              align: 'center',
          },
          {
              title: '公司',
              dataIndex: 'eName',
              align: 'center'
          },
          {
              title: '部门',
              dataIndex: 'tName',
              align: 'center'
          },
          {
              title: '岗位',
              dataIndex: 'towName',
              align: 'center'
          },
      
      
          {
              title: '进场时间',
              dataIndex: 'enterTime',
              align: 'center',
              render: (_) => {
                  let time = null
                  if(_) {
                      time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')
                  } else {
                      time = ' '
                  }
                  return (
                      <p>
                          {time}
                      </p>
                  )
              }
          }
        ]


  
    
    componentDidMount() {

        for(let i = 0; i < 20; i++) {
            this.state.areaList.push({})
        }

        this.setState({
            areaList: this.state.areaList
        })

        
        
        this.projectId = React.$getStorage('projectInfo').id

        this.getAllNewestManagementUserPC()

        this.getNewestUserAttendanceCurve()

        // 获取安全教育
        this.getSafeEducationUser()

        // 获取现场人数用户
        this.getOnSitePeopleNumber()

        // 最新一条施工单位今日出勤
        this.getNewOneUserTeamTodayAttendance()

        // this.getNewestEducationMonth()
        // setInterval(() => {
        //     this.getAllNewestManagementUserPC()   
        // }, 30000) 
    }





    // 最新一条施工单位今日出勤
    getNewOneUserTeamTodayAttendance() {
        React.$axios.post('newOneUserTeamTodayAttendance', { projectId:this.projectId}).then(res => {
            // 劳动单位
            let enterprise = res.data.info.enterprise
            
            if(!enterprise) {
                enterprise = {
                    manager: {},
                    laborer: {}
                }
            } 
            console.log(enterprise)
            // 重点人员
            let team = res.data.info.team
            
            let teamName = [], teamColor = ['#FE3739','#17F49B','#FFAB31','#4570FE','#E2FE43','#B040FE','#00BDFE','#00B050','#E2F0D9','#000000','#E317E5'];
            

            let index = 0
            let valueNum = 0
            let allValueNum = 0
            for(let i in team) {
                teamName.push({
                    name: i,
                    value: team[i].attendance,
                    allValue:team[i].user,
                    color: teamColor[index] == undefined ? '#' + Math.random().toString(16).substr(2, 6).toUpperCase() :  teamColor[index]
                })

                valueNum += team[i].attendance
                allValueNum += team[i].user
                index++;
            }

            let rate1 =  parseInt(valueNum / allValueNum * 100) 
            // this.state.allNum

            let slice = teamColor.splice(0,index)


            let labelName = [
                {
                    name: '劳动管理人员',
                    value: enterprise.manager.attendance,
                    allValue:  enterprise.manager.user
                },
                {
                    name: '劳务作业人员',
                    value: enterprise.laborer.attendance,
                    allValue: enterprise.laborer.user,
                }
            ]
            
            let num2 =  enterprise.manager.user + enterprise.laborer.user
            let rate2 = parseInt((enterprise.manager.attendance + enterprise.laborer.attendance) / (num2) * 100)
            

            this.setState({
                teamNameList: teamName,
                labelNameList: {
                    value1:  enterprise.manager.attendance,
                    value2 : enterprise.laborer.attendance,
                    allValue1: enterprise.manager.user,
                    allValue2: enterprise.laborer.user
                },
                allNum: {
                    rate1: rate1,
                    num1: allValueNum,
                    num2: num2,
                    rate2: rate2
                }
            })

            // 重点人员图形
            this.getEcharts3(teamName, slice)

            // 劳动单位出勤
             this.getEcharts4(labelName)

             // 今日出勤
             this.getEcharts2(teamName)
        })
    }


    // 获取安全教育
    getSafeEducationUser() {
        React.$axios.post('educationOverviewStatistics', { projectId:this.projectId, teamStatistics: 3}).then(res => {
            console.log(res)
            let params = {
                exam: res.data.userCount,
                training: res.data.not3EducatedUserCount
            }

            let num =  res.data.not3EducatedUserCount

            this.setState({
                setEducation: params
            })

            params.examRate = parseInt((params.exam / num) * 100) 
            params.trainingRate = parseInt((params.training / num) * 100) 
            
            this.getEcharts5(params)
        })



    }


    // 现场人数用户
    getOnSitePeopleNumber() {
        React.$axios.post('onSitePeopleNumber', { projectId:this.projectId}).then(res => {
            let data = res.data.site
            let arr = []
            
            let num = 0
            for(let i in data) {
                arr.push(data[i])
                num += data[i].count
            }
            
            this.setState({
                OnSitePeopleNumberList: arr, 
                personNum: num
            })
            
        })
    }


    getNewestUserAttendanceCurve = ()=> {

          React.$axios.post('newestUserAttendanceCurve', {projectId: this.projectId}).then(res => {
    
            let enterData = []
            let exitData = []
      
            let enterPatt = /manager\d+h/
            let exitPatt = /laborer\d+h/
      
            // res.data.
      
            if(!res.data.info) {
              return
            }
    
            for(let i in res.data.info) {
              let enterStatus = enterPatt.test(i)
              let exitStatus = exitPatt.test(i)
              if(enterStatus) {
                enterData.push(res.data.info[i])
              } else if(exitStatus) {
                exitData.push(res.data.info[i])
              } else {
      
              }
            }

            this.setState({toDayNumber: res.data.info.laborer + res.data.info.manager}) 
            
            this.getEcharts(enterData,exitData)
            // setechart1(enterData, exitData)
          })
      }


 

    // 获取实时现场
    // getNewestUserAttendanceCurve() {
    //     React.$axios.post('statisticsUserLaborCurvePC', {projectId: this.projectId}).then(res => {
    //         let data = res.data
    //         if(!data) {
    //             data = {}
    //         }
    //         let reg = /enter\d+/
    //         let reg2 = /exit\d+/
    //         let laborer = []
    //         let manager = []
    //         let indexName = []
    //         let index2 = 0
    //         for(let i in data) {
    //             let status = reg.test(i)
                
    //             if(status) {
    //                 indexName.push(index2)
    //                 laborer.push(data[i])
    //                 index2+=1;
    //             }
                
    //             if(reg2.test(i)) {
    //                 manager.push(data[i])
    //             }

    //         }

           
            
    //         // this.setState({
    //         //     labolAttendance: {
    //         //         laborer: data.laborer,
    //         //         manager: data.manager,
    //         //         userAll: data.laborer + data.manager + 30
    //         //     }
    //         // })

    //         this.getEcharts(manager,laborer, indexName)

    //         // this.getEcharts4(this.state.labolAttendance)

    //     })
    // }


    // 实时门禁
    getAllNewestManagementUserPC() {

        // React.$axios.post('allNewestManagementUserPC', {projectId: this.projectId}).then(res => {
        //     let data = res.data;
        //     let arr = [];
        //     for(let i in data) {
        //         arr.push(data[i].laborManagementUser)
        //     }

        //     // console.log(1111)
        //     this.setState({
        //         allNewestManagementUserPCList: arr
        //     })
            
        // })

        React.$axios.post('oneNewUfr', {projectId: this.projectId}).then(res => {
            if(!res.data.oneNewUfr) return; 
            let arr = [res.data.oneNewUfr]
            this.setState({
                allNewestManagementUserPCList: arr
            })

        })



    }
        

    getEcharts5(data) {

        // console.log(data)
        
        const dom = document.getElementById('echarts5')

        if(!dom) {
            return
        }

        let myChart = echarts.init(dom);


        let option = {
            color: ['#00B050','#E2F0D9'],
            tooltip: {
                trigger: 'item'
            },
            legend: {
                data: ['安全教育人数', '未安全教育',],
                type: 'scroll',
                orient: 'vertical',
                icon: "circle", 
                right: 0,
                top: 50,
                bottom: 20,
            },

            // legend: {
            //     top: '5%',
            //     left: 'center'
            // },
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '25',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {value: data.exam, name: '安全教育人数'},
                        {value: data.training, name: '未安全教育'},
                    ]
                }
            ]
        };
        myChart.setOption(option);
    }


    // 设置劳务单位出勤
    getEcharts4(data) {
        var myChart = echarts.init(document.getElementById('main4'));

        let option = {
            color: ['#0049CB' ,'#00BEFE'],
            tooltip: {
                trigger: 'item'
            },
            // legend: {
            //     top: '5%',
            //     left: 'center'
            // },
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '10',
                            // fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: data
                }
            ]
        };


        myChart.setOption(option);
    }


    //设置重点人员出清
    getEcharts3(data, colors) {
 
        var myChart = echarts.init(document.getElementById('main3'));
        let option = {
            color: colors,
            tooltip: {
                trigger: 'item'
            },
            // legend: {
            //     top: '5%',
            //     left: 'center'
            // },
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '10',
                            // fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: data
                }
            ]
        };
        
        
        myChart.setOption(option);

    }


    // 设置出勤图形
    getEcharts2(data) {
        let nameAll = []
        data.map(item => {
            nameAll.push(item.name)
        }) 

        var myChart = echarts.init(document.getElementById('main2'));

       let option = {
            color: ['#004EA2'],
            xAxis: {
                type: 'category',
                data: nameAll,
                axisLine: {
                    lineStyle: {
                      type: 'dashed',
                       color: '#7F7F7F',
                      width: '1'
                    }
                  },
                axisLabel: {  
                    interval:0,  
                    rotate:-60
                }, 
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle:{
                        type:'dashed'
                    }
                },
            },
            
            yAxis: {
                type: 'value'
            },
            series: [{
                data: data,
                type: 'bar',
                label: {
                    formatter: "{c}",
                    show: true,
                    position: 'top',
                    color: '#004EA2'
                }
            }]
        };
    
        myChart.setOption(option);
    }

    getEcharts(data1, data2, name) {
        var myChart = echarts.init(document.getElementById('main'));

        let xData = [1,2,3,4,5,6,7,8,9,10,11,12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
        const Hours = new Date().getHours();
        xData = xData.splice(0, Hours  + 1);
        let option = {
            
        color: ['#004EA1','#C71D4C' ],
        grid: {
            left: '2%',
            right: '2%',
            bottom: '0px',
            top: "30%",
            containLabel: true
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['bar','管理人员', '劳务人员'],
            right: '0%',
            icon: 'rect'
        },

        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: xData,
                show:true,
            }
        ],
        yAxis: [
            {
                type: 'value',
                // show:false,
                splitLine:{
                    　　　　show:false
                    　}
            }
        ],
        series: [
            {
                name: '管理人员',
                type: 'line',
                // stack: '总量',
                areaStyle: {},
                //   smooth: true,
                emphasis: {
                    focus: 'series'
                },
                showSymbol: false,
                areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: 'rgba(0,78,161, 0.8)'
                    }, {
                        offset: 1,
                        color: 'rgba(0,78,161, 0.2)'
                    }])
                    
                },

                data: data1
            },
            {
                name: '劳务人员',
                type: 'line',
                // stack: '总量',
                //   smooth: true,
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                showSymbol: false,
                areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: 'rgba(199,29,76, 0.8)'
                    }, {
                        offset: 1,
                        color: 'rgba(199,29,76, 0.2)'
                    }])
                },
                data: data2
            },
        ]
    };
        myChart.setOption(option);
    }


    // // 设置echart
    // getEcharts(data1, data2, name) {
    //     var myChart = echarts.init(document.getElementById('main'));

    //     console.log(name)

    //     let option = {
            
    //         color: ['#004EA2','#C71D4C' ],
    //         grid: {
    //             left: '3%',
    //             right: '4%',
    //             bottom: '3%',
    //             containLabel: true
    //         },
    //         tooltip: {
    //             trigger: 'axis'
    //           },
    //         legend: {
    //             data: ['进场人员', '退场人员',],
    //             right: '0%',
    //             icon: 'rect'
    //         },

    //         xAxis: {
    //             type: 'category',
    //             data: name,
    //             axisLine: {
    //                 lineStyle: {
    //                   type: 'dashed',
    //                    color: '#7F7F7F',
    //                   width: '1'
    //                 }
    //               }
    //         },
    //         yAxis: {
    //             type: 'value',
    //             splitLine: {
    //                 show: true,
    //                 lineStyle:{
    //                     type:'dashed'
    //                 }
    //             },
    //         },

    //         series: [
    //             {
    //                 name: '进场人员',
    //                 type: 'line',
    //                 // stack: '总量',
    //                 areaStyle: {},
    //                 // smooth: true,
    //                 emphasis: {
    //                     focus: 'series'
    //                 },
    //                 showSymbol: false,
    //                 areaStyle: {
    //                     opacity: 0.8,
    //                     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    //                         offset: 0,
    //                         color: 'rgba(0,78,161, 0.8)'
    //                     }, {
    //                         offset: 1,
    //                         color: 'rgba(0,78,161, 0.2)'
    //                     }])
                        
    //                 },

    //                 data: data2
    //             },
    //             {
    //                 name: '退场人员',
    //                 type: 'line',
    //                 // stack: '总量',
    //                 // smooth: true,
    //                 areaStyle: {},
    //                 emphasis: {
    //                     focus: 'series'
    //                 },
    //                 showSymbol: false,
    //                 areaStyle: {
    //                     opacity: 0.8,
    //                     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    //                         offset: 0,
    //                         color: 'rgba(199,29,76, 0.8)'
    //                     }, {
    //                         offset: 1,
    //                         color: 'rgba(199,29,76, 0.2)'
    //                     }])
    //                 },
    //                 data: data1 
    //             },
    //         ]
    //     };
    
    
    //     myChart.setOption(option);
    // }


    // 点击下一页
    nextIMG(status) {
        if(status) {
            this.CarouselRef.prev()
            
        } else{
            this.CarouselRef.next()
        }
    }

    OnSitePeopleClick(item) {
        let parmas = {
            logoDoorman: item.logo,
            status: 3,
            logic: 3
        }
    
        React.$axios.post('recognitionList', parmas).then(res => {
           this.setState({
               tableData: res.data,
               isModalVisible: true,
               ModalName: item.name
           })
       })
    }




    handleCancel() {
        this.setState({
            isModalVisible: false
        })
    }


  
  
    render() {
    return (
      <div className="overviewWarp"> 
          <div className="topWarp">
             <div className="leftData">
                <div className="context">
                    <h1><img className="posImg"  src={img1}  alt=""/> 人员出勤</h1>  

                                        
                    <div className="text">
                       
                        <div className="echarts">
                            <div className="number">
                                <p>今日出勤</p>
                                <span>{this.state.toDayNumber}</span> 人
                            </div> 

                            <div  id="main">

                            </div>
                        </div>  
                        

                        <div class="personList">
                              <div className="title">
                              <p class="h1">
                                  现场实时人数
                              </p>
                              <p className="current"><span>{this.state.personNum}</span> 人</p>
                              </div>  
                              
                              <div className="scroll">
                              <ul>
                                {
                                    this.state.OnSitePeopleNumberList.map((item, index) => {
                                        return (
                                            <li key={index} onClick={this.OnSitePeopleClick.bind(this, item)}>
                                                <img src={img6} alt="" />
                                                <p class="p1">{item.name}</p>
                                                <p class="p2"><span>{item.count}</span> <i>人</i> </p>
                                                {/* <p class="p3">滞留超12h <span>{item.stay12}</span>人</p>
                                                <p class="p4">滞留超24h <span>{item.stay24}</span>人</p> */}
                                                
                                            </li>
                                        )
                                    })
                                   
                                }
                                
                              </ul>  
                              </div>
                        </div>

                    </div>

                    
                </div>

             </div>

             

             <div className="rightData topRdata setBack">
                   <img className="baImg" src={img7} alt="" />
                   <h1 style={{fontWeight:'bold', color: '#fff'}}>
                       <img class="posImg" src={img2} alt=""/>

                       实时门禁
                       </h1>
                   <div className="handHover">
                        <div class="iconStyle">
                            <div><LeftOutlined className="icon" onClick={this.nextIMG.bind(this, true)} /></div>
                            <div><RightOutlined className="icon" onClick={this.nextIMG.bind(this, false)} /></div>
                        </div>
                        
                   </div>             
                    <Carousel autoplay dots={false} ref={(el) => this.CarouselRef = el}>            
                                
                     {
                         this.state.allNewestManagementUserPCList.map(item => {
                            return (
                                <div>             
                                    <div className="tText">
                                            <div className="myImg">
                                            <Image
                                                width ={160}
                                                src={this.imgUrl + item.uiAvatarKeyCutout}
                                            />

                                            <p>{item.uicName}</p>
                                                {/* <img src={item.enterImage} alt=""/> */}
                                            </div>        
                                            <ul className="myMsg">
                                                <li>姓名: {item.uicName}</li>
                                                <li>部门: {item.tName}</li>
                                                <li>单位: {item.eName}</li>
                                                <li>安全教育: 是</li>
                                                <li>劳务积分: {item.uiSafetyEducationScore}</li> 
                                                <li>进场时间: {formatTime(new Date(item.enterTime * 1000), 'yyyy年MM月dd日 hh:mm:ss')}</li>
                                                <li>出场时间: { function() {
                                                    if(item.exitTime) {
                                                        return <span>
                                                            {
                                                                formatTime(new Date(item.exitTime * 1000),'yyyy年MM月dd日 hh:mm:ss')
                                                            }
                                                        </span>
                                                    } else {
                                                        return (
                                                            <span> </span>
                                                        )
                                                    }
                                                }.bind(this)()}</li>
                                                <li>设备名称: {item.nameExit?item.nameExit:item.nameEnter}</li>     
                                            </ul>        
                                    </div> 
                                </div>
                             )
                         })
       
                    }   

                   </Carousel>             
             </div>

          </div>

          <div className="topWarp bottomWarp">
            <div class="leftData flex">
                 <div class="chuqing">
                    <h1>
                        <img className="posImg p2" src={img3} alt=""/>
                        今日出勤
                        </h1>
                    <div class="echart2" id="main2">
                    
                    </div>        
                 </div>               

                 <div class="zhongdian">
                      <h1>
                      <img className="posImg p2" src={img4} alt=""/>
                          重点人员出勤
                          </h1>

                        <ul class="zdUl">
                              <li class="firstLi">
                                <div class="firtT1">
                                    <p class="title">{this.state.allNum.rate1}%</p>
                                    <p>今日在岗率</p>
                                </div>

                                <div class="firtT2">
                                    <p class="title">{this.state.allNum.num1}</p>
                                    <p>人员总数</p>
                                </div>                                  
                              </li>  
                              <li>
                                <div class="echarts3" id="main3">

                                </div>
                             </li>

                        </ul> 

                        <ul class="personLiable">
                                {
                                    this.state.teamNameList.map(item => {
                                          return (
                                              <li><span className="setSpanColor" style={{backgroundColor: item.color}}></span> <span className="setWidth">{item.name}</span>  {item.value} / {item.allValue}</li>
                                          )  
                                    })
                                }


                        </ul>  


                        <h2 class="title2">劳务单位出勤</h2>      


                        <ul class="zdUl zdul2">
                            <li class="firstLi">
                                <div class="firtT1">
                                    <p class="title">{this.state.allNum.rate2}%</p>
                                    <p>今日在岗率</p>
                                </div>

                                <div class="firtT2">
                                    <p class="title">{this.state.allNum.num2}</p>
                                    <p>人员总数</p>
                                </div>                                  
                              </li>  
                              <li>
                                <div class="echarts3" id="main4">
                                
                                </div>
                             </li>
                              
                        </ul>

                        <ul class="personLiable personLiable2">
                        <li><span></span> 管理人员 {this.state.labelNameList.value1} / {this.state.labelNameList.allValue1}人</li>
                        <li><span className="span1"></span> 作业人员 {this.state.labelNameList.value2} / {this.state.labelNameList.allValue2}人</li>
                        </ul>         

                 </div>                   
            </div>

            <div class="rightData topRdata">
                <h1 style={{fontWeight:'bold', marginTop:-5}}>
                <img className="posImg" src={img5} alt=""/>安全教育</h1>
                <h3 className="setH3">三级安全教育</h3> 

                <div className="posRate">100%</div>                

                <div id="echarts5">
                    
                </div>
                <h3><span>{this.state.allNum.num1}</span>  / {this.state.setEducation.training} （人）</h3>              
            </div>

          </div>



          <Modal title={this.state.ModalName} width="80%" visible={this.state.isModalVisible} onOk={this.handleCancel.bind(this)}  onCancel={this.handleCancel.bind(this)}>

                 <div className="overviewModal">
                                 
                <Table  align="center" bordered  pagination={false}
                 rowKey={record => record.id}  columns={this.columns} dataSource={this.state.tableData}  />                
                 </div>  


          </Modal>                       



      </div>
    );
  }
}
