import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import './earth.less';

// import * as echarts from 'echarts';

import * as THREE from './three.module.js';

import { OrbitControls } from './OrbitControls.js';


import { CSS2DRenderer, CSS2DObject } from './threeSouce/CSS2DRenderer.js';


import * as d3 from 'd3';

import full from './full.json';



// tunnel
// import TunnelEmitter from '../earth/method/star/libs/emitters/tunnel';


// import ParticleSystem from '../earth/method/star/libs/system';
// import { Shape } from '../earth/method/star/libs/const';
// import Tween from '../earth/method/star/libs/tween';


// 地球圆圈
import img3 from '@/assets/img/earth/1.png';
 
import img6 from '@/assets/img/earth/6.png';

// 圆柱
import img4 from '@/assets/img/earth/121.png';


import {FlyLine} from './method/flyLineVersion1';


const total = 1000;
const height = 160;
const width = 160;
const depth = 160;


function Earth(props) {
    let container  = null
    let map = null,
    renderer = null,
    scene = null,
    camera = null,
    controller = null,
    raycaster = null,
    mouse = null,
    eventOffset = null,
    provinceInfo = null,
    controls = null,
    activeInstersect = [],
    uniform = {},
    circle1 = null,
    circle2 = null,
    circle3 = null,
    circle4 = null,
    linegroup = [],
    pointScene = null,
    Squarenessmesh1 = null,
    CylinderObject = new THREE.Object3D(),
    bottomCicleObj = new THREE.Object3D(), 
    circleObj = new THREE.Object3D(),
    AnimationFrameRender = null,
    AnimationFrameAll = null,
    labelRenderer = null

    useEffect(() => {

      getgetThreeEarth()

      return () => {
        cancelAnimationFrame(AnimationFrameRender); 
        cancelAnimationFrame(AnimationFrameAll); 
        window.removeEventListener('mousemove', () => {

        }) 

        clearDom()

      }

      }, [])

    function clearDom() {
      container  = null
      map = null
      renderer = null
      scene = null
      camera = null
      controller = null
      raycaster = null
      // mouse = null
      // eventOffset = null
      provinceInfo = null
      controls = null
      activeInstersect = null
      uniform = null
      circle1 = null
      circle2 = null
      circle3 = null
      circle4 = null
      bottomCicleObj = null
      circleObj = null
      AnimationFrameRender = null
      AnimationFrameAll = null
        
    }

    
    // three.js 模型 
    function getgetThreeEarth() {
      container = document.getElementById("containerHHback");

      renderer = new THREE.WebGLRenderer({
        antialias:true,
      });
      
      renderer.setClearColor('#0D1E37', 1.0);
      

      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);


      labelRenderer = new CSS2DRenderer();
      labelRenderer.setSize( window.innerWidth, window.innerHeight );
      labelRenderer.domElement.style.position = 'absolute';
      labelRenderer.domElement.style.top = '0px';
      container.appendChild( labelRenderer.domElement );



      scene = new THREE.Scene();
      
      scene.position.x = 0;

      var directionalLight = new THREE.DirectionalLight( 0xffffff );
      directionalLight.position.set( 1, 0.75, 0.5 ).normalize();
      scene.add( directionalLight );



      // var cylinderGeo = new THREE.CylinderGeometry(15, 15 ,40 ,40 ,40);
      //   var cylinderMat = new THREE.MeshLambertMaterial({//创建材料
      //       color:0xFF6600,
      //       wireframe:false
      //   });
      //   //创建圆柱体网格模型
      //   var cylinderMesh = new THREE.Mesh(cylinderGeo, cylinderMat);
      //   cylinderMesh.position.set(0, 20, 10);//设置圆柱坐标
      //   scene.add(cylinderMesh);


      scene.position.x = -10;

      scene.add(circleObj)
      scene.add(CylinderObject)

      
 
      camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(0, -50, 40);
      camera.lookAt(0, 0, 0);

      //设置控制
      setController()

      // 设置光照
      setLight()

      // 设置射线
      setRaycaster()

      // 设置动画
      animate()

      // 加载地图
      loadMapData(full);

      // 加载圆圈
      scatterCircle(1, 0.1, 0.1, new THREE.Vector3(0, 1, 1), 0.004);



      // 加载第二个圆圈
      
      let circle2 = scatterCircle(1, 0.2, 0.3, new THREE.Color('#5BADE7'), 0.012);
      circle2.scale.x = circle2.scale.y = circle2.scale.z = 1.5;
      circle2.position.y = -23
      circle2.position.x = -2
      circle2.position.z = 5
      
      // 加载粒子

      // 加载圆圈
      setCircleImg()
      // 加载粒子
      addParticle()

    // 加载正方形粒子
    Squarenessmesh1 =  addSquareness()
    Squarenessmesh1.position.y = -23;
    Squarenessmesh1.scale.x = Squarenessmesh1.scale.y = Squarenessmesh1.scale.z = 0.7;
    Squarenessmesh1.position.x = -2; 
    CylinderObject.add(Squarenessmesh1)


    let Squarenessmesh2 =  Squarenessmesh1.clone()
    Squarenessmesh2.position.x = 13;
    Squarenessmesh2.position.y = -23;
    CylinderObject.add(Squarenessmesh2)
    let circle3 = circle2.clone()
    CylinderObject.add(circle3)
    circle3.position.x = 13;
    circle3.position.y = -23;


    let Squarenessmesh3 =  Squarenessmesh1.clone()
    Squarenessmesh3.position.x = -2;
    Squarenessmesh3.position.y = -14;
    CylinderObject.add(Squarenessmesh3)
    let circle4 = circle2.clone()
    CylinderObject.add(circle4)
    circle4.position.x = -2;
    circle4.position.y = -14;


    let Squarenessmesh4 =  Squarenessmesh1.clone()
    Squarenessmesh4.position.x = 13;
    Squarenessmesh4.position.y = -8;
    CylinderObject.add(Squarenessmesh4)
    let circle5 = circle2.clone()
    CylinderObject.add(circle5)
    circle5.position.x = 13;
    circle5.position.y = -8;

     
    // FlyLine() 

    // 飞线1
    let mesh =  FlyLine(0, 45, '#1EB5FB', '#1EB5FB')
    CylinderObject.add(mesh);
    mesh.scale.x = mesh.scale.y = mesh.scale.z = 0.3; 
    linegroup.push(mesh);
    // mesh.rotateY(Math.PI/180 * -80)
    // mesh.rotateX(Math.PI/180 * 20)
    mesh.position.z = 0
    mesh.position.y = -26
    mesh.position.x = -0.7

    
     // 飞线1
     let mesh2 =  FlyLine(0, 32, '#1EB5FB', '#1EB5FB')
     CylinderObject.add(mesh2);
     mesh2.scale.x = mesh2.scale.y = mesh2.scale.z = 0.3; 
     linegroup.push(mesh2);
     mesh2.rotateY(Math.PI/180 * -70)
     mesh2.rotateX(Math.PI/180 * 10)
     mesh2.position.z = 1
     mesh2.position.y = -21
     mesh2.position.x = 6


     let mesh3 =  FlyLine(0, 58, '#1EB5FB', '#1EB5FB')
     CylinderObject.add(mesh3);
     mesh3.scale.x = mesh3.scale.y = mesh3.scale.z = 0.3; 
     linegroup.push(mesh3);
     mesh3.rotateY(Math.PI/180 * -42)
     mesh3.rotateX(Math.PI/180 * 10)
     mesh3.position.z = 1
     mesh3.position.y = -23.5
     mesh3.position.x = 4.8



     let earthLabel = addFontThree(['滇中引水石鼓水源工程', '滇中引水昆明段'])
     earthLabel.position.set(2, -22, 10);
     CylinderObject.add( earthLabel );  


     let earthLabel2 = addFontThree(['成都轨迹交通19号线土建8工区', '成都轨道交通19号线机电4工区'])
     earthLabel2.position.set(0, -8, 6);
     CylinderObject.add( earthLabel2 );  
     

     let earthLabel3 = addFontThree('深圳轨迹交通十二号线')
     earthLabel3.position.set(17.5, -20, 6);
     CylinderObject.add( earthLabel3 );  
  

     let earthLabel4 = addFontThree('郑州轨迹交通8号线一期工程')
     earthLabel4.position.set(17, -3, 6);
     CylinderObject.add( earthLabel4 );   


     
    }


    // 添加上升矩形
    function addSquareness() {
        const geometry = new THREE.Geometry();
        
        for(let i = 0; i < 10; i++) {
            const vertex = new THREE.Vector3();
            const x = Math.random() * 4 - 4 * 0.5;
            const y = Math.random() * 5;
            const z = Math.random() * 5 - 5 * 0.5;

            vertex.x = x;
            vertex.y = y;
            vertex.z = z;

            vertex.origin = new THREE.Vector3();
            vertex.origin.x = x;
            vertex.origin.y = y;
            vertex.origin.z = z;

            vertex.direction = new THREE.Vector3();
            vertex.direction.x = Math.random() - 0.5;
            vertex.direction.y = 0.5;
            vertex.direction.z = Math.random() - 0.5;
            geometry.vertices.push(vertex);
        }


        const material = new THREE.PointsMaterial({
            size: 0.5,
            color: 0x50FFFF,
            fog: true,
          });

        const points = new THREE.Points(geometry, material); 
        points.rotateX(Math.PI/180 * 40);
        points.position.z = 5
        return points;

        // animate()  
        // function animate() { // 设置动画

        //     const time = Date.now() * 0.0005;
        //     console.log(111)
        //     requestAnimationFrame(animate);  

        //     // points.geometry.vertices.forEach((item) => {
        //     //     item.y += item.direction.y * 0.2;
        //     //     // if (item.x > width || item.x < -width) item.x = item.origin.x;
        //     //     // if (item.y > width || item.y < -width) item.y = item.origin.y;
        //     //     if (item.y > 10) item.y = item.origin.y;
        //     // });
    
        //     points.geometry.verticesNeedUpdate = true;            
        // }
    }
 

    // 添加粒子
    function addParticle() {



      const geometry = new THREE.Geometry();
      for (let i = 0; i < total; i++) {
        const vertex = new THREE.Vector3();
        const x = Math.random() * width - width * 0.5;
        const y = Math.random() * height - height * 0.5;
        const z = Math.random() * depth - depth * 0.5;

        vertex.x = x;
        vertex.y = y;
        vertex.z = z;

        vertex.origin = new THREE.Vector3();
        vertex.origin.x = x;
        vertex.origin.y = y;
        vertex.origin.z = z;

        vertex.direction = new THREE.Vector3();
        vertex.direction.x = Math.random() - 0.5;
        vertex.direction.y = Math.random() - 0.5;
        vertex.direction.z = Math.random() - 0.5;
        geometry.vertices.push(vertex);
      }

      const material = new THREE.PointsMaterial({
        size: 0.2,
        color: 0x00ffff,
        fog: true,
      });

      const points = new THREE.Points(geometry, material);

      console.log(points)
      pointScene = points;
      scene.add(points);


    }


    /**
     * 
     * @param {*} name [] || string 
     * @returns  // 字体mesh
     */

    function addFontThree(name) {
      const earthDiv = document.createElement( 'div' );
    //   let img = document.createElement( 'img' );
      let p2 = document.createElement( 'p' );
       
    //   img.src = img6;

      p2.className = 'number';
    //   earthDiv.appendChild(img)
      earthDiv.appendChild(p2)


      earthDiv.className = 'ThreeLabelProjectDistribution';
      
      if(typeof(name) == 'string') {
        p2.textContent = name;
      } else {
        name.map(item => {
          let p = document.createElement( 'p' );
          p.textContent = item
          p2.appendChild(p)
        })


      }    

      
      
      earthDiv.style.marginTop = '-1em';
      const earthLabel = new CSS2DObject( earthDiv );
      
      return earthLabel;
    }



    function setCircleImg() {
      const geometry = new THREE.CircleGeometry(60, 120);

      // 0C2940

      var texture = THREE.ImageUtils.loadTexture(img3, {}, function() {
          
      });
      const material = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true,
      });

      const circle = new THREE.Mesh(geometry, material);

      circleObj.add(circle)
    }

    

    // 加载圆圈
    function scatterCircle(r, init, ring, color, speed) {
      
      let uniform = {
        u_color: { value: color },
        u_r: { value: init },
        u_ring: {
          value: ring,
        },
      };
    
      var vs = `
        varying vec3 vPosition;
        void main(){
          vPosition=position;
          gl_Position	= projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `;
      var fs = `
        varying vec3 vPosition;
        uniform vec3 u_color;
        uniform float u_r;
        uniform float u_ring;
    
        void main(){
          float pct=distance(vec2(vPosition.x,vPosition.y),vec2(0.0));
          if(pct>u_r || pct<(u_r-u_ring)){
            gl_FragColor = vec4(1.0,0.0,0.0,0);
          }else{
            float dis=(pct-(u_r-u_ring))/(u_r-u_ring);
            gl_FragColor = vec4(u_color,dis);
          }
        }
      `;
      const geometry = new THREE.CircleGeometry(r, 120);
      var material = new THREE.ShaderMaterial({
        vertexShader: vs,
        fragmentShader: fs,
        side: THREE.DoubleSide,
        uniforms: uniform,
        transparent: true,
        depthWrite: false,
      });
      const circle = new THREE.Mesh(geometry, material);
      circle.scale.x = circle.scale.y = circle.scale.z = 200;
      // circleObj.rotateX(-Math.PI/180 * 90);
      CylinderObject.add(circle)
    
      function render() {
        uniform.u_r.value += speed || 0.1;
        if (uniform.u_r.value >= r) {
          uniform.u_r.value = init;
        }
        requestAnimationFrame(render);
      }
      render();
      return circle;
    }





    
    function loadMapData(chinaJson){

      map = new THREE.Object3D();


      
      const projection = d3.geoMercator().center([104.0, 37.5]).scale(80).translate([0, 0]);



      chinaJson.features.forEach(elem => {
          // 定一个省份3D对象
          const province = new THREE.Object3D();
          // 每个的 坐标 数组
          const coordinates = elem.geometry.coordinates;

        let uv = `
           uniform sampler2D baseTexture;
           uniform sampler2D bloomTexture;
           varying vec2 vUv;
           vec4 getTexture( sampler2D texelToLinearTexture ) {
             return mapTexelToLinear( texture2D( texelToLinearTexture , vUv ) );
           }
          void main() {
             gl_FragColor = ( getTexture( baseTexture ) + vec4( 1.0 ) * getTexture( bloomTexture ) );
          }
        `
        


          // 循环坐标数组
          coordinates.forEach(multiPolygon => {

            multiPolygon.forEach(polygon => {
              const shape = new THREE.Shape();

              const lineMaterial = new THREE.LineBasicMaterial({
                color: '#91FFFF'
              });

              const lineGeometry = new THREE.Geometry();

             
              for (let i = 0; i < polygon.length; i++) {
                const [x, y] = projection(polygon[i]);
                if (i === 0) {
                  shape.moveTo(x, -y);
                }
                shape.lineTo(x, -y);
                lineGeometry.vertices.push(new THREE.Vector3(x, -y, 4.01));
              }

              const extrudeSettings = {
                depth: 4,
                bevelEnabled: false
              };

              
              const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
              const material = new THREE.MeshBasicMaterial({
                color: '#3A3E44',
                transparent: true,
                opacity: 0.85
              });
              const material1 = new THREE.MeshBasicMaterial({
                color: '#3A3E44',
                transparent: true,
                opacity: 0.85
              });
              const mesh = new THREE.Mesh(geometry, [material, material1]);
              const line = new THREE.Line(lineGeometry, lineMaterial);

              province.add(mesh);
              province.add(line)



            })

          })

          // 将geo的属性放到省份模型中
          province.properties = elem.properties;
          if (elem.properties.contorid) {
            const [x, y] = projection(elem.properties.contorid);
            province.properties._centroid = [x, y];
          }

          map.add(province);

        })


        scene.add(map);

    }


    function animate() { // 设置动画

      const time = Date.now() * 0.0005;

      AnimationFrameAll =  requestAnimationFrame(animate);

      raycaster.setFromCamera(mouse, camera);
  
      let intersects = raycaster.intersectObjects(scene.children, true);

      if (activeInstersect && activeInstersect.length > 0)  {
        activeInstersect.forEach(element => {
          element.object.material[0].color.set('#3A3E44');
          element.object.material[1].color.set('#3A3E44');
        });
      }

      activeInstersect = []


      for (var i = 0; i < intersects.length; i++) {
        if (intersects[i].object.material && intersects[i].object.material.length === 2) {
          activeInstersect.push(intersects[i]);
          intersects[i].object.material[0].color.set(0x59C3F9);
          intersects[i].object.material[1].color.set(0x59C3F9);
          break; // 只取第一个
        }
      }


      

      if(Squarenessmesh1) {
        Squarenessmesh1.geometry.vertices.forEach((item) => {
            item.y += item.direction.y * 0.1;
            if (item.y > 5) item.y = 0;
            
        });
        Squarenessmesh1.geometry.verticesNeedUpdate = true;
      }


      if(pointScene) {

        pointScene.geometry.vertices.forEach((item) => {
           item.x -= item.direction.x * 0.2;
           item.y -= item.direction.y * 0.2;
           item.z -= item.direction.z * 0.2;
           if (item.x > width || item.x < -width) item.x = item.origin.x;
           if (item.y > width || item.y < -width) item.y = item.origin.y;
           if (item.z > width || item.z < -width) item.z = item.origin.z;
       });

       pointScene.geometry.verticesNeedUpdate = true;
     }


     if(linegroup.length){
        for(var i = 0;i<linegroup.length;i++){
            var flyline = linegroup[i];
            if(flyline && flyline.material.uniforms){
                var time2 = flyline.material.uniforms.time.value;
                var size = flyline.material.uniforms.size.value;
                if(time2 > flyline.maxx){
                    flyline.material.uniforms.time.value = flyline.minx - size;
                }
                flyline.material.uniforms.time.value += 0.8;
            }
        }
    }

      
  
      circleObj.rotation.z += 0.01

      renderer.render(scene, camera);

      labelRenderer.render( scene, camera );
    }


    function setRaycaster() {

      raycaster =  new THREE.Raycaster();
      mouse = new THREE.Vector2();
      eventOffset = {};


      function onMouseMove(event) {

        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components

        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        eventOffset.x = event.clientX;
        eventOffset.y = event.clientY;

        
        // this.provinceInfo.style.left = _this.eventOffset.x + 2 + 'px';
        // this.provinceInfo.style.top = _this.eventOffset.y + 2 + 'px';
      }

       window.addEventListener('mousemove', onMouseMove, false)

    }

    function setController() {
      // controller = new THREE.OrbitControls(camera, renderer.domElement);
      controls = new OrbitControls(camera, renderer.domElement);


    }

    function setLight() {
      let ambientLight = new THREE.AmbientLight(0xffffff); // 环境光
      scene.add(ambientLight);

      const light = new THREE.DirectionalLight();
      light.position.set( 0.5, 1, 100 );
      light.castShadow = true;
      light.shadow.camera.zoom = 4; // tighter shadow map
      scene.add( light );


    }


  return (
    <div className="EarthEchart">


    <div>
        <div id="containerHHback" className="echart" style={{width: 1400, height: 900}}>

        </div>
    </div>
    
        

    </div >
  )
}

export default withRouter(Earth);