import React from "react";


import { Input,Select,Button, Form,Table,message, Popconfirm, Modal, Image, DatePicker} from "antd";

import {formatTime} from '@/utils/util';
import {getStorage} from '@/utils/storage';

import { SearchOutlined, UploadOutlined, UpOutlined, DownOutlined, PlusOutlined} from '@ant-design/icons';
import { Uploadhh } from "@/components/upload/upload";
import SignCanvas from '@/components/signCanvas/signCanvas';
import './CastingPermit.less';

import Style from "./CastingPermit.module.less";



const {Option} =  Select;

const addPath = process.env.REACT_APP_BASE_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

export default class  PartyActive extends React.Component {
  // imageUrl = process.env.REACT_APP_IMG_URL
    columns = [
      {
        title: "准浇证号",
        dataIndex: 'pouringNo',
        align: 'center',
        // render: (_, item, index) => {
        //   return (
        //     <p>{index + 1}</p>
        //   )
        // }
      },
    //   {
    //     title: '桩号',
    //     dataIndex: 'stationNumber',
    //     align: 'center'
    //   },
        {
          title: '分部工程',
          dataIndex: 'subOption',
          align: 'center',
          width: 200
        },
        {
            title: '分项工程',
            dataIndex: 'unit',
            align: 'center',
            width: 200
         },
        {
          title: '单位工程',
          dataIndex: 'branch',
          align: 'center'
        },
        {
          title: '单元工程',
          dataIndex: 'element',
          align: 'center'  
        },
        {
          title: '拌合坍落度',
          dataIndex: 'mixingSlumpStart',
          align: 'center',
          render: (_, item) => {
            return (
              <p>{item.mixingSlumpStart + ' ~ ' + item.mixingSlumpEnd}</p>
            )
          }  
        },
        {
          title: '入仓方式',
          dataIndex: 'warehousingMethod',
          align: 'center',
        },
        {
          title: '预浇筑时间',
          dataIndex: 'preCastTime',
          align: 'center',
        },
        {
            title: '预浇筑方量 m³',
            dataIndex: 'preCastVolume',
            align: 'center',
        },
        {
          title: '任务状态',
          dataIndex: 'taskStatus',
          align: 'center',
          render: (_) => {
              return (
                  <p style={{color: _==1?'':'#008000'}}>{_== 1? '待处理': '已处理'}</p>
              )
            }
          },
        // {
        //   title: '状态',
        //   dataIndex: 'status',
        //   align: 'center',
        //   render: (_) => {
        //     return (
        //       <p>{_==2? '报名未开始': _==3? '报名中':_==4? '签到未开始':_==5?'签到中':_==6?'活动已结束': '报名和签到同时进行'}</p>
        //     )
        //   }
        // },
        {
            title: '操作',
            dataIndex: 'remark',
            align: 'center',
            render:(_,item) => {
                return (
                    <div style={{display: 'flex', width: 160}}>
                  
                        <div onClick={this.getDetail.bind(this, item)} style={{color: '#6A9BF4', cursor:'pointer' , marginRight: 20}}>详情</div>
                        <a target="_blank" href={imgUrl + item.word} style={{marginRight: 20, color: '#6A9BF4', cursor:'pointer', display: item.word?'block':'none'}}>打印预览</a>
                        
                        <Popconfirm
                            title="是否确认删除?"
                            onConfirm={this.toDel.bind(this, item.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                          <p style={{color: '#ff0000 ', cursor:'pointer'}}>删除</p>
                        </Popconfirm>
                    </div>
                    
                    
                )
            }
        },
      ]


  state = {
    herfEarth: '',
    formRef: null,
    signCanvas: null,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    TableParams: {
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 0,
        meStatus: '',
        pouringNo: '',
        taskStatus: '',
        subOption: '',
        unit: '',
        branch: '',
        element: ''
    },

    infoMsg: {
      signIn: [],
      singUp: []
    }, // 详情信息
    isModalVisible: false,
    detailItem: {
      img: []
    },
    listPouringPermitList: [],
    isDetailShow: false,
    pouringNo: '',
    subOption: '',
    branch: '',
    element: '',
    unit: '',
    mixingSlumpStart: '',
    mixingSlumpEnd: '',
    subOptionList: [], // 分项工程
    branchList: [], // 分部工程
    elementList: [], // 单元工程
    unitList: [],  //  单位工程
    showNumberList: [], // 显示标号列表
    ImgList: [],
    visibleAdd: false,
    technologyName: '',
    userAll: []
  }




  componentDidMount() {

    this.getTableData()
    

    this.getListConcreteMarking();
    this.getListEngineering();

    this.getUser();
    const name =  getStorage('loginInfo').name;

    this.setState({
      technologyName: name
    })

  }


  
  // 获取用户列表
  getUser() {
    React.$axios.post('userAll', {enterStatus: 2,teamType: 4,unitType: 12}).then(res => {

      this.setState({
        userAll: res.data.userAll
      });

    })
  }
  

  // 获取混凝土标号
  getListConcreteMarking() {
    const projectId =  getStorage('projectInfo').id;
    React.$axios.post('openPositions/listConcreteMarking', {logic:2, projectId: projectId}).then(res => {
      this.setState({
        showNumberList: res.data.list
      })
    })
  }

  // 获取单位详情
  getListEngineering() {
    const projectId =  getStorage('projectInfo').id;
    React.$axios.post('openPositions/listEngineering', {projectId: projectId, logic: 1}).then(res => {
      let brr = res.data.filter((item) => {
        return item.pid == 0;
      })

      brr.map(item => {
        let arr2 = res.data.filter(item2 => {
          return item.id == item2.pid
        })


        arr2.map(item3 => {
         let arr3 =  res.data.filter(item2 => {
            return item3.id == item2.pid
         })
         
        arr3.map(item4 => {
          let arr4 = res.data.filter(item5 => {
            return item4.id == item5.pid
          })
          item4.children = arr4;
          
        })
        
        item3.children = arr3;
        })
        item.children = arr2;
      })

      
      this.setState({
        unitList: brr
      })
      
    });
  }

  // 获取详情信息
  getDetail(item) {
    const {projectId, pouringNo}= item;
    this.setState({
      isDetailShow: true
    })

    React.$axios.post('openPositions/listPouringPermit', {projectId, pouringNo, logic: 2}).then(res => {
      res.data.approvalLogMany.map(item => {
        item.addTime = formatTime(new Date(item.addTime * 1000), 'yyyy.MM.dd hh:mm')
      })
      if(res.data.img) {
        res.data.img = res.data.img.split(',');
      } else {
        res.data.img = []
      }
      

      this.setState({
        listPouringPermitList: res.data.approvalLogMany,
        detailItem: res.data
      })

    })

  }


  setDrapDom() {
    if(this.state.openStatus == true) {
        return (
          <UpOutlined/>
        )
      } else {
        return (
          <DownOutlined />
        )
      }
  }

  drapHeight() {
    this.setState({
      openStatus: !this.state.openStatus
    })
  }

  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

       let projectId =  React.$getStorage('projectInfo').id
      React.$axios.post('openPositions/listPouringPermit', {...this.state.TableParams, ...{projectId}}).then(res => {
        
        console.log(res);
        this.state.pagination.total = res.data.countList

        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    console.log(name)
    this.state.TableParams[name] = value
  }

  toSerch2() {
    this.getTableData(true)
  }



  toDel(id) {
    React.$axios.post('openPositions/savePouringPermit', {id, logic: 3}).then(res => {
        if(res.code == 20000) {
            message.success('删除成功')
            this.getTableData()
        } else {
            message.error('删除失败')
        }
    })
  }

  handleOk() {
    this.setState({
        isModalVisible: false
    })
  }

  handleCancel() {
    this.setState({
      isDetailShow: false,
      visibleAdd: false,
    })
  }

 
  handleTableChange(value) {
    this.state.pagination = value
    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }


  onChangeT(name, value) {
    this.state.TableParams[name] = value;
    this.setState({
      TableParams: this.state.TableParams
    })
  }

  exportExcel() {
      let projectId =  React.$getStorage('projectInfo').id
      // React.$axios.get('openPositions/excelExportPouringPermit', {params: {...this.state.TableParams, ...{projectId}}}, ).then(res => {
        
      // })
      let jsonString = {...this.state.TableParams, ...{projectId}};
      
      let url = addPath +  "openPositions/excelExportPouringPermit?" + 
      `lengthLimit=${jsonString.lengthLimit}&logic=${jsonString.logic}&unit=${jsonString.unit}&branch=${jsonString.branch}&element=${jsonString.element}&subOption=${jsonString.subOption}&meStatus=${jsonString.meStatus}&offsetLimit=${jsonString.offsetLimit}&pouringNo=${jsonString.pouringNo}&projectId=${jsonString.projectId}&taskStatus=${jsonString.taskStatus}`
      
      // 这里那个头信息可以改
    //   React.$axios({
    //     method: "get",
    //     responseType: 'arraybuffer',
    //     // responseType: 'blob',
    //     url: "openPositions/excelExportPouringPermit?" + 
    //     `lengthLimit=${jsonString.lengthLimit}&logic=${jsonString.logic}&meStatus=${jsonString.meStatus}&offsetLimit=${jsonString.offsetLimit}&pouringNo=${jsonString.pouringNo}&projectId=${jsonString.projectId}&taskStatus=${jsonString.taskStatus}`,
    //     headers: { 'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //     'content-disposition':'attachment;',
    //     'Cache-Control': 'max-age=0',
    //     "Access-Control-Allow-Origin": "*"
    //      }
    // })
    
      const elink = document.createElement('a');	// 创建一个HTML元素
      elink.download = '1';			        // 给元素添加H5中的download属性
      elink.style.display = 'none';			
      elink.href = url;	// 利用Blob对象创建URL
      document.body.appendChild(elink);		// 加入标签
      elink.click();                          // 点击事件
      URL.revokeObjectURL(elink.href);		// 释放URL对象
      document.body.removeChild(elink)		// 移除标签
      // console.log("导出成功");


  //   React.$axios.get(url, { responseType: 'arraybuffer' }).then((res) => {
  //     if (res.status === 200) {
  //         const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });	// 创建Blob 对象（存储二进制文件，注意type一定要）
  //         const fileName = "文件名.xls";			    // 文件名
          // const elink = document.createElement('a');	// 创建一个HTML元素
          // elink.download = fileName;			        // 给元素添加H5中的download属性
          // elink.style.display = 'none';			
          // elink.href = URL.createObjectURL(blob);	// 利用Blob对象创建URL
          // document.body.appendChild(elink);		// 加入标签
          // elink.click();                          // 点击事件
          // URL.revokeObjectURL(elink.href);		// 释放URL对象
          // document.body.removeChild(elink)		// 移除标签
          // console.log("导出成功");
  //     }
  // })

  }


  // 去提交
  toSubmit() {
    this.formRef.submit();
  }

  toAdd() {
    this.setState({
      visibleAdd: true,
      pouringNo: '',
      subOption: '',
      branch: '',
      element: '',
      unit: '',
      mixingSlumpStart: '',
      mixingSlumpEnd: '',
      subOptionList: [], // 分项工程
      branchList: [], // 分部工程
      elementList: [], // 单元工程
      ImgList: [],
    })

    if( this.formRef) {
      this.formRef.resetFields();
    }

    if(this.signCanvas) {
      this.signCanvas.clearSignature();
     
    }

  }

  // 去验证
  async onFinish(value) {
    let technicianUser =  React.$getStorage('loginInfo').id;
    let query = { // 提交参数
      altitude: "2",
      branch: "",
      concreteMarking: "",
      element: "",
      fix: 2,
      id: 0,
      img: "",
      logic: 0,
      mixingSlumpEnd: '',
      mixingSlumpStart: '',
      noticeSupervisionEngineer: "1",
      pouringNo: "",
      preCastTime: "",
      preCastVolume: "",
      qualityInspectorSign: "",
      qualityInspectorUser: "",
      stationNumber: "",
      subOption: "",
      supervisionEngineerSign: "",
      supervisionEngineerUser: "",
      teamName: "",
      technicianSign: "",
      technicianUser: '',
      testMembersUser: "",
      unit: "",
      warehousingMethod: "",
    };


    let parmas = {
      ...query,
      ...value, 
    }

    parmas.pouringNo = this.state.pouringNo;
    parmas.subOption = this.state.subOption;
    parmas.element = this.state.element;
    parmas.unit = this.state.unit;
    parmas.mixingSlumpEnd = this.state.mixingSlumpEnd;
    parmas.mixingSlumpStart = this.state.mixingSlumpStart;
    parmas.technicianUser = technicianUser;
    parmas.img = this.state.ImgList.join(',');

    if(this.signCanvas) {
      let signImg = await this.signCanvas.toUpload();
      if(signImg) {
      parmas.technicianSign = signImg.key[0];
      }else {
        return;
      }
    }
    

    if(parmas.preCastTime) {
      parmas.preCastTime = formatTime(parmas.preCastTime._d , 'yyyy-MM-dd hh:mm') 
    }
    

    React.$axios.post('openPositions/savePouringPermit', parmas).then(res => {
      if(res.code  === 20000 ) {
        this.setState({
          visibleAdd: false,
        })
        this.getTableData();
          message.success('保存成功');
      }
    })


  }

  render() {
    return (
      <div className="roster">
      <div className="PartyActive PartyActive_self table rosterAll">

<div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                      <li><span>准浇证号:</span> 
                          <Input onChange={this.serchCar.bind(this, 'pouringNo')}  style={{ width: 220, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                          
                          </li>

                          <li><span>分部工程:</span> 
                          <Input onChange={this.serchCar.bind(this, 'subOption')}  style={{ width: 220, height:30, marginRight:20,  }} placeholder="请输入分部工程" />
                          
                          </li>
                          <li><span>分项工程:</span> 
                          <Input onChange={this.serchCar.bind(this, 'unit')}  style={{ width: 220, height:30, marginRight:20,  }} placeholder="请输入分项工程" />
                          
                          </li>
                          <li><span>单位工程:</span> 
                          <Input onChange={this.serchCar.bind(this, 'branch')}  style={{ width: 220, height:30, marginRight:20,  }} placeholder="请输入单位工程" />
                          <Button  className="btn" style={{display: this.state.openStatus?'none':''}} onClick={this.toSerch2.bind(this)}>
                                <SearchOutlined className="icon"/>
                          </Button>
                          </li>
                         

                          <li><span>单元工程:</span> 
                          <Input onChange={this.serchCar.bind(this, 'element')}  style={{ width: 220, height:30, marginRight:20,  }} placeholder="请输入单元工程" />
                          

                         
                        </li>

                        <li><span>任务状态:</span>  
                          <Select
                              showSearch
                              style={{ width: 220, height:30, marginRight:20,  }}
                              placeholder="请选择"
                              optionFilterProp="children"
                              onChange={this.onChangeT.bind(this, 'taskStatus')}
                          >
                          <Option value=" ">全部</Option>
                          <Option value="1">待处理</Option>
                          <Option value="3">已完成</Option>
                          </Select>
                        </li>

                        <li><span>状态:</span>  
                          <Select
                              showSearch
                              style={{ width: 220, height:30, marginRight:20,  }}
                              placeholder="请选择"
                              optionFilterProp="children"
                              onChange={this.onChangeT.bind(this, 'status')}
                          >
                            <Option value=" " key="1">全部</Option>
                            <Option value="2" key="2">我的</Option>
                          </Select>


                          {/* <Button  className="btn" >
                                <SearchOutlined  className="icon"/>
                            </Button> */}

                            <Button style={{marginBottom: 20, height: '30px', display: this.state.openStatus?'block':'none'}}  className="btn" onClick={this.toSerch2.bind(this)}>
                                  <SearchOutlined className="icon" />
                              </Button>
                        </li>



                          

                             

                            {/* </div>   */}

                            

                    </ul>

                    <Button  className="bt" onClick={this.toAdd.bind(this)} type="primary" icon={<PlusOutlined className="translate" />}>
                        新增
                    </Button>


                    <Button  className="bt" onClick={this.exportExcel.bind(this)} type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button>

                    
                   
                </div>

                <p className="drapBottom" onClick={this.drapHeight.bind(this)}>
                        {
                          this.setDrapDom()
                        }
                </p>

                 
                

          {/* <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        

                        
                    </ul>
                </div> */}



                <div className="table2" style={{marginTop: 20}}>
                {/* <div class="allBtn">
                   
                    <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                        导入
                    </Button>
                    <Button  className="bt" type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button>
                    <Button  className="bt" type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button>
                    </div> */}


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                   
                    loading={this.state.loading} rowKey={record => record.id} columns={this.columns} dataSource={this.state.tableData}  />
                </div>
            

                <Modal
                    width="60%"
                    title="添加准浇证"
                    visible={this.state.visibleAdd}
                    className={Style.AddCasting}
                    onOk={this.toSubmit.bind(this)}
                    onCancel={this.handleCancel.bind(this)}
                    >
                      <div className="box">
                        <Form ref={(_) => {this.formRef = _}} name="basic"
                           labelCol={{ span: 6 }}
                           wrapperCol={{ span: 16 }}
                           onFinish={this.onFinish.bind(this)}
                        >

                       
                        <ul className="List">
                          <li>
                          <Form.Item
                              label="准浇编号"
                              name="pouringNo"
                             
                            >
                              <div className="flex">  
                                <Input onChange={(e) => {this.state.pouringNo = e.target.value; this.setState({
                                  pouringNo:this.state.pouringNo
                                })}} value={this.state.pouringNo}/>
                                <Button style={{marginLeft: 20}} type="primary" onClick={() => {
                                   let date = new Date();
                                  let strDate = formatTime(date, 'yyyy-MM-dd-hh-mm-ss');
                                  strDate = strDate.split('-');
                                  let str='';
                                  strDate.map(item => {
                                    str +=''+item 
                                  })
                                  let a = `${parseInt(Math.random() * 1000000)}` 
                                  str = str + a
                                  this.setState({
                                    pouringNo: str
                                  })

                                }}>
                                    随机生成
                                </Button>
                              </div>
                            </Form.Item>

                          </li>

                          <li>
                          <Form.Item
                              label="单位工程"
                              name="branch"
                            
                            >
                            
                                <Select
                                  placeholder="请选择单位工程"
                                  onChange={(e, item) => {
                                    if(!item) {
                                      this.setState({
                                        branchList: [],
                                        subOptionList: []
                                      })
                                    } else {
                                      this.setState({
                                        
                                        branchList: item.item.children
                                      })
                                    }
                                   
                                  }}
                                  allowClear
                                >
                                  {
                                    this.state.unitList.map((item) => {
                                      return (
                                        <Option key={item.id} item={item} value={item.name}>{item.name}</Option>
                                      );
                                    })
                                  }
                                </Select> 
                         
                            </Form.Item>
                          </li>

                          <li>
                          <Form.Item
                              label="分部工程"
                              name="subOption"
                             
                            >
                              <div className="flex">

                                

                                <Select
                                  placeholder="请选择分部工程"
                                  onChange={(e, item) => {
                                      if(!item) {
                                        this.setState({
                                          subOption: '',
                                          subOptionList: []
                                        })
                                      } else {
                                        this.setState({
                                          subOption: item.value,
                                          subOptionList: item.item.children
                                        })
                                      }
                                

                                  }}
                                  allowClear
                                  value={this.state.subOption}
                                >
                                  {
                                    this.state.branchList.map((item) => {
                                      return (
                                        <Option key={item.id} item={item} value={item.name}>{item.name}</Option>
                                      );
                                    })
                                  }
    
                                </Select> 

                                <Input onChange={(e) => {this.state.subOption = e.target.value; this.setState({
                                  subOption:this.state.subOption
                                })}}  value={this.state.subOption} className="Pos" />
   
                              </div>
                            </Form.Item>
                          </li>

                          <li>
                          <Form.Item
                              label="分项工程"  
                            >
                              <div className="flex">

                                

                                <Select
                                  placeholder="请选择分项工程"
                                  onChange={(e, item) => {
                                    if(!item) {
                                      this.setState({
                                        unit: '',
                                        elementList: []
                                      })
                                    } else {
                                      this.setState({
                                        unit: item.value,
                                        elementList: item.item.children
                                      })
                                    }

                                  }}
                                  allowClear
                                  value={this.state.unit}

                                >
                                 {
                                    this.state.subOptionList.map((item) => {
                                      return (
                                        <Option key={item.id} item={item} value={item.name}>{item.name}</Option>
                                      );
                                    })
                                  }
    
                                </Select> 

                                <Input onChange={(e) => {this.state.unit = e.target.value; this.setState({
                                  unit:this.state.unit
                                })}} value={this.state.unit} className="Pos" />
   
                              </div>
                            </Form.Item>
                          </li>

                          <li>
                          <Form.Item
                              label="单元工程"
                              name="subOption"
                              
                            >
                             <div className="flex">
                                <Select
                                  placeholder="请选择单元工程"
                                  onChange={(e, item) => {
                                    if(!item) {
                                      this.setState({
                                        element: '',
                                      })
                                    } else {
                                      this.setState({
                                        element: item.value,
                                      })
                                    }
                                    
                                  }}
                                  allowClear
                                >
                                  {
                                    this.state.elementList.map((item) => {
                                      return (
                                        <Option key={item.id} item={item} value={item.name}>{item.name}</Option>
                                      );
                                    })
                                  }
                                </Select> 

                                <Input onChange={(e) => {this.state.element = e.target.value; this.setState({
                                  element:this.state.element
                                })}} value={this.state.element} className="Pos" />

                                </div>
                            </Form.Item>
                          </li>

                          <li>
                          <Form.Item
                              label="桩号"
                              name="stationNumber"
                              
                            >
                              
                                <Input />
                            
                            </Form.Item>

                          </li>


                          <li>
                          <Form.Item
                              label="高程"
                              name="altitude"
                              
                            >
                           
                                <Input />
                            
                            </Form.Item>

                          </li>

                          <li>
                          <Form.Item
                              label="混凝土标号"
                              name="concreteMarking"
                             
                            >
                              
                              <Select
                                  placeholder="请选择混凝土标号"
                                  // onChange={onGenderChange}
                                  allowClear
                                >
                                  {
                                    this.state.showNumberList.map((item, index) => {
                                      return (
                                        <Option key={index} value={item}>{item}</Option>

                                      );
                                    })
                                  }
                                </Select> 
                         
                            </Form.Item>

                          </li>


                          <li>
                          <Form.Item
                              label="入仓方式"
                              name="warehousingMethod"
                                
                            >

                              <Select
                                  placeholder="请选择入仓方式"
                                  onChange={(e) => {
                                    let mixingSlumpStart = 0;
                                    let mixingSlumpEnd = 0;
                                    if(e == '自卸') {

                                      mixingSlumpStart= 120
                                      mixingSlumpEnd = 160

                                    } else {

                                      mixingSlumpStart = 160
                                      mixingSlumpEnd = 200

                                    }
                                    

                             
                                    this.setState({
                                      mixingSlumpStart: mixingSlumpStart,
                                      mixingSlumpEnd: mixingSlumpEnd
                                    })
                     

                                }}  
                                 
                                  allowClear
                                >
                                  <Option value="泵送">泵送</Option>
                                  <Option value="常态">常态</Option>
                                  <Option value="导管">导管</Option>
                                  <Option value="溜槽">溜槽</Option>
                                  <Option value="自卸">自卸</Option>
                                </Select> 
                            </Form.Item>

                          </li>


                          <li>
                          <div className="gridInput">

                            <div className="posLeft">

                           
                            <Form.Item
                              label="拌合坍落度"
                              wrapperCol={{ span: 12 }}
                              labelCol={{ span: 10 }} 
                            >
                     
                              <Input 
                                onChange={(e) => {this.state.mixingSlumpStart = e.target.value; this.setState({
                                  mixingSlumpStart:this.state.mixingSlumpStart
                                })}} 
                                
                                value={this.state.mixingSlumpStart}
                                placeholder="请输入"
                            />
                           
                            </Form.Item>
                            </div>

                           
                          
                            <div className="posRight">
                            <span>~</span>
                            <Form.Item
                              label=""
                              wrapperCol={{ span: 21 }}
                              labelCol={{ span: 0 }}
                              // name="mixingSlumpEnd"
                              
                            >
                              
                              <Input  onChange={(e) => {this.state.mixingSlumpEnd = e.target.value; this.setState({
                                  mixingSlumpEnd:this.state.mixingSlumpEnd
                                })}} value={this.state.mixingSlumpEnd} placeholder="请输入" />
                             
                            </Form.Item>
                            </div>



                          </div> 

                          

                          </li>

                          <li>
                          <Form.Item
                              label="预浇筑方量"
                              name="preCastVolume"
                             
                            >
                              <Input style={{width: 'calc(100% - 50px)'}} />
                          
                            </Form.Item>
                            <span className="unit" style={{marginLeft: 20, marginTop: 5}}>m³</span>     
                          </li>


                          <li>
                          <Form.Item
                              label="浇筑时间"
                              name="preCastTime"
                            
                            >
                              
                              <DatePicker style={{width: '100%'}} showTime />
                            </Form.Item>

                          </li>


                          <li>
                          <Form.Item
                              label="队伍名称"
                              name="teamName"
                            >
                             
                              <Input />
                           
                            </Form.Item>

                          </li>

                          <li>
                          <Form.Item
                              label="技术人员"
                            >
                             
                              <Input value={this.state.technologyName} />
                           
                            </Form.Item>

                          </li>

                          <li>
                          <Form.Item
                              label="质检员"
                              name="qualityInspectorUser"  
                            >
                             <Select
                                  placeholder="请选择"
                                  allowClear
                                >

                                {
                                  this.state.userAll.map(item => {
                                    return (
                                      <Option key={item.id} value={item.id}>{item.uicName}</Option>
                                    )
                                  })
                                }
                                </Select>
                              
                           
                            </Form.Item>

                          </li>

                          <li>
                          <Form.Item
                              label="技术人员签名"
                              
                            >
                             
                              <SignCanvas ref={(_) => {this.signCanvas = _}} id="sin1" width={300} height={120}></SignCanvas>
                           
                            </Form.Item>

                          </li>


                          

                         
                        </ul>

                        <div className="img">

                              <p>照片</p>

                        {
                                <ul className="setFingerList flex">
                                    <Image.PreviewGroup>
                                        {
                                            this.state.ImgList.map((item, index) => {
                                                return (
                                                    <div style={{marginRight: 20, position: 'relative'}} key={index}>
                                                    <span className="removeImgList"  onClick={ ()=> {
                                                      
                                                      
                                                       this.state.ImgList.splice(index, 1);
                                                      
                                                      this.setState({
                                                        ImgList: this.state.ImgList
                                                      })
                                                    }}>×</span>
                                                <Image
                                                    width={140}
                                                    height={100}
                                                    src={imgUrl + item}
                                                />
                                                </div>
                                                )
                                            })
                                        } 
                                    </Image.PreviewGroup>


                                    <Uploadhh  listStatus={true}   data={{type: 85, pixel: ''}}  width={140} height={100} handleClick={(item) => {
                                      this.state.ImgList.push(item.data.key[0]);
                                      this.setState({
                                        ImgList: this.state.ImgList
                                      })
                                    }} />    
                                </ul> 
                            } 

                        </div>

                        </Form>


                       
                       
                      </div>
                      

                </Modal>


<Modal title="准浇证详情" width="50%"   className={Style.Casting + " BlankingListModal"}  visible={this.state.isDetailShow}  onOk={this.handleCancel.bind(this)} onCancel={this.handleCancel.bind(this)}>
                   <div className="box">
                   

                   <h1>编号：{this.state.detailItem.pouringNo}</h1>
                   <ul className="inputLi">
                      {/* <li>
                      <span>编号：</span> {this.state.detailItem.pouringNo}
                      </li> */}

                      <li>
                          <span>单位工程：</span> <div> {this.state.detailItem.branch}</div>
                      </li>
                      <li>
                          <span>分部工程：</span> <div>{this.state.detailItem.subOption}</div>
                      </li>
                      <li>
                          <span>分项工程：</span> <div>{this.state.detailItem.unit}</div>
                      </li>
                      <li>
                          <span>单元工程：</span> <div>{this.state.detailItem.element}</div>
                      </li>
                      <li>
                          <span>桩号：</span> <div>{this.state.detailItem.stationNumber}</div>
                      </li>
                      <li>
                          <span>高程：</span> <div>{this.state.detailItem.altitude}</div>
                      </li>
                      <li>
                          <span>混凝土标号：</span><div> {this.state.detailItem.mixingSlumpStart}</div>
                      </li>
                      <li>
                          <span>拌合坍落度：</span> <div>{this.state.detailItem.mixingSlumpStart}  ~  {this.state.detailItem.mixingSlumpEnd} mm</div>
                      </li>
                      <li>
                          <span>入仓方式：</span> <div>{this.state.detailItem.warehousingMethod}</div>
                      </li>
                      <li>
                          <span>预浇筑方量：</span><div> {this.state.detailItem.preCastVolume}</div>
                      </li>
                      <li>
                          <span>浇筑时间：</span><div> {this.state.detailItem.preCastTime}</div>
                      </li>
                      <li>
                          <span>队伍名称：</span><div> {this.state.detailItem.teamName}</div>
                      </li>

                      <li>
                          <span>技术员(发起人)：</span><div> {this.state.detailItem.technicianUserName}</div>
                      </li>

                      <li>
                          <span>图片：</span><div>
                          {
                            this.state.detailItem.img.map(((item, index) => {
                              return (
                                <div key={index} style={{marginRight:10}}>
                                <Image
                                      style={{display: item?'':'none'}}
                                      
                                      width={120}
                                      height={80}
                                      src={imgUrl + item}
                                  /> 
                                </div>
                              )
                            }))
                          }
                          </div>
                      </li>

                          
      
                      
                    </ul> 

                    
                    <h1>审批/处理过程</h1>
                    <ul className="user">
                      {
                        this.state.listPouringPermitList.map(item => {
                          return (
                            <li key={item.id + 'index'}>
                              <div className="name">
                                <img src={require('@/assets/img/cloudOpenning/face.png').default} alt="" />
                                {item.nameUser}</div>
                            
                              <div className="des">
                                
                                <span className="nameWidth" style={{marginRight: 30}}>
                                {
                                     item.status == 1? '技术员': item.status == 2? '质检员': item.status == 3? '质检员': item.status == 4? '监理工程师':
                                     item.status == 5? '监理工程师':  item.status == 6? '技术员': item.status == 7? '质检员': 
                                     item.status == 8? '监理工程师': item.status == 9? '监理': '监理'
                                  }
                                </span>
                                
                                <span className={'sign cololr' + item.status}>
                                  {
                                    item.status == 1? '申请': item.status == 2? '同意': item.status == 3? '打回': item.status == 4? '同意':
                                    item.status == 5? '打回':  item.status == 6? '待处理': item.status == 7? '待处理': 
                                    item.status == 8? '待处理': item.status == 9? '同意': '打回'
                                  }
                                </span>

                                <span>{item.fix === 3? '': '/修正'}</span>
                              </div>
      
                              <div className="sign">
                                  <Image
                                      style={{display: item.sign?'':'none'}}
                                      width={120}
                                      src={imgUrl + item.sign}
                                  />
                              </div>
                            </li>
                          )
                        })
                      }

                     


                    </ul>


        

       




                    </div>




                    
      </Modal>    
                      
                                                                                            
      </div>
      </div>
    );
  }
}
