import React from "react";

import {Button, Table, Modal, Form, Input, message, Select, DatePicker} from "antd";

import './teamList.less';

import moment from 'moment';

import {formatTime} from '@/utils/util';

import WorkList from '../workList/workList';


const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 16},
  };


export default class TeamList extends React.Component {
  state = {
    status: 0,
    loading: false,
    tableData: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    requestParmas: {
        name: null,
        enterpriseId: 0,
        weigh: 0,
        type: 0,
        attendanceStatistics: 3
    },
    params : {
        lengthLimit: 10,
        offsetLimit: 0
    },

    teamAllList: [],
  } 
  FormSbmitRef = null

  static defaultProps = {
    id: null  
  }


 columns = [
    {
      title: '名称',
      dataIndex: 'name',
      align: 'center',
    },

    {
        title: '工种',
        dataIndex: 'workId',
        align: 'center',
        render: (_) => {
            let arr = ''
           let name = _.map(item => {
               let item3 = []
                this.state.teamAllList.map(item2 => {
                    if(item == item2.id) {
                        arr += item2.name + ', '
                    }
                })
           })

            return (
                <p>{arr}</p>
            )
        }
      },
      {
          title: '出勤统计',
          dataIndex: 'attendanceStatistics',
          align: 'center',
          render: (_) => {
            return (
              <p>{_==2? '不统计': '统计'}</p>
            )
          }
      },

    {
        title: '权重',
        dataIndex: 'weigh',
        align: 'center',
    },

    {
      title: '编辑时间',
      dataIndex: 'editTime',
      align: 'center',
      width: 250,
      render: (_) => {
        let date = null
        if(_ != 0) {
          date =formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')  
        } else {
          date = 0
        }
        return (
          <p>
            {
              date
            }
          </p>
        )
      }
    },

    {
      title: '操作',
      dataIndex: 'address',
      align: 'center',
      width: 150,
      render:(_,item) => {
        return (
          <div className="operation">
              <p onClick={() => this.clickFun(item)}>编辑</p>
              <p className="red" onClick={() => this.clickDetail(item)}>删除</p>
          </div>
        )
      }
    },
  ]



  componentDidMount() {
    // this.getList(this.props.id)
    // this.getTeamAll()

  }


  handleTableChange(pagination) {

    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize

    this.setState({
      params: this.state.params,
      pagination: pagination
    })


    // 执行更新列表
    // this.getList(this.props.id)
  }

  
  clickDetail(item) {
    item.del = 2
    let params = {
      attendanceStatistics: 3,
      del: 2,
      enterpriseId: 111,
      id: 422,
      name: "测试333",
      projectId: 1,
      type: 2,
      weigh: 0,
      workId: null,
    }

    for(let i in params) {
      params[i] = item[i]
    }

    this.saveList(params, '删除成功')
  }


  clickFun(item) {


    this.setState({
        isModalVisible: true
    })


    for(let i in this.state.requestParmas) {
      this.state.requestParmas[i] = item[i]
    }

    
    console.log(this.state.requestParmas)

    this.state.requestParmas.id = item.id

    this.setState({
      requestParmas: this.state.requestParmas
    })

    
    this.clierFormSbmitRef()

  }


  clierFormSbmitRef() {
    if(!this.FormSbmitRef) {
        setTimeout(() => {
          
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
  }



  // getList(id) {
  //   this.setState({
  //       loading: true
  //   })
  //   let currentId = []
  //   if(id) {
  //     currentId = id.split(',');
  //   }
    

  //   React.$axios.post('teamAll', {id:currentId}).then(res => {
  //       // this.state.pagination.total = res.data.countList
    
  //       res.data.teamAll.map(item => {
  //           if(item.workId) {
  //               item.workId = item.workId.split(',')
  //           } else {
  //               item.workId = []
  //           }
  //       })

  //       this.setState({
  //           tableData: res.data.teamAll,
  //           loading: false
  //       })

  //   })
  // }

  // 获取工种
  // getTeamAll() {
  //   React.$axios.post('workAll').then(res => {
  //       this.setState({
  //           teamAllList:res.data.workAll
  //       })
  //   })
  // }


  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  handleOk() {
    // console.log(this.state.requestParmas)  
    
    this.FormSbmitRef.submit()
  }

  // 点击新增
  add(id) {
      for(let i in this.state.requestParmas) {
        this.state.requestParmas[i] = null
      }
      this.state.requestParmas.weigh = 0
      this.state.requestParmas.attendanceStatistics = 3
      this.state.requestParmas.enterpriseId = id;
      // this.state.requestParmas.workId = []
      delete this.state.requestParmas.id


      this.setState({
        isModalVisible: true,
        requestParmas: this.state.requestParmas
      })

      this.clierFormSbmitRef()
  }

  setInput(name, { target: { value } }) {

    this.state.requestParmas[name] = value
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  // 选择下拉数据
  handleChange(value, item) {
    this.state.requestParmas[value] = item
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }
  

  handleFinger(item) {
    this.state.requestParmas.img = [...this.state.requestParmas.img, ...item.data.key]
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  // 
  onFinish(value) {
  

    for(let i in value) {
      this.state.requestParmas[i] = value[i]
    }

    this.saveList(this.state.requestParmas)
   
  }

  saveList(params ,type) {
      let message2 = '保存成功'
        if(type) {
        message2 = type
        }

        console.log(params);

        React.$axios.post('editSaveTeam', params).then(res => {
        if(res.code == 20000) {
            message.success(message2)

            this.setState({
            isModalVisible: false  
            })

            this.props.saveStype();
            // this.getList()
        }

        })
    }


  


  onChangeSelect() {
    
  }



  render() {
    return (
      <div className="TeamList">
            


            {/* <div className="warp1">
              <h1>部门管理 <span>（当前公司：{this.props.CurrentTitleName}）</span></h1>
                <div className="head">
                    <button onClick={this.add.bind(this)}>新增</button>
                </div>

                <div className="table">
                <Table  align="center" bordered rowClassName="editable-row"
                
                pagination={false}
                expandable={{ 
                  expandedRowRender: record => {
                    return (
                      <WorkList id={record.workId} CurrentTitleName={record.name}></WorkList>
                    )
                  },
                }}

                loading={this.state.loading} rowKey={record => record.id}
                columns={this.columns}
                dataSource={this.state.tableData}  />
                </div>
                
            </div> */}


          <Modal title="添加" width="900px" className="ProgressNodeModal" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                        <Form
                            layout="vertical"
                            onFinish={this.onFinish.bind(this)}
                            ref={(el) => this.FormSbmitRef = el}
                            {...layout}
                            initialValues = {this.state.requestParmas}
                        >   
                            <div className="flex setFlex">
                                <div>
                                    <Form.Item   label="名称" name="name"   rules={[{ required: true }]}>
                                    <Input style={{ width:350,  }}  placeholder="请输入名称" />
                                    </Form.Item>

                                    <Form.Item label="权重"   name="weigh" rules={[{ required: true }]} >
                                    <Input style={{ width:350,  }}  placeholder="请输入权重" />
                                    </Form.Item>
                                </div>
                                
                                <div>
                                
                                    {/* <Form.Item label="工种" name="workId"  rules={[{ required: true }]}>
                                     <Select
                                            showSearch
                                            style={{ width:350,  }}
                                            placeholder="请选择工种"
                                            mode="multiple"
                                            optionFilterProp="children"
                                            >
                                                {
                                                    this.state.teamAllList.map(item => {
                                                        return (
                                                            <Option value={item.id.toString()}>{item.name}</Option>
                                                        )
                                                    })
                                                }
                                        </Select>

                                    </Form.Item> */}


                                    <Form.Item label="人员概览-重点人员出勤" name="attendanceStatistics" >
                                     <Select
                                            style={{ width:350,  }}
                                            placeholder="请选择出勤统计">
                        
                                                <Option value={2}>不统计</Option>
                                                <Option value={3}>统计</Option>
                                        </Select>

                                    </Form.Item>        

                                    <Form.Item label="类型" name="type" >
                                     <Select
                                            style={{ width:350,  }}
                                            placeholder="请选择类型">
                        
                                                <Option value={2}>其他</Option>
                                                <Option value={3}>安全</Option>
                                                <Option value={4}>质量</Option>
                                                <Option value={5}>实验员</Option>
                                                <Option value={6}>调度室</Option>
                                                <Option value={7}>监理部</Option>
                                                <Option value={8}>工区</Option>
                                        </Select>

                                    </Form.Item>         


                                </div>
                            </div>
                        </Form>

        </Modal> 

      </div>
    );
  }
}
