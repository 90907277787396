import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Style from './LeaveRequestForm.module.less';
import { DatePicker,Input,Select,Button, Table, Popconfirm, Image, message,  Modal, Form } from "antd";
import { SearchOutlined, PlusOutlined} from '@ant-design/icons';
import { Uploadhh } from "@/components/upload/upload";
import SignCanvas from '@/components/signCanvas/signCanvas';
import {debounce} from '@/utils/util';
const {Option} =  Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const imageUrl = process.env.REACT_APP_IMG_URL;
let signCanvas = null;
let FormSbmitRef = null;
function LeaveRequestForm() {

 
    const [visible, setVisible] = useState(false);
    const [approval, setApproval] = useState(false);
    const [userList, setUserList] = useState([]);
    const [leaderList, setLeaderList] = useState([]);
    const [btnActive, setBtnActive] = useState(0);
    


    const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Age', dataIndex: 'age', key: 'age' },
        { title: 'Address', dataIndex: 'address', key: 'address' },
        { title: '操作', dataIndex: '', key: 'x',
        width: 200,
        align: 'center',
        render: (_, item) => {
        return (<div className='tableCa'>
        <div style={{cursor:'pointer', color: '#63ABFF'}} >编辑</div> 
        <div style={{cursor:'pointer'}} >
            
            <Popconfirm
                title="是否确认删除?"
                // onConfirm={this.toDel.bind(this, item.id)}
                okText="Yes"
                cancelText="No"
            >
                <p style={{color: '#ff0000 ', cursor:'pointer'}}>删除</p>
            </Popconfirm>
        </div> 
        <div style={{cursor:'pointer', color: '#FAA646'}} >查看</div> 
        <div style={{cursor:'pointer', color: '#00B050'}}  onClick={() => {toApproval(item)}}>审批</div> 
    </div>)
    
    } },
    ];


    const data = [
    { key: 1, name: 'John Brown', age: 32, address: 'New York No. 1 Lake Park', description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.' },
    { key: 2, name: 'Jim Green', age: 42, address: 'London No. 1 Lake Park', description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.' },
    { key: 3, name: 'Joe Black', age: 32, address: 'Sidney No. 1 Lake Park', description: 'My name is Joe Black, I am 32 years old, living in Sidney No. 1 Lake Park.' },
    ];


    // 去审批
    const toApproval = (item) => {

        console.log(signCanvas);

        if(signCanvas) {
            signCanvas.clearSignature();
        }
        setApproval(true);
    }

    // 去查询用户
    const toSerchUser = (value) => {
        
       if(value === '') {
        setUserList([]);
       } else {
        React.$axios.post('userList', {keyword: value, export: 2}).then(res => {
            setUserList(res.data.list);
        })
       }
    }
    // 去查询领导
    const toSerchLeader = (value) => {
        if(value === '') {
        setUserList([]);
        } else {
        React.$axios.post('userList', {keyword: value, export: 2}).then(res => {
            setLeaderList(res.data.list);
        })
        }
    }

    // 去提交
    const toSbmit = () => {

        if(signCanvas) {
            signCanvas.clearSignature();
        }


        // 提交
        FormSbmitRef.submit()
        
    }

    // 清除form 数据
    const clierFormSbmitRef = () => {
        if(FormSbmitRef) {
            setTimeout(() => {
            FormSbmitRef.resetFields()
            }, 500)
        } else {
        FormSbmitRef.resetFields()
        }
    }


    return (
        <div className={Style.LeaveRequestForm}>

                <div className="input">
                    <ul >
                        <li><span>标题：</span> 
                        <Input   style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                        
                        </li>
                        
                        <li>
                            <span>类型：</span>
                            <Select
                                placeholder="请选择"
                                allowClear={true}
                                style={{ width: 200, height:30, marginRight:20,  }} 
                                   
                                >
                                <Option value={2} key='2'>普通新闻</Option>
                                <Option value={3} key='3'>党建新闻</Option>
                            </Select>
                        </li>
                        

                            <Button  className="btn" >
                                <SearchOutlined  className="icon"/>
                            </Button>
                    </ul>

                    <div class="allBtn">
                   
  
                   <Button  className="bt" type="primary" onClick={() => {
                    setVisible(true)
                   }} icon={<PlusOutlined />}>
                       新建
                   </Button>
                   </div>
                </div>



            <Table
                columns={columns}
                dataSource={data}
            />

{/* onCancel={handleCancel} */}
            <Modal  title="添加"  className={Style.LeaveRequestFormModal} width="60%" onOk={toSbmit}  visible={visible}  onCancel={() => {setVisible(false)}} >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 18 }}
                        layout="horizontal"

                        
                        // onValuesChange={onFormLayoutChange}
                        // disabled={componentDisabled}
                    >

                    <ul>
                        <li>
                        <Form.Item label="请假人">
                            <Select
                                showSearch
                                // value={value}
                                defaultActiveFirstOption={false}
                                showArrow={true}
                                allowClear={true}
                                placeholder="请选择请假人"
                                filterOption={false}
                                onSearch={debounce(toSerchUser, 1000)}
                                onChange={(value) => { // 选中
                                    
                                }}
                                notFoundContent={null}
                            >


                                {
                                    userList.map(item => {
                                        return (
                                            <Option value={item.id} key={item.id}>
                                                <div className="textImg" style={{display: 'flex', alignItems: 'center'}}>
                                                <img src={imageUrl + item.uiAvatarKey} style={{height: 80, marginRight: 20}} alt="" />

                                                {item.uicName}
                                                </div>
                                            </Option>
                                        )
                                    })
                                }

      
                            </Select>
                        </Form.Item>
                        </li>


                        <li>
                        <Form.Item label="联系电话">
                            <Input></Input>
                        </Form.Item>
                        </li>


                        <li>
                        <Form.Item label="假期类型">
                            <Select
                                placeholder="请选择假期类型"
                                allowClear
                                >
                                <Option value="0">公休</Option>
                                <Option value="1">探亲</Option>
                                <Option value="2">事假</Option>
                                <Option value="3">病假</Option>
                            </Select>
                        </Form.Item>
                        </li>

                        <li>
                        <Form.Item label="交通工具">
                            <Select
                                placeholder="请选择假期类型"
                                allowClear
                                >
                                <Option value="0">公交</Option>
                                <Option value="1">高铁</Option>
                                <Option value="2">飞机</Option>
                                <Option value="3">自驾</Option>
                                <Option value="4">火车</Option>
                            </Select>
                        </Form.Item>
                        </li>

                        <li>
                        <Form.Item label="目的地">
                            <Input></Input>
                        </Form.Item>
                        </li>

                        <li>
                        <Form.Item label="假期时间">
                            <RangePicker />
                        </Form.Item>
                        </li>

                        <li>
                        <Form.Item label="部门负责人">
                            <Select
                                    showSearch
                                    // value={value}
                                    defaultActiveFirstOption={false}
                                    showArrow={true}
                                    allowClear={true}
                                    placeholder="请选择部门负责人"
                                    filterOption={false}
                                    onSearch={debounce(toSerchLeader, 1000)}
                                    onChange={(value) => { // 选中

                                    }}
                                    notFoundContent={null}
                                >
                                
                                {
                                    leaderList.map(item => {
                                        return (
                                            <Option value={item.id} key={item.id}>
                                                <div className="textImg" style={{display: 'flex', alignItems: 'center'}}>
                                                <img src={imageUrl + item.uiAvatarKey} style={{height: 80, marginRight: 20}} alt="" />

                                                {item.uicName}
                                                </div>
                                            </Option>
                                        )
                                    })
                                }

                                
                            </Select>
                        </Form.Item>
                        </li>
                    </ul>

                    <Form.Item label="请假事由" labelCol={{ span: 2 }} wrapperCol={{ span: 21 }}>
                        <TextArea></TextArea>
                    </Form.Item>
                               

                <Form.Item label="图片" labelCol={{ span: 2 }} wrapperCol={{ span: 21 }}>
                    <Uploadhh   data={{type: 5}}  width={150} height={120} handleClick={(e) => {console.log(e)}} />
                </Form.Item>



                </Form>

                        
              
            
            
            
            </Modal>



            <Modal title="审批" width="50%"   className={Style.Casting + " BlankingListModal"}  visible={approval}  onOk={() => {setApproval(false)}} onCancel={() => {setApproval(false)}}>
                   <div className="box">
                   

                   <h1>天数：</h1>
                   <ul className="inputLi">
         

                      <li>
                          <span>请假人：</span> <div> </div>
                      </li>
                      <li>
                          <span>联系电话：</span> <div></div>
                      </li>
                      <li>
                          <span>假期类型：</span> <div></div>
                      </li>
                      <li>
                          <span>交通工具：</span> <div></div>
                      </li>
                      <li>
                          <span>目的地：</span> <div></div>
                      </li>
                      <li>
                          <span>假期时间：</span> <div></div>
                      </li>

                      <li>
                          <span>部门负责人：</span> <div></div>
                      </li> 

                      <li>
                          <span>请假事由：</span> <div></div>
                      </li> 


                      <li>
                          <span>图片：</span><div>
                          {/* {
                            this.state.detailItem.img.map(((item, index) => {
                              return (
                                <div key={index} style={{marginRight:10}}>
                                <Image
                                      style={{display: item?'':'none'}}
                                      
                                      width={120}
                                      height={80}
                                      src={imgUrl + item}
                                  /> 
                                </div>
                              )
                            }))
                          } */}
                          </div>
                      </li>

   
                    </ul> 
    
                    <h1 style={{marginTop: 20}}>审批/处理过程</h1>
                    <ul className="user">
                      {/* {
                        this.state.listPouringPermitList.map(item => {
                          return (
                            <li key={item.id + 'index'}>
                              <div className="name">
                                <img src={require('@/assets/img/cloudOpenning/face.png').default} alt="" />
                                {item.nameUser}</div>
                            
                              <div className="des">
                                
                                <span className="nameWidth" style={{marginRight: 30}}>
                                {
                                     item.status == 1? '技术员': item.status == 2? '质检员': item.status == 3? '质检员': item.status == 4? '监理工程师':
                                     item.status == 5? '监理工程师':  item.status == 6? '技术员': item.status == 7? '质检员': 
                                     item.status == 8? '监理工程师': item.status == 9? '监理': '监理'
                                  }
                                </span>
                                
                                <span className={'sign cololr' + item.status}>
                                  {
                                    item.status == 1? '申请': item.status == 2? '同意': item.status == 3? '打回': item.status == 4? '同意':
                                    item.status == 5? '打回':  item.status == 6? '待处理': item.status == 7? '待处理': 
                                    item.status == 8? '待处理': item.status == 9? '同意': '打回'
                                  }
                                </span>

                                <span>{item.fix === 3? '': '/修正'}</span>
                              </div>
      
                              <div className="sign">
                                  <Image
                                      style={{display: item.sign?'':'none'}}
                                      width={120}
                                      src={imgUrl + item.sign}
                                  />
                              </div>
                            </li>
                          )
                        })
                      } */}

                     


                    </ul>

                  


                   

                    <div className="SignCanvas">
                        <h2>签名</h2>

                        <SignCanvas 
                        ref={(_) => {signCanvas = _}}
                            id="sin1" width={350} height={150}>
                        
                        </SignCanvas>   
                    </div>   

                      <div className="setBtn">
                        <button className={btnActive === 0? 'active': ''} onClick={() => {
                            setBtnActive(0);
                        }}>同意</button>        


                        <button className={btnActive === 1? 'activeNo': ''}  onClick={() => {
                            setBtnActive(1);
                        }}>打回</button> 
                    </div>                 
                                

          

       




                    </div>




                    
      </Modal>    



        </div>
    );

}





export default withRouter(LeaveRequestForm);


