


import React, { useState, useEffect, useReducer } from "react";
import { withRouter } from 'react-router-dom';
import {  Checkbox, Button, message  } from 'antd';
import Style from './backstage.module.less';

let sbmitList = [];
let infoParams = {};
function Invoice() {
    const [, forceRerender] = useReducer(x => x + 1, 0);
    const [menuList, setMenuList] = useState([]);
    
    
    useEffect(() => {
        getList();
    }, []) 

    const getList = () => {
        React.$axios.post('infoProject',).then(res => {
            
            const pcMenu = JSON.parse(res.data.infoProject[0].enterpriseBiMenu);
            infoParams = res.data.infoProject[0];
            console.log(pcMenu);
            setMenuList(pcMenu);
            sbmitList = JSON.parse(JSON.stringify(pcMenu));
        })
    }

    const toChangeItem = (index, e) => {
        e.stopPropagation();
        console.log(index);
        let item =  getItem(index);
        item.status = item.status?false:true;
        setMenuList(menuList);
        forceRerender();
    }



    const getCheckbox = (index, e) => {
        e.stopPropagation();
        let item =  getItem(index);
        item.show = !item.show;

        let subItem = getSubItem(index); 
        subItem.show = item.show;
    }

    const getSubItem = (index) => {
        let arr = index.split('-');
        let item = {};
        item = sbmitList[arr[0]];
        if(arr[1] != undefined) {
            item = item.children[arr[1]]
        }
        if(arr[2] != undefined) {
            item = item.childBtn[arr[2]]
        }
        return item;
    }

    const getItem = (index) => {
        let arr = index.split('-');
        let item = {};
        item = menuList[arr[0]];
        if(arr[1] != undefined) {
            item = item.children[arr[1]]
        }
        if(arr[2] != undefined) {
            item = item.childBtn[arr[2]]
        }
        return item;
    } 
    
    // 去提交
    const toSbmit = () => {
        React.$axios.post('projectEditSave', {enterpriseBiMenu: JSON.stringify(sbmitList)}).then(res => {
            if(res.code === 20000) {
                message.success('保存成功');
            }
        })
    }

    const  renderMenu = (menuList, cuurentIndex = 0, keyIndex = 0) => {
        return menuList.map((item, index) => {
          if (item.children) {
            
            return (
            //   <SubMenu key={item.path} icon={<img src={item.icon}></img>} title={<div className="setWidth">{item.name} <Checkbox checked={item.show}></Checkbox> </div>}>
            //     {renderMenu(item.children)}
            //   </SubMenu>
            <div key={item.path} className="li" style={{paddingLeft: 25 + 'px'}} onClick={(e) => toChangeItem(`${index}`, e)}>
                
                <div className="setWidth" ><div>
                    
                      {item.name}</div> <span style={{right: '-2px'}}><Checkbox onChange={(e) => getCheckbox(`${index}`,e)} checked={item.show}></Checkbox></span> </div>

                <div className="UseConfigParents" style={{display:item.status?'block': 'none'}}>
                    {renderMenu(item.children, cuurentIndex + 1, `${index}`)}
                </div>
            </div>
            );
          }

        
          return true &&
            <div className="li" key={item.path} style={{paddingLeft: 25 + 'px'}} onClick={(e) => toChangeItem(keyIndex + '-' + index, e)}>
                <div className="setWidth">{item.name} <span><Checkbox onChange={(e) => getCheckbox(keyIndex + '-' + index, e)} checked={item.show}></Checkbox></span> </div>
            </div>
            // <div key={item.path} title={<div className="setWidth">{item.name} <Checkbox checked={item.show}></Checkbox> </div>}>
            //     <p>{item.name}</p>
            // </Menu.Item>;
        });
      };

    return (
        <div className={Style.Backstage}>
            <div className="UseConfigTitle">
                <p>功能板块</p>
                <p>板块显示</p>
            </div>
            <div className='pos'>
                <div className="useConfigUl">
                    {/* {renderMenu(menuList)} */}
                    {
                        menuList.map((item, index) => {
                            return (
                                <div key={item.path} className="li" style={{paddingLeft: 25 + 'px'}} onClick={(e) => toChangeItem(`${index}`, e)}>
                
                                <div className="setWidth" ><div>
                                    
                                    {item.name}</div> <span style={{right: '-2px'}}><Checkbox onChange={(e) => getCheckbox(`${index}`,e)} checked={item.show}></Checkbox></span> </div>

                                <div className="UseConfigParents" style={{display:item.status?'block': 'none'}}>

                                    {
                                        item.children && item.children.map((item2, index2) => {
                                            return (
                                                <div className="li" key={item2.path} style={{paddingLeft: 25 + 'px'}} onClick={(e) => toChangeItem(index + '-' + index2, e)}>
                                                    <div className="setWidth">{item2.name} <span><Checkbox onChange={(e) => getCheckbox(index + '-' + index2, e)} checked={item2.show}></Checkbox></span> </div>
                                                </div>
                                            )
                                        })
                                    }
                                       

                                </div>
                            </div>
                                )
                        })
                    }
                    
                </div>
            </div>

            <p className="toSbmit">
            <Button  type='primary' onClick={() => toSbmit()}><span className="setPadding">提交</span></Button>
            </p>
            
        </div>
    )

}


export default withRouter(Invoice);