import axios from 'axios'
import { message } from 'antd';

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // url = base url + request url
  timeout: 20000, // request timeout
})


request.interceptors.request.use(
  config => {
    config.headers.accesstoken = '$2y$10$z.0Fz2z0r9XKJ5M3.TowhOEzBGjQndjo2hijvAZQmdLjztiJkbUZu'
    return config
  }
)


// response interceptor
request.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 20000) {
      return Promise.resolve(res)
    } else {
      message.error(res.message)
      return Promise.reject(res);
    }
  },
  error => {
    console.log('err' + error) // for debug
    message.error(error.message)
    return Promise.reject(error)
  }
)

export default request