import React from "react";

import {Button, Table, message, Image, Select, Modal, Form, Input, DatePicker} from "antd";
// 添加furon
import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import './authorizationLogList.less';

import { Uploadhh } from "@/components/upload/upload";

import {formatTime} from '@/utils/util'

import moment from 'moment';

const {Option} =  Select;

const { RangePicker } = DatePicker;



const layout = {
    labelCol: { span: 16},
};


export default class ProjectConfig extends React.Component {
  FormSbmitRef = null
  imageUrl = process.env.REACT_APP_IMG_URL
  state = {
    status: 0,
    loading: false,
    tableData: [],
    pagination: {
        current: 1,
        pageSize: 10,
        total: 200,
        onShowSizeChange: function(current, pageSize) {
            this.state.pagination.pageSize = pageSize;
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        onChange: function(current) {
            this.state.pagination.current = current
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        showTotal: function () {  //设置显示一共几条数据
            return '共 ' + this.state.pagination.total + ' 条'; 
        }.bind(this),
        showQuickJumper: true,
        },
    params : {
        lengthLimit: 10,
        offsetLimit: 0,
        deviceKey: null,
        keyword: null,
        logoDoorman: null,
        uiEnterpriseId: [],
        uiTeamId: [],
        uiWorkId: [],
        status: null,
    },
    isModalVisible: false,
    requestParmas: {},
    machineDeviceList: [],
    deviceInspectionTypeList: [],

    openStatus: false,

    enterpriseAllList: [],
    teamAllList: [],
    workAllList: [],

    doormanAllList: [],
    deviceAllList:[],

    newDataHH: [],
  } 
  


 columns = [
  {
    title: "序号",
    dataIndex: 'title',
    align: 'center',
    render: (_, item, index) => {
      return (
        <p>{index + 1}</p>
      )
    }
  },
    {
      title: '姓名',
      dataIndex: 'uicName',
      align: 'center',
    },
    {
      title: '进退场',
      dataIndex: 'uiEnterStatus',
      align: 'center',
      render: (_) => {
        return (
            <p>{_ == 2? '进场': '退场'}</p>
        )
      }  
    },
    {
      
      title: '手机号码',
      dataIndex: 'uPhone',
      align: 'center',
    },
    {
      
        title: '身份证号码',
        dataIndex: 'uicNumber',
        align: 'center',
    },
    {
        title: '公司',
        dataIndex: 'eName',
        align: 'center'
    },
    {
        title: '部门',
        dataIndex: 'tName',
        align: 'center'
    },
    {
        title: '岗位',
        dataIndex: 'towName',
        align: 'center'
    },
    {
        title: '设备名称',
        dataIndex: 'nameDevice',
        align: 'center',
    },
  
    {
        title: '设备进出',
        dataIndex: 'directionDevice',
        align: 'center',
        render: (_) => {
            return(
                <p>{_ == 1?'出': '进'}</p>
            )
        }
    },

    {
      title: '授权状态',
      dataIndex: 'status',
      align: 'center',

        render: (_) => {
            return (
                <p>{_ == 2? '授权中': _==3? '授权成功':'授权失败'}</p>
            )
        }
    },

    {
        title: '授权结果',
        dataIndex: 'resultMqtt',
        align: 'center'
    },
    {
      title: '编辑时间',
      dataIndex: 'editTime',
      align: 'center',
      render: (e) => {
        let da = formatTime(new Date(e * 1000), 'yyyy-MM-dd hh:mm:ss')
        if(!e) {
          da = '-'
        }
         
        return (
          <p>{da}</p>
        )
      }
    },
   
  ]


  handleTableChange(pagination) {
    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize
    this.setState({
      params: this.state.params,
      pagination: pagination
    })

    // // 执行更新列表
    this.getList()
  }


  

  componentDidMount() {

    this.getList()

    this.getDoormanAll() 
    this.getEnterpriseAll()

    this.getDeviceAll(' ')
  }


  // 获取门卫
  getDoormanAll() {
      React.$axios.post('doormanAll').then(res => {
        this.setState({
            doormanAllList: res.data.doormanAll
        })
      })
  }

   // 获取公司  
  getEnterpriseAll() {
    React.$axios.post('enterpriseAll').then(res => {
      this.setState({
        enterpriseAllList: res.data.enterpriseAll
      })
    })
   }

   getTeamAll(id) {

    let params = {
       id: id
     }
    React.$axios.post('teamAll', params).then(res => {
      this.setState({
        teamAllList: res.data.teamAll,
        workAllList: []
      })  
    })
  }

  // 获取工种
  getWorkAll(params) {
    React.$axios.post('workAll', {id: params}).then(res => {
      this.setState({
        workAllList: res.data.workAll
      })
    })
  }

  // 获取设备
  getDeviceAll(value, type) {
    React.$axios.post('deviceAll', {logoDoorman: value}).then(res => {
        if(type) {
            res.data.deviceAll.map(item => {
                let direction = item.direction == 1? '出场': '入场'
                item.name = item.name +  `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0` +  direction
            })

            this.setState({
                deviceAllListAll2: res.data.deviceAll
            })
        } else {
            this.setState({
                deviceAllList: res.data.deviceAll
            })
        }
    })
  }



  getPersonnelCategory(type) {
    switch(type) {
      case type == 2:
        return '一般作业人员'
      case type == 3:
        return '特种作业人员'
      case type == 4:
        return '项目管理人员'
      case type == 5:
        return '其它人员'
      default:
        return '其他'
    }
  }



  getList(type) {
    this.setState({
        loading: true
    })

    if(type) {
      this.state.params.offsetLimit = 0
      this.state.pagination.current = 1

      this.setState({
        params: this.state.params,
        pagination: this.state.pagination
      })
    }


    React.$axios.post('authorizationLogList', this.state.params).then(res => {
         this.state.pagination.total = res.data.countList
         

         this.setState({
          tableData: []
         })

         let timerout = setTimeout(() => {
          this.setState({
              tableData: res.data.list,
              loading: false,
          })
          clearTimeout(timerout)
         }, 50)
    })
  }


  clierFormSbmitRef() {

  }


  onChangeT(name, value) {
     if(name) {
        this.state.params[name] = value
        this.setState({
            params: this.state.params
        })
     } else {
         this.state.requestParmas.enterDeviceKey = value
         this.setState({requestParmas:this.state.requestParmas})
     }
    

}

  // 去查询
  toSerch() {
    this.getList(true)
  }


  handleBack(val) {
    this.state.requestParmas.img = val.data.key[0]

    this.setState({
        requestParmas: this.state.requestParmas
    })
    

  }


  setInput(name,value) {
    this.state.params[name] = value.target.value
  }

  setDrapDom() {
    if(this.state.openStatus == true) {
        return (
          <UpOutlined/>
        )
      } else {
        return (
          <DownOutlined />
        )
      }
  }

  drapHeight() {
    this.setState({
        openStatus: !this.state.openStatus
      })
  }


  onChangeEnterprise(value, item) {
    item = [item]
    

    this.state.params.uiEnterpriseId = value
    this.state.params.uiTeamId = []
    this.state.params.uiWorkId = []

    this.setState({
      params: this.state.params,
      teamAllList: [],
      workAllList: []
    })

    let arr = []
    item.map(item2 => {
      if(!item2.item.teamId) {

      } else {
        let teamId = item2.item.teamId.split(',')
        arr = [...arr, ...teamId]
      }
      
    })

     this.getTeamAll(arr)
  }


  onChangeTeam(value, item) {
    item = [item]
    let arr = []
    item.map(item2 => {
      if(!item2.item.workId) {

      } else {
        let workId = item2.item.workId.split(',')
       arr = [...arr, ...workId]
      }
      
    })

    this.state.params.uiTeamId = value
    this.state.params.uiWorkId = []

    this.setState({
      params: this.state.params,
      workAllList: []
    })

    this.getWorkAll(arr)
  }


  onChangeWorkAll(value) {
    this.state.params.uiWorkId = value
    this.state.params.deviceKey = null
    this.setState({
      params: this.state.params
    })
  }

  onChangeDoor(status,value) {
    if(status) { // 详情
        this.state.requestParmas.logoDoorman = value
        this.state.requestParmas.enterDeviceKey = null

        this.setState({
            requestParmas: this.state.requestParmas
        })

    } else { // 搜索条件
        this.state.params.logoDoorman = value
        this.state.params.deviceKey = null
        this.setState({
            params: this.state.params
        })
    }

    this.getDeviceAll(value, status)
  }


  onChangeSelect(name, value) {
    this.state.params.status = value
  }




  // 改变时间
  onChangeTime(value) {
    this.state.newDataHH = value
    
    this.state.params.showTimeStart = parseInt((+ value[0]._d) / 1000) 
    this.state.params.showTimeStop = parseInt((+value[1]._d) / 1000) 

    
    this.setState({
        newDataHH: this.state.newDataHH,
        params:this.state.params
    })    
  }




  render() {
    return (
      <div className="distinguishList">

        <div className="warp1">
                <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                    <li><span>公司:</span> 
                            <Select
                                    showSearch
                                    style={{ width: 200, marginRight:20, }}
                                    placeholder="请选择公司"
                                    optionFilterProp="children"
                                    onChange={this.onChangeEnterprise.bind(this)}
                                >
                                 {
                                   this.state.enterpriseAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }

                            </Select>
                        </li>
                        <li><span>部门:</span>
                            <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择部门"
                                    optionFilterProp="children"
                                    value = {this.state.params.uiTeamId}
                                    onChange={this.onChangeTeam.bind(this)}
                                >                         
                                {
                                   this.state.teamAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }

                                </Select> 
                        </li>

                        <li><span>岗位:</span>
                            <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择岗位"
                                    optionFilterProp="children"
                                    value = {this.state.params.uiWorkId}
                                    onChange={this.onChangeWorkAll.bind(this)}
                                >
                                {
                                   this.state.workAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }
                             
                                </Select>
                        </li>

                        <li><span>门卫:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeDoor.bind(this, false)}
                                >
                                  <Option value=' '>全部</Option>
                                    {
                                        this.state.doormanAllList.map(item => {
                                            return (
                                                <Option value={item.logo}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                        </li>

                        <li><span>设备:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    value = {this.state.params.deviceKey}
                                    onChange={this.onChangeT.bind(this, 'deviceKey')}
                                >
                                    {
                                        this.state.deviceAllList.map(item => {
                                            return (
                                                <Option value={item.deviceKey}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                        </li>   

                         <Button  className="btn" style={{display: this.state.openStatus?'none':''}} onClick={this.toSerch.bind(this)}>
                            <SearchOutlined className="icon"/>
                        </Button>       



                        <li><span>授权状态:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeSelect.bind(this, false)}
                                >
                                   <Option value={2} key='1'>授权中</Option>
                                   <Option value={3} key='2'>授权成功</Option>
                                   <Option value={4} key='3'>授权失败</Option>
                                </Select>
                        </li>

     
                        <li><span>进退场:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'enterStatus')}
                                >
                                    <Option value=" " key="0">全部</Option>    
                                    <Option value="2" key="1">进场</Option>
                                    <Option value="3" key="2">退场</Option>
                                </Select>
                        </li>

                      

                        <li><span>模糊查询:</span> 
                            <Input style={{ width: 200, height:30, marginRight:20, } } onChange={this.setInput.bind(this, 'keyword')}  placeholder="请输入姓名/身份证,手机号" />
                        </li>

                            <Button  className="btn" onClick={this.toSerch.bind(this)} style={{display: this.state.openStatus?'':'none'}}>
                                <SearchOutlined  className="icon"/>
                            </Button>

                          
                    </ul>

                    <p class="drapBottom" onClick={this.drapHeight.bind(this)}>
                        {
                          this.setDrapDom()
                        }
                    </p>
                </div>


                <div className="table">
                {/* <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id}  columns={this.columns} dataSource={this.state.tableData}  /> */}

                <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }}  columns={this.columns} dataSource={this.state.tableData}  />        


{/* bordered rowClassName="editable-row" pagination={this.state.pagination} */}
                </div>
            
            </div>



      </div>

    );
  }
}
