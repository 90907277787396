import React, { useState, useRef, useEffect } from "react";
// import { Image } from 'antd';

import { DatePicker,Input,Select,Button, Table, Modal, Form, Image, message} from "antd";


import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import { withRouter } from 'react-router-dom';

import {formatTime} from '@/utils/util';

import './userRecove.less';


const {Option} =  Select;

const { RangePicker } = DatePicker;

export function UserRecove(props) {

    const columns = [
        {
            title: '姓名',
            dataIndex: 'nameIdCard',
            key: 'nameIdCard',
            align: 'center' 
        },
        {
            title: '公司',
            dataIndex: 'nameEnterprise',
            key: 'nameEnterprise',
            align: 'center' 
        },
        {
            title: '部门',
            dataIndex: 'nameTeam',
            key: 'nameTeam',
            align: 'center' 
        },
        {
            title: '岗位',
            dataIndex: 'nameWork',
            key: 'nameWork',
            align: 'center' 
        },

        {
            title: '电话',
            dataIndex: 'phone',
            key: 'phone',
            align: 'center' 
        },
        {
            title: '错误信息',
            dataIndex: 'message',
            key: 'message',
            align: 'center' 
        },

        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (_) => {
                return (
                    <p>{_ == 2? '发送中':_==3?'成功':'失败'}</p>
                )
            }
        },
      ];

      let projectId = React.$getStorage('projectInfo').id

      const [list, setList] = useState([]);
      const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
      })

      const [serchParams, setSerchParams] = useState({
          status: null,
          keyword: null,
          timeStart: null,
          timeStart: null
      })
      

      const [loading, setLoading] = useState(false)

      useEffect(() => {
        pagination.current  = 1
        getList(true)
      }
      ,[props])


      function handleTableChange(json) {
        setPagination(json)
        getList();
      }

      function listDeatail(item) {
        console.log(getList)
      }



      function getList(type = false) {
        let params = {
            offsetLimit: (pagination.current - 1) * pagination.pageSize,
            lengthLimit: pagination.pageSize,
            logic:2,
            projectId: projectId,
            managementId: props.userId
        }

        for(let i in serchParams) {
            params[i] = serchParams[i]
        }


        setLoading(true)
        
        React.$axios.post('listEmergencyManagementUser', params).then(res => {
            
            setLoading(false)
            setpage(res)
            setList(res.data.list)
        })
      }


      function setpage(res) {
            pagination.current = pagination.current;
            pagination.pageSize = pagination.pageSize;
            pagination.total = res.data.countList;
            let json = JSON.parse(JSON.stringify(pagination)); 
            json.onShowSizeChange = function(current, pageSize) {
                pagination.pageSize = pageSize;
                let json = JSON.parse(JSON.stringify(pagination)); 
                setPagination(json)
                handleTableChange(json)
            }
            json.onChange = function(current) {
                pagination.current = current
                let json = JSON.parse(JSON.stringify(pagination)); 
                setPagination(json)
            handleTableChange(json)
            }
            json.showTotal = function () {  //设置显示一共几条数据
                return '共 ' + pagination.total + ' 条'; 
            }
            setPagination(json)
      }


      function onChangeT(val) {
        serchParams.status = val
        setSerchParams(serchParams)

      }

      function changeTime(val) {
          serchParams.timeStart = parseInt((+val[0]._d) / 1000)
          serchParams.timeStop = parseInt((+val[1]._d) / 1000)
          setSerchParams(serchParams)
      }

      function serchInput(val) {
        serchParams.keyword = val.target.value
        setSerchParams(serchParams)
      }

      function toSerch(val) {
        pagination.current  = 1
        getList(true)

      }



    return (
        <div  className="EmergencyManagement">
            <div className="input">
                   <ul className='removeOpen'>     {/* this.state.openStatus?'addOpen':'removeOpen' */}

                        
                        <li><span>状态:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20, marginBottom: 20  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={onChangeT}
                                >
                                    <Option value=" ">全部</Option>
                                    <Option value="2">发送中</Option>
                                    <Option value="3">成功</Option>
                                    <Option value="4">失败</Option>

                                </Select>
                            </li>
                            
                            {/* onChange={this.serchParamsFun.bind(this, 'keyword')} */}
                        <li><span>关键字:</span> <Input  onChange={serchInput}  style={{ width: 200, height:30, marginRight:20, marginBottom: 20 }} placeholder="模糊搜索" /></li>
                        {/* onClick={this.toSerch.bind(this)} */}
                            <Button  className="btn" onClick={toSerch}> 
                                <SearchOutlined   className="icon"/>
                            </Button>

                          
                    </ul>
                </div>



                <Table  align="center" bordered rowClassName="editable-row" pagination={pagination} 
                    loading={loading} rowKey={record => record.id}   columns={columns} dataSource={list}  />               

        </div>
    )



}


// export default withRouter(UserRecove);