import React from "react";
import { withRouter } from 'react-router-dom';
import EZUIKit from "ezuikit-js";
import './video.less';


export const VideoComponentHH = (
    class VideoComponentHH extends React.Component {
        player2 = null // 萤石云视频对象
        timeout= null 
        playerDdaHua = null  // 大华视频对象
        static defaultProps = {
            videoDetail: Object,
            width: '100%',
            height: '100%',
            index: 0,
            clickFun: null,
            show: true,
            type: null
          }
        

          componentDidMount() {

     
           if(this.props.videoDetail.deviceType == 4) {
            this.playVideoDaHua()
           } else {
            this.playVideo(this.props.videoDetail)
           }

           
            // console.log(window.ImouPlayer)

            
           // this. playVideoDaHua()
          }

          componentWillUnmount() {
            if(this.playerDdaHua) { 
              this.playerDdaHua.destroy()
              
            }

            try {
                this.player2.stop()
            } catch(e) {

            }
          }


          // 加载大华视频
         playVideoDaHua() {
            
          this.playerDdaHua = new window.ImouPlayer('#videoDetail' + this.props.index);

          let url = 'imou://open.lechange.com/'+ this.props.videoDetail.deviceSerial +'/'+ this.props.videoDetail.channelNo +'/1?streamId=1'
          if(this.props.type) { // 高清
            url = 'imou://open.lechange.com/'+ this.props.videoDetail.deviceSerial +'/'+ this.props.videoDetail.channelNo +'/1?streamId=0'
          }


          this.playerDdaHua.setup({
            src: [
              {
                url: url, // url拼接说明请见：1.3 轻应用播放地址说明
                kitToken: this.props.videoDetail.kitToken, // 播放Token，通过接口getKitToken获取，具体请见：1.4 getKitToken接口协议说明
              }
            ], // 播放地址
            width: this.props.width, // 播放器宽度
            height: this.props.height, // 播放器高度
            poster: '', // 封面图url
            autoplay: true, // 是否自动播放
            controls: false, // 是否展示控制栏
          });

          this.playerDdaHua.play();

         }
         
         
         




          clickVideo() {
              if(this.props.clickFun) {
                this.props.clickFun(this.props.videoDetail)
              }
          }

          handleOk() {

          }


        playVideo(videoDetail) {
            let url = `ezopen://open.ys7.com/${videoDetail.deviceSerial}/${videoDetail.channelNo}.live`
            if(this.props.type) {
                url = `ezopen://open.ys7.com/${videoDetail.deviceSerial}/${videoDetail.channelNo}.hd.live`
            } 

            
        
              this.player2 = new EZUIKit.EZUIKitPlayer({
                autoplay: true,
                id: 'videoDetail' + this.props.index,
                accessToken: videoDetail.accessToken,
                url: url,
                template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                // 视频上方头部控件
                //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                //plugin: ['talk'],                       // 加载插件，talk-对讲
                // 视频下方底部控件
                // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                // openSoundCallBack: data => console.log("开启声音回调", data),
                // closeSoundCallBack: data => console.log("关闭声音回调", data),
                // startSaveCallBack: data => console.log("开始录像回调", data),
                // stopSaveCallBack: data => console.log("录像回调", data),
                // capturePictureCallBack: data => console.log("截图成功回调", data),
                // fullScreenCallBack: data => console.log("全屏回调", data),
                // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                // width: 612,
                // height: 282,
              });

            this.player2.play();
        
          }


        render() {
            return (
                <div className = "VideoComponentHH">
                    <span style={{display: this.props.show? '': 'none'}}  onClick={this.clickVideo.bind(this)}></span>
                    <div  id={'videoDetail' + this.props.index} style={{width: this.props.width, height: this.props.height}}>

                    </div>
                </div>
            )
        }
    }
)

