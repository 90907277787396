import React from "react";

import { DatePicker,Input,Select,Button, Table, Modal, message, Form} from "antd";


import {formatTime} from '@/utils/util';

import {VerticalAlignTopOutlined, SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import { Uploadhh } from "@/components/upload/upload";

import moment from 'moment';

const {Option} =  Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

const currentDate = new Date()
const setDefindDate = currentDate.getFullYear() + '/' + (currentDate.getMonth() + 1) + '/' + currentDate.getDate()

// export default class ConstructionProgress extends React.Component {
export const Grotto = (
    class Grotto extends React.Component {
      loadExcelRef=''
      FormObject = null
      state = {
        // 表格分页属性
        pagination: {
          current: 1,
          pageSize: 10,
          total: 200,
          onShowSizeChange: function(current, pageSize) {
              this.state.pagination.pageSize = pageSize;
              this.handleTableChange(this.state.pagination)
          }.bind(this),
          onChange: function(current) {
              this.state.pagination.current = current
              this.handleTableChange(this.state.pagination)
          }.bind(this),
          showTotal: function () {  //设置显示一共几条数据
              return '共 ' + this.state.pagination.total + ' 条'; 
          }.bind(this),
          showQuickJumper: true,
          },

    loading: false,

    tableData: [
        
    ],

  

    enterpriseAllList:[],
    teamAllList: [],
    workAllList: [],
    ExcelName: null,
    params: {
      lengthLimit: 10,
      offsetLimit: 0,
      projectId: null,
      logic: 2,
      timeStart: null,
      timeEnd: null,
      type: 3,
      name: null
    },

  
    jsonFieldsList: {
      '0_姓名': 'uicName',
      '1_身份证号码':{
        field: 'idCard',
            callback: value => {
                return '&nbsp;' + value
                // return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
            }
        },
      "2_发放时间": {
        field: 'time',
            callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
            }
        },
      "3_状态": {
        field: 'status',
            callback: value => {
                return value==2?'已发送':'发放异常'
            }
        },
      "4_基本工资":"basic",
      "5_实际工资": 'real',
      "6_奖罚工资": 'prizePunish',
      "7_出勤天数": "d",
      "8_备注": "remark",
    },

    openStatus: false,

    isModalVisible: false,
    isModalVisible2: false,

    uploadParams: {
        type: '3',
        timeStart: null,
        timeEnd: null
    },

    videoIndex: {
      pid: null,
      name: null,
      total: null,
      excavation: null,
      sideArch: null,
      bottomPlate: null,
      remark: null,
      time: null,
      id: null
    },
    prentList: [],

  }


  columns = [
      {
        title: '洞室名称',
        dataIndex: 'namePid',
        align: 'center'
      },
      {
        title: '分项工程',
        dataIndex: 'name',
        align: 'center'
      },

      {
        title: '总长度',
        dataIndex: 'total',
        align: 'center'
      },
      {
        title: '剩余长度',
        dataIndex: 'remaining',
        align: 'center'
      },
      {
        title: '累计开挖',
        dataIndex: 'excavation',
        align: 'center',
        colSpan: 2,
        render: (_) => {
          return (
            <p>{_}m³</p>
          )
        }
      },
      {
        title: '累计开挖(%)',
        dataIndex: 'excavationRate',
        align: 'center',
        colSpan: 0,
        
        render:(_) => {
          if(_ >= 100) {
            return (
              <div className="greenBackgreen">{_}%</div>
            )
          } else {
            return (
              <p>{_}%</p>
            )
          }
        }
      },
      
      {
        title: '累计衬砌边顶拱',
        dataIndex: 'sideArch',
        align: 'center',
        colSpan: 2,
        render: (_) => {
          return (
            <p>{_}m³</p>
          )
        }
      },
      {
        title: '累计衬砌边顶拱(%)',
        dataIndex: 'sideArchRate',
        colSpan: 0,
        align: 'center',
        render: (_) => {
          return (
            <p>{_}%</p>
          )
        }
      },
      {
        title: '累计衬砌底板',
        dataIndex: 'bottomPlate',
        align: 'center',
        colSpan: 2,
        render: (_) => {
          return (
            <p>{_}m³</p>
          )
        }
      },
      {
        title: '累计衬砌底板(%)',
        dataIndex: 'bottomPlateRate',
        align: 'center',
        colSpan: 0,
        render: (_) => {
          return (
            <p>{_}%</p>
          )
        }
      },

  
      {
        title: '时间',
        dataIndex: 'timeStart',
        align: 'center',
        render: (_, item) => {
          return (
            <div>
              <span>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}</span> ~
              <span>{formatTime(new Date(item.timeEnd * 1000), 'yyyy-MM-dd')}</span>
            </div>
          )
        }
      }, 

      {
          title: '备注',
          dataIndex: 'remark',
          align: 'center',
          width: 300
        },
      
      {
        title: '操作',
        dataIndex: 'address',
        align: 'center',
        width: 100,
        render:(_,item) => {
          return (
            <div className="operation">
                <p onClick={this.editPerson.bind(this, item)}>编辑</p>  
                <p className="read" onClick={this.deletePerson.bind(this, item)}>删除</p>  
            </div>
          )
        }
      },
    ]


    editPerson(item) {
      for(let i in this.state.videoIndex) {
        this.state.videoIndex[i] = item[i]
      }
     
      this.state.videoIndex.time = [moment(new Date(item.timeStart * 1000), dateFormat), moment(new Date(item.timeEnd * 1000), dateFormat)];
     
      this.setState({
        isModalVisible2: true,
        videoIndex: this.state.videoIndex
      })

      if(this.FormObject) {
        this.FormObject.resetFields()
      }
    }

    // 删除
    deletePerson(item) {
      React.$axios.post('saveConstructionProgress', {type: 3 ,del: 2, id: item.id}).then(res => {
        if(res.code == 20000) {
          message.success('删除成功')
          this.getList()
        }
      })
    }

    // 提交结果
    onFinish(e) {
      let params = {...e}

      params.timeStart = params.time[0]._d.getTime() / 1000;
      params.timeEnd = params.time[1]._d.getTime() / 1000;
      params.projectId = this.state.params.projectId
      params.type = 3

      delete params.time

      params.id = this.state.videoIndex.id

      React.$axios.post('saveConstructionProgress', params).then(res => {
        
        if(res.code == 20000) {
          message.success('保存成功')
          this.getList()
        }

      })

    }

    // 获取父级所有
    getParentList(projectId) {
      React.$axios.post('listConstructionProgress', {projectId: projectId, logic: 5, type: 3}).then(res => {
        this.setState({
          prentList: res.data.list
        })
      })
    }
    
    handleTableChange(pagination) {
        this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
        this.state.params.lengthLimit = pagination.pageSize
        this.setState({
          params: this.state.params,
          // pagination: pagination
        })
        
        // 执行更新列表
        this.getList()
      }

    componentDidMount() {
 
      this.state.uploadParams.timeStart = new Date(setDefindDate + ' 00:00:00').getTime() / 1000
      this.state.uploadParams.timeEnd = new Date(setDefindDate + ' 23:59:59').getTime() / 1000
    

    
      const projectId = React.$getStorage('projectInfo').id
      this.state.params.projectId = projectId
      this.getList()

      this.getParentList(projectId)
    }

    // 获取工资列表
    getList(type = false) {
      if(type) {
        this.state.params.offsetLimit = 0
        this.state.pagination.current = 1
        this.setState({
          params: this.state.params,
          pagination: this.state.pagination
        })
      }


      this.setState({ loading: true });
      React.$axios.post('listConstructionProgress', this.state.params).then(({ data }) => {
        
        this.state.pagination.total =  data.countList
        
        this.setState({
          loading: false,
          tableData: data.list,
          pagination: this.state.pagination
        })
  
      })
    }


    setInput(name, {target:{value}}) {
      this.state.params[name] = value
      this.setState({
        params: this.state.params
      })
    }


    onChangeT(name, value) {
      this.state.params[name] = value
      this.setState({
        params: this.state.params
      })
    }

    handleFront() {
      message.success('上传成功')  
      this.getList(true)
    }

    setDrapDom() {
      if(this.state.openStatus == true) {
          return (
            <UpOutlined/>
          )
        } else {
          return (
            <DownOutlined />
          )
        }
    }

    drapHeight() {
      this.setState({
        openStatus: !this.state.openStatus
      })
    }

    getTime(value) {
      if(!value) {
        this.state.params.timeStart = value
        this.state.params.timeEnd = value
      } else {
        this.state.params.timeStart =parseInt(+value[0]._d / 1000) 
        this.state.params.timeEnd = parseInt(+value[1]._d / 1000) 
      }

      this.setState({
        params: this.state.params
      })
    }


    serchClick() {
      this.getList(true)
    }


    fetchFun() {
      return new Promise((resolve, reject) => {

        let parmas = {}
        parmas = {...parmas, ...this.state.params}
        parmas.logic = 3


        React.$axios.post('userWageList', parmas).then(({ data }) => {
          resolve(data)
        })

      })
    
    }


    handleCancel() {
        this.setState({
            isModalVisible: false,
            isModalVisible2: false
        })
    }

    // 点击确定
    handleOk() {
      this.setState({
        isModalVisible2: false
      })

      this.FormObject.submit()

    }

    // 展示弹框
    showModeal() {
        this.setState({
            isModalVisible: true
        })
    }

    // 上传时间
    getUploadTime(value) {
        if(!value) {
            this.state.uploadParams.timeStart = value
            this.state.uploadParams.timeEnd = value
          } else {
            this.state.uploadParams.timeStart = (value[0]._d.getTime()) / 1000
            this.state.uploadParams.timeEnd = (value[1]._d.getTime()  + 86400000 - 60000) / 1000
          }
    
          this.setState({
            uploadParams: this.state.uploadParams
          })
    }


        render() {
            return (
              <div className="WageEntry ConstructionProgress">

                 <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>名称:</span> <Input onChange={this.setInput.bind(this, 'name')}   style={{ width: 200, height:30, marginRight:20,  }}  placeholder="洞室名称, 分项工程"  /></li>
     
                            <li><span>日期:</span>  
                              <RangePicker style={{ width: 300, height:30, marginRight:20,  }} onChange={this.getTime.bind(this)} />
                            </li>


                            <Button  className="btn"  onClick={this.serchClick.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>


                            <p className="setHeight"></p>     


                    </ul>


                    <div class="allBtn">
                       
                    <Button className="bt"  type="primary" icon={<BorderLeftOutlined />}>
                    <a href="https://project.1wx.cn/static/excel/import/洞室导入模板.xlsx" download>模板下载</a>
                    </Button>
                    <Button  className="bt" onClick={this.showModeal.bind(this)} type="primary" icon={<UploadOutlined />}>
                        导入
                    </Button>
                    </div>

                </div>



                <div className="table2">
  
                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }}  columns={this.columns} dataSource={this.state.tableData}  />
                </div>

                <Modal title="导入模板"
                 width="800px"
                 className="ConstructionProgressModal"
                 footer={[
                    <Button key="back" onClick={this.handleCancel.bind(this)}>
                        关闭
                    </Button>,
                 ]}
                 visible={this.state.isModalVisible} onOk={this.handleCancel.bind(this)} onCancel={this.handleCancel.bind(this)}>
                    <div className="warpConstruction">

                    <ul>
                        <li>
                        <span className="sapn">日期：</span>      
                        <RangePicker 
                        defaultValue={[moment(setDefindDate, dateFormat), moment(setDefindDate, dateFormat)]}
                        style={{ width: 300, height:30, marginRight:20,  }} onChange={this.getUploadTime.bind(this)} />
                        </li>
                    </ul>
                    

                    <Button  className="bt" onClick={this.showModeal.bind(this)} type="primary" icon={<UploadOutlined />}>
                        请选择导入的excel文件
                        <div className="setUPloda">   
                          <Uploadhh accept = '.xlsx'  path="importConstructionProgress" data={this.state.uploadParams}  pdfStatus={true}  name="file" width={190} height={30}   handleClick={this.handleFront.bind(this)} />
                        </div> 
                    </Button>
                    </div>
                </Modal> 

                <Modal title="操作" className="editModalTunnel" 
                
                visible={this.state.isModalVisible2}
                width="900px"
                onOk={this.handleOk.bind(this)}
                onCancel={this.handleCancel.bind(this)}>
                  
                <Form
    
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 20 }}
                  initialValues= {this.state.videoIndex}
                  onFinish={this.onFinish.bind(this)}
                  ref={(el) => {this.FormObject = el}}
                >
                  <ul>
                    <li>
                      <div className="width">
                        <Form.Item
                          label="洞室名称"
                          name="pid"
                        > 
                          <Select>
                            {
                              this.state.prentList.map((item, index) => {
                                return (
                                  <Option value={item.id} kye={index}>{item.name}</Option>
                                )
                              })
                            }
                           
                          </Select>
                        </Form.Item>
                      </div>

                      <div className="width">
                        <Form.Item
                          label="分项工程"
                          name="name"
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </li> 

                    <li>
                      <div className="width">
                        <Form.Item
                          label="总长度"
                          name="total"
                        >
                          <Input />
                        </Form.Item>
                      </div>

                      <div className="width">
                        <Form.Item
                          label="累计开挖"
                          name="excavation"
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </li> 

                    <li>
                      <div className="width">
                        <Form.Item
                          label="累计衬砌边顶拱"
                          name="sideArch"
                        >
                          <Input />
                        </Form.Item>
                      </div>

                      <div className="width">
                        <Form.Item
                          label="累计衬砌底板"
                          name="bottomPlate"
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </li> 

                    <li>

                      <div className="width">
                      <Form.Item
                          label="时间"
                          name="time"
                        >
                         <RangePicker /> 
                        </Form.Item>
                      </div>

                      <div className="width">
                        <Form.Item
                          label="备注"
                          name="remark"
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </li> 


                  </ul>
                
                </Form>            

                  
                </Modal> 



              </div>
            );
          }

    }
)

