


import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Button, DatePicker, Table, Input, Select } from 'antd';

import { formatTime } from '@/utils/util.js'


import image1 from '@/assets/img/coordination/11.png';


import './Invoice.less';

const { Option } = Select;

function Invoice() {
        const columns = [
            {
                title: '编号',
                dataIndex: 'FNo',
                align: 'center',
            },
            {
                title: '状态',
                dataIndex: 'FZt',
                align: 'center',
                render: (_) => {
                    let status = _==2? '自动': '手动'
                    return (
                        <p>{status}</p>
                    )
                }
            },
            {
                title: '生产日期',
                dataIndex: 'FScrq',
                align: 'center',
                render: (_) => {
                    return (
                        <p>{formatTime(new Date(_*1000), 'yyyy-MM-dd')}</p>
                    )
                }
            },

            {
                title: '到场时间',
                dataIndex: 'FDcsj',
                align: 'center',
                render: (_) => {
                    return (
                        <p>{formatTime(new Date(_*1000), 'yyyy-MM-dd')}</p>
                    )
                }
            },
            {
                title: '任务单号',
                dataIndex: 'FRwdh',
                align: 'center',
            },
            {
                title: '合同编号',
                dataIndex: 'FHtbh',
                align: 'center',
            },
            {
                title: '客户编号',
                dataIndex: 'FKhbh',
                align: 'center',
            },
            {
                title: '合同单位',
                dataIndex: 'FHtdw',
                align: 'center',
            },
            {
                title: '工程名称',
                dataIndex: 'FGcmc',
                align: 'center',
            },
            {
                title: '公里数',
                dataIndex: 'FGls',
                align: 'center',
            },
            {
                title: '送货车号',
                dataIndex: 'FShch',
                align: 'center',
            },



            {
                title: '操作',
                dataIndex: 'value',
                align: 'center',
                render: (_, item) => {
                    return (
                        // <Button onClick={() => {onExpandedRowsChange(item)} }>操作</Button>
                        <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
                    )
                }
            },
        ];

        
        
        const sliceColumns =  [
            {
                title: '编号',
                dataIndex: 'FNo',
                align: 'center',
            },
            {
                title: '状态',
                dataIndex: 'FZt',
                align: 'center',
                render: (_) => {
                    let status = _==2? '自动': '手动'
                    return (
                        <p>{status}</p>
                    )
                }
            },
            {
                title: '生产日期',
                dataIndex: 'FScrq',
                align: 'center',
                render: (_) => {
                    return (
                        <p>{formatTime(new Date(_*1000), 'yyyy-MM-dd')}</p>
                    )
                }
            },

            {
                title: '到场时间',
                dataIndex: 'FDcsj',
                align: 'center',
                render: (_) => {
                    return (
                        <p>{formatTime(new Date(_*1000), 'yyyy-MM-dd')}</p>
                    )
                }
            },
            {
                title: '任务单号',
                dataIndex: 'FRwdh',
                align: 'center',
            },
            {
                title: '合同编号',
                dataIndex: 'FHtbh',
                align: 'center',
            },
                {
                    title: '操作',
                    dataIndex: 'value',
                    align: 'center',
                    render: (_, item) => {
                        return (
                            // <Button onClick={() => {onExpandedRowsChange(item)} }>操作</Button>
                            <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
                        )
                    }
                },
        ]  


        const projectId = React.$getStorage('projectInfo').id

        let loadExcelRef = null

            

        const [ExcelName, setExcelName] = useState('考试');
        const [status, setStatus] = useState(0)
        const [columns2, setColumn] =  useState(columns);
        const [tableList, setTableList] = useState([{}])
        const [loading, setLoading] = useState(false)
        const [detail, setDetail] = useState({

        })

        const [deviceList, setDeviceList] = useState([]);
   
        let trbbListParams = {
            logic: 2,
            sn: '',
            offsetLimit: 0,
            lengthLimit: 10,
            projectId: projectId,
        }


        const [deviveSn, setDeviveSn] = useState(null);

        const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
        })


        const [detailMsg, setDetailMsg] = useState({
        FTbj: [],
        TjlbArr: [],
        TphbyclArr: []
        })
        
    
        const [widthStatus, setWidthStatus] = useState(false)

        const [widthStatus2, setWidthStatus2] = useState(false)


        useEffect(() => {
            getDeviceListMixingStation()
            getList()
          }, []);

           // 获取设备列表
    function getDeviceListMixingStation() {
        React.$axios.post('deviceListMixingStation', {logic: 3, projectId: projectId}).then(res => {
            if(!res.data.list) return
            setDeviceList(res.data.list)
        })
    }

        
        // 获取list  
        function getList(type) {

            if(type) {
                pagination.current = 1;
                setPagination(pagination)
            } 

            trbbListParams.offsetLimit = (pagination.current - 1) * 10
            trbbListParams.lengthLimit = pagination.pageSize
            trbbListParams.sn = deviveSn

            setLoading(true)
            React.$axios.post('tjlbListMixingStation', trbbListParams).then(res => {

               setTableList(res.data.list)
               setpage(res)
            }).finally(() => {
               setLoading(false)
            })
        }


        function setpage(res) {

            pagination.current = pagination.current;
            pagination.pageSize = pagination.pageSize;
            pagination.total = res.data.countList;
            let json = JSON.parse(JSON.stringify(pagination)); 
            json.onShowSizeChange = function(current, pageSize) {
                pagination.pageSize = pageSize;
                let json = JSON.parse(JSON.stringify(pagination)); 
                setPagination(json)
                onPaginationChange(json)
            }
            json.onChange = function(current) {
                pagination.current = current
                let json = JSON.parse(JSON.stringify(pagination)); 
                setPagination(json)
                onPaginationChange(json)
            }
            json.showTotal = function () {  //设置显示一共几条数据
                return '共 ' + pagination.total + ' 条'; 
            }
            setPagination(json)
        }

        function onPaginationChange(row) {
            console.log(row)
            setPagination(row)
            
            getList()
        }


        
        // 点击展开
        function onExpandedRowsChange(item) {
            setDetail(item)
            toSee(true)
            
        }


        function toSee(status, item, recove) {
  
            if(status) {
                setColumn(sliceColumns)
            } else {
                setColumn(columns)
            }
    
         setWidthStatus(status)


        if(!status) {
            setTimeout(() => {
                setWidthStatus2(status)
            }, 1000)
        } else {
            setWidthStatus2(status)
        }
        
      }

    function onChange() {

    }

    function handleChange(val) {
        trbbListParams.sn = val
        setDeviveSn(val)
    }

    function toSerch() {
        getList(true)
    }



    return (
        <div className="MixSerch">

            <div className="flex serchBox">
                <div className="flex">
                    {/* <Input className="setInput"  placeholder="请输入配合比号"/> */}

                    <Select style={{ width: 200 }}  onChange={handleChange} placeholder="请选择设备">
                    <Option value=''>全部</Option>
                        {
                            deviceList.map(item => {
                                return (
                                    <Option value={item.sn} key={item.id}>{item.name}</Option>
                                )
                            })
                        }
                    </Select>

                    <Button className="setMargin styleBtnYellow" type="primary" onClick={toSerch}>搜索</Button>
                </div>
               
            </div>

            <div className="flex tableWarp">

            

            <div className="setWidth">


                <Table
                    bordered
                    loading={loading}
                    columns={columns2}
                    dataSource={tableList}
                    pagination={pagination}
                />

            </div>



            

            <div className={`detail  ${widthStatus? 'addWidth': ''}`}>
                
                <div className={`reactive ${widthStatus? 'ShowBlock': ''}`}    onClick={() => toSee(false)}>
                    ×
                </div>
                {/* <Tabs defaultActiveKey="1">
                    <TabPane tab="2021-11-12日报表统计信息" key="1"> */}
                    <h1 className="title">详情</h1>

                    <div className="statisticalInfo">
                        <div>
                            <div className="li">
                                <div className="text">
                                    <span>设备编号</span>
                                    <div>{detail.sn}</div>
                                </div>
                                <div className="text">
                                <span>时&ensp;&ensp;&ensp;&ensp;间</span>
                                    <div>{formatTime(new Date(detail.FVersion * 1000), 'yyyy-MM-dd') }</div>
                                </div>
                            </div> 


                            <div className="li">
                                <div className="text">
                                    <span>任务单号</span>
                                    <div>{detail.FRwdh}</div>
                                </div>
                                <div className="text">
                                <span>客户编号</span>
                                    <div>{detail.FKhbh}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>工程名称</span>
                                    <div>{detail.FGcmc}</div>
                                </div>
                                <div className="text">
                                <span>施工地点</span>
                                    <div>{detail.FGcdz}</div>
                                </div>
                            </div>     

                            <div className="li">
                            {/* 操&ensp;&ensp;&ensp;&ensp;作 */}
                                <div className="text">
                                    <span>施工部位</span>
                                    <div>{detail.FJzbw}</div>
                                </div>
                                <div className="text">
                                <span>浇筑方式</span>
                                    <div>{detail.FJzfs}</div>
                                </div>
                            </div>

                            <div className="li">
                                <div className="text">
                                    <span>公&ensp;里&ensp;数</span>
                                    <div>{detail.FGls}</div>
                                </div>
                                <div className="text">
                                <span>砼&ensp;品&ensp;种</span>
                                    <div>{detail.FTpz}</div>
                                </div>
                            </div>    

                            <div className="li">
                                <div className="text">
                                    <span>坍&ensp;落&ensp;度</span>
                                    <div>{detail.FGls}</div>
                                </div>
                                <div className="text">
                                <span>施工配合</span>
                                    <div>{detail.FSgpb}</div>
                                </div>
                            </div>    

                            <div className="li">
                                <div className="text">
                                    <span>砂浆配合</span>
                                    <div>{detail.FSjpb}</div>
                                </div>
                                <div className="text">
                                <span>送货车号</span>
                                    <div>{detail.FShch}</div>
                                </div>
                            </div>    

                            <div className="li">
                                <div className="text">
                                    <span>司机姓名</span>
                                    <div>{detail.FSjxm}</div>
                                </div>
                                <div className="text">
                                <span>本车盘数</span>
                                    <div>{detail.FBcps}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>本车方数</span>
                                    <div>{detail.FBcfs}</div>
                                </div>
                                <div className="text">
                                <span>总&ensp;重&ensp;量</span>
                                    <div>{detail.FZzl}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>泵送数量</span>
                                    <div>{detail.FBsfs}</div>
                                </div>
                                <div className="text">
                                <span>累计方数</span>
                                    <div>{detail.FLjfs}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>累计车数</span>
                                    <div>{detail.FLjcs}</div>
                                </div>
                                <div className="text">
                                <span>出场签发</span>
                                    <div>{detail.FCcqf}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>用户签收</span>
                                    <div>{detail.FYhqs}</div>
                                </div>
                                <div className="text">
                                <span>操&ensp;作&ensp;员</span>
                                    <div>{detail.FCzy}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>余&ensp;方&ensp;量</span>
                                    <div>{detail.FRvA}</div>
                                </div>
                                <div className="text">
                                <span>拌&ensp;方&ensp;量</span>
                                    <div>{detail.FRvB}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>票&ensp;方&ensp;量</span>
                                    <div>{detail.FRvC}</div>
                                </div>
                                <div className="text">
                                <span>退&ensp;方&ensp;量</span>
                                    <div>{detail.FQvA}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>本车混凝土方数</span>
                                    <div>{detail.FBcfsC}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>本车砂浆方数</span>
                                    <div>{detail.FBcfsM}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>委托方任务编号</span>
                                    <div>{detail.FA3}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>小票是否打印完成</span>
                                    <div>{detail.Fdywc}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>确认生产完成</span>
                                    <div>{detail.Fqrscwc}</div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>第一次小票打印的时间</span>
                                    <div>{formatTime(new Date(detail.Fdysj * 1000), 'yyyy-MM-dd') }</div>
                                </div>
                            </div> 

                    
                        </div>
                        
                        
                        <div className="echartsTime">
                            <div id="echartsTime" className="echartsTime"></div>
                        </div>        

                    </div>                    
                    
                    {/* <div className="setMaxHeight">

                        <ul className="setdetailedInfo" >
                                <h1>原材料品种规格及用量</h1>

                                <table>
                                <tr>
                                    <th>原料</th>
                                    <th>品种</th>
                                    <th>用量</th>
                                    <th>仓库</th>
                                </tr>
                                {
                                    detailMsg.TphbyclArr.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.FYlmc}</td>
                                                <td>{item.FPzgg}</td>
                                                <td>{item.FPbsl}</td>
                                                <td>{Math.floor((item.FPbsl - item.FSysl) * 100) / 100 }</td>
                                            </tr>
                                        )
                                    })
                                }
                                </table> 
                            </ul>   
                        
                    </div>     */}

                    {/* </TabPane>
                </Tabs> */}

         
            </div>

        </div>



        </div>
    )

}


export default withRouter(Invoice);