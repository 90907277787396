import React from "react";


import { Input,Select,Button, Table, message, Popconfirm, Modal, Form} from "antd";


import { SearchOutlined, BorderLeftOutlined} from '@ant-design/icons';
import { Uploadhh } from "@/components/upload/upload";
// import './BlankingList.less';
import {formatTime} from '@/utils/util'
const {Option} =  Select;

const imgUrl = process.env.REACT_APP_IMG_URL;

export default class  PartyActive extends React.Component {
  FormSbmitRef = null;
    columns = [
      {
        title: "名称",
        dataIndex: 'name',
        align: 'center',
      },

        {
          title: '含水率',
          dataIndex: 'theMoistureContent',
          align: 'center'
        },
        {
            title: '添加时间',
            dataIndex: 'addTime',
            align: 'center',
            render: (_) => {
                return (
                    <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}</p>
                )
            }
         },
        {
          title: '类型',
          dataIndex: 'type',
          align: 'center',
          render:(_) => {
            return (
                <p>{_==1?'水泥':_==2?'掺和料':_==3?'细骨料':_==4?'粗骨料':_==5?'外加剂':'水'}</p>
            )
          } 
        },
        {
            title: '操作',
            dataIndex: 'remark',
            align: 'center',
            render:(_,item) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>

                        <p onClick={this.toEdit.bind(this, item)} style={{color: '#6A9BF4 ', cursor:'pointer',}}>编辑</p>

                        <Popconfirm
                            title="是否确认删除?"
                            onConfirm={this.toDel.bind(this, item.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                          <p style={{color: '#ff0000 ', cursor:'pointer', paddingLeft: 10}}>删除</p>
                        </Popconfirm>

                       
                    </div>
                    
                    
                )
            }
        },
      ]


  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    TableParams: {
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 1,
        meStatus: '',
        name: '',
    },

    infoMsg: {
      signIn: [],
      singUp: []
    }, // 详情信息
    isModalVisible: false,
    addItem: {
      name: '',
      type: '',
      theMoistureContent: 0,
      id: '',
    }
  }




  componentDidMount() {
    this.getTableData()
    
  }

  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

       let projectId =  React.$getStorage('projectInfo').id
      React.$axios.post('openPositions/listSpecificationsOfOrigin', {...this.state.TableParams, ...{projectId}}).then(res => {
        
        console.log(res);
        this.state.pagination.total = res.data.countList

        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    console.log(name)
    this.state.TableParams.pouringNo = value
  }

  toSerch2() {
    this.getTableData(true)
  }


  toEdit(item) {
    console.log(item);
    // this.FormSbmitRef.setFields({
    //   name: item.name,
    // })

    this.setState({
      isModalVisible: true
  })

  this.state.addItem =  {
      name: item.name,
      id: item.id,
      type: item.type,
      theMoistureContent: item.theMoistureContent,
    }


    let time = setTimeout(() => {
      clearTimeout(time)
      this.clierFormSbmitRef()  
    }, 200)
    // this.FormSbmitRef.resetFields()

  }

 
  clierFormSbmitRef() {
    if(this.FormSbmitRef) {
      this.FormSbmitRef.resetFields()
    } else {
      setTimeout(() => {
        this.FormSbmitRef.resetFields()
      }, 500)
    }
  }


  // 新增一条
  addOneItem() {
    this.setState({
      isModalVisible: true
  })

  this.state.addItem = {
      name: '',
      id: '',
      type: '',
      theMoistureContent: '',
  }
   

    let time = setTimeout(() => {
      clearTimeout(time)
      this.clierFormSbmitRef()  
    }, 200)

  }


  toDel(id) {
    React.$axios.post('openPositions/saveSpecificationsOfOrigin', {id, del: 2}).then(res => {
        if(res.code == 20000) {
            message.success('删除成功')
            this.getTableData();
        } else {
            message.error('删除失败')
        }
    })
  }

  handleOk() {
    this.FormSbmitRef.submit()
  }

  onFinishItem(val) {
    React.$axios.post('openPositions/saveSpecificationsOfOrigin', { ...this.state.addItem, ...val}).then(res => {
      if(res.code === 20000) {
        this.toSerch2()
        this.setState({
          isModalVisible: false
      })
      }
    })
  }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  // 点击上传
  handleClick(e) {
    this.toSerch2()
  }

 
  handleTableChange(value) {
    this.state.pagination = value
    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }




  onChangeT(name, value) {
    this.state.TableParams[name] = value;
    this.setState({
      TableParams: this.state.TableParams
    })
  }

  render() {
    return (
      <div className="PartyActive">
          <div className="input" style={{position: 'relative'}}>
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>名称:</span> 
                        <Input onChange={this.serchCar.bind(this, 'name')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                        
                        </li>
        
                            <Button  className="btn" onClick={this.toSerch2.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>
                    </ul>

                    <div style={{position: 'absolute', right: 10, top: 24}}>
                        <Button type="primary" style={{marginRight: 20}} onClick={this.addOneItem.bind(this)}>
                            新增
                        </Button>

                        <Button type="primary" style={{marginRight: 20}} onClick={this.toSerch2.bind(this)}>
                            导入
                            <div style={{position: 'absolute', right: 0, top: 0, width: '100%', opacity: 0}}>
                            <Uploadhh  path = "openPositions/importSpecificationsOfOrigin" handleClick={this.handleClick.bind(this)}  name="file" data={{}}  width={'100%'} height={'100%'} />
                            </div>
                        </Button>

                        <Button className="bt"  type="primary" icon={<BorderLeftOutlined />}>
                            <a href="https://project.1wx.cn/static/excel/import/开仓证产地规格导入模板.xlsx" download>下载模板</a>
                        </Button>

                        
                    </div>
                    
                </div>

                <div className="table2">
                {/* <div class="allBtn">
                   
                    <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                        导入
                    </Button>
                    <Button  className="bt" type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button>
                    <Button  className="bt" type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button>
                    </div> */}


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                   
                    loading={this.state.loading} rowKey={record => record.id} columns={this.columns} dataSource={this.state.tableData}  />
                </div>

                
                <Modal
                    title={this.state.addItem.id?'编辑':'添加'}
                    visible={this.state.isModalVisible}
                    onOk={this.handleOk.bind(this)}
                    onCancel={this.handleCancel.bind(this)}
                    >
                    
                    <Form
                        name="名称"
                        labelCol={{ span: 4 }}
                        ref={(el) => this.FormSbmitRef = el}
                        initialValues={this.state.addItem}
                        onFinish={this.onFinishItem.bind(this)}
                        >
                        <Form.Item
                            label="名称"
                            name="name"
                            rules={[{ required: true, message: '请输入名称' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="含水率"
                            name="theMoistureContent"
                            rules={[{ required: true, message: '请输入含水率' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="类型"
                            name="type"
                            rules={[{ required: true, message: '请选择类型' }]}
                        >
                          <Select
                              placeholder="请选择">
                              <Option  value={1}>水泥</Option>
                              <Option  value={2}>掺和料</Option>
                              <Option  value={3}>细骨料</Option>
                              <Option  value={4}>粗骨料</Option>
                              <Option  value={5}>外加剂</Option>
                              <Option  value={6}>水</Option>
                          </Select>  

                        </Form.Item>


                        {/* <Form.Item
                            label="目录说明"
                            name="remark"
                        >
                            <Input />
                        </Form.Item> */}

                    </Form> 


                </Modal>
              

                                                                                            
      </div>
    );
  }
}
