import React from "react";

import {Button, Table, message, Image, Select, Modal, Form, Input, DatePicker} from "antd";
// 添加furon
import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import './device.less';

import {formatTime} from '@/utils/util'

import moment from 'moment';

const {Option} =  Select;

const { RangePicker } = DatePicker;



const layout = {
    labelCol: { span: 16},
};


export default class ProjectConfig extends React.Component {
  FormSbmitRef = null
  imageUrl = process.env.REACT_APP_IMG_URL
  state = {
    status: 0,
    loading: false,
    tableData: [],
    pagination: {
    current: 1,
    pageSize: 10,
    total: 200,
    onShowSizeChange: function(current, pageSize) {
        this.state.pagination.pageSize = pageSize;
        this.handleTableChange(this.state.pagination)
    }.bind(this),
    onChange: function(current) {
        this.state.pagination.current = current
        this.handleTableChange(this.state.pagination)
    }.bind(this),
    showTotal: function () {  //设置显示一共几条数据
        return '共 ' + this.state.pagination.total + ' 条'; 
    }.bind(this),
    showQuickJumper: true,
    },
    params : {
        lengthLimit: 10,
        offsetLimit: 0,
    },
    isModalVisible: false,
    requestParmas: {},
    machineDeviceList: [],
    deviceInspectionTypeList: [],

    openStatus: false,

    enterpriseAllList: [],
    teamAllList: [],
    workAllList: [],

    doormanAllList: [],
    deviceAllList:[],

    newDataHH: [],
  } 
  


 columns = [
  {
    title: "序号",
    dataIndex: 'title',
    align: 'center',
    render: (_, item, index) => {
      return (
        <p>{index + 1}</p>
      )
    }
  },
    {
      title: '设备名称',
      dataIndex: 'name',
      align: 'center',
    },
    {
      
      title: '设备序列号',
      dataIndex: 'deviceKey',
      align: 'center',
    },
    {
        title: '类型',
        dataIndex: 'type',
        align: 'center',
        render: (_) => {
            return (
                <p>{_ == 2? '宁泛':_==3?'真地':'9象'}</p>
            )
        }
    },
    {
      
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        render: (_) => {
            return (
                <p className={'setStatus' + _}>{_ == 2? '在线': '离线'}</p>
            )
        }
    },
  ]


  handleTableChange(pagination) {
    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize
    this.setState({
      params: this.state.params,
      pagination: pagination
    })

    // // 执行更新列表
    this.getList()
  }


  

  componentDidMount() {

    this.getList()


  }



  getList(type) {
    this.setState({
        loading: true
    })

    if(type) {
      this.state.params.offsetLimit = 0
      this.state.pagination.current = 1

      this.setState({
        params: this.state.params,
        pagination: this.state.pagination
      })
    }


    React.$axios.post('deviceList', this.state.params).then(res => {
         this.state.pagination.total = res.data.countList
        this.setState({
            tableData: res.data.list,
            loading: false,
        })
    })
  }


  clierFormSbmitRef() {

  }


  onChangeT(name, value) {
     if(name) {
        this.state.params[name] = value
        this.setState({
            params: this.state.params
        })
     } else {
         this.state.requestParmas.enterDeviceKey = value
         this.setState({requestParmas:this.state.requestParmas})
     }
    

}

  // 去查询
  toSerch() {
    this.getList(true)
  }


  handleBack(val) {
    this.state.requestParmas.img = val.data.key[0]

    this.setState({
        requestParmas: this.state.requestParmas
    })
    

  }


  setInput(name,value) {
    this.state.params[name] = value.target.value
  }

  setDrapDom() {
    if(this.state.openStatus == true) {
        return (
          <UpOutlined/>
        )
      } else {
        return (
          <DownOutlined />
        )
      }
  }

  drapHeight() {
    this.setState({
        openStatus: !this.state.openStatus
      })
  }


  onChangeEnterprise(value, item) {
    item = [item]
    

    this.state.params.uiEnterpriseId = value
    this.state.params.uiTeamId = []
    this.state.params.uiWorkId = []

    this.setState({
      params: this.state.params,
      teamAllList: [],
      workAllList: []
    })

    let arr = []
    item.map(item2 => {
      if(!item2.item.teamId) {

      } else {
        let teamId = item2.item.teamId.split(',')
        arr = [...arr, ...teamId]
      }
      
    })

     this.getTeamAll(arr)
  }


  onChangeTeam(value, item) {
    item = [item]
    let arr = []
    item.map(item2 => {
      if(!item2.item.workId) {

      } else {
        let workId = item2.item.workId.split(',')
       arr = [...arr, ...workId]
      }
      
    })

    this.state.params.uiTeamId = value
    this.state.params.uiWorkId = []

    this.setState({
      params: this.state.params,
      workAllList: []
    })

    this.getWorkAll(arr)
  }


  onChangeWorkAll(value) {
    this.state.params.uiWorkId = value
    this.state.params.deviceKey = null
    this.setState({
      params: this.state.params
    })
  }

  onChangeDoor(status,value) {
    
    if(status) { // 详情
        this.state.requestParmas.logoDoorman = value
        this.state.requestParmas.enterDeviceKey = null

        this.setState({
            requestParmas: this.state.requestParmas
        })

    } else { // 搜索条件
        this.state.params.logoDoorman = value
        this.state.params.deviceKey = null
        this.setState({
            params: this.state.params
        })
    }

    
    this.getDeviceAll(value, status)
  }





  // 改变时间
  onChangeTime(value) {
    this.state.newDataHH = value
    
    this.state.params.showTimeStart = parseInt((+ value[0]._d) / 1000) 
    this.state.params.showTimeStop = parseInt((+value[1]._d) / 1000) 

    
    this.setState({
        newDataHH: this.state.newDataHH,
        params:this.state.params
    })    
  }




  render() {
    return (
      <div className="distinguishList">

        <div className="warp1">
  
                <div className="table">
                <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id}   columns={this.columns} dataSource={this.state.tableData}  />

                </div>
            
            </div>



      </div>

    );
  }
}
