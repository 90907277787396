import React from "react";


import { Input,Select,Button, Table, message, Popconfirm, Modal, Image} from "antd";


import { SearchOutlined, UploadOutlined, UpOutlined, DownOutlined} from '@ant-design/icons';

import {formatTime} from '@/utils/util';

import './BlankingList.less';
import Style from "./BlankingList.module.less";

const {Option} =  Select;

const addPath = process.env.REACT_APP_BASE_URL;

const imgUrl = process.env.REACT_APP_IMG_URL;

export default class  PartyActive extends React.Component {
    
    columns = [
      {
        title: "准浇证号",
        dataIndex: 'pouringNo',
        align: 'center',
      },

        {
          title: '拌合站',
          dataIndex: 'branch',
          align: 'center'
        },
        {
            title: '生产班组',
            dataIndex: 'productionTeam',
            align: 'center'
         },
        {
          title: '设计强度等级',
          dataIndex: 'designStrengthClass',
          align: 'center'
        },
        {
          title: '配合比报告编号',
          dataIndex: 'pouringNoIngredients',
          align: 'center'  
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          render: (_, item) => {
            return (
              <p>{item.status == 1? '系统生成下料单': item.status == 2? '试验员申请下料': item.status == 3 ? '实验室主任同意': item.status == 4? '实验室主任打回': item.status == 5?'实验室监理同意':'实验室监理打回'}</p>
            )
          }  
        },
     
        {
          title: '生产日期',
          dataIndex: 'preCastTime',
          align: 'center',
        },

        {
          title: '任务状态',
          dataIndex: 'taskStatus',
          align: 'center',
          render: (_) => {
              return (
                  <p style={{color: _==1?'':'#008000'}}>{_== 1? '待处理': '已完成'}</p>
              )
            }
          },
        // {
        //   title: '状态',
        //   dataIndex: 'status',
        //   align: 'center',
        //   render: (_) => {
        //     return (
        //       <p>{_==2? '报名未开始': _==3? '报名中':_==4? '签到未开始':_==5?'签到中':_==6?'活动已结束': '报名和签到同时进行'}</p>
        //     )
        //   }
        // },
        {
            title: '操作',
            dataIndex: 'remark',
            align: 'center',
            render:(_,item) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <p  onClick={this.toDeatail.bind(this, item)} style={{color: '#6A9BF4', cursor:'pointer', marginRight: 20}}>详情</p>

                        

                         

                        <a target="_blank" href={imgUrl + item.word} style={{ marginRight: 20 ,display: item.word?'':'none',color: '#6A9BF4', cursor:'pointer'}}>打印预览</a>


                        <Popconfirm
                            title="是否确认删除?"
                            onConfirm={this.toDel.bind(this, item.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                          <p style={{color: '#ff0000 ', cursor:'pointer',}}>删除</p>
                        </Popconfirm>

                    </div>
                    
                    
                )
            }
        },
      ]


  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    TableParams: {
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 0,
        meStatus: '',
        pouringNo: '',
        taskStatus: '',
        subOption: '',
        unit: '',
        branch: '',
        element: ''
    },

    infoMsg: {
      signIn: [],
      singUp: []
    }, // 详情信息
    isModalVisible: false,
    detailItem: {
      approvalLogMany: [],
      img: []
    },
    listPouringPermitList: [],
    isDetailShow: false,
    ingredientsList: [], // 理论配合比
  }




  componentDidMount() {
    this.getTableData()
    
  }

  toDeatail(item) {
    const {projectId, pouringNo}= item;
    this.setState({
      isDetailShow: true
    })

    React.$axios.post('openPositions/listPouringPermit', {projectId, pouringNo, logic: 2}).then(res => {
      res.data.approvalLogMany.map(item => {
        item.addTime = formatTime(new Date(item.addTime * 1000), 'yyyy.MM.dd hh:mm')
      })

      this.setState({
        listPouringPermitList: res.data.approvalLogMany
      })

    })

    React.$axios.post('openPositions/listBillOfMaterials', { projectId, pouringNo, logic: 2}).then(res => {
      res.data.approvalLogMany.map(item => {
        item.addTime = formatTime(new Date(item.addTime * 1000), 'yyyy.MM.dd hh:mm')
      })

      let status = res.data.status;
      if(status == 1) {
        status = false
      } else {
        status = true
      }
      // 配合比编号存在
      res.data.ingredientsMany.map(item => {
        for(let i in item) {
          if(!item[i]) item[i] = '/';
        }

      })

      if(res.data.img) {
        res.data.img = res.data.img.split(',');
      } else {
        res.data.img = []
      }

      this.setState({
        detailItem: res.data,
        ingredientsList: res.data.ingredientsMany
      })
    })
    


    
  }


  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

       let projectId =  React.$getStorage('projectInfo').id
      React.$axios.post('openPositions/listBillOfMaterials', {...this.state.TableParams, ...{projectId}}).then(res => {
        
        console.log(res);
        this.state.pagination.total = res.data.countList

        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    console.log(name)
    this.state.TableParams[name] = value
  }

  toSerch2() {
    this.getTableData(true)
  }


  setDrapDom() {
    if(this.state.openStatus == true) {
        return (
          <UpOutlined/>
        )
      } else {
        return (
          <DownOutlined />
        )
      }
  }

  drapHeight() {
    this.setState({
      openStatus: !this.state.openStatus
    })
  }



  toDel(id) {
    React.$axios.post('openPositions/saveBillOfMaterials', {id, logic: 3}).then(res => {
        if(res.code == 20000) {
            message.success('删除成功')
            this.getTableData()
        } else {
            message.error('删除失败')
        }
    })
  }

  handleOk() {
    this.setState({
        isModalVisible: false
    })
  }

  handleCancel() {
    this.setState({
      isDetailShow: false
    })
  }

 
  handleTableChange(value) {
    this.state.pagination = value
    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }


  onChangeT(name, value) {
    this.state.TableParams[name] = value;
    this.setState({
      TableParams: this.state.TableParams
    })
  }

  exportExcel() {
    let projectId =  React.$getStorage('projectInfo').id
    // React.$axios.get('openPositions/excelExportPouringPermit', {params: {...this.state.TableParams, ...{projectId}}}, ).then(res => {
      
    // })
    let jsonString = {...this.state.TableParams, ...{projectId}};
    
    // let url = addPath +  "openPositions/excelExportBillOfMaterials?" + 
    // `lengthLimit=${jsonString.lengthLimit}&logic=${jsonString.logic}&meStatus=${jsonString.meStatus}&offsetLimit=${jsonString.offsetLimit}&pouringNo=${jsonString.pouringNo}&projectId=${jsonString.projectId}&taskStatus=${jsonString.taskStatus}`
    let url = addPath +  "openPositions/excelExportPouringPermit?" + 
      `lengthLimit=${jsonString.lengthLimit}&logic=${jsonString.logic}&unit=${jsonString.unit}&branch=${jsonString.branch}&element=${jsonString.element}&subOption=${jsonString.subOption}&meStatus=${jsonString.meStatus}&offsetLimit=${jsonString.offsetLimit}&pouringNo=${jsonString.pouringNo}&projectId=${jsonString.projectId}&taskStatus=${jsonString.taskStatus}`
      
    const elink = document.createElement('a');	// 创建一个HTML元素
    elink.download = '1';			        // 给元素添加H5中的download属性
    elink.style.display = 'none';			
    elink.href = url;	// 利用Blob对象创建URL
    document.body.appendChild(elink);		// 加入标签
    elink.click();                          // 点击事件
    URL.revokeObjectURL(elink.href);		// 释放URL对象
    document.body.removeChild(elink)		// 移除标签
}

  render() {
    return (
      <div className="roster">
      <div className="PartyActive PartyActive_self table rosterAll">

      <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                      <li><span>准浇证号:</span> 
                          <Input onChange={this.serchCar.bind(this, 'pouringNo')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                          
                          </li>

                          <li><span>分部工程:</span> 
                          <Input onChange={this.serchCar.bind(this, 'subOption')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="请输入分部工程" />
                          
                          </li>
                          <li><span>分项工程:</span> 
                          <Input onChange={this.serchCar.bind(this, 'unit')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="请输入分项工程" />
                          
                          </li>
                          <li><span>单位工程:</span> 
                          <Input onChange={this.serchCar.bind(this, 'branch')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="请输入单位工程" />
                          
                          </li>
                          <li><span>单元工程:</span> 
                          <Input onChange={this.serchCar.bind(this, 'element')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="请输入单元工程" />
                          

                          <Button  className="btn" style={{display: this.state.openStatus?'none':''}} onClick={this.toSerch2.bind(this)}>
                                <SearchOutlined className="icon"/>
                          </Button>
                        </li>



                        <li><span>任务状态:</span>  
                          <Select
                              showSearch
                              style={{ width: 200, height:30, marginRight:20,  }}
                              placeholder="请选择"
                              optionFilterProp="children"
                              onChange={this.onChangeT.bind(this, 'taskStatus')}
                          >
                          <Option value=" ">全部</Option>
                          <Option value="1">待处理</Option>
                          <Option value="3">已完成</Option>
                          </Select>
                        </li>

                        <li><span>状态:</span>  
                          <Select
                              showSearch
                              style={{ width: 200, height:30, marginRight:20,  }}
                              placeholder="请选择"
                              optionFilterProp="children"
                              onChange={this.onChangeT.bind(this, 'status')}
                          >
                            <Option value=" " key="1">全部</Option>
                            <Option value="2" key="2">我的</Option>
                          </Select>


                          {/* <Button  className="btn" >
                                <SearchOutlined  className="icon"/>
                            </Button> */}

                            <Button style={{marginBottom: 20, display: this.state.openStatus?'block':'none'}}  className="btn" onClick={this.toSerch2.bind(this)}>
                                  <SearchOutlined className="icon"/>
                              </Button>
                        </li>



                          

                             

                            {/* </div>   */}

                            

                    </ul>

                    <Button  className="bt" onClick={this.exportExcel.bind(this)} type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button>

                    
                   
                </div>

                <p className="drapBottom" onClick={this.drapHeight.bind(this)}>
                        {
                          this.setDrapDom()
                        }
                </p>
          {/* <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>准浇证号:</span> 
                        <Input onChange={this.serchCar.bind(this, 'pouringNo')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                        
                        </li>
                        <li><span>任务状态:</span>  
                          <Select
                              showSearch
                              style={{ width: 200, height:30, marginRight:20,  }}
                              placeholder="请选择"
                              optionFilterProp="children"
                              onChange={this.onChangeT.bind(this, 'taskStatus')}
                          >
                          <Option value=" ">全部</Option>
                          <Option value="1">待处理</Option>
                          <Option value="3">已完成</Option>
                          </Select>
                        </li>

                        <li><span>状态:</span>  
                          <Select
                              showSearch
                              style={{ width: 200, height:30, marginRight:20,  }}
                              placeholder="请选择"
                              optionFilterProp="children"
                              onChange={this.onChangeT.bind(this, 'status')}
                          >
                            <Option value=" " key="1">全部</Option>
                            <Option value="2" key="2">我的</Option>
                          </Select>
                        </li>



                            <Button  className="btn" onClick={this.toSerch2.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>
                    </ul>


                    <Button  className="bt" onClick={this.exportExcel.bind(this)} type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button>

                </div> */}



                <div className="table2" style={{marginTop: 20}}>
                {/* <div class="allBtn">
                   
                    <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                        导入
                    </Button>
                    <Button  className="bt" type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button>
                    <Button  className="bt" type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button>
                    </div> */}


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                   
                    loading={this.state.loading} rowKey={record => record.id} columns={this.columns} dataSource={this.state.tableData}  />
                </div>



      <Modal title="下料单详情" width="50%"  className={Style.BlankingListModal +  " BlankingListModal"}  visible={this.state.isDetailShow}  onOk={this.handleCancel.bind(this)} onCancel={this.handleCancel.bind(this)}>
                   <div className="box">
                   

                   <h1>编号：{this.state.detailItem.pouringNo}</h1>
                   <ul className="inputLi">
                      {/* <li>
                      <span>编号：</span> {this.state.detailItem.pouringNo}
                      </li> */}
                      <li>
                          <span>自定义单号：</span> <div>{this.state.detailItem.customNo}</div>
                      </li>
                      <li>
                          <span>单位工程：</span> <div>{this.state.detailItem.branch}</div>
                      </li>
                      <li>
                          <span>分部工程：</span> <div>{this.state.detailItem.subOption}</div>
                      </li>
                      <li>
                          <span>分项工程：</span> <div>{this.state.detailItem.unit}</div>
                      </li>
                      <li>
                          <span>单元工程：</span> <div>{this.state.detailItem.element}</div>
                      </li>
                      <li>
                          <span>桩号：</span> <div>{this.state.detailItem.stationNumber}</div>
                      </li>
                      <li>
                          <span>高程：</span> <div>{this.state.detailItem.altitude}</div>
                      </li>
                      <li>
                          <span>混凝土标号：</span> <div>{this.state.detailItem.mixingSlumpStart}</div>
                      </li>
                      <li>
                          <span>拌合坍落度：</span> <div>{this.state.detailItem.mixingSlumpStart}  ~  {this.state.detailItem.mixingSlumpEnd} mm</div>
                      </li>
                      <li>
                          <span>入仓方式：</span> <div>{this.state.detailItem.warehousingMethod}</div>
                      </li>
                      <li>
                          <span>预浇筑方量：</span> <div>{this.state.detailItem.preCastVolume}</div>
                      </li>
                      <li>
                          <span>浇筑时间：</span> <div>{this.state.detailItem.preCastTime}</div>
                      </li>
                      <li>
                          <span>队伍名称：</span> <div>{this.state.detailItem.teamName}</div>
                      </li>

                      <li>
                          <span>图片：</span> <div>
                          {
                            this.state.detailItem.img.map(((item, index) => {
                              return (
                                <div key={index} style={{marginRight:10}}>
                                <Image
                                      style={{display: item?'':'none'}}
                                      
                                      width={120}
                                      height={80}
                                      src={imgUrl + item}
                                  /> 
                                </div>
                              )
                            }))
                          }
                          </div>
                      </li>
                    
                    </ul> 

                    
                    <h1>审批/处理过程</h1>
                    <ul className="user">
                      {
                        this.state.detailItem.approvalLogMany.map(item => {
                          return (
                            <li key={item.id}>
                              <div className="name">
                                <img src={require('@/assets/img/cloudOpenning/face.png').default} alt="" />
                                {item.nameUser}</div>
                            
                              <div className="des">
                                
                                <span className="nameWidth" style={{marginRight: 30}}>
                                {
                                      item.status == 1? '试验员': item.status == 2? '试验员': item.status == 3? '实验室主任': item.status == 4? '实验室主任':
                                      item.status == 5? '实验室监理':  item.status == 6? '实验室监理': item.status == 7? '实验室主任': 
                                      item.status == 8? '实验室监理': '监理'
                                  }
                                </span>
                                
                                <span className={'sign cololr2' + item.status}>
                                  {
                                    item.status == 1? '待处理': item.status == 2? '申请下料': item.status == 3? '同意': item.status == 4? '打回':
                                    item.status == 5? '同意':  item.status == 6? '打回': item.status == 7? '待处理': 
                                    item.status == 8? '待处理': '打回'
                                  }
                                </span>

                                <span>{item.fix === 3? '': '/修正'}</span>
                              </div>
      
                              <div className="sign">
                                  <Image
                                      style={{display: item.sign?'':'none'}}
                                      width={120}
                                      src={imgUrl + item.sign}
                                  />
                              </div>
                            </li>
                          )
                        })
                      }

                      {
                        this.state.listPouringPermitList.map(item => {
                          return (
                            <li key={item.id + 'index'}>
                              <div className="name">
                                <img src={require('@/assets/img/cloudOpenning/face.png').default} alt="" />
                                {item.nameUser}</div>
                            
                              <div className="des">
                                
                                <span className="nameWidth" style={{marginRight: 30}}>
                                {
                                     item.status == 1? '技术员': item.status == 2? '质检员': item.status == 3? '质检员': item.status == 4? '监理工程师':
                                     item.status == 5? '监理工程师':  item.status == 6? '技术员': item.status == 7? '质检员': 
                                     item.status == 8? '监理工程师': item.status == 9? '监理': '监理'
                                  }
                                </span>
                                
                                <span className={'sign cololr' + item.status}>
                                  {
                                    item.status == 1? '申请': item.status == 2? '同意': item.status == 3? '打回': item.status == 4? '同意':
                                    item.status == 5? '打回':  item.status == 6? '待处理': item.status == 7? '待处理': 
                                    item.status == 8? '待处理': item.status == 9? '同意': '打回'
                                  }
                                </span>

                                <span>{item.fix === 3? '': '/修正'}</span>
                              </div>
      
                              <div className="sign">
                                  <Image
                                      style={{display: item.sign?'':'none'}}
                                      width={120}
                                      src={imgUrl + item.sign}
                                  />
                              </div>
                            </li>
                          )
                        })
                      }

                     


                    </ul>



                    {/* listPouringPermitList */}



                    <h1 style={{display: this.state.ingredientsList.length > 0? '':'none'}}>理论配合比</h1>

                    <ul className="tableProportion" style={{display: this.state.ingredientsList.length > 0? '':'none'}}>
                      {
                        this.state.ingredientsList.map(item => {
                          return (
                            <li key={item.id}>
                                <div className="name side1">{item.name}</div>
                                <div className="name">{item.theoreticalMixRatio == 0?'/' : item.theoreticalMixRatio}</div>
                            </li>
                          )
                        })
                      }
                      
                    </ul>


                    <h1 style={{display: this.state.ingredientsList.length > 0? '':'none'}}>施工配料计算</h1>  

                     <ul className="tableCount" style={{display: this.state.ingredientsList.length > 0? '':'none'}}>
                        <li className="tr">
                          <div className="td">材料名称</div>
                          <div className="td">产地规格</div>
                          <div className="td">理论用料(kg/m³)</div>
                          <div className="td">材料表面含水量(%)</div>
                          <div className="td">材料含水量</div>
                          <div className="td">施工用料(kg/m³)</div> 
                        </li>

                        {
                          this.state.ingredientsList.map(item => {
                            return (
                              <li key={item.id}>

                                  <div className="td">{item.name}</div>
                                  <div className="td">{item.originSpecifications}</div>
                                  <div className="td">{item.theoreticalMaterial}</div>
                                  <div className="td">{item.materialSurfaceMoistureContent}</div>
                                  <div className="td">{item.materialMoistureContent}</div>
                                  <div className="td">{item.constructionMaterials}</div> 

                              </li>
                            )
                          })
                        }

                     </ul>



                    <h1 style={{display: this.state.ingredientsList.length > 0? '':'none'}}>施工配合比</h1>  

                        <div className="tableRate" style={{display: this.state.ingredientsList.length > 0? '':'none'}}>
                            <li className="tr">
                              <div className="td">材料名称</div>
                              <div className="td">施工配合比列</div>
                              <div className="td">理盘用料(kg)</div>
                            </li>

                            {
                          this.state.ingredientsList.map(item => {
                            return (
                              <li key={item.id}>

                                  <div className="td">{item.name}</div>
                                  <div className="td">{item.constructionIngredientRatio}</div>
                                  <div className="td">{item.constructionMaterials}</div>
                              </li>
                            )
                          })
                        }


                        </div>


                    </div>




                    
      </Modal>    
                                                                                            
      </div>
      </div>
    );
  }
}
