import React from "react";

import {Button, Table, Modal, Form, Input, message, Select, DatePicker} from "antd";

import { SearchOutlined, PlusOutlined} from '@ant-design/icons';

import './TunnelList.less';

import moment from 'moment';


import {formatTime} from '@/utils/util';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 10 },
  };


export default class ProgressNode extends React.Component {
  state = {
    status: 0,
    loading: false,
    tableData: [],
    openStatus: false,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    requestParmas: {
        name: null, // 名称
        endMileageKm: null, // Km 结束里程
        endMileageM: null, // M
        endTime: null, // 
        startTime: null,
        mileageUnit: null, //里程单位
        startMileageKm: null, // 开始里程
        startMileageM: null, // M
        time: null
    },
    params : {
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 2,
        name: null
    },

    teamAllList: [],

    isModalVisible: false,
  } 
  FormSbmitRef = null
  


 columns = [
    {
      title: '名称',
      dataIndex: 'name',
      align: 'center',
    },
    {
        title: '开始里程',
        dataIndex: 'startMileageString',
        align: 'center',
    },
    {
        title: '结束里程',
        dataIndex: 'endMileageString',
        align: 'center',
    },
    {
        title: '开始时间',
        dataIndex: 'startTime',
        align: 'center',
        render: (_) => {
          return (
            <p>
              {formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}
            </p>
          )
        }
    },
    {
        title: '结束时间',
        dataIndex: 'endTime',
        align: 'center',
        render: (_) => {
          return (
            <p>
              {formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}
            </p>
          )
            
        }
    },
    {
        title: '总长度（米）',
        dataIndex: 'endMileageM',
        align: 'center',
    },
    {
        title: '累计已开挖（米）',
        dataIndex: 'excavatedTotal',
        align: 'center',
    },
    {
        title: '累计未开挖（米）',
        dataIndex: 'notExcavatedTotal',
        align: 'center',
    },
    {
        title: '累计仰拱（米）',
        dataIndex: 'invertTotal',
        align: 'center',
    },
    {
        title: '累计二衬（米）',
        dataIndex: 'secondLinerTotal',
        align: 'center',
    },
    {
        title: '累计已开挖百分比（%）',
        dataIndex: 'excavatedTotalPercentage',
        align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'address',
      align: 'center',
      width: 100,
      render:(_,item) => {
        return (
          <div className="operation">
              <p onClick={() => this.clickFun(item, '2')}>编辑</p>
          </div>
        )
      }
    },
  ]


  componentDidMount() {
    this.getList()
    // this.getTeamAll()
  }




  handleTableChange(pagination) {

    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize

    this.setState({
      params: this.state.params,
      pagination: pagination
    })


    // 执行更新列表
    this.getList()
  }


  clickFun(item) {


    this.setState({
        isModalVisible: true
    })


    for(let i in this.state.requestParmas) {
      this.state.requestParmas[i] = item[i]
    }

    this.state.requestParmas.time =[moment(item.startTime * 1000), moment(item.endTime * 1000)];
    this.state.requestParmas.id = item.id

    this.setState({
      requestParmas: this.state.requestParmas
    })


    this.clierFormSbmitRef()


  }


  clierFormSbmitRef() {
    if(!this.FormSbmitRef) {
        setTimeout(() => {
          
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
  }



  getList(type = false) {
    this.setState({
        loading: true
    })

    if(type) {
        this.state.pagination.current = 1
        this.state.params.offsetLimit = 0
    }


    React.$axios.post('listTunnelProgress', this.state.params).then(res => {

        this.state.pagination.total = res.data.countList
        
        // this.state.pagination.total = res.data.countList
        this.setState({
            tableData: res.data.list,
            loading: false,
            pagination: this.state.pagination
        })

    })
  }

  // 获取岗位
  getTeamAll() {
    React.$axios.post('teamAll').then(res => {
        this.setState({
            teamAllList:res.data.teamAll
        })
    })
  }


  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  handleOk() {
    this.FormSbmitRef.submit()
  }

  // 点击新增
  add() {
   
      for(let i in this.state.requestParmas) {
        this.state.requestParmas[i] = null
      }
      this.state.requestParmas.weigh = 0
      delete this.state.requestParmas.id

      this.setState({
        isModalVisible: true,
        requestParmas: this.state.requestParmas
      })

      this.clierFormSbmitRef()

  }

  setInput(name, { target: { value } }) {

    this.state.requestParmas[name] = value
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  // 选择下拉数据
  handleChange(value, item) {
    this.state.requestParmas[value] = item
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }
  

  handleFinger(item) {
    this.state.requestParmas.img = [...this.state.requestParmas.img, ...item.data.key]
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  // 
  onFinish(value) {
    
    for(let i in value) {
      this.state.requestParmas[i] = value[i]
    }


    let start = this.getNewDate(value.time[0]._d, '00:00:00')
    let endTime = this.getNewDate(value.time[1]._d, '23:59:59')
    
    this.state.requestParmas.startTime = start
    this.state.requestParmas.endTime =  endTime

    delete this.state.requestParmas.time

    React.$axios.post('saveTunnelProgress', this.state.requestParmas).then(res => {
        this.state.requestParmas.time = null
        if(res.code == 20000) {
            message.success('保存成功')
            
           this.setState({
                isModalVisible: false  
           })

           this.getList()
        }
    })
  }

  getNewDate(date, hh) {
    let year = date.getFullYear();
    let Month = date.getMonth() + 1;
    let cuurenDate = date.getDate();
    
    let ne = year + '/' + Month + '/' + cuurenDate + ' ' + hh
    return parseInt(new Date(ne) / 1000)
  }




  onChangeSelect() {
    
  }

  serchCar(name,value) {
    this.state.params[name] = value.target.value
  }

  toSerch2() {
    this.getList(true)
  }



  render() {
    return (
      <div className="TunnelList">
          <div style={{display: this.state.status==0? 'block': 'none'}}>
            <div className="warp1">
                <div className="head">
                <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li>
                            <span>标题:</span> 
                        <Input onChange={this.serchCar.bind(this, 'name')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                        
                        </li>
                        

                            <Button className="setcolorBtn" onClick={this.toSerch2.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>
                    </ul>
                </div>

                    <button className="button" onClick={this.add.bind(this)}>新增</button>
                </div>


                <div className="table">
                <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id}  columns={this.columns} dataSource={this.state.tableData}  />

                </div>
        
            </div>
          </div>


          <Modal title="添加" width="30%" className="TunnelListNodeModal" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                        <Form
                            layout="vertical"
                            onFinish={this.onFinish.bind(this)}
                            ref={(el) => this.FormSbmitRef = el}
                            {...layout}
                            initialValues = {this.state.requestParmas}
                        >

                            <Form.Item label="名称" name="name"  rules={[{ required: true }]}>
                            <Input placeholder="请输入名称"  />
                            </Form.Item>

                            <div className="setFlexInput">
                                <Form.Item label="开始里程" name="startMileageKm"  rules={[{ required: true }]}>
                                    <Input placeholder="请输入开始里程" addonAfter="Km" />
                                </Form.Item>
                                
                                <p className="setp">~</p>
                                {/* <button>Km</button> */}
                                <Form.Item label="" name="startMileageM" className='setCssItem'  rules={[{ required: true }]}>
                                    
                                    <Input placeholder="请输入" addonAfter="M" />
                                    
                                </Form.Item>   
                            </div>

                            <div className="setFlexInput">
                                <Form.Item label="结束里程" name="endMileageKm"  rules={[{ required: true }]}>
                              
                                    <Input placeholder="请输入结束里程" addonAfter="Km" />
                                </Form.Item>
                                
                                <p className="setp">~</p>
                                {/* <button>Km</button> */}
                                <Form.Item label="" name="endMileageM" className='setCssItem'  rules={[{ required: true }]}>
                                    
                                    <Input placeholder="请输入" addonAfter="M" />
                                    
                                </Form.Item>   
                            </div> 

                            <Form.Item label="里程单位" name="mileageUnit"  rules={[{ required: true }]}>
                            <Input placeholder="里程单位"/>
                            </Form.Item>
                            
                            <Form.Item label="日期" name="time"  rules={[{ required: true }]}>
                                <RangePicker />
                            </Form.Item>   
                        </Form>

        </Modal> 



      </div>
    );
  }
}
