import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Form, Button, DatePicker, Table, message, Select } from 'antd';
// import './monitoringDetails.less'
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatTime, getCurrentDayLastDay} from '@/utils/util.js'
import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

const {Option} =  Select;
function MonitoringDetails(props) {
  const [searchForm] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [tableList, setTableList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })

 
  let InitialValues = {
    time: getCurrentDayLastDay(moment)
  }

 

  const [ExcelName, setExcelName] = useState('环境导出');
  const [devAddr, setDevAddr] = useState(null); 
  const [devList, setDeviceList] = useState([]); 


  const jsonFieldsList = {
    "0_设备": 'craneName',
    "1_时间": 'craneTime',
    // "1_监测时间": {
    //     field: 'monitorTime',
    //       callback: value => {
    //           return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
    //       }
    // },
    "2_塔机编号": "towerCraneId",
    "3_幅度(cm)": "craneRange",
    "4_噪声(dB)": "noiseDb",
    "5_温度(℃)": "temperature",
    "6_湿度(%RH)": "humidity",
    "7_风力(级)": "windForce",
    "8_风速(m/s)": "windSpeed",
    "9_风向(方位)": "windDirection",
    "10_风向(度数)": "windDegree",
    "11_TSP(ug/m³)": "tsp",
    "12_气压(kPa)": "airPressure",
    "13_噪音等级": {
      field: 'noiseDbGrade',
        callback: value => {
          return value ==2? '达标': '不达标'
      }
    },
    "14_等级": {
      field: 'grade',
        callback: value => {
          let gradeText = null
          switch (value) {
            case 2:
              gradeText = "优";
              break;
            case 3:
              gradeText = "良";
              break;
            case 4:
              gradeText = "轻度污染";
              break;
            case 5:
              gradeText = "中度污染";
              break;
            case 6:
              gradeText = "重度污染";
              break;
            case 7:
              gradeText = "严重污染";
              break;
          }
          return gradeText
      }
    },
    
  }



  const columns = [
    {
      title: '序号',
      align: 'center',
      render: (text, record, index) => {
        return <span>{index + 1}</span>
      }
    },

    {
      title: '设备名称',
      dataIndex: 'craneName',
      align: 'center',
    },
    {
      title: '时间',
      dataIndex: 'craneTime',
      align: 'center',
    },

    {
      title: '塔机编号',
      align: 'center',
      dataIndex: 'towerCraneId',
    },

    {
      title: '高度(m)',
      align: 'center',
      dataIndex: 'craneHeight',
    },
    {
        title: '幅度(m)',
        align: 'center',
        dataIndex: 'craneRange',
    },
    {
        title: '回转',
        align: 'center',
        dataIndex: 'craneRotation',
    },
    {
        title: '起重(t)',
        align: 'center',
        dataIndex: 'craneElevatingCapacity',
    },
    {
        title: '风速(cm/s)',
        align: 'center',
        dataIndex: 'craneElevatingCapacity',
    },
    {
        title: '倾斜百分比%',
        align: 'center',
        dataIndex: 'craneTiltPercentage',
    },
    {
        title: '平均力矩(m)',
        align: 'center',
        dataIndex: 'moment',
    },
    {
        title: '风级',
        align: 'center',
        dataIndex: 'beaufortScale',
    },
    {
        title: '警报原因',
        align: 'center',
        dataIndex: 'alarmReason',
    },


    

  ]

  // useEffect(() => {
  //   console.log(11111111)
  //   getTablelList(true);
  // }, []);

  useEffect(() => {

    getTablelList(true);
    getMonitoringDeviceAll();
    
    
  }, []);

  
  const getMonitoringDeviceAll = () => {
    React.$axios.post("towerCrane", {type: 6, logic: 2, projectId: React.$getStorage('projectInfo').id}).then(({ data }) => {
      // setDevAddr(devAddr.)
      if(data.list && data.list.length > 0) {
        console.log(data.list);
        setDeviceList(data.list);
      }
     
    })
  };

  const onChangeT = (value, item) => {
    setDevAddr(value);
  }


  // useEffect(() => {
  //   console.log(pagination)

  //   getTablelList();
  // }, [pagination])

  function onPaginationChange(page) {
    setPagination(page)

    
    getTablelList();
  }
  const getTablelList = (type = false) => {
    // if (devAddr) {
      const { time } = searchForm.getFieldsValue()

      const monitorTimeStart = time ? parseInt(new Date(time[0]._i + ' 00:00:00').getTime() / 1000) : ""
      const monitorTimeStop = time ? parseInt(new Date(time[1]._i + ' 23:59:59').getTime() / 1000) : ""

      const requestData = {
        offsetLimit: (pagination.current - 1) * pagination.pageSize,
        lengthLimit: pagination.pageSize,
        blackBoxId: devAddr,
        craneTime: [time[0]._i + ' 00:00:00', time[1]._i + ' 23:59:59'],
        // monitorTimeStop: monitorTimeStop,
        projectId: React.$getStorage('projectInfo').id,
        logic: 1
      }


      setLoading(true)
      React.$axios.post("towerCraneLog", requestData)
        .then(({ data }) => {
          setTableList(data.list)
          if (type) {
            setPagination({
              current: 1,
              pageSize: pagination.pageSize,
              total: data.countList
            })
          }
        }).finally(() => {
          setLoading(false)
        })
    // }
  }


  // 返回导出数据函数
  function fetchFun() {
    return new Promise(resolve => {

      let name = '塔吊日志明细' + formatTime(new Date(), 'yyyyMMdd')
      setExcelName(name)

      const { time } = searchForm.getFieldsValue()
      
      if(!time) {
        message.error('导出需要选择时间')
        return
      }
      
      const monitorTimeStart = time ? parseInt(new Date(time[0]._d).getTime() / 1000) : ""
      const monitorTimeStop = time ? parseInt(new Date(time[1]._d).getTime() / 1000) : ""
      // console.log(time)
      const requestData = {
        offsetLimit: (pagination.current - 1) * pagination.pageSize,
        lengthLimit: pagination.pageSize,
        blackBoxId: devAddr,
        craneTime: [time[0]._i + ' 00:00:00', time[1]._i + ' 23:59:59'],
        // monitorTimeStop: monitorTimeStop,
        projectId: React.$getStorage('projectInfo').id,
        logic: 2
      }


      // 返回导出数据
      React.$axios.post('towerCraneLog', requestData).then(res => {
        // res.data.list.map(item => {
        //   item.devName = props.devName
        // })
        resolve(res.data.list)
      })

    })
  }

  


  return (
    <div className="environmentalMonitoring">
    <div className="monitoringDetails">
      <div className="search-wrap">
        <Form form={searchForm} initialValues={InitialValues}  layout="inline" onFinish={() => { getTablelList(true) }}>

        <li><span>设备：</span> 
        <Select
            showSearch
            style={{ width: 200, height:30, marginRight:20,  }}
            placeholder="请选择"
            optionFilterProp="children"
            value={devAddr}
            onChange={onChangeT}
        >
          {
            devList.map((item, index) => {
              
              return (
                <Option value={item.blackBoxId} key={index} item={item}>{item.craneName}</Option>
              )
            })
          }
          {/* <Option value=" ">全部</Option>
          <Option value="2">劳务人员</Option>
          <Option value="3">项目管理人员</Option> */}
        </Select>
        </li>
             
          <Form.Item
            name="time"
          >
            <RangePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon={<SearchOutlined />} htmlType="submit">搜索</Button>
          </Form.Item>

          {/* <Form.Item>
          <DownloadExcel name={ExcelName} fields={jsonFieldsList}   fetch={fetchFun} btnName="导出" />
          </Form.Item> */}

        </Form>
      </div>
      <div className="table-wrap">
        <Table
          bordered
          loading={loading}
          columns={columns}
          dataSource={tableList}
          pagination={pagination}
          onChange={onPaginationChange}
        />
      </div>
    </div>
    </div>
  )
}

export default withRouter(MonitoringDetails);