import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { VerticalAlignTopOutlined, VerticalAlignBottomOutlined, FileProtectOutlined } from '@ant-design/icons';
import { Modal, Input, message } from "antd";
import './rule.less';


import { Uploadhh } from "@/components/upload/upload";
const url = process.env.REACT_APP_BASE_URL
function Rule() {

    const [Reward, setReward] = useState([])
    const [Basic, setBasic] = useState([])
    const [Dedution, setDedution] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [safetyEducationScore, setSafetyEducationScore] = useState(null);
    const [projectInfoId, setProjectInfoId] = useState(null);

    useEffect(() => {
        
        getlistEvaluationStandard()
        getInfoProject()
        
    }, [])

    const getInfoProject = () => {
        React.$axios.post('infoProject').then(res => {
          let item = res.data.infoProject[0]

          setSafetyEducationScore(item.safetyEducationScore)
          setProjectInfoId(item.id)
        })
    
      }



    const getlistEvaluationStandard = () => {
        React.$axios.post('listEvaluationStandard', {logic: 4}).then(res => {
            
            setReward(res.data[1].standard)
            setBasic(res.data[0].standard)
            setDedution(res.data[2].standard)

        })
    }


    const handleClick = (res) => {
        if(res.code == 20000) {
            getlistEvaluationStandard()
        }
    }


    const onInput = (e) => {
        setSafetyEducationScore(e.target.value)
    }

    // 点击提交
    const handleOk = () => {
        React.$axios.post('projectEditSave', {id: projectInfoId, safetyEducationScore: safetyEducationScore}).then(res => {

            if(res.code == 20000) {
                message.success('保存成功')
                handleCancel()
            }
    
        })


    }

    //点击关闭
    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const ShowModal = () => {
        setIsModalVisible(true);
    }
    


    
    return (
        <div className="EvaluationRule">
            <div className="top">
                <div className="tips">
                    <h1>提示：</h1>
                    <p>i电建企业级数字管理系统结合一线劳务工人日常生活实际，从<span>基础评分、</span><span> 奖励评分、</span><span> 扣减评分</span>三大板块；考勤、生产、技术、安全文明施工、质量、合理化建议、文明生活、劳动竞赛、特殊贡献9个类别对劳务工人个人及协作队伍进行信息化智能积分评比，<i>个人得分=基础评分+奖励评分-扣减评分。</i> 
</p>
                </div>

                <div className="TemplateBtn">
                        
                        <div className="warp">
                            <a download href={url + 'static/excel/import/考评规则导入模板.xlsx'}>
                            <div>
                            
                            <VerticalAlignBottomOutlined  /> 
                            <p>下载规则模板</p> 
                            
                            {/* beforeFun={this.beforeFun.bind(this)}    handleClick={this.handleFront.bind(this)}  */}
                            
                            </div>
                            </a> 
                        </div>
                        
                        <div className="warp">
                            <div>
                            <VerticalAlignTopOutlined />

                            <Uploadhh className="Uploadhh" path = "saveEvaluationStandard"  pdfStatus={true} handleClick={handleClick}  name="file" data={{logic: 1}}  width={160} height={75} />
                            <p>上传规则</p>
                            </div>
                        </div>


                        <div className="warp" onClick={ShowModal}>
                            <div >
                            <FileProtectOutlined /> 
                            <p>初始积分设置</p> 
                            </div>
                        </div>

                </div>

            </div>
            <div className="bottom currentRule">
               <div className="box">
                   <div className="title">
                    <h1>基础评分规则</h1>
                    <p>Basic scoring rules</p>


                   </div>

                   <div className="scroll">
                   <div className="table">
                    <table>
                            <thead>
                                <tr>
                                <th>考试类别</th>
                                <th>考试内容</th>
                                <th>分值</th>
                                </tr>
                            </thead>

                            <tbody>

                            {
                                Basic.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.nameType}</td>
                                            <td className="setWidth">{item.name}</td>
                                            <td>{item.fraction}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>

                    </table>
                    </div>
                    </div>
                    
               </div> 
               <div className="box">
                    <div className="title">
                    <h1>奖励评分规则</h1>
                    <p>Reward scoring rules</p>
                    </div>


                    <div className="scroll">
                   <div className="table">
                    <table>
                            <thead>
                                <tr>
                                <th>考试类别</th>
                                <th>考试内容</th>
                                <th>分值</th>
                                </tr>
                            </thead>

                            <tbody>

                            {
                                Reward.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.nameType}</td>
                                            <td className="setWidth">{item.name}</td>
                                            <td>{item.fraction}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>

                    </table>
                    </div>
                    </div>
                </div> 
               <div className="box">
                    <div className="title">
                    <h1>扣减评分规则</h1>
                    <p>Dedution scoring rules</p>
                    </div>

                    <div className="scroll">
                   <div className="table">
                    <table>
                            <thead>
                                <tr>
                                <th>考试类别</th>
                                <th>考试内容</th>
                                <th>分值</th>
                                </tr>
                            </thead>

                            <tbody>

                            {
                                Dedution.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.nameType}</td>
                                            <td className="setWidth">{item.name}</td>
                                            <td>{item.fraction}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>

                    </table>
                    </div>
                    </div>
               </div> 
            </div>   


            
            <Modal title="初始积分" width="500px"  visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>    
              <div className="machineDeviceListModal">
   
                 <div>
                  <span>初始积分设置</span>
                  <Input placeholder="初始积分设置" value={safetyEducationScore}  onInput={onInput} />    
                 </div>
                 
              </div>      
              
            </Modal> 




        </div>
    )
  
}

export default withRouter(Rule);