import React, { useState } from 'react'
import { Modal, Tabs, Form, Input, Switch, Radio, Select, DatePicker, Button, message, Image } from 'antd';



import { PlusOutlined,  MinusOutlined} from '@ant-design/icons';

import { Company } from "@/components/company/company";

import { Uploadhh } from "@/components/upload/upload";

import moment from 'moment';

import "./modeal2.less";

// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;


const { Option } = Select;


    // let loading = false

    // const uploadButton = (
    //     <div >
    //       {loading ? <LoadingOutlined /> : <PlusOutlined />}
    //       <div style={{ marginTop: 8 }}>Upload</div>
    //     </div>
    //   );
    // const layout = {
    //     labelCol: { span: 1 },
    //     wrapperCol: { span: 10000 },
    // };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };


export const Modeal =   (

    class Modeal extends React.Component {
        state = {
            visible: false,
            imageUrl: process.env.REACT_APP_IMG_URL,
            loading: false,
            value: 1,
            titleName: '新增',

            detatiType: false,

            teamParams: {
                uiEnterpriseId: '',
                uiTeamId: '',
                uiWorkId: '',
            },

            roleAllList: [],
            fingerList: [], // 指纹图片集合
            copyAllParams: {
                bankCard: {
                    bankCardNumber: "",
                    bankName: "",
                    imgKey: ""
                },
                idCard: {
                    address: "", // 地址
                    age: "", //年龄
                    dateOfBirth: "", // 生日
                    effectiveCutoffTime: "",
                    effectiveStartTime: "",
                    issuingAuthority: "",
                    name: "", //姓名
                    nation: "", // 名族
                    number: "", // 身份证号码
                    positiveKey: "",
                    reverseSideKey: "",
                    sex: 2,
                },
                user: {
                    id: "",
                    phone: ""
                },

                info: {
                    avatarKey: "",
                    certificate: [{img: [], name: "", timeEnd: "", timeStart: ""}],
                    contractKey: "",
                    contractNo: "",
                    contractProcessing: 2,
                    culture: 2,
                    delivery: 2,
                    dormitoryNumber: "",
                    emergencyContact: [{name: "", phone: ""}],
                    enterStatus: 2,
                    enterTime: "",
                    enterpriseId: 41,
                    exitTime: "",
                    fingerprintKey: [],
                    icNumber: "",
                    jobNumber: "",
                    personType: 2,
                    personnelCategory: 2,
                    political: 2,
                    role: 2,
                    roleId: "",
                    safeEducation: 3,
                    safetyEducationContent: "",
                    safetyEducationTime: "",
                    safetyEducationScore: '',
                    consumptionScore: '',
                    signatureKey: "",
                    teamId: "",
                    teamLeader: 3,
                    typeOfPersonnel: 2,
                    wage: "",
                    workId: "",
                    positioningLabel: '',
                    witnessMatching: 2
                }
            },
            
            allParams: {
                bankCard: {
                    bankCardNumber: "",
                    bankName: "",
                    imgKey: ""
                },
                idCard: {
                    address: "", // 地址
                    age: "", //年龄
                    dateOfBirth: "", // 生日
                    effectiveCutoffTime: "",
                    effectiveStartTime: "",
                    issuingAuthority: "",
                    name: "", //姓名
                    nation: "", // 名族
                    number: "", // 身份证号码
                    positiveKey: "",
                    reverseSideKey: "",
                    sex: 2,
                },
                user: {
                    id: "",
                    phone: ""
                },

                info: {
                    avatarKey: "",
                    certificate: [{img: [], name: "", timeEnd: "0", timeStart: "0"}],
                    contractKey: "",
                    contractNo: "",
                    contractProcessing: 2,
                    culture: 2,
                    delivery: 2,
                    dormitoryNumber: "",
                    emergencyContact: [{name: "", phone: ""}],
                    enterStatus: 2,
                    enterTime: "",
                    enterpriseId: 41,
                    exitTime: "",
                    fingerprintKey: [],
                    icNumber: "",
                    jobNumber: "",
                    personType: 2,
                    personnelCategory: 2,
                    political: 2,
                    role: 2,
                    roleId: "",
                    safeEducation: 3,
                    safetyEducationContent: "",
                    safetyEducationScore: '',
                    consumptionScore: '',
                    safetyEducationTime: "",
                    signatureKey: "",
                    teamId: "",
                    teamLeader: 3,
                    typeOfPersonnel: 2,
                    wage: "",
                    workId: "",
                    witnessMatching: 2,
                    positioningLabel: null
                }

            }

        }

        FormSbmitRef = '' // 表单ref
        companyRef = '' // 公司所属组件

       
        componentWillMount() {
            this.getRoleAll()
        }

        // 请求接口 获取角色权限
        getRoleAll() {
            React.$axios.post('roleAll', {enable: 2}).then(res => {
                res.data.roleAll.push({
                   id: 0,
                   name:  '允许使用全部板块权限'
                })

                this.state.allParams.info.roleId = res.data.roleAll[0].id

                this.setState({
                    allParams: this.state.allParams,
                    roleAllList: res.data.roleAll
                })
            })

        }
        

        handleOk() { // 点击确定 去保存
            // 如果是详情
            if(this.state.detatiType) {
                this.setState({
                    visible: false
                })
                return
            }


            this.state.allParams.info.enterpriseId = this.companyRef.state.uiEnterpriseId
            this.state.allParams.info.teamId = this.companyRef.state.uiTeamId
            this.state.allParams.info.workId = this.companyRef.state.uiWorkId

            if(this.companyRef.state.uiEnterpriseId == '') {
                message.error('请选择公司');
                return
            } else if(this.companyRef.state.uiTeamId == '') {
                message.error('请选择部门');
                return
            } else if(this.companyRef.state.uiWorkId == '') {
                message.error('请选择岗位');
                return
            }

            this.FormSbmitRef.submit()

            // 如果出生时间存在
            if(this.state.allParams.idCard.dateOfBirth) {
                if(typeof(this.state.allParams.idCard.dateOfBirth) != 'number' ) {
                    this.state.allParams.idCard.dateOfBirth = parseInt(this.state.allParams.idCard.dateOfBirth._d.getTime()  / 1000)
                }
            }

            
            // 如果进场时间存在
            if(this.state.allParams.info.enterTime) {
                if(typeof(this.state.allParams.info.enterTime) != 'number' ) {
                    this.state.allParams.info.enterTime = parseInt(this.state.allParams.info.enterTime._d.getTime()  / 1000)
                }
            }

            // 如果退场时间存在
            if(this.state.allParams.info.exitTime) {
                if(typeof(this.state.allParams.info.exitTime) != 'number' ) {
                    this.state.allParams.info.exitTime = parseInt(this.state.allParams.info.exitTime._d.getTime()  / 1000)
                }
                
            }

            // 如果安全教育时间存在
            if(this.state.allParams.info.safetyEducationTime) {
                if(typeof(this.state.allParams.info.safetyEducationTime) != 'number' ) {
                    this.state.allParams.info.safetyEducationTime = parseInt(this.state.allParams.info.safetyEducationTime._d.getTime()  / 1000)
                }
                
            }


            
            this.state.allParams.info.certificate.map(item => {
                if(item.timeEnd) {
                    if(typeof(item.timeEnd) != 'number') {
                        item.timeEnd = parseInt(item.timeEnd._d.getTime()  / 1000)
                    }
                    
                }
                if(item.timeStart) {
                    if(typeof(item.timeStart) != 'number') {
                        item.timeStart = parseInt(item.timeStart._d.getTime()  / 1000)
                    } 
                }
            })


            if(this.state.allParams.info.witnessMatching) {
                this.state.allParams.info.witnessMatching = 2
            } else {
                this.state.allParams.info.witnessMatching = 3
            }
            
            // 去替换带*的数据 如果存在*号则为空 否则不处理
            this.state.allParams.idCard.number =  this.isStar(this.state.allParams.idCard.number);
            this.state.allParams.user.phone =  this.isStar(this.state.allParams.user.phone);
            this.state.allParams.bankCard.bankCardNumber = this.isStar(this.state.allParams.bankCard.bankCardNumber);
            this.state.allParams.info.teamLeader =  this.state.allParams.info.teamLeader ? 2 : 3;
            React.$axios.post('userEditSave', this.state.allParams).then(res => {

                if(res.code == 20000) {
                    message.success('保存成功')
                    this.handleCancel()
                    // 返回结果
                    this.props.getResult()  
                }
            })

        }

        isStar(str) { // 判断是为有星号键

            let index = str.indexOf("****");

            return index == -1 ?str:"";
        }


        handleCancel(e) {

             // 清空数据
             this.reRever()
            
            this.setState({
                visible: false
            })
        }

        callback(e) {

        }

        beforeUpload() {

        }


        clierFormSbmitRef() {
            if(!this.FormSbmitRef) {
                setTimeout(() => {
                  
                    this.FormSbmitRef.resetFields()
                }, 500)
            } else {
                this.FormSbmitRef.resetFields()
            }
          }


        /**
         * 
         * @param {*} type  2  详情 
         */
        getType(type) {
            if(type == 2) {
                this.setState({
                    detatiType: true
                })
            } else {
                this.setState({
                    detatiType: false
                })
            }
        }


        setCancel(id, type) {
            let title = '新增'

            this.getType(type)
          
            this.setState({
                visible: true
            })
            
            if(id) {
                React.$axios.post('infoUser', {id: id.id}).then(res => {
                    
                    // console.log(res)    
                    // fingerprintKey

                    for(let i in this.state.allParams.info) {
                        this.state.allParams.info[i] = res.data.infoUser.infoHasOne[i]
                    }

                    for(let i in this.state.allParams.bankCard) {
                        this.state.allParams.bankCard[i] = res.data.infoUser.bankCardHasOne[i]
                    }

                   
                    if(this.state.allParams.info.fingerprintKey) {
                        this.state.allParams.info.fingerprintKey =  this.state.allParams.info.fingerprintKey.split(',')
                    } else {
                        this.state.allParams.info.fingerprintKey = []
                    }

 
                    this.state.allParams.user.phone = res.data.infoUser.phone
                    this.state.allParams.user.id = res.data.infoUser.id
                    


                    if(!this.state.allParams.info.emergencyContact) {
                        this.state.allParams.info.emergencyContact = [{
                            name: "", phone: ""
                        }]
                    } else {
                        this.state.allParams.info.emergencyContact = JSON.parse(this.state.allParams.info.emergencyContact)
                    }

                    try {
                        if(!this.state.allParams.info.certificate) {
                            this.state.allParams.info.certificate = [{img: [], name: "", timeEnd: "", timeStart: ""}]
                        } else {
                            this.state.allParams.info.certificate = JSON.parse(this.state.allParams.info.certificate)
    
                           
                            this.state.allParams.info.certificate.map(item => {
                                item.img = !item.img? []:  item.img
                                item.timeEnd = !item.timeEnd?'': moment(item.timeEnd * 1000) 
                                item.timeStart = !item.timeStart?'': moment(item.timeStart * 1000) 
                            }) 
                        }
    
                      
                        if(this.state.allParams.info.enterTime) {
                             this.state.allParams.info.enterTime = moment(this.state.allParams.info.enterTime * 1000) //new Date()
                        } else {
                            this.state.allParams.info.enterTime = ''
                        }
    
                        if(this.state.allParams.info.exitTime) {
                            this.state.allParams.info.exitTime =moment(this.state.allParams.info.exitTime * 1000)
                        } else {
                            this.state.allParams.info.exitTime = ''
                        }
    
                        if(this.state.allParams.info.safetyEducationTime) {
                           
                            this.state.allParams.info.safetyEducationTime =moment(this.state.allParams.info.safetyEducationTime * 1000)
                        } else {
                            this.state.allParams.info.safetyEducationTime = ''
                        }


                    } catch(e){
                        console.error(e)
                    }
                    


                    
                    for(let i in this.state.allParams.idCard) {
                        this.state.allParams.idCard[i] = res.data.infoUser.idCardHasOne[i]
                    }


                    if( this.state.allParams.idCard.dateOfBirth) {
                        if(typeof(this.state.allParams.idCard.dateOfBirth) == 'string') {
                            if(this.state.allParams.idCard.dateOfBirth == 0) {
                                this.state.allParams.idCard.dateOfBirth = null
                            }else {
                                this.state.allParams.idCard.dateOfBirth = this.state.allParams.idCard.dateOfBirth.replace(/-/g, '/')
                                this.state.allParams.idCard.dateOfBirth = moment(this.state.allParams.idCard.dateOfBirth,  'YYYY/MM/DD')
                            }
                            
                            
                        } else {
                            this.state.allParams.idCard.dateOfBirth = moment(this.state.allParams.idCard.dateOfBirth * 1000)
                        }

                    } else {
                        this.state.allParams.idCard.dateOfBirth = null
                    }

                    console.log(this.state.allParams.idCard.dateOfBirth);


                    title = '编辑'
                    if(this.state.detatiType) {
                        title = '详情'
                    }


                    this.setState({
                        allParams: this.state.allParams,
                        titleName: title,
                        teamParams: {
                            uiEnterpriseId:  this.state.allParams.info.enterpriseId,
                            uiTeamId: this.state.allParams.info.teamId,
                            uiWorkId: this.state.allParams.info.workId,
                        }
                    })

                    try {
                        this.clierFormSbmitRef()
                        this.companyRef.defaultData(res.data.infoUser)
                    }catch(e) {
                        console.error(e)
                    }

                    
                    console.log()
                    
                })     

               
            } else {

                 this.state.allParams.idCard.name = ''
                 
                 

                title = '新增'
                this.state.allParams.info.roleId = this.state.roleAllList[0].id

                this.reRever()



                this.setState({
                    titleName: title,
                    allParams: this.state.allParams
                })

                

                try {
                    this.clierFormSbmitRef()
                    this.companyRef.defaultData()

                } catch(e) {
                    // console.error(e)
                } 
            }

        }


        // 更新数据  从新赋值
        reRever() {
             
             for(let i in this.state.allParams.idCard) {
                this.state.allParams.idCard[i] = this.state.copyAllParams.idCard[i]
             }

             for(let i in this.state.allParams.info) {
                this.state.allParams.info[i] = this.state.copyAllParams.info[i]
             }

             for(let i in this.state.allParams.bankCard) {
                 this.state.allParams.bankCard[i] = this.state.copyAllParams.bankCard[i]
             }

             for(let i in this.state.allParams.user) {
                this.state.allParams.user[i] = this.state.copyAllParams.user[i]
             }


        }


        
        // 上传
        handleFront(e) { // 身份证 正面
            let data2 = e.data.idCard.front

            if(data2.idcard_number_type == -1) {
                message.error('请选择身份证正面')
                return
            }

            this.state.allParams.idCard.address = data2.words_result['住址'].words
            this.state.allParams.idCard.number = data2.words_result['公民身份号码'].words

            let str = data2.words_result['出生'].words
            let str1 = str.slice(0, 4)
            let str2 = str.slice(4, 6)
            let str3 = str.slice(6, 8)
            

            this.state.allParams.idCard.dateOfBirth =  moment(str1 + '/' + str2 + '/' + str3,  'YYYY/MM/DD');
            this.state.allParams.idCard.name = data2.words_result['姓名'].words;
            this.state.allParams.idCard.sex = data2.words_result['性别'].words =='男'? 2: 3;
            this.state.allParams.idCard.nation = data2.words_result['民族'].words;


            this.state.allParams.idCard.positiveKey = e.data.idCard.front.keyOss;


            this.setState({
                allParams: this.state.allParams,
            })

            try {
                this.clierFormSbmitRef()

            } catch(e) {
                // console.error(e)
            } 
            return e.data.idCard.front.keyOss
        }

        handleBack(e) { // 身份证 反面
            let data2 = e.data.idCard.back
            
            if(data2.idcard_number_type == -1) {
                message.error('请选择身份证背面')
                return
            }

            this.state.allParams.idCard.issuingAuthority =  data2.words_result['签发机关'].words
            this.state.allParams.idCard.effectiveStartTime  = data2.words_result['签发日期'].words
            this.state.allParams.idCard.effectiveCutoffTime = data2.words_result['失效日期'].words
            this.state.allParams.idCard.reverseSideKey = e.data.idCard.back.keyOss
            

            this.setState({
                allParams:  this.state.allParams
            })
            
            try {
                this.clierFormSbmitRef()

            } catch(e) {
                // console.error(e)
            } 

            return e.data.idCard.back.keyOss
        }

        handleFace(e) { // 现场照片上传
            this.state.allParams.info.avatarKey = e.data.key[0]
            
            this.setState({
                allParams: this.state.allParams
            })

            return e.data.key[0]
        }


        handlebank(e) { // 银行卡照片

            this.state.allParams.bankCard.imgKey  =  e.data.key[0]

            this.setState({
                allParams: this.state.allParams
            })

            return e.data.key[0]
        }

        handleSing(e) { // 签名照片

            this.state.allParams.info.signatureKey = e.data.key[0]
            this.setState({
                allParams:  this.state.allParams          
            })

            return e.data.key[0]
        }

        handleFinger(e) { // 指纹照片
           

            this.state.allParams.info.fingerprintKey = [...this.state.allParams.info.fingerprintKey, ...e.data.key]

            this.setState({
                allParams: this.state.allParams
            })

        }
        
        handleContract(e) { // 劳动合同照片
            this.state.allParams.info.contractKey = e.data.key[0]
            this.setState({
                allParams: this.state.allParams
            })

            return e.data.key[0]
        }

        handleSpecial(index,e) { // 特种作业证书图片
            let params = this.state.allParams.info.certificate[index]

            params.img = [...params.img, ...e.data.key]

            this.setState({
               allParams: this.state.allParams
            })

        }


        reMoveImgList(index, name) {// 删除图片集合

            this.state.allParams.info.fingerprintKey.splice(index, 1)

            this.setState({
                allParams: this.state.allParams
            })
        }


        reMoveImgListSpecial(index, index2) { // 删除特种作业证书图片
            let params = this.state.allParams.info.certificate[index]
            params.img.splice(index2, 1)
            this.setState({
                allParams: this.state.allParams
            })
        }

        PlusOutClickSpecial(index) {
            if(index == -1) {
                
                this.state.allParams.info.certificate.push({
                    img: [],
                    name: "",
                    timeEnd: "",
                    timeStart: ""
                })
            } else {
                this.state.allParams.info.certificate.splice(index, 1)
            }

            

            this.setState({
                allParams: this.state.allParams
            })
            
        }

    
        onChangeRadio() { // 单选框

        }

        setInput(name1, name2, { target: { value } }) { // 所有输入框事件
            this.state.allParams[name1][name2] = value
            this.setState({
                allParams: this.state.allParams
            })

        }

        handleChange(name1, name2, value) { // select 切换事件
            // if(value == true) {
            //     value = 2
            // } else if(value == false) {
            //     value = 3
            // }

            this.state.allParams[name1][name2] = value

            this.setState({
                allParams: this.state.allParams
            })
        }

        SwitchChange( name1, name2, { target: { value } }) { // 单选切换
            console.log(name1, name2, value)
            this.state.allParams[name1][name2] = value
            this.setState({
                allParams: this.state.allParams
            })
        }

        // Form 提交按钮
        onFinish(value) {
            
        }

        // 紧急联系人添加
        PlusOutClick(index, e) {

            if(index != -1) {
                this.state.allParams.info.emergencyContact.splice(index, 1)
            } else {
                this.state.allParams.info.emergencyContact.push({})
            }

 
            this.setState({
                allParams: this.state.allParams
            })
        }

        // 紧急联系人
        inputEmergencyContact(name, index, {target: {value}}) {
            this.state.allParams.info.emergencyContact[index][name] = value

            this.setState({
                allParams: this.state.allParams
            })
        }

        // 特种作业证书
        SpecialInput(name, index, {target: {value}}) {
            this.state.allParams.info.certificate[index][name] = value
            this.setState({
                allParams: this.state.allParams
            })
        }

        // 时间选择
        onChangeDate(name1, name2, value) {
            this.state.allParams[name1][name2] = value
        }

        // 特种作业时间选择
        onChangeDateSpecial(name1, name2, index, value) {
            this.state.allParams.info.certificate[index][name2] = value
            this.setState({
                allParams: this.state.allParams
            })
            return name1
        }


        render() {
            return <Modal
                title={this.state.titleName}
                width="80%"
                okText="确定"
                cancelText="取消"
                className="userListModeal"
                visible={this.state.visible}
                onOk={this.handleOk.bind(this)}
                onCancel={this.handleCancel.bind(this)}
            >
                <Form
                            layout="vertical"
                            onFinish={this.onFinish.bind(this)}
                            validateMessages={validateMessages}
                            ref={(el) => this.FormSbmitRef = el}
                            initialValues = {this.state.allParams}                                                    
                        >

                <Tabs onChange={this.callback} type="card">
                    <TabPane tab="用户详情" key="1">
                        
                            <div className="allWarp">
                            <div className="warp">

                                <div className="leftWarp">
                                    <Form.Item label="ID-默认填写身份证号码" required
                                        name={['idCard' ,'number']}
                                        

                                        rules={[{ required: true, message: '请填写身份证号码'}]}
                                    >
                                        <Input placeholder="身份证号码" value={this.state.allParams.idCard.number} onChange={this.setInput.bind(this, 'idCard','number')} />
                                    </Form.Item>

                                    
                                    <Form.Item label="是否允许该人使用账号登陆本系统" name={['info' ,'role']}
                                        rules={[{ required: true, message: ''}]}
                                    >
                                        {/* <Input placeholder="" /> */}
                                        {/* <Select value={this.state.allParams.info.role}  onChange={this.handleChange.bind(this, 'info', 'role')}>
                                            <Option value={3}>项目管理人员</Option>
                                            <Option value={2}>劳务人员</Option>
                                        </Select> */}

                                        <Radio.Group onChange={this.SwitchChange.bind(this, 'info', 'role')}>
                                            <Radio value={3}>是</Radio>
                                            <Radio value={2}>否</Radio>
                                        </Radio.Group>




                                    </Form.Item>
                                    <Form.Item 
                                        rules={[{ required: true, message: ''}]}
                                    label='权限分配' name={['info' ,'roleId']} >
                                        <Select value={this.state.allParams.info.roleId} placeholder="请选择角色权限"  onChange={this.handleChange.bind(this, 'info', 'roleId')}>
                                            {
                                                this.state.roleAllList.map((item, index) => {
                                                    return (
                                                        <Option value={item.id} key={index}>{item.name}</Option>
                                                    )
                                                })
                                            }

                                        </Select>

                                    </Form.Item>

                                    <p style={{fontSize: 10, color:'#1890FF', marginBottom: 20}}>可通过"角色管理{'>'}角色权限"中自定义子板块访问权限,然后可设定该人登陆后仅可访问指定功能板块,注意:如允许该人可以访问全部功能,则选择"允许使用全部板块权限"</p>        

                                    <Form.Item label="人员类型" required  name={['info' , 'personType']}>
                                        <Radio.Group onChange={this.SwitchChange.bind(this, 'info', 'personType')}>
                                            <Radio value={2}>员工</Radio>
                                            <Radio value={3}>临时员工</Radio>
                                        </Radio.Group>
                                    </Form.Item> 

                                    <Form.Item label="所属单位人员类型" required  name={['info' , 'typeOfPersonnel']}>
                                        <Select onChange={this.handleChange.bind(this, 'info', 'typeOfPersonnel')}>
                                            <Option value={2}>劳务工人</Option>
                                            <Option value={3}>建设单位管理人员</Option>
                                            <Option value={4}>监理单位管理人员</Option>
                                            <Option value={5}>施工单位管理人员</Option>
                                            <Option value={6}>其它参建单位管理人员</Option>
                                            <Option value={7}>设计单位管理人员</Option>
                                        </Select>   
                                    </Form.Item> 
                                       
                                </div>


                                <div className="flex">
                                    <div className="setMargin20">
                                    <Form.Item label="身份证正面" >
                                        
                                        {
                                            function () {
                                                if(this.state.detatiType) {
                                                    return (
                                                        <Image
                                                            width={300} height={250}
                                                            src={this.state.imageUrl + this.state.allParams.idCard.positiveKey}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <Uploadhh   path = "idCardBaidu" name="front" imageUrl={this.state.allParams.idCard.positiveKey}  width={300} height={250} handleClick={this.handleFront.bind(this)} />
                                                    )
                                                }
                                            }.bind(this)()
                                        }    
                                    
                                    </Form.Item>     
                                    </div>
                                    <div className="setMargin20">
                                    <Form.Item label="身份证反面"  >
                                        
                                    
                                        {
                                            function () {
                                                if(this.state.detatiType) {
                                                    return (
                                                        <Image
                                                            width={300} height={250}
                                                            src={this.state.imageUrl + this.state.allParams.idCard.reverseSideKey}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <Uploadhh  path = "idCardBaidu" name="back" imageUrl={this.state.allParams.idCard.reverseSideKey} data={{status: 1}}  width={300} height={250} handleClick={this.handleBack.bind(this)} />
                                                    )
                                                }
                                            }.bind(this)()
                                        } 
                                    </Form.Item>    
                                    </div>
                                    <div className="setMargin20">
                                    <Form.Item label="现场照片"  >
                                        
                                        
                                        {
                                            function () {
                                                if(this.state.detatiType) {
                                                    return (
                                                        <Image
                                                            width={300} height={250}
                                                            src={this.state.imageUrl + this.state.allParams.info.avatarKey}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <Uploadhh  path = "fileUploadOss" name="image[]" imageUrl={ this.state.allParams.info.avatarKey}   data={{type: 5}}  width={300} height={250} handleClick={this.handleFace.bind(this)} />
                                                    )
                                                }
                                            }.bind(this)()
                                        }   
                                        
                                    
                                    </Form.Item>
                                    </div>
                                </div>

                                <div className="rightData">
                                <Form.Item label="人证审核"  name={['info', 'witnessMatching']}>
                                    <Switch defaultChecked={this.state.allParams.info.witnessMatching == 2? true:false}  onChange={this.handleChange.bind(this, 'info', 'witnessMatching')} />
                                </Form.Item>   
                                <Form.Item label="是否班长" name={['info', 'teamLeader']}>
                                    <Switch defaultChecked={this.state.allParams.info.teamLeader == 2? true:false} onChange={this.handleChange.bind(this, 'info', 'teamLeader')} />           
                                </Form.Item>    
                                </div>

                            </div>    

                            <Company ref={(el) => this.companyRef = el} Status={false} fromStatus ={false} allParams={this.state.teamParams} width={300} setStyle={{display : 'flex', justifyContent: 'space-between'}}  />

                            <ul className="ul">
                                <li>
                                <Form.Item label="人员类别" name={['info' ,'personnelCategory']}>
                                        <Select onChange={this.handleChange.bind(this, 'info', 'personnelCategory')}>
                                            <Option value={2}>一般作业人员</Option>
                                            <Option value={3}>特种作业人员</Option>
                                            <Option value={4}>项目管理人员</Option>
                                            <Option value={5}>其它人员</Option>
                                        </Select>
                                </Form.Item> 

                                <Form.Item label="人员定位"  name={['info' ,'positioningLabel']}>
                                    <Input placeholder="请输入人员定位" onChange={this.setInput.bind(this, 'info','positioningLabel')} />   
                                </Form.Item>

                                <Form.Item label="性别"  name={['idCard' ,'sex']}>
                                    <Radio.Group onChange={this.SwitchChange.bind(this, 'idCard', 'sex')} >
                                        <Radio value={2}>男</Radio>
                                        <Radio value={3}>女</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item label="年龄"  name={['idCard' ,'age']}>
                                    <Input placeholder="请输入年龄" onChange={this.setInput.bind(this, 'idCard','age')} />   
                                </Form.Item>

                                 <Form.Item label="有效期限" name={['idCard','effectiveCutoffTime']}>
                                    <Input placeholder="请输入身份证有效期限" value={this.state.allParams.idCard.effectiveCutoffTime} onChange={this.setInput.bind(this, 'idCard','effectiveCutoffTime')}  />   
                                </Form.Item>

                                  <Form.Item label="政治面貌" name={['info','political']}>
                                        <Select onChange={this.handleChange.bind(this, 'info', 'political')}>
                                            <Option value={2}>中共党员</Option>
                                            <Option value={3}>中共预备党员</Option>
                                            <Option value={4}>共青团员</Option>
                                            <Option value={5}>民革党员</Option>
                                            <Option value={6}>民盟盟员</Option>
                                            <Option value={7}>民建会员</Option>
                                            <Option value={8}>民进会员</Option>
                                            <Option value={9}>农工党党员</Option>
                                            <Option value={10}>致公党党员</Option>
                                            <Option value={11}>九三学社社员</Option>
                                            <Option value={12}>台盟盟员</Option>
                                            <Option value={13}>无党派人士</Option>
                                            <Option value={14}>群众</Option>
                                            <Option value={15}>其他</Option>
                                        </Select> 
                                </Form.Item>

                                <Form.Item label="工资（元）"  name={['info' ,'wage']}>
                                    <Input placeholder="请输入工资" onChange={this.setInput.bind(this, 'info','wage')} />   
                                </Form.Item>

                                 <Form.Item label="紧急联系人" >
                                    <ul className="personUl">
                                        {

                                           this.state.allParams.info.emergencyContact.map((item,index) => {
                                               if(this.state.allParams.info.emergencyContact.length == index+1) {
                                                    return (
                                                        <li key={index}>
                                                            <p>
                                                                <Input placeholder="姓名" value={item.name} onChange={this.inputEmergencyContact.bind(this, 'name', index)}  />  
                                                            </p> 
                                                            <p>
                                                            <Input placeholder="电话" value={item.phone} onChange={this.inputEmergencyContact.bind(this, 'phone', index)} />  
                                                            </p>
                                                            <p>
                                                                {
                                                                    function() {
                                                                        if(this.state.detatiType) {

                                                                        } else {
                                                                            return (
                                                                                <Button type="primary" icon={<PlusOutlined />} index={index} onClick={this.PlusOutClick.bind(this, -1)}></Button>
                                                                            )
                                                                        }
                                                                    }.bind(this)()
                                                                }
                                                            </p>
                                                        </li>
                                                    )
                                               } else {
                                                    return (
                                                        <li key={index}>
                                                            <p>
                                                                <Input value={item.name} onChange={this.inputEmergencyContact.bind(this, 'name', index)}  placeholder="姓名" />  
                                                            </p> 
                                                            <p>
                                                            <Input value={item.phone} onChange={this.inputEmergencyContact.bind(this, 'phone', index)}  placeholder="电话" />  
                                                            </p>
                                                            <p>
                                                            <Button type="primary" icon={<MinusOutlined />} index={index} onClick={this.PlusOutClick.bind(this, index)}></Button>
                                                            </p>
                                                        </li>
                                                    )
                                               }
                                                

                                           })
                                         }   

                                    </ul>
                                </Form.Item>


                                </li>
                                <li>
                                <Form.Item label="手机号码" required  name={['user', 'phone']}
                                 rules={[{ required: true, message: '请填写手机号码'}]}>
                                    <Input placeholder="请输入手机号码" value={this.state.allParams.user.phone}  onChange={this.setInput.bind(this, 'user','phone')}  />   
                                </Form.Item> 

                                <Form.Item label="民族"   name={['idCard', 'nation']}>
                                    <Input placeholder="请输入民族" value={this.state.allParams.idCard.nation} onChange={this.setInput.bind(this, 'idCard','nation')} />
                                </Form.Item>

                                <Form.Item label="地址" name={['idCard', 'address']}>
                                    <Input placeholder="请输入地址" value={this.state.allParams.idCard.address}  onChange={this.setInput.bind(this, 'idCard','address')} />   
                                </Form.Item>

                                 <Form.Item label="工号" name={['info', 'icNumber']}>
                                    <Input placeholder="请输入工号" value={this.state.allParams.info.icNumber} onChange={this.setInput.bind(this, 'info','icNumber')} />   
                                </Form.Item>

                                <Form.Item label="是否安全教育"  name={['info', 'safeEducation']}>
                                    <Radio.Group  onChange={this.SwitchChange.bind(this, 'info', 'safeEducation')}>
                                        <Radio value={2}>是</Radio>
                                        <Radio value={3}>否</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item label="安全教育时间" name={['info','safetyEducationTime']}>
                                    {/* <Input placeholder="input placeholder" /> */}
                                    <DatePicker onChange={this.onChangeDate.bind(this, 'info', 'safetyEducationTime')}  placeholder="请选择日期" style={{width: 300}} />
                                </Form.Item> 
                                <Form.Item label="进退场"  name={['info', 'enterStatus']}>
                                        <Select onChange={this.handleChange.bind(this, 'info', 'enterStatus')}>
                                            <Option value={2}>进场</Option>
                                            <Option value={3}>退场</Option>
                                        </Select>
                                </Form.Item>
                                <Form.Item label="退场时间" name={['info', 'exitTime']}>
                                   
                                    <DatePicker onChange={this.onChangeDate.bind(this, 'info', 'exitTime')} placeholder="请选择日期" style={{width: 300}} />
                                </Form.Item>     
                                </li>
                                <li>
                                <Form.Item label="姓名" name={['idCard', 'name']} required rules={[{ required: true, message: '请填写姓名'}]}>
                                    <Input placeholder="请输入姓名" value={this.state.allParams.idCard.name} onChange={this.setInput.bind(this, 'idCard','name')} />   
                                </Form.Item> 

                                <Form.Item label="出生日期"  name={['idCard', 'dateOfBirth']} >
                                    <DatePicker onChange={this.onChangeDate.bind(this, 'idCard', 'dateOfBirth')} placeholder="请选择日期" style={{width: 300}}  />
                                </Form.Item>

                                <Form.Item label="发证机关" name={['idCard', 'issuingAuthority']}>
                                    <Input placeholder="请输入发证机关" onChange={this.setInput.bind(this, 'idCard','issuingAuthority')} />   
                                </Form.Item>

                                <Form.Item label="文化程度" name={['info','culture']}>
                                         <Select onChange={this.handleChange.bind(this, 'info', 'culture')}>
                                            <Option value={2}>小学</Option>
                                            <Option value={3}>初中</Option>
                                            <Option value={4}>中专</Option>
                                            <Option value={5}>高中</Option>
                                            <Option value={6}>专科</Option>
                                            <Option value={7}>本科</Option>
                                            <Option value={8}>硕士研究生</Option>
                                            <Option value={9}>博士研究生</Option>
                                            <Option value={10}>其他</Option>
                                        </Select>
                                </Form.Item>

                                <Form.Item label="安全教育总分"  name={['info','safetyEducationScore']}>
                                    <Input placeholder="请输入" value={this.state.allParams.info.safetyEducationScore} onChange={this.setInput.bind(this, 'info','safetyEducationScore')} />   
                                </Form.Item>

                                <Form.Item label="消费安全教育分"  name={['info','consumptionScore']}>
                                    <Input placeholder="请输入" value={this.state.allParams.info.consumptionScore} onChange={this.setInput.bind(this, 'info','consumptionScore')} />   
                                </Form.Item>

                                <Form.Item label="安全教育内容"  name={['info','safetyEducationContent']}>
                                    <Input placeholder="请输入" value={this.state.allParams.info.safetyEducationContent} onChange={this.setInput.bind(this, 'info','safetyEducationContent')} />   
                                </Form.Item>

                                <Form.Item label="宿舍号"  name={['info', 'dormitoryNumber']}>
                                    <Input placeholder="请输入" value={this.state.allParams.info.dormitoryNumber} onChange={this.setInput.bind(this, 'info','dormitoryNumber')} />   
                                </Form.Item>

                                <Form.Item label="进场时间" name={['info', 'enterTime']}>
                                   
                                    <DatePicker onChange={this.onChangeDate.bind(this, 'info', 'enterTime')}  value={this.state.allParams.info.enterTime}  placeholder="请选择日期" style={{width: 300}} />
                                </Form.Item>   
                                </li>
                            </ul>
                            </div>
                        
        

                            
                    </TabPane>
                    <TabPane tab="用户银行卡" key="2">
                        <div className="bankCard flex">
                            <div className="bankMsg setMargin60">
                                <div>
                                    <Form.Item label="开户行" name={['bankCard','bankName']}>
                                        <Input placeholder="请输入开户行" value={this.state.allParams.bankCard.bankName}  onChange={this.setInput.bind(this, 'bankCard','bankName')}  />   
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item label="银行卡号" name={['bankCard','bankCardNumber']}>
                                        <Input placeholder="请输入银行卡号" value={this.state.allParams.bankCard.bankCardNumber} onChange={this.setInput.bind(this, 'bankCard','bankCardNumber')}  />   
                                    </Form.Item>
                                </div>
                                
                                
                            </div>

                            <div>
                                <div className="setMargin150">
                                    <Form.Item label="银行卡照片"  >
                                        
                                         
                                        {
                                            function () {
                                                if(this.state.detatiType) {
                                                    return (
                                                        <Image
                                                            width={300} height={250}
                                                            src={this.state.imageUrl + this.state.allParams.bankCard.imgKey}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <Uploadhh  imageUrl={this.state.allParams.bankCard.imgKey}  path = "fileUploadOss" name="image[]" data={{type: 2}}  width={300} height={250} handleClick={this.handlebank.bind(this)} />
                                                    )
                                                }
                                            }.bind(this)()
                                        }    

                                    </Form.Item>     
                                </div>
                            </div>


                        </div>
                    </TabPane>
                    <TabPane tab="签字证件" key="3">
                         <div className="singStyle flex">
                            <div className="setMargin60 setWidth">
                                <Form.Item label="签名"  >
                                    {
                                    function () {
                                        if(this.state.detatiType) {
                                            return (
                                                <Image
                                                    width={300} height={250}
                                                    src={this.state.imageUrl + this.state.allParams.info.signatureKey}
                                                />
                                            )
                                        } else {
                                            return (
                                                <Uploadhh  imageUrl={this.state.allParams.info.signatureKey}  path = "fileUploadOss" name="image[]" data={{type: 6}}  width={300} height={250} handleClick={this.handleSing.bind(this)} />
                                            )
                                        }
                                    }.bind(this)()
                                }       
                                
                                
                                </Form.Item>

                                        


                            </div>

                            <div className="setMargin150">

                                <Form.Item label="指纹" >
                                    <div className="flex">
                                    {
                                        <ul className="setFingerList flex">
                                            <Image.PreviewGroup>
                                               {
                                                   this.state.allParams.info.fingerprintKey.map((item, index) => {
                                                       return (
                                                           <span style={{marginRight: 20, position: 'relative'}} key={index}>
                                                            <span className="removeImgList" onClick={()=> this.reMoveImgList(index, 'fingerList')}>×</span>
                                                        <Image
                                                            width={100}
                                                            height={100}
                                                            src={this.state.imageUrl + item}
                                                        />
                                                        </span>
                                                       )
                                                   })
                                               } 
                                            </Image.PreviewGroup>    
                                        </ul> 
                                    }
                       
                                    

                                    {
                                    function () {
                                        if(this.state.detatiType) {
                                           
                                            } else {
                                                return (
                                                    <Uploadhh  listStatus={true}   data={{type: 7}}  width={100} height={100} handleClick={this.handleFinger.bind(this)} />
                                                )
                                            }
                                        }.bind(this)()
                                    }  
                                  </div>
                                </Form.Item> 
                            </div>


                         </div>   
                    </TabPane>
                    <TabPane tab="劳动合同及附属" key="4">
                    <div className="bankCard flex">
                            <div className="bankMsg setMargin60">
                                <div>
                                    <Form.Item label="合同编号" name={['info','contractNo']}>
                                        <Input  placeholder="请输入劳动合同" onChange={this.setInput.bind(this, 'info','contractNo')}  />   
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item label="合同办理">
                                    <Radio.Group  name={['info','contractProcessing']}   onChange={this.SwitchChange.bind(this, 'info', 'contractProcessing')}>
                                            <Radio value={2}>是</Radio>
                                            <Radio value={3}>否</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                
                                
                            </div>

                            <div>
                                <div className="setMargin150">
                                    <Form.Item label="合同附件" >
 
                                        {
                                            function () {
                                                if(this.state.detatiType) {
                                                    return (
                                                        <Image
                                                            width={300} height={250}
                                                            src={this.state.imageUrl + this.state.allParams.info.contractKey}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <Uploadhh imageUrl={this.state.allParams.info.contractKey}  data={{type: 9}}  width={300} height={250} handleClick={this.handleContract.bind(this)} />
                                                    )
                                                }
                                            }.bind(this)()
                                        }   
                                    
                                    </Form.Item>     
                                </div>

                            </div>


                        </div>
                    </TabPane>
                    <TabPane tab="特种作业证书" key="5">
                           <p>特种作业证</p>

                            <ul className="specialType">
                                    {
                                        this.state.allParams.info.certificate.map((item2,index2) => {
                                            return (
                                                <li style={{marginTop:20}} key={index2}>
                                                    <div className="flex">
                                                        {
                                                            <ul className="setFingerList flex">
                                                                <Image.PreviewGroup>
                                                                {
                                                                    item2.img.map((item, index) => {
                                                                        return (
                                                                            <span key={index} style={{marginRight: 20, position: 'relative'}}>
                                                                                <span className="removeImgList" onClick={()=> this.reMoveImgListSpecial(index2,index)}>×</span>
                                                                            <Image
                                                                                width={100}
                                                                                height={100}
                                                                                src={this.state.imageUrl + item}
                                                                            />
                                                                            </span>
                                                                        )
                                                                    })
                                                                } 
                                                                </Image.PreviewGroup>    
                                                            </ul> 
                                                        }
                                        
                                                        
                                                    
                                                        {
                                                            function () {
                                                                if(this.state.detatiType) {
                                                                    
                                                                } else {
                                                                    return (
                                                                        <Uploadhh  listStatus={true}   data={{type: 9}}  width={100} height={100} handleClick={(e)=> this.handleSpecial(index2,e)} />
                                                                    )
                                                                }
                                                            }.bind(this)()
                                                        } 
                                                    
                                                    </div>

                                                        <ul className="personUl personUl2">
                                                                
                                                                <li>
                                                                    <p>
                                                                        <Input placeholder="名称"  onChange={this.SpecialInput.bind(this, 'name',index2)} value={item2.name}  />  
                                                                    </p> 
                                                                    <p>

                                                                    <DatePicker onChange={this.onChangeDateSpecial.bind(this, 'info', 'timeStart', index2)} value={item2.timeStart}  placeholder="有效期开始日期" />  
                                                                    </p>
                                                                    <p>
                                                                   
                                                                    <DatePicker onChange={this.onChangeDateSpecial.bind(this, 'info', 'timeEnd', index2)}  value={item2.timeEnd} placeholder="有效期结束日期" />   
                                                                    </p>
                                                                    <p>
                                                                        {
                                                                            function() {
                                                                                if(this.state.detatiType) {
                                                                                    return
                                                                                }
                                                                                if(this.state.allParams.info.certificate.length == index2+1) {
                                                                                    return (
                                                                                        
                                                                                         <Button type="primary" icon={<PlusOutlined />} onClick={this.PlusOutClickSpecial.bind(this, -1)}></Button>   
                                                                                        )
                                                                                } else {
                                                                                    return (
                                                                                         <Button type="primary" icon={<MinusOutlined />} onClick={this.PlusOutClickSpecial.bind(this, index2)}></Button>
                                                                                    )
                                                                                }
                                                                            }.bind(this)()
                                                                        }

                                                                    
                                                                    </p>
                                                                </li>

                                                        </ul>


                                                </li>  
                                            )
                                        })
                                    }


                                      
                                
                            </ul>        

                    </TabPane>
                </Tabs>
                </Form>

            </Modal>
        }
    }
);