


import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Form, Button, DatePicker, Table, Input, Select, Tabs  } from 'antd';

import { SearchOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatTime } from '@/utils/util.js'

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

import image1 from '@/assets/img/coordination/11.png';

import * as echarts from 'echarts';

import './MixSerch.less';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;


let jsonFieldsList =  {
    '0_小票号': 'FNo',
    '1_生产日期':{
        field: 'FScrq',
            callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
            }
        },
    "2_时间": {
        field: 'FVersion',
            callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
            }
        },
    "3_拌台": 'FScbt',
    "4_砼品种":"FTpz",

    "5_车数": 'FLjcs',
    "6_累计": 'FLjfs',
    "7_车号": "FShch",
    "7_司机": "FSjxm",
    "8_余方量": "FRvA",
    "9_拌方量" : "FRvB",
    "10_退方量" : "FQvA",
    "11_总重量": "FZzl",
    "12_操作员": "FCzy"
  }



function MixSerch() {
        const columns = [
            {
                title: '设备编号',
                dataIndex: 'sn',
                align: 'center',
            },
            {
                title: '编号',
                dataIndex: 'FPhbh',
                align: 'center',
            },
            {
                title: '配合比号',
                dataIndex: 'FPhbNo',
                align: 'center',
            },
            {
                title: '砼品种',
                dataIndex: 'FTpz',
                align: 'center',
            },

            {
                title: '水泥品种',
                dataIndex: 'FSnpz',
                align: 'center',
            },
            {
                title: '坍落度',
                dataIndex: 'FTld',
                align: 'center',
            },
            {
                title: '石子规格',
                dataIndex: 'FSzgg',
                align: 'center',
            },
            {
                title: '用途',
                dataIndex: 'FYt',
                align: 'center',
            },
            {
                title: '功率曲线',
                dataIndex: 'FPhbNoC',
                align: 'center',
            },
            {
                title: '备注',
                dataIndex: 'FBz',
                align: 'center',
            },
            {
                title: '状态',
                dataIndex: 'FZt',
                align: 'center',
                render:(_) => {
                    return (
                        <p>{_==2? '停止使用': _==3? '尚未使用': '正在使用'}</p>
                    )
                }
            },

            // {
            //     title: '操作',
            //     dataIndex: 'value',
            //     align: 'center',
            //     render: (_, item) => {
            //         return (
            //             <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
            //         )
            //     }
            // },
        ];

        
        
        const sliceColumns =  [
            {
                title: '编号',
                dataIndex: 'FTpz',
                align: 'center',
            },
            {
                title: '配合比号',
                dataIndex: 'FTpz',
                align: 'center',
            },
            {
                title: '砼品种',
                dataIndex: 'FLjcs',
                align: 'center',
            },

            {
                title: '水泥品种',
                dataIndex: 'FShch',
                align: 'center',
            },
            {
                title: '坍落度',
                dataIndex: 'FSjxm',
                align: 'center',
            },
            {
                title: '石子规格',
                dataIndex: 'FRvA',
                align: 'center',
            },
                {
                    title: '操作',
                    dataIndex: 'value',
                    align: 'center',
                    render: (_, item) => {
                        return (
                            // <Button onClick={() => {onExpandedRowsChange(item)} }>操作</Button>
                            <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
                        )
                    }
                },
        ]  


        const projectId = React.$getStorage('projectInfo').id

        let loadExcelRef = null

    
        const [status, setStatus] = useState(0)
        const [columns2, setColumn] =  useState(columns);
        const [tableList, setTableList] = useState([])
        const [loading, setLoading] = useState(false)
        const [allParams, setAllParams] = useState({
            logic: 2,
            keyword: null,
            sn: null,
            FJzbw: null,
            FTpz: null,
            FZt: null,
            FJhrqStart: null,
            FJhrqEnd: null,
            offsetLimit: 0,
            lengthLimit: 10,
            projectId: projectId,
            FNo: null,
        })

        const [deviceSn, setDeviceSn] = useState(null) 

    
        const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
        })


        const [detailMsg, setDetailMsg] = useState({
        FTbj: [],
        TjlbArr: [],
        TphbyclArr: []
        })

        const [deviceList, setDeviceList] = useState([]);

        let trbbListParams = {
            logic: 2,
            sn: '',
            offsetLimit: 0,
            lengthLimit: 10,
            projectId: projectId,
        }


        
    
        const [widthStatus, setWidthStatus] = useState(false)

        const [widthStatus2, setWidthStatus2] = useState(false)


        useEffect(() => {
            
            getDeviceListMixingStation()
            getList()
          }, []);

            // 获取设备列表
        function getDeviceListMixingStation() {
            React.$axios.post('deviceListMixingStation', {logic: 3, projectId: projectId}).then(res => {

                setDeviceList(res.data.list)
            })
        }
        
          
        // 获取list  
        function getList(type) {

            if(type) {
                pagination.current = 1;
                setPagination(pagination)
            } 

            trbbListParams.offsetLimit = (pagination.current - 1) * 10
            trbbListParams.lengthLimit = pagination.pageSize
            trbbListParams.sn = deviceSn

            setLoading(true)
            React.$axios.post('tphbListMixingStation', trbbListParams).then(res => {

                

               setTableList(res.data.list)
               setpage(res)
            }).finally(() => {
               setLoading(false)
            })
        }


        function setpage(res) {

            pagination.current = pagination.current;
            pagination.pageSize = pagination.pageSize;
            pagination.total = res.data.countList;
            let json = JSON.parse(JSON.stringify(pagination)); 
            json.onShowSizeChange = function(current, pageSize) {
                pagination.pageSize = pageSize;
                let json = JSON.parse(JSON.stringify(pagination)); 
                setPagination(json)
                onPaginationChange(json)
            }
            json.onChange = function(current) {
                pagination.current = current
                let json = JSON.parse(JSON.stringify(pagination)); 
                setPagination(json)
                onPaginationChange(json)
            }
            json.showTotal = function () {  //设置显示一共几条数据
                return '共 ' + pagination.total + ' 条'; 
            }
            setPagination(json)
        }

        
        // 点击展开
        function onExpandedRowsChange() {
            // console.log(111)
            toSee(true)

        }


        function toSee(status, item, recove) {
  
            if(status) {
                setColumn(sliceColumns)
            } else {
                setColumn(columns)
            }
    
         setWidthStatus(status)


        if(!status) {
            setTimeout(() => {
                setWidthStatus2(status)
            }, 1000)
        } else {
            setWidthStatus2(status)
        }
        
      }


      function onPaginationChange(row) {
        setPagination(row)
        getList()
    }

    function onChange() {

    }

    function toSerch() {
        getList(true)
    }

    function handleChange(val) {
        setDeviceSn(val)
        trbbListParams.sn = val



    }



    return (
        <div className="MixSerch">

            <div className="flex serchBox">
                <div className="flex">
                    {/* <Input className="setInput"  placeholder="请输入配合比号"/> */}
                    <Select style={{ width: 200 }}  onChange={handleChange} placeholder="请选择设备">
                    <Option value=''>全部</Option>
                        {
                            deviceList.map(item => {
                                return (
                                    <Option value={item.sn} key={item.id}>{item.name}</Option>
                                )
                            })
                        }
                    </Select>
                    <Button className="setMargin styleBtnYellow" type="primary" onClick={toSerch}>搜索</Button>
                </div>
               
            </div>

            <div className="flex tableWarp">

            

            <div className="setWidth">


                <Table
                    bordered
                    loading={loading}
                    columns={columns2}
                    dataSource={tableList}
                    pagination={pagination}
                />

            </div>



            

            <div className={`detail  ${widthStatus? 'addWidth': ''}`}>
                
                <div className={`reactive ${widthStatus? 'ShowBlock': ''}`}    onClick={() => toSee(false)}>
                    ×
                </div>
                {/* <Tabs defaultActiveKey="1">
                    <TabPane tab="2021-11-12日报表统计信息" key="1"> */}
                    <h1 className="title">配合比号: 37 PHB-20121384BG</h1>

                    <div className="statisticalInfo">
                        <div>
                            <div className="li">
                                <div className="text">
                                    <span>砼&ensp;品&ensp;种</span>
                                    <div></div>
                                </div>
                                <div className="text">
                                <span>水泥品种</span>
                                    <div></div>
                                </div>
                            </div> 

                            <div className="li">
                                <div className="text">
                                    <span>石子规格</span>
                                    <div></div>
                                </div>
                                <div className="text">
                                <span>坍&ensp;落&ensp;度</span>
                                    <div></div>
                                </div>
                            </div>     

                            <div className="li">
                                <div className="text">
                                    <span>操&ensp;&ensp;&ensp;&ensp;作</span>
                                    <div></div>
                                </div>
                                <div className="text">
                                <span>修改日期</span>
                                    <div></div>
                                </div>
                            </div>    
                        </div>
                        

                        <div className="echartsTime">
                            <div id="echartsTime" className="echartsTime"></div>
                        </div>        

                    </div>                    

                    <div className="setMaxHeight">

                        <ul className="setdetailedInfo" >
                                <h1>原材料品种规格及用量</h1>

                                <table>
                                <tr>
                                    <th>原料</th>
                                    <th>品种</th>
                                    <th>用量</th>
                                    <th>仓库</th>
                                </tr>
                                {
                                    detailMsg.TphbyclArr.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.FYlmc}</td>
                                                <td>{item.FPzgg}</td>
                                                <td>{item.FPbsl}</td>
                                                <td>{Math.floor((item.FPbsl - item.FSysl) * 100) / 100 }</td>
                                            </tr>
                                        )
                                    })
                                }
                                </table> 
                            </ul>   
                        
                    </div>    

                    {/* </TabPane>
                </Tabs> */}

         
            </div>

        </div>



        </div>
    )

}


export default withRouter(MixSerch);