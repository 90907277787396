import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Form, Button, DatePicker, Table, message } from 'antd';
// import './monitoringDetails.less'
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatTime, getCurrentDayLastDay} from '@/utils/util.js'
import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';


function MonitoringDetails(props) {
  const [searchForm] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [tableList, setTableList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })


  const [ExcelName, setExcelName] = useState('环境导出')



  let InitialValues = {
    time: getCurrentDayLastDay(moment)
  }



  const jsonFieldsList = {
    "0_设备": 'devName',
    "1_监测时间": {
        field: 'monitorTime',
          callback: value => {
              return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
          }
    },
    "2_PM10(ug/m³)": "pm10",
    "3_PM2.5(ug/m³)": "pm2_5",
    "4_噪声(dB)": "noiseDb",
    "5_温度(℃)": "temperature",
    "6_湿度(%RH)": "humidity",
    "7_风力(级)": "windForce",
    "8_风速(m/s)": "windSpeed",
    "9_风向(方位)": "windDirection",
    "10_风向(度数)": "windDegree",
    "11_TSP(ug/m³)": "tsp",
    "12_气压(kPa)": "airPressure",
    "13_噪音等级": {
      field: 'noiseDbGrade',
        callback: value => {
          return value ==2? '达标': '不达标'
      }
    },
    "14_等级": {
      field: 'grade',
        callback: value => {
          let gradeText = null
          switch (value) {
            case 2:
              gradeText = "优";
              break;
            case 3:
              gradeText = "良";
              break;
            case 4:
              gradeText = "轻度污染";
              break;
            case 5:
              gradeText = "中度污染";
              break;
            case 6:
              gradeText = "重度污染";
              break;
            case 7:
              gradeText = "严重污染";
              break;
          }
          return gradeText
      }
    },
    
  }



  const columns = [
    {
      title: '序号',
      align: 'center',
      render: (text, record, index) => {
        return <span>{index + 1}</span>
      }
    },
    // {
    //   title: '设备地址',
    //   dataIndex: 'devAddr',
    //   align: 'center',
    // },
    {
      title: '监测时间',
      dataIndex: 'monitorTime',
      align: 'center',
      render: (text, record, index) => {
        return <span>{formatTime(new Date(record.monitorTime * 1000), "yyyy-MM-dd")}</span>
      }
    },
    {
      title: 'PM10(ug/m³)',
      dataIndex: 'pm10',
      align: 'center'
    },
    {
      title: 'PM2.5(ug/m³)',
      dataIndex: 'pm2_5',
      align: 'center',
    },
    {
      title: '噪声(dB)',
      dataIndex: 'noiseDb',
      align: 'center'
    },
    {
      title: '温度(℃)',
      dataIndex: 'temperature',
      align: 'center'
    },
    {
      title: '湿度(%RH)',
      dataIndex: 'humidity',
      align: 'center'
    },
    {
      title: '风力(级)',
      dataIndex: 'windForce',
      align: 'center'
    },
    {
      title: '风速(m/s)',
      dataIndex: 'windSpeed',
      align: 'center'
    },
    {
      title: '风向(方位)',
      dataIndex: 'windDirection',
      align: 'center'
    },
    {
      title: '风向(度数)',
      dataIndex: 'windDegree',
      align: 'center'
    },
    {
      title: 'TSP(ug/m³)',
      dataIndex: 'tsp',
      align: 'center'
    },
    {
      title: '气压(kPa)',
      dataIndex: 'airPressure',
      align: 'center'
    },
    {
      title: '噪音等级',
      dataIndex: 'noiseDbGrade',
      align: 'center',
      render: (text, record, index) => {
        return <span>{record.noiseDbGrade === 2 ? "达标" : "不达标"}</span>
      }
    },
    {
      title: '等级',
      dataIndex: 'grade',
      align: 'center',
      render: (text, record, index) => {
        let gradeText = ""
        switch (record.grade) {
          case 2:
            gradeText = "优";
            break;
          case 3:
            gradeText = "良";
            break;
          case 4:
            gradeText = "轻度污染";
            break;
          case 5:
            gradeText = "中度污染";
            break;
          case 6:
            gradeText = "重度污染";
            break;
          case 7:
            gradeText = "严重污染";
            break;
        }
        return <span>{gradeText}</span>
      }
    },
  ]

  // useEffect(() => {
  //   console.log(11111111)
  //   getTablelList(true);
  // }, []);

  useEffect(() => {
    getTablelList(true);
    
  }, [props.devAddr]);

  // useEffect(() => {
  //   console.log(pagination)

  //   getTablelList();
  // }, [pagination])

  function onPaginationChange(page) {
    setPagination(page)

    
    getTablelList();
  }
  const getTablelList = (type = false) => {
    if (props.devAddr) {
      const { time } = searchForm.getFieldsValue()
      const monitorTimeStart = time ? parseInt(new Date(time[0]._i + ' 00:00:00').getTime() / 1000) : ""
      const monitorTimeStop = time ? parseInt(new Date(time[1]._i + ' 23:59:59').getTime() / 1000) : ""
      // console.log(time)
      const requestData = {
        offsetLimit: (pagination.current - 1) * pagination.pageSize,
        lengthLimit: pagination.pageSize,
        devAddr: props.devAddr,
        monitorTimeStart: monitorTimeStart,
        monitorTimeStop: monitorTimeStop
      }
      setLoading(true)
      React.$axios.post("monitoringDayList", requestData)
        .then(({ data }) => {
          setTableList(data.list)
          if (type) {
            setPagination({
              current: 1,
              pageSize: pagination.pageSize,
              total: data.countList
            })
          }
        }).finally(() => {
          setLoading(false)
        })
    }
  }


  // 返回导出数据函数
  function fetchFun() {
    return new Promise(resolve => {

      let name = '环境监测数据' + formatTime(new Date(), 'yyyyMMdd')
      setExcelName(name)

      const { time } = searchForm.getFieldsValue()
      
      if(!time) {
        message.error('导出需要选择时间')
        return
      }
      
      const monitorTimeStart = time ? parseInt(new Date(time[0]._d).getTime() / 1000) : ""
      const monitorTimeStop = time ? parseInt(new Date(time[1]._d).getTime() / 1000) : ""
      // console.log(time)
      const requestData = {
        offsetLimit: (pagination.current - 1) * pagination.pageSize,
        lengthLimit: pagination.pageSize,
        devAddr: props.devAddr,
        monitorTimeStart: monitorTimeStart,
        monitorTimeStop: monitorTimeStop,
        export: 2
      }


      // 返回导出数据
      React.$axios.post('monitoringDayList', requestData).then(res => {
        res.data.list.map(item => {
          item.devName = props.devName
        })
        resolve(res.data.list)
      })

    })
  }


  return (
    <div className="monitoringDetails">
      <div className="search-wrap">
        <Form form={searchForm} layout="inline" initialValues = {InitialValues} onFinish={() => { getTablelList(true) }}>
          <Form.Item
            name="time"
          >
            <RangePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon={<SearchOutlined />} htmlType="submit">搜索</Button>
          </Form.Item>

          <Form.Item>
          <DownloadExcel name={ExcelName} fields={jsonFieldsList}   fetch={fetchFun} btnName="导出" />
          </Form.Item>

        </Form>
      </div>
      <div className="table-wrap">
        <Table
          bordered
          loading={loading}
          columns={columns}
          dataSource={tableList}
          pagination={pagination}
          onChange={onPaginationChange}
        />
      </div>
    </div>
  )
}

export default withRouter(MonitoringDetails);