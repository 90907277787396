import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';

import './Summary.less'

import * as echarts from 'echarts';

import {DatePicker} from "antd";
import {formatTime} from '@/utils/util';

import img1 from '@/assets/img/newReviseImg/imgAndIcon/jiantai.png';



var date = new Date();
var lastMonthDate = "";
//获取当前几号，如果小于10则补0
var day = date.getDate();
if(day < 10){
  day = "0"+day;
}
var month = date.getMonth();
var year = date.getFullYear();
//上个月小于1则证明跨年，年份减1
if(month < 1){
  lastMonthDate = year-1 + '-12-' + day + " 23:59:59";
}else{
  //月份小于10则补0
  if(month < 10){
    month = "0"+month;
  }
  lastMonthDate = year + '-' + month + '-' + day + " 23:59:59";
}


let updateTime = new Date(lastMonthDate);


function Summary() {

    const imgPath = process.env.REACT_APP_IMG_URL;

 

    



    

    const  projectId = React.$getStorage('projectInfo').id
    const [listPlusLessName, setListPlusLessName] = useState([]);  
    const [TaskStatistics, setTaskStatistics] = useState({})
    const [EvaluationTeamStatistics, setEvaluationTeamStatistics] = useState([]);   
    const [isActive, setIsActive] = useState(0);
    const [currentTime, setCurrentTime] = useState(new Date(lastMonthDate));

    const [userList, setUserList] = useState([]);
    const [userRank, setUseRank] = useState([{}, {}, {}]);

    let myChart = null;
 
    useEffect(() => {
        
  
        init();
        
    }, []);

    const init =  () => {
        getlistEvaluationPlusLessStatistics()

        getEvaluationTaskStatistics()

        getListEvaluationTeamStatistics2()

        getUserList()
    }



    // 获取队伍评比分析
    const getListEvaluationTeamStatistics2 = () => {
        React.$axios.post('listEvaluationTeamStatistics2', {projectId: projectId, logic: 4, time: parseInt(updateTime.getTime() / 1000)}).then(res => {
            
                if(!res.data.info) {
                    setEvaluationTeamStatistics([]);


                } else {
                    let arr = []
                    arr =  JSON.parse(res.data.info.data);
                    let currenArr = []
                    toGetList(arr, 2, currenArr);
    
                    setEvaluationTeamStatistics(currenArr);
                }



               

        })
    }


    // 获取积分排行列表
    const getUserList = () => {
        React.$axios.post('listEvaluationTaskUser', {logic: 4, projectId: projectId, time: parseInt(updateTime.getTime() / 1000)}).then(res => {
            let par1 = res.data.list[0]?res.data.list[0]:{};
            let par2 = res.data.list[1]?res.data.list[1]:{};
            let par3 = res.data.list[2]?res.data.list[2]:{};
           setUseRank([par1,par2,par3]);
           setUserList(res.data.list);
        })
    }  


    /**
     * 
     * @param {*} Params 参数 
     * @param {*} index  执行次数
     * @param {*} currenArr 放置参数容器
     *  
     * 
     */
    const toGetList = (Params, index, currenArr) => {

        if(index == 0) {
            currenArr.push(Params)
            return
        };

        for(let i in Params) {
            if(index == 1) {
                Params[i]['name'] = i;
            }


            toGetList(Params[i], index-1, currenArr);
        }
    } 


    // 获取考评统计
    const getEvaluationTaskStatistics = () => {
        
        React.$axios.post('evaluationTaskStatistics', {projectId: projectId, time: parseInt(updateTime.getTime() / 1000)}).then(res => {
            setTaskStatistics(res.data)
        })
    }

    
    // 获取奖罚统计列表
    const getlistEvaluationPlusLessStatistics = () => {
        React.$axios.post('listEvaluationPlusLessStatistics2', {projectId: projectId ,logic: 4, time: parseInt(updateTime.getTime() / 1000)}).then(res => {
            if(res.data.info == null) {
                setListPlusLessName([]);
                getEcharts2([]);
            } else {
                let data = res.data.info.data;
                let currentData =  JSON.parse(data)

                let arr = []
                for(let i in currentData) {
                    let currenArr = []
                    toGetList(currentData[i], 2, currenArr);
                    let params= {
                        name: i,
                        currenArr: currenArr
                    }
                    arr.push(params)
                }

                setListPlusLessName(arr)

                getEcharts2(arr[0])
            }

            
        })

    }



    // 点击切换图形
    const changeListPlus = (item, index) => {
        setIsActive(index);
        getEcharts2(item);
    }


    // 加载图形
    const getEcharts2 = (item)=> {
        let less = [];
        let plus = [];
        let name = [];
        if(!item.currenArr) {

        } else {
            item.currenArr.map(item => {
                less.push(item.less)
                plus.push(item.plus)

                if(item.name.length > 6) {
                    item.name = item.name.substring(0, 6) + '...'
                }

                name.push(item.name)
            })
        }
        
        
        if(!myChart) {
            myChart = echarts.init(document.getElementById('main2'));
        }
       let option = {
            color: ['#004EA2'],
            legend: {
                data: ['奖励人次', '处罚人次'],
                right: '20',
                top: '20'
            },
            grid: {
                left: '1%',
                right: '2%',
                bottom: '0%',
                top: '30%',
                containLabel: true
              },
            xAxis: {
                type: 'category',
                data: name,

                label: {
                    formatter: function(val) {
                        console.log(val)
                    }   
                },
                axisLabel: {  
                    interval:0,  
                    rotate:-60
                }, 
                axisLine: {
                    lineStyle: {
                      type: 'dashed',
                       color: '#7F7F7F',
                      width: '1'
                    }
                  }
            },

            yAxis: {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle:{
                        type:'dashed'
                    }
                },
            },
            
            series: [{
                name: '奖励人次',
                data: plus,
                type: 'bar',
                color: ['#FFAB34'],
                barWidth: 20,
                barMaxWidth:40,
                label: {
                    formatter: "{c}",
                    show: true,
                    position: 'top',
                    color: '#FFAB34'
                }
            },
            {
                name: '处罚人次',
                data: less,
                type: 'bar',
                color: ['#C5153A'],
                barWidth: 20,
                barMaxWidth:40,
                label: {
                    formatter: "{c}",
                    show: true,
                    position: 'top',
                    color: '#C5153A'
              }
            }    
        ]
        };
    
        myChart.setOption(option);
    }

    const onChange = (val) => {
        setCurrentTime(val._d)
        
        updateTime = val._d;
        init();

    }


    
    return (
        <div className="EvaluationSummary">
            <div className="topAllBtn"> 
            <p className="active">{formatTime(currentTime, 'yyyy年MM月')}
             <DatePicker picker="month" className="setOpctiy" onChange={onChange}></DatePicker>
            </p>
          </div>  



            <div className="left">
                <div className="top padding">
                    <h1 className="title">
                        一线劳务积分考评统计
                    </h1>

                    <ul>
                        <li>
                            <h2>累计考评人数（次）</h2>

                            <p style={{color: '#FFAB34'}}>{TaskStatistics.countUser}</p>
                        </li>
                        <li>
                        <h2>考评次数（次）</h2>
                        <p style={{color: '#C8CBF4'}}>{TaskStatistics.countTask}</p>
                        </li>
                        <li>
                        <h2>参与考评队伍（支）</h2>

                        <p style={{color: '#004EA2'}}>{TaskStatistics.countTeam}</p>
                        </li>
                        <li>
                        <h2>参与评委（人）</h2>

                            <p style={{color: '#C5153F'}}>{TaskStatistics.countJudgesUser}</p>
                        </li>
                    </ul>

                </div>

                <div className="bottom ">
                <h1 className="title padding">
                    队伍评比分析 <span>（次）</span> 
                </h1>

                <div className="headerTable">
                    <div>队伍名称</div>
                    <div>最高分</div>
                    <div>最低分</div>
                    <div>平均分</div>
                </div>    

                <ul>
                    {
                        EvaluationTeamStatistics.map((item, index) => {
                            return (
                                <li key={index}>
                                    <div>{item.name}</div>
                                    <div>{item.maxScore}</div>
                                    <div>{item.minScore}</div>
                                    <div>{item.avgScore}</div>
                                </li>
                            )
                        })
                    }


                   
                </ul>

                </div>


            </div>
            <div className="right">
                    
                <div className="top padding">
                    <h1 className="title ">
                        积分排行榜
                    </h1>

                    <div className="trophy">
                        <div className="imgPos">
                            <div className="ranking">
                                <div className="row first">
                                    <div className="circle">{userRank[1].avatarKeyInfo?<img src={imgPath + userRank[1].avatarKeyInfo} alt="" />: ''}</div>
                                    <div className="descript">{userRank[1].teName}<span>{userRank[1].nameIdCard}</span></div>
                                    </div>
                                <div className="row second">
                                    <div className="circle ">{userRank[0].avatarKeyInfo?<img src={imgPath + userRank[0].avatarKeyInfo} alt="" />: ''}</div>
                                    <div className="descript">{userRank[0].teName}<span>{userRank[0].nameIdCard}</span></div>
                                </div>
                                <div className="row thirst">
                                    <div className="circle">{userRank[2].avatarKeyInfo?<img src={imgPath + userRank[2].avatarKeyInfo} alt="" />: ''}</div>
                                    <div className="descript">{userRank[2].teName}<span>{userRank[2].nameIdCard}</span></div>
                                </div>
                            </div>

                            <img src={img1} alt="" />
                        </div>
                        

                        <ul>
                            {
                                userList.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <div>{index + 1}</div>
                                            <div>{item.nameIdCard}</div>
                                            <div>{item.nameEnterprise}</div>
                                            <div>{item.nameTeam}</div>
                                            <div>{item.fraction}分</div>
                                        </li>
                                    )
                                })
                            }
                        </ul>

                    </div>




                </div>

                <div className="bottom padding">
                <h1 className="title">
                        9大类别评比分析 <span>（次）</span>  
                </h1>
                <ul>
                            {
                                listPlusLessName.map((item, index) => {
                                    return (
                                        <li key={index} className={isActive == index?'active':''}  onClick={() => {changeListPlus(item, index)}}>{item.name}</li>  
                                    )
                                })
                            }

                        </ul>


                        <div className="echart">
                            <div id="main2"></div>
                        </div>
                </div>

            </div>

        </div>
    )
  
}

export default withRouter(Summary);