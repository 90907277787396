import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Carousel } from 'antd';
import './BIDataKanban.less'
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatTime, getQueryVariable } from '@/utils/util.js'

import image1 from '@/assets/img/MixingPlant/1630573914(1).jpg'

import * as echarts from 'echarts';
let newTimeTout = null
let index = 0
let listLength = 0

function BIDataKanban() {

  let projectId = getQueryVariable('projectId');

  if(!projectId) {
    projectId = React.$getStorage('projectInfo').id
  } else {
    React.$setStorage('projectInfo', {id: projectId})
  }

  let refDom = null

  const [chartData, setChartData] = useState([])
  // const [detailMsg, setDetailMsg] = useState({
  //   FTbj: [],
  //   TjlbArr: [],
  //   TphbyclArr: []
  // })

  const [sqsMixingStation, setSqsMixingStation] = useState({})
  const [newestTrwdphbyclMixingStation, setNewestTrwdphbyclMixingStation] = useState([])
  const [newestTrwdMixingStation, setNewestTrwdMixingStation] = useState([])

  const [count, setCount] = useState(1)

  const [TybbylSumArr, setTybbylSumArr] = useState([])
  
  const [allColor, setAllColor] = useState([])

  // const [newTimeTout, setnNwTimeTout] = useState(null)

  
  useEffect(() => {
    
    getMonitoringDeviceAll();


    // 获取方量
    getSqsMixingStation()
    // 获取最新实际生产配比
    getNewestTrwdphbyclMixingStation()

    // 获取最新任务单
    getNewestTrwdMixingStation()
    return () => {
        clearInterval(newTimeTout)
    }
  }, []);


  function randomHexColor() { //随机生成十六进制颜色
    return '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6);
  }


  function getMonitoringDeviceAll() {
    React.$axios.post('tybbListMixingStation', {projectId: projectId, logic: 5}).then(res => {
      console.log(res)


      let Tybbcl = res.data.Tybbcl;
      let Tybbyl = res.data.Tybbyl;
      let TybbylSum = res.data.TybbylSum;
      let arrTybbclArr = [['product', '拌 方 量', '总 方 量'],], TybbylArr = {name: [], arr: [],
      color: ['#FE3739','#17F49B','#FFAB31','#4570FE','#E2FE43','#B040FE','#00BDFE','#00B050','#E2F0D9','#000000','#E317E5' ]},
      TybbylSumArr2 = [];
      
      for(let i in Tybbcl) {
        let arr = []
        arr.push(i)
        arr.push(Tybbcl[i].FLjfs)
        arr.push(Tybbcl[i].FRvB)
        arrTybbclArr.push(arr)
      }

      
       
      let index2 = 0;
      for(let i in Tybbyl) {
        TybbylArr.name.push(i)
        let value = Tybbyl[i]
        let index = 0;

        if(!TybbylArr.color[index2]) { // 存在颜色则不随机
          TybbylArr.color[index2] = randomHexColor()
        }

        for(let j in value) {
          let params = {
            name: j,
            type: 'line',
            stack: '总量',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            
            data: []
          }

          if(TybbylArr.arr[index]) { // 如果已有数据
            params = TybbylArr.arr[index]
          }
          params.data.push(value[j])
          TybbylArr.arr[index] = params

          index++;
        }

        index2++;
      }

      


     


      for(let i in TybbylSum) {
        TybbylSumArr2.push({
          name: i,
          value: TybbylSum[i]
        })
      }

      setAllColor(TybbylArr.color)
      setTybbylSumArr(TybbylSumArr2)

      setEchartsHistogram(arrTybbclArr)
      setEchartsLine(TybbylArr)

    })

  }





  // 获取最新任务单
  function getNewestTrwdMixingStation() { 
    React.$axios.post('newestTrwdMixingStation', {projectId: projectId}).then(res => {

      if(!res.data.trwd) return

      res.data.trwd.map(item => {
        item.FTbj = item.FTbj.split('|')
      })


      setNewestTrwdMixingStation(res.data.trwd)
    })
  }


  // 获取最新实际生产配比
  function getNewestTrwdphbyclMixingStation() {
    React.$axios.post('trbbListMixingStation', {projectId: projectId, logic: 5}).then(res => {
      
      if(res.data.device.length == 0 || !res.data.device) {

        return
      }

      if(res.data.device.length >= 2) {
        res.data.device.push(res.data.device[0])
        res.data.device.push(res.data.device[1])
      } else {
        res.data.device.push(res.data.device[0])
      }

      
      setNewestTrwdphbyclMixingStation(res.data.device)

     

      listLength =  res.data.device.length
      // 开启定时器
      setIntervalFun()
      res.data.device.map((item,index) => {
        let arr = []
        item.Trbbyl.map(item2 => {
          arr.push({
            value: item2.FUse,
            name: item2.FYlmc
          })
        })

        setEcharts(arr, 'echartId' + index)
      })
    })
  }


  //设置定时器 实际生产配比
  function setIntervalFun() {
    newTimeTout = setInterval(() => {
      if(listLength <= 2) {
        clearInterval(newTimeTout)
      } else {

        if(index == listLength-2) {
          index = 0
          
          let clierT =  setTimeout(() => {
            index += 1

            clearTimeout(clierT)
            setCount(index)
          }, 10)

        } else {
          index = index + 1
        }
        
        setCount(index)
      }

      // console.log(index)
    }, 5000)

  }
  
  // 清除定时器
  function clearIntervalFun() {
    clearInterval(newTimeTout)
  }



  // 获取方量统计
  function getSqsMixingStation() {

    React.$axios.post('sqsMixingStation', {projectId: projectId}).then(res => {
      if(!res.data.Tybb) return
      setSqsMixingStation(res.data.Tybb)
    })

  }


  // 设置图片
  function setEchartsLine(data) {
    const myChart = echarts.init(document.getElementById('echarts2'));
    

    let option = {
        color: data.color,

        dataZoom: {
          orient: "horizontal",
          show: true,
          start:0,
          end:100,
          height:10,
          bottom:10,
        },
 
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        // legend: {
           
        // },

        // legend: {
        //   data: ['煤 灰 量', '机    砂', '瓜 米 石', '水 泥 1', '水 泥 2'],
        //     type: 'scroll',
        //     orient: 'vertical',
        //     left: 0,
        //     top: 50,
        //     bottom: 20,
        // },


        grid: {
            left: 200,
            right: 22,
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: data.name
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: data.arr
    };

    myChart.setOption(option);
  }

  // 设置 砼生产量
  function setEchartsHistogram(data) {
   


    const myChart = echarts.init(document.getElementById('echarts1'));
    let  option = {
      legend: {
          type: 'scroll',
          // orient: 'vertical',
          left: 0,
          top: 10,
          bottom: 20,
      },
      grid: {
          left: 0,
          right: 22,
          bottom: '3%',
          containLabel: true
      },
      dataZoom: {
        orient: "horizontal",
        show: true,
        start:0,
        end:100,
        height:10,
        bottom:10,
      },

        tooltip: {},
        dataset: {
            source: data
        },
        xAxis: {type: 'category'},
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
            {type: 'bar'
          },
            {type: 'bar'},
        ]
    };

    myChart.setOption(option);
  }


   // 设置图形
   function setEcharts(arr, dom) {
    var myChart = echarts.init(document.getElementById(dom));

    let option = {
        tooltip: {
            trigger: 'item'
        },

 
        legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 0,
            top: 15,
            bottom: 20,
        },
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['50%', '90%'],
                x:-100,//靠左
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: arr
            }
        ]
    };
    
    
    myChart.setOption(option);
}


function onmouseover() {
      clearIntervalFun()
      
}


function onmouseout() {
  // setIntervalFun()
}


function onChange() {

}

// 点击球跳转路径
function setCountClick(index) {
  index = index

  setCount(index)
}

// 
function clickEchartPoint() {
  
}




  return (
    <div className="BIDataKanban">
        <div className="head">

          <div className="leftText">
            <span>本月总累计总方量</span>
            <h1>{sqsMixingStation.FLjfs}</h1> 
            <p>{formatTime(new Date(sqsMixingStation.FRq1 * 1000), 'yyyy-MM-dd')} 至 {formatTime(new Date(sqsMixingStation.FRq2 * 1000), 'yyyy-MM-dd')}</p>

            <div className="fenge"></div>
            <span>本月总累计拌方量</span>
            <h1 className="blue">{sqsMixingStation.FRvB}</h1> 
            <p>{formatTime(new Date(sqsMixingStation.FRq1 * 1000), 'yyyy-MM-dd')} 至 {formatTime(new Date(sqsMixingStation.FRq2 * 1000), 'yyyy-MM-dd')}</p>
          </div>


          <div className="rightText">
            <div className="setRightDom">

              <div className="point">
                  {
                    newestTrwdphbyclMixingStation.map((item, index) => {
                      if(newestTrwdphbyclMixingStation.length -3 >= index) {
                        return (
                          <p onClick={() => {setCountClick(index+1)}} className={count == index+1? 'active': ''}></p>
                        )
                      } 
                      
                    })
                  }
              </div>

              <ul onMouseOver={onmouseover} onMouseOut={onmouseout} style={{transform: `translate(${count * -51}%,0px)`, transitionDuration: `${count == 0? 0: 2}s`}}>
                {
                  newestTrwdphbyclMixingStation.map((item, index) => {
                    if(!item.Trbb) {
                      item.Trbb = {}
                    }
                    return (
                      <li className="flex">
                          <div className="setLabName">
                            <h1><p>{item.name} <i>最新生产概况</i></p> </h1>
                            <div className="flex">
                              <span>生产时间</span>
                              <div className="context">{item.Trbb.FRq?formatTime(new Date(item.Trbb.FRq * 1000), 'yyyy-MM-dd'):0}</div>
                            </div>
                            <div className="flex">
                              <span>砼&ensp;品&ensp;种</span>
                              <div className="context">{item.Trbb.FTpzs}</div>
                            </div>
                            <div className="flex">
                              <span>总&ensp;方&ensp;量</span>
                              <div className="context setW2">{item.Trbb.FLjfs}</div>
                            </div>
                            <div className="flex">
                              <span >拌&ensp;方&ensp;量</span>
                              <div className="context setW2">{item.Trbb.FRvB}</div>
                            </div>
                            
                            </div>

                            <div className="echartId" id={'echartId' + index}>
                                
                            </div>

                            <div className={item.Trbbyl.length > 0?'showNone':'showBlock'}>
                                  暂无数据
                            </div>
                        </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>

        </div>

        <div className="body flex">
            <div className="leftContex">
              <div className="Histogram echart">
                砼生产量


                <div className="echarts1" id="echarts1">

                </div>
              </div>
                

              <div className="line echart">
                原材料累计消耗
                

                <ul>
                  <div className="liHeight">
                  {
                    TybbylSumArr.map((item, index) => {
                      return (
                        <li key={index}><b style={{backgroundColor: allColor[index]}} onClick={clickEchartPoint}></b> <i>{item.name}</i> <span>{(item.value / 1000).toFixed(2)}</span> 吨</li>
                      )
                    })
                  }
                  </div>



                </ul>

                <div className="echarts2" id="echarts2"></div>


              </div>
            </div>

            
   

            <div className="rightContext" >
                
                <Carousel afterChange={onChange} autoplay={true} >
                  {
                    newestTrwdMixingStation.map(detailMsg => {
                      
                      detailMsg.TjlbArr = detailMsg.TjlbArr?detailMsg.TjlbArr: []
                      detailMsg.TphbyclArr = detailMsg.TphbyclArr?detailMsg.TphbyclArr: []
                      return (
                        <div className="rightContextBox">            
                        <h1>实时任务单</h1>
                        <ul className="setdetailedInfo">
                                  <li>
                                      <span>任务单号</span>
                                      <div className="halfWidth">{detailMsg.FRwdh}</div>
                                      <span>状&#12288;&#12288;态</span>
                                      <div className="halfWidth" style={{color: detailMsg.Fqrscwc == 0? '#2F68FF': '#2FC59A'}}>{detailMsg.Fqrscwc == 0? '未完成': '完成'}</div>
                                  </li>
                                  <li>
                                      <span>合同单号</span>
                                      <div className="halfWidth">{detailMsg.FHtbh}</div>
                                      <span>下达任务</span>
                                      <div className="halfWidth">{detailMsg.FXdrw}</div>
                                  </li>
                                  <li>
                                      <span>单位名称</span>
                                      <div className="allWidth">{detailMsg.FHtdw}</div>
                                  </li>
                                  <li>
                                      <span>工程名称</span>
                                      <div className="allWidth">{detailMsg.FGcmc}</div>
                                  </li>
                                  <li>
                                      <span>施工部位</span>
                                      <div className="allWidth">{detailMsg.FJzbw}</div>
                                  </li>
                                  <li>
                                      <span>施工地点</span>
                                      <div className="halfWidth">{detailMsg.FGcdz}</div>
                                      <span>运&#12288;&#12288;距</span>
                                      <div className="halfWidth">{detailMsg.FGls}</div>
                                  </li>
                                  <li>
                                      <span>计划日期</span>
                                      <div className="halfWidth">{formatTime(new Date(detailMsg.FJhrq * 1000), 'yyyy-MM-dd') }</div>
                                      <span>泵&#12288;&#12288;送</span>
                                      <div className="halfWidth">{detailMsg.FJzfs}</div>
                                  </li>
                                  <li>
                                      <span>砼&#160;品&#160;种</span>
                                      <div className="halfWidth">{detailMsg.FTpz}</div>
                                      <span>坍&#160;落&#160;度</span>
                                      <div className="halfWidth">{detailMsg.FTld}</div>
                                  </li>
                                  <li>
                                      <span>水泥品种</span>
                                      <div className="halfWidth">{detailMsg.FSnbh}</div>
                                      <span>石子规格</span>
                                      <div className="halfWidth">{detailMsg.FSzgg}</div>
                                  </li>
                                  <li>
                                      <span>掺料规格</span>
                                      <div className="halfWidth">{detailMsg.FTbj[1]}</div>
                                      <span className="noSpacing">外加剂规格</span>
                                      <div className="halfWidth">{detailMsg.FTbj[2]}</div>
                                  </li>
                                  <li>
                                      <span>抗渗等级</span>
                                      <div className="halfWidth">{detailMsg.FTbj[3]}</div>
                                      <span>其他要求</span>
                                      <div className="halfWidth">{detailMsg.FTbj[4]}</div>
                                  </li>
                                  <li>
                                      <span>备&#12288;&#12288;注</span>
                                      <div className="allWidth">{detailMsg.FTbj[0]}</div>
                                  </li>
                                  <li>
                                      <span>计划方量</span>
                                      <div className="halfWidth">{detailMsg.FJhsl}</div>
                                      <span>生产拌台</span>
                                      <div className="halfWidth">{detailMsg.FScbt}</div>
                                  </li>
                                  <li>
                                      <span>完成方量</span>
                                      <div className="halfWidth">{detailMsg.Trwd}</div>
                                      <span>累计车数</span>
                                      <div className="halfWidth">{detailMsg.FLjcs}</div>
                                  </li>

                                  <h1 className="sMsg">送货信息</h1>

                                  {
                                      detailMsg.tjlbAll.map(item => {
                                          return (
                                              <li>
                                                  <span>车&#12288;&#12288;号</span>
                                                  <div className="halfWidth">{item.FShch}</div>
                                                  <span>司&#12288;&#12288;机</span>
                                                  <div className="halfWidth">{item.FSjxm}</div>
                                              </li>
                                          )
                                      })
                                  }    
                                  
                                  

                              </ul>
                              </div>     
                      )
                    })
                  }

                </Carousel>


               

             
              

            </div>


        </div>



      


    </div >
  )
}

export default withRouter(BIDataKanban);