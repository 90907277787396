import React from "react";

import {Button} from "antd";



import {Tunnel} from "./tunnel/tunnel";
import {Grotto} from './Grotto/Grotto';
import {DiversionCanal} from './DiversionCanal/DiversionCanal';
import {InternalRoad} from './InternalRoad/InternalRoad';
import {OverallConstruction} from './OverallConstruction/OverallConstruction';


export default class ConstructionProgress2 extends React.Component {
 
  state = {
      status: null,
      childBtn: []
  }

  componentDidMount() {
    let childBtn = React.$getStorage('menuchildBtn');
    for(let i = 0; i < childBtn.length; i++) {
      if(childBtn[i].show) {
        let item = childBtn[i];
        this.changeStatus(item.index);
        break;
      }
    }
  

    this.setState({
      childBtn: childBtn
    })
  } 



  changeStatus(e) {
      this.setState({
        status: e
      })

      
  }


  render() {
    return (
      <div className= "wages">

          <div className="btn">
            {
              this.state.childBtn.map((item, index) => {
                if(item.show) {
                  return (
                    <Button  key={index} type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                  )
                }
              })
            }
           {/* <Button  type={this.state.status==0? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 0)}>隧洞</Button>
           <Button className="setBtn" type={this.state.status==1? 'primary': ''}  onClick={this.changeStatus.bind(this, 1)}>洞室</Button>
           <Button className="setBtn" type={this.state.status==2? 'primary': ''}  onClick={this.changeStatus.bind(this, 2)}>引水渠、进水箱涵</Button>
           <Button className="setBtn" type={this.state.status==3? 'primary': ''}  onClick={this.changeStatus.bind(this, 3)}>场内道路</Button> */}
          </div>
          
          {
              function () {
                if(this.state.status == 0) {
                    return (
                       <Tunnel/>
                    )
                }

                if(this.state.status == 1) {
                    return (
                        <Grotto/>
                    )
                }

                if(this.state.status == 2) {
                    return (
                        <DiversionCanal/>
                    )
                }

                if(this.state.status == 3) {
                    return (
                        <InternalRoad/>
                    )
                }
                if(this.state.status == 4) {
                    return (
                        <OverallConstruction/>
                    )
                }

                

            


              }.bind(this)()
          }
  
      </div>
    );
  }
}
