import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Form, Button, DatePicker, Table, Input, Select, Tabs  } from 'antd';
import './TaskList2.less';

import { SearchOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatTime } from '@/utils/util.js'

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

import image1 from '@/assets/img/coordination/11.png'

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

let jsonFieldsList =  {
    '0_单号': 'FRwdh',
    '1_单位名称':'FHtdw',
    "2_工程名称": 'FGcmc',
  
    "3_施工部位": 'FJzbw',
    "4_砼品种":"FTpz",

    "5_计划日期": {
      field: 'FJhrq',
          callback: value => {
              return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
          }
      },
    "6_计划量": 'FJhsl',
    "7_完成数": "FWcsl",
    "7_车数": "FLjcs",
    "8_状态": {
      field: "FZt",
      callback: value => {
        let text = value == 2? '完成':'未完成'
        return  text
      }
    },
    "9_泵送" : "FJzfs"
  }
  



function TaskList() {

    const columns = [
        {
        title: '单号',
        align: 'center',
        dataIndex: 'FRwdh'
        },
        {
            title: '单位名称',
            dataIndex: 'FHtdw',
            // align: 'center',
            render: (_) => {
                return (
                    <p className="setPwidth">{_}</p>
                )
            }
        },
        {
            title: '工程名称',
            dataIndex: 'FGcmc',
            // align: 'center',
            render: (_) => {
                return (
                    <p className="setPwidth">{_}</p>
                )
            }
        },
        {
            title: '施工部位',
            dataIndex: 'FJzbw',
            align: 'center',
        },
        {
            title: '砼品种',
            dataIndex: 'FTpz',
            align: 'center',
        },
        {
            title: '计划日期',
            dataIndex: 'FJhrq',
            align: 'center',
            render: (_) => {
                let time;
                if(_) {
                    time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                }
                return (
                    <p>{time}</p>
                )
            }
        },
        {
            title: '计划量',
            dataIndex: 'FJhsl',
            align: 'center',
        },
        {
            title: '完成量',
            dataIndex: 'FWcsl',
            align: 'center',
        },
        {
            title: '车数',
            dataIndex: 'FLjcs',
            align: 'center',
        },
        {
            title: '状态',
            dataIndex: 'FZt',
            align: 'center',
            render: (_) => {
                if(_ == 2) {
                
                    return (
                        <CheckCircleOutlined className="setIcon" style={{color:'#2FC59A'}} />
                    )
                } else {
                    return (
                        <CloseCircleOutlined className="setIcon" style={{color:'#2F68FF'}} />
                    )
                }


            }
        },
        {
            title: '泵送',
            dataIndex: 'FJzfs',
            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'FBsfs',
            align: 'center',
            render: (_, item) => {
                return (
                    // <Button onClick={() => {onExpandedRowsChange(item)} }>操作</Button>
                    <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
                )
            }
        },
    ];
    
        
    const sliceColumns =  [
        {
            title: '单号',
            align: 'center',
            dataIndex: 'FRwdh'
        },
        {
            title: '单位名称',
            dataIndex: 'FHtdw',
            align: 'center',
            render: (_) => {
                return (
                    <p className="setPwidth">{_}</p>
                )
            }
        },
        {
            title: '工程名称',
            dataIndex: 'FGcmc',
            align: 'center',
            render: (_) => {
                return (
                    <p className="setPwidth">{_}</p>
                )
            }
        },
        {
            title: '施工部位',
            dataIndex: 'FJzbw',
            align: 'center',
        },
        {
            title: '砼品种',
            dataIndex: 'FTpz',
            align: 'center',
        },
        {
            title: '计划日期',
            dataIndex: 'FJhrq',
            align: 'center',
            render: (_) => {
                let time;
                if(_) {
                    time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                }
                return (
                    <p>{time}</p>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'FBsfs',
            align: 'center',
            render: (_, item) => {
                return (
                    // <Button onClick={() => {onExpandedRowsChange(item)} }>操作</Button>
                    <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
                )
            }
        },
    ]
    



  const projectId = React.$getStorage('projectInfo').id

  let loadExcelRef = null

  const [ExcelName, setExcelName] = useState('考试');
  const [columns2, setColumn] =  useState(columns);
  const [deviceList, setDeviceList] = useState([]);
  const [tableList, setTableList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })
  const [allParams, setAllParams] = useState({
    logic: 2,
    keyword: null,
    sn: null,
    FJzbw: null,
    FTpz: null,
    FZt: null,
    FJhrqStart: null,
    FJhrqEnd: null,
    offsetLimit: 0,
    lengthLimit: 10,
    projectId: projectId
  })
  const [detailMsg, setDetailMsg] = useState({
    FTbj: [],
    TjlbArr: [],
    TphbyclArr: []
  })
  const [selectVal, setSelectVal] = useState('')

  const [widthStatus, setWidthStatus] = useState(false)
    

  useEffect(() => {

    getDeviceListMixingStation();
  }, []);


  // 获取设备列表
  function getDeviceListMixingStation() {
    React.$axios.post('deviceListMixingStation', {logic: 3, projectId: projectId}).then(res => {
        if(res.data.list.length == 0) {
            return
        }

        setDeviceList(res.data.list)
        allParams.sn = res.data.list[0].sn
        setSelectVal(res.data.list[0].sn)
        setAllParams(allParams)

        getMonitoringDeviceAll(pagination);
        // getDeviceListMixingStation(allParams)
    })
  }

  
  function getMonitoringDeviceAll(pagination, type=false) {
    // 如果需要更新 从第一页开始
    if(type) {
        pagination.current = 1
    }


    allParams.offsetLimit = (pagination.current - 1) *  pagination.pageSize
    allParams.lengthLimit = pagination.pageSize


    React.$axios.post('trwdListMixingStation', allParams).then(res => {

        pagination.total = res.data.countList

        setTableList(res.data.list)

        setPagination(pagination)
    })

  }

  function onPaginationChange(val) {

    setPagination(val)

    console.log(val)

    getMonitoringDeviceAll(val)
  }


  function toSee(status, item, recove) {

        if(status) {
            setColumn(sliceColumns)
        } else {
            setColumn(columns)
        }

     setWidthStatus(status)
  }
  
  function callback() {

  }

  function onExpandedRowsChange(item) {
    toSee(true)

    React.$axios.post('trwdListMixingStation', {logic: 4, id: item.id, projectId: projectId, FPblb: 0}).then(res => {
    
        if(typeof(res.data.Trwd.FTbj) == 'string') {

            res.data.Trwd.FTbj = res.data.Trwd.FTbj.split('|')
            
            res.data.Trwd.TjlbArr = res.data.Tjlb
            
            res.data.Trwd.FSgpbFSysl = res.data.FSgpbFSysl
            res.data.Trwd.TphbyclArr = res.data.Tphbycl

            setDetailMsg(res.data.Trwd)
        }
    

    })

  }

  function handleChange(val) { // 设备选择
    allParams.sn = val
    setAllParams(allParams)
    setSelectVal(val)
  }

  // input 输入框
  function paramsInput({target:{value}}, text) {
    allParams[text] = value
    setAllParams(allParams)
  }

  // 时间选择
  function changeTime(val) {
    allParams.FJhrqStart = parseInt((+val[0]._d) / 1000) 
    allParams.FJhrqEnd = parseInt((+val[1]._d) / 1000)  
    setAllParams(allParams)
  }

  // 点击搜索
  function toSerch() {

    getMonitoringDeviceAll(pagination, true);

  }

  // 导出方法
  function fetchFun() {

    setExcelName(formatTime(new Date(), '任务单yyyy-MM-dd hh:mm:ss'))

    return new Promise((resolve) => {
        let newParams = Object.assign({}, allParams);
        newParams.logic = 3
        React.$axios.post('trwdListMixingStation', newParams).then(res => {
            resolve(res.data.list)
        })
    })
  }



  return (
    <div className="dTaskList2">

        <div className="flex operationTask">
            <div className="serchList">
                <p>
                <i>单号</i>  <Input placeholder="请输入" className="setWidth100" onInput={(val)=> {paramsInput(val, 'FRwdh')}}  />
                </p>
                <p>
                <i>设备</i>
                <Select className="setWidth200" value={selectVal} onChange={handleChange} placeholder="请选择">
                    
                    {
                        deviceList.map(item => {
                            return (
                                <Option value={item.sn} key={item.id}>{item.name}</Option>
                            )
                        })
                    }
                </Select>
                </p>
                <p>
                <i>浇筑部位</i>      
                <Input placeholder="请输入" className="setWidth200" onInput={(val)=> {paramsInput(val, 'FJzbw')}}  />
                </p>
                <p>
                <i>砼品种</i>
                <Input placeholder="请输入" className="setWidth200" onInput={(val)=> {paramsInput(val, 'FTpz')}}  />
                </p>
                <p>
                <i>日期</i>
                {/* <Input placeholder="请输入" style={{width: 200}} /> */}
                <RangePicker className="setWidth300" onChange={changeTime} />
                </p>
 
            </div>

            <div className="serchBtn">
                <Button className="setMargin styleBtnYellow" type="primary" onClick={toSerch}>搜索</Button>
                
                <DownloadExcel ref={(el) => loadExcelRef = el}  name={ExcelName} fields={jsonFieldsList}  fetch={fetchFun} btnName="导出">
                    <Button  type="primary styleBtnGreen">导出</Button>
                </DownloadExcel>   

            </div>


        </div>
        



        <div className="flex tableWarp">
            <div className="setWidth">
            {/* <p className="posAbs">
                <Button type="primary" onClick={this.addItemFun.bind(this)}>新建签到</Button> 
            </p>  */}

                <Table
                    bordered
                    loading={loading}
                    columns={columns2}
                    dataSource={tableList}
                    pagination={pagination}
                    onChange={(page) => onPaginationChange(page)}
                    // expandable={{
                    // expandRowByClick: true,
                    // expandIconColumnIndex: 4,
                    // expandIcon: ({ onExpand, record }) => {
                    // return <p></p>
                    // },
                    
                    // // onExpandedRowsChange: onExpandedRowsChange
                    // }}
                />
            </div>
            

            <div className={`detail  ${widthStatus? 'addWidth': ''}`}>
                
                <div className={`reactive ${widthStatus? 'ShowBlock': ''}`}    onClick={() => toSee(false)}>
                    ×
                </div>
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="详细信息" key="1">

                    <ul className="setdetailedInfo">
                        <li>
                            <span>任务单号</span>
                            <div className="halfWidth">{detailMsg.FRwdh}</div>
                            <span>状&#12288;&#12288;态</span>
                            <div className="halfWidth" style={{color: detailMsg.Fqrscwc == 0? '#2F68FF': '#2FC59A'}}>{detailMsg.Fqrscwc == 0? '未完成': '完成'}</div>
                        </li>
                        <li>
                            <span>合同单号</span>
                            <div className="halfWidth">{detailMsg.FHtbh}</div>
                            <span>下达任务</span>
                            <div className="halfWidth">{detailMsg.FXdrw}</div>
                        </li>
                        <li>
                            <span>单位名称</span>
                            <div className="allWidth">{detailMsg.FHtdw}</div>
                        </li>
                        <li>
                            <span>工程名称</span>
                            <div className="allWidth">{detailMsg.FGcmc}</div>
                        </li>
                        <li>
                            <span>施工部位</span>
                            <div className="allWidth">{detailMsg.FJzbw}</div>
                        </li>
                        <li>
                            <span>施工地点</span>
                            <div className="halfWidth">{detailMsg.FGcdz}</div>
                            <span>运&#12288;&#12288;距</span>
                            <div className="halfWidth">{detailMsg.FGls}</div>
                        </li>
                        <li>
                            <span>计划日期</span>
                            <div className="halfWidth">{formatTime(new Date(detailMsg.FVersion * 1000), 'yyyy-MM-dd')}</div>
                            <span>泵&#12288;&#12288;送</span>
                            <div className="halfWidth">{detailMsg.FJzfs}</div>
                        </li>
                        <li>
                            <span>砼&#160;品&#160;种</span>
                            <div className="halfWidth">{detailMsg.FTpz}</div>
                            <span>坍&#160;落&#160;度</span>
                            <div className="halfWidth">{detailMsg.FTld}</div>
                        </li>
                        <li>
                            <span>水泥品种</span>
                            <div className="halfWidth">{detailMsg.FSnbh}</div>
                            <span>石子规格</span>
                            <div className="halfWidth">{detailMsg.FSzgg}</div>
                        </li>
                        <li>
                            <span>掺料规格</span>
                            <div className="halfWidth">{detailMsg.FTbj[1]}</div>
                            <span className="noSpacing">外加剂规格</span>
                            <div className="halfWidth">{detailMsg.FTbj[2]}</div>
                        </li>
                        <li>
                            <span>抗渗等级</span>
                            <div className="halfWidth">{detailMsg.FTbj[3]}</div>
                            <span>其他要求</span>
                            <div className="halfWidth">{detailMsg.FTbj[4]}</div>
                        </li>
                        <li>
                            <span>备&#12288;&#12288;注</span>
                            <div className="allWidth">{detailMsg.FTbj[0]}</div>
                        </li>
                        <li>
                            <span>计划方量</span>
                            <div className="halfWidth">{detailMsg.FJhsl}</div>
                            <span>生产拌台</span>
                            <div className="halfWidth">{detailMsg.FScbt}</div>
                        </li>
                        <li>
                            <span>完成方量</span>
                            <div className="halfWidth">6</div>
                            <span>累计车数</span>
                            <div className="halfWidth">{detailMsg.FLjcs}</div>
                        </li>

                        <h1>送货信息</h1>

                        {
                            detailMsg.TjlbArr.map(item => {
                                return (
                                    <li>
                                        <span>车&#12288;&#12288;号</span>
                                        <div className="halfWidth">{item.FShch}</div>
                                        <span>司&#12288;&#12288;机</span>
                                        <div className="halfWidth">{item.FSjxm}</div>
                                    </li>
                                )
                            })
                        }    
                        
                        

                    </ul>  



                    </TabPane>
                    <TabPane tab="施工配比" key="2">
                    <ul className="setdetailedInfo">

                        <h1>任务单信息</h1>
                        <li>
                            <span>单位名称</span>
                            <div className="allWidth">{detailMsg.FGcmc}</div>
                        </li>
                        <li>
                            <span>工程名称</span>
                            <div className="allWidth">{detailMsg.FGcmc}</div>
                        </li>
                        <li>
                            <span>施工部位</span>
                            <div className="allWidth">{detailMsg.FJzbw}</div>
                        </li>
                        <li>
                            <span>砼&#160;品&#160;种</span>
                            <div className="halfWidth">{detailMsg.FTpz}</div>
                            <span>坍&#160;落&#160;度</span>
                            <div className="halfWidth">{detailMsg.FTld}</div>
                        </li>
                        <li>
                            <span>配合比号</span>
                            <div className="allWidth">{detailMsg.FSgpb}</div>
                        </li>
                    </ul>

                    
                    <ul className="setdetailedInfo" style={{marginTop: 30}}>
                    <h1>施工配合比（总重量：<b style={{color: '#106CFF'}}>{detailMsg.FSgpbFSysl}</b>kg）</h1>

                    <table>
                    <tr>
                        <th>原料名称</th>
                        <th>品种规格</th>
                        <th>用量</th>
                        <th>仓位</th>
                    </tr>

                    {
                        detailMsg.TphbyclArr.map(item => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.FYlmc}</td>
                                    <td>{item.FPzgg}</td>
                                    <td>{item.FSysl}</td>
                                    <td>{item.FPlcw}</td>
                                </tr>
                            )
                        })
                    }
                   
                    </table> 
                    </ul>   


                    </TabPane>
                </Tabs>



                          
            </div>

        </div>



        {/* <Table
        bordered
        loading={loading}
        columns={columns}
        dataSource={tableList}
        pagination={pagination}
        onChange={(page) => onPaginationChange(page)}
    /> */}


    </div >
  )
}

export default withRouter(TaskList);