import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import debounce from 'lodash.debounce'
import './projectdistrtion3.less'

import videoPlay from '@/assets/img/videoReturning/2.png';
import img1 from '@/assets/img/videoReturning/1.png';
import * as echarts from 'echarts';


// import Eearth from '../../component/earth/earth';
import {VideoComponentHH} from '../../component/video/video';


let timer = null
let indexItemGlbal = 0,
    lengthIndex = 0,
    shi = [], 
    echartTime = null, // 更新图标
    timerPorject = null
function Projectdistrtion() {
    const projectIdPermission = React.$getStorage('loginInfo').projectIdPermission;
    const [status, setStatus] = useState(0)
    const [scale, setRate] = useState(0)
    const [indexItem, setIndex] = useState(0)
    const [widthHieghtRate, setWidthHieghtRate] = useState({
        width:window.innerWidth, height:window.innerHeight
    })
    //项目数量
    const [projectCount, setProjectCount] = useState(0)
    const [projectName, setProjectName] = useState('')
    //视频个数
    const [shiCount, setShiCount] = useState(0)

    const [isVisible, setIsVisible] = useState(false)
    const [videoParams, setVideoParams] = useState({})
    const [videoList, setVideoList] = useState([])
    const [echartStatus, setEchartStatus] = useState(true)


    useEffect(() => {

      getprojectAll()

       FunSetInterval()
       oneMniueTime()

        // 设置图形
        setTimeout(() => {
            setEchart()
            setEchart2()
        }, 200)
        


        return () => {
            clierIntervalFun()
            clearAllTime()
        }
    }, []);




    // 1秒钟定时器
    function oneMniueTime() {
        // 定时更新数据
       echartTime =  setInterval(() => {
            refreshData()
            getprojectAll()
        }, 60000)
    }

    // 重新更新图标
    function refreshData() {
        setEchartStatus(false)

        setEchartStatus(true)

          // 设置图形
        setTimeout(() => {
            setEchart()
            setEchart2()
        }, 200)

    }



    // 清除全部定时器
    function clearAllTime() {
        clearInterval(echartTime)
        clearInterval(timerPorject)
    }



    function getprojectAll() {
        const projectIdPermission = React.$getStorage('loginInfo').projectIdPermission;
        React.$axiosNoLogin.post('projectAll', {logic: 3, projectIdPermission: projectIdPermission}).then(res => {

            res.data.project.map(item => {
                item.shi.map(item2 => {

                    try {
                        item2.accessToken = res.data.applicationShi[item2.applicationId].accessToken
                    } catch(e) {
                        console.log(item)
                    }
                })
            })

            let index = 0,
            status = false,
            status2 = false,
            step = 9,
            count  = 0

            timerPorject = setInterval(() => {
                if(index < res.data.shiCount) {
                    
                    setShiCount(index)
                   
                } else {
                    status = true
                    setShiCount(res.data.shiCount)
                }

                if(count < res.data.projectCount) {
                    setProjectCount(count)
                    
                } else {
                    status2  = true
                    setProjectCount(res.data.projectCount)
                }

                if(status2 && status) {
                    clearInterval(timerPorject)
                }

                index++;
                count = count + step;
            }, 30)

            if(res.data.project.length == 0) {
                return
            }

            setProjectName(res.data.project[0].name)
            lengthIndex = res.data.project.length

            setVideoList(res.data.project)
        })
    }


    // 定时播放视频
    function FunSetInterval() {
        clearInterval(timer)
        timer = setInterval(() => {
            indexItemGlbal += 1

            if(indexItemGlbal > lengthIndex-1) {
                indexItemGlbal = 0
            }

            setIndex(indexItemGlbal)

            try {
                setProjectName(videoList[indexItemGlbal].name)
            } catch(e) {
                console.log(indexItemGlbal)
                console.log(e)
            }
 
        }, 40000);
    }
    // 清除定时器
    function clierIntervalFun() {
        clearInterval(timer)
        timer = null
    }

    
    function clickList(index) {
        indexItemGlbal = index
        setIndex(index)
        setProjectName(videoList[indexItemGlbal].name)
    }

    // 鼠标移入
    function leftMouseOver() {
        clierIntervalFun()
    }

    // 鼠标移出
    function leftMouseOut() {

        if(timer) {
            
        } else {
            FunSetInterval()
        }
    }

    // 点击关闭弹框
    function handleOk() {

        videoList[indexItem].shi = shi
        setVideoList(videoList)
        setIsVisible(false)
        FunSetInterval()
        
        setIndex(indexItemGlbal)
    }

    //点击视频符号
    function clickVideo(item) {
        setIsVisible(true)
        shi = videoList[indexItem].shi
        videoList[indexItem].shi = [] 
        setVideoList(videoList)
        clierIntervalFun()
        setVideoParams(item)
    }


    // 设置图形
    function setEchart() {
        let chartDom = document.getElementById('barChart');
        let myChart  = echarts.init(chartDom);
        let option;


        option = {
            xAxis: {
                type: 'category',
                data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12','13', '14','15','16', '17', '18', '19', '20', '21', '22', '23', '24'],
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: false
                }
            },

            grid: {
                left: '1%',
                right: '4%',
                bottom: '10%',
                top: '10%',
                containLabel: true
            },

            series: [{
                data: [120, {
                    value: 200,
                    itemStyle: {
                        color: '#5B76F7'
                    }
                }, 150, 80, 70, 110, 130,70, 110, 130,70, 110, 150, 80, 70, 110, 130,70, 110, 130,70, 110, 70, 110],
                type: 'bar',
                barWidth: 3,
                color: ['#58CDFE'],
            }]
        };
        
    
        option && myChart.setOption(option);  

    }


    function setEchart2() {
        let chartDom = document.getElementById('roundChart');
        let myChart = echarts.init(chartDom);


        var option;

        option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                    name: '访问来源',
                    type: 'pie',
                    x:-130,//靠左
                    radius: ['55%', '80%'],
                     avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#0A192C',
                        borderWidth: 15
                    },

                    

                    label: {
                        show: false,
                        position: 'center'
                    },
                    title: {
                        show: false
                    },

                    labelLine: {
                        show: false
                    },
                    data: [
                        {value: 1048,},
                        {value: 735,},
                        {value: 580, },
                        {value: 484, },
                        {value: 300,}
                    ]
                }
            ]
        };

        option && myChart.setOption(option);

    }


  return (
     
    <div className='Projectdistrtion'> 

                <div className="body flex">
                    
                    <div className="leftData" onMouseOver={leftMouseOver} onMouseOut={leftMouseOut}>
                        <div className="projectList">
                             <div className="title">
                                <img src={videoPlay} alt="" />
                                <div>
                                  <h3>Real Time</h3> 
                                  <h1>实时远程监控</h1>      
                                </div>  
                               
                             </div>
                             
                            <div className="tips">
                                <p>全城监控项目总数量</p>
                                <p><span>{projectCount} <i>个</i> </span></p>
                            </div>
                            <div className="tips">
                                <p>接入视频监控数量</p>
                                <p><span>{shiCount} <i>个</i> </span></p>
                            </div>  


                             <ul className="anmateUl">

                                 {
                                     videoList.map((item, index) => {
                                         return (
                                            <li className={indexItem == index?'active': ''} onClick={() => { clickList(index) }} key={index}><img src={img1} alt="" />{item.name}</li>
                                         )
                                     })
                                 }
                            </ul>   

                        </div>

                        <div className="videoList">
                            <h1>
                                {/* {videoList[indexItem].name} */}
                                {
                                    function() {
                                        
                                        if(videoList.length > 0) {
                                            return (
                                               <b>{videoList[indexItem].name}</b> 
                                            )
                                        }
                                    }()
                                }

                                <span>monitor</span>
                            </h1>

                            <div className="setHeight">
                                <div className={indexItem == 0 ? "moveDiv0" : 'moveDiv'} style={{transform: `translate(0px, ${-640 * indexItem}px)`}}>
                               
                                    {
                                            videoList.map((item, index) => {
                                                return (
                                                    <ul key={item.id} className="allVideo">
                                                        {
                                                            item.shi.map((itme2, index2) => {
                                                                return (
                                                                    <li>
                                                                    {
                                                                        function() {    
                                                                            if(indexItem == index) {
                                                                                return (
                                                                                    <VideoComponentHH clickFun={clickVideo} width="345px" height="200px" index={index2} videoDetail = {itme2} />
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <img key={'img' + index2} src={itme2.coverImg} alt="" />
                                                                                )
                                                                            }

                                                                        }()
                                                                    }    
                                                                    </li>

                                                                    

                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                )
                                            })
                                        }
                                </div>    

                               
                            </div>
                           

                        </div>

                    </div>

                    <div className="rightData">
                        <div className="title">
                            <p>
                              <img src={img1} alt="" /> 行为事件抓拍趋势

                               <span>8月 (件)</span>         
                            </p>

                           {
                               function() {
                                   if(echartStatus) {
                                    return (
                                        <div id="barChart" className="echartBar">
                                        </div>  
                                    )

                                         
                                   } else {
                                    return (
                                        <span></span>
                                    )
                                   }
                               }()
                           }

                              
                            
                        </div>
                        <div className="title">
                            <p>
                            <img src={img1} alt="" />   安全生产违规事件类型占比
                            </p>

                            {
                               function() {
                                   if(echartStatus) {
                                    return (
                                        <div id="roundChart" className="echartBar">
                                        
                                        </div>
                                    )

                                         
                                   } else {
                                    return (
                                        <span></span>
                                    )
                                   }
                               }()
                           }
                            
                             

                            <div className="round">
                                <div className="context">
                                    <div className="p">安全违规事件</div>
                                    <div className="p">1054件</div> 
                                </div>
                                       
                            </div>

                            <ul>
                                <li>佩戴安全帽 <span>11.6 %</span></li>
                                <li>反光衣 <span>12.7 %</span></li>
                                <li>陌生拜访 <span>23.0 %</span></li>
                                <li className="colorActive">滞留 <span>30.6 %</span></li>
                                <li>其他 <span>21.92 %</span></li>
                            </ul>

                        </div>
                    </div>
                
                </div>    
      

         {
             function () {

                if(isVisible) {

                    return (
                        <Modal visible={isVisible} width="1400px"  className='VideoComponentHHModal' handleOk={handleOk} onCancel={handleOk}  footer={null}>
                                   <div className="deleVideo">
                                        <p className="setP">
                                                    <VideoComponentHH show={false}  width="1400px" height="800px" type="hd" index={'hh962'} videoDetail = {videoParams} />
                                        </p>
                                    </div>             
                        </Modal>
                    )


                } else {

                }


             }() 
         }


    </div >
  )
}

export default withRouter(Projectdistrtion);