import React from "react";


import {Button, Table,  Modal, Select, message, Form, Input} from "antd";


import './AdminList.less';

const {Option} =  Select;

const layout = {
    labelCol: { span: 4 },
  };

export default class AdminList extends React.Component {
    columns = [
        {
          title: '姓名',
          dataIndex: 'name',
          align: 'center',
        },
        {
            title: '登陆IP',
            dataIndex: 'loginIp',
            align: 'center',
        },
        {
            title: '角色',
            dataIndex: 'adminRoleHasOne',
            align: 'center',
        },

        {
            title: '启用',
            dataIndex: 'enable',
            align: 'center',
            render:(_) => {
                let text = null;
                if(_ == 2) {
                    text = '启用'
                } else {
                    text = '禁用'
                }
                return (
                    <p className={'setColor' + _}>{text}</p>
                )
            }
        },

        // {
        //     title: '添加时间',
        //     dataIndex: 'editTime',
        //     align: 'center',
        // },
        {
          title: '操作',
          dataIndex: 'address',
          align: 'center',
          width: 200,
          render:(_,item) => {
            return (
              <div className="operation">
                  <p onClick={this.clickFun.bind(this, item)} className="centp">编辑</p>
              </div>
            )
          }
        },
      ]

      FormSbmitRef = ''

  state = {
    herfEarth: '',
    status: 1,
    loading: false,
    tableData:[],
    pagination: {
        current: 1,
        pageSize: 10,
        total: 200,
        onShowSizeChange: function(current, pageSize) {
            this.state.pagination.pageSize = pageSize;
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        onChange: function(current) {
            this.state.pagination.current = current
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        showTotal: function () {  //设置显示一共几条数据
            return '共 ' + this.state.pagination.total + ' 条'; 
        }.bind(this),
        showQuickJumper: true,
        },
    paramsAdmin: {
        lengthLimit: 10,
        offsetLimit: 0
    },

    FormParams: {
        enable: null,
        name: null,
        password: null,
        projectId: null,
        roleId: null,
    },
    roleAllList: [],

    isModalVisible: false,
    projectId:null
  }

  componentDidMount() {

    let id =  React.$getStorage('projectInfo').id

    this.state.FormParams.projectId = id
    this.state.projectId = id

    this.getAdminList()
    this.getRoleAll()
  }


  clickFun(item) {
    for(let i in this.state.FormParams) {
        this.state.FormParams[i] = item[i]
    }
    this.state.FormParams.id = item.id;
    this.state.FormParams.password = null
    
    this.setState({
        isModalVisible: true
    })

    if(!this.FormSbmitRef) {
        setTimeout(() => {
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
  
    // this.FormSbmitRef.setFieldsValue(this.state.FormParams)

    this.setState({
        FormParams: this.state.FormParams,
    })
  }

  getRoleAll() {
      React.$axios.post('roleAll', {enable: 2}).then(res => {
        this.setState({
            roleAllList: res.data.roleAll
        })
      })
  }    


  getAdminList() {
      React.$axios.post('adminList', this.state.paramsAdmin).then(res => {
          this.state.pagination.total = res.data.count
          this.setState({
            tableData: res.data.list
          })
      })
  }


  changeStatus(type) {
    this.setState({
        status: type
    })
  }

  handleTableChange(pagination) {

    this.state.paramsAdmin.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.paramsAdmin.lengthLimit = pagination.pageSize

    this.setState({
      paramsAdmin: this.state.paramsAdmin,
      pagination: pagination
    })


    // 执行更新列表
    this.getList()
  }

 

  handleOk() {
    this.FormSbmitRef.submit()
  }


  addAdmin() {
        for(let i in this.state.FormParams) {
            this.state.FormParams[i] = null
        }
        this.state.FormParams.projectId = this.state.projectId;
        if(this.state.FormParams.id) {
            delete this.state.FormParams.id
        }


        if(!this.FormSbmitRef) {
            setTimeout(() => {
                this.FormSbmitRef.resetFields()
            }, 500)
        } else {
            this.FormSbmitRef.resetFields()
        }


      this.setState({
        isModalVisible: true,
        FormParams: this.state.FormParams
      })
  }

  handleCancel() {
      this.setState({
        isModalVisible: false
      })
  }

  // 可提交数据
  onFinish(value) {
    //console.log(value)
    
    for(let i in value) {
        this.state.FormParams[i] = value[i]
    }


    React.$axios.post('adminEditSave', this.state.FormParams).then(res => {
        if(res.code == 20000) {
            message.success('保存成功')
        
            this.setState({
                isModalVisible: false
            })

            this.getAdminList()
        }
    })
  }

  onChangeSelect() {

  }


  render() {
    return (
      <div className="adminList" >
          <div className="btn">
            <Button type="primary" onClick={this.addAdmin.bind(this)}>
                添加管理员 
            </Button> 
          </div>
         
        
          <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }} columns={this.columns} dataSource={this.state.tableData}  />


        <Modal title="添加" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                        <Form
                            onFinish={this.onFinish.bind(this)}
                            ref={(el) => this.FormSbmitRef = el}
                            {...layout}
                            initialValues = {this.state.FormParams}
                        >
                            <Form.Item label="名称" name="name"  rules={[{ required: true }]}>
                            <Input placeholder="请输入名称" />
                            </Form.Item>
                            <Form.Item label="密码"  name="password">
                            <Input placeholder="请输入密码 不填则不修改" />
                            </Form.Item>
                            <Form.Item label="状态" name="enable" rules={[{ required: true }]} >
                            <Select
                                showSearch
                                style={{ width: 200,  marginRight:20,  }}
                                placeholder="请选择"
                                optionFilterProp="children"
                                onChange={this.onChangeSelect.bind(this, 'uiRole')}
                            >

                            <Option value={2}>启用</Option>
                            <Option value={3}>禁用</Option>
                            </Select>

                            </Form.Item>
                            <Form.Item label="管理角色" name="roleId" rules={[{ required: true }]} >
                            {/* <Input placeholder="input placeholder" /> */}
                                
                                <Select
                                    showSearch
                                    style={{ width: 200,  marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeSelect.bind(this, 'uiRole')}
                                >
                                     <Option key={0} value={0}>超级管理员</Option>
                                    {
                                        this.state.roleAllList.map(item => {
                                            return (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        </Form>

        </Modal> 


      </div>
    );
  }
}
