import React from "react";

import { DatePicker,Input,Select,Button, Table, Modal, Tabs, Image, Timeline, message} from "antd";



import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';


import {formatTime} from '@/utils/util'

import './WageEntry.less';

const {Option} =  Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;



export const WageEntry = (

    class WageEntry extends React.Component {

      imgUrl = process.env.REACT_APP_IMG_URL
      projectId = React.$getStorage('projectInfo').id
      state = {
        // 表格分页属性
        pagination: {
          current: 1,
          pageSize: 10,
          total: 200,
          onShowSizeChange: function(current, pageSize) {
              this.state.pagination.pageSize = pageSize;
              this.handleTableChange(this.state.pagination)
          }.bind(this),
          onChange: function(current) {
              this.state.pagination.current = current
              this.handleTableChange(this.state.pagination)
          }.bind(this),
          showTotal: function () {  //设置显示一共几条数据
              return '共 ' + this.state.pagination.total + ' 条'; 
          }.bind(this),
          showQuickJumper: true,
          },

    loading: false,

    tableData: [
        
    ],
    params: {
      relationshipPid: 2,
      logic: 2,
      type: null,
      status: null,
      nameUser: null,
      problemLevel: null,
      reportType: null
    },
    openStatus: false,

    isModalVisible: false,
    ModalData: { // 默认数据 不然会报错
      addImg: [],
      rectifyImg: [],
      checkImg: [],
    },
    showDom1: false,
    showDom2: false,
    ModalTitleName: '待整改',
    LogList: [], // 排查记录列表
  }


  columns = [
      {
        title: '序号',
        dataIndex: 'uicName',
        align: 'center',
        render: (_, item, index) =>{
          return (
            <p>{index + 1}</p>
          )
        }
      },
      {
        title: '发起人',
        dataIndex: 'nameCaAd',
        align: 'center',
      }, 
      {
        title: '类型',
        dataIndex: 'type',
        align: 'center',
        render: (_) => {
          return (
            <p>{_==2? '质量': '安全'}</p>
          )
        }
      },
      {
        title: '报表类型',
        dataIndex: 'reportType',
        align: 'center',
        render: (_) => {
          return (
            <p>{_==2? '日': _==3?'周':'月'}</p>
          )
        }
      },
      {
        title: '限时完成时间',
        dataIndex: 'limitedTime',
        align: 'center',
        render: (_) => {
          return (
            <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}</p>
          )
        }
      },

      {
        title: '问题级别',
        dataIndex: 'problemLevel',
        align: 'center',
        render: (_) => {
          return (
            <p className={'setProblemLevel' + _}>{_==2? '一般': _==3?'较大': '重大'}</p>
          )
        }
      },
      
      {
        title: '问题描述',
        dataIndex: 'remark',
        align: 'center',
        width: "300px"
      },
      {
        title: '整改人',
        dataIndex: 'nameCaRe',
        align: 'center',
      },
      {
        title: '整改时间',
        dataIndex: 'rectifyTime',
        align: 'center',
        render: (_) => {
          if(_ == 0) {
            return (
              <p> </p>
            )
          }  
          return (
            <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}</p>
          )
        }
      },
      {
        title: '审核人',
        dataIndex: 'nameCaCh',
        align: 'center',
      },
      {
        title: '审核时间',
        dataIndex: 'checkTime',
        align: 'center',
        render: (_) => {
          if(_ == 0) {
            return (
              <p> </p>
            )
          }  
          return (
            <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}</p>
          )
        }
      },

      {
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        render: (_) => {
          return (
            <p >{_==2? '待审核': _==3?'待整改': _==4?'逾期待整改':_==5?'已完成':_==6?"逾期完成":'逾期待审核'}</p>
          )
        }
      },
      
      {
        title: '操作',
        dataIndex: 'address',
        align: 'center',
        width: 250,
        render:(_,item) => {
          return (
            <div className="operation">
                {/* <p>任务处理</p> */}
                <p className="logSucess" onClick={this.showModal.bind(this, item)}>排查记录</p>

                <p className="setProblemLevel4" onClick={this.removeListWage.bind(this, item)}>删除</p>  
                {/* <p>退场</p>   */}
            </div>
          )
        }
      },
    ]

    showModal(item) {
      //<p >{_==2? '待审核': _==3?'待整改': _==4?'逾期待整改':_==5?'已完成':_==6?"逾期完成":'逾期待审核'}</p>

      this.state.showDom1 = false;
      this.state.showDom2 = false;
      this.state.ModalTitleName = '待整改'

      if(item.status == 2 || item.status == 7) {
        this.state.showDom1 = true;
        this.state.ModalTitleName = '待审核'
      }
      if(item.status == 5 || item.status == 6) {
        this.state.showDom1 = true;
        this.state.showDom2 = true;
        this.state.ModalTitleName = '已完成'
      }

      
      this.setState({
        isModalVisible: true,
        ModalData: item,
        showDom1: this.state.showDom1,
        showDom2: this.state.showDom2,
        ModalTitleName: this.state.ModalTitleName
      })

      this.getListInspectionLog(item.id)
    }


    // 删除排查记录
    removeListWage(item) {

      let params = {
        del: 2,
        id: item.id,
        logic: 5
      }

      React.$axios.post('saveInspection', params).then(res => {

        if(res.code == 20000) {
          message.success('删除成功')
          this.getList()


        }

      })

    }


    
    getListInspectionLog(id) {
      let params = {
        inspectionId: id,
        logic: 3,
        projectId: this.projectId
      }
      React.$axios.post('listInspectionLog', params).then(res => {
        this.setState({
          LogList: res.data.list
        })
      })

    }

    handleTableChange(row) {

      this.setState({
        pagination: row
      })

      this.getList()

    }

    componentDidMount() {
      this.getList()
    }

    // 获取隐患排查列表
    getList(type = false) {
      if(type) {
        this.state.pagination.current = 1
        this.setState({
          pagination: this.state.pagination
        })
      }
      
      this.state.params.offsetLimit = (this.state.pagination.current - 1) * this.state.pagination.pageSize
      this.state.params.lengthLimit = this.state.pagination.pageSize

      React.$axios.post('listInspection', this.state.params).then(res => {

        this.state.pagination.total = res.data.countList

        res.data.list.map(item => {
          item.addImg = item.addImg==null?[]: item.addImg.split(',')
          item.rectifyImg = item.checkImg==null?[]: item.rectifyImg.split(',')
          item.checkImg = item.checkImg==null?[]: item.checkImg.split(',')
        })

        this.setState({
          tableData: res.data.list,
          pagination: this.state.pagination
        })

      })
    }

    // 新增
    addItem() {

    }

    handleOk() {
      this.handleCancel()
    }

    handleCancel() {
      this.setState({
        isModalVisible: false
      })
    }

    setInput({target: {value}}) {
      this.state.params.nameUser = value
      this.setState({
        params: this.state.params
      })
    }

    onChangeT(name, value) {
      this.state.params[name] = value
      this.setState({
        params: this.state.params
      })
    }

    toSerch() {
      this.getList(true)
    }


        render() {
            return (
              <div className="WageEntry">
                 <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        {/* <li><span>姓名:</span> <Input  style={{ width: 200, height:30, marginRight:20,  }} onChange={this.setInput.bind(this)} placeholder="模糊搜索" /></li> */}
                        <li> <span>报表类型:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'type')}
                                >
                                <Option value=" ">全部</Option>
                                <Option value="2">质量</Option>
                                <Option value="3">安全</Option>
                                </Select>
                            </li>
                        
                        <li> <span>报表类型:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'reportType')}
                                >
                                <Option value=" ">全部</Option>
                                <Option value="2">日</Option>
                                <Option value="3">周</Option>
                                <Option value="4">月</Option>
                                </Select>
                            </li>
                        <li><span>状态:</span>  
                        <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'status')}
                                >
                                <Option value=" ">全部</Option>
                                <Option value="3">待整改</Option>
                                <Option value="4">逾期待整改</Option>
                                <Option value="7">逾期待审核</Option>
                                <Option value="2">待审核</Option>
                                <Option value="5">已完成</Option>
                                <Option value="6">逾期完成</Option>
                                </Select>
                            </li>
                         
                            <li> <span>问题级别:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'problemLevel')}
                                >
                                <Option value=" ">全部</Option>
                                <Option value="2">一般</Option>
                                <Option value="3">较大</Option>
                                <Option value="4">重大</Option>
                                </Select>
                            </li>


                            <Button  className="btn" onClick={this.toSerch.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>

                          
                    </ul>

                </div>



                <div className="table2">
                {/* <div class="allBtn">
                    <Button  className="bt" type="primary" onClick={this.addItem.bind(this)} icon={<PlusOutlined />}>
                        新建
                    </Button>
                    </div> */}

                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }} columns={this.columns} dataSource={this.state.tableData}  />
                </div>


                <Modal title="排查记录" className="WageEntryModal" width="70%" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>

                  <div className = "flex">
                    <div style={{width: '50%'}}>
                    <Tabs  type="card">
                        <TabPane tab={this.state.ModalTitleName} key="1">
                        <div className="allWarp">
                            <div className="setWarp">
                              <h1>整改要求</h1>

                              <ul>
                                <li>
                                  <p>报表类型</p>
                                  <p className="result">
                                    {this.state.ModalData.reportType == 2? '日报': this.state.ModalData.reportType == 3? '周报': '月报'}
                                  </p>
                                </li>
                                <li>
                                  <p>整改位置</p>
                                  <p className="result">
                                    {this.state.ModalData.location}
                                  </p>
                                </li>
                                <li>
                                  <div> 
                                    <p>问题类型</p>
                                    <p className="divResult">{this.state.ModalData.type == 2? '质量': '安全'}</p>
                                  </div>

                                  <div>
                                    <p>问题级别</p>
                                    <p className="divResult">{this.state.ModalData.problemLevel == 2? '一般': this.state.ModalData.problemLevel == 3? '较大': '严重'}</p>
                                  </div>
                                </li>
                              </ul>

                              <div className="remark">
                                <p>问题描述及整改要求</p>

                                <p>
                                {this.state.ModalData.remark}
                                </p>
                              </div>

                              <div className="img">
                                  {/* <Image
                                      height ={160}
                                      src={this.imgUrl + item.uiAvatarKeyCutout}
                                  /> */}
                                  {
                                    this.state.ModalData.addImg.map(item => {
                                      if(item) {
                                        return (
                                          <span>
                                            <Image
                                                width ={60}
                                                src={this.imgUrl + item}
                                            />
                                          </span>
                                        )
                                      }
                                      
                                    })
                                  }



                              </div>
                              </div>

                              <div className="setWarp" style={{display: this.state.showDom2? 'block': 'none'}}>
                              <h1>处理结果审核</h1>

                              <ul>
                                <li>
                                  <p>审核时间</p>
                                  <p className="result">
                                  { formatTime(new Date(this.state.ModalData.checkTime * 1000), 'yyyy-MM-dd') }
                                  </p>
                                </li>
                              </ul>

                              <div className="remark">
                                <p>审核意见</p>

                                <p>
                                {this.state.ModalData.checkRemark}
                                </p>
                              </div>

                              <div className="img">
                                {
                                    this.state.ModalData.checkImg.map(item => {
                                      if(item) {
                                        return (
                                          <span>
                                            <Image
                                                width ={60}
                                                src={this.imgUrl + item}
                                            />
                                          </span>
                                        )
                                      }
                                    })
                                  }
                              </div>
                              </div>     

                          </div>


                        </TabPane>

                        {/* <TabPane tab="待审核" key="2">
                        <div className="allWarp">    
                        <div className="setWarp" style={{display: this.state.showDom1? 'block': 'none'}}>
                              <h1>整改处理</h1>

                              <ul>
                                <li>
                                  <p>处理时间</p>
                                  <p className="result">
                                    { formatTime(new Date(this.state.ModalData.rectifyTime * 1000), 'yyyy-MM-dd') }
                                  </p>
                                </li>
                              </ul>

                              <div className="remark">
                                <p>处理回复</p>

                                <p>
                                 
                                  {this.state.ModalData.rectifyRemark}
                                </p>
                              </div>

                              <div className="img">
                                {
                                    this.state.ModalData.rectifyImg.map(item => {
                                      return (
                                        <span>
                                          <Image
                                              width ={60}
                                              src={this.imgUrl + item}
                                          />
                                        </span>
                                      )
                                    })
                                  }
                              </div>
                              </div>     
                        </div>          

                        </TabPane>           */}
                    </Tabs>
                    </div>


                    <div style={{width: '50%', marginLeft:20}}>
                      <Tabs  type="card">
                        <TabPane tab="排查记录" key="1">
                        <Timeline>
                           <div className = "setHeight">       
                          {
                            this.state.LogList.map(item => {
                              return (
                                <Timeline.Item>
                                    <p>{formatTime(new Date(item.time * 1000), 'yyyy-MM-dd')}</p>

                                    <div className="flex log">
                                      <p>验收员</p>   
                                      <p>{item.nameIdCardAdd}</p>   
                                    </div> 

                                    <div className="flex log">
                                      <p>状态</p>   
                                      <p>{item.status == 2? '添加任务': item.status == 3? '待整改': item.status == 4? '逾期待整改': item.status == 5? '已完成':item.status == 6? '逾期完成':'逾期待审核'}</p>   
                                    </div>

                                    <div className="flex log">
                                      <p>打回</p>   
                                      <p>{item.returnStatus==2? '否': '是'}</p>   
                                    </div>

                                    <div className="flex log">
                                      <p>备注</p>   
                                      <p>{item.remark}</p>   
                                    </div>

                                    <div className="img">
                                        <span>
                                          <Image
                                              width ={60}
                                              src={this.imgUrl + item.img}
                                          />
                                        </span>
                                    </div>               


                                  </Timeline.Item>
                              )
                            })
                          }
                          </div>
                        </Timeline>
                        </TabPane>
                      </Tabs>
                    </div>
                  </div>

                  






                </Modal>





              </div>
            );
          }

    }
)

