import React from "react";

import { DatePicker,Input,Select,Button, Table} from "antd";


import {formatTime} from '@/utils/util';

import {VerticalAlignTopOutlined, SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import { Uploadhh } from "@/components/upload/upload";

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

import './certifiSerch.less';

const {Option} =  Select;
const { RangePicker } = DatePicker;


// 作业类别
const jobCategory = {'2': '低压电工作业', '3': '电工作业', '4': '高压电工作业', '5':'焊接与热切割作业', '6':'机动车辆驾驶', '7': '建筑电工', '8':'建筑电焊工',
  '9': '建筑焊割（操作）工', '10': '金属焊工作业', '11': '金属焊接切割作业', '12': '起重机械作业', '13':'场（厂）内专用机动车辆作业'
}

const quasiExercise = {'2': '电工', '3': '电工作业', '4': '电焊工', '5': '焊工', '6':'气保焊、气焊、切割、氩弧焊', '7':'熔化焊接与热切割作业', '8': '司索工',
'9':'信号司索工', '10':'100T履带吊司机', '11': '150T履带吊司机', '12':'300T履带吊司机', '13':'60T履带吊司机', '14':'成槽机司机', '15':'罐车司机', '16': '履带吊司机',
'17':'码头吊司机', '18':'门吊司机', '19':'汽车吊司机', '20':'推车机司机', '21':'挖机司机', '22': '铁槽机司机', '23': '旋挖机司机', '24': '压路机司机', '25': '装载机司机',
'26': '自卸车司机', '27': '钻机司机', '28':'塔吊司机', '29': '叉车司机', '30': '升降机司机'}


export const CertifiSerch = (
    class CertifiSerch extends React.Component {
      loadExcelRef=''
      state = {
        // 表格分页属性
        pagination: {
          current: 1,
          pageSize: 10,
          total: 200,
          onShowSizeChange: function(current, pageSize) {
              this.state.pagination.pageSize = pageSize;
              this.handleTableChange(this.state.pagination)
          }.bind(this),
          onChange: function(current) {
              this.state.pagination.current = current
              this.handleTableChange(this.state.pagination)
          }.bind(this),
          showTotal: function () {  //设置显示一共几条数据
              return '共 ' + this.state.pagination.total + ' 条'; 
          }.bind(this),
          showQuickJumper: true,
          },

    loading: false,

    tableData: [
        
    ],

    enterpriseAllList:[],
    teamAllList: [],
    workAllList: [],
    ExcelName: null,
    params: {
      lengthLimit: 10,
      offsetLimit: 0,
      projectId: null,
      logic: 2,
      keyword: null,
      jobCategory: null,
      quasiExercise: null,
      status: null
    },

    jsonFieldsList: {
      '0_姓名': 'nameIdCard',
      '1_身份证号码':{
        field: 'idNum',
            callback: value => {
                return '&nbsp;' + value
            }
        },
      "2_发放时间": {
        field: 'releaseDate',
            callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
            }
        },
      "3_状态": {
        field: 'status',
            callback: value => {
                return value==2?'正常':value == 3?'即将过期': '已过期'
            }
        },
      "4_名称":"name",
      "5_作业类别": {
        field:'jobCategory',
        callback: value => {
          return jobCategory[value]
        }
      }, 
      "6_准操项目": {
        field: 'quasiExercise',
        callback: value => {
          return quasiExercise[value]
        }
      },
      "7_编号":{
        field: 'numbering',
            callback: value => {
                return '&nbsp;' + value
            }
        },
      "8_发放机构": "issuingAgency",
      "9_附件": "appendix",
      "10_发放日期": "releaseDate",
      "11_有效期起": {
        field: 'validFrom',
          callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
          }
        },
      "12_有效期至": {
        field: 'validUntil',
          callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
          }
        },
    },

    openStatus: false,
    jobCategoryKeys: [], // 作业类别
    quasiExerciseKeys: [], // 准操项目
  }


  columns = [
      {
        title: '姓名',
        dataIndex: 'nameIdCard',
        align: 'center'
      },
      
      {
        title: '身份证号码',
        dataIndex: 'idNum',
        align: 'center'
      },

      {
        title: '名称',
        dataIndex: 'name',
        align: 'center'
      },
      {
        title: '作业类别',
        dataIndex: 'jobCategory',
        align: 'center',
        render:(_) => {
          return (
            <p>{jobCategory[_]}</p>
          )
        }
      },
      {
        title: '准操项目',
        dataIndex: 'quasiExercise',
        align: 'center',
        render:(_) => {
          return (
            <p>{quasiExercise[_]}</p>
          )
        }
      },
      {
        title: '编号',
        dataIndex: 'numbering',
        align: 'center'
      },
      {
          title: '发放机构',
          dataIndex: 'issuingAgency',
          align: 'center'
      },
      {
          title: '附件',
          dataIndex: 'appendix',
          align: 'center'
      },
      {
          title: '发放日期',
          dataIndex: 'releaseDate',
          align: 'center',
          render:(_) => {
            return (
              <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}</p> 
            )
          }
        },
      {
        title: '有效期起 ~ 至',
        dataIndex: 'validFrom',
        align: 'center',
        render:(_, item) => {
          
          return (
            <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd')} ~ {formatTime(new Date(item.validUntil * 1000), 'yyyy-MM-dd')} </p>
          )
        }
      },
      {
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        render: (_) => {
          return (
            <p>
              {_ == 2?'正常':_==3?'即将过期':'已过期'}
            </p>
          )
        }
      },
      
      // {
      //   title: '操作',
      //   dataIndex: 'address',
      //   align: 'center',
      //   render:(_,item) => {
      //     return (
      //       <div className="operation">
      //           <p>详情</p>
      //           <p>编辑</p>  
      //           <p>退场</p>  
      //       </div>
      //     )
      //   }
      // },
    ]
    
    handleTableChange(pagination) {
      this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
      this.state.params.lengthLimit = pagination.pageSize
      this.setState({
        params: this.state.params,
        // pagination: pagination
      })
      
      // 执行更新列表
      this.getList()
    }

    componentDidMount() {
      this.setState({
        ExcelName:  '人员证件' + formatTime(new Date(), 'yyyyMMdd') + '.xls'
      })

      const projectId = React.$getStorage('projectInfo').id
      this.state.params.projectId = projectId
      this.getList()

 
      let jobCategoryKeys = Object.keys(jobCategory);
      
      let quasiExerciseKeys =  Object.keys(quasiExercise);

      this.setState({
        jobCategoryKeys: jobCategoryKeys,
        quasiExerciseKeys: quasiExerciseKeys
      })

    }

    // 获取工资列表
    getList(type = false) {
      if(type) {
        this.state.params.offsetLimit = 0
        this.state.pagination.current = 1
        this.setState({
          params: this.state.params,
          pagination: this.state.pagination
        })
      }


      this.setState({ loading: true });
      React.$axios.post('userDocumentsList', this.state.params).then(({ data }) => {

        this.state.pagination.total =  data.countList

        console.log(data.countList)

        
        this.setState({
          loading: false,
          tableData: data.list,
          pagination: this.state.pagination
        })
  
      })
    }


    setInput(name, {target:{value}}) {
      this.state.params[name] = value
      this.setState({
        params: this.state.params
      })
    }


    onChangeT(name, value) {
      this.state.params[name] = value
      this.setState({
        params: this.state.params
      })
    }

    handleFront() {
      this.getList(true)
    }

    setDrapDom() {
      if(this.state.openStatus == true) {
          return (
            <UpOutlined/>
          )
        } else {
          return (
            <DownOutlined />
          )
        }
    }

    drapHeight() {
      this.setState({
        openStatus: !this.state.openStatus
      })
    }

    getTime(value) {
      if(!value) {
        this.state.params.timeWageStart = value
        this.state.params.timeWageEnd = value
      } else {
        this.state.params.timeWageStart =parseInt(+value[0]._d / 1000) 
      this.state.params.timeWageEnd = parseInt(+value[1]._d / 1000) 
      }

      this.setState({
        params: this.state.params
      })
    }


    serchClick() {
      this.getList(true)
    }


    fetchFun() {
      return new Promise((resolve, reject) => {

        let parmas = {}
        parmas = {...parmas, ...this.state.params}
        parmas.logic = 3


        React.$axios.post('userDocumentsList', parmas).then(({ data }) => {
          resolve(data)
        })

      })
      

    }


        render() {
            return (
              <div className="WageEntry">

                 <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>人员名称:</span> <Input onChange={this.setInput.bind(this, 'keyword')}   style={{ width: 200, height:30, marginRight:20,  }}  placeholder="姓名, 证件名称, 证件编号" /></li>
                         
                            <li> <span>状态:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择角色"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'status')}
                                >
                                <Option value="">全部</Option>
                                <Option value="2">正常</Option>
                                <Option value="3">即将过期</Option>
                                <Option value="4">已过期</Option>
                                </Select>
                            </li>

                            {/* <li><span>发放日期:</span>  
                              <RangePicker style={{ width: 300, height:30, marginRight:20,  }} onChange={this.getTime.bind(this)} />
                            </li> */}

                            <li> <span>作业类别:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择角色"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'jobCategory')}
                                >
                                <Option value="">全部</Option>
                                {
                                  this.state.jobCategoryKeys.map((item, index) => {
                                    return (
                                      <Option value={item} key={index}>{jobCategory[item]}</Option>
                                    )
                                  })
                                }

                                </Select>
                            </li>

                            <li> <span>准操项目:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择角色"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'quasiExercise')}
                                >
                                <Option value="">全部</Option>
                                {
                                  this.state.quasiExerciseKeys.map((item, index) => {
                                    return (
                                      <Option value={item} key={index}>{quasiExercise[item]}</Option>
                                    )
                                  })
                                }
                                </Select>
                            </li>



                            <Button  className="btn"  onClick={this.serchClick.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>

                            <p className="setHeight"></p>     


                    </ul>

                    <p class="drapBottom" onClick={this.drapHeight.bind(this)}>
                        {/* <DownOutlined /> */}
                        {
                          this.setDrapDom()
                        }

                    </p>
                    
                </div>



                <div className="table2">
                <div class="allBtn">
                    <Button className="bt"  type="primary" icon={<BorderLeftOutlined />}>
                    <a href="https://project.1wx.cn/static/excel/import/证件导入模板.xlsx" download>下载模板</a>
                    </Button>
                    <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                        导入
                        <div className="setUPloda">   
                          <Uploadhh accept = '.xlsx' path="userDocumentsImport" pdfStatus={true}  name="file" width={80} height={30}   handleClick={this.handleFront.bind(this)} />
                        </div>    

                    </Button>
                    <DownloadExcel ref={(el) => this.loadExcelRef = el}  name={this.state.ExcelName}   fields={this.state.jsonFieldsList}  fetch={this.fetchFun.bind(this)} >    
                      <Button  className="bt" type="primary"  icon={<VerticalAlignTopOutlined />}>
                          导出
                      </Button>
                    </DownloadExcel>      

                    
                    </div>


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }}  columns={this.columns} dataSource={this.state.tableData}  />
                </div>
              </div>
            );
          }

    }
)

