import React, { useState, useEffect } from "react";
import { Link, Redirect, BrowserRouter,HashRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import debounce from 'lodash.debounce'
import './pageIndex.less';
import '../VideoTurning.less';

import Eearth from '../component/earth/earth3';
import {formatTime} from '@/utils/util';
import img5 from '@/assets/img/videoReturning/13.png';

import Projectdistrtion from './projectdistrtion/projectdistrtion3';

// 人员管理
import PersonnelSupervision from './PersonnelSupervision/PersonnelSupervision';
import PersonnelSupervisionEarth from '../component/earth/PersonnelSupervisionEarth'; // 人员管理地球

// 项目分布
import ProjectDistribution from './ProjectDistribution/ProjectDistribution';
import ProjectDistributionEarth from '../component/earth/ProjectDistributionEarth'; // 项目分布地球


// 安全监管
import SecurityEarth from '../component/earth/securityEarth'; // 人员管理地球
import Security from './security/security';


let timerout = null;

function PageIndex() {


    const [scale, setRate] = useState(1)
    const [widthHieghtRate, setWidthHieghtRate] = useState({
        width:window.innerWidth, height:window.innerHeight
    })
    const [oneMinuteAll, setOneMinuteAll] = useState({
        year: null,
        minute: null
    })
    const [currentIndex, setCurrentIndex] = useState(3)


    useEffect(() => {
        window.addEventListener('resize', setScale)
        clearInterval(timerout)

        console.log('我执行的次数')
        setMniueTime()

        setTi()
        
        return () => {
            window.removeEventListener('resize', setScale)
            clearInterval(timerout)
        }

        
    }, [])

    function setTi() {
        
        timerout = setInterval(() => {
            setMniueTime()
        }, 1000);
    }


    function setMniueTime() {


        let params = {
            year: formatTime(new Date(), 'yyyy-MM-dd'),
            minute: formatTime(new Date(), 'hh:mm')
        }
        
         setOneMinuteAll(params)
    }


    function getScale() {
        let ww=window.innerWidth/widthHieghtRate.width
        let wh=window.innerHeight/widthHieghtRate.height
        
        return ww<wh?ww: wh
    }

    function setScale() {
        debounce(() => {
            let scale= getScale()
            setRate(scale)
        }, 500)()
    }

    function setIndex(index) {
        if(index == 4) {
            
            window.open(window.location.origin + '/partyBuild')

            return
        }

        setCurrentIndex(index)
    }





  const {width, height} = widthHieghtRate

  return (
     
    <div className={`VideoTurning`}> 

    
    <div className="scale-box"
        style={{
            transform: `scale(${scale}) translate(-50%, -50%)`,
            WebkitTransform: `scale(${scale}) translate(-50%, -50%)`,
            width,
            height
        }}>

            <div className="layer">
                <div className="head">
                        <img className="iconImg" src={img5} alt=""/>
                        <div className="cube">

                        </div>
                        <div className="bannerImg">
                            <h1> i 电 建 企 业 级 数 字 工 地 管 理 系 统</h1>
                            <p className="EngLish"><p> I POWERCHINA DIGITAL SITE MANAGEMENT SYSTEM I POWERCHINA DIGITAL SITE MANAGEMENT SYSTEM </p></p>    
                        </div>

                        <div className="flex select">
                            <ul>
                            
                                <li style={{color: currentIndex==0?'#FFF576':''}}  onClick={() => {setIndex(0)}}>项目分布</li>
                                <li style={{color: currentIndex==1?'#FFF576':''}} onClick={() => {setIndex(1)}}>人员管理</li>
                                <li style={{color: currentIndex==2?'#FFF576':''}} onClick={() => {setIndex(2)}}>安全监管</li>
                                <li style={{color: currentIndex==3?'#FFF576':''}} onClick={() => {setIndex(3)}}>远程监管</li>
                                <li style={{color: currentIndex==4?'#FFF576':''}} onClick={() => {setIndex(4)}}>党建管理</li>
                            </ul>


                            <div className="wather">
                                <div className="setAbsolt">
                                    <div className="icon">
                                        
                                    </div>
                                    <div>
                                        <p>21<span>℃</span></p>
                                        <h4>昆明</h4>
                                    </div>
                                    <div>
                                        <p>{oneMinuteAll.minute}</p>
                                        <h5>{oneMinuteAll.year}</h5>
                                    </div>
                                </div>
                            
                            </div>
                        </div>

                    </div>


                    {/* 可实施方案 */}
                    {/* <Router> 
                        <Switch>
                            <Route  exact path="/Projectdistrtion" component={Projectdistrtion}></Route>

                        </Switch>

                    </Router>    */}

               

                    {
                        function() {


                            if(currentIndex == 3) {
                                return (
                                    <Projectdistrtion />
                                )
                            }

                            if(currentIndex == 2) {
                                return (
                                    <Security />
                                )
                            }


                            if(currentIndex == 1) {
                                return (
                                    <PersonnelSupervision/>
                                )
                            } 

                            if(currentIndex == 0) {
                                return (
                                    <ProjectDistribution />
                                )
                            }

                        }()
                    }

                    
            </div>

            {
                function() {
                    if(currentIndex == 3) {
                        return (
                            <Eearth /> 
                        )
                    }

                    if(currentIndex == 1) {
                        return (
                            <PersonnelSupervisionEarth /> 
                        )
                    }

                    if(currentIndex == 0) {
                        return (
                            <ProjectDistributionEarth />
                        )
                    }

                    if(currentIndex == 2) {
                        return (
                            <SecurityEarth />
                        )
                    }

                }()
            }                      
        </div>
    </div >
  )
}

// export default withRouter(PageIndex);
export default (withRouter(PageIndex));

