import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Tree, Popconfirm} from 'antd';
import './enterpriseList.less';


import EnterpriseList2 from './enterpriseList2';
import TeamList from '../teamList/teamList';
import WorkList from '../workList/workList';


const { DirectoryTree } = Tree;


function updateTreeData(list, key, children) {
    return list.map((node) => {
      if (node.key === key) {
        return { ...node, children };
      }
  
      if (node.children) {
        return { ...node, children: updateTreeData(node.children, key, children) };
      }
  
      return node;
    });
  }

  const initTreeData = [
    {
      title: 'Expand to load',
      key: '0',
    },
    {
      title: 'Expand to load',
      key: '1',
    },
    {
      title: 'Tree Node',
      key: '2',
      isLeaf: true,
    },
  ];


let enterpriseDom = null;
let TeamDom = null;
let cuurentKey = null;
let WorkDom = null;

function EnterpriseList() {

 const accessLogin =   React.$getStorage('accessLogin')
 const url = process.env.REACT_APP_BASE_URL;

 const [EnterpriseTreeData, setEnterpriseTreeData] = useState(initTreeData);
 
 const [isModalVisible, setIsModalVisible] = useState(false);
 
 


  useEffect(() => {
     getEnterpriseAll()
  }, []);


  // 获取部门
  const getTeamAll = (id) => {
    return React.$axios.post('teamAll', {enterpriseId:id})
  }



  const getEnterpriseAll= () => {
    React.$axios.post('enterpriseAll').then(res => {
        // console.log(res)

        res.data.enterpriseAll.map((item, index) => {
            item.title = (<p className="toEditI">{item.name}  <div><i onClick={() => {addList('2', item, `${index}`)}} className="adbumen">新增部门</i> <i onClick={() => {editList('1', item, `${index}`)}} className="editbumen">编辑</i> <i className="delbumen"><Popconfirm title="是否删除"  onConfirm={() => {toDetail('1',item)}}>删除</Popconfirm></i></div>  </p>);
            item.key = `${index}`;
        })

        setEnterpriseTreeData(res.data.enterpriseAll);
    })
  }

  const getListWorkAll = (id)=> {
   return React.$axios.post('workAll', {teamId: id})
  }

  const addList = (name, node, key) => {
      cuurentKey = node.key;
      if(name == '2') { // 新增部门
        try {
            TeamDom.add(node.id);
        } catch(e) {
            console.error(e);
        }
        
      } else if(name == '3') { // 新增岗位
        WorkDom.add(node.id);
      }

  }

  // 编辑
  const editList =(name, node, key) => {
    if(name === '1') { // 编辑公司
        enterpriseDom.clickFun(node);
    } else if(name == '2') { // 编辑部门
        let key = node.key.split('-')[0];
        cuurentKey = key;
        TeamDom.clickFun(node);
    } else if(name == '3') { // 编辑岗位
        let key = node.key.split('-');
        cuurentKey = key[0] + '-' + key[1];
        WorkDom.clickFun(node);
    }
    
    
   }

  // 新增公司
  const addEnterprise = () =>{ 
    enterpriseDom.add();
  }
  
  // 去删除
  const toDetail = (name , node) => {
    if(name === '1') { // 删除公司
        enterpriseDom.clickDetail(node);
    } else if(name == '2') { // 删除部门
        let key = node.key.split('-')[0];
        cuurentKey = key;
        TeamDom.clickDetail(node);
    } else if(name == '3') { // 删除岗位
        let key = node.key.split('-');
        cuurentKey = key[0] + '-' + key[1];
        WorkDom.clickDetail(node);
    }
  }


  // 保存后的返回更新数组
  const saveStypePrise = () => {
    getEnterpriseAll()
  }

  // 保存部门
  const saveTeamList = () => {
    // 保存成功
    const node =  EnterpriseTreeData[cuurentKey];
    let key = cuurentKey;
    // 更新
    getTeamAll(node.id).then(res2 => {
        res2.data.teamAll.map((item3,index) => {
            item3.title = (<p className="toEditI toEdit2">{item3.name}  <div><i className="adbumen" onClick={() => {addList('3' ,item3, key)}}>新增岗位</i> <i className="editbumen" onClick={() => {editList('2' ,item3, key)}}>编辑</i>  <i className="delbumen"><Popconfirm title="是否删除"  onConfirm={() => {toDetail('2',item3)}}>删除</Popconfirm></i></div>  </p>);
            item3.key = `${key}-${index}`;
        })
        setEnterpriseTreeData((origin) =>
        updateTreeData(origin, key, res2.data.teamAll),
        );
    })
  }

  // 保存岗位
  const saveWorkList = () => {
    let key2 = cuurentKey.split('-');
    const node =  EnterpriseTreeData[key2[0]].children[key2[1]];
    let key = cuurentKey;
    getListWorkAll(node.id).then(res => {
        res.data.workAll.map((item3, index) => {
            item3.title = (<p className="toEditI toEdit3">{item3.name}  <div> <i onClick={() => {editList('3' ,item3, key)}} className="editbumen">编辑</i> <i className="delbumen"><Popconfirm title="是否删除"  onConfirm={() => {toDetail('3',item3)}}>删除</Popconfirm></i></div>  </p>);
            item3.key = `${key}-${index}`;
            item3.isLeaf = true
        }) 
        setEnterpriseTreeData((origin) =>
            updateTreeData(origin, key, res.data.workAll),
        );     
    })
  
  }


  




   
  const onLoadData = ({ key, children }) =>
  new Promise((resolve) => {
    if (children) {
      resolve();
      return;
    }

   let keys = key.split('-')
   if(keys.length == 1) { // 加载部门
        const node = EnterpriseTreeData[keys[0]];
        
        getTeamAll(node.id).then(res2 => {
            res2.data.teamAll.map((item3,index) => {
                item3.title = (<p className="toEditI toEdit2">{item3.name}  <div><i className="adbumen" onClick={() => {addList('3' ,item3, key)}}>新增岗位</i> <i className="editbumen" onClick={() => {editList('2' ,item3, key)}}>编辑</i> <i className="delbumen"><Popconfirm title="是否删除"  onConfirm={() => {toDetail('2',item3)}}>删除</Popconfirm></i></div>  </p>);
                item3.key = `${key}-${index}`;
            })
            setEnterpriseTreeData((origin) =>
            updateTreeData(origin, key, res2.data.teamAll),
            );

            resolve();
        })
   }else { // 加载岗位
      const node =  EnterpriseTreeData[keys[0]].children[keys[1]];
      getListWorkAll(node.id).then(res => {
        res.data.workAll.map((item3, index) => {
            item3.title = (<p className="toEditI toEdit3">{item3.name}  <div> <i onClick={() => {editList('3' ,item3, key)}} className="editbumen">编辑</i> <i className="delbumen"><Popconfirm title="是否删除"  onConfirm={() => {toDetail('3',item3)}}>删除</Popconfirm></i></div>  </p>);
            item3.key = `${key}-${index}`;
            item3.isLeaf = true
        }) 
        setEnterpriseTreeData((origin) =>
            updateTreeData(origin, key, res.data.workAll),
        );     
      })

      resolve();
   }


     
  });



  return (
    <div className="enterpriseList" id="EnterpriseList">
            <WorkList ref={(el) => WorkDom = el} saveStype={saveWorkList}></WorkList>
            <EnterpriseList2 ref={(el) => enterpriseDom = el} saveStype={saveStypePrise}></EnterpriseList2>
            <TeamList ref={(el) => TeamDom = el} saveStype={saveTeamList}></TeamList>
            <div className="titleName">
              <p className="p1" >单位名称</p> 


               <p className="p2" onClick={addEnterprise}>新建单位</p> 
            </div>

        <div className="allBody">
        <Tree multiple loadData={onLoadData} showLine={true} treeData={EnterpriseTreeData} />
        </div>
    </div>
  )
}

export default withRouter(EnterpriseList);