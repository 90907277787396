import React, { useState, useRef, useEffect } from "react";
// import { Image } from 'antd';

import { DatePicker,Input,Select,Button, Table, Modal, Form, Image, message, Popover, Popconfirm} from "antd";


import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import { withRouter } from 'react-router-dom';

import {formatTime} from '@/utils/util';

import {UserRecove} from './userRecove/userRecove';

import './emergencyManagement.less';



const {Option} =  Select;

const { RangePicker } = DatePicker;


function Emergency() {
    const columns = [
        {
            title: "序号",
            dataIndex: 'title',
            align: 'center',
            render: (_, item, index) => {
              return (
                <p>{index + 1}</p>
              )
            }
          },
        {
            title: '姓名',
            dataIndex: 'nameIdCard',
            key: 'nameIdCard',
            align: 'center' 
        },
        {
            title: '公司',
            dataIndex: 'nameEnterprise',
            key: 'nameEnterprise',
            align: 'center' 
        },
        {
            title: '部门',
            dataIndex: 'nameTeam',
            key: 'nameTeam',
            align: 'center' 
        },
        {
            title: '岗位',
            dataIndex: 'nameWork',
            key: 'nameWork',
            align: 'center' 
        },

        // {
        //     title: '时间',
        //     dataIndex: 'addTime',
        //     key: 'time',
        //     align: 'center',
        //     render: (_) => {
        //         return (
        //             <p>
        //                 {formatTime(new Date(_ * 1000), 'yyyy-MM-dd')}
        //             </p>
        //         )
        //     }
        // },
        // {
        //   title: '定位地址/位置',
        //   dataIndex: 'location',
        //   key: 'location',
        //   align: 'center'
        // },
        {
            title: '短信内容',
            dataIndex: 'contentSms',
            key: 'contentSms',
            align: 'center',
            render: (_) => {
                return (
                    <span className="setContent">{_}</span>
                    // <Popover className="emergencyManagementPopover" content={_} title="短信内容" trigger="hover">
                        
                    // </Popover>
                )
            }
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (_) => {
                return (
                    <p>{_ == 2? '爆破通知':_==3?'应急群发':'SOS'}</p>
                )
            }
        },

        {
            title: '操作',
            align: 'center',
            width: 150,
            render:(_,item) => {
                return (
                  <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div className="operation">
                            <p onClick={(e) => {listDeatail(item)}}>短信</p>
                        </div>
                    <div>
                        <Popconfirm
                            title="是否确认删除?"
                            onConfirm={() => {toDel(item)}}
                            okText="Yes"
                            cancelText="No"
                        >
                          <p style={{color: '#ff0000 ', cursor:'pointer'}}>删除</p>
                        </Popconfirm>
                    </div>
                       
                  </div>
                  
                )
              }
        }

      ];

      let projectId = React.$getStorage('projectInfo').id
      let componC = null

      const [userId, setUserId] = useState(null);

      

    //   const [recove, setRecove] = useState(null);

      const [list, setList] = useState([]);
      const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
      })

      const [index, setIndex] = useState(0)

      const [isModalVisible, setIsModalVisible] = useState(false)

      const [serchParams, setSerchParams] = useState({
          type: null,
          keyword: null,
          timeStart: null,
          timeStart: null
      })
      

      const [loading, setLoading] = useState(false)



      useEffect(() => {
        getList(true);
      }, []);


      function toDel(item) {
        React.$axios.post('saveEmergencyManagement', {id:item.id, del: 2}).then(res => {
            getList();
        })
      }

      function handleTableChange(json) {
        setPagination(json)

        getList();

      }


      // 编辑
      function listDeatail(item) {
        setUserId(item.id)
        setIsModalVisible(true)

        let cindex = index + 1

        console.log(cindex)
        setIndex(cindex)

      }



      function getList(type = false) {
        let params = {
            offsetLimit: (pagination.current - 1) * pagination.pageSize,
            lengthLimit: pagination.pageSize,
            logic:2,
            projectId: projectId
        }

        for(let i in serchParams) {
            params[i] = serchParams[i]
        }


        setLoading(true)
        
        React.$axios.post('listEmergencyManagement', params).then(res => {
            
            
            setLoading(false)
            setpage(res)
            setList(res.data.list)
        })
      }


      function setpage(res) {
            pagination.current = pagination.current;
            pagination.pageSize = pagination.pageSize;
            pagination.total = res.data.countList;
            let json = JSON.parse(JSON.stringify(pagination)); 
            json.onShowSizeChange = function(current, pageSize) {
                pagination.pageSize = pageSize;
                let json = JSON.parse(JSON.stringify(pagination)); 
                setPagination(json)
                handleTableChange(json)
            }
            json.onChange = function(current) {
                pagination.current = current
                let json = JSON.parse(JSON.stringify(pagination)); 
                setPagination(json)
            handleTableChange(json)
            }
            json.showTotal = function () {  //设置显示一共几条数据
                return '共 ' + pagination.total + ' 条'; 
            }
            setPagination(json)
      }


      function onChangeT(val) {
        serchParams.type = val
        setSerchParams(serchParams)

      }

      function changeTime(val) {
          serchParams.timeStart = parseInt((+val[0]._d) / 1000)
          serchParams.timeStop = parseInt((+val[1]._d) / 1000)
          setSerchParams(serchParams)
      }

      function serchInput(val) {
        serchParams.keyword = val.target.value
        setSerchParams(serchParams)
      }

      function toSerch(val) {
        pagination.current = 1;
        getList()

      }


      function handleOk() {
          setIsModalVisible(false)
      }

      function handleCancel() {
         setIsModalVisible(false)
      }





    return (
        <div  className="EmergencyManagement">
            <div className="input">
                   <ul className='removeOpen'>     {/* this.state.openStatus?'addOpen':'removeOpen' */}

                        
                        <li><span>类型:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20, marginBottom: 20  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    onChange={onChangeT}
                                >
                                    <Option value=" ">全部</Option>
                                    <Option value="2">爆破通知</Option>
                                    <Option value="3">应急群发</Option>
                                    <Option value="4">SOS</Option>
                                </Select>
                            </li>

                            <li><span>时间:</span>  
                                <RangePicker onChange={changeTime} style={{ height:30, marginRight:20, marginBottom: 20 }} />
                            </li>
                            
                            {/* onChange={this.serchParamsFun.bind(this, 'keyword')} */}
                        <li><span>关键字:</span> <Input  onChange={serchInput}  style={{ width: 200, height:30, marginRight:20, marginBottom: 20 }} placeholder="模糊搜索" /></li>
                        {/* onClick={this.toSerch.bind(this)} */}
                            <Button  className="btn" onClick={toSerch}> 
                                <SearchOutlined   className="icon"/>
                            </Button>
                    </ul>
                </div>


                <Modal title="记录" width="80%" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    
                    <div className="setUserRecoveModal">
                    <UserRecove index = {index}  userId={userId} />
                    </div>
                    
                </Modal>



                <Table  align="center" bordered rowClassName="editable-row" pagination={pagination} 
                    loading={loading} rowKey={record => record.id}   columns={columns} dataSource={list}  />               

        </div>
    )



}









export default withRouter(Emergency);