
import * as echarts from 'echarts';


// 设置图形
export  function setEchartDiaozhong() {
        let chartDom = document.getElementById('diaozhong');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
            grid: {
                left: '7%',
                right: '7%',
                bottom: '5%',
                top: '10%',
                containLabel: true
            }, 
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            areaStyle: {}
            }
        ]
        };

        option && myChart.setOption(option);

    }


    export  function setEchartliju() {
        let chartDom = document.getElementById('liju');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
            grid: {
                left: '7%',
                right: '7%',
                bottom: '5%',
                top: '10%',
                containLabel: true
            }, 
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            areaStyle: {}
            }
        ]
        };

        option && myChart.setOption(option);

    }


 
// 仪表盘 
export function setEchartDashboardChart() {
    var chartDom = document.getElementById('DashboardChart');
    var myChart = echarts.init(chartDom);
    var option;

    option = {
        grid: {
            left: '7%',
            right: '7%',
            bottom: '5%',
            top: '15%',
            containLabel: true
        }, 
    series: [
        {
        type: 'gauge',
        radius: "75%", 
        startAngle: 90,        // 仪表盘起始角度,默认 225。圆心 正右手侧为0度，正上方为90度，正左手侧为180度。
        endAngle: -269.999,   
        min: 0,                 // 最小的数据值,默认 0 。映射到 minAngle。
        max: 360, 
        // startAngle: 0,
        // endAngle: 359.9999,
        axisLine: {
            lineStyle: {
            width: -20,
            color: [
                [0.5, '#67e0e3'],
                [0.75, '#37a2da'],
                [1, '#fd666d'],
            ]
            }
        },
        pointer: {
            itemStyle: {
            color: 'auto'
            }
        },
        axisTick: {
            distance: -0,
            length: 8,
            lineStyle: {
            color: '#fff',
            width: 2
            }
        },
        splitLine: {
            distance: 0,
            length: 20,
            lineStyle: {
            color: '#fff',
            width: 2
            }
        },
        axisLabel: {
            color: 'inherit',
            distance: -15,
            fontSize: 10
        },
        detail: {
            valueAnimation: true,
            // formatter: '{value} km/h',
            color: 'inherit',
            fontSize: 20
        },
        data: [
            {
            value: 100
            }
        ]
        }
    ]
    };
    // setInterval(function () {
    // myChart.setOption({
    //     series: [
    //     {
    //         data: [
    //         {
    //             value: +(Math.random() * 100).toFixed(2)
    //         }
    //         ]
    //     }
    //     ]
    // });
    // }, 2000);

    option && myChart.setOption(option);
}