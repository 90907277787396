import React from "react";
import './SecurityInvestigation.less';

import {Button} from "antd";

import {WageEntry} from "./WageEntry/WageEntry"

import {OverviewImg} from './overviewImg/overviewImg'


export default class Wages extends React.Component {
 
  state = {
      status: null,
      childBtn: []
  }


  changeStatus(e) {
      this.setState({
        status: e
      })

      React.$setStorage('SecurityInvestigation', e)
  }

  componentWillMount () {
    let childBtn = React.$getStorage('menuchildBtn');
    for(let i = 0; i < childBtn.length; i++) {
      if(childBtn[i].show) {
        let item = childBtn[i];
        this.changeStatus(item.index);
        break;
      }
    }
  

    this.setState({
      childBtn: childBtn
    })


    let t =  React.$getStorage('SecurityInvestigation')

        if(t) {
            this.setState({
                status: t
            })
        }
  }

   // 组件离开生命周期
   componentWillUnmount() {
        // React.$setStorage('pageIndex1', null)
         localStorage.removeItem('SecurityInvestigation');
    }

  
  

  render() {
    return (
      <div className= "wages">

          <div className="btn">
            {
              this.state.childBtn.map((item => {
                if(item.show) {
                  return (
                    <Button  type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                  )
                }
              }))
            }

           {/* <Button  type={this.state.status==1? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 0)}>排查概览</Button>
           <Button className="setBtn" type={this.state.status==2? 'primary': ''}  onClick={this.changeStatus.bind(this, 1)}>排查记录</Button> */}
          </div>
          
          {
              function() {
                  if(this.state.status == 0) {
                      return (
                          <OverviewImg />
                        
                      )
                  }

                  if(this.state.status ==  1) {
                    return (
                        <WageEntry />
                    )
                  }
              }.bind(this)()
          }
          

      </div>
    );
  }
}
