
import * as THREE from 'three';
import {
  AmbientLight,
  AxesHelper,
  DirectionalLight,
  GridHelper,
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
  Color,
  Group,
  PointLight,
  Raycaster,
  Vector2,
  MeshLambertMaterial
} from "three";
import {
    OrbitControls
} from "three/examples/jsm/controls/OrbitControls";

import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

import { IFCLoader } from "web-ifc-three/IFCLoader";


import {ShadowDropper} from './ShadowDropper.js';

import {
    IFCWALLSTANDARDCASE,
    IFCSLAB,
    IFCDOOR,
    IFCWINDOW,
    IFCFURNISHINGELEMENT,
    IFCMEMBER,
    IFCPLATE,
    IFCBUILDING,
    IFCBUILDINGSTOREY
} from 'web-ifc';
import { CompositeEntityCollection } from 'cesium';



// // 类别名称列表
const categories = {
    IFCWALLSTANDARDCASE,
    IFCSLAB,
    IFCFURNISHINGELEMENT,
    IFCDOOR,
    IFCWINDOW,
    IFCPLATE,
    IFCMEMBER,
    IFCBUILDING,
    IFCBUILDINGSTOREY
};

// uniform float v;
const ClippingPlanes = {
    uniforms: {
        'tDiffuse': {'type': 't', 'value': null},
        'clippingLow': {value: new THREE.Vector3(-5, -1, -5)},
        'clippingHigh': {value: new THREE.Vector3(10, 50, 10)},
        'color': {value: new THREE.Color( 0x3d9ecb ) }
    },
    vertexShader: /* glsl */`
    varying vec2 vUv;
    varying vec3 pixelNormal;
    varying vec4 worldPosition;

    void main() {
        vUv = uv;
        pixelNormal = normal;
  
		worldPosition = modelMatrix * vec4( position, 1.0 );
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

    }`,
    fragmentShader: /* glsl */`
    uniform vec3 color;
    uniform vec3 clippingLow;
    uniform vec3 clippingHigh;
    varying vec3 pixelNormal;
    varying vec4 worldPosition;

    void main() {
        float shade = (
            3.0 * pow ( abs ( pixelNormal.y ), 2.0 )
		  + 2.0 * pow ( abs ( pixelNormal.z ), 2.0 )
		  + 1.0 * pow ( abs ( pixelNormal.x ), 2.0 )
        )/3.0;
        
        if (
               worldPosition.x < clippingLow.x
            || worldPosition.x > clippingHigh.x
            || worldPosition.y < clippingLow.y
            || worldPosition.y > clippingHigh.y
            || worldPosition.z < clippingLow.z
            || worldPosition.z > clippingHigh.z
        ) {
            
            discard;
            
        } else {
            
            gl_FragColor = vec4( color * shade, 1.0 );
            
        }
    }
    `
};

// 我搞不懂了啊


let _this = null; 
export  class Measure {
    constructor(dom)  {
        try {
            // 如果存在就清除
            this.dispose()
        } catch(e) {

        }
        _this = this;
        this.callbackFun = {}; // 事件容器
        this.mapGroup = new THREE.Group();
        this.mapGroup.name = 'map';
        this.controlGroup = new THREE.Group(); // 操作容器
        this.camera = null; // 相机
        this.scene = null; // 场景
        this.group = null; // 容器
        this.controls = null; 
        this.renderer = null;
        this.light = null;
        this.threeDom = dom; // Dom 容器
        this.ifcModels = []; // 当前模型
        this.ifsMinModels = []; // 当前点击获取的模块
        this.LoopId = null;
        this.ids = []; // 所有模型的ID
        this.preselectMat = new MeshLambertMaterial({ // 改变颜色材质
            transparent: true,
            opacity: 0.6,
            color: 0xff88ff,
            depthTest: false
        })
        this.preselectMatoOacity0 =  new MeshLambertMaterial({ // 改变颜色材质
            transparent: true,
            opacity: 0.1,
            color: 0xff88ff,
            depthTest: false
        })


        // this.clippingPlanesMateail = new THREE.ShaderMaterial(ClippingPlanes);



        this.shadowDropper = null;

        this.shadows = {}; // 着色器容器

        /**
         * 鼠标控制操作
         */
        this.raycaster = new Raycaster();
        this.raycaster.firstHitOnly = true;
        this.mouse = new Vector2();

        this.ifcLoader = new IFCLoader();
        
        this.setUpMultiThreading(this.ifcLoader.ifcManager);

        
        this.init();

        // this.addEventLeft()
        // this.removeEvent();
    } 

    async setUpMultiThreading(manager) {
        // 设置 wasm文件
        // manager.useWebWorkers(true, '../../wasm/');
        manager.setWasmPath('../../wasm/');
        
    }



    // 清除内存
    dispose() {
        // 这将释放所有的IFCLoader内存
        this.ifcLoader.ifcManager.dispose();
        this.ifcLoader = null;
        this.ifcModels = []
        cancelAnimationFrame(this.LoopId)
        this.clearGroup(this.controlGroup);
    }


    // 清除group
    clearGroup(group) {
        // 释放 几何体 和 材质
        const clearCache = (item) => {
          try {
            item.geometry.dispose();
            item.material.dispose();
          } catch(e) {

          }
          
        };
        
        // 递归释放物体下的 几何体 和 材质
        const removeObj = (obj) => {
          let arr = obj.children.filter((x) => x);
          arr.forEach((item) => {
            if (item.children.length) {
              removeObj(item);
            } else {
              clearCache(item);
              item.clear();
            }
          });
          obj.clear();
          arr = null;
        };
        removeObj(group);
      }
    
    // 事件执行down
    mousedownEvent(e) {
        for(let i in _this.callbackFun) {
            let item = _this.callbackFun[i];
           if(e.button == 0 && item.type == 'LEFT') {
                item.callback(e)
           }
           if(e.button == 2 && item.type == 'RIGHT') {
                item.callback(e)
           }
        }
    }

    // 事件执行UP
    mouseUpEvent(e) {
        _this.callbackFun['UP'].callback(e);
    }
    // 事件执行move
    mouseMoveEvent(e) {
        _this.callbackFun['MOVE'].callback(e);
    }
     /**
      * 
      * @param {*} callback 
      *     
      */
      addEventUp(callback) {
        this.callbackFun['UP'] = {
            type: 'UP',
            callback: callback
        }

        this.threeDom.removeEventListener('mouseup', this.mouseUpEvent);
        this.threeDom.addEventListener('mouseup', this.mouseUpEvent);
    }

    

    /**
     *  点击左键
     */
    addEventLeft(callback,type="LEFT") {
        // 放事件容器
        this.callbackFun[type] = {
            type: type,
            callback: callback
        }
        this.threeDom.removeEventListener('mousedown', this.mousedownEvent);
        this.threeDom.addEventListener('mousedown', this.mousedownEvent);
    }

    /**
     * 鼠标移动的事件
     * @param {*} callback 
     * @param {*} type 
     */
    addEventMove(callback) {
         // 放事件容器
         this.callbackFun['MOVE'] = {
            type: 'MOVE',
            callback: callback
        }
        this.threeDom.removeEventListener('mousemove', this.mouseMoveEvent);
        this.threeDom.addEventListener('mousemove', this.mouseMoveEvent);
    }

    /**
     *  点击右键
     */
     addEventRight(callback) {
        this.addEventLeft(callback, 'RIGHT');
     }

     // 移出事件
     removeEvent() {
        this.threeDom.removeEventListener('mousedown', this.mousedownEvent);
        this.threeDom.removeEventListener('mouseup', this.mouseUpEvent);
        this.threeDom.removeEventListener('mousemove', this.mouseMoveEvent);
        
        // this.callbackFun = {};
        // this.ifsMinModels.map(item => { // 清除ID
        //     ifc.removeSubset(item, this.preselectMatoOacity0);
        // })
        // this.ifsMinModels = [];
     }

 
    init() { // 开始加载
        this.scene = new Scene();
        this.scene.background = new Color(0xffffff);
        const _this = this;

        this.camera = new PerspectiveCamera(
            45,
            this.threeDom.offsetWidth / this.threeDom.offsetHeight,
            1,
            1000000
        );

        this.camera.position.set(90, 25, -20);

        const light1 = new DirectionalLight(0xffeeff, 0.8);
        light1.position.set(1, 1, 1);
        this.scene.add(light1);
        const light2 = new DirectionalLight(0xffffff, 0.8);
        light2.position.set(-1, 0.5, -1);
        this.scene.add(light2);
        const ambientLight = new AmbientLight(0xffffee, 0.25);
        this.scene.add(ambientLight);


        // 添加容器    
        this.scene.add( this.mapGroup );
        this.scene.add( this.controlGroup );
        
        let labelRenderer = new CSS2DRenderer();
        labelRenderer.setSize( window.innerWidth, window.innerHeight );
        labelRenderer.domElement.style.position = 'absolute';
        labelRenderer.domElement.style.top = '0px';
        this.threeDom.appendChild( labelRenderer.domElement );


        this.renderer = new WebGLRenderer({ antialias: true });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.threeDom.offsetWidth,this.threeDom.offsetHeight);
        this.renderer.localClippingEnabled = true;
        // renderer.toneMapping = THREE.ReinhardToneMapping; // 曝光值
        // this.renderer.shadowMap.enabled = true; // 启动阴影
        // this.renderer.shadowMap.type =  THREE.PCFSoftShadowMap;

        this.threeDom.appendChild(this.renderer.domElement);

        this.shadowDropper = new ShadowDropper(this);

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls = new OrbitControls(this.camera, labelRenderer.domElement);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.25;
        this.controls.screenSpacePanning = false;
        this.controls.update();


        Update()
        function Update() {
            _this.LoopId =  requestAnimationFrame(Update);
            _this.renderer.render(_this.scene, _this.camera);
            _this.controls.update();
            labelRenderer.render( _this.scene, _this.camera);
   
        }

    }

    /**
     * 
     * @param {*} checkedNodes  [{expressID: 0}] 或者 {ids: []}
     * @param {*} update 全部的ID是否保存
     */
    // 当前选中的模型对象 显示和隐藏
    checkedKeys(checkedNodes, update = false) {
        let _this = this
        let ids = []

        if(checkedNodes instanceof Array) { // 如果是数组
            for(let i = 0; i < checkedNodes.length; i++) {
                let item = checkedNodes[i];
                let id = item.expressID;
                ids.push(id)
            }
        } else { // 直接赋值
            ids = checkedNodes.ids;
        }

        if(update) { // 保存第一次进来的所有id
            this.ids = ids;
        }


        let subsets = this.ifcLoader.ifcManager.createSubset({
            modelID: 0,
            scene: _this.mapGroup,
            ids,
            removePrevious: true,
            // material: this.clippingPlanesMateail,
        })
        // subsets.castShadow = true;
        _this.mapGroup.add(subsets);
    }


    // 加载模型
    loadModal(url, callback) {
        this.ifcLoader.load(
            url,
            (ifcModel) => {
      
              // 存储已创建的子集
              this.ifcModels.push(ifcModel);
              // 放在场景
              // this.mapGroup.add(ifcModel);

            //   let box = new THREE.Box3().setFromObject(this.mapGroup);
            //   let mdlen = box.max.x - box.min.x; // 模型长度
            //   let mdwid = box.max.z - box.min.z; // 模型宽度
            //   let mdhei = box.max.y - box.min.y; // 模型高度
            //   let x1 = box.min.x + mdlen / 2; // 模型中心点坐标X
            //   let y1 = box.min.y + mdhei / 2; // 模型中心点坐标Y
            //   let z1 = box.min.z + mdwid / 2; // 模型中心点坐标Z
            //   this.mapGroup.position.set(-x1, -y1, -z1);
            
        
           
            this.shadowDropper.renderShadow(ifcModel);   
            //   this.renderShadow(ifcModel)  

              this.ifcLoader.ifcManager.getSpatialStructure(ifcModel.modelID).then(res => {
                  console.log(res);  
                  if(callback) {
                      callback(res)
                  }
              })
            //  setupAllCategories();
            }, (e) => {
            //   console.log(e)
            }, (e) => {
              console.log(e)
            });

    }

    // 获取当前点击的对象
    cast = (event) => {
        // 计算鼠标在屏幕上的位置
        const bounds = this.threeDom.getBoundingClientRect();
        const x1 = event.clientX - bounds.left;
        const x2 = bounds.right - bounds.left;
        this.mouse.x = (x1 / x2) * 2 - 1;
      
        const y1 = event.clientY - bounds.top;
        const y2 = bounds.bottom - bounds.top;
        this.mouse.y = -(y1 / y2) * 2 + 1;
        // 将其放置在指向鼠标的相机上

        try {
            this.raycaster.setFromCamera(this.mouse, this.camera);
            // 投射射线
            return this.raycaster.intersectObjects(this.ifcModels);
        }catch(e) {
            console.log(e)
        }
    }


    // 获取IFc 属性的方法
    async getItemProperties(modelID, id) {
        const ifc = this.ifcLoader.ifcManager;
        const props = await ifc.getItemProperties(modelID, id, true);

        return props;
    }


    // 点击修改颜色
    async pick(event, callback) {
        
        const found = this.cast(event)[0];
         // 创建子集材料
        const ifc = this.ifcLoader.ifcManager;
        this.ifsMinModels.map(item => { // 清除ID
            ifc.removeSubset(item, this.preselectMat);
        })

        this.ifsMinModels = []; // 清空ID;
        if (found) {
            const index = found.faceIndex;
            const geometry = found.object.geometry;

            const id = ifc.getExpressId(geometry, index);

            const modelID = found.object.modelID;
            try { 
                // 
                this.ifcLoader.ifcManager.createSubset({
                    modelID: modelID,
                    ids: [id],
                    material: this.preselectMat,
                    scene: this.mapGroup,
                    removePrevious: true
                })
                this.ifsMinModels.push(modelID);

              const props = await ifc.getItemProperties(modelID, id, true);
                
              if(callback) {
                // console.log(props)
                callback(props);
              }

            } catch(e) { // 报错不影响页面功能 哈哈哈哈哈哈
              console.error(e);
            }
  
        } else {
             // 删除材质
            // ifc.removeSubset(model.id, material);   
        }
    }


    // 操作属性
    attribute(callback) {
        let params = {};
        let that = this;
        this.addEventLeft((e) => {
            params = e;
        })

        this.addEventUp((e) => {
            if(Math.abs(e.clientX - params.clientX) <=5 && Math.abs( e.clientY == params.clientY ) <= 5) {
                that.pick(e, (data, key) => {
                //   const arr = treeDataDom(data); // 当前选中的值
                //   setTreeData(arr)
                //   setLoadTree(true)
                  callback(data, key)
              });
            }
        })

        // 右键清除事件
        this.addEventRight((e) => {
            that.removeEvent()
            callback(false)
            params = null;
            
        })

        // this.addEventMove((e) => {
        //     console.log(e)
        // })

       
    }




    // 距离测量
    distance(callback) {
        let params = {};
        let that = this;
        let marker1 = null;
        let line = null
        this.addEventLeft((e) => { // 鼠标左键
            params = e;
            const found = that.cast(e)[0];

            if(found) {
                addSphereGeometry(found);
            }
        })

        this.addEventUp((e) => {
           
        })

        // 一直移动
        this.addEventMove((e) => {
            // 存在一个点
            if(marker1) {
                const found = that.cast(e)[0];
                if(found) {
                    updateLinePoint(found.point)
                }

            }


        })

        
        this.addEventRight((e) => { // 鼠标右键清除全部事件
             // 存在一个点
             const found = that.cast(e)[0];
             if(found) {
                 addSphereGeometry(found);
             }

            that.removeEvent()
            callback(false)
            params = null;
        })

        // 创建文字
        function addFontThree(text) {
            const earthDiv = document.createElement( 'div' );
            let p = document.createElement( 'p' );
            earthDiv.appendChild(p)

            p.textContent = text

            earthDiv.style.marginTop = '-1em';
            const earthLabel = new CSS2DObject( earthDiv );
            
            return earthLabel;
        }



        // 更新线的位置
        function updateLinePoint(point) {
            const positions = line.geometry.attributes.position.array;

            positions[0] = point.x;
            positions[0 + 1] = point.y;
            positions[0 + 2] = point.z;

            line.geometry.attributes.position.needsUpdate = true;
           // console.log(positions)
        }


        // 添加一个圆圈 再添加一根线
        function addSphereGeometry(found) {
            // 添加当前距离
            if(!marker1) {
                let earthLabel = addFontThree('0m')
                earthLabel.position.set(found.point.x, found.point.y, found.point.z);
                that.controlGroup.add( earthLabel );  
            } else {
                // point1 = marker1;
                let point1 = marker1.position;
 
                let point2 = found.point;
                const v0 = new THREE.Vector3(
                    point1.x,
                    point1.y,
                    point1.z
                )
                const v1 = new THREE.Vector3(
                    point2.x,
                    point2.y,
                    point2.z
                )
                const distance  =  v0.distanceTo(v1);  
                let earthLabel = addFontThree(distance.toFixed(2) + 'm')
                earthLabel.position.set(found.point.x, found.point.y, found.point.z);
                that.controlGroup.add( earthLabel );  
            }


            marker1 = new THREE.Mesh(
                new THREE.SphereGeometry(0.3, 10, 20),
                new THREE.MeshBasicMaterial({
                    color: 0xff5555,
                })
            );
            

   
            marker1.position.x = found.point.x
            marker1.position.y = found.point.y
            marker1.position.z = found.point.z
            that.controlGroup.add(marker1);
            
             //构建虚线
            const geometry = new THREE.BufferGeometry().setFromPoints(
                [found.point, found.point.clone()]
            )

            line =  new THREE.LineSegments(
                geometry,
                new THREE.LineDashedMaterial({
                    color: 0xff5555,
                    transparent: true,
                    depthTest: false,
                    dashSize: 0.1,//短划线的大小
                    gapSize: 0.1//短划线之间的距离
                })
            )
                
            
            that.controlGroup.add(line);
        }

    }

    // 剖切
    Sectioning(callback) {
        let that = this;

        let Box = new THREE.Box3().setFromObject(this.mapGroup);
        //console.log(box);
        // let mdlen = box.max.x - box.min.x; // 模型长度
        // let mdwid = box.max.z - box.min.z; // 模型宽度
        // let mdhei = box.max.y - box.min.y; // 模型高度
        // console.log(mdlen, mdwid, mdhei)
        // let x1 = box.min.x + mdlen / 2; // 模型中心点坐标X
        // let y1 = box.min.y + mdhei / 2; // 模型中心点坐标Y
        // let z1 = box.min.z + mdwid / 2; // 模型中心点坐标Z
        // const planeGeo = new THREE.PlaneGeometry(planeSize, planeSize);

        const pointArr = [];
        for(let i = 0; i < 8; i++) {
            pointArr[i] = new THREE.Vector3();
        }
        pointArr[0].set(Box.max.x, Box.max.y, Box.max.z);
        pointArr[1].set(Box.min.x, Box.max.y, Box.max.z);
        pointArr[2].set(Box.min.x, Box.min.y, Box.max.z);
        pointArr[3].set(Box.max.x, Box.min.y, Box.max.z);

        pointArr[4].set(Box.max.x, Box.max.y, Box.min.z);
        pointArr[5].set(Box.min.x, Box.max.y, Box.min.z);
        pointArr[6].set(Box.min.x, Box.min.y, Box.min.z);
        pointArr[7].set(Box.max.x, Box.min.y, Box.min.z);

        this.addEventLeft((e) => { // 鼠标左键
            
        })

        this.addEventRight((e) => { // 鼠标右键清除全部事件
           that.removeEvent()
           callback(false)
       })


    }


    Quarantine(callback) { // 隔离
        let that = this;
        let params = {};
        this.addEventLeft((e) => { // 鼠标左键
            const found = this.cast(e)[0];
            const ifc = this.ifcLoader.ifcManager;
            if (found) {
                const index = found.faceIndex;
                const geometry = found.object.geometry;
                const id = ifc.getExpressId(geometry, index);
                this.checkedKeys([{expressID: id}])
            }

        })

        this.addEventRight((e) => { // 鼠标右键清除全部事件
           that.removeEvent()
           callback(false)
       })

    }


    hide(callback) { // 隐藏
        let that = this;
        this.addEventLeft((e) => { // 鼠标左键
            const found = this.cast(e)[0];
            const ifc = this.ifcLoader.ifcManager;
            if (found) {
                const index = found.faceIndex;
                const geometry = found.object.geometry;
                const id =  ''+ ifc.getExpressId(geometry, index);
                let arr = [...this.ids];
                arr = arr.join(',');
                arr += ',';
                let indexof =  arr.indexOf(id);
                if(indexof != -1) {
                    let str = arr.replace(id + ',', '');
                    str = str.split(',');
                    str = str.map(Number);
                    let strData = {
                        ids: str,
                        type: 'Object'
                    }
                    this.checkedKeys(strData);
                }
            }
        })

        this.addEventRight((e) => { // 鼠标右键清除全部事件
           that.removeEvent()
           callback(false)
       })
    }


    allShow() { // 全部显示
       this.clearGroup(this.controlGroup);
       const ifc = this.ifcLoader.ifcManager;
       this.ifsMinModels.map(item => { // 清除ID
           ifc.removeSubset(item, this.preselectMat);
       })


       let strData = {
            ids: this.ids,
            type: 'Object'
        }
        this.checkedKeys(strData);
    }

    /**漫游 */
    roam() {
        
    }

}










