import React from "react";

import {Button, Table, Modal, Form, Input, message, Select, DatePicker} from "antd";



import './workList.less';

import moment from 'moment';


import {formatTime} from '@/utils/util';


const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 16},
  };


export default class WorkList extends React.Component {
  state = {
    status: 0,
    loading: false,
    tableData: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    requestParmas: {
        name: null,
        weigh: 0,
        teamId: null
    },
    params : {
        lengthLimit: 10,
        offsetLimit: 0
    },

    teamAllList: [],
  } 
  FormSbmitRef = null
  
 columns = [
    {
      title: '名称',
      dataIndex: 'name',
      align: 'center',
    },

    {
        title: '权重',
        dataIndex: 'weigh',
        align: 'center',
    },

    {
      title: '编辑时间',
      dataIndex: 'editTime',
      align: 'center',
      width: 250,
      render: (_) => {
        let date = null
        if(_ != 0) {
          date =formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')  
        } else {
          date = 0
        }
        return (
          <p>
            {
              date
            }
          </p>
        )
      }
    },

    {
      title: '操作',
      dataIndex: 'address',
      align: 'center',
      width: 150,
      render:(_,item) => {
        return (
          <div className="operation">
              <p onClick={() => this.clickFun(item, '2')}>编辑</p>
              <p className="red" onClick={() => this.clickDetail(item, '2')}>删除</p>
          </div>
        )
      }
    },
  ]


  componentDidMount() {
    // this.getList(this.props.id)
  }



  handleTableChange(pagination) {

    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize

    this.setState({
      params: this.state.params,
      pagination: pagination
    })


    // 执行更新列表
    // this.getList(this.props.id)
  }

  
  clickDetail(item) {
    item.del = 2

    let params = {
      name: null,
      weigh: 0,
      teamId: null,
      id: null
    }

    for(let i in params) {
      params[i] = item[i]
    }

    params.del = 2;

 
    this.saveList(params, '删除成功')
  }


  clickFun(item) {

    this.setState({
        isModalVisible: true
    })


    for(let i in this.state.requestParmas) {
      this.state.requestParmas[i] = item[i]
    }

  
    this.state.requestParmas.id = item.id

    this.setState({
      requestParmas: this.state.requestParmas
    })

    
    this.clierFormSbmitRef()

  }


  clierFormSbmitRef() {
    if(!this.FormSbmitRef) {
        setTimeout(() => {
          
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
  }



  getList(id) {
    this.setState({
        loading: true
    })
    React.$axios.post('workAll', {id: id}).then(res => {
        if(!res.data.countList) {
          res.data.countList = res.data.workAll.length;
        }
        this.state.pagination.total = res.data.countList
        
        this.setState({
            tableData: res.data.workAll,
            loading: false,
            pagination: this.state.pagination
        })

    })
  }




  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  handleOk() {
    // console.log(this.state.requestParmas)  
    
    this.FormSbmitRef.submit()
  }

  // 点击新增
  add(id) {
      for(let i in this.state.requestParmas) {
        this.state.requestParmas[i] = null
      }
      this.state.requestParmas.weigh = 0
      this.state.requestParmas.teamId = id
      delete this.state.requestParmas.id

      this.setState({
        isModalVisible: true,
        requestParmas: this.state.requestParmas
      })

      this.clierFormSbmitRef()
  }

  setInput(name, { target: { value } }) {

    this.state.requestParmas[name] = value
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  // 选择下拉数据
  handleChange(value, item) {
    this.state.requestParmas[value] = item
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }
  

  handleFinger(item) {
    this.state.requestParmas.img = [...this.state.requestParmas.img, ...item.data.key]
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  // 
  onFinish(value) {
  

    for(let i in value) {
      this.state.requestParmas[i] = value[i]
    }


    this.saveList(this.state.requestParmas)
  }


  saveList(params ,type) {
    let message2 = '保存成功'
      if(type) {
      message2 = type
      }

      console.log(params)


      React.$axios.post('addSaveWork', params).then(res => {
        if(res.code == 20000) {
            message.success(message2)
            this.setState({
            isModalVisible: false  
            })
            
            this.props.saveStype()
            // this.getList(this.props.id)
        }
      })
  }


  onChangeSelect() {
    
  }


  render() {
    return (
      <div className="WorkList">
            {/* <div className="warp1">



                <div className="head">
                    <button onClick={this.add.bind(this)}>新增</button>
                </div>

                <div className="table">
                <Table  align="center" bordered rowClassName="editable-row"
                //  pagination={this.state.pagination}
                loading={this.state.loading}
                 rowKey={record => record.id} 
                  columns={this.columns} dataSource={this.state.tableData}  />

                </div>
        
            </div> */}

          <Modal title="添加" width="900px" className="ProgressNodeModal" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                        <Form
                            layout="vertical"
                            onFinish={this.onFinish.bind(this)}
                            ref={(el) => this.FormSbmitRef = el}
                            {...layout}
                            initialValues = {this.state.requestParmas}
                        >   
                            <div className="flex setFlex">
                                <div>
                                    <Form.Item   label="名称" name="name"   rules={[{ required: true }]}>
                                    <Input style={{ width:350,  }}  placeholder="请输入名称" />
                                    </Form.Item>

                                    
                                </div>
                                
                                <div>
                                    <Form.Item label="权重"   name="weigh" rules={[{ required: true }]} >
                                    <Input style={{ width:350,  }}  placeholder="请输入权重" />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>

        </Modal> 

      </div>
    );
  }
}
