import React from "react";

import {Button, Table, Modal, Form, Input, message, Image} from "antd";
// 添加furon

 
import { Uploadhh } from "@/components/upload/upload";

import './BannerConfig.less';



import MobilePhone from './MobilePhone/MobilePhone';

export default class BannerConfig extends React.Component {
  FormSbmitRef = null
  imageUrl = process.env.REACT_APP_IMG_URL
  state = {
    status: null,
    loading: false,
    img: [],
    projectData:{

    },
    indexCarouselImgList: [],
    childBtn: []
  } 
  




  changeStatus(e) {
    this.setState({
      status: e
    })

    React.$setStorage('pageIndex1', e)
 }

 componentWillMount () {
    let childBtn = React.$getStorage('menuchildBtn');
    for(let i = 0; i < childBtn.length; i++) {
        if(childBtn[i].show) {
        let item = childBtn[i];
        this.changeStatus(item.index);
        break;
        }
    }


    this.setState({
        childBtn: childBtn
    })


    let t =  React.$getStorage('pageIndex1')
    if(t) {
        this.setState({
            status: t
        })
    }
}

  componentDidMount() {
    this.getList()
    this.getInfoProject()
  }


  // 组件离开生命周期
  componentWillUnmount() {
    // React.$setStorage('pageIndex1', null)
    localStorage.removeItem('pageIndex1');
  }


  getList() {
    this.setState({
        loading: true
    })

    
  }

  getInfoProject() {
    React.$axios.post('infoProject').then(res => {
        let data = res.data.infoProject[0]
        let indexCarouselImgList =  JSON.parse(data.indexCarousel)
        
        this.setState({
            projectData: res.data.infoProject[0],
            indexCarouselImgList: indexCarouselImgList,
        })
    })
    
  }


  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }




  setInput(name, { target: { value } }) {

    this.state.requestParmas[name] = value
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

//   handleFinger(item) {
//     this.state.requestParmas.img = [...this.state.requestParmas.img, ...item.data.key]
//     this.setState({
//         requestParmas: this.state.requestParmas
//     })
//   }


  handleFinger(type,params, item) {

    if(!type || !params) {
        return
    }

    console.log(type);

    this.state[type] = [...this.state[type], ...item.data.key]
    
    this.setState({
        [type]: this.state[type]
    })

    

    this.toSaveProject(type ,params)

  }



  // 删除图片
//   reMoveImgList(index) {
//     this.state.requestParmas.img.splice(index, 1)
//     this.setState({
//         requestParmas: this.state.requestParmas
//     })
//   }

  reMoveImgList(index, list, params) {
    this.state[list].splice(index, 1)
    
    this.setState({
        [list]: this.state[list]
    })

    this.toSaveProject(list,params)
  }


  toSaveProject(type ,params) {
    let params2 = {
        [params]: this.state[type] 
    }
    
    React.$axios.post('projectEditSave', params2).then(res => {
        if(res.code == 20000) {
            message.success('保存成功')
        }
    })
  }

  changeInput(text , e) {
    this.state.projectData[text] = e.target.value;
    this.setState({
        projectData: this.state.projectData
    })

    
    React.$axios.post('projectEditSave', {[text]: e.target.value}).then(res => {
    
    })
   
    

  }




  render() {
    return (
      <div className="BannerConfig">
         <div className="btn">
            {
              this.state.childBtn.map(item => {
                  if(item.show) {
                      return (
                          <Button  type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                          ) 
                  }
                  
              })
            }
           
           {/* <Button  type={this.state.status==0? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 0)}>PC端</Button>
           <Button className="setBtn" type={this.state.status==1? 'primary': ''}  onClick={this.changeStatus.bind(this, 1)}>移动端</Button> */}
          </div>
          
        
          <div style={{display: this.state.status==0? 'block': 'none'}}>
               <div className="warp1">
                   <h1>大屏首页</h1>

                   <div className="allData">
                    <div className="setData">
                        <h2>1 .轮播图:</h2>

                        {/* <div className="flex">

                                {
                                    <ul className="setFingerList flex">
                                        <Image.PreviewGroup>
                                            {
                                                this.state.img.map((item, index) => {
                                                    return (
                                                        <span style={{marginRight: 20, position: 'relative'}} key={index}>
                                                        <span className="removeImgList"  onClick={()=> this.reMoveImgList(index, 'fingerList')}>×</span>
                                                    <Image
                                                        width={140}
                                                        height={100}
                                                        src={this.imageUrl + item}
                                                    />
                                                    </span>
                                                    )
                                                })
                                            } 
                                        </Image.PreviewGroup>    
                                    </ul> 
                                }



                                <Uploadhh  listStatus={true}   data={{type: 48}}  width={140} height={100} handleClick={this.handleFinger.bind(this)} />
                            </div>    */}

                            <div className="flex">

                            {
                                <ul className="setFingerList flex">
                                    <Image.PreviewGroup>
                                        {
                                            this.state.indexCarouselImgList.map((item, index) => {
                                                return (
                                                    <span style={{marginRight: 20, position: 'relative'}} key={index}>
                                                    <span className="removeImgList"  onClick={()=> this.reMoveImgList(index, 'indexCarouselImgList', 'indexCarousel')}>×</span>
                                                <Image
                                                    width={140}
                                                    height={100}
                                                    src={this.imageUrl + item}
                                                />
                                                </span>
                                                )
                                            })
                                        } 
                                    </Image.PreviewGroup>


                                    <Uploadhh  listStatus={true}   data={{type: 66}}  width={140} height={100} handleClick={this.handleFinger.bind(this, 'indexCarouselImgList', 'indexCarousel')} />    
                                </ul> 
                            }



                            
                            </div>   

                    </div>


                    <div className="setData">
                        <h2>2 .全景图:</h2>

                         <div className='setINputWidth' >
                            <Input value={this.state.projectData.panoramaUrl} onChange={this.changeInput.bind(this, 'panoramaUrl')} placeholder="请张贴720云链接"  />
                         </div>       
                        
                    </div> 

                    <div className="setData">
                        <h2>3 .视频播放:</h2>

                         <div className='setINputWidth' >
                            <Input value={this.state.projectData.promotionalVideo} onChange={this.changeInput.bind(this, 'promotionalVideo')} placeholder="请张贴视频地址.mp4格式"  />
                         </div>       
                        
                    </div>             

                    
                   </div>
               
               </div> 


                {/* 劳务管理                 */}
               {/* <div className="warp1">
                   <h1>劳务管理板块</h1>

                   <div className="allData">
                    <div className="setData">
                        <h2>1 .轮播图: （建议尺寸: 1920x1080, 4:3）</h2>

                        <div className="flex">

                                {
                                    <ul className="setFingerList flex">
                                        <Image.PreviewGroup>
                                            {
                                                this.state.img.map((item, index) => {
                                                    return (
                                                        <span style={{marginRight: 20, position: 'relative'}} key={index}>
                                                        <span className="removeImgList"  onClick={()=> this.reMoveImgList(index, 'fingerList')}>×</span>
                                                    <Image
                                                        width={140}
                                                        height={100}
                                                        src={this.imageUrl + item}
                                                    />
                                                    </span>
                                                    )
                                                })
                                            } 
                                        </Image.PreviewGroup>

                                        <Uploadhh  listStatus={true}   data={{type: 48}}  width={140} height={100} handleClick={this.handleFinger.bind(this)} />    
                                    </ul> 
                                }



                                
                            </div>   
                    </div>
                   </div>
               
               </div>  */}

                {/* 设备管理模块                 */}
               {/* <div className="warp1">
                   <h1>设备管理模块</h1>

                   <div className="allData">
                    <div className="setData">
                        <h2>1 .轮播图: （建议尺寸: 1920x1080, 4:3）</h2>

                        <div className="flex">

                                {
                                    <ul className="setFingerList flex">
                                        <Image.PreviewGroup>
                                            {
                                                this.state.img.map((item, index) => {
                                                    return (
                                                        <span style={{marginRight: 20, position: 'relative'}} key={index}>
                                                        <span className="removeImgList"  onClick={()=> this.reMoveImgList(index, 'fingerList')}>×</span>
                                                    <Image
                                                        width={140}
                                                        height={100}
                                                        src={this.imageUrl + item}
                                                    />
                                                    </span>
                                                    )
                                                })
                                            } 
                                        </Image.PreviewGroup> 
                                        <Uploadhh  listStatus={true}   data={{type: 48}}  width={140} height={100} handleClick={this.handleFinger.bind(this)} />   
                                    </ul> 
                                }



                               
                            </div>   
                    </div>
                   </div>
               
               </div>  */}


               {/* 安全之星模块                 */}
               {/* <div className="warp1">
                   <h1>安全之星模块</h1>

                   <div className="allData">
                    <div className="setData">
                        <h2>1 .轮播图: （建议尺寸: 1920x1080, 4:3）</h2>

                        <div className="flex">

                                {
                                    <ul className="setFingerList flex">
                                        <Image.PreviewGroup>
                                            {
                                                this.state.img.map((item, index) => {
                                                    return (
                                                        <span style={{marginRight: 20, position: 'relative'}} key={index}>
                                                        <span className="removeImgList"  onClick={()=> this.reMoveImgList(index, 'fingerList')}>×</span>
                                                    <Image
                                                        width={140}
                                                        height={100}
                                                        src={this.imageUrl + item}
                                                    />
                                                    </span>
                                                    )
                                                })
                                            } 
                                        </Image.PreviewGroup> 
                                        <Uploadhh  listStatus={true}   data={{type: 48}}  width={140} height={100} handleClick={this.handleFinger.bind(this)} />   
                                    </ul> 
                                }



                                
                            </div>   
                    </div>
                   </div>
               
               </div> */}


               {/* <div className="flex">
                        <div className="warp1 ma1">
                            <h1>行为管理板块</h1>
                            <div className="allData"> 
                            <div className="setData">
                                <h2>视频监控播放</h2>

                                <div className='setINputWidth' >
                                    <Input placeholder="请张贴视频监控直播地址"  />
                                </div>       
                                
                            </div>
                            </div>     
                        
                        </div>
                        <div className="warp1 ma2">
                            <h1>车辆管理板块</h1>
                            <div className="allData">     
                            <div className="setData">
                                <h2>视频监控播放</h2>

                                <div className='setINputWidth' >
                                    <Input placeholder="请张贴视频监控直播地址"  />
                                </div>       
                                
                            </div>
                            </div>  
                        
                        </div>          

               </div> */}





          </div> 

      
          <div style={{display: this.state.status==1? 'block': 'none'}}>
                
            {
                  function() {
                    if(this.state.status==1) {
                        return (
                            <MobilePhone/>
                        )
                    }
                  }.bind(this)()
              }                  


          </div>

      </div>

    );
  }
}
