import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { message } from 'antd';
// import { createBrowserHistory } from 'history'; 

// const history = createBrowserHistory();
const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // url = base url + request url
  timeout: 20000, // request timeout
})

// request interceptor
request.interceptors.request.use(
  config => {
    const accessLogin = getStorage('accessLogin')
    if (accessLogin) {
      config.headers.accessLogin = accessLogin
    }
    config.headers.accesstoken = '$2y$10$z.0Fz2z0r9XKJ5M3.TowhOEzBGjQndjo2hijvAZQmdLjztiJkbUZu'
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
request.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 20000) {
      return Promise.resolve(res)
    } else if (res.code === 90000) {
      localStorage.removeItem('accessLogin');
      localStorage.removeItem('loginInfo');
      localStorage.removeItem('projectInfo');
      message.error(res.message);
      setTimeout(() => {
        // history.push('/login')
        window.location.href = '/login'
      }, 1500)
      return Promise.reject(res.message)
    } else {
      message.error(res.message)
      return Promise.reject(res);
    }
  },
  error => {
    console.log('err' + error) // for debug
    message.error(error.message)
    return Promise.reject(error)
  }
)

export default request