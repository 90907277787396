import React from "react";
import { Layout, Menu, PageHeader, Badge, Avatar, Popover, List,  Dropdown } from "antd";
import { Link, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import * as Icon from '@ant-design/icons';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
import "./layout.less";
//  import menuList from "@/router/menuConfig";
import routerList from "@/router/routerConfig";
import { setCollapse } from '@/redux/actions/setting';


import icon from '@/assets/img/newReviseImg/imgAndIcon/logo3.png';
import project from '@/assets/img/newReviseImg/imgAndIcon/project.png';
import man from '@/assets/img/newReviseImg/imgAndIcon/man.png';

import Header2 from "@/components/Header/Header";


const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;



// console.log(React.$getStorage('accessLogin'))
class PagesLayout extends React.Component {
  state = {
    activeName: 0,
    userName: "",
    toPath: window.location.origin + '/login',
    selectedMenuItem: '',
    newsList: [
      {
        title: 'Ant Design Title 1',
      },
      {
        title: 'Ant Design Title 2',
      },
      {
        title: 'Ant Design Title 3',
      },
      {
        title: 'Ant Design Title 4',
      },
    ],
    newsMenuSelected: 'newsKey1',
    projectVisible: false,
    projectVisible2: false,
    projectList: [],
    projectInfo: React.$getStorage('projectInfo'),
    openKeys: '',
    openKeysList: [],
    menuList: []
  };
  componentDidMount() {
    // console.log(React.$getStorage('accessLogin'))
    this.getProjectWhereUser();
    this.getInfoProjectMenu();

    
    this.setState({
      projectName: this.state.projectInfo.name
    })
  }

  getInfoProjectMenu() {
    React.$axios.post('infoProject').then(({ data }) => {
      this.setState({
        //menuList:menuList 
        menuList:JSON.parse(data.infoProject[0].pcMenu) 
      })
      this.getSelectedMenu();
    })
  } 


  // 获取未读信息
  getListEngineeringSecretUser() {
    let params = {
      lengthLimit: 10,
      logic: 2,
      meMsg: 2,
      offsetLimit: 0,
      type: [7, 8, 9]
    }

    React.$axios.post('listEngineeringSecretUser', params).then(res => {
      
    })
  }


  


  getProjectWhereUser() {
    React.$axios.post('projectWhereUser').then(res => {
      this.setState({
        projectList: res.data.projectWhereUser
      })

    })
  }

  toNavigate(path, item) {
    this.props.history.push(path)
    this.getSelectedMenu()
    this.changeItemPath(item)
  }


  changeItemPath(item) {
    console.log(item);
    React.$setStorage('menuchildBtn', item.childBtn);
  }


  renderMenu(menuList) {
    return menuList.map((item, index) => {
      if (item.children && item.show) {
        return (
          <SubMenu key={item.path} icon={<img src={item.icon}></img>} title={item.name}>
            {this.renderMenu(item.children)}
          </SubMenu>
        );
      }

      return item.show &&
        <Menu.Item key={item.path} title={item.name}>
          <Link onClick={this.changeItemPath.bind(this, item)} to={item.path}>{item.name}</Link>
        </Menu.Item>;
    });
  };

  getSelectedMenu() {
    const pathName = this.props.history.location.pathname;
    this.state.menuList.forEach(item => {
      if (item.children) {
        if (item.children.filter(item => item.path === pathName).length) {
          this.setState({
            selectedMenuItem: item
          })
        }
      }
    })

    // console.log(menuList)

  }
  newsMenuClick(e) {
    this.setState({
      newsMenuSelected: e.key
    })
  }

  handleVisibleChange(flag) {
    this.setState({
      projectVisible: flag
    })
  }

  handleVisibleChange2(flag) {
    this.setState({
      projectVisible2: flag
    })
  }


  // 点击切换项目
  handleMenuClick(e) {
    let name =  this.state.projectList.filter(item => item.projectId == e.key)

    this.setState({
      projectName: name[0].nameProject,
      projectVisible: false
    })

    React.$axios.post('projectSwitchUser', {projectId: name[0].projectId}).then(res => {

      React.$setStorage('accessLogin', res.data.projectSwitchUser.accessLogin)
      React.$setStorage('loginInfo', res.data.projectSwitchUser)


      React.$axios.post('infoProject').then(({ data }) => {
        React.$setStorage('projectInfo', data.infoProject[0])
        window.location.reload();
      })

    })


  }




  setMenu2() {
    return (
      <Menu onClick={this.handleMenuClick.bind(this)}>
         {
           this.state.projectList.map(item => {
             return (
              <Menu.Item  key={item.projectId}>{item.nameProject}</Menu.Item>
             )
           })
         }
      </Menu>
    )
  }

  handleMenuClick2() {
    
  }

  setMenu3() {
    return (
      <Menu onClick={this.handleMenuClick2.bind(this)}>
          <Menu.Item><a href = {this.state.toPath}>退出登录 </a> </Menu.Item>
      </Menu>
    )
  }

  // 
  onOpenChange(keys) {
    
    let lastKey = null

    // this.state.openKeysList.map(item => {
    //   if(item == keys) {
        
    //   }
    // })

    const latestOpenKey = keys.find(key => this.state.openKeys.indexOf(key) === -1);
    let status = keys.indexOf(latestOpenKey)


    if(status == -1) {
      this.setState({
        openKeys: [],
      })
    } else {
      this.setState({
        openKeys: [latestOpenKey],
      })
    }



  }


  render() {
    const pathName = this.props.history.location.pathname
    const { selectedMenuItem, newsList, newsMenuSelected } = this.state
    const { collapse } = this.props;
    return (
      <Header2 index={1}>
      <Layout className="layout">
          
        <Sider
          className="layout-sider"
          trigger={null}
          collapsible
          collapsed={collapse.isCollapsed}
        >
        
          <div className="menuWrap">
            <Menu theme="light" mode="inline"  openKeys={this.state.openKeys}  onOpenChange={this.onOpenChange.bind(this)} onSelect={this.getSelectedMenu.bind(this)} defaultOpenKeys={[selectedMenuItem.path]} selectedKeys={[pathName]} >
              {this.renderMenu(this.state.menuList)}
            </Menu>
          </div>
        </Sider>
        <Layout>
          
          {this.state.selectedMenuItem &&
            <div className={'sitePageHeader ' + this.state.selectedMenuItem.background}>
              {/* <PageHeader className={'sitePageHeader ' + this.state.selectedMenuItem.background} title={this.state.selectedMenuItem.name}> */}
                <div className="title">
                  <h2>{this.state.selectedMenuItem.name}</h2>
                  <p>{this.state.selectedMenuItem.English}</p>
                </div>
                <ul>
                  {
                    this.state.selectedMenuItem.children.map((item, index) => {
                      return <li className={item.path === pathName ? 'active' : ''} style={{display: item.show?'block': 'none'}}  key={index} onClick={this.toNavigate.bind(this, item.path, item)}>{item.name}</li>
                    })
                  }
                </ul>
            {/* </PageHeader> */}
            </div>
            }
          {/* <TransitionGroup>
            <CSSTransition classNames="fade" key={pathName} timeout={1000}> */}
              <Content className="layoutContent">
                <Switch>
                  {
                    routerList.map((item, index) => {
                      return (<Route exact component={item.component} key={index} path={item.path} />)
                    })
                  }
                  <Redirect from="*" to="/error" />
                </Switch>
              </Content>
            {/* </CSSTransition>
          </TransitionGroup> */}
        </Layout>
      </Layout >
      </Header2>
    );
  }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  setCollapse: data => {
    dispatch(setCollapse(data));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PagesLayout));