import React from "react";


import { DatePicker,Input,Select,Button, Table,  Image, message, Tag} from "antd";


import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import './FloodPrevention.less';

import moment from 'moment';

import {formatTime, getCurrentDayLastDay} from '@/utils/util'

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

const {Option} =  Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};




let imgUrl = process.env.REACT_APP_IMG_URL

let InitialValues = getCurrentDayLastDay(moment)

export default class FloodPrevention extends React.Component {
    columns = [
        {
            title: '序号',
            dataIndex: 'monitorTime',
            align: 'center',
            render: (_, item, index) => {
                return (
                    <p>{index + 1}</p>
                )
            } 
        },
        {
            title: '监测日期',
            dataIndex: 'monitorTime',
            align: 'center',
            render: (_) => {
                return (
                    <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')}</p>
                )
            }
        },
        {
            title: '当前雨量(mm)',
          dataIndex: 'current',
          align: 'center'
        },
        {
            title: '昨日雨量(mm)',
            dataIndex: 'yesterday',
            align: 'center' 
        },
        {
            title: '瞬时雨量(mm)',
            dataIndex: 'instantaneous',
            align: 'center'
        },

        {
            title: '累积雨量(mm)',
            dataIndex: 'accumulation',
            align: 'center'
        },
        {
            title: '风力(级)',
            dataIndex: 'windForce',
            align: 'center'
        },
        {
            title: '风速(m/s)',
            dataIndex: 'windSpeed',
            align: 'center'
        },
        {
            title: '风向(方位)',
            dataIndex: 'windDirection',
            align: 'center'
        },
        {
            title: '温度(℃)',
            dataIndex: 'temperature',
            align: 'center'
        },
        {
            title: '湿度(%RH)',
            dataIndex: 'humidity',
            align: 'center'
        },
        {
            title: '气压(kPa)',
            dataIndex: 'airPressure',
            align: 'center'
        },
      ]
    
    jsonFieldsList =  {
        '0_监测时间': 'monitorTime',
        "2_当前雨量(mm)": 'current',
        "3_昨日雨量(mm)": 'yesterday',
        "4_瞬时雨量(mm)": "instantaneous",
        "5_累积雨量(mm)": "accumulation",
        "6_风力(级)": "windForce",
        "7_风速(m/s)": "windSpeed",
        "8_风向(方位)": "windDirection",
        "9_温度(℃)": "temperature",
        "10_湿度(%RH)": "humidity",
        "11_气压(kPa)": "airPressure"
      }


  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    deviceList: [],
    TableParams: {
        idCaName: "",
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 2,
        devAddr: null,
        monitorTimeStart: parseInt(new Date(InitialValues[0]._i + ' 00:00:00').getTime() / 1000),
        monitorTimeStop: parseInt(new Date(InitialValues[1]._i + ' 23:59:59').getTime() / 1000),
    },
    ExcelName: '导出',
    
  }




  componentDidMount() {
    let projectId =   React.$getStorage('projectInfo').id
    this.state.TableParams.projectId = projectId
    
    // this.getTableData()
    this.getDeviceList()

    this.setState({
        ExcelName: formatTime(new Date(), 'yyyyMMdd防汛监测')   
    })

  }


  // 获取设备list
  getDeviceList() {
    React.$axios.post('monitoringDeviceAll2', {type: 3, projectId: this.state.TableParams.projectId}).then(res => {
        
        if(res.data.monitoringDeviceAll[0]) {
            this.state.TableParams.devAddr = res.data.monitoringDeviceAll[0].devAddr
        }

        
        this.setState({
            deviceList: res.data.monitoringDeviceAll,
            TableParams: this.state.TableParams
        })

        this.getTableData()
    })
  }


  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       }) 

      React.$axios.post('rainfallList', this.state.TableParams).then(res => {
        this.state.pagination.total = res.data.countList
        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    this.state.TableParams.idCaName = value
  }

  onChangeWork(name, value) {
    this.state.TableParams.devAddr = value

    this.setState({
        TableParams: this.state.TableParams
    })

  }

  toSerch2() {
    this.getTableData(true)
  }

  handleTableChange(value) {
    this.state.pagination = value

    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }

  getChangeTime(time ,val) {
    
    this.state.TableParams.monitorTimeStart = val? parseInt(new Date(val[0] + ' 00:00:00').getTime() / 1000): '' 
    this.state.TableParams.monitorTimeStop = val? parseInt(new Date(val[1] + ' 23:59:59').getTime() / 1000): ''
    
    console.log(this.state.TableParams)
    this.setState({
        TableParams: this.state.TableParams
    })
  }


  // 导出返回数据
  fetchFun() {

   
    let params = JSON.parse(JSON.stringify(this.state.TableParams)) 

    params.export = 2

    if(!params.monitorTimeStart) {
        message.error('请选择时间')
        return
    }

    return new Promise((resolve) => {
        React.$axios.post('rainfallList', params).then(res => {
            res.data.list.map(item => {
                item.monitorTime  = formatTime(new Date(item.monitorTime * 1000), 'yyyy-MM-dd hh:ss:mm')
            })
            resolve(res.data.list)
        })


    })
  }

  render() {
    return (
      <div className="FloodPrevention">
          <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>监测时间:</span> 
                        
                        <RangePicker defaultValue={InitialValues}  onChange={this.getChangeTime.bind(this)} style={{  height:30, marginRight:20,  }} />

                        </li>
                        <li><span>设备:</span>  
                        <Select
                                    showSearch
                                    style={{ width: 200,  marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    value = {this.state.TableParams.devAddr}
                                    onChange={this.onChangeWork.bind(this, 'devAddr')}
                                >
 
                                 {
                                     this.state.deviceList.map(item => {
                                         return (
                                            <Option value={item.devAddr} key={item.id}>{item.name}</Option>
                                         )
                                     })
                                 }           

                                </Select>
                            </li>
                            <Button  className="btn" onClick={this.toSerch2.bind(this)} style={{marginRight: 20}}>
                                <SearchOutlined  className="icon"/>
                            </Button>


                            <DownloadExcel    name={this.state.ExcelName} fields={this.jsonFieldsList}  fetch={this.fetchFun.bind(this)} btnName="导出" />
                    </ul>
                </div>



                <div className="table2">
                <div class="allBtn">
                   
  
                    {/* <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                        导入
                    </Button>
                    <Button  className="bt" type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button> */}
                    {/* <Button  className="bt" type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button> */}
                    </div>


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }} columns={this.columns} dataSource={this.state.tableData}  />
                </div>

      </div>
    );
  }
}
