import React from "react";
import './overviewImg.less';

import {DatePicker} from "antd";
import * as echarts from 'echarts';
import {formatTime} from '@/utils/util';

const imgurl = process.env.REACT_APP_IMG_URL;


let currentTime = null;
export const OverviewImg = ( 
class OverviewImg2 extends React.Component {
    projectId = React.$getStorage('projectInfo').id
  state = {
      status: 1,
      allParams: {

      },
      thisMonth: {
        count: 0,
        rectified: 0,
        remaining: 0
      },

      thisMonthParams: false, // true 为本月 false 为全部
      listStatus1: true,
      count: {
        problemLevel: {}
      },


      active: 0,

      UserParams: {
        addUser: {},
        rectifyUser: {},
      },


      currentTime: null

  }

  componentDidMount() {
    this.getListInspectionCountMonth(this.state.thisMonthParams);
  }
 

 // 获取月度记录列表
  getListInspectionCountMonth(thisMonthParams, time) {
    let params = {
        projectId: this.projectId,
        logic: 4,
        time: thisMonthParams? parseInt(new Date(time).getTime() / 1000) : 0,
    }

    React.$axios.post('listInspectionCountMonth', params).then(res => {
        // 获取总和
        let allCont = res.data.count;
        allCont.allProcessCount = allCont.overdueNotRectified + allCont.timelyRectified + allCont.overdue +  allCont.treatRectifiedCount + allCont.inProcess;
        allCont.AllProblemLevelCount = allCont.problemLevel.generally + allCont.problemLevel.larger +  allCont.problemLevel.major;
        allCont.AllNumber = allCont.timelyRectified + allCont.treatRectifiedCount + allCont.overdueNotRectified + allCont.overdue;

        // console.log(allCont)


        this.setState({
            count: res.data.count,
            UserParams: {
                addUser:  res.data.ranking.addUser[0]?res.data.ranking.addUser[0]:{},
                rectifyUser: res.data.ranking.rectifyUser[0]?res.data.ranking.rectifyUser[0]: {}
            }
        });

        // 隐患排查排查人员概览
        this.getEcharts3('main3', res.data.ranking.addUser)
        this.getEcharts3('main5', res.data.ranking.rectifyUser, '#FFAB34')
        // 整改分析
        this.getEcharts2(res.data.count)
        // 隐患排查发布及整改趋势
        this.getEcharts4(res.data.trend)
    })

  }


  getEcharts2(params) {
    var myChart = echarts.init(document.getElementById('main2'));
    if(!params) {
        params = {
            timelyRectified: 0, // 
            overdue: 0,
            count: 0,
            overdueNotRectified: 0,
            treatRectifiedCount: 0,
        }  
    }

    let option = {
        tooltip: {
            trigger: 'item'
        },
        color: ['#FF3737','#FFAB34', '#47DCDE', '#4772FF'],
        series: [
            {
                name: '访问来源',
                type: 'pie',
                radius: ['40%', '70%'],
                //x:50,//靠左
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },

                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    {value: params.overdueNotRectified, name: '逾期未整改'},
                    {value: params.treatRectifiedCount, name: '未整改'},
                    {value: params.overdue, name: '逾期完成'},
                    {value: params.timelyRectified, name: '及时整改'},
                ]
            }
        ]
    };
    
    
    myChart.setOption(option);
  }

  getEcharts3(id, arr2, color="#004EA1") {
        let name = []
        let data = []
        arr2.map(item => {
            name.push(item.nameIdCard);
            data.push(item.countInspection);
        })

        var myChart = echarts.init(document.getElementById(id));
        let option = {
            color: [color],
            grid: {
                left: '1%',
                right: '1%',
                bottom: '15%',
                containLabel: true
              },
            xAxis: {
                type: 'category',
                data: name,
                axisLine: {
                    lineStyle: {
                      type: 'dashed',
                       color: '#7F7F7F',
                      width: '1'
                    }
                  }
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle:{
                        type:'dashed'
                    }
                },
            },

            series: [{
                barWidth: 20,
                barMaxWidth:40,
                data: data,
                type: 'bar',
                label: {
                    formatter: "{c}",
                    show: true,
                    position: 'top',
                    color: color
               }
            }]
        };

    myChart.setOption(option);
  }


  getEcharts4(data) {
    var myChart = echarts.init(document.getElementById('main4'));
    let arr = [] // 整改
    let arr2 = [] // 发布
    let name = []
    
    for(let i in data.rectified) {
        arr.push(data.rectified[i])
        arr2.push(data.release[i])
        name.push(i)
    }
    let option = {
            color: ['#567BFB'],
            tooltip: {
                trigger: 'item'
            },
            legend: {
                data: ['整改', '发布'],
                right: '20',
                top: '20',
                icon: 'rect'
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },

            xAxis: {
                type: 'category',
                data: name,
                axisLine: {
                    lineStyle: {
                      type: 'dashed',
                       color: '#7F7F7F',
                      width: '1'
                    }
                  }
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle:{
                        type:'dashed'
                    }
                },
            },


            // xAxis: [
            //   {
            //     type: 'category',
            //     boundaryGap: false,
            //     data: name
            //   }
            // ],
            // yAxis: [
            //   {
            //     type: 'value'
            //   }
            // ],
            series: [
              {
                name: '整改',
                type: 'line',
                itemStyle : {
                    normal : {
                        color:'#004EA2',
                            lineStyle:{
                                color:'#004EA2'
                            }
                        },
                },
                emphasis: {
                  focus: 'series'
                },
                data: arr,
              },
              {
                name: '发布',
                type: 'line',
                itemStyle : {
                    normal : {
                        color:'#C71D4C',
                            lineStyle:{
                                color:'#C71D4C'
                            }
                        },
                },
                emphasis: {
                  focus: 'series'
                },
                data: arr2,
              }
            ]
    };
    
    
    myChart.setOption(option);
  }

  changeType2(type) {

    
      this.setState({
        listStatus1: type
      })

      
      

    if(this.state.allParams.troubleshootingOverview) {
        if(type) {
            this.setState({
                thisMonth : this.state.allParams.troubleshootingOverview.grandTotal
            })  
        } else {
            this.setState({
                thisMonth : this.state.allParams.troubleshootingOverview.thisMonth
            }) 
        }

        this.getEcharts() 
        
    }
  }

  changeActive(index) {
    this.setState({
        thisMonthParams: index
    })

    if(!index) {
        this.getListInspectionCountMonth(index);
    } 
  }

  onChange(val) {
    this.setState({
        currentTime: val._d
    })

    currentTime = val._d;
  }
  onOpenChange(val) {
    if(!val) {
      this.getListInspectionCountMonth(true, currentTime);
    }
}

  render() {
    return (
      <div className= "wages overviewImg">

          <div className="topAllBtn"> 
            <p className={this.state.thisMonthParams?"active":''}   onClick={this.changeActive.bind(this, true)}>{this.state.currentTime?formatTime(this.state.currentTime, 'yyyy年MM月'): '请选择'}
             <DatePicker picker="month" className="setOpctiy" onOpenChange={this.onOpenChange.bind(this)} onChange={this.onChange.bind(this)}></DatePicker>
            </p>
            

            <p className={this.state.thisMonthParams?"":'active'} onClick={this.changeActive.bind(this, false)}>全部</p>
          </div>  

          <div className="head">
            <ul>
                <li>
                    <h1>排查统计</h1>


                     <div className="number">
                        <div>
                            <h2>{this.state.count.safety}</h2>
                            <p>安全排查（项）</p> 
                        </div>

                        <div>
                            <h2 className="blue">{this.state.count.quality}</h2>
                            <p>质量排查（项）</p> 
                        </div>
                            
                     </div>

                </li>
                <li>
                    <h1>整改分析</h1>
                    <div id="main2" className="echarts">

                    </div>

                    <ul className="setUlEchart">
                        <li>
                            <p>逾期未整改</p>
                            <p>{ this.state.count.overdueNotRectified}/{parseFloat(this.state.count.overdueNotRectified / this.state.count.AllNumber * 100 ).toFixed(2)}%</p>
                        </li>
                        <li>
                            <p>未整改</p>
                            <p>{this.state.count.treatRectifiedCount}/{parseFloat(this.state.count.treatRectifiedCount / this.state.count.AllNumber * 100).toFixed(2)}%</p>
                        </li>
                        <li>
                            <p>逾期完成</p>
                            <p>{this.state.count.overdue}/{parseFloat(this.state.count.overdue / this.state.count.AllNumber * 100).toFixed(2)}%</p>
                        </li>
                        <li>
                            <p>及时整改</p>
                            <p>{this.state.count.timelyRectified}/{parseFloat(this.state.count.timelyRectified / this.state.count.AllNumber  * 100).toFixed(2)}%</p>
                        </li>
                    </ul>

                </li>
                <li>
                    <h1>隐患排查状态分布</h1>

                    <ul className="process">
                        <li><span>已完成</span>  <div className="p1"><p style={{width:this.state.count.allProcessCount == 0?'0': (this.state.count.timelyRectified + this.state.count.overdue) / this.state.count.allProcessCount *100 + '%'}}></p><i>{this.state.count.timelyRectified + this.state.count.overdue}</i></div></li>
                        <li><span>流程中</span>  <div className="p1"><p style={{width:this.state.count.allProcessCount == 0?'0':   this.state.count.inProcess / this.state.count.allProcessCount *100 + '%', backgroundColor: '#004EA2'}}></p><i style={{color: '#004EA2'}}>{this.state.count.inProcess}</i></div>  </li>
                        <li> <span>待整改</span> <div className="p1"><p style={{width:this.state.count.allProcessCount == 0?'0':  (this.state.count.treatRectifiedCount + this.state.count.overdueNotRectified)  / this.state.count.allProcessCount *100 + '%', backgroundColor: '#FFAB34'}}></p><i style={{color: '#FFAB34'}}>{this.state.count.treatRectifiedCount}</i></div></li>
                    </ul>    
                    
        
                </li>
                <li>
                    <h1>隐患排查严重性分布</h1>
                    <ul className="process">
                        <li><span>严重</span> <div className="p1"><p style={{width: this.state.count.AllProblemLevelCount == 0?'0': this.state.count.problemLevel.major / this.state.count.AllProblemLevelCount *100 + '%', backgroundColor: '#C71D4D'}}></p><i style={{color: '#C71D4D'}}>{this.state.count.problemLevel.major}</i></div></li>
                        <li><span>较大</span> <div className="p1"><p style={{width: this.state.count.AllProblemLevelCount == 0?'0': this.state.count.problemLevel.larger / this.state.count.AllProblemLevelCount *100 + '%', backgroundColor: '#FFC000'}}></p><i style={{color: '#FFC000'}}>{this.state.count.problemLevel.larger}</i></div></li>
                        <li><span>一般</span> <div className="p1"><p style={{width: this.state.count.AllProblemLevelCount == 0?'0': this.state.count.problemLevel.generally / this.state.count.AllProblemLevelCount *100 + '%', backgroundColor: '#BFBFBF'}}></p><i style={{color: '#BFBFBF'}}>{this.state.count.problemLevel.generally}</i></div></li>
                    </ul>    
                </li>
            </ul>
          </div>


          <div className="body1">
              <div>
                <h1>隐患排查发布及整改趋势</h1>
                <div id="main4" className="echarts4">

                </div>
              </div>
              <div>
                <h1>隐患排查排查人员概览</h1>

                <div class="echart3" id="main3">
                    
                </div>  
              </div>
          </div>

          <div className="body1">
              <div className="noPadding">
                <ul className="person">
                    <li>
                        <img src={imgurl + this.state.UserParams.addUser.avatarKeyCutout} alt="" />
                        
                        {/* this.setState({
                                count: res.data.count,
                                UserParams: {
                                    addUser:  res.data.ranking.addUser[0],
                                    rectifyUser: res.data.ranking.rectifyUser[0]
                                }
                            }); */}


                        <div className="briefIntroduction">
                             <h2 className="fontColor">实力排查明星</h2>

                             <h3 className="fontColor">{this.state.UserParams.addUser.nameIdCard}</h3>
                             <p>{this.state.UserParams.addUser.nameEnterprise}</p>
                             <p>{this.state.UserParams.addUser.nameTeam}</p>

                             <h3 className="fontColor">共排查问题</h3>
                             <div><span>{this.state.UserParams.addUser.countInspection}</span>项</div>   
                        </div>
                    </li>
                    <li>
                    <img src={imgurl + this.state.UserParams.rectifyUser.avatarKeyCutout} alt="" />
                        

                        <div className="briefIntroduction">
                             <h2 className="fontColor">需要关注的对象</h2>

                             <h3 className="fontColor">{this.state.UserParams.rectifyUser.nameIdCard}</h3>
                             <p>{this.state.UserParams.rectifyUser.nameEnterprise}</p>
                             <p>{this.state.UserParams.rectifyUser.nameTeam}</p>   

                             <h3 className="fontColor">被排查问题</h3>
                             <div><span>{this.state.UserParams.rectifyUser.countInspection}</span>项</div>   
                        </div>
                    </li>
                </ul>


              </div>
              <div>
                <h1>隐患排查责任人员概览</h1>
                <div class="echart3" id="main5">
                    
                </div>  
              </div>
          </div>
      </div>
    );
  }
}
)
