import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Form, Button, DatePicker, Table, Input, Select, Tabs  } from 'antd';
import './ProductionTransportation.less'

import { SearchOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatTime } from '@/utils/util.js'

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

import image1 from '@/assets/img/coordination/11.png'

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

let jsonFieldsList =  {
    '0_小票号': 'FNo',
    '1_生产日期':{
        field: 'FScrq',
            callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
            }
        },
    "2_时间": {
        field: 'FVersion',
            callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
            }
        },
    "3_拌台": 'FScbt',
    "4_砼品种":"FTpz",

    "5_车数": 'FLjcs',
    "6_累计": 'FLjfs',
    "7_车号": "FShch",
    "7_司机": "FSjxm",
    "8_余方量": "FRvA",
    "9_拌方量" : "FRvB",
    "10_退方量" : "FQvA",
    "11_总重量": "FZzl",
    "12_操作员": "FCzy"
  }



function ProductionTransportation() {
    const columns = [
        {
        title: '小票号',
        align: 'center',
        dataIndex: 'FNo',
        },
        {
            title: '生产日期',
            dataIndex: 'FScrq',
            align: 'center',
            render: (_) => {
                let time;

                if(_) {
                    time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                }
                return (
                    <p>{time}</p>
                )
            }
            
        },
        {
            title: '时间',
            dataIndex: 'FVersion',
            align: 'center',
            render: (_) => {
                let time;
                if(_) {
                    time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                }
                return (
                    <p>{time}</p>
                )
            }
        },
        {
            title: '拌台',
            dataIndex: 'FScbt',
            align: 'center',
        },
        {
            title: '任务单号',
            dataIndex: 'FRwdh',
            align: 'center',
        },
        {
            title: '工程名称',
            dataIndex: 'FGcmc',
            align: 'center',
        },
        {
            title: '砼品种',
            dataIndex: 'FTpz',
            align: 'center',
        },
        {
            title: '车数',
            dataIndex: 'FLjcs',
            align: 'center',
        },
        {
            title: '累计',
            dataIndex: 'FLjfs',
            align: 'center',
        },
        {
            title: '车号',
            dataIndex: 'FShch',
            align: 'center',
        },
        {
            title: '司机',
            dataIndex: 'FSjxm',
            align: 'center',
        },
        {
            title: '余方量',
            dataIndex: 'FRvA',
            align: 'center',
        },
        {
            title: '拌方量',
            dataIndex: 'FRvB',
            align: 'center',
        },
        {
            title: '退方量',
            dataIndex: 'FQvA',
            align: 'center',
        },
        {
            title: '总重量',
            dataIndex: 'FZzl',
            align: 'center',
        },
        {
            title: '操作员',
            dataIndex: 'FCzy',
            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'value',
            align: 'center',
            render: (_, item) => {
                return (
                    // <Button onClick={() => {onExpandedRowsChange(item)} }>操作</Button>
                    <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
                )
            }
        },
    ];
    
    const sliceColumns =  [
        {
            title: '小票号',
            align: 'center',
            dataIndex: 'FNo',
            },
            {
                title: '生产日期',
                dataIndex: 'FScrq',
                align: 'center',
                render: (_) => {
                    let time;
    
                    if(_) {
                        time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                    }
                    return (
                        <p>{time}</p>
                    )
                }
                
            },
            {
                title: '时间',
                dataIndex: 'FVersion',
                align: 'center',
                render: (_) => {
                    let time;
                    if(_) {
                        time = formatTime(new Date(_ * 1000), 'yyyy-MM-dd')
                    }
                    return (
                        <p>{time}</p>
                    )
                }
            },
            {
                title: '拌台',
                dataIndex: 'FScbt',
                align: 'center',
            },
            {
                title: '任务单号',
                dataIndex: 'FRwno',
                align: 'center',
            },
            {
                title: '单位名称',
                dataIndex: 'FHtdw',
                align: 'center',
            },
            {
                title: '工程名称',
                dataIndex: 'FGcmc',
                align: 'center',
            },
            {
                title: '砼品种',
                dataIndex: 'FTpz',
                align: 'center',
            },
            {
                title: '操作',
                dataIndex: 'value',
                align: 'center',
                render: (_, item) => {
                    return (
                        // <Button onClick={() => {onExpandedRowsChange(item)} }>操作</Button>
                        <img className="FBsfsImg" src={image1} alt="" onClick={() => {onExpandedRowsChange(item)}} />
                    )
                }
            },
    ]  


    const projectId = React.$getStorage('projectInfo').id

    let loadExcelRef = null
  
    const [ExcelName, setExcelName] = useState('考试');
    const [columns2, setColumn] =  useState(columns);
    const [deviceList, setDeviceList] = useState([]);
    const [tableList, setTableList] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 10,
      total: 0
    })
    const [allParams, setAllParams] = useState({
      logic: 2,
      keyword: null,
      sn: null,
      FJzbw: null,
      FTpz: null,
      FZt: null,
      FJhrqStart: null,
      FJhrqEnd: null,
      offsetLimit: 0,
      lengthLimit: 10,
      projectId: projectId,
      FNo: null,
    })

    const [selectVal, setSelectVal] = useState('')

    const [detailMsg, setDetailMsg] = useState({
      FTbj: [],
      TjlbArr: [],
      TphbyclArr: []
    })
    
  
    const [widthStatus, setWidthStatus] = useState(false)
      
  
    useEffect(() => {
  
      getDeviceListMixingStation();
    }, []);
  
  
    // 获取设备列表
    function getDeviceListMixingStation() {
      React.$axios.post('deviceListMixingStation', {logic: 3, projectId: projectId}).then(res => {

          if(res.data.list.length == 0) {
              return
          }  

          setDeviceList(res.data.list)
          allParams.sn = res.data.list[0].sn
          setAllParams(allParams)
          setSelectVal(res.data.list[0].sn)
  
          getMonitoringDeviceAll(pagination);
          // getDeviceListMixingStation(allParams)
      })
    }
  
    
    function getMonitoringDeviceAll(pagination, type=false) {
      // 如果需要更新 从第一页开始
      if(type) {
          pagination.current = 1
      }
  
  
      allParams.offsetLimit = (pagination.current - 1) *  pagination.pageSize
      allParams.lengthLimit = pagination.pageSize
  
  
      React.$axios.post('tjlbListMixingStation', allParams).then(res => {
  
          pagination.total = res.data.countList
          setTableList(res.data.list)
  
          setPagination(pagination)
      })
  
    }
  
    function onPaginationChange(val) {
  
      setPagination(val)
  
      console.log(val)
  
      getMonitoringDeviceAll(val)
    }
  
  
    function toSee(status, item, recove) {
  
          if(status) {
              setColumn(sliceColumns)
          } else {
              setColumn(columns)
          }
  
       setWidthStatus(status)
    }
    
    function callback() {
  
    }
  
    function onExpandedRowsChange(item) {
      toSee(true)
      console.log(item)
      React.$axios.post('tjlbListMixingStation', {logic: 4, id: item.id, projectId: projectId, FScrq2: formatTime(new Date(item.FVersion * 1000), 'yyyy-MM-dd')   }).then(res => {
        if(!res.data.Tjlbycl) return

        res.data.Tjlb.TphbyclArr = res.data.Tjlbycl
        setDetailMsg(res.data.Tjlb)
      })
  
    }
  
    function handleChange(val) { // 设备选择
      allParams['sn'] = val
      
      setSelectVal(val)
      setAllParams(allParams)
    }
  
    // input 输入框
    function paramsInput({target:{value}}, text) {

      console.log(value)
      console.log(text)  

      allParams[text] = value
      setAllParams(allParams)
    }
  
    // 时间选择
    function changeTime(val) {
      allParams.FJhrqStart = parseInt((+val[0]._d) / 1000) 
      allParams.FJhrqEnd = parseInt((+val[1]._d) / 1000)  
      setAllParams(allParams)
    }
  
    // 点击搜索
    function toSerch() {
  
      getMonitoringDeviceAll(pagination, true);
  
    }
  
    // 导出方法
    function fetchFun() {

      setExcelName(formatTime(new Date(), '砼生产情况和运输记录yyyy-MM-dd hh:mm:ss'))
  
      return new Promise((resolve) => {
          let newParams = Object.assign({}, allParams);
          newParams.logic = 3
          React.$axios.post('tjlbListMixingStation', newParams).then(res => {
              resolve(res.data.list)
          })
      })
    }





  return (
    <div className="ProductionTransportation">

        <div className="flex operationTask">
            <div className="serchList">
                <p>
                <i>小票号</i>  <Input placeholder="请输入" style={{width: 100}} onInput={(val)=> {paramsInput(val, 'FNo')}}  />
                </p>
                <p>
                <i>设备</i>
                <Select style={{ width: 200 }} value={selectVal} onChange={handleChange} placeholder="请选择">
                    
                    {
                        deviceList.map((item, index) => {
                            return (
                                <Option value={item.sn} key={index}>{item.name}</Option>
                            )
                        })
                    }
                </Select>

                

                </p>
                <p>
                <i>砼品种</i>      
                <Input placeholder="请输入" style={{width: 200}} onInput={(val)=> {paramsInput(val, 'FTpz')}}  />
                </p>
                <p>
                <i>日期</i>
                {/* <Input placeholder="请输入" style={{width: 200}} /> */}
                <RangePicker onChange={changeTime} />
                </p>
                <p>
                <i>任务单号</i>
                <Input placeholder="请输入" style={{width: 100}} onInput={(val)=> {paramsInput(val, 'FRwdh')}}  />
                </p>
            
            </div>

            <div className="serchBtn">
                <Button className="setMargin styleBtnYellow" type="primary" onClick={toSerch}>搜索</Button>
                
                <DownloadExcel ref={(el) => loadExcelRef = el}  name={ExcelName} fields={jsonFieldsList}  fetch={fetchFun} btnName="导出">
                    <Button  type="primary styleBtnGreen">导出</Button>
                </DownloadExcel>   

            </div>


        </div>
        

        <div className="flex tableWarp">
            <div className="setWidth">
                <Table
                    bordered
                    loading={loading}
                    columns={columns2}
                    dataSource={tableList}
                    pagination={pagination}
                    onChange={(page) => onPaginationChange(page)}
                />

            </div>



            

            <div className={`detail  ${widthStatus? 'addWidth': ''}`}>
                
                <div className={`reactive ${widthStatus? 'ShowBlock': ''}`}    onClick={() => toSee(false)}>
                    ×
                </div>
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="详细信息" key="1">

                    <div className="setMaxHeight">
                        <ul className="setdetailedInfo">
                            <li>
                                <span>小票编号</span>
                                <div className="halfWidth">{detailMsg.FNo}</div>
                                <span>状&#12288;&#12288;态</span>
                                <div className="halfWidth">{detailMsg.FZt == 2? '自动':'手动'}</div>
                            </li>
                            <li>
                                <span>生产日期</span>
                                <div className="halfWidth">{formatTime(new Date(detailMsg.FScrq * 1000), 'yyyy-MM-dd')}</div>
                                <span>生产拌台</span>
                                <div className="halfWidth">{detailMsg.FScbt}</div>
                            </li>

                            


                            <li>
                                <span>任务单号</span>
                                <div className="halfWidth">{detailMsg.FRwno}</div>
                                <span>合同编号</span>
                                <div className="halfWidth">{detailMsg.FHtbh}</div>
                            </li>
                            <li>
                                <span>单位名称</span>
                                <div className="halfWidth">{detailMsg.FHtdw}</div>
                                <span>公&#160;里&#160;数</span>
                                <div className="halfWidth">{detailMsg.FGls}</div>
                            </li>
                            <li>
                                <span>工程地址</span>
                                <div className="halfWidth">{detailMsg.FGcdz}</div>
                                <span>施工方法</span>
                                <div className="halfWidth">{detailMsg.FJzfs}</div>
                            </li>
                            <li>
                                <span>施工部位</span>
                                <div className="allWidth">{detailMsg.FJzbw}</div>
                            </li>

                            <li>
                                <span>砼&#160;品&#160;种</span>
                                <div style={{width: 50}} >{detailMsg.FTpz}</div>
                                <span>坍&#160;落&#160;度</span>
                                <div style={{width: 50}}>{detailMsg.FTld}</div>
                                <span>砂浆配比</span>
                                <div style={{width: 90}}>{detailMsg.FPhbSJ}</div>
                            </li>
                            
                            <li>
                                <span>施工配比</span>
                                <div style={{width: 40}} >{detailMsg.FSgpb}</div>
                                <div style={{width: 320, marginLeft: 10}}>{detailMsg.FPhbNo}</div>
                            </li>

                            <li>
                                <span>车&#12288;&#12288;号</span>
                                <div className="halfWidth">{detailMsg.FShch}</div>
                                <span>司&#12288;&#12288;机</span>
                                <div className="halfWidth">{detailMsg.FSjxm}</div>
                            </li>

                            <li>
                                <span>到厂时间</span>
                                <div className="halfWidth">{formatTime(new Date(detailMsg.FDcsj * 1000), 'hh:mm:ss')}</div>
                                <span>生产时间</span>
                                <div className="halfWidth">{formatTime(new Date(detailMsg.FCcsj * 1000), 'hh:mm:ss')}</div>
                            </li>

                            <li>
                                <span>本车方数</span>
                                <div className="halfWidth">{detailMsg.FBcfs}</div>
                                
                                <span style={{width: 30, marginLeft: 7}}>余</span>
                                <div style={{width: 40, }}>{detailMsg.FRvA}</div>
                                <span style={{width: 30, marginLeft: 6}}>拌</span>
                                <div style={{width: 40}}>{detailMsg.FRvB}</div>
                                <span style={{width: 30, marginLeft: 6}}>退</span>
                                <div style={{width: 40}}>{detailMsg.FQvA}</div>

                            </li>

                            <li>
                                <span>砂浆数量</span>
                                <div className="halfWidth">{detailMsg.FBcfsM}</div>
                                <span>累计方数</span>
                                <div className="halfWidth">{detailMsg.FLjfs}</div>
                            </li>

                            <li>
                                <span>出厂签发</span>
                                <div className="halfWidth">{detailMsg.FCcqf}</div>
                                <span>用户签收</span>
                                <div className="halfWidth">{detailMsg.FYhqs}</div>
                            </li>

                            <li> 
                                <span>操&#160;作&#160;员</span>
                                <div className="halfWidth">{detailMsg.FCzy}</div>
                                <span>备&#12288;&#12288;注</span>
                                <div className="halfWidth">{detailMsg.FBz}</div>
                            </li>

                        </ul> 


                        <ul className="setdetailedInfo" style={{marginTop: 30}}>
                            <h1>原料消耗量和称重误差</h1>

                            <table>
                            <tr>
                                <th>原料</th>
                                <th>品种</th>
                                <th>设定值</th>
                                <th>误差</th>
                                <th>含水率</th>
                                <th>仓库</th>
                            </tr>
                            {
                                detailMsg.TphbyclArr.map(item => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.FYlmc}</td>
                                            <td>{item.FPzgg}</td>
                                            <td>{item.FPbsl}</td>
                                            <td>{Math.floor((item.FPbsl - item.FSysl) * 100) / 100 }</td>
                                            <td>{item.FHsl}</td>
                                            <td>{item.FBh}</td>
                                        </tr>
                                    )
                                })
                            }
                            </table> 
                        </ul>   
                    </div>    

                    </TabPane>
                </Tabs>

         
            </div>

        </div>

    </div >
  )
}

export default withRouter(ProductionTransportation);