import React from "react";

import {Button, Table, message, Image, Select, Modal, Form, Input} from "antd";
// 添加furon
import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import './machineCommodityAll.less';


import { Uploadhh } from "@/components/upload/upload";

const {Option} =  Select;


const layout = {
    labelCol: { span: 16},
};


export default class ProjectConfig extends React.Component {
  FormSbmitRef = null
  imageUrl = process.env.REACT_APP_IMG_URL
  state = {
    status: 0,
    loading: false,
    tableData: [],
    pagination: {
    current: 1,
    pageSize: 10,
    total: 200,
    onShowSizeChange: function(current, pageSize) {
        this.state.pagination.pageSize = pageSize;
        this.handleTableChange(this.state.pagination)
    }.bind(this),
    onChange: function(current) {
        this.state.pagination.current = current
        this.handleTableChange(this.state.pagination)
    }.bind(this),
    showTotal: function () {  //设置显示一共几条数据
        return '共 ' + this.state.pagination.total + ' 条'; 
    }.bind(this),
    showQuickJumper: true,
    },
    params : {
        lengthLimit: 10,
        offsetLimit: 0,
        name: null,
        nameIdCard: null,
        deviceKey: null,

    },
    isModalVisible: false,
    requestParmas: {
        id: null,
        img: null,
        name: null,
        quantity: null,
        redeemPoints: null
    },
    machineDeviceList: [],
    deviceInspectionTypeList: [],
  } 
  


 columns = [
    {
        title: "序号",
        dataIndex: 'title',
        align: 'center',
        render: (_, item, index) => {
          return (
            <p>{index + 1}</p>
          )
        }
      },
    {
      title: '商品名称',
      dataIndex: 'name',
      align: 'center',
    },
    {
      
      title: '设备序列号',
      dataIndex: 'deviceKey',
      align: 'center',
    },
    {
      
        title: '货道号',
        dataIndex: 'cargoNo',
        align: 'center',
    },
    {
        title: '兑换积分',
        dataIndex: 'redeemPoints',
        align: 'center',
    },
    {
        title: '上架数量',
        dataIndex: 'quantity',
        align: 'center',
    },
    {
        title: '商品图片',
        dataIndex: 'img',
        align: 'center',
        render: (_) => {
            return (
                <Image
                width={15}
                src={this.imageUrl + _}
                />
            )
        }
    },
     {
        title: '操作',
        dataIndex: 'address',
        align: 'center',
        width: 170,
        render:(_,item) => {
          return (
            <div className="operation">
                <p onClick={this.edit.bind(this, item)}>编辑</p> 
            </div>
          )
        }
      },
  ]


  handleTableChange(pagination) {
    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize
    this.setState({
      params: this.state.params,
      pagination: pagination
    })

    // // 执行更新列表
    // this.getList()
  }


  

  componentDidMount() {
    // this.getList()

    this.getMachineDeviceAll()
  }


  getMachineDeviceAll() {
      React.$axios.post('machineDeviceAll').then(res => {
        this.setState({
            machineDeviceList: res.data.machineDeviceAll
        })

        if(res.data.machineDeviceAll[0]) {
            this.state.params.deviceKey = res.data.machineDeviceAll[0].deviceKey

            this.setState({
                params: this.state.params
            })
        }

        this.getList();
      })
  }

  

  getList() {
    this.setState({
        loading: true
    })
    React.$axios.post('machineCommodityAll', this.state.params).then(res => {
        if(!res.data.countList) {
            res.data.countList = res.data.machineCommodityAll.length;
        }
         this.state.pagination.total = res.data.countList
        this.setState({
            tableData: res.data.machineCommodityAll,
            loading: false,
        })

    })
  }

  edit(item) {
    for(let i in this.state.requestParmas) {
        this.state.requestParmas[i] = item[i]
    }

    this.setState({
        requestParmas: this.state.requestParmas,
        isModalVisible: true,
        cargoNo: item.cargoNo
    })

    
    this.clierFormSbmitRef()

  }


  clierFormSbmitRef() {
    if(!this.FormSbmitRef) {
        setTimeout(() => {
          
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
  }




  onChangeT(value) {
    this.state.params.deviceKey = value

    this.setState({
        params: this.state.params
    })

}

  // 去查询
  toSerch() {
    this.getList()
  }

  handleOk() {
    this.FormSbmitRef.submit()
  }



  handleCancel() {
    this.setState({
        isModalVisible:false
    })
  }



  onFinish(params) {
    for(let i in params) {
        this.state.requestParmas[i] = params[i]
    }

    React.$axios.post('machineCommodityEditSave', this.state.requestParmas).then(res => {
        if(res.code == 20000) {
         message.success('保存成功')
         this.getList()
         this.setState({
             isModalVisible: false
         })   
        }
    })
  }

  handleBack(val) {
    this.state.requestParmas.img = val.data.key[0]

    this.setState({
        requestParmas: this.state.requestParmas
    })
    

  }





  render() {
    return (
      <div className="machineCommodityAll">

        <div className="warp1">
                <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>设备:</span> 
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    value={this.state.params.deviceKey}
                                    onChange={this.onChangeT.bind(this)}
                                >
                                    {
                                        this.state.machineDeviceList.map(item => {
                                            return (
                                                <Option value={item.deviceKey}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                        </li>

                        

                            <Button  className="btn" onClick={this.toSerch.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>

                          
                    </ul>
                </div>


                <div className="table">
                <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id}  onChange={this.handleTableChange.bind(this)} columns={this.columns} dataSource={this.state.tableData}  />

                </div>
            
            </div>


            
            
            <Modal title="编辑" width="900px" className="machineCommodityAllModal" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                            
                            <Form
                                layout="vertical"
                                onFinish={this.onFinish.bind(this)}
                                ref={(el) => this.FormSbmitRef = el}
                                {...layout}
                                initialValues = {this.state.requestParmas}
                            >   

                                <h1>货道号: {this.state.cargoNo}</h1>
                                <div className="flex justCont">
                                    <div>
                                        <div className="flex setFlex">
                                            <div>
                                                <Form.Item   label="名称" name="name"   rules={[{ required: true }]}>
                                                <Input style={{ width:350,  }}  placeholder="请输入名称" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="flex setFlex">
                                            <div>
                                                <Form.Item label="积分"   name="redeemPoints" rules={[{ required: true }]} >
                                                <Input style={{ width:350,  }}  placeholder="请输入积分" />
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className="flex setFlex">
                                        <div>
                                            <Form.Item   label="库存" name="quantity"   rules={[{ required: true }]}>
                                                <Input style={{ width:350,  }}  placeholder="请输入库存" />
                                            </Form.Item>
                                        </div>
                                        
                                        </div>
                                    </div>
                                    <div>
                                        <Form.Item label="图片">
                                            <Uploadhh  imageUrl={this.state.requestParmas.img} data={{type:25, pixel:''}}  width={300} height={250} handleClick={this.handleBack.bind(this)} />
                                        </Form.Item>
                                    </div>
                                    
                                </div>        


                            </Form>
            </Modal> 



      </div>

    );
  }
}
