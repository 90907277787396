

import {ifcUtf_8} from '@/utils/util';


/**
 * 
 * @param {*} status 是否独占一行
 * @param {*} name   名称
 * @param {*} value  值
 * @param {*} unit   单位
 * @returns 
 */
const treeDomList = (status, name, value, unit) => {
    if(status) {
        return (
            <div className="treeD"><div className="t1">{name}</div> <div className="t2">{value}</div> <div className="t3">{unit}</div> </div>
        )
    } else {
        return (
            <div className="treeD"><div className="oneCell">{name}</div></div>
        )
    }
    
}


export const isEmpty = (params) => {

    if(!params) {
        return {};
    } else {
        let str =  params.value;
        str =  ifcUtf_8(str);
        params.value = str;
        return params;
    }

}


/**
 *  树结构
 * @param {*} data 
 */
export const treeDataDom = (data) => {
    if(!data) {
        return [];
    }

    console.log(data)

    let Representation = data.Representation.Representations?data.Representation.Representations: [];
    let Representations = {};
    Representation = Representation[Representation.length-1];   //  data.Representation.Representations[0]?data.Representation.Representations[0][0].Items:{};
    if(Representation) {
        console.log(Representation)
        
        Representations = Representation.Items[0].SweptArea ? Representation.Items[0].SweptArea: {};

        if(Representations) {
            isEmpty(Representations.ProfileName);
        }
        
    }
   
    data.GlobalId =  isEmpty(data.GlobalId)
    data.Name = isEmpty(data.Name)
    data.ObjectType = isEmpty(data.ObjectType)
    data.Tag = isEmpty(data.Tag)
    data.Description = isEmpty(data.Description)
    data.parentParams.Name = isEmpty(data.parentParams.Name)
    data.parentParams.LongName =  isEmpty(data.parentParams.LongName)

    // data.Tag = isEmpty(data.Tag)

    // 
    // console.log(data.Name.value)
    // console.log(data)

    let arr = []
    try {
         arr = [
            {
               title: treeDomList(false, 'Element Specific'),
               key: '0',
               children: [
                   {
                       title: treeDomList(true,'Descript', data.Description.value, ''),
                       key: '0-0',
                   },
                   {
                        title: treeDomList(true,'Guid', data.GlobalId.value, ''),
                        key: '0-1',
                   },
                   {
                        title: treeDomList(true,'Name', data.Name.value, ''),
                        key: '0-2'
                   },
                   {
                    title: treeDomList(true,'ObjectType', data.ObjectType.value, ''),
                    key: '0-3'
                   },
                   {
                    title: treeDomList(true,'Tag', data.Tag.value, ''),
                    key: '0-4'
                   },
                   {
                    title: treeDomList(true,'PredefinedType', data.PredefinedType?data.PredefinedType.value:'', ''),
                    key: '0-5'
                   },
               ] 
            },
            {
                title: treeDomList(false, 'Location'),
                key: '2',
                children: [
                    {
                        title: treeDomList(true,'CompositionType', data.parentParams.CompositionType.value, ''),
                        key: '2-0',
                    },
                    {
                        title: treeDomList(true,'Name', data.parentParams.Name.value, ''),
                        key: '2-1',
                    },
                    {
                        title: treeDomList(true,'LongName', data.parentParams.LongName.value, ''),
                        key: '2-2',
                    },

                ] 
             },
            {
                title: treeDomList(false, 'Profile'),
                key: '1',
                children: [
                    {
                        title: treeDomList(true,'profileName', Representations.ProfileName?Representations.ProfileName.value: '', ''),
                        key: '1-0',
                    },
                    {
                         title: treeDomList(true,'ProfileType', Representations.ProfileType?Representations.ProfileType.value: '', ''),
                         key: '1-1',
                    },
                    {
                         title: treeDomList(true,'XDim', Representations.XDim?Representations.XDim.value: '', ''),
                         key: '1-2'
                    },
                    {
                        title: treeDomList(true,'YDim', Representations.YDim?Representations.YDim.value: '', ''),
                     key: '1-3'
                    },
                ] 
             },
             
        ]
    } catch(e) {
        console.error(e)
    }
     

    return arr;


}







