import React from "react";

import {Image, Tabs, Pagination, Carousel, Modal} from "antd";


import {RightOutlined, MessageFilled} from '@ant-design/icons';

import {formatTime} from '@/utils/util'

import './Home.less'
import img1 from '@/assets/img/party/党1.png';

const { TabPane } = Tabs;

const imgUrl = process.env.REACT_APP_IMG_URL

export default class Home extends React.Component {
  
  state = {
      activeList : [],
      NegotiateList: [],
      showMsg: {

      },
      params: {
        lengthLimit: 10,
        logic: 2,
        offsetLimit: 0,
        status: "",
        title: "",
        type: "",
      },
      paramsActivity: {
        lengthLimit: 20,
        logic: 2,
        offsetLimit: 0,
        range: "",
        status: "",
      },
      total: 0,
      partyMemberBuilding: {},
      newsList2Msg: [],
      isModalVisible: false,
      isModalVisibleParty: false,
      contentText: null,
      title: null,
      defaultCurrent: 1,
      signIn: [],
      singUp: [],
      toNewsPath: null,
  }

  componentDidMount() {
      this.getListBuildingMicroActivity()
    //   this.getListBuildingNegotiate()


    this.state.toNewsPath = window.location.origin + '/projectSummary/newsList?type = 3'; 

    this.setState({
        toNewsPath: this.state.toNewsPath
    })

      let projectId = React.$getStorage('projectInfo').id;

     this.getMartyMemberBuildingStatistics(projectId)


     this.getNewsList2(projectId)

  }


  // 获取新闻
  getNewsList2(id) {
      let params = {
          projectId: id,
          lengthLimit: 20,
          offsetLimit: 0,
          type: 3
      }  

      React.$axios.post('newsList2', params).then(res => {
          this.setState({
            newsList2Msg: res.data.list
          })  


      })
  }    


  // 获取党员统计
  getMartyMemberBuildingStatistics(id) {
    React.$axios.post('partyMemberBuildingStatistics', {projectId: id}).then(res => {
        this.setState({
            partyMemberBuilding: res.data
        })
    })
  }



  
  // 获取活动列表
  getListBuildingMicroActivity(type = false) {

    if(type) {
        this.state.paramsActivity.offsetLimit = 0;
        this.setState({
            defaultCurrent: 1,
            paramsActivity: this.state.paramsActivity
        })
    }
    React.$axios.post('listBuildingMicroActivity', this.state.paramsActivity).then(res => {
        if(res.data.list == null) {
            res.data.list = []
        }
        this.setState({
            activeList:res.data.list,
            total: res.data.countList,
        })

    })
  }

  // 获取通知
  getListBuildingNegotiate() {

    React.$axios.post('listBuildingNegotiate', this.state.params).then(res => {
        console.log(res)
        this.setState({
            NegotiateList: res.data.list
        })
    })
  }


  callback(index) {

    if(index == 2) {
        index = [3, 4]
    }

    this.state.paramsActivity.status = index
    this.getListBuildingMicroActivity(true)
    this.setState({
        defaultCurrent: 1
    })
  }

  showNews(item) {
    this.setState({
        isModalVisible: true,
        title: item.title,
        contentText: item.content
    })

  }


  handleOk() {
    this.handleCancel()
  }

  handleCancel() {
    this.setState({
        isModalVisible: false,
        isModalVisibleParty: false,
    })
  }

  // 
  onShowSizeChange(val) {
    this.state.paramsActivity.offsetLimit =  (val -1) * 10
    this.setState({
        defaultCurrent: val
    })
  }

  // 点击获取活动
  clickPartyActive(item) {
    let params = {
        logic: 4,
        singUp: 2,
        singUpMe: 2,
        signIn: 2,
        singInMe: 2,
        id: item.id
    }

    React.$axios.post('listBuildingMicroActivity', params).then(res => {
        if(typeof(res.data.info.singUp) == "string") {
            res.data.info.singUp = JSON.parse(res.data.info.singUp)
            res.data.info.singUpMe = JSON.parse(res.data.info.singUpMe)
            res.data.info.signIn = JSON.parse(res.data.info.signIn)
        }
        
        this.setState({
            showMsg: res.data.info,
            signIn: res.data.info.signIn,
            singUp: res.data.info.singUp,
            isModalVisibleParty: true
        })
    })

  }


  render() {
    return (
      <div className="partyWarp">
          <div className="flex">
            <div className="leftData">
                <div className="top flex">
                    <img src={img1} alt="" />

                    <ul>
                        <li className="active">
                            <p><span>{this.state.partyMemberBuilding.partyMember}</span>个</p>
                            <p>党员</p>
                        </li>
                        <li>
                            <p><span>{this.state.partyMemberBuilding.probationaryMember}</span>个</p>
                            <p>预备党员</p>
                        </li>
                        <li>
                            <p><span>{this.state.partyMemberBuilding.partyMemberActivities}</span>个</p>
                            <p>党员活动</p>
                        </li>
                        <li>
                            <p><span>{this.state.partyMemberBuilding.conferenceNegotiation}</span>个</p>
                            <p> 会议协商</p>
                        </li>
                    </ul>


                </div>    

                <div className="bottom flex">
                    <Carousel autoplay>
                     {
                         this.state.newsList2Msg.map(item => {
                             return (
                                <div className="botLeft" key={item.id}>
                                    <Image
                                        height={250}
                                        src={imgUrl + item.cover} alt=""
                                    />

                                    <div className="context" onClick={this.showNews.bind(this, item)}>
                                        <h1>{item.title}</h1>
                                        <p dangerouslySetInnerHTML={{__html: item.content}}></p> 
                                </div>
                                </div>
                             )
                         })
                     }    
                    
                    </Carousel>
                    <div className="botRight">
                        <h1 className="flex">
                            <span><MessageFilled className="icon" />通知公告</span>
                            <p> <a href={this.state.toNewsPath}>查看全部</a>< RightOutlined/></p>
                        </h1>

                        <ul>
                            {
                                this.state.newsList2Msg.map(item => {
                                    return (
                                        <li key={item.id} onClick={this.showNews.bind(this, item)}><p>{item.title}</p>  <span>{formatTime(new Date(item.editTime * 1000), 'yyyy-MM-dd') }</span></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    

                </div>
            </div>

            <div className="rightData">
                <h1 className="flex">
                    <span><MessageFilled className="icon" />支部活动</span>
                    <p>查看全部< RightOutlined/></p>
                </h1>

                <Tabs defaultActiveKey=" " onChange={this.callback.bind(this)}>
                    <TabPane tab="全部" key=" ">

                    </TabPane>
                    <TabPane tab="进行中" key="2">
                    
                    </TabPane>
                    <TabPane tab="已结束" key="6">
                    
                    </TabPane>
                </Tabs>

                <ul className="rul">
                    {
                        this.state.activeList.map(item => {
                            return (
                                <li onClick={this.clickPartyActive.bind(this, item)}>
                                    <div key={item.id} className="leftData">
                                        <h2>{item.title}</h2>
                                        <h3>{formatTime((new Date(item.timeStart * 1000)), 'yyyy-MM-dd')}</h3>
                                        <h4>{item.address}</h4>  
                                    </div>
                                    <div className="rawarp">
                                        <div  className={'setBackColor' + item.status + ' rightData2' }>
                                            {item.status == 6? '已结束': '进行中'}
                                        </div>
                                    </div>
            
                                </li>
                            )
                        })
                    }
                   

                </ul>

                <div className="serPagin">
                    <Pagination  size ='small' current = {this.state.defaultCurrent} total={this.state.total} onChange={this.onShowSizeChange.bind(this)}   />
                </div>

     
            </div>

          </div>


          <Modal title={this.state.title}  className='projectSummaryModal' width="60%"  visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                      <div className="setHEIGHT">
                        <div dangerouslySetInnerHTML ={{__html: this.state.contentText}}>

                        </div>
                      </div>
          </Modal>
        

          <Modal title = '详情' className='setPartyModal' width="600px" visible={this.state.isModalVisibleParty}  onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                    <div className="PartyModalBody setHEIGHT">
                            <Image
                                width="100%"
                                src={imgUrl + this.state.showMsg.img} alt=""
                            />

                           <div className="body"> 
                                <div className="title">
                                    {this.state.showMsg.title}
                                </div>

                                <div className="dscript">
                                    {this.state.showMsg.introduction}
                                </div>

                                <div className="flex setTable">
                                    <div className={'status setColor' + this.state.showMsg.status}>{this.state.showMsg.status ==2?'报名未开始': this.state.showMsg.status==3?'报名中':this.state.showMsg.status==4?'签到未开始':this.state.showMsg.status==5?'签到中':'活动已结束'}</div>
                                    <div className="jifen">{this.state.showMsg.integral}积分</div>
                                </div>


                                <div className="signUpQuantity">
                                    {this.state.showMsg.signUpQuantity}人已报名
                                </div>

                                <ul>
                                    <li>
                                        <h1>活动地点</h1>
                                        <p>{this.state.showMsg.address}</p>
                                    </li>
                                    <li>
                                        <h1>活动时间</h1>
                                        <p>{formatTime(new Date(this.state.showMsg.timeStart * 1000), 'yyyy.MM.dd')} - {formatTime(new Date(this.state.showMsg.timeEnd * 1000), 'yyyy.MM.dd')}</p>
                                    </li>
                                    <li>
                                        <h1>报名人员</h1>

                                        <span>
                                            {
                                                function() {
                                                    if(this.state.singUp.length == 0) {
                                                        return (
                                                            <p>暂无报名人员</p>
                                                        )
                                                    }
                                                }.bind(this)()
                                            }

                                            {
                                                this.state.singUp.map(item => {
                                                    return (
                                                        <span>{item.nameIdCard}</span>
                                                    )
                                                })
                                            }
                                        </span>

                                           
                                        
                                    </li>
                                    <li>
                                        <h1>签到人员</h1>
                                        {
                                                function() {
                                                    if(this.state.signIn.length == 0) {
                                                        return (
                                                            <p>暂无签到人员</p>
                                                        )
                                                    }
                                                }.bind(this)()
                                            }

                                            {
                                            
                                                this.state.signIn.map(item => {
                                                    return (
                                                        <span>{item.nameIdCard}</span>
                                                    )
                                                })
                                            }
                                    </li>
                                </ul>
                            </div>



                    </div>



          </Modal>


    
      </div>
    );
  }
}
