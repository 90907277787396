import React from "react";




import { DatePicker,Input,Select,Button, Table, Menu, Dropdown, Image} from "antd";


import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import './vehicleControlLogList.less';

import {formatTime} from '@/utils/util'
const {Option} =  Select;



let imgUrl = process.env.REACT_APP_IMG_URL


export default class Home extends React.Component {



    state = {
        // 表格分页属性
        pagination: {
            current: 1,
            pageSize: 10,
            total: 200,
            onShowSizeChange: function(current, pageSize) {
                this.state.pagination.pageSize = pageSize;
                this.handleTableChange(this.state.pagination)
            }.bind(this),
            onChange: function(current) {
                this.state.pagination.current = current
                this.handleTableChange(this.state.pagination)
            }.bind(this),
            showTotal: function () {  //设置显示一共几条数据
                return '共 ' + this.state.pagination.total + ' 条'; 
            }.bind(this),
            showQuickJumper: true,
            },

    loading: false,

    tableData: [
        
    ],
    enterpriseAllList: [],
    vehicleDoormanAllList: [],
    teamAllList: [],
    workAllList: [],

    openStatus: false,
    queryParams: {
        lengthLimit: 10,
        logic: 2,
        offsetLimit: 0,
        logoDoorman: '',
        enterpriseId: [],
        teamId: [],
        workId: [],
        keyword:'',
        deviceID: '',
    },

    status: 0,

    deviceList: []
  }


  columns = [
    {
        title: "序号",
        dataIndex: 'title',
        align: 'center',
        render: (_, item, index) => {
          return (
            <p>{index + 1}</p>
          )
        }
      },
      {
        title: '车牌',
        dataIndex: 'carId',
        align: 'center'
      },
      {
        title: '图片',
        dataIndex: 'state',
        align: 'center',
        render:(_, item) => {
            return(
                <Image
                    width={50}
                    src={imgUrl + item.image}
                    />
            )
        } 
      },
      {
        title: '设备',
        dataIndex: 'nameDevice',
        align: 'center'
      },
      {
        title: '进出时间',
        dataIndex: 'showTime',
        align: 'center',
        render: (_, item) => {
            return(
                <div>
                    {formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss') }
                </div>
            )
        }
      }, 
      {
        title: '公司',
        dataIndex: 'nameEnterprise',
        align: 'center'
      },
      {
        title: '所属部门',
        dataIndex: 'nameTeam',
        align: 'center'
      },
      {
          title: '所属职务',
          dataIndex: 'nameWork',
          align: 'center'
      },
    ]

    

    componentDidMount() {
       // this.getVehicleControlList()
        this.getEnterpriseAll()
        this.getVehicleDoormanAll()

        this.getListVehicleDevice();
    }

     // 组件离开生命周期
    componentWillUnmount() {
        localStorage.removeItem('pageIndex1');
    }

    componentWillMount () {
        let t =  React.$getStorage('pageIndex1')
        if(t) {
            this.setState({
                status: t
            })
        }
    }

    // 获取设备
    getListVehicleDevice(logo = '') {
        let params = {
            logic: 3,
            logo: logo
        }
        React.$axios.post('listVehicleDevice', params).then(res => {
            this.setState({
                deviceList: res.data.list
            })
        })

        // this.state.queryParams


    }

    // 获取列表
    getVehicleControlList(type) {
        this.setState({
            loading: true
          })

        if(type) {
            this.state.queryParams.offsetLimit = 0
            this.state.pagination.current = 1
            this.setState({
                pagination: this.state.pagination
            })
        }
        this.setState({
            tableData: [],
        })
        

        React.$axios.post('vehicleControlLogList', this.state.queryParams).then(res => {

            this.state.pagination.total = res.data.countList
            
            this.setState({
                tableData: res.data.list,
                pagination: this.state.pagination,
                loading: false
            })

        })
    }


   // 获取公司 
   getEnterpriseAll()  {
    React.$axios.post('enterpriseAll').then(res => {
        this.setState({
            enterpriseAllList: res.data.enterpriseAll
        })
    })
   }

   getTeamAll(id) {
    console.log(id)
    React.$axios.post('teamAll', {id: id}).then(res => {
        this.setState({
            teamAllList: res.data.teamAll
        })
    })
   }

   getWorkAll(id) {
    React.$axios.post('workAll', {id: id}).then(res => {
        this.setState({
            workAllList: res.data.workAll
        })
    })
   }



   // 位置
   getVehicleDoormanAll() {
    React.$axios.post('vehicleDoormanAll').then(res => {
        this.setState({
            vehicleDoormanAllList: res.data.vehicleDoormanAll
        })

        // if(res.data.vehicleDoormanAll[0]) {
        //     this.state.queryParams.logoDoorman = res.data.vehicleDoormanAll[0].logo
        // }
        

        this.setState({
            queryParams: this.state.queryParams
        })

        this.getVehicleControlList()
    })
   }

   
    nChangeT() {

    }

    setDrapDom() {
      if(this.state.openStatus == true) {
          return (
            <UpOutlined/>
          )
        } else {
          return (
            <DownOutlined />
          )
        }
    }

    drapHeight() {
      this.setState({
        openStatus: !this.state.openStatus
      })
    }

    

      onChangeT(name, value) {
        this.state.queryParams.logoDoorman = value
        this.state.queryParams.deviceID = '';
        this.setState({
            queryParams: this.state.queryParams
        })

        this.getListVehicleDevice(value)
      }

      // 点击获取设备
      onChangeDevice(val) {
        this.state.queryParams.deviceID = val;
        this.setState({
            queryParams: this.state.queryParams
        })
      }

      // 选择岗位
      onChangeWork(name, value) {
        this.state.queryParams.workId = value
      }


      onChangeEnterprise(name, value, item) {
        let newId = this.getNewIdData(item)
        this.state.queryParams.enterpriseId = value;



        this.getTeamAll(newId)
      }

      // 获取队伍
      onChangeTeam(name,value, item) {
        let newId = this.getNewIdData(item)
        this.state.queryParams.teamId = value
        this.getWorkAll(newId)
     }


      getNewIdData(item) {
        let id = []
        item.map(ite => {
            if(ite.item) {
                let allId = ite.item.split(',')
                id = [...id, ...allId]
            }
        })

        var newId = [...new Set(id)]

        return newId
      }

      // 点击放大镜查询
      toSerch() {
        this.getVehicleControlList(true)
      }

      handleTableChange(pagination) {
            this.state.queryParams.offsetLimit = (pagination.current -1) * pagination.pageSize
            this.state.queryParams.lengthLimit = pagination.pageSize

            this.setState({
            queryParams: this.state.queryParams,
            pagination: pagination
            })


            // 执行更新列表
            this.getVehicleControlList()


      }

      // 设置车牌
      selectCar() {

      }

      // 查找车牌号
      serchCar(name, {target: {value}}) {
        this.state.queryParams.keyword = value
        this.setState({
            queryParams: this.state.queryParams
        })
      }

      changeStatus(index) {
        this.setState({
            status: index
        })
        React.$setStorage('pageIndex1', index)
      }

    
  render() {
    return (
      <div className="vehicleControlLogList vehicleControlLogList2">
            <div className="page1">
            <div className="input">
                <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                    <li><span>门卫:</span> 
                        <Select
                            showSearch
                            style={{ width: 190, height:30, marginRight:20,  }}
                            placeholder="请选择"
                            optionFilterProp="children"
                            value={this.state.queryParams.logoDoorman}
                            onChange={this.onChangeT.bind(this, 'logoDoorman')}
                        >

                            <Option value=''>全部</Option>
                            {
                                this.state.vehicleDoormanAllList.map(item => {
                                    return (
                                        <Option value={item.logo}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </li>
                    <li><span>设备:</span> 
                        <Select
                            showSearch
                            style={{ width: 190, height:30, marginRight:20,  }}
                            placeholder="请选择"
                            optionFilterProp="children"
                            value={this.state.queryParams.deviceID}
                            onChange={this.onChangeDevice.bind(this)}
                        >

                            <Option value=''>全部</Option>
                            {
                                this.state.deviceList.map(item => {
                                    return (
                                        <Option value={item.deviceID}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </li>
                    <li><span>公司:</span> 
                        <Select
                                showSearch
                                style={{ width: 190,  marginRight:20,  }}
                                placeholder="请选择"
                                optionFilterProp="children"
                                mode="multiple"
                                onChange={this.onChangeEnterprise.bind(this, 'uiRole')}
                            >
                                {
                                    this.state.enterpriseAllList.map(item => {
                                        return (
                                            <Option value={item.id} item={item.teamId}>{item.name}</Option>  
                                        )
                                    })
                                }
                            </Select>
                            
                    </li>
                    <li><span>部门:</span> 
                        <Select
                                showSearch
                                style={{ width: 190,  marginRight:20,  }}
                                placeholder="请选择"
                                optionFilterProp="children"
                                mode="multiple"
                                onChange={this.onChangeTeam.bind(this, '1')}
                            >
                                {
                                    this.state.teamAllList.map(item => {
                                        return (
                                            <Option value={item.id} item={item.workId}>{item.name}</Option>  
                                        )
                                    })
                                }
                            </Select>  
                    </li>
                    <li><span>岗位:</span>  
                    <Select
                                showSearch
                                style={{ width: 190,  marginRight:20,  }}
                                placeholder="请选择"
                                optionFilterProp="children"
                                mode="multiple"
                                onChange={this.onChangeWork.bind(this, 'uiRole')}
                            >
                                {
                                    this.state.workAllList.map(item => {
                                        return (
                                            <Option value={item.id}>{item.name}</Option>
                                        )
                                    })
                                }

                            {/* <Option value=" ">全部</Option>
                            <Option value="2">劳务人员</Option>
                            <Option value="3">项目管理人员</Option> */}

                            </Select>
                        </li>
                    
                        {/* <li> 
                        <Dropdown overlay={this.menu} trigger={['click']}>
                            <Button onClick={this.selectCar.bind(this)}>请选择省</Button>  
                        </Dropdown>
                        </li> */}
                        <li><span>车牌号:</span> <Input onChange={this.serchCar.bind(this, 'keyword')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" /></li>


                        <Button  className="btn" onClick={this.toSerch.bind(this)} >
                            <SearchOutlined className="icon"/>
                        </Button>

                        
                </ul>
            </div>


            <div className="table2">
            <div className="allBtn">

                </div>

                <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => `a${record.id}`} expandable={{ expandedRowRender: this.expandedRowRender }} columns={this.columns} dataSource={this.state.tableData}  />
            </div>
            </div>
      </div>
    );
  }
}
