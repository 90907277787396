import React from "react";
import { Button, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import './error.less';
class Error extends React.Component {
  // goback() {
  //   this.props.history.push('/home');
  // };
  render() {
    return (
      <Row gutter={24} className="wrap">
        <Col offset={4} sm={10} className="img-box" xs={20} />
        <Col offset={1} sm={10} className="content-error" xs={20}>
          <h1>404</h1>
          <p className="desc">抱歉，你访问的页面不存在</p>
          {/* <div>
            <Button onClick={this.goback.bind(this)} type="primary">
              返回首页
            </Button>
          </div> */}
        </Col>
      </Row>
    );
  }
}
export default withRouter(Error);