import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import debounce from 'lodash.debounce'
import './security.less'

import videoPlay from '@/assets/img/videoReturning/2.png';
import img1 from '@/assets/img/videoReturning/1.png';
import img5 from '@/assets/img/earth/5.png';
import img8 from '@/assets/img/earth/8.png';
import * as echarts from 'echarts';


function Security() {

    const [echartStatus, setEchartStatus] = useState(true)

    useEffect(() => {



        // 设置图形
        setTimeout(() => {
            setEchart()
            setEchart2()
        }, 200)
        
        return () => {
            
        }
    }, []);


    // 设置图形
    function setEchart() {
        let chartDom = document.getElementById('barChart');
        let myChart  = echarts.init(chartDom);
        let option;


        option = {
            xAxis: {
                type: 'category',
                data: ['1月', '2月', '3月', '4月', '5月','6月','7月','8月','9月','10月'],
                axisLabel: {  
                    interval:0,  
                    // rotate:-20
                }, 
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: false
                }
            },

            grid: {
                left: '1%',
                right: '4%',
                bottom: '20%',
                top: '10%',
                containLabel: true
            },

            series: [{
                data: [120, {
                    value: 200,
                    itemStyle: {
                        color: '#5B76F7'
                    }
                }, 150, 80, 70, 30, 60, 70, 80, 50],
                type: 'bar',
                barWidth: 20,
                color: ['#58CDFE'],
            }]
        };
        
    
        option && myChart.setOption(option);  

    }


    function setEchart2() {
        let chartDom = document.getElementById('roundChart');
        let myChart = echarts.init(chartDom);


        var option;

        option = {
            
            legend: {
              top: '5%',
              left: 'center'
            },
            
            series: [
              {
                name: 'Access From',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: false,
                    fontSize: '40',
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                // itemStyle:{
                //     borderWidth:5, //设置border的宽度有多大
                //     borderColor:'#0A182C',
                // },    
                data: [
                  { value: 1048, },
                  { value: 100,  },
                ],
                color: ['#57CCFF', '#15252E'],
              }
              
            ]
          };

        option && myChart.setOption(option);

    }


  return (
     
    <div className='Security'> 

                <div className="body flex">
                    
                    <div className="leftData" >
              

                    </div>

                    <div className="rightData">

                    <div className="title PersonnelSupervisionFistTitle">
                        <p>
                            <img src={img1} alt="" />   隐患排查整改率
                        </p>

                        <div className="SecurityTotal">
                           <div className="flexBody">

                            <div className="content">
                                    <div className="flex">
                                        <div className="setImg">
                                            <img src={img8} alt="" />
                                        </div>

                                        <div className="text">
                                            隐患数量
                                        </div>
                                    </div>

                                    <div className="number">
                                        33
                                    </div>
                    
                            </div>    

                                
                            <div className="echartsPerson" id="roundChart">

                            </div>

                            <div className="pos">
                                <p className="p1">91.67  <i>%</i></p>
                                <p className="p2">整改率</p>  
                            </div>

                            <div className="content">
                                    <div className="flex">
                                        <div className="text2">
                                            隐患办结
                                        </div>
                                        <div className="setImg2">
                                            <img src={img8} alt="" />
                                        </div>
                                    </div>

                                    <div className="number2">
                                        36
                                    </div>
                    
                            </div>   


                           </div>

                            


                        </div>

                    </div>

                    <div className="title">
                            <p>
                              <img src={img1} alt="" /> 隐患数量趋势

                               <span>件</span>         
                            </p>

                           {
                               function() {
                                   if(echartStatus) {
                                    return (
                                        <div id="barChart" className="echartBar">
                                        </div>  
                                    )

                                         
                                   } else {
                                    return (
                                        <span></span>
                                    )
                                   }
                               }()
                           }

                              
                            
                        </div>



                    <div className="title">
                            <p>
                            <img src={img1} alt="" />   各项目隐患整改比
                            </p>
                            <div className="PersonnelSupervisionPerson">
                                <p>
                                    <div className="name">
                                         <b>1</b>       
                                        滇中引水石鼓水源工程    
                                    </div>
                                    
                                    <i>100%</i>
                                </p>
                                <p>
                                    <div className="name">
                                         <b>2</b>       
                                        滇中引水石大理1段2标    
                                    </div>
                                    
                                    <i>100%</i>
                                </p>
                                <p>
                                    <div className="name">
                                         <b>3</b>       
                                        郑州轨迹交通8号线    
                                    </div>
                                    
                                    <i>100%</i>
                                </p>
                                <p>
                                    <div className="name">
                                         <b>4</b>       
                                        成都轨迹交通19号线 
                                    </div>
                                    
                                    <i>100%</i>
                                </p>
                                <p>
                                    <div className="name">
                                         <b>5</b>       
                                        深圳轨迹交通12号线  
                                    </div>
                                    
                                    <i>100%</i>
                                </p>
                            </div>


                            {/* <ul className="setUl">
                                <li>佩戴安全帽 <span>11.6 %</span></li>
                                <li>反光衣 <span>12.7 %</span></li>
                                <li>陌生拜访 <span>23.0 %</span></li>
                                <li className="colorActive">滞留 <span>30.6 %</span></li>
                                <li>其他 <span>21.92 %</span></li>
                            </ul> */}

                        </div>



                       
                        
                    </div>
                
                </div>    

    </div >
  )
}

export default withRouter(Security);