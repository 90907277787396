import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import debounce from 'lodash.debounce'
import './projectdistrtion.less'
import { Carousel } from 'antd';

import img1 from '@/assets/img/earth/gcjz.svg';

import {VideoComponentHH} from '../../component/video/video';



const imgURL = process.env.REACT_APP_IMG_URL;
let index = 0, length = 0, timer, shi = [];
function Projectdistrtion() {

    const [projectList, setProjectList] = useState([]);

    const projectIdPermission = React.$getStorage('loginInfo').projectIdPermission;

    const [newsType, setNewsType] = useState(false); // 新闻类型

    const [permission, setPermission] =  useState([]);
    const [projectNes, setProjectNes] = useState([]);
    const [projectMsg, setProjectMsg] = useState({});
    const [videoList, setVideoList] =  useState([]);
    const [active, setActive] =  useState(0);
    const [showDatail, setShowDatail] = useState({});
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [videoParams, setVideoParams] = useState({})



    useEffect(() => {

        getNews();
        getProjectList();
        getProjectAll();

        // console.log(projectIdPermission)
        timerTask();
        return () => {
            timerTask(true)
        }
    }, []);

    const timerTask = (status) => {
        if(status) {
            clearInterval(timer);
            return
        } else {
            clearInterval(timer);
        }

        timer = setInterval(() => {
            index ++;
            if(index > length -1) {
                index = 0;
            }
             setActive(index);
        }, 30000)
    }
 

    const getProjectAll = () => {
        React.$axios.post('projectAll', {logic:3, projectIdPermission: projectIdPermission}).then(res => {
            
            if(!res.data.project) {
                return;
            }

            res.data.project.map(item => {
                item.shi.map(item2 => {

                    try {
                        item2.accessToken = res.data.applicationShi[item2.applicationId].accessToken
                        item2.status = true;
                    } catch(e) {
                        console.log(item)
                    }
                })

                // 数组长度补零
                let ArrayLenth = Array(9 - item.shi.length).fill(null).map(() => ({status: false}))

                item.shi = [...item.shi, ...ArrayLenth];

            })
         
            console.log(res.data.project)            
            length = res.data.project.length;
            setVideoList(res.data.project);
        })
    }

    // 获取项目列表
    const getProjectList = ()=> {

        React.$axios.post('enterpriseHomeStatistics', {logic: 1, projectIdPermission:projectIdPermission}).then(res => {
                setProjectList(res.data.engineeringProgress);
                setProjectMsg({
                    annualOutputValuePlan: res.data.annualOutputValuePlan,
                    completeAnnualOutputValue: res.data.completeAnnualOutputValue,
                    projectsUnderConstruction: res.data.projectsUnderConstruction
                })
        })

    }

    // 获取项目列表
    const getNews = ()=> {
        React.$axios.post('newsListEPA', {logic: 0, projectIdPermission: projectIdPermission}).then(res => {
            setPermission(res.data.permission);
            setProjectNes(res.data.project);
            // console.log(res)
            // setProjectList(res.data.engineeringProgress);
        })
    }

    //点击视频符号
    const clickVideo = (item)=> {
        setIsVisible(true)
        timerTask(true)
        setActive(-1)
        setVideoParams(item)
    }

    const handleOk = () => {
        setIsVisible(false)
        setActive(index)
        timerTask()
    }

    const toNews = (item, type) => {
        if(type == 'url' ) {
            window.open(item.url)
            return 
        }

        setIsModalVisible2(true)
        setShowDatail(item)
    }

    const changeNews = (status) => {
        console.log(status)
        setNewsType(status)
    }

    const handleCancel = () => {
        setIsModalVisible2(false)
    }

    // 


    const onmouseover = () => {
        timerTask(true)
    }

    const onmouseout = () => {
        timerTask()
    }

    const pove = () => {
        index ++;
        if(index > length -1) {
            index = 0;
        }
        setActive(index);

        timerTask();
    }
 



  return (
     
    <div className='Projectdistrtion2'> 

    


        <div className="videoBox" style={{top: window.innerHeight - 380, }}>

            {/* <Carousel afterChange={afterChange}> */}
            
            <div className="moveBox" onMouseOver={onmouseover} onMouseOut={onmouseout} style={{transform: `translate(${active * -100}%,0px)`, transitionDuration: `${active == 0? 0: 2}s`}}>    

                {
                    videoList.map((item, index) => {

                        return (
                            <ul key={index}>
                                <div className="positionText">
                                    {item.name}    <button className="pove" onClick={pove}>下一个</button>  
                                </div>

                                <li className="control">
                                    {
                                        active == index? 
                                        <div className="position" >
                                            {
                                                item.shi[0].status?
                                                <VideoComponentHH clickFun={clickVideo} width="100%" height="100%"  index={index + 'active'} videoDetail = { item.shi[0]} />
                                                :''
                                            }
                                        </div>:
                                        <div className="position">
                                            <img src={item.shi[0].coverImg} width="100%" height="100%" alt="" />
                                        </div>
                                    } 



                                    {
                                        
                                        active == index? item.shi.map((itme2, index2) => {
                                            if(index2 != 0) {
                                                if(itme2.status == true) {
                                                    return (
                                                        <div className="video">
                                                            <VideoComponentHH clickFun={clickVideo} width="100%" height="100%"  index={index2 + 'allActive'} videoDetail = {itme2} />
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="video nocolor"></div> 
                                                    )
                                                }


                                               
                                            }
                                           
                                        }): ''
                                    }
                                   
                                </li>
                        </ul>
                        )
                    })
                }

            </div>

             
            {/* </Carousel>  */}

            
        </div>

        <div className="newsBox">

            <div className="projectMsg">

                <div>土木工程事业部在建项目 <span>{projectMsg.projectsUnderConstruction}</span>  个</div> 
                <div>年产值计划 <span>{projectMsg.annualOutputValuePlan}</span> </div>
                <div>截止目前完成产值 <span>{projectMsg.completeAnnualOutputValue}</span> </div>

            </div>
            <div className="news">
                <h1>新闻动态</h1>
                {/* <div class="posbtn">
                    <button onClick={() => {changeNews(false)} }>新闻一</button>
                    <button onClick={() => {changeNews(true)}}>新闻二</button>
                </div> */}


                    <Carousel  autoplay>
                        
                    {
                        permission.map((item, index2) => {
                            return (
                                <div key={index2} className="CarouselBox" onClick={(e) => {toNews(item, 'url')} }>
                                    <img src={item.thumb_url} alt="" />
                                    <p>【事业部】{item.title}</p>
                                </div>
                            )
                        })
                    }
                    {
                        projectNes.map((item, index2) => {
                            return (
                                <div key={index2} className="CarouselBox" onClick={(e) => {toNews(item, 'text')} }>
                                    <img src={ imgURL + item.cover} alt="" />
                                    <p>【项目部】{item.title}</p>
                                </div>
                            )
                        })
                    }



                    </Carousel>
             
                

            </div>
            <div className="projectPrcess">
                <h1>工程进展</h1>

                <ul>
                    {
                        projectList.map((item, index) => {

                            return (
                                <li key={index}>
                                    <div className="projectName">
                                        <img src={img1} alt="" />
                                        {item.name}
                                    </div> 
            
                                    <div className="process">
                                        <div className="gay"></div>
                                        <div className="green" style={{width: item.percentage + '%'}}></div>
                                        <div className="rund" style={{left: item.percentage>88? 88.5+'%' :item.percentage + '%'}}>
                                            {item.percentage}%
                                        </div>
                                    </div>
                                </li>
                            )

                        })
                    }

                   
                    {/* <li >
                        <div className="projectName">
                            <img src={img1} alt="" />
                            滇中引水石鼓水源工程
                        </div> 

                        <div className="process">
                            <div className="gay"></div>
                            <div className="green"></div>
                            <div className="rund">
                                30.3%
                            </div>
                        </div>
                    </li> */}
                </ul>
            </div>


        </div>



        <Modal title={showDatail.title} footer={null} className='newsListModal' width="60%"  visible={isModalVisible2} onOk={handleCancel} onCancel={handleCancel}>
            <div className="setHeight" dangerouslySetInnerHTML={{__html: showDatail.content}}>
                    
            </div>
        </Modal>

        {
             function () {

                if(isVisible) {

                    return (
                        <Modal visible={isVisible} width="1400px"  className='VideoComponentHHModal' handleOk={handleOk} onCancel={handleOk}  footer={null}>
                                   <div className="deleVideo">
                                        <p className="setP">
                                                    <VideoComponentHH show={false}  width="1400px" height="800px" type="hd" index={'hh962'} videoDetail = {videoParams} />
                                        </p>
                                    </div>             
                        </Modal>
                    )


                } else {

                }


             }() 
         }            






    </div >
  )
}

export default withRouter(Projectdistrtion);