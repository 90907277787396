import React from "react";

import { Input, Button, message, Modal} from 'antd';
import './panoramamang.less';
import {formatTime} from '../../../utils/util';


//  import { Uploadhh } from "@/components/upload/upload";
import {Uploadhh} from "../../../components/upload/upload";

export default class Home extends React.Component {
    imgUrl = process.env.REACT_APP_IMG_URL
    state ={
        img: '',
        imgList: [],
        loading: false,
        visible: false,
        loading2: false,
        index: 0,
        params: {
            lengthLimit: 30,
            offsetLimit: 0,
            logic: 2,
            order: {
                time: 'ASC'
            } 
        },
        saveParams: {
            name: '',
            originalUrl: '',
            image: '',
            id: '',
            weigh: ''
        },
        editParams: {
            name: '',
            originalUrl: '',
            image: '',
            id: '',
            weigh: ''
        }
    }

    componentDidMount() {
        // for(let i = 0; i < 30; i++) {
        //     this.state.imgList.push({})
        // }

        // this.setState({
        //     imgList: this.state.imgList
        // })

        

        this.getList()
    }



    getList(type) {

        if(type) {
            this.state.params.offsetLimit = 0
            this.setState({
                params: this.state.params
            })
        }
    
        this.setState({ loading: true });
        React.$axios.post('planList', this.state.params).then(({ data }) => {
            if(this.state.params.offsetLimit == 0) {
                this.state.imgList = data.list
            } else {
                this.state.imgList = [...this.state.imgList, ...data.list]
            }  
    
            this.setState({
            loading: false,
            imgList: this.state.imgList,
            total: data.countList,
            })
    
        })
    
      }

    
    setInput(name, {target:{value}}) {
        this.state.saveParams[name] = value
        this.setState({
            saveParams: this.state.saveParams
        })

    }


    setInput2(name, {target:{value}}) {
        this.state.editParams[name] = value
        this.setState({
            editParams: this.state.editParams
        })

    }

    removeImg(item,index) {
        let params = {
            id: item.id,
            name: item.name,
            originalUrl: item.originalUrl,
            image: item.image,
            del: 2
        }

        React.$axios.post('planEditSave', params).then(res => {
            if(res.code == 20000) {
                message.success('删除成功');
                this.state.imgList.splice(index, 1)
                this.setState({
                    imgList: this.state.imgList
                })
            }
        })
    }


    toEdit(item, index) {

        console.log(item)
        
        for(let i in this.state.editParams) {
            this.state.editParams[i] = item[i]
        }

        this.setState({
            visible: true,
            editParams: this.state.editParams,
            index: index
        })
    }


    toSave() { // 去保存
        console.log(this.state.saveParams)

        if(this.state.saveParams.name == '') {
            message.error('请输入标题');
            return
        } else if(this.state.saveParams.url == '') {
            message.error('请输入地址');
            return
        } else if(this.state.saveParams.image == '') {
            message.error('请选择封面');
            return
        }

       
        React.$axios.post('planEditSave', this.state.saveParams).then(res => {
            if(res.code == 20000) {
                for(let i in this.state.saveParams) {
                    this.state.saveParams[i] = ''
                }
        
                this.setState({
                    saveParams: this.state.saveParams
                })

                this.getList(true)
            }


        })

        
    }


    handleFront(e) {
        this.state.saveParams.image = e.data.key[0]
        this.setState({
            saveParams: this.state.saveParams
        })

        return e.data.key[0]
    }

    handleFront2(e) {
        this.state.editParams.image = e.data.key[0]
        this.setState({
            editParams: this.state.editParams
        })

        return e.data.key[0]
    }

    handleOk() {
        React.$axios.post('planEditSave', this.state.editParams).then(res => {
            if(res.code == 20000) {
                
                message.success('保存成功');
                
                for(let i in this.state.editParams) {
                    this.state.imgList[this.state.index][i] = this.state.editParams[i]
                }

                this.setState({
                    visible: false,
                    imgList: this.state.imgList
                }) 
            }


        })

    }

    toPath(item) {
        window.open(item.originalUrl)
    }

    handleCancel() {
    
        this.setState({
            visible: false
        })
    }

    onScroll(value) {
        let dom = value

        if(dom.target.scrollTop + dom.target.clientHeight >= dom.target.scrollHeight) {
        this.state.params.offsetLimit += 30

        if(this.state.params.offsetLimit >= this.state.total) {
            message.success('没有更多数据了')
        } else {
            this.getList()
        }
        }
    }



  render() {
    return (
      <div className="panorama" onScroll={this.onScroll.bind(this)}>
        
        <div className="panoramaTitle">
            <h1>全景添加</h1>

            <div className="paddingTop"></div>
            <div className="flex">
                <div className="inputWarp inptW2">
                    <ul>
                        <li>
                            <div className="labelName">标题:</div>
                            <Input placeholder="请输入标题"  value={this.state.saveParams.name}  onChange={this.setInput.bind(this, 'name')}  />

                        </li>

                        <li>

                        <div className="labelName">链接:</div>
                        <Input placeholder="请输入720地址" value={this.state.saveParams.originalUrl} onChange={this.setInput.bind(this, 'originalUrl')}  />
                        </li>


                        <li>
                            <div className="labelName">权重:</div>
                                <Input placeholder="请输入权重" value={this.state.saveParams.weigh} onChange={this.setInput.bind(this, 'weigh')}  />
                        </li>

                    </ul>
                    
                </div>

                <div className="inputWarp">
                    <ul>
                        <li>
                            <div className="labelName">封面:</div>
                            <div>
                                <Uploadhh  imageUrl={this.state.saveParams.image}   width={400} height={200}  handleClick={this.handleFront.bind(this)} />
                            </div>
                        </li>

                    
                        <li className="setBtn">
                            <Button type="primary" onClick={this.toSave.bind(this)}  className="btn">提交</Button>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

        <div className="panoramaTitle first2">
            <h1>全景管理</h1>

            <div className="paddingTop"></div>

            <ul className="imgList">

                {
                    this.state.imgList.map((item,index) => {

                        return (
                            <li>
                                <div className="img" onClick={this.toPath.bind(this, item)}>
                                 <img src={this.imgUrl + item.image} alt=""/>
                                </div>
            
                                <div className="title">
                                <p>{item.name}</p>
                                <p className="time">{formatTime(new Date(item.time*1000), 'yyyy年MM月dd日')}</p>
                                </div>
            
                                <div className="edit">
                                    <p className="textp" onClick={this.toEdit.bind(this, item, index)}>编辑</p>
                                    <p className="shu"></p>
                                    <p className="textp pColor1" onClick={this.removeImg.bind(this, item, index)}>删除</p>
                                </div>
                            </li>
                        )
                    })
                }

               
            </ul>
        
        </div>


        <Modal
          visible={this.state.visible}
          title="编辑"
          width="800px"
          onOk={this.handleOk}
          onCancel={this.handleCancel.bind(this)}
          footer={[
            <Button key="back" onClick={this.handleCancel.bind(this)}>
              关闭
            </Button>,
            <Button key="submit" type="primary"  onClick={this.handleOk.bind(this)}>
              保存
            </Button>,
          ]}
        >

        <div className="panoramaTitle">

            <div className="inputWarp2">
                <ul>
                    <li>
                        <div className="labelName">标题:</div>
                        <Input placeholder="请输入标题"  value={this.state.editParams.name}  onChange={this.setInput2.bind(this, 'name')}  />

                    </li>

                    <li>

                    <div className="labelName">链接:</div>
                    <Input placeholder="请输入720地址" value={this.state.editParams.originalUrl} onChange={this.setInput2.bind(this, 'originalUrl')}  />
                    </li>

                    <li>

                        <div className="labelName">权重:</div>
                        <Input placeholder="请输入权重" value={this.state.editParams.weigh} onChange={this.setInput2.bind(this, 'weigh')}  />
                        </li>

                    

                </ul>
                
            </div>

            <div className="inputWarp2">
                <ul>
                    <li>
                        <div className="labelName">封面:</div>
                        <div>
                            <Uploadhh  imageUrl={this.state.editParams.image}   width={400} height={200}  handleClick={this.handleFront2.bind(this)} />

                            {/* <Uploadhh  imageUrl={this.state.editParams.image} path = "fileUploadOss" name="image[]" data={{type: 2}}   width={400} height={200}  handleClick={this.handleFront2.bind(this)} />
                           */}
                        </div>
                    </li>

                </ul>
            </div>

        </div>
          
        </Modal>

      </div>
    );
  }
}
