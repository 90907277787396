import * as echarts from 'echarts';



export function main2(data) {

    if(!data) return;

    var myChart = echarts.init(document.getElementById('main3'));
    let arr = []
    let name = []
    for(let i in data) {
        name.push(i);
        arr.push(data[i])
    }
    
 

    let option = {
            color: ['#567BFB'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  label: {
                    backgroundColor: '#6a7985'
                  }
                }
              },
            grid: {
              left: '3%',
              right: '30px',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
                type: 'category',
                data: name,
                axisLine: {
                    lineStyle: {
                      type: 'dashed',
                       color: '#7F7F7F',
                      width: '1'
                    }
                  }
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle:{
                        type:'dashed'
                    }
                },
            },
            series: [
              {
                name: '兑换次数',
                type: 'line',
                stack: '总量',
                itemStyle : {
                    normal : {
                        color:'#004EA2',
                            lineStyle:{
                                color:'#004EA2'
                            }
                        },
                },
                areaStyle: {
                    normal:{
                        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ 
             
                             offset: 0.2,
                             color: '#86A2FF'
                         }, {
                             offset: .6,
                             color: '#BECDFF'
                         },{
                             offset: 1,
                             color: '#ffffff'
                         }])
                     }
                },
                emphasis: {
                  focus: 'series'
                },
                data: arr,
              }
            ]
    };
    
    
    myChart.setOption(option);
  }