import React, { useState, useEffect } from "react";
// import { Layout, Carousel } from 'antd';
import { withRouter } from "react-router-dom";
import { Table, Modal, Select, Input, Empty, Checkbox, message, Popconfirm  } from 'antd';
import XLSX from 'xlsx';
import './QuestionBank.less';

import { formatTime } from '@/utils/util.js';

import { VerticalAlignTopOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';

import icon from '@/assets/img/newReviseImg/imgAndIcon/新建.png';
import icon2 from '@/assets/img/newReviseImg/imgAndIcon/题库.png';

import { Uploadhh } from "@/components/upload/upload";
import download from "downloadjs";



const { Option } = Select;

function HomeRouter() {
    let exportStatus = false;
 
    const columns = [
        { title: '题库名称', dataIndex: 'name', render:(_) => {
            
            return (
                <div className="firstDiv"> <img src={icon2} alt="" /> {_}</div> 
            )
        }},
        
        { title: '分类', align:'center', dataIndex: 'type',  render:(_) => {
            return (
               <p>{_==2?'公司':_==3?'项目部':'施工现场'}</p>   
            )
        } },
        { title: '试卷数量', width: '60%', align:'left', dataIndex: 'paperQuantity',  },
        {
          title: '操作',
          dataIndex: 'id',
          align:'center',
          width: 150,

          render: (_, item) => {
              return (
                <ul className="editUl">
                     <li style={{position: 'absolute'}}></li>
                    <li onClick={() => {editQuestion(item)}}>编辑</li>
                    <Popconfirm
                        title="是否需要删除"
                        onConfirm={() => {confirmDetail(item)}}
                        okText="Yes"
                        cancelText="No"
                    ><li>删除</li></Popconfirm>
                    
                </ul> 
              )
          },
        },
    ];



const [active, setActive] = useState(0);
const [childrenList, setChildrenList] = useState([]);
const [isvisiable, setIsvisiable] = useState(false);
const [isvisiable2, setIsvisiable2] = useState(false); // 题库编辑
const [exportPage, setExportPage] = useState(0); // 当前展开页
const [isvisiable3, setIsvisiable3] = useState(false);

const [questionsMany, setQuestionsMany] = useState([]); // 返回的全部题目数组
const [qrCode, setQrCode] = useState('');
const [qrName, setQrName] = useState('');

// 题库名称
const [QuestionName, setQuestionName] =  useState('');
const [QuestionParams,setQuestionParams] = useState({
    name: '',
    id: null
});
 
 // 纸张名称
 const [inputValue, setInputValue] = useState('');
 // 题库名称
 const [tiku, setTiku] = useState(null);
 const [recommend, setRecommend] =  useState(false);

 const [List, setList] = useState([]);
 const [countParams, setCountParams] = useState({
    subject: 0,
    fraction: 0
 });


 // 题库分类
 const [QuestionTypeBtn, setQuestionTypeBtn] = useState(false);


 const [listEducationParams, setListEducationParams] = useState({
    logic: 3,
    type: 2,
    base: '',
 })


 const [listEducationList, setListEducationList] = useState([]);

 // 选择列表
 const [selectListEducationList, setSelectListEducationList] =  useState([]);
 
 const [sbmitParams, setSbmitParams] = useState({
    logic: 1,
    file: '',
    paper: { // 试卷
        name: '',
        id: null,
        recommend: false
    },
    bank: {
        id: null,
        type: null,
        name: ''
    }
 });


 const accessLogin =   React.$getStorage('accessLogin')
 const url = process.env.REACT_APP_BASE_URL

  useEffect(() => {
    listEducationQuestionBank(listEducationParams)
  }, []);

  // 删除题库
  const confirmDetail = (item) => { 
    React.$axios.post('saveEducationQuestionBank', {id: item.id, del: 2}).then(res => {
        if(res.code == 20000) {

            // 刷新题库列表
            listEducationQuestionBank(listEducationParams)
            message.success('删除成功')
        }
    })
  }

  // 删除试卷
  const confirmDetailPaper = (item, index) => {
    console.log(item, index)

    React.$axios.post('saveEducationTestPaper', {id: item.id, del: 2, bankId: item.bankId}).then(res => {
        if(res.code == 20000) {
            // 重新刷新试卷列表
            listEducationTestPaper(listEducationList[index])
            message.success('删除成功')
        }
    })

  }


  // 编辑题库
//   console.log(editQuestion)
  const editQuestion = (item) => {
    setQuestionName(item.name)
    QuestionParams.id = item.id;
    setIsvisiable2(true)
  }

  // 去保存题库
  const toSaveQuestion = () => {
    QuestionParams.name = QuestionName;
    React.$axios.post('saveEducationQuestionBank', QuestionParams).then(res => {
        if(res.code == 20000) {
            message.success('保存成功')
            handleCancel();
            listEducationQuestionBank(listEducationParams)
        }
    }).catch((err) => {
        message.error(err.message)
    })
  }

  // 获取二维码
  const getPaperCode = (item) => {
    let params = {
        paperId: item.id
    }
    React.$axios.post('saveEducationExamPaper', params).then(res => {
          setQrCode(res.data.qrCode)
          setQrName(item.name)
          setIsvisiable3(true)
    })
  }




  // 获取题库列表
  const  listEducationQuestionBank = (params,  callback) => {
    React.$axios.post('listEducationQuestionBank', params).then(res => {

        // 如果有回调 执行回调
        if(callback) {
            callback(res.data.list)
        } else {
            res.data.list.map((item, index) => {
                item.key = index+1
                childrenList.push([]);
            })

            setChildrenList(childrenList);
            setListEducationList(res.data.list)
        }
    })
  }



 // 去保存
  const toSave = () => {

    let id = sbmitParams.bank.id;

    if(QuestionTypeBtn) { // 新增题库
        sbmitParams.bank.name = QuestionName;
        sbmitParams.bank.id = '';
    }


    if(!sbmitParams.file && !sbmitParams.paper.id) {
        message.error('没有上传文件');
        return
    }

    const formData = new FormData();
    
    for(let i in sbmitParams) {
       
        if(i == 'file') {
            formData.append(i, sbmitParams[i])
        }else {
            let params =  JSON.stringify(sbmitParams[i])
            formData.append(i, params)
        }
    } 

    fetch(url + 'saveEducationTestQuestions', {
        method: 'POST',
        body: formData,
        headers: {
            accessLogin: accessLogin,
            accesstoken:'$2y$10$z.0Fz2z0r9XKJ5M3.TowhOEzBGjQndjo2hijvAZQmdLjztiJkbUZu',
        }
    }).then(res => {
         res.json().then(res => {
            if(res.code == 20000) {
                message.success('保存成功')
                // 保存刷新当前行 不存在则刷新列表
                if(id) {
                    
                } else {
                    listEducationQuestionBank(listEducationParams)
                }

                

                // 匹配当前展开页  更新
                for(let i = 0; i < listEducationList.length; i++) {
                    if(listEducationList[i].id == tiku) {
                        let currnetList =  listEducationList[i]
                        listEducationTestPaper(currnetList)
                        listEducationTestPaper(listEducationList[exportPage])
                        break;
                        return;  
                    }
                }
            }
        })
 
        handleCancel();
    }).catch(e => {
        message.error(e.msg)
    })
  }

  
  // 获取试卷页表
  const listEducationTestPaper = (item) => {
        let params = {
            logic: 3,
            bankId: item.id
        }
        
    
        React.$axios.post('listEducationTestPaper', params).then(res => {
            item.childrenList =  res.data.list;
            setChildrenList([...childrenList]);
        })

   
  }


  const handleCancel = () => {
    setIsvisiable(false)
    setIsvisiable2(false)
    setIsvisiable3(false)
  }

  const beforeFun = (file) => { // 获取文件信息
    let reader = new FileReader()
    sbmitParams.file = file;
    reader.readAsBinaryString(file) //读取这个文件
    reader.onload = function (event) {
        try {
            let result = event.target.result
            let xlsxdata = XLSX.read(result, { type: 'binary' })//读取xlsx

            for (let n in xlsxdata.Sheets) {//这里是多张表格 所以写一个循环
                let col = XLSX.utils.sheet_to_json(xlsxdata.Sheets[n], { header: 1, defval: '', blankrows: true })//解析为数组
                let fraction = 0;
                
                for(let i = 1; i < col.length; i++) {
                    let item = col[i];
                    
                    fraction += item[item.length-1];
                }

                let params = {
                    subject: col.length - 1,
                    fraction: fraction
                }

                setCountParams(params)
                setList(col)
            }
        } catch (err) {

        }
    }

    return false
  }

  const showModal = () => {
    setQuestionName(''); // 题库名称
    setRecommend(false) // 设置推荐
    setInputValue(''); // 试卷名称
    setSelectListEducationList([]) // 题库列表
    setQuestionTypeBtn(false) // 新建 和 选择的弹框
    setTiku(null) // 题库值
    setList([])
    setSbmitParams({
            logic: 1,
            file: '',
            paper: { // 试卷
                name: '',
                id: null,
                recommend: false
            },
            bank: {
                id: null,
                type: null,
                name: ''
     }})

    setIsvisiable(true) // 显示弹框
  }

  // 点击试卷编辑
  const paperEdit = (item, recordName, index2) => {
    let params = {
        logic: 4,
        id: item.id 
    }

    setExportPage(index2)

    React.$axios.post('listEducationTestPaper', params).then(res => {
            setQuestionName(''); // 题库名称
            setRecommend(item.recommend ==2? true: false) // 设置推荐
            setInputValue(item.name); // 试卷名称
            onChangeType(item.type, true);
            setQuestionTypeBtn(false) // 新建 和 选择的弹框
            setTiku(item.bankId) // 题库值    
             setSbmitParams({
                    logic: 2,
                    file: '',
                    paper: { // 试卷
                        name: item.name,
                        id: item.id,
                        recommend: item.recommend
                    },
                    bank: {
                        id: item.bankId,
                        type: `${item.type}`,
                        name: recordName,
            }})
            
           let data = res.data.info.questionsMany;
    
           let arr = [[]]
           data.map((item2, index) => {
            let optionsMany =  item2.optionsMany;
            let answer = ''
            let answerType = item2.answerType == 2? '单选': item2.answerType == 3?"多选":'判断'
            for(let i = 0; i < optionsMany.length; i++) {
                if(optionsMany[i].answer == 2) {
                    if(optionsMany.length == i) {
                        answer += optionsMany[i].nameEn 
                    } else {
                        answer += optionsMany[i].nameEn + ','
                    }
                    
                }
            }
                           //序号     题目         提示                 A                   //B                   
            let curent = [index+1, item2.name, item2.prompt, optionsMany[0].name, optionsMany[1].name,
                //  c                                     //D                                       //E
            optionsMany[2]?optionsMany[2].name:'',   optionsMany[3]?optionsMany[3].name:'', optionsMany[4]?optionsMany[4].name:'',
              // 正确d答案  // 答案类型        // 分数
              answer,       answerType,     item2.fraction
            ]    
            arr.push(curent)
           }) 

           setList(arr)
           let params = {
                subject: item.topicQuantity,
                fraction: item.totalScore
            }

        setCountParams(params)
           setIsvisiable(true)
    })

  }



  // 题库分类  status 判断要不要更新列表
  const onChangeType = (e, status) => {
    sbmitParams.bank.type = e;
    setSbmitParams(sbmitParams)  
      listEducationQuestionBank({logic: 3,
        type: e}, (res) => {
            setSelectListEducationList(res);
            if(status) {  

            }else {
                setTiku();
                return
            }
            
        })
  }

  // 获取题库分类数据
  const onChangeBankName = (e, item) => {
    sbmitParams.bank.id = e;  
    sbmitParams.bank.name = item.item.name;
    setTiku(e);
  }

  // 下载图片
  const downloadQrcode = async(e) => {

    try {
        let currnet = url + qrCode
        console.log(currnet)
        let response = await fetch(currnet, {
            mode: 'no-cors'
        })
        // 内容转变成blob地址
        let blob = await response.blob()
        // 创建隐藏的可下载链接
        let objectUrl = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        //地址
        a.href = objectUrl
        //修改文件名
        a.download = qrCode
        // 触发点击
        document.body.appendChild(a)
        a.click()
        //移除
        setTimeout(() => document.body.removeChild(a), 1000)
    } catch (e){
        console.log(e)
    }
    
  }


  return (
    <div className="EducationQuestionBank">
        <div className="left">
           {/* <div></div>  */}
           <div className="title" onClick={showModal}>
               <img src={icon} alt="" />
               新建题库</div>
           <ul>
               <li onClick={() => {
                   setActive(0)
                   listEducationParams.type = 2
                   listEducationQuestionBank(listEducationParams)
                   }} className={active == 0? 'active': ''}>公司</li>
               <li onClick={() => {setActive(1)
                    listEducationParams.type = 3
                    listEducationQuestionBank(listEducationParams)}} className={active == 1? 'active': ''}>项目部</li>
               <li onClick={() => {setActive(2)
                listEducationParams.type = 4
                listEducationQuestionBank(listEducationParams)}} className={active == 2? 'active': ''}>施工班组</li>
           </ul>
        </div>

        

        <div className="right">
        <Table
            columns={columns}
            expandable={{
            onExpand: (expanded, record) => {
                if(expanded) {
                    listEducationTestPaper(record)
                }
            },   
            expandedRowRender: (record, index2) => {
                if(!record.childrenList) {
                    record.childrenList = []
                }
                
                return (
                    <table className="currentTable">
                    <thead>
                        <tr>
                        <th className="firstTh">试卷名称</th>
                        <th>分类</th>
                        <th>题目数量</th>
                        <th>总分</th>
                        <th>编辑日期</th>
                        <th>编辑人</th>
                        <th style={{textAlign: 'center'}}>操作</th>  
                        </tr>
                    </thead>
    
                    <tbody>
                       {
                          record.childrenList.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="firstTh">
                                     {item.name}
                                    </td>
                                    <td>
                                        {item.type == 2? '公司': item.type == 3?'项目部': '施工班组'}
                                    </td>
                                    <td>
                                        {item.topicQuantity}
                                    </td>
                                    <td>
                                        {item.totalScore}
                                    </td>
                                    <td>
                                       {item.editTime != 0 ?formatTime(new Date(item.editTime * 1000), 'yyyy/MM/dd'): '-'}
                                       
                                    </td>
                                    <td>
                                        {item.userName}
                                    </td>
                                    <td style={{width: 250}}>
                                        <ul>
                                            <li onClick={(e) => {getPaperCode(item)}}>发起考试</li>
                                            <li onClick={(e) => {paperEdit(item, record.name, index2)}}>编辑</li>
                                            <Popconfirm
                                                title="是否需要删除"
                                                onConfirm={() => {confirmDetailPaper(item, index2)}}
                                                okText="Yes"
                                                cancelText="No"
                                            ><li>删除</li></Popconfirm>
                                        </ul>
                                    </td>
                                </tr> 
                            )
                          })
                       }
                    </tbody> 
    
                </table>   
                )

               
            },
            rowExpandable: record => record.name !== 'Not Expandable',
            }}
            dataSource={listEducationList}
        />  

        </div>

        <Modal
            title={<h1 className="QuestionBankModalTitle"><img src={icon} alt="" /> 新建题库</h1>}
            visible={isvisiable}
            width="80%"
            footer ={null}
            // onOk={handleOk}
            // confirmLoading={confirmLoading}
            onCancel={handleCancel}
        >
            
            <div className="QuestionBankModalBody">
            
                <div className="head">
                    <div className="from">
                        <ul>
                            <li><span>试卷名称</span>  <Input id="1" value={inputValue} className="input"
                             onChange={e => {
                                sbmitParams.paper.name =  e.target.value;
                                setInputValue(e.target.value);
                    }} type="text" placeholder="请输入" /></li>
                            <li><span>题库分类</span>
                            <Select
                                    showSearch
                                    placeholder="请选择"
                                    className="input"
                                    onChange={(e) => {onChangeType(e)}}
                                    value={sbmitParams.bank.type}

                                >
                                    <Option value="2">公司</Option>
                                    <Option value="3">项目部</Option>
                                    <Option value="4">施工班级</Option>
                                </Select>  
                            {/* <Input className="input" onChange={(e) => {onChange(e, ['paper', 'name'])}} type="text" placeholder="请输入" /> */}
                            </li>
                        </ul>
                    </div>

                    <div className="from TwoFrom">
                        <ul>
                            <li><span>设置推荐</span>  <div><Checkbox checked={recommend} onChange={
                                (e) => {
                                    setRecommend(e.target.checked)
                                    sbmitParams.paper.recommend = e.target.checked?2:3;
                                }
                            }></Checkbox> </div></li>
                            <li><span>题库名称</span> <Select
                                    showSearch
                                    placeholder="请选择"
                                    className="input"
                                    onChange={onChangeBankName}
                                    value={tiku}
                                    style={{display:QuestionTypeBtn?'none':''}}
                                >
                                    {
                                        selectListEducationList.map((item, index) => {
                                            return (
                                                <Option key={index} item={item} value={item.id}>{item.name}</Option>
                                            )
                                        })
                                    }
                                </Select>  
                                <Input style={{display:QuestionTypeBtn?'':'none'}} value={QuestionName} onChange={
                                    (e) => {
                                        setQuestionName(e.target.value);
                                    }}

                                className="input" type="text" placeholder="请输入" />
                                <button onClick={() => {
                                    setQuestionTypeBtn(!QuestionTypeBtn)
                                }}>{QuestionTypeBtn?'取消新建':'新建'} </button></li>

                        </ul>
                    </div>

                    {/* <div className="tips">

                        已导入题目数量： <span>25</span>  合计分数：<span>100</span>
                        </div> */}

                    <div className="sbmitBtn">
                        <div className="warp">
                            <div>
                            <a download href={url + "static/excel/import/试题导入模板.xlsx"}>
                            <VerticalAlignBottomOutlined  /> 
                            <p>下载题库模板</p> 
                            </a> 
                            {/* beforeFun={this.beforeFun.bind(this)}    handleClick={this.handleFront.bind(this)}  */}
                            
                            </div>
                        </div>
                        <div className="warp">
                            <div>
                            <VerticalAlignTopOutlined  />

                            <Uploadhh className="Uploadhh" beforeFun={beforeFun}   pdfStatus={true}  name="file" data={{type: 68, logic: 3}}  width={160} height={75} />
                            <p>上传题库</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table">
                    <table>
                        <thead>
                            <tr>
                            <th className="firstTh">序号</th>
                            <th>题目</th>
                            <th>提示</th>
                            <th>选项</th>
                            <th>正确答案</th>
                            <th>题型</th>
                            <th>分值</th>  
                            </tr>
                        </thead>
                        <tbody>

                            {
                                List.map((item, index) => {
                                    if(index > 0) {
                                        return (
                                            <tr key={index}>
                                                <td>{item[0]}</td>
                                                <td>{item[1]}</td>
                                                <td>{item[2]}</td>
                                                <td>
                                                    <ul>
                                                        <li><span style={{color: '#004EA2'}}>A.</span>{item[3]}</li>
                                                        <li><span style={{color: '#17C26C'}}>B.</span>{item[4]}</li>
                                                        <li style={{ display: item[5]?'':'none'}}><span style={{color:'#C71D4D'}}>C.</span>{item[5]}</li>
                                                        <li style={{ display: item[6]?'':'none'}}><span style={{color: '#FFAB34'}}>D.</span>{item[6]}</li>
                                                        <li style={{display: item[7]?'':'none'}}><span>D.</span>{item[7]}</li> 
                                                    </ul>
                                                </td>
                                                <td>{item[8]}</td>
                                                <td>{item[9]}</td>
                                                <td>{item[10]}</td>
                                            </tr> 
                                        )
                                    }
                                    
                                })
                            }

                        </tbody>

                        

                    </table>

                    <div className="noList" style={{display: List.length == 0?'':'none'}}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div> 
                </div>


                <div className="tips">
                        <div>
                        已导入题目数量： <span>{countParams.subject}</span>  合计分数：<span>{countParams.fraction}</span>
                        </div>    

                        <div>
                            仅支持批量导入修改
                        </div>
                        
                </div>


                <div className="foot">
                    <div onClick={toSave}>保存</div>
                    <div onClick={handleCancel}>取消</div>
                </div>

            </div>

        </Modal>



        <Modal
            title={<h1 className="QuestionBankModalTitle"><img src={icon} alt="" /> 编辑题库</h1>}
            visible={isvisiable2}
            width="30%"
            footer ={null}
            onCancel={handleCancel}
        >
          <div className="QuestionBankModalBody">
          <div className="head" >
                    <div className="from" style={{width: '100%'}}>
                        <ul>
                            <li style={{width: '100%'}}><span>题库名称</span>  <Input id="1" value={QuestionName} className="input"
                             onChange={e => {
                                setQuestionName(e.target.value);
                        }} type="text" placeholder="请输入" /></li>
                               
                            </ul>
                        </div>
                    </div>            
                            
                <div className="foot">
                    <div onClick={toSaveQuestion}>保存</div>
                    <div onClick={handleCancel}>取消</div>
                </div>          
          </div>  
        </Modal>


        <Modal
            title={<h1>i电建在线考试系统</h1>}
            visible={isvisiable3}
            width="400px"
            footer ={null}
            className="QuestionBankCode"
            onCancel={handleCancel}
        >
          <div className="QuestionBankModalBody">

                <div className="content">
                       <h2>试卷名称</h2>

                       <p>{qrName}</p>     


                        <img src={url + qrCode} alt="" />


                        <h3>微信扫一扫进入考试系统</h3>
                        <h4>本次生成二维码有效时间1天，请及时保存</h4>

                        {/* <a download href={url + qrCode}> */}
                        <button onClick={downloadQrcode}>下载二维码图片</button>
                        {/* </a> */}
                        
                </div>        

                            
                <div className="foot">
                    {/* <div onClick={toSaveQuestion}>保存</div> */}
                    {/* <div onClick={handleCancel}>取消</div> */}
                </div>          
          </div>  
        </Modal>
        

    </div>
  )
}

export default withRouter(HomeRouter);