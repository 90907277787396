import React, { useState, useEffect } from "react";

import {  withRouter } from "react-router-dom";
import * as Cesium from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import Header2 from "@/components/Header/Header";

import { Slider, message} from 'antd';

import {
    UnorderedListOutlined
  } from '@ant-design/icons';

import {Measure} from './Measure';

import './GIScenter.less';

import img1 from '@/assets/img/newReviseImg/imgAndIcon/celiang.png';
// import img2 from '@/assets/img/newReviseImg/imgAndIcon/剖面.svg';
import img3 from '@/assets/img/newReviseImg/imgAndIcon/mianji.png';
import img4 from '@/assets/img/newReviseImg/imgAndIcon/tiji.png';
import img5 from '@/assets/img/newReviseImg/imgAndIcon/xianshiquanbu.png';
import img6 from '@/assets/img/newReviseImg/imgAndIcon/manyou.png';
import img7 from '@/assets/img/newReviseImg/imgAndIcon/sanweiceliang.png'; 

import img8 from '@/assets/img/newReviseImg/imgAndIcon/yidongsudu.png'; // 移动速度


import img9 from '@/assets/img/newReviseImg/imgAndIcon/ziyoushijiao.png'; // 自由视角
import img10 from '@/assets/img/newReviseImg/imgAndIcon/ziyoushijiao2.png'; // 移动速度2

let measure = null;
let viewer = null;
let speed = 0;
function GIScenter() {
  let threeDom = null;
  const arr = [{
      img: img1,
      name: '距离测量',
      index: 1
  },
  ,{
    img: img7,
    name: '三维测量',
    index: 7
  },{
    img: img3,
    name: '面积测量',
    index: 2
  }, {
    img: img4,
    name: '体积测量',
    index: 3
  }, {
    img: img5,
    name: '还原',
    index: 4
  },
//    {
//     img: img6,
//     name: '漫游',
//     index: 5
//   },
];

  const arr2 = [
    {
        img: img9,
        name: '自由视角',
        index: 9
    },
    {
        img: img8,
        name: '移动速度',
        index: 8
    },
  ]  


  const [currentIndex, setCurrentIndex] = useState(0);
  const [projectActive, setProjectActive] = useState(0);


  const [projectList, setProjectList] = useState([]);
 
  let clampToGround = true;
    
  useEffect(() => {
    
    init()    

  }, []);


  const init = () => {
    
    viewer = new Cesium.Viewer('cesiumContainer', {
      terrainProvider: Cesium.createWorldTerrain(),
      animation:false,    //左下角的动画仪表盘
    baseLayerPicker:false,  //右上角的图层选择按钮
    geocoder:false,  //搜索框
    homeButton:false,  //home按钮
    sceneModePicker:false, //模式切换按钮
    timeline:false,    //底部的时间轴
    navigationHelpButton:false,  //右上角的帮助按钮，
    fullscreenButton:false,   //右下角的全屏按钮
    }); 

    viewer._cesiumWidget._creditContainer.style.display="none";  


    viewer.scene.globe.depthTestAgainstTerrain = false;

   
    measure = new Measure(viewer, Cesium);
    getList()
  }

  const getList= () => {
    React.$axios.post('infoProject').then(res => {
        
        if(!res.data.infoProject[0].gis) return;

        let arr = JSON.parse(res.data.infoProject[0].gis);
        arr.map(item => {
            item.tileset = null
        })

        let item = arr[0];
        let classificationTileset = new Cesium.Cesium3DTileset({
            url: item.url,
            dynamicScreenSpaceError: true,
            maximumScreenSpaceError: 6
    })

        var tilesets = viewer.scene.primitives.add(classificationTileset);
        // classificationTileset.style = new Cesium.Cesium3DTileStyle({
        //     color: "rgba(255, 0, 0, 0.5)",
        // });

        tilesets.readyPromise.then(function (tileset) {
            viewer.flyTo(tileset)
        })

        item.tileset = tilesets;
        setProjectList(arr);

    })
  }


    // 获取点击的样式
    const getItem = (item) => {
        
        if(item.index == currentIndex) {
            // setCurrentIndex(0)
            return;
        } else {
            if(currentIndex != 0) {
                message.error('请先清除上一步操作')
                return
            }


            setCurrentIndex(item.index)
        }

        if(item.index == 1) { // 测试距离
            console.log(measure)
            measure.drawLineMeasureGraphics({ clampToGround: clampToGround, callback: () => { 
                setCurrentIndex(0)
            }});
        } else if(item.index == 2) {// 测试面积
            // 测试面积
            measure.drawAreaMeasureGraphics({ clampToGround: clampToGround, callback: () => { 
                setCurrentIndex(0)

                // new $this.Cesium.Color(199 / 255,29 / 255, 76/ 255 , 1.0)
                // new $this.Cesium.Color(0 / 255,78 / 255, 161/ 255 , 1.0)
            } });
        } else if(item.index == 7) { // 三维测量
            measure.drawTrianglesMeasureGraphics({ callback: () => {
                setCurrentIndex(0)
             } });
        } else if(item.index == 4) { // 清除
            measure._drawLayer.entities.removeAll();
            setCurrentIndex(0)
        } else if(item.index == 5) {

            measure.FreePerspective({ callback: (e, movement) => {
                if(e) {
                    console.log(e, movement)
 

                     let x = e.x;
                     let y = e.y;
                     let z = e.z;

                     console.log(x, y, z)

                    viewer.camera.flyTo({
                        destination : Cesium.Cartesian3.fromDegrees(x, y, z)
                    });
                } else {
                    setCurrentIndex(0)
                }
             } });

        } else if(item.index == 3) { // 体积
            message.error('暂未开放');
            setCurrentIndex(0)
            // measure.drawVolumeMeasureGraphics({ callback: () => {
            //     // setCurrentIndex(0)
            //  } });
        } else if(item.index == 9) { // 自由视角
                measure.FreePerspective({ callback: (e) => {
                    if(e) {
                        let initialHeading = viewer.camera.heading;
                        var camera = viewer.camera;
                        camera.lookAt(e, new Cesium.HeadingPitchRange(Cesium.Math.toRadians(initialHeading), -30, 50));
                    } else {
                        viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
                        setCurrentIndex(0)
                    }
                 } });
        } else if(item.index == 8) { // 移动速度
            viewer.scene.screenSpaceCameraController.enableRotate = false;


            measure.lookFactor({callback: (e) => {
                viewer.scene.screenSpaceCameraController.enableRotate = true;
                setCurrentIndex(0)
            }})
        }
    }

    // 切换项目
    const changeProject = (item, index) => {
        if(item.tileset) {
            viewer.flyTo(item.tileset)
        } else {
            var tilesets = viewer.scene.primitives.add(new Cesium.Cesium3DTileset({
                url: item.url,
                maximumScreenSpaceError: 3
            }));
            tilesets.readyPromise.then(function (tileset) {
                viewer.flyTo(tileset)
            })

            item.tileset = tilesets;
            setProjectList(projectList);
        }

        setProjectActive(index);
    }


    const handleDisabledChange = (val) => {
        // console.log(val);
        measure.speed = val / 100;
    }



  return (
    <Header2 index={3}>
    <div className="GIScenter">
       <div id="cesiumContainer" ref={(e) => {threeDom = e}} className="threeRef">

       </div>
        <div className="selectFun">
            <ul>
                {
                    arr.map((item, index) => {
                        return (
                            <li className={currentIndex == item.index? 'active': ''} key={index} onClick={() => {getItem(item)}}>
                                <img src={item.img} alt="" />
                                <p>{item.name}</p>
                            </li>
                        )
                    })
                }

            </ul>   

            <ul className="ul2">
                <div className='range' style={{display: currentIndex == 8?'block':'none'}}>
                {/* disabled={disabled} */}
                 <Slider defaultValue={0} onChange={handleDisabledChange}  />
                </div>


               {
                  arr2.map((item, index) => {
                        return (
                            <li className={currentIndex == item.index? 'active': '' + ' '} key={index} onClick={() => {getItem(item)}}><img src={item.index == 9?currentIndex == 9?img10: item.img: item.img} alt="" />
                                <p>{item.name}</p>
                            </li>
                        )
                    }) 
               } 
            </ul>    
        </div>

        <div className="selectproject">
             <h2><UnorderedListOutlined /> 模型列表</h2> 
              <ul>
                {
                    projectList.map((item, index) => {
                        return (
                            <li className={projectActive == index? 'active': ''} onClick={() => {changeProject(item, index)} } key={index}>{item.name}</li>
                        )
                    })
                }
              </ul>  
        </div>

    </div>
</Header2>
  )
}

export default withRouter(GIScenter);