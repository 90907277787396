import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import './ProjectDistribution.less';

import img1 from '@/assets/img/videoReturning/1.png';
import img5 from '@/assets/img/earth/5.png';
import img6 from '@/assets/img/earth/6.png';
import img7 from '@/assets/img/earth/7.png';
import * as echarts from 'echarts';



function ProjectDistribution() {

    
    const [echartStatus, setEchartStatus] = useState(true)
    const [projectList, setProjectList] = useState([])

    useEffect(() => {

        // 设置图形
        setTimeout(() => {
            // setEchart()
            // setEchart2()
        }, 200)


        
        getProjectAll()

        
        return () => {
            
        }
    }, []);

    function getProjectAll() {
        console.log(111)

        React.$axiosNoLogin.post('projectAll', {logic: 3, notInId: [1,  5]}).then(res => {

            setProjectList(res.data.project)

        })

        // projectAll

        // logic: 3
        // notInId: [1, 4, 5]
    }


    // 设置图形
    function setEchart() {
        let chartDom = document.getElementById('barChart');
        let myChart  = echarts.init(chartDom);
        let option;


        option = {
            xAxis: {
                type: 'category',
                data: ['滇中引水石鼓水源工程', '滇中引水石大理1段2标', '郑州轨迹交通8号线', '成都轨迹交通19号线', '深圳轨迹交通12号线'],
                axisLabel: {  
                    interval:0,  
                    rotate:20
                }, 
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    show: false
                }
            },

            grid: {
                left: '1%',
                right: '4%',
                bottom: '20%',
                top: '10%',
                containLabel: true
            },

            series: [{
                data: [120, {
                    value: 200,
                    itemStyle: {
                        color: '#5B76F7'
                    }
                }, 150, 80, 70],
                type: 'bar',
                barWidth: 20,
                color: ['#58CDFE'],
            }]
        };
        
    
        option && myChart.setOption(option);  

    }


    function setEchart2() {
        let chartDom = document.getElementById('roundChart');
        let myChart = echarts.init(chartDom);


        var option;

        option = {
            
            legend: {
              top: '5%',
              left: 'center'
            },
            
            series: [
              {
                name: 'Access From',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: false,
                    fontSize: '40',
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                itemStyle:{
                    borderWidth:5, //设置border的宽度有多大
                    borderColor:'#0A182C',
                },    
                data: [
                  { value: 1048, color: '#57CCFF'},
                  { value: 100,  color: '#BDBDBD'},
                ],
                color: ['#57CCFF', '#BDBDBD'],
              }
              
            ]
          };

        option && myChart.setOption(option);

    }


  return (
     
    <div className='ProjectDistribution'> 

                <div className="DistributionBody flex">
                    
                    <div className="leftData" >
          

                    </div>

                    <div className="rightDataDistribution">
                       <div className="top flex">
                        <div className="leftProject">
                            <div className="createIng">

                                <div className="headDis">
                                    <img src={img6} alt="" /> 
                                    <div className="title">
                                        <h1>在建项目</h1>
                                        <div><span>12</span> 个</div>
                                    </div>
                                    
                                </div>

                                <div className="hr"></div> 
                                
                                <ul>
                                    <li><span>水利工程</span>  <p><i>10</i> 个</p> </li>
                                    <li><span>轨道工程</span>  <p><i>10</i> 个</p></li>
                                    <li><span>公路交通</span>  <p><i>10</i> 个</p></li>
                                </ul>

                            </div>
                            <div className="key">
                                <div className="headKey">
                                    <img src={img1} alt="" /> 重点工程项目
                                </div>

                                <ul>
                                    <li>滇中引水石鼓水源工程</li>
                                </ul>
                                
                            </div>                             
                        </div>


                            <div className="rightProject">
                                <div className="headKey">
                                    <img src={img1} alt="" /> 在建项目列表
                                </div>
                                <ul>
                                    {
                                        projectList.map((item,index) => {
                                            return (
                                                <li>
                                                <span>{index+1}</span>
                                                <p>{item.name}</p>
                                                <img src={img7} alt="" />
                                            </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>   
                       </div>
                      

                        


                  



                      
                        
                    </div>
                
                </div>    

    </div >
  )
}

export default withRouter(ProjectDistribution);