import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Button} from "antd";
import './ServicePoints.less'



import Summary from './page/Summary/Summary';
import ServicePoints2 from './page/servicePoints/ServicePoints';
import Rule from './page/rule/rule';


function ServicePoints() {
  const navList = ['考评汇总', '考评查询', '考评规则']
  const [navIndex, setNavIndex] = useState(null);
  const [childBtn, setchildBtn] = useState([]);

useEffect(() => {
  let childBtn = React.$getStorage('menuchildBtn');
    for(let i = 0; i < childBtn.length; i++) {
      if(childBtn[i].show) {
        let item = childBtn[i];
        toSelecteNav(item.index);
        break;
      }
    }

    setchildBtn(childBtn)
}, [])

  function toSelecteNav(index) {
    setNavIndex(index)
  }
  return (
    <div className="diy-container ServicePoints">
      <ul className="nav-list">
        {
          childBtn.map(((item, index) => {
            if(item.show) {
              return <Button  type={navIndex === item.index? 'primary': ''}  key={index} className="setBtn" onClick={() => toSelecteNav(item.index)}>{item.name}</Button>
            }
            
            // <li className={navIndex === index ? 'active' : ''} key={index} onClick={() => toSelecteNav(index)} >{item}</li>
          }))
        }



      </ul>
      {navIndex === 0 && <Summary />}
      {navIndex === 1 && <ServicePoints2 />}
      {navIndex === 2 && <Rule />}
      {/* {navIndex === 3 && <QuestionBank />} */}
    </div>
  )
}

export default withRouter(ServicePoints);