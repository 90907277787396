import React from "react";
import './wages.less';

import {Button, Table} from "antd";

import * as echarts from 'echarts';


import {WageEntry} from "./WageEntry/WageEntry"


export default class Wages extends React.Component {
 
  state = {
      status: null,
      childBtn: [{}, {}],
      wageStatis: {
        count: 0,
        normal: 0,
        abnormal: 0,
        abnormalRate: 0
      },
      wageAarray: []
  }

  componentDidMount() {
    let childBtn = React.$getStorage('menuchildBtn');
    for(let i = 0; i < childBtn.length; i++) {
      if(childBtn[i].show) {
        let item = childBtn[i];
        this.changeStatus(item.index);
        break;
      }
    }
  

    this.setState({
      childBtn: childBtn
    })
  }


  columns = [
    {
      title: '队伍名称',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '应发人数',
      dataIndex: 'count',
      align: 'center',
    },
    {
      title: '发放正常人数',
      dataIndex: 'normal',
      align: 'center',
    },
    {
      title: '发放异常人数',
      dataIndex: 'abnormal',
      align: 'center',
    }
  ]

  

  

  getWageStatistics() {
    const projectId = React.$getStorage('projectInfo').id
    let params = {
      logic: 2,
      projectId: projectId
    }
    React.$axios.post('wageStatistics', params).then(({ data }) => {
      if(!data.count) {
        return
      }   


      let praams = {
        count: data.count,
        normal: data.normal,
        abnormal: data.abnormal,
        abnormalRate: (data.abnormal / data.count).toFixed(2) * 100
      }

     


      let arr = []
      Object.keys(data.team).map(item => {
        let json = {
          name: item
        }
        json = {...json, ...data.team[item]}
        arr.push(json)
      })


      this.setState({
        wageStatis: praams,
        wageAarray: arr
      })



      this.getEcharts([
          {value: data.count, name: '应发人数'},
          {value: data.normal, name: '发放正常人数'},
          {value: data.abnormal, name: '发放异常人数'},
      ])

    })
  }

  
  changeStatus(e) {
      this.setState({
        status: e
      })

      if(e === 0) {
        this.getWageStatistics()
      }
      
  }


  getEcharts(val) {
    var myChart = echarts.init(document.getElementById('main'));
    let option = {
          color: ['#1DC7B5', '#2977F4', '#FD3476'],
        tooltip: {
            trigger: 'item'
        },
        
        series: [
            {
                name: '访问来源',
                type: 'pie',
                radius: '50%',
                data: val,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    
    
    myChart.setOption(option);
  }
    
  render() {
    return (
      <div className= "wages">

          <div className="btn">
            {
              this.state.childBtn.map((item, index) => {
                if(item.show) {
                  return (
                    <Button  key={index} type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                  )
                }
              })
            }
           {/* <Button style={{display: this.state.childBtn[0].show?'block':'none'}}  type={this.state.status==true? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, true)}>工资统计</Button>
           <Button style={{display: this.state.childBtn[1].show?'block':'none'}} className="setBtn" type={this.state.status==false? 'primary': ''}  onClick={this.changeStatus.bind(this, false)}>工资录入</Button> */}
          </div>
          
        
          <div style={{display: this.state.status==0? 'block': 'none'}}>  
          <div className="gongziNum">

            <div className="tongji">
                <h1>工资统计</h1>
                <div className="border"></div>
                <div className="list">
                       <div>
                           <p>应发人数</p>
                           <p className="pc pcolor1">{this.state.wageStatis.count}</p> 
                        </div>
                       <div>
                           <p>发放正常人数</p> 
                           <p className="pc pcolor2">{this.state.wageStatis.normal}</p>
                       </div>
                       <div>
                            <p>发放异常人数</p>
                            <p className="pc pcolor3">{this.state.wageStatis.abnormal}</p>
                       </div>

                       <div>
                            <p>异常率</p>
                            <p className="pc pcolor4">{this.state.wageStatis.abnormalRate}%</p>
                       </div> 
                </div>


                <div id="main" className="echarts">

                </div>


            </div>

            <div className="fafang">
                <h1>各劳务队伍工资发放情况</h1>
                <div className="border"></div>


                <div style={{margin: '0 20px'}}>
                  <Table bordered columns={this.columns} dataSource={this.state.wageAarray}/>
                </div>
                

            
            </div>
          </div>
          </div> 

      
          <div style={{display: this.state.status==1? 'block': 'none'}}>
            <WageEntry />
          </div>
          

      </div>
    );
  }
}
