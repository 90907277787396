import React, { useState, useEffect } from "react";
import { Image, Form, DatePicker, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import './mixingStationOverview.less'
import moment from 'moment';
import * as echarts from 'echarts';

function MixingStationOverview(props) {
  const imgUrl = process.env.REACT_APP_IMG_URL
  const [deviationForm] = Form.useForm();
  const { Option } = Select;
  const [stationIndex, setStationIndex] = useState(0);
  const [blenderStationList, setBlenderStationList] = useState([]);
  const [blenderDeviceList, setBlenderDeviceList] = useState([]);
  const [productNumberList, setProductNumberList] = useState([]);
  const [productBatchList, setProductBatchList] = useState([]);
  const [materialsList, setMaterialsList] = useState([]);
  const [proportionDeviation, setProportionDeviation] = useState(null);

  useEffect(() => {
    getBlenderStationList();
  }, []);

  useEffect(() => {
    getBlenderDeviceList();
  }, [blenderStationList, stationIndex]);

  useEffect(() => {
    deviationForm.setFieldsValue({
      productLine: blenderDeviceList.length ? blenderDeviceList[0].deviceKey : ""
    })
    getProductNumber();
  }, [blenderDeviceList]);

  useEffect(() => {
    deviationForm.setFieldsValue({
      productNumber: productNumberList.length ? productNumberList[0].prodNo : ""
    })
    getProductBatch();
  }, [productNumberList]);

  useEffect(() => {
    deviationForm.setFieldsValue({
      productBatch: productBatchList.length ? productBatchList[0].batchNo : ""
    })
    getMaterialsList()
    getProportionDeviation()
  }, [productBatchList]);

  useEffect(() => {
    if (materialsList.length) {
      drawProportionAnalysisChart();
      drawRealTimeMonitoring();
    }
  }, [materialsList]);
  function toNavigate() {
    props.history.push('/QualitySupervision/productionDetails')
  }
  function toSelecteNav(index) {
    setStationIndex(index)
  }
  function productDateChange() {
    getProductNumber()
  }
  function productLineChange() {
    getProductNumber()
  }
  function productNumberChange() {
    getProductBatch()
  }
  function productBatchChange() {
    getMaterialsList()
    getProportionDeviation()
  }
  function getBlenderStationList() {
    const requestData = {
      logic: 2,
    };
    React.$axios.post("blenderStationList", requestData).then(({ data }) => {
      setBlenderStationList(data.list)
    })
  };
  function getBlenderDeviceList() {
    if (!blenderStationList.length) {
      return false
    }
    const requestData = {
      logic: 2,
      stationId: blenderStationList[stationIndex].id,
    };
    React.$axios.post("blenderDeviceList", requestData).then(({ data }) => {
      setBlenderDeviceList(data.list)
    });
  }
  function getProductNumber() {
    if (!blenderDeviceList.length) {
      return false
    }
    const productDate = deviationForm.getFieldValue('productDate');
    const produceTimeStart = parseInt(new Date(productDate).getTime() / 1000);
    const produceTimeStop = parseInt((new Date(productDate).getTime() + 86399999) / 1000);
    const requestData = {
      logic: 3,
      stationId: blenderStationList[stationIndex].id,
      deviceKey: deviationForm.getFieldValue('productLine'),
      produceTimeStart,
      produceTimeStop,
    };
    React.$axios.post("monitorProduceDetail", requestData).then(({ data }) => {
      if (data.prodNo.length) {
        setProductNumberList(data.prodNo)
      } else {
        setProductNumberList([])
        setProductBatchList([])
        deviationForm.setFieldsValue({
          productNumber: ""
        })
        deviationForm.setFieldsValue({
          productBatch: ""
        })
      }
    });
  }
  function getProductBatch() {
    if (!productNumberList.length) {
      return false
    }
    const requestData = {
      logic: 4,
      stationId: blenderStationList[stationIndex].id,
      deviceKey: deviationForm.getFieldValue('productLine'),
      prodNo: deviationForm.getFieldValue('productNumber')
    };
    React.$axios.post("monitorProduceDetail", requestData).then(({ data }) => {
      if (data.batchNo.length) {
        setProductBatchList(data.batchNo)
      } else {
        setProductBatchList([])
        deviationForm.setFieldsValue({
          productBatch: ""
        })
      }
    });
  }
  function getMaterialsList() {
    if (!productBatchList.length) {
      return false
    }
    const productDate = deviationForm.getFieldValue('productDate');
    const produceTimeStart = parseInt(new Date(productDate).getTime() / 1000);
    const produceTimeStop = parseInt((new Date(productDate).getTime() + 86399999) / 1000);
    const requestData = {
      logic: 2,
      stationId: blenderStationList[stationIndex].id,
      deviceKey: deviationForm.getFieldValue('productLine'),
      prodNo: deviationForm.getFieldValue('productNumber'),
      batchNo: deviationForm.getFieldValue('productBatch'),
      produceTimeStart,
      produceTimeStop,
    };
    React.$axios.post("monitorProduceDetail", requestData).then(({ data }) => {
      setMaterialsList(data.material)
    });
  }
  function getProportionDeviation() {
    if (!productBatchList.length) {
      return false
    }
    const productDate = deviationForm.getFieldValue('productDate');
    const produceTimeStart = parseInt(new Date(productDate).getTime() / 1000);
    const produceTimeStop = parseInt((new Date(productDate).getTime() + 86399999) / 1000);
    const requestData = {
      logic: 4,
      projectId: React.$getStorage('projectInfo').id,
      stationId: blenderStationList[stationIndex].id,
      deviceKey: deviationForm.getFieldValue('productLine'),
      prodNo: deviationForm.getFieldValue('productNumber'),
      batchNo: deviationForm.getFieldValue('productBatch'),
      produceTimeStart,
      produceTimeStop,
    };
    React.$axios.post("listBlenderProportionDeviation", requestData).then(({ data }) => {
      // setMaterialsList(data.material)
      setProportionDeviation(data.info)
    });
  }

  function drawProportionAnalysisChart() {
    const myChart = echarts.init(
      document.getElementById("proportionAnalysisChart")
    );
    myChart.setOption({
      color: ['#27ccf4', '#fd3879','#1ac8b6','#ffb822', '#5968dd', '#3193f5', '#ffa133', '#4ee6f1'],
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        type: "scroll",
        width: "40%",
        height: 300,
        orient: "vertical",
        right: "20%",
        top: "center",
        align: "left",
        icon: 'circle',
        itemGap: 20,
        formatter: (name, actMaterial) => {
          let total = 0;
          let target;
          for (let i = 0, l = materialsList.length; i < l; i++) {
            total += materialsList[i].actMaterial;
            if (materialsList[i].materialName == name) {
              target = materialsList[i].actMaterial;
            }
          }
          let rate = "0%";
          if (total > 0) {
            rate = `${((target / total) * 100).toFixed(2)}%`;
          }
          // return `${name}   ${rate}    ${target}`;
          return `${name}       ${rate}`;
        },
        data: materialsList.map(item => item.materialName)
      },
      series: [
        {
          name: "配比分析",
          type: "pie",
          radius: ["40%", "70%"],
          center: ["30%", "50%"],
          data: materialsList.map((item) => {
            return {
              name: item.materialName,
              value: item.actMaterial,
            };
          }),
          labelLine: {
            show: false,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    });
  }
  function drawRealTimeMonitoring() {
    const myChart = echarts.init(
      document.getElementById("realTimeMonitoring")
    );
    myChart.setOption({
      legend: {
        right: "10",
        data: ["配方值", "实际值"],
        textStyle: {
          color: "#aeb3bb",
        },
      },
      color: ['#ffb822', '#05cdff'],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: materialsList.map((item) => item.materialName),
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            // rotate: 40
          }
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#606d7f",
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ["rgba(109,128,255,.8)"],
            },
          },
        },
      ],
      series: [
        {
          name: "配方值",
          type: "bar",
          barWidth: "30%",
          itemStyle: {
            //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
            emphasis: {
              barBorderRadius: 30,
            },
            normal: {
              //柱形图圆角，初始化效果
              // barBorderRadius: [5, 5, 0, 0],
              label: {
                show: false, //是否展示
                textStyle: {
                  fontWeight: "bolder",
                  fontSize: "12",
                  fontFamily: "微软雅黑",
                  color: "#fff",
                },
              },
            },
          },
          data: materialsList.map((item) => item.ratedMaterial),
        },
        {
          name: "实际值",
          type: "bar",
          barWidth: "30%",
          itemStyle: {
            //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
            emphasis: {
              barBorderRadius: 30,
            },
            normal: {
              //柱形图圆角，初始化效果
              // barBorderRadius: [5, 5, 0, 0],
              label: {
                show: false, //是否展示
                textStyle: {
                  fontWeight: "bolder",
                  fontSize: "12",
                  fontFamily: "微软雅黑",
                  color: "#fff",
                },
              },
            },
          },
          data: materialsList.map((item) => item.actMaterial),
        },
      ],
    });
  }
  return (
    <div className="diy-container mixingStationOverview">
      <ul className="nav-list">
        {
          blenderStationList.map(((item, index) => {
            return <li className={stationIndex === index ? 'active' : ''} key={index} onClick={() => toSelecteNav(index)} >{item.name}</li>
          }))
        }
      </ul>
      <div className="content-box">
        <div className="content-top">
          <div className="top-left">
            <div className="title-wrap">
              <h3>设备信息</h3>
              <div className="status">状态：<span>运行中</span></div>
            </div>
            <div className="device-message">
              <div className="device-message-left">
                <ul>
                  <li>
                    <p>
                      <span>设备名称</span>
                      <span>{blenderStationList[stationIndex] ? blenderStationList[stationIndex].name : "-"}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>设备型号</span>
                      <span>{blenderStationList[stationIndex] ? blenderStationList[stationIndex].mainMachine : "-"}</span>
                    </p>
                    <p>
                      <span>生产能力</span>
                      <span>{blenderStationList[stationIndex] ? blenderStationList[stationIndex].totalCapacity : "-"}</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>整机质量</span>
                      <span>{blenderStationList[stationIndex] ? `${blenderStationList[stationIndex].totalQty}吨` : "-"}</span>
                    </p>
                    <p>
                      <span>功率</span>
                      <span>{blenderStationList[stationIndex] ? `${blenderStationList[stationIndex].totalPower}%` : "-"}</span>
                    </p>
                  </li>
                </ul>
                <h4>计算精度</h4>
                <ul>
                  <li>
                    <p>
                      <span>石子</span>
                      <span>±{blenderStationList[stationIndex] ? `${blenderStationList[stationIndex].stoneAccurcy}` : "0"}%kg</span>
                    </p>
                    <p>
                      <span>砂子</span>
                      <span>±{blenderStationList[stationIndex] ? `${blenderStationList[stationIndex].sandAccurcy}` : "0"}%kg</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>水泥</span>
                      <span>±{blenderStationList[stationIndex] ? `${blenderStationList[stationIndex].cementAccurcy}` : "0"}%kg</span>
                    </p>
                    <p>
                      <span>水</span>
                      <span>±{blenderStationList[stationIndex] ? `${blenderStationList[stationIndex].waterAccurcy}` : "0"}%kg</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>粉煤灰</span>
                      <span>±{blenderStationList[stationIndex] ? `${blenderStationList[stationIndex].ashAccurcy}` : "0"}%kg</span>
                    </p>
                    <p>
                      <span>外加剂</span>
                      <span>±{blenderStationList[stationIndex] ? `${blenderStationList[stationIndex].additAccurcy}` : "0"}%kg</span>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="device-message-right">
                <Image
                  width={200}
                  height={270}
                  src="error"
                  fallback={`${imgUrl}/${blenderStationList[stationIndex] ? blenderStationList[stationIndex].img : ''}`}
                />
              </div>
            </div>
          </div>
          <div className="top-right">
            <div className="title-wrap">
              <h3>配比偏差</h3>
              <span onClick={toNavigate}>生产明细</span>
            </div>
            <Form form={deviationForm} layout="inline">
              <Form.Item
                label="生产日期"
                name="productDate"
                rules={[{ required: true, message: '请选择生产日期!' }]}
                initialValue={moment(new Date().toLocaleDateString())}
              >
                <DatePicker allowClear={false} onChange={productDateChange} />
              </Form.Item>

              <Form.Item
                label="生产线"
                name="productLine"
                rules={[{ required: true, message: '请选择生产线!' }]}
              >
                <Select style={{ width: 150 }} onChange={productLineChange}>
                  {
                    blenderDeviceList.map((item, index) => {
                      return (
                        <Option value={item.deviceKey} key={index}>{item.name}</Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                label="生产编号"
                name="productNumber"
                rules={[{ required: true, message: '请选择生产编号!' }]}
              >
                <Select style={{ width: 150 }} onChange={productNumberChange}>
                  {
                    productNumberList.map((item, index) => {
                      return (
                        <Option value={item.prodNo} key={index}>{item.prodNo}</Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                label="生产批次"
                name="productBatch"
                rules={[{ required: true, message: '请选择生产批次!' }]}
              >
                <Select style={{ width: 150 }} onChange={productBatchChange}>
                  {
                    productBatchList.map((item, index) => {
                      return (
                        <Option value={item.batchNo} key={index}>{item.batchNo}</Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Form>
            <div className="flex-box">
              <div className="flex-item">
                <p>设计总量</p>
                <p>
                  <span>{proportionDeviation ? proportionDeviation.ratedMaterial : 0.00}</span>
                  <span>kg</span>
                </p>
              </div>
              <div className="flex-item">
                <p>实际总量</p>
                <p>
                  <span>{proportionDeviation ? proportionDeviation.actMaterial : 0.00}</span>
                  <span>kg</span>
                </p>
              </div>
              <div className="flex-item">
                <p>偏差量</p>
                <p>
                  <span>{proportionDeviation ? proportionDeviation.deviation : 0.00}</span>
                  <span>kg</span>
                </p>
              </div>
              <div className="flex-item">
                <p>偏差率</p>
                <p>
                  <span>{proportionDeviation ? proportionDeviation.deviationRate : 0.00}</span>
                  <span>%</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-bottom">
          <div className="title-wrap">
            <h3>生产数据监控</h3>
          </div>
          <div className="chart-wrap">
            <div className="chart-left">
              <div id="proportionAnalysisChart" className="proportionAnalysisChart"></div>
            </div>
            <div className="chart-right">
              <div id="realTimeMonitoring" className="realTimeMonitoring"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(MixingStationOverview);