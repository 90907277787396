import React from 'react'

import './downLoadWorld.less'

import docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver'
import ImageModule from 'docxtemplater-image-module-free'



export const DownLoadWorld = (
    class DownLoadWorld extends React.Component {

        state = {
            
        }

        static defaultProps = {
            beforeGenerate:{ // 导出之前
                type: Function,
            },
            afterGenerate: { // 导出之后
                type: Function,
            },

            exportTemplate: '', // 模板
            fileName: '导出', 
        }

 
        componentDidMount() {
            
        }


        getBase64(imgUrl, item, name) {
            let _this = this

            return new Promise(resolve => {

                if(!imgUrl) {
                    resolve([])
                    return
                }

                window.URL = window.URL || window.webkitURL;
                var xhr = new XMLHttpRequest();
                xhr.open("get", imgUrl, true);
                // 至关重要
                xhr.responseType = "blob";
                xhr.onload = function () {
                if (this.status == 200) {
                    //得到一个blob对象
                    var blob = this.response;

                    // 至关重要
                    let oFileReader = new FileReader();
                    oFileReader.onloadend = function (e) {
                    let base64 = e.target.result;

                    if(item) {
                        item[name] = base64
                    }

                    resolve(base64)
                   
                    };
                    oFileReader.readAsDataURL(blob);
                }
                }
                xhr.send();
            })
          }



        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return [];
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        }




        exportWord() {
            let _this = this

             // let wordData = { // 对应模板内容
            //     "textcontents": "测试132456",
            //     "clients": [
            //         {
            //             "first_name": "胡浩Z",
            //             "last_name": "dd",
            //             "phone": "+44546546454",
            //             "image": ""
            //         }
            //     ],
            // }

            let wordData = {
            
            }            
            
            if(this.props.beforeGenerate) {
                this.props.beforeGenerate().then(res => {
                    wordData = res
                    for(let i in res) {
                        if(i.indexOf('image') != -1) {
                            _this.getBase64(wordData[i]).then(res2 => { // 如果是图片直接图片请求base64 数据
                                wordData[i] = res2
                            })
                        }
                    }


                    this.toSaveWorld(wordData)
                    
                    
                })
            } else {
                return false
            }
          
        }


        


        toSaveWorld(wordData) {
            let _this = this
            let opts  = {}
            opts.centered = false; //Set to true to always center images
            opts.fileType = "docx"; //Or pptx
            opts.getImage = function (chartId) {
                return _this.base64DataURLToArrayBuffer(chartId);
            }
            //Pass the function that return image size
            opts.getSize = function () {
                return [100, 40];
            }
            let imageModule = new ImageModule(opts);

            // 读取并获得模板文件的二进制内容，是docxtemplater提供的固定写法
            JSZipUtils.getBinaryContent(_this.props.exportTemplate, function (error, content) {

                // 抛出异常
                if (error) {
                    throw error;
                }

                // 创建一个PizZip实例，内容为模板的内容
                let zip = new PizZip(content);
                // 创建并加载docxtemplater实例对象
                let doc = new docxtemplater()
                .attachModule(imageModule) //图片导出功能
                .loadZip(zip)
                .compile();
                 
                doc.resolveData(wordData).then(function () {
                    doc.render();
                    var out = doc.getZip().generate({
                        type: "blob",
                        mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    // 将目标文件对象保存为目标类型的文件，并命名（起一个自己需要的名字就好了）
                    saveAs(out,  _this.props.fileName + ".docx")

                    if(_this.props.afterGenerate) {
                        _this.props.afterGenerate()
                    }

                    
                }).catch((error) => {
                    console.log(error)
                })

                })
        }


        render() {
            return <div onClick={this.exportWord.bind(this)}> 
                {
                    this.props.children
                }            
            </div>
        }
    }
)

