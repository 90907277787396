import React from "react";


import {Button,   Image, message} from "antd";

// import MachineDeviceList from './machineDeviceList/machineDeviceList';
// import MachineCommodityAll from './machineCommodityAll/machineCommodityAll';
// import MachineRecord from './machineRecord/machineRecord';


import DistinguishList from './distinguishList/distinguishList';
import RecognitionLogList from './recognitionLogList/recognitionLogList';
import AuthorizationLogList from './authorizationLogList/authorizationLogList';
import Device from './device/device';


export default class Home extends React.Component {


  state = {
    herfEarth: '',
    status: 0,
    childBtn: []
  }


  componentWillMount () {
    let t =  React.$getStorage('jurIndex2')
    let childBtn = React.$getStorage('menuchildBtn');
    for(let i = 0; i < childBtn.length; i++) {
      if(childBtn[i].show) {
        let item = childBtn[i];
        this.changeStatus(item.index);
        break;
      }
    }
  
    
    this.setState({
      childBtn: childBtn
    })


    if(t) {
        this.setState({
            status: t
        })
    }
 }

 // 组件离开生命周期
 componentWillUnmount() {
    localStorage.removeItem('jurIndex2');
  }

  componentDidMount() {
    
   
  }


  changeStatus(type) {
    this.setState({
        status: type
    })

    React.$setStorage('jurIndex2', type) 
  }



  render() {
    return (
      <div className="Jurisdiction">
          <div className="btn" style={{paddingLeft: 10}}>
          {
              this.state.childBtn.map(item => {
                  if(item.show) {
                      return (
                          <Button  type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                          ) 
                  }
                  
              })
          }
           {/* <Button  type={this.state.status==1? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 1)}>识别列表</Button>
           <Button  type={this.state.status==2? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 2)}>识别日志列表</Button>
           <Button className="setBtn" type={this.state.status==3? 'primary': ''}  onClick={this.changeStatus.bind(this, 3)}>授权日志列表</Button>
           <Button className="setBtn" type={this.state.status==4? 'primary': ''}  onClick={this.changeStatus.bind(this, 4)}>设备列表</Button> */}
          </div>
      <div style={{margin: '0 10px'}}>
          {
            function() {
               if(this.state.status == 0) {
                 return (
                   <DistinguishList/>
                 )
               }
               
               if(this.state.status == 1) {
                 return (
                   <RecognitionLogList/>
                 )
               }

               if(this.state.status == 2) {
                 return (
                   <AuthorizationLogList />
                 )
               }

               if(this.state.status == 3) {
                 return (
                   <Device />
                 )
               }
              
            }.bind(this)()
          }
        </div>





      </div>
    );
  }
}
