import React from "react";

import { DatePicker,Input,Select,Button, Table, Carousel , Image} from "antd";

import { SearchOutlined, DownOutlined, UpOutlined} from '@ant-design/icons';

import * as echarts from 'echarts';

import './vehicleControlList.less';

import CarList from './carList/carList';
import VehicleControlLogList from './vehicleControlLogList/vehicleControlLogList';
import EmpowerCar from './empowerCar/empowerCar';

import InAndOutCar from './InAndOutCar/InAndOutCar';






export default class Home extends React.Component {

    state = {
    status: null,
    childBtn: []
  }

  componentDidMount() {
    let childBtn = React.$getStorage('menuchildBtn');
    for(let i = 0; i < childBtn.length; i++) {
      if(childBtn[i].show) {
        let item = childBtn[i];
        this.changeStatus(item.index);
        break;
      }
    }
  

    this.setState({
      childBtn: childBtn
    })
    }
  

      changeStatus(index) {
        this.setState({
            status: index
        })
        React.$setStorage('pageIndex1', index)
      }

    
  render() {
    return (
      <div className="vehicleControlList">
                <div className="btn btn2">
                    {
                        this.state.childBtn.map(item => {
                            if(item.show) {
                                return (
                                    <Button  type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                                   ) 
                            }
                           
                        })
                    }


                {/* <Button  type={this.state.status==0? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 0)}>车辆进出</Button>
                <Button className="setBtn" type={this.state.status==2? 'primary': ''}  onClick={this.changeStatus.bind(this, 2)}>车辆进出日志</Button>
                <Button  type={this.state.status==3? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 3)}>车辆授权列表</Button>
                <Button  type={this.state.status==1? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 1)}>车辆列表</Button> */}
                </div>
                {
                    function () {
                        if(this.state.status == 0) {
                            return (
                                <InAndOutCar/>
                            )
                        }
                        if(this.state.status == 3) {
                            return (
                                <CarList/>
                            )
                        }

                        if(this.state.status == 1) {
                            return (
                                <VehicleControlLogList/>
                            )
                        }

                        if(this.state.status == 2) {
                            return (
                                <EmpowerCar/>
                            )
                        }

                    }.bind(this)()
                } 
      </div>
    );
  }
}
