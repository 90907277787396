import React from 'react';
import ReactDOM from 'react-dom';
import App from '@/app'
import 'antd/dist/antd.css';
import '@/assets/css/reset.less'
import '@/assets/css/common.less'
import axios from "@/axios/axios"
import axiosNoLogin from '@/axios/axiosNoLogin'
import { setStorage, getStorage,removeStorage } from '@/utils/storage'

React.$axios = axios
React.$axiosNoLogin = axiosNoLogin
React.$setStorage = setStorage
React.$getStorage = getStorage
React.$removeStorage = removeStorage

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
