import React, { useState, useEffect } from "react";
import { Link, Redirect, BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import debounce from 'lodash.debounce'

import { Progress } from 'antd';

// import Translate from '../component/translate/translate.jsx';

import img1 from '@/assets/img/party/1.svg'; //黑色图标
import img2 from '@/assets/img/party/2.png'; // 黄色心心
import img3 from '@/assets/img/party/3.png'; // 左侧天安门
import img4 from '@/assets/img/party/4.png'; // 中间天安门
import img5 from '@/assets/img/party/5.png'; // 底部宫殿
import img6 from '@/assets/img/party/6.svg'; // 党旗
import img7 from '@/assets/img/party/7.gif'; // 播放动图
// import img8 from '@/assets/img/party/12.png'; // 列表颜色

import Eearth from '../component/earth/partyEarth';

import {formatTime} from '@/utils/util';


// 自增方法
// import {setIncrement} from '@/assets/js/utilhh';
 
import * as echarts from 'echarts';

import './partyBuild.less';


function PartyBuild() {
    const [sexMan, setSexMan] = useState([
        0,0,0,0,0,0,0,0,0,0
    ])

    const [sexWomen, setSexWomen] = useState([
        0,0,0,0,0,0,0,0,0,0
    ])

    const [PartyMember, setPartyMember] = useState(0)
    const [PartyOrganization, setPartyOrganization] =  useState(0)

    const [partyActive, setPartyActive] = useState(0)
    const [projectActive, setProjectActive] = useState(0)


    const [PieRate, setPieRate] = useState(10)
    const [womanNumber, setWomanNumber] = useState(42) // 女 数据
    const [manNumber, setManNumber] =  useState(58) // 男 数据

    const [projectListArr, setProjectListArr] = useState([])
    
    const [activeListArr, setactiveListArr] = useState([]) // 活动列表数据

    const [scale, setRate] = useState(1)
    const [widthHieghtRate, setWidthHieghtRate] = useState({
        width:window.innerWidth, height:window.innerHeight
    })



    let activeListArrIndex = 0, projectListArrIndex = 0, partyMemberCount = 0
    let timeInterval = null, setIncrementInterval = null, echartPieInterval = null


    // 
    // 饼状图高亮
    function highlight(mychart1, index) {
        mychart1.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: index
        });

        mychart1.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: index == 0? 3 : index - 1            
        });
    }


    function getScale() {
        let ww=window.innerWidth/widthHieghtRate.width
        let wh=window.innerHeight/widthHieghtRate.height
        
        return ww<wh?ww: wh
    }

    function setScale() {

        debounce(() => {
            let scale= getScale()
            setRate(scale)
        }, 500)()
    }


    useEffect(() => {

        window.addEventListener('resize', setScale)



        setTimeout(() => {
            echartLine()
            let mychart1 =  echartPie('echartPie')
            let mychart2 =  echartPie('echartPie2')
            let mychart3 =  echartPie('echartPie3')
            let mychart4 = echartPie('echartPie4')


            let index = 3;

            let arr = getRandom(4.2)
            let arr2 = getRandom(5.8)
            let allRate = [50, 25, 15, 10]
            setSexWomen(arr)
            setSexMan(arr2)


            echartPieInterval = setInterval(() => {
                index++;
                if(index == 4) {
                  index = 0
                }


                highlight(mychart1, index)
                highlight(mychart2, index)
                highlight(mychart3, index)
                highlight(mychart4, index)

                // 设置男女比列
                let arr = getRandom(4.2)
                let arr2 = getRandom(5.8)
                
                
                setSexWomen([
                    0,0,0,0,0,0,0,0,0,0
                ])
    
                setSexMan([
                    0,0,0,0,0,0,0,0,0,0
                ])

                // 设置比率
                setIncrementFunPie(allRate[index])

                let timerout = setTimeout(() => {
                    setSexWomen(arr)
                    setSexMan(arr2)
                    clearTimeout(timerout)
                }, 300)
    


           }, 5000)

        }, 200)

        // 请求党员活动
        activeList()

        //请求项目列表
        projectList()

        uploadSex()


        return () => {
            clearInterval(timeInterval)
            clearInterval(setIncrementInterval)
            clearInterval(echartPieInterval)
        }
    }, [])


    // 自增
    function setIncrement(val, callback, twice = 1000) {

        
        let status = false,
        step =  Math.ceil(val / (twice / 30)),
        count  = 0,
        interval = null;
    
    
        interval = setInterval(() => {
            if(count < val) {
                // setShiCount(count)
                callback(count)
               
            } else {
                status = true
                callback(val)
            }
            
            if(status) {
                clearInterval(interval)
            }
    
            count = count + step;
        }, 30)
    }


    
    // 设置自增数字党员
    function setIncrementFun() {
            setIncrement(partyMemberCount, (val) => {
                setPartyMember(val)
            }, 1000) 
    }

    // 设置图形自增
    function setIncrementFunPie(number) {
        setIncrement(number, (val) => {
            setPieRate(val)
        }, 1000) 

        setIncrement(42, (val) => { // 女自增
            setWomanNumber(val)
        })

        setIncrement(58, (val) => { // 男自增
            setManNumber(val)
        })

    }



    // 设置事业部基础党组织
    function setPartyOrganizationFun() {
        
        setIncrement(projectListArrIndex - 5, (val) => {
            setPartyOrganization(val)
        }, 1000)
    }




    // 更新党员活动列表
    function uploadSex() {
        let index = 0
        let index2 = 0 // 项目列表
         timeInterval = setInterval(() => {
            setPartyOrganizationFun()
            setIncrementFun()
            index++;
            index2++;
            if(index == activeListArrIndex - 4) {
                index = 0
            }

            if(index2 == projectListArrIndex - 4) {
                index2 = 0 
            }



            if(projectListArrIndex <= 4) { // 更新项目
                
            } else {
                setProjectActive(index2)
                let timeout =  setTimeout(() => {
                    if(index2 == 0) {
                        index2++;
                        setProjectActive(index2)
                    }
                    clearTimeout(timeout)
                }, 300)
            }

         
            if(activeListArrIndex <= 4) { // 更新党员活动
                
            } else {
                setPartyActive(index)
      
                let timeout =  setTimeout(() => {
                    if(index == 0) {
                        index++;
                        setPartyActive(index)
                    }
                    clearTimeout(timeout)
                }, 300)
            }

            
         }, 5000)
    }


    // 获取随机数
    function getRandom(number) {
        let random = number

        let cell = parseInt(random) //整数
        let arr = []
        arr.length = 10
        for(let i = 0; i < random; i++) {
            
            if(i < cell) {
                arr[i] = 1
            } else {
                let str = random.toString()
                if(str.indexOf('.')) {
                    let point = str.split('.')[1] / 10
                    arr[i] = point
                }
                
            }
            
        }

        for(let i = 0; i < arr.length; i++) {
            if(arr[i] == undefined) {
                arr[i] = 0
            }
        }

        return arr;
    }


    // 活动列表
    function activeList() {
        React.$axiosNoLogin.post('listBuildingMicroActivity2', {lengthLimit: 20,logic: 2,offsetLimit: 0}).then(res => {

            let arr = res.data.list
            
            if(arr.length > 4) {
                let copy = res.data.list.slice(0, 5)

                arr = [...arr, ...copy]   
            } 


            activeListArrIndex = arr.length
            
            setactiveListArr(arr)

        })
    }

    // 项目列表
    function projectList() {
        React.$axiosNoLogin.post('projectAll', {logic: 4, notInId: [1, 5]}).then(res => {
            
            // const [projectList, setProjectList] = useState([])
            let arr = res.data.list

            arr.map((item, index) => {
                item.xulie = index + 1
            })

            if(arr.length > 4) {
                let copy = res.data.list.slice(0, 5)

                arr = [...arr, ...copy]   
            } 

                projectListArrIndex = arr.length

                partyMemberCount = res.data.partyMemberCount

                setProjectListArr(arr)

                setPartyOrganizationFun()
                setIncrementFun()
        })
    }



    // 饼图
    function echartPie(dom) {
        let chartDom = document.getElementById(dom);
        let myChart = echarts.init(chartDom);
        
         let option = {
                                                                                                                                                                   
            series: [
              {
                name: 'Access From',
                type: 'pie',
                radius: ['75%', '90%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: false,
                    fontSize: '40',
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                data: [
                    { value: 50, name: 'Email', itemStyle: {color: '#FAD679'} },
                    { value: 25, name: 'Union Ads', itemStyle: {color: '#E33635' }  },
                  { value: 15, name: 'Search Engine', itemStyle: {color: '#EC7676'} },
                  { value: 10, name: 'Direct' , itemStyle: {color: '#EEEBE1'} },

                ]
              }
            ]
          };


          

          option && myChart.setOption(option);
            myChart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: 3
            });


         return myChart;
    }

    function echartLine() {
        let chartDom = document.getElementById('echartLine');

       let myChart = echarts.init(chartDom);

        var option;


        let date = [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021];
        
        let data = [Math.random() * 10];
        for (let i = 1; i < 11; i++) {
        data.push(Math.round((Math.random()) * 20 + i * 20));
        }

        option = {

        textStyle: {
            fontWeight: 'normal',
            color: '#F1C773',
        },
        
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: date,
            splitLine:{
        　　　　show:false
        　  }
        },

        yAxis: {
            type: 'value',
            // boundaryGap: [0, '100%'],
            splitLine:{
            　　　　show:false
            },
            show:false,
        },

        grid: {
            left: '0%',
            right: '5%',
             bottom: '135px',
            top: "100%",
            containLabel: true
        },

        series: [
            {
            name: 'Fake Data',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
                color: '#F1C773'
            },
            areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                    offset: 0,
                    color: 'rgb(255, 158, 68)'
                },
                {
                    offset: 1,
                    color: 'rgb(255, 70, 131)'
                }
                ])
            },
            data: data
            }
        ]
        };

        option && myChart.setOption(option);
    }


  const {width, height} = widthHieghtRate
  return (
     
    <div className="PartyBuild"> 

        <div className={`Translate`}> 
            <div className="scale-box"
                style={{
                transform: `scale(${scale}) translate(-50%, -50%)`,
                WebkitTransform: `scale(${scale}) translate(-50%, -50%)`,
                width,
                height
            }}>

        
        {/* <Translate>  */}

            <div className="body">
                 

                 <div className="allContent">
                      <div className="headImg">
                            <img src={img3} alt="" />
                            <img src={img3} className="flip-vertical" /> 
                      </div>  


                    <div className="allText">
                        <div className="side leftSide">
                             <div className="sideHead">
                                    <img src={img1} alt="" />    

                                    <ul className="fistUl">
                                        <li>事业部党员数据概览</li>
                                        <li>National Party Menber Data Overview</li>
                                        <li>中国水利水电第十四工程局有限公司</li>
                                    </ul>

                             </div>

                             <div className="line"></div>   

                             <div className="num">

                                    <b>事业部党员</b><span className="span">{PartyMember} 人</span>    

                             </div>

                             <div className="line"></div> 


                             <div className="typeSex">
                               <div className="sex">
                                     <div className="flex">
                                     <ul>
                                        {
                                            sexWomen.map((item, index) => {
                                                return (
                                                    <li key={index} >
                                                        <div className="isTrue">
                                                            <div className="head"></div>
                                                            <div className="bodyHH"></div>
                                                            <div className="leg"></div>
                                                        </div>

                                                        <div className="isFalse" style={{width: item * 100 + '%', 'transition': `width ${item==0?0:0.1}s ease ${item==0?0:index * 0.1}s`}}>
                                                            <div className="head"></div>
                                                            <div className="bodyHH"></div>
                                                            <div className="leg"></div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }         
                                     </ul> 

                                      <h1>{womanNumber}<span>%</span></h1>  

                                     </div>

                                     <div className="man">
                                        <div className="flex">
                                            <ul>
                                                    {
                                                        sexMan.map((item, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <div className="isTrue">
                                                                        <div className="head"></div>
                                                                        <div className="bodyHHMan"></div>
                                                                        <div className="leg"></div>
                                                                    </div>
                                                                    <div className="isFalse" style={{width: item * 100 + '%', 'transition': `width ${item==0?0:0.1}s ease ${item==0?0:index * 0.1}s`}}>
                                                                        <div className="head"></div>
                                                                        <div className="bodyHHMan"></div>
                                                                        <div className="leg"></div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }         
                                            </ul>
                                            <h1>{manNumber}<span>%</span></h1>  

                                        </div>     
                                    </div>      
                                      
                               </div>

                               <div className="setFont">
                                    <div className="echartPie" id="echartPie">

                                    </div>

                                    <div className="fontCOlor">
                                        <p>{PieRate} <span>%</span> </p>
                                        <p>35-45</p>
                                    </div>         
                               </div>                     
                               

         
                             </div>   

                             <div className="line"></div> 

                             <div className="addPartyNum">
                                <h1>新发展党员数量</h1>

                                <div className="echartLine" id="echartLine">

                                </div>

                             </div>

                             <div className="partyActive addPartyNum">
                                <h1>党员活动</h1>

                                <ul className="activeList">

                                   <div className="move" style={{'transition-duration': `${partyActive == 0? 0: 1}s`,'transform': `translate(0, ${-partyActive * 65}px)`}}>         
                                        
                                       {
                                           activeListArr.map((item, index) => {

                                             return (
                                                 <li key={index}>
                                                     <p>
                                                        {item.title}
                                                    </p>
                                                    <div>
                                                        {formatTime(new Date(item.addTime * 1000), 'yyyy-MM-dd')}
                                                    </div>
                                                 </li>
                                             )
                                           })
                                       } 
    
                                    </div>
                                </ul>

                             </div>



                            {/* <img src={img8} alt="" /> */}
                        </div>

                        <div className="earthCanvas">
                            <Eearth />                       
                        </div>     

                        <div className="earth">

                                            
                            
                            
                            <img className="headImg" src={img4} alt="" />


                            <img className="star" src={img2} alt="" />


                            <img className="build" src={img5} alt="" />

                            <h1>土木事业部 党建大屏</h1>    

                            

                        </div>

                        <div className="side leftSide rightSide">
                        {/* <img src={img8} alt="" /> */}
                            <div className="sideHead">
                                    <img src={img6} alt="" /> 

                                    <ul className="fistUl">
                                        <li>事业部党员数据概览</li>
                                        <li>National Party Menber Data Overview</li>
                                        <li>中国水利水电第十四工程局有限公司</li>
                                    </ul>
                             </div>

                             <div className="line"></div>   

                             <div className="num">

                                <b>事业部基层党组织</b><span className="span">{PartyOrganization} 个</span>    

                             </div>

                            <div className="line"></div> 

                            <div className="Progress">
                                <ul>
                                    <div className="move" style={{'transform': `translate(0, ${-projectActive * 55}px)`, 'transition-duration': `${projectActive?1:0}s`}}>
                                        
                                       {
                                           projectListArr.map((item, index) => {
                                            return (
                                                <li key={index + 'project'}>
                                                    <h2>{item.xulie} {item.name}</h2>
                                                    <Progress trailColor="rgba(255,255,255, 0.2)"  strokeColor="#FBC8AB" percent={item.partyMemberRatio} showInfo={false} />
                                                 </li>
                                            )
                                           })
                                       }      
                                    </div>
                                </ul>
                            </div>    


                            <div className="addPartyNum gailan">
                                <h1>党组织概览</h1>

                                <div className="flex">
                                <div className="setFont">
                                        <div className="echartPie" id="echartPie2">

                                        </div>

                                        <div className="fontCOlor">
                                            <p>{PieRate} <span>%</span> </p>
                                            <p>35以下</p>
                                        </div>         
                                </div>  

                                <div className="setFont">
                                        <div className="echartPie" id="echartPie3">

                                        </div>

                                        <div className="fontCOlor">
                                            <p>{PieRate} <span>%</span> </p>
                                            <p>35-45</p>
                                        </div>         
                                </div>  

                                <div className="setFont">
                                        <div className="echartPie" id="echartPie4">

                                        </div>

                                        <div className="fontCOlor">
                                            <p>{PieRate} <span>%</span> </p>
                                            <p>45以上</p>
                                        </div>         
                                </div>                     
                                                
                                </div>
                                
                             </div>


                            <div className="addPartyNum">
                                <h1>党员活动</h1>

                                <div className="setImg">
                                <img src={img7} alt="" />
                                </div>
                                
                                
                             </div>


                        </div>


                    </div>



                 </div>

               </div>
              </div >                        

            </div>
       


        {/* </Translate> */}


    </div >
  )
}

export default (withRouter(PartyBuild));

