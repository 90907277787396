import React from "react";


import {Button,  Modal, Timeline, Image, Empty } from "antd";

import './record.less';

import {formatTime} from '@/utils/util';

export default class Record extends React.Component {

  imageUrl =  process.env.REACT_APP_IMG_URL
    
  state = {
    herfEarth: '',
    isModalVisible: false,
    deviceListInfo: []
  }

  componentDidMount() {
    console.log(window.location)

    this.setState({
      herfEarth: window.location.origin + '/Earth' 
    })

  }


  handleOk() {
    this.setState({
        isModalVisible: false
    })
  }

  toSerch(item) {
    console.log(item)

    this.setState({
        isModalVisible: true
    })
    React.$axios.post('deviceInspectionLogList', {logic: 3 ,deviceId: item.id}).then(res => {
        res.data.list.map(item => {
            if(item.img) {
                item.img = item.img.split(',')
            } else {
                item.img = []
            }
        })
        this.setState({
            deviceListInfo: res.data.list
        })
        
    })

  }

  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  render() {
    return (
      <div className="Record">

        <Modal title="巡检记录"   width="35%" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
        
        <div className="RecordModal" >

        {
            function() {
                if(this.state.deviceListInfo.length == 0) {
                    return (
                        <Empty/>
                    )
                }
            }.bind(this)()
        }

        <Timeline>
            {
                this.state.deviceListInfo.map(item => {

                    return (
                        <div className="RecordSetHeight">
                        <Timeline.Item>
                            <p>{formatTime(new Date(item.editTime * 1000), 'yyyy-MM-dd')}</p>
                            <div >
                                <div className="recordResult">
                                    <div><p>巡检员</p>
                                        <p>{item.nameIdCard}</p>   
                                    </div>
                                    <div>
                                    <p>巡检结果</p>
                                    <p className={'status ' + 'color' + item.state }>{item.state == 2? '正常': '异常'}</p></div>
                                </div>

                                <div className="recordContext">
                                    <h1>检查内容</h1>
                                    <ul>
                                        {
                                            item.contentMany.map(item2 => {
                                                return (
                                                    <li> <p>{item2.content}</p>
                                                        <p>
                                                            <span className={'setStatus2' + item2.state}></span> 正常
                                                            <span className={'setStatus3' + item2.state}></span> 异常
                                                        </p>
                                                    </li>
                                                )
                                                
                                            })
                                        }
                                       
                                    </ul>
                                </div>   

                                <div className="recordQK">
                                <h1>巡检情况记录</h1> 

                                    <p>
                                        {item.remark}
                                    </p>

                                </div>

                                <div className="recordImg">
                                    <h1>现场照片</h1>

                                    <div className="setImg">
                                        

                                        <Image.PreviewGroup>
                                            {
                                                item.img.map(item => {
                                                    return (
                                                        <span style={{marginRight: 10}}>
                                                        <Image
                                                            width={100}
                                                            height={100}
                                                            
                                                            src={this.imageUrl + item}
                                                        />
                                                        </span>
                                                    )
                                                })
                                            }
                                            
                                    
                                        </Image.PreviewGroup>
                                    </div>

                                </div>

                            </div>
                        </Timeline.Item>
                        </div>
                    )


                })
            }

            
            {/* <Timeline.Item></Timeline.Item>
            <Timeline.Item></Timeline.Item>
            <Timeline.Item></Timeline.Item> */}
        </Timeline>
        </div>

        </Modal>


        {/* <a href={this.state.herfEarth}>进入地球</a>
        <a onClick={this.toPath.bind(this)}>进入大屏首页</a> */}


      </div>
    );
  }
}
