

// 图片文字
export const canvasFont = (ctx, text ,pos, color) => {
    const opacity = 1.0;

    ctx.fillStyle = color;
    ctx.font = "bold 100px 雅黑";
    ctx.lineWidth = 4;
    ctx.fillText(text,200,104);
    // 绘制圆型
    ctx.beginPath()
    ctx.arc(100, 74, 50, 0, 2*Math.PI, true);
    //设置填充颜色
    ctx.fillStyle = 'rgba(0,0,0,0)';
    ctx.fill();
    
    // 原形边框宽度
    ctx.lineWidth = '10';
    //边框颜色
    ctx.strokeStyle = '#606060';
    // 绘制圆形
    ctx.stroke();
    
    // 绘制圆型
    ctx.beginPath()
    ctx.arc(100, 74, 25, 0, 2*Math.PI, true);
    //设置填充颜色
    ctx.fillStyle = 'rgba(94,94,94,' +  opacity + ')';
    ctx.fill();

    // 原形边框宽度
    ctx.lineWidth = '1';
    //边框颜色
    ctx.strokeStyle = 'rgba(255,0,0, 0)';
    // 绘制圆形
    ctx.stroke();
}


// 获取图片
export const canvasAddImgFont = (img, text) => {
    return new Promise((reslove) => {

        let canvas = document.createElement("canvas");
        canvas.width =2000;
        canvas.height = 200;
        let ctx = canvas.getContext("2d");
        
         const image = new Image();;
         image.src = img;
         image.onload = (event) => {

            ctx.drawImage(image,0,0, 2000, 200, 0, 0, 3000, 200);
            ctx.fillStyle = "#ffffff";
            ctx.font = "bold 70px 雅黑";
            // ctx.lineWidth = 100;
            ctx.fillText(text,320,100);

            reslove(canvas); 
         }
    })
}


// 机电中心
export const canvasCenter = (img) => {
    return new Promise((reslove) => {

        let canvas = document.createElement("canvas");
        canvas.width =1500;
        canvas.height = 200;
        let ctx = canvas.getContext("2d");
    

         const image = new Image();;
         image.src = img;
         image.onload = (event) => {
            ctx.drawImage(image,0,0, 2000, 200, 0, 0, 2100, 200);	
            ctx.fillStyle = "#ffffff";
            ctx.font = "bold 70px 雅黑";
            ctx.fillText("中国水电十四局土木事业部",320,80);
            ctx.fillText("机电中心",320,180);
            reslove(canvas); 
         }
    })
}

