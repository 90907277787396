import React from "react";


import { Input,Select,Button, Table, message, Popconfirm, Modal, Image} from "antd";

import {formatTime} from '@/utils/util';

import { SearchOutlined} from '@ant-design/icons';

import './ConstructionLog.less';

const {Option} =  Select;


const imgUrl = process.env.REACT_APP_IMG_URL;

export default class  PartyActive extends React.Component {
    
    columns = [
      {
        title: "工程部位",
        dataIndex: 'projectLocation',
        align: 'center',
        width: 200
        // render: (_, item, index) => {
        //   return (
        //     <p>{index + 1}</p>
        //   )
        // }
      },
    //   {
    //     title: '桩号',
    //     dataIndex: 'stationNumber',
    //     align: 'center'
    //   },
        {
          title: '日期',
          dataIndex: 'date',
          align: 'center',
          width: 150
        },
        {
            title: '记录时段',
            dataIndex: 'time',
            align: 'center',
            width: 100
         },
        {
          title: '班次',
          dataIndex: 'classes',
          align: 'center',
          width: 100
        },
        {
          title: '施工部位',
          dataIndex: 'position',
          align: 'center'  
        },
        {
          title: '天气情况',
          dataIndex: 'weather',
          align: 'center',
          width: 100
        },
        {
          title: '工班组/人员',
          dataIndex: 'personnel',
          align: 'center',
        },
        {
            title: '质量情况',
            dataIndex: 'mass',
            align: 'center',
            width: 200
        },
        {
            title: '交接班',
            dataIndex: 'changeShifts',
            align: 'center',
        },

        {
            title: '操作',
            dataIndex: 'remark',
            align: 'center',
            render:(_,item) => {
                return (
                    <div style={{display: 'flex', width: 160}}>
                  
                        <div onClick={this.getDetail.bind(this, item)} style={{color: '#6A9BF4', cursor:'pointer' , marginRight: 20}}>详情</div>
                        <a target="_blank" href={imgUrl + item.pdf} style={{marginRight: 20, color: '#6A9BF4', cursor:'pointer', display: item.pdf?'block':'none'}}>打印预览</a>
                        
                        <Popconfirm
                            title="是否确认删除?"
                            onConfirm={this.toDel.bind(this, item.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                          <p style={{color: '#ff0000 ', cursor:'pointer'}}>删除</p>
                        </Popconfirm>
                    </div>
                    
                    
                )
            }
        },
      ]


  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    TableParams: {
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 1,
        projectLocation: null

        // meStatus: '',
        // pouringNo: '',
        // taskStatus: '',
    },

    infoMsg: {
      signIn: [],
      singUp: []
    }, // 详情信息
    isModalVisible: false,
    detailItem: {
      img: []
    },
    listPouringPermitList: [],
    isDetailShow: false
  }




  componentDidMount() {
    this.getTableData()
    
  }

  // 获取详情信息
  getDetail(item) {
    const {projectId, pouringNo}= item;
    this.setState({
      isDetailShow: true
    })

    // React.$axios.post('openPositions/listPouringPermit', {projectId, pouringNo, logic: 2}).then(res => {
    //   res.data.approvalLogMany.map(item => {
    //     item.addTime = formatTime(new Date(item.addTime * 1000), 'yyyy.MM.dd hh:mm')
    //   })
    //   if(res.data.img) {
    //     res.data.img = res.data.img.split(',');
    //   } else {
    //     res.data.img = []
    //   }
      
    console.log(item);
      this.setState({
        // listPouringPermitList: res.data.approvalLogMany,
        detailItem: item
      })

    // })



  }

  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

    //    let projectId =  React.$getStorage('projectInfo').id
      React.$axios.post('constructionLogList', {...this.state.TableParams}).then(res => {
        
        console.log(res);
        this.state.pagination.total = res.data.countList

        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    console.log(name)
    this.state.TableParams.projectLocation = value
  }

  toSerch2() {
    this.getTableData(true)
  }



  toDel(id) {
    React.$axios.post('constructionLogSave', {id, del: 2}).then(res => {
        if(res.code == 20000) {
            message.success('删除成功')
            this.getTableData()
        } else {
            message.error('删除失败')
        }
    })
  }

  handleOk() {
    this.setState({
        isModalVisible: false
    })
  }

  handleCancel() {
    this.setState({
      isDetailShow: false
    })
  }

 
  handleTableChange(value) {
    this.state.pagination = value
    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }


  onChangeT(name, value) {
    this.state.TableParams[name] = value;
    this.setState({
      TableParams: this.state.TableParams
    })
  }

  render() {
    return (
      <div className="PartyActive">
          <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>工程部位:</span> 
                        <Input onChange={this.serchCar.bind(this, 'projectLocation')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                        
                        </li>
                        {/* <li><span>任务状态:</span>  
                          <Select
                              showSearch
                              style={{ width: 200, height:30, marginRight:20,  }}
                              placeholder="请选择"
                              optionFilterProp="children"
                              onChange={this.onChangeT.bind(this, 'taskStatus')}
                          >
                          <Option value=" ">全部</Option>
                          <Option value="1">待处理</Option>
                          <Option value="3">已完成</Option>
                          </Select>
                        </li>

                        <li><span>状态:</span>  
                          <Select
                              showSearch
                              style={{ width: 200, height:30, marginRight:20,  }}
                              placeholder="请选择"
                              optionFilterProp="children"
                              onChange={this.onChangeT.bind(this, 'status')}
                          >
                            <Option value=" " key="1">全部</Option>
                            <Option value="2" key="2">我的</Option>
                          </Select>
                        </li> */}



                            <Button  className="btn" onClick={this.toSerch2.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>
                    </ul>
                </div>



                <div className="table2">
                {/* <div class="allBtn">
                   
                    <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                        导入
                    </Button>
                    <Button  className="bt" type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button>
                    <Button  className="bt" type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button>
                    </div> */}


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                   
                    loading={this.state.loading} rowKey={record => record.id} columns={this.columns} dataSource={this.state.tableData}  />
                </div>
            
{/* 
                <Modal
                    title="详情"
                    visible={this.state.isModalVisible}
                    onOk={this.handleOk.bind(this)}
                    onCancel={this.handleCancel.bind(this)}
                    >
                      
                    
                </Modal> */}


<Modal title={this.state.detailItem.date + "施工日志详情"} width="50%"  className="BlankingListModal ConstructionLogModal"  visible={this.state.isDetailShow}  onOk={this.handleCancel.bind(this)} onCancel={this.handleCancel.bind(this)}>
                   <div className="box">
                   

                  
                   <ul className="inputLi">
                      {/* <li>
                      <span>编号：</span> {this.state.detailItem.pouringNo}
                      </li> */}

                      <li>
                          <span>工程部位：</span> 
                          <div>
                          {this.state.detailItem.projectLocation}
                          </div>
                      </li>
                      <li>
                          <span>日期：</span> 
                          <div>{this.state.detailItem.date}</div>
                          
                      </li>
                      <li>
                          <span>记录时段：</span>
                          <div>{this.state.detailItem.time}</div>
                           
                      </li>
                      <li>
                          <span>班次：</span> 
                          <div>{this.state.detailItem.classes}</div>
                          
                      </li>
                      <li>
                          <span>施工部位：</span> 
                          <div>{this.state.detailItem.position}</div>
                      </li>
                      <li>
                          <span>天气情况：</span> 
                          <div>
                          {this.state.detailItem.weather}
                          </div>
                      </li>
                      <li>
                          <span>施工班组/人员：</span> 
                          <div>
                          {this.state.detailItem.personnel}
                          </div>
                      </li>
                      <li>
                          <span>内容：</span> 
                          <div>
                          {this.state.detailItem.content}
                          </div>
                      </li>
                      <li>
                          <span>质量情况：</span>
                          <div>
                          {this.state.detailItem.mass}
                          </div>
                      </li>
                      <li>
                          <span>安全、文明施工情况：</span> 
                          <div>
                          {this.state.detailItem.safe}
                          </div>
                      </li>
                      <li>
                          <span>交接班：</span> 
                          <div>
                          {this.state.detailItem.changeShifts}
                          </div>
                      </li>
                      <li>
                          <span>记录人签名：
                          

                          </span>
                          <div className="imgDiv">
                          <Image
                                      style={{display: this.state.detailItem.user?'':'none'}}
                                      
                                      width={120}
                                      height={80}
                                      src={imgUrl + this.state.detailItem.user}
                                  /> 
                          </div>
                      </li>

                      
                      <li>
                          <span>当班负责人签名：
                         
                          </span>
                          <div className="imgDiv">
                          <Image
                                      style={{display: this.state.detailItem.onDutyUser?'':'none'}}
                                      
                                      width={120}
                                      height={80}
                                      src={imgUrl + this.state.detailItem.onDutyUser}
                                  /> 
                          </div>
                      </li>


                      
                    </ul> 

                    

                    </div>




                    
      </Modal>    
                      
                                                                                            
      </div>
    );
  }
}
