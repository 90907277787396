import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Input,Select,Button, Table, Modal, message} from "antd";
import './roster.less';

function ErrorTable(props) {

  const columns = [
    {
      
      title: '姓名',
      dataIndex: 'A',
      align: 'center',
      fixed: 'left',
      width: 150,
      render:(_,item) => {
          
        return (
          <div>
              <input type="text" defaultValue={item.A} onInput={(e) => changeInput(e, item, 'A')} />
              {/* {!_?'-':_} */}
              </div>
        )
      }
    },
    {
      
        title: '身份证号码',
        dataIndex: 'C',
        width: 200,
        align: 'center',
        fixed: 'left',
        render:(_,item) => {
          return (
            <div>
               <input type="text" defaultValue={item.C} onInput={(e) => changeInput(e, item, 'C')} />
            </div>
          )
        }
    },
    {
      
        title: '手机号码',
        dataIndex: 'B',
        width: 180,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
                <input type="text" defaultValue={item.B} onInput={(e) => changeInput(e, item, 'B')} />
            </div>
          )
        }
    },
    
    {
      
        title: '公司',
        dataIndex: 'D',
        width: 300,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
               <input type="text" defaultValue={item.D} onInput={(e) => changeInput(e, item, 'D')} />
            </div>
          )
        }
    },
    {
      
        title: '部门',
        dataIndex: 'E',
        width: 150,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
                <input type="text" defaultValue={item.E} onInput={(e) => changeInput(e, item, 'E')} />
            </div>
          )
        }
    },
    {
      
        title: '岗位',
        dataIndex: 'F',
        width: 150,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
               <input type="text" defaultValue={item.F} onInput={(e) => changeInput(e, item, 'F')} />
            </div>
          )
        }
    },
    {
      
        title: '进场时间',
        dataIndex: 'G',
        width: 120,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
               <input type="text" defaultValue={item.G} onInput={(e) => changeInput(e, item, 'G')} />
            </div>
          )
        }
    },
    {
      
        title: '政治面貌',
        dataIndex: 'H',
        width: 100,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
                <input type="text" defaultValue={item.H} onInput={(e) => changeInput(e, item, 'H')} />
            </div>
          )
        }
    },
    {
      
        title: '人员类型',
        dataIndex: 'I',
        width: 150,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
                <input type="text" defaultValue={item.I} onInput={(e) => changeInput(e, item, 'I')} />
            </div>
          )
        }
    },
    {
      
        title: '所属单位人员类型',
        dataIndex: 'J',
        width: 180,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
                <input type="text" defaultValue={item.J} onInput={(e) => changeInput(e, item, 'J')} />
            </div>
          )
        }
    },
    {
      
        title: '是否安全教育',
        dataIndex: 'K',
        width: 150,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
                <input type="text" defaultValue={item.K} onInput={(e) => changeInput(e, item, 'K')} />
            </div>
          )
        }
    },
    {
      
        title: '人员类别',
        dataIndex: 'L',
        width: 150,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
                <input type="text" defaultValue={item.L} onInput={(e) => changeInput(e, item, 'L')} />
            </div>
          )
        }
    },
    {
      
        title: '工资',
        dataIndex: 'M',
        width: 70,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
                <input type="text" defaultValue={item.M} onInput={(e) => changeInput(e, item, 'M')} />
            </div>
          )
        }
    },
    {
      
        title: '紧急联系人姓名',
        dataIndex: 'N',
        width: 140,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
                <input type="text" defaultValue={item.N} onInput={(e) => changeInput(e, item, 'N')} />
            </div>
          )
        }
    },
    {
      
        title: '紧急联系人电话',
        dataIndex: 'O',
        width: 140,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
                <input type="text" defaultValue={item.O} onInput={(e) => changeInput(e, item, 'O')} />
            </div>
          )
        }
    },
    {
      
        title: '银行卡号',
        dataIndex: 'P',
        width: 250,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
               <input type="text" defaultValue={item.P} onInput={(e) => changeInput(e, item, 'P')} />
            </div>
          )
        }
    },
    {
      
        title: '开户行',
        dataIndex: 'Q',
        width: 350,
        align: 'center',
        render:(_,item) => {
          return (
            <div>
                <input type="text" defaultValue={item.Q} onInput={(e) => changeInput(e, item, 'Q')} />
            </div>
          )
        }
    },
    {
      
        title: '失败信息',
        dataIndex: 'msg',
        align: 'center',
        fixed: 'right',
        width: 200,
        render:(_,item) => {
          return (
            <div>{!_?'-':_}</div>
          )
        }
    },
    
  ]  


 

  useEffect(() => {

    
  }, []);


  const handleOk = () => {

    console.log(props.list)
    return
    React.$axios.post('userImport', props.list).then(({ data }) => {
         if(data.error.length > 0) {
            props.setErrorList(data.error);

         } else {
            props.setErrorList(false);
            handleCancel()
         }
    })


    

  }


  const changeInput =(e, item, index) =>{
      item[index] = e.target.value;
  }


  const handleCancel = () => {
    props.toClose()
  }

  return (
    <div className="ErrorTable">
        <Modal width="90%" className="ErrorTableModal" visible={props.show}
            onOk={handleOk}
            onCancel={handleCancel}
              title="需要重新导出数据">

                
<Table  align="center" bordered rowClassName="editable-row" pagination={{
                                                                pageSize: 50,
                                                            }}
 rowKey={record => record.C}   columns={columns} dataSource={props.list} scroll={{
    x: 1500,
    y: 500,
  }}  />




        </Modal>
    

    </div>
  )
}

export default withRouter(ErrorTable);