import React from "react";

import { message, Image} from "antd";
// 添加furon

 
import { Uploadhh } from "@/components/upload/upload";

import './MobilePhone.less';


export default class MobilePhone extends React.Component {
  FormSbmitRef = null
  imageUrl = process.env.REACT_APP_IMG_URL
  state = {
    
    loading: false,
    img: [],
    indexCarouselAppImgList: [],
    indexCarouselImgList: [], // 项目图片
    partyBuildingImgList:[], // 党建图片
    progressNode: [], // 项目介绍
    behaviorManagement: [], // 行为管理
  } 
  


  changeStatus(e) {
    this.setState({
      status: e
    })

    React.$setStorage('pageIndex1', e)
 }

 componentWillMount () {
    let t =  React.$getStorage('pageIndex1')
    if(t) {
        this.setState({
            status: t
        })
    }
}

  componentDidMount() {
    // this.getList()
    

    React.$axios.post('infoProject').then(res => {
        // console.log(res)
        let data = res.data.infoProject[0]

        let indexCarousel =  data.indexCarouselApp?data.indexCarouselApp.split(','): []

        let indexCarouselImgList =  JSON.parse(data.indexCarousel)
        let partyBuildingImgList = data.partyBuildingImg?data.partyBuildingImg.split(','): []
        // Img 进度
        let progressNode =data.progressNode?data.progressNode.split(','): [];
        let behaviorManagement = data.behaviorManagement?data.behaviorManagement?.split(','): [];

        this.setState({
            indexCarouselAppImgList: indexCarousel,
            indexCarouselImgList: indexCarouselImgList,
            partyBuildingImgList:partyBuildingImgList,
            progressNode: progressNode,
            behaviorManagement: behaviorManagement
        })  

    })

  }


  // 组件离开生命周期
  componentWillUnmount() {
    // React.$setStorage('pageIndex1', null)
    localStorage.removeItem('pageIndex1');
  }


  getList() {
    // this.setState({
    //     loading: true
    // })
  
  }


  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }




  setInput(name, { target: { value } }) {

    this.state.requestParmas[name] = value
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  /**
   * 
   * @param {*} type 类型
   * @param {*} params 上一层数据
   * @param {*} item  返回数据
   */
  handleFinger(type,params, item) {

    if(!type || !params) {
        return
    }


    this.state[type] = [...this.state[type], ...item.data.key]
    
    this.setState({
        [type]: this.state[type]
    })

    this.toSaveProject(type ,params)

  }

  toSaveProject(type ,params) {
    let params2 = {
        [params]: this.state[type] 
    }
    
    React.$axios.post('projectEditSave', params2).then(res => {
        if(res.code == 20000) {
            message.success('保存成功')
        }
    })
  }

  // 删除图片
  reMoveImgList(index, list, params) {
    this.state[list].splice(index, 1)
    
    this.setState({
        [list]: this.state[list]
    })

    console.log(list)
    console.log(params)

    this.toSaveProject(list,params)
  }



  render() {
    return (
      <div className="BannerConfig">
  
          
        
          <div>
               <div className="warp1">
                   <h1>首页</h1>

                   <div className="allData">
                    <div className="setData">
                        <h2>1 .轮播图:</h2>

                        <div className="flex">

                                {
                                    <ul className="setFingerList flex">
                                        <Image.PreviewGroup>
                                            {
                                                this.state.indexCarouselAppImgList.map((item, index) => {
                                                    return (
                                                        <span style={{marginRight: 20, marginBottom:10, position: 'relative'}} key={index}>
                                                        <span className="removeImgList"  onClick={()=> this.reMoveImgList(index, 'indexCarouselAppImgList', 'indexCarouselApp')}>×</span>
                                                    <Image
                                                        width={300}
                                                        height={100}
                                                        src={this.imageUrl + item}
                                                    />
                                                    </span>
                                                    )
                                                })
                                            } 
                                        </Image.PreviewGroup>  

                                        <Uploadhh  listStatus={true} pdfStatus={true}   data={{type: 48, pixel: ''}}  width={300} height={100} handleClick={this.handleFinger.bind(this, 'indexCarouselAppImgList', 'indexCarouselApp')} />  
                                    </ul> 
                                }



                                
                            </div>   
                    </div>
                    
                   </div>
               
               </div> 


                {/* 劳务管理                 */}
               <div className="warp1">
                   <h1>项目介绍</h1>

                   <div className="allData">
                    <div className="setData">
                        <h2>1 .轮播图: （建议尺寸: 1920x1080, 4:3）</h2>

                        <div className="flex">

                        {
                            <ul className="setFingerList flex">
                                <Image.PreviewGroup>
                                    {
                                        this.state.progressNode.map((item, index) => {
                                            return (
                                                <span style={{marginRight: 20, marginBottom:10, position: 'relative'}} key={index}>
                                                <span className="removeImgList"  onClick={()=> this.reMoveImgList(index, 'progressNode', 'progressNode')}>×</span>
                                            <Image
                                                width={140}
                                                height={100}
                                                src={this.imageUrl + item}
                                            />
                                            </span>
                                            )
                                        })
                                    } 
                                </Image.PreviewGroup> 
                                <Uploadhh  listStatus={true} pdfStatus={true}   data={{type: 74, pixel: ''}}  width={140} height={100} handleClick={this.handleFinger.bind(this, 'progressNode', 'progressNode')} />   
                            </ul> 
                            
                        }        


                                {/* <Uploadhh  listStatus={true}   data={{type: 66}}  width={140} height={100} handleClick={this.handleFinger.bind(this)} /> */}
                            </div>   
                    </div>
                   </div>
               
               </div> 

                {/* 设备管理模块                 */}
               <div className="warp1">
                   <h1>党建板块</h1>

                   <div className="allData">
                    <div className="setData">
                        <h2>1 .轮播图: （建议尺寸: 1920x1080, 4:3）</h2>

                        <div className="flex">

                                {
                                    <ul className="setFingerList flex">
                                        <Image.PreviewGroup>
                                            {
                                                this.state.partyBuildingImgList.map((item, index) => {
                                                    return (
                                                        <span style={{marginRight: 20, marginBottom:10, position: 'relative'}} key={index}>
                                                        <span className="removeImgList"  onClick={()=> this.reMoveImgList(index, 'partyBuildingImgList', 'partyBuildingImg')}>×</span>
                                                    <Image
                                                        width={140}
                                                        height={100}
                                                        src={this.imageUrl + item}
                                                    />
                                                    </span>
                                                    )
                                                })
                                            } 
                                        </Image.PreviewGroup> 
                                        <Uploadhh  listStatus={true} pdfStatus={true}   data={{type: 48, pixel: ''}}  width={140} height={100} handleClick={this.handleFinger.bind(this, 'partyBuildingImgList', 'partyBuildingImg')} />   
                                    </ul> 
                                    
                                }
                            </div>   
                    </div>
                   </div>
               
               </div> 


               {/* 安全之星模块                 */}
               <div className="warp1">
                   <h1>行为管理</h1>

                   <div className="allData">
                    <div className="setData">
                        <h2>1 .轮播图: （建议尺寸: 1920x1080, 4:3）</h2>

                        <div className="flex">

                            {
                                    <ul className="setFingerList flex">
                                        <Image.PreviewGroup>
                                            {
                                                this.state.behaviorManagement.map((item, index) => {
                                                    return (
                                                        <span style={{marginRight: 20, marginBottom:10, position: 'relative'}} key={index}>
                                                        <span className="removeImgList"  onClick={()=> this.reMoveImgList(index, 'behaviorManagement', 'behaviorManagement')}>×</span>
                                                    <Image
                                                        width={140}
                                                        height={100}
                                                        src={this.imageUrl + item}
                                                    />
                                                    </span>
                                                    )
                                                })
                                            } 
                                        </Image.PreviewGroup> 
                                        <Uploadhh  listStatus={true} pdfStatus={true}  data={{type: 75, pixel: ''}}  width={140} height={100} handleClick={this.handleFinger.bind(this, 'behaviorManagement', 'behaviorManagement')} />   
                                    </ul> 
                                    
                                }

                                
                            </div>   
                    </div>
                   </div>
               
               </div>


               {/* <div className="warp1">
                   <h1>劳务考评</h1>

                   <div className="allData">
                    <div className="setData">
                        <h2>1 .轮播图: （建议尺寸: 1920x1080, 4:3）</h2>

                        <div className="flex">

                                {
                                    <ul className="setFingerList flex">
                                        <Image.PreviewGroup>
                                            {
                                                this.state.img.map((item, index) => {
                                                    return (
                                                        <span style={{marginRight: 20, position: 'relative'}} key={index}>
                                                        <span className="removeImgList"  onClick={()=> this.reMoveImgList(index, 'fingerList')}>×</span>
                                                    <Image
                                                        width={80}
                                                        height={100}
                                                        src={this.imageUrl + item}
                                                    />
                                                    </span>
                                                    )
                                                })
                                            } 
                                        </Image.PreviewGroup> 
                                        <Uploadhh  listStatus={true}   data={{type: 48}}  width={80} height={100} handleClick={this.handleFinger.bind(this)} />   
                                    </ul> 
                                }



                                
                            </div>   
                    </div>
                   </div>
               
               </div> */}


               

          </div> 

      </div>

    );
  }
}
