import React, { useState, useEffect } from "react";
import { Tree,message, Spin} from 'antd';
import {  withRouter } from "react-router-dom";

import Header2 from "@/components/Header/Header";
import './BIMCenter.less';

import {
  UnorderedListOutlined
} from '@ant-design/icons';


import {Measure} from './Measure';

import {treeDataDom} from './tool';


import img1 from '@/assets/img/newReviseImg/imgAndIcon/celiang.png';
import img2 from '@/assets/img/newReviseImg/imgAndIcon/pomian.png';
import img3 from '@/assets/img/newReviseImg/imgAndIcon/geli.png';
import img4 from '@/assets/img/newReviseImg/imgAndIcon/yincang.png';
import img5 from '@/assets/img/newReviseImg/imgAndIcon/xianshiquanbu.png';
import img6 from '@/assets/img/newReviseImg/imgAndIcon/shuxin.png';
import img7 from '@/assets/img/newReviseImg/imgAndIcon/manyou.png'; 


let threeDom = null;
let measure = null;
let mouveStatus = true;
let clientPosition = {};
const exampleModal = require('@/assets/projiect1.ifc');
//const exampleModal = require('@/assets/example.ifc');


const arrImgList = [{
    img: img1,
      name: '测量',
      index: 1
    },
    ,{
    img: img2,
    name: '剖切',
    index: 2
    },{
    img: img3,
    name: '隔离',
    index: 3
    }, {
    img: img4,
    name: '隐藏',
    index: 4
    }, {
    img: img5,
    name: '全部显示',
    index: 5
    },
    {
        img: img6,
        name: '属性框',
        index: 6
    },
    {
      img: img7,
      name: '漫游',
      index: 7
    }
  ];



let BuildingMsg = []; // 楼层信息

function HomeRouter() {
  
  const [treeData, setTreeData] =useState([]); 
  const [treeDataModal, setTreeDataModal] = useState([]);
  const [loadTree, setLoadTree] = useState(false); // 属性
  const [loadTree2, setLoadTree2] = useState(false); // 项目浏览器
  const [currentIndex, setCurrentIndex] = useState(0);

  const [projectList, setProjectList] = useState([]);
  const [projectActive, setProjectActive] = useState(0);

  const [loading, setLoading] = useState(true);
  const [paramsLoading, setParamsLoading] = useState(false);



  const getProject = (res, arr, buildingArr) => {
 
    if(res.children &&  Array.isArray(res.children)) {
        res.children.map((item, index) => {
             item.title = treeDomList2(item.type, '');
             item.key = res.key + '-' + index;
             arr.push({expressID: item.expressID})
             if(item.type == "IFCBUILDINGSTOREY") {
              buildingArr.push(item.expressID)
             }

              getProject(item, arr, buildingArr);   
        })

    }

  }

  useEffect(() => {

    let ModalId =  React.$getStorage('ModalId');

    if(!ModalId) {
      ModalId = 0;
    }

    measure = new Measure(threeDom);
    getList(ModalId)

    return () => {
      measure.dispose()
      React.$removeStorage('ModalId');
    } 
  }, []);

  const getList= (index) => {
    React.$axios.post('infoProject').then(res => {
      let arr = JSON.parse(res.data.infoProject[0].bim);
        if(!res.data.infoProject[0].bim) return;
        setProjectList(arr);

        // setLoading(false);
        // return
        // exampleModal.default
        measure.loadModal(exampleModal.default, (res) => {
        // measure.loadModal(arr[index].url, (res) => {
          res.title = treeDomList2(res.type, '');
          res.key = 0;
          let arr2 = [];
          let buildingArr = [];
          getProject(res, arr2, buildingArr);

          
          // 设置树结构
          setTreeDataModal([res]);
          // 更新树
          setLoadTree2(true);
          // 添加模型
          measure.checkedKeys(arr2, true);

          // 获取全部楼层信息
          getBuildingAllMsg(buildingArr);

          setLoading(false);
      });

    })
  }

  // 获取全部楼层信息
  const getBuildingAllMsg = (buildingArr)=> {
    let arr = [];
    buildingArr.map( (item) => {
       
    const data = measure.getItemProperties(0, parseInt(item))
    arr.push(data)  
       
    })

    // 获取全部楼层信息
    Promise.all(arr).then(function(values) {
      BuildingMsg = values;
    });


  }


  // 切换项目
  const changeProject = (item, index) => {
    React.$setStorage('ModalId', index);
    setProjectActive(index);
}

 

  const treeDomList2 = (name, value) => {
    return (
        <div className="treeD"><div className="treet1">{name}</div>  <div className="treet2">{value}</div> </div>
    )
  }


 // 去选择 
 const onSelect = () => {
    
 }
 
 // 选择显示和隐藏
 const onCheck = (checkedKeysValue, value) => {

    measure.checkedKeys(value.checkedNodes);
  };


 const onExpand = (expandedKeysValue, expanded) => {

    // setExpandedKeys(expandedKeysValue);
 }


 const getItem = (item) => {
        if(item.index == currentIndex) {
          return;
        } else {
            if(currentIndex != 0) {
                message.error('请先清除上一步操作')
                return
            }

            setCurrentIndex(item.index)
        }

        


        if(item.index == 1) { // 测试距离
          measure.distance((e) => {
            if(!e) { //返回false 则归零
              setCurrentIndex(0)
            } else {
              
            }
          })
        } else if(item.index == 2) {// 剖切
          measure.Sectioning((e) => {
            if(!e) { //返回false 则归零
              setCurrentIndex(0)
            } else {
               

            }
          })
        } else if(item.index == 3) {// 隔离
          measure.Quarantine((e) => {
            if(!e) { //返回false 则归零
              setCurrentIndex(0)
            } else {
               

            }
          })

        } else if(item.index == 4) {
          measure.hide((e) => {
            if(!e) { //返回false 则归零
              setCurrentIndex(0)
            } else {
               
            }
          })
        } else if(item.index == 5) {
          // allShow
          measure.allShow();
          setCurrentIndex(0);
        } else if(item.index == 6) { // 属性框
          // pick
          measure.attribute((e) => { // 有值返回则修改为0 表示结束操作
            if(e) {
              setParamsLoading(true)

              // 找到最近的
              let fitItem = null;
              let minItem = 0;
              BuildingMsg.map(item => {
                let min = e.expressID - item.expressID;
                if(min > 0) { // 获取大于0的
                  if(!fitItem) {
                    fitItem = item;
                    minItem = min;
                  }else {
                    minItem = Math.min(minItem, min); // 选择最小的

                    if(minItem == min) { // 当前最小的已经替换就跟着替换
                      fitItem = item;
                    }
                  }
                }
              })

              e.parentParams = fitItem;

              setTimeout(() => {
                const arr = treeDataDom(e); 
                setTreeData(arr)
                setLoadTree(true)

                setParamsLoading(false);
              },200)
            } else {
              setCurrentIndex(0)
            }

          });
        }
 }





  return (
    
    <Header2 index={2}>
      <Spin spinning={loading}>
      
      

   <div className="BIMCenter">   {/*   */}

      {/* onMouseDown={pick} onMouseUp={onUp} */}
       <div ref={(e) => {threeDom = e}} className="threeRef" >

       </div>

       <div className="selectproject">
             <h2><UnorderedListOutlined /> 模型列表</h2> 
              <ul>
                {
                    projectList.map((item, index) => {
                        return (
                            <li className={projectActive == index? 'active': ''} onClick={() => {changeProject(item, index)} } key={index}>{item.name}</li>
                        )
                    })
                }
              </ul>  
        </div>



       {/* <input
              type="file"
      /> */}

      <div className="position">
        <div className="top">
            <h3>项目浏览器</h3>
            <ul>
                    <li>活跃的</li>
                    <li>形成</li>
                    <li>名称</li>
            </ul>
            <div className="tree">
                {
                  loadTree2? <Tree
                  checkable
                  onExpand={onExpand}
                  onCheck = {onCheck}
                  onSelect={onSelect}
                  defaultCheckedKeys={[0]}
                  defaultExpandAll={true}
                  treeData={treeDataModal}
                  showLine={{showLeafIcon: false}}
              /> : null               
                }
            </div>
            
        </div>

        <div className="botom">
           
            <h3>属性</h3>

            <Spin spinning={paramsLoading}>    
            <div className="tree">

                {loadTree?<Tree
                    showLine={{showLeafIcon: false}}
                    treeData={treeData}
                    defaultExpandAll = {true}
                />: null}
            </div>
            </Spin>
           
        </div>


      </div>

      <div className="selectFun">
            <ul>
                {
                    arrImgList.map((item, index) => {
                        return (
                            <li className={currentIndex == item.index? 'active': ''} key={index} onClick={() => {getItem(item)}}>
                                <img src={item.img} alt="" />
                                <p>{item.name}</p>
                            </li>
                        )
                    })
                }

            </ul>   
        </div>


    </div>
    </Spin>
</Header2>
  )
}

export default withRouter(HomeRouter);