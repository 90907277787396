import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Form, Button, DatePicker, Table, Input, Select, Tabs  } from 'antd';
import './MixingBill.less'


import BillDay from './billDay/billDay';


import BillMonth from './billMonth/billMonth';

function MixingBill() {


    const [status, setStatus] = useState(0)


    function changeStatus(status) {
        setStatus(status)
    }

 



  return (
    <div className="MixingBill">
        <div className="btn">
           <Button  type={status==0? 'primary': ''}  className="setBtn" onClick={() => {changeStatus(0)} }>日账单</Button>
           <Button className="setBtn" type={status==1? 'primary': ''}  onClick={() => {changeStatus(1)} }>月账单</Button>
          </div>


          {
              function() {
                if(status==0) {
                    return (
                        <BillDay/>
                    )
                } else {
                    return (
                        <BillMonth/>
                    )
                }
              }()
          }


        

            
    </div >
  )
}

export default withRouter(MixingBill);