import React from "react";

import {Button, Table, Modal, Form, Input, message, Select, DatePicker} from "antd";



import './ProgressNode.less';

import moment from 'moment';


import {formatTime} from '@/utils/util';


const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 2 },
  };


export default class ProgressNode extends React.Component {
  state = {
    status: 0,
    loading: false,
    tableData: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    requestParmas: {
      content: null,
      time: null,
      weigh: 0,
    },
    params : {
        lengthLimit: 10,
        offsetLimit: 0
    },

    teamAllList: [],
  } 
  FormSbmitRef = null
  


 columns = [
    {
      title: '日期',
      dataIndex: 'time',
      align: 'center',
      width: 250,
      render: (_) => {
        let date = null
        if(_ != 0) {
          date =formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')  
        } else {
          date = 0
        }
        return (
          <p>
            {
              date
            }
          </p>
        )
      }
    },
    {
      
      title: '权重',
      dataIndex: 'weigh',
      align: 'center',
      width: 100
    },
    {
      title: '内容',
      dataIndex: 'content',
    }, 
    {
      title: '操作',
      dataIndex: 'address',
      align: 'center',
      width: 100,
      render:(_,item) => {
        return (
          <div className="operation">
              <p onClick={() => this.clickFun(item, '2')}>编辑</p>
          </div>
        )
      }
    },
  ]


  componentDidMount() {
    this.getList()
    this.getTeamAll()
  }




  handleTableChange(pagination) {

    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize

    this.setState({
      params: this.state.params,
      pagination: pagination
    })


    // 执行更新列表
    this.getList()
  }


  clickFun(item) {


    this.setState({
        isModalVisible: true
    })


    for(let i in this.state.requestParmas) {
      this.state.requestParmas[i] = item[i]
    }

    this.state.requestParmas.time = moment(item.time * 1000);

    this.state.requestParmas.id = item.id

    this.setState({
      requestParmas: this.state.requestParmas
    })



    
    this.clierFormSbmitRef()


  }


  clierFormSbmitRef() {
    if(!this.FormSbmitRef) {
        setTimeout(() => {
          
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
  }



  getList() {
    this.setState({
        loading: true
    })
    React.$axios.post('progressList', this.state.params).then(res => {

        this.state.pagination.total = res.data.countList
        
        // this.state.pagination.total = res.data.countList
        this.setState({
            tableData: res.data.list,
            loading: false,
            pagination: this.state.pagination
        })

    })
  }

  // 获取岗位
  getTeamAll() {
    React.$axios.post('teamAll').then(res => {
        this.setState({
            teamAllList:res.data.teamAll
        })
    })
  }


  handleCancel() {
    this.setState({
        isModalVisible: false
    })
  }

  handleOk() {
    // console.log(this.state.requestParmas)  
    
    this.FormSbmitRef.submit()
  }

  // 点击新增
  add() {
   
     console.log(this.state.requestParmas)

      for(let i in this.state.requestParmas) {
        this.state.requestParmas[i] = null
      }
      this.state.requestParmas.weigh = 0
      delete this.state.requestParmas.id

      this.setState({
        isModalVisible: true,
        requestParmas: this.state.requestParmas
      })

      this.clierFormSbmitRef()

  }

  setInput(name, { target: { value } }) {

    this.state.requestParmas[name] = value
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  // 选择下拉数据
  handleChange(value, item) {
    this.state.requestParmas[value] = item
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }
  

  handleFinger(item) {
    this.state.requestParmas.img = [...this.state.requestParmas.img, ...item.data.key]
    this.setState({
        requestParmas: this.state.requestParmas
    })
  }

  // 
  onFinish(value) {
    value.time = parseInt((+value.time._d) / 1000)  

    for(let i in value) {
      this.state.requestParmas[i] = value[i]
    }

    React.$axios.post('progressEditSave', this.state.requestParmas).then(res => {
      
      if(res.code == 20000) {
        message.success('保存成功')

        this.setState({
          isModalVisible: false  
        })
          
        this.getList()
      }

    })
  }


  onChangeSelect() {
    
  }



  render() {
    return (
      <div className="ProgressNode">
          <div style={{display: this.state.status==0? 'block': 'none'}}>
            <div className="warp1">
                <div className="head">
                    <button onClick={this.add.bind(this)}>新增</button>
                </div>


                <div className="table">
                <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id}  columns={this.columns} dataSource={this.state.tableData}  />

                </div>
        
            </div>
          </div>


          <Modal title="添加" width="50%" className="ProgressNodeModal" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                        <Form
                            layout="vertical"
                            onFinish={this.onFinish.bind(this)}
                            ref={(el) => this.FormSbmitRef = el}
                            {...layout}
                            initialValues = {this.state.requestParmas}
                        >
                            <Form.Item label="日期" name="time"  rules={[{ required: true }]}>
                            <DatePicker  />
                            </Form.Item>
                            <Form.Item label="内容"  name="content" rules={[{ required: true }]} >
                              <TextArea  placeholder="请输入内容" rows={8} />
                            </Form.Item>
                            <Form.Item label="权重" name="weigh" rules={[{ required: true }]} >
                            <Input placeholder="请输入权重" />
                            </Form.Item>
                        </Form>

        </Modal> 



      </div>
    );
  }
}
