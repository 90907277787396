

import * as echarts from 'echarts';





  // 人员出勤
  export const getNewestUserAttendanceCurve = (React, projectInfo)=> {
    return new Promise((reslove) => {
      React.$axios.post('newestUserAttendanceCurve', {projectId: projectInfo.id}).then(res => {

        let enterData = []
        let exitData = []
  
        let enterPatt = /manager\d+h/
        let exitPatt = /laborer\d+h/
  
        // res.data.
  
        if(!res.data.info) {
          return
        }

        
  
  
        for(let i in res.data.info) {
          let enterStatus = enterPatt.test(i)
          let exitStatus = exitPatt.test(i)
          if(enterStatus) {
            enterData.push(res.data.info[i])
          } else if(exitStatus) {
            exitData.push(res.data.info[i])
          } else {
  
          }
        }
  
        
        setechart1(enterData, exitData)

        reslove(res.data.info);
      })
    })
   
  }


  function setechart1(data1, data2) {
    var myChart = echarts.init(document.getElementById('main'));

    let xData = [1,2,3,4,5,6,7,8,9,10,11,12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
    const Hours = new Date().getHours() + 1;
    xData = xData.splice(0, Hours);
    let option = {
        
      color: ['#004EA1','#C71D4C' ],
      grid: {
          left: '0%',
          right: '2%',
           bottom: '0px',
           top: "30%",
          containLabel: true
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
          data: ['bar','管理人员', '劳务人员'],
          right: '0%',
          icon: 'rect'
      },

      xAxis: [
          {
              type: 'category',
              boundaryGap: false,
              data: xData,
              show:true,
          }
      ],
      yAxis: [
          {
              type: 'value',
              // show:false,
              splitLine:{
                　　　　show:false
                　}
          }
      ],
      series: [
          {
              name: '管理人员',
              type: 'line',
              // stack: '总量',
              areaStyle: {},
            //   smooth: true,
              emphasis: {
                  focus: 'series'
              },
              showSymbol: false,
              areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: 'rgba(0,78,161, 0.8)'
                  }, {
                      offset: 1,
                      color: 'rgba(0,78,161, 0.2)'
                  }])
                  
              },

              data: data1
          },
          {
              name: '劳务人员',
              type: 'line',
              // stack: '总量',
            //   smooth: true,
              areaStyle: {},
              emphasis: {
                  focus: 'series'
              },
              showSymbol: false,
              areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: 'rgba(199,29,76, 0.8)'
                  }, {
                      offset: 1,
                      color: 'rgba(199,29,76, 0.2)'
                  }])
              },
              data: data2
          },
      ]
  };
     myChart.setOption(option);
  }




  export const getInspectionStatistics = (React, projectInfo) => {
    React.$axios.post('inspectionStatistics', {logic: 2,projectId: projectInfo.id}).then(res => {

      let data1 =  res.data.listMonth[0]
      
      if(!data1) {
        return 
      }

      let enterData = []
      let exitData = []

      let enterPatt = /quality\d+/
      let exitPatt = /safety\d+/


      for(let i in data1) {
        let enterStatus = enterPatt.test(i)
        let exitStatus = exitPatt.test(i)
        if(enterStatus) {
          enterData.push(data1[i])
        } else if(exitStatus) {
          exitData.push(data1[i])
        } else {

        }
      }

      // this.setState(
      //   {
      //     AttendanceCurveParmas: {
      //       enter: this.state.AttendanceCurveParmas.enter,
      //       exit: this.state.AttendanceCurveParmas.exit,
      //       zhil: data1.quality,
      //       safe: data1.safety
      //     }
      // })

 
       setechart2(enterData, exitData)
    })
  }


  function setechart2(data1, data2) {

    var myChart = echarts.init(document.getElementById('main2'));

    let option = {
      legend: {
        data: ['质量问题', '安全问题'],
        left: 'right',
        icon: 'rect'
    },


    tooltip: {
      trigger: 'axis'
    },

      textStyle: {
        fontWeight: 'normal',
        // color: '#7BABFF',
      },
      

      // color: ['#C5E9D6'],
      grid: {
        left: '1%',
        right: '4%',
        bottom: '4%',
        top: '20%',
        containLabel: true
      },
      xAxis: [
        {
            type: 'category',
            data: [1,2,3,4,5,6,7,8,9,10,11,12],

            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
              interval: 0,
              formatter: function(value) {
                let month =  value.split('月')
                let str = ''
                if(month[1]) {
                  str = month[0] + '月' + '\n' + month[1]
                }else {
                  str = month[0] + '月'
                }
                return str
              }
            },
            　splitLine:{
            　　　　show:false
            　　}
        }
    ],

      yAxis: [{
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#fff'
          }
        },

        // axisLabel: function(value) {
        //   return value
        // },
      　splitLine:{
      　　　　show:false
      　}
      }],

      series: [
        {
            name: '质量问题',
            type: 'bar',
            data: data1,
            symbolSize: 0,
            barWidth: 10,
            barMaxWidth:20,
            showBackground: true,
            color: ['#004EA1'],
            label: {
                  formatter: "{c}",
                  show: true,
                  position: 'top',
                  color: '#004EA1'
            }
        },
        {
            name: '安全问题',
            type: 'bar',
            data: data2,
            barWidth: 10,
            symbolSize: 0,
            barMaxWidth:20,
            showBackground: true,
            color: ['#C71D4C'],
            label: {
              formatter: "{c}",
              show: true,
              position: 'top',
              color: '#C71D4C'
            }
        },
    ],
    };
 
 
     myChart.setOption(option);
  }

