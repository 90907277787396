import React from "react";
import './certificates.less';

import {Button} from "antd";

import * as echarts from 'echarts';


 import {CertifiSerch} from "./certifiSerch/certifiSerch"


export default class Certificates extends React.Component {
 
  state = {
      childBtn: [],
      status: null,
      Statistics: {
        specialOperator: 0,
        numberOfHolders: 0,
        expiringSoon: 0,
        expired: 0
      }
  }

  componentDidMount() {
    let childBtn = React.$getStorage('menuchildBtn');
    for(let i = 0; i < childBtn.length; i++) {
      if(childBtn[i].show) {
        let item = childBtn[i];
        this.changeStatus(item.index);
        break;
      }
    }
  

    this.setState({
      childBtn: childBtn
    })

   
    const projectId = React.$getStorage('projectInfo').id
    React.$axios.post('userDocumentsStatistics', {projectId:projectId, logic: 2 }).then(res => {
        

        let data = [
            {value: res.data.specialOperator, name: '特种作业人数'},
            {value: res.data.numberOfHolders, name: '持证人数'},
            {value: res.data.expiringSoon, name: '即将逾期人数'},
            {value: res.data.expired, name: '已逾期人数'}
        ]   

        this.getEcharts(data)

        let paramsStatistics = {
            specialOperator: res.data.specialOperator,
            numberOfHolders: res.data.numberOfHolders,
            expiringSoon: res.data.expiringSoon,
            expired: res.data.expired
        }

        this.setState({
            Statistics: paramsStatistics
        })


        let team = res.data.team
        let expired = []
        let normal = []
        
        let keys = []    
        for(let item in team) {
            keys.push(item)
            expired.push(team[item].expired)
            normal.push(team[item].normal)
        }

 
        this.getEcharts2(keys, expired, normal)


    })


  }
  
  
  changeStatus(e) {
      this.setState({
        status: e
      })
  }


  getEcharts2(xAxis, data1, data2) {
    var myChart = echarts.init(document.getElementById('main2'));
    let option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            }
        },
        legend: {
            data: ['正常', '逾期']
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: xAxis
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: '正常',
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: data1
            },
            {
                name: '逾期',
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },
                data: data2
            },
        ]
    };
    
    
    myChart.setOption(option);
  }


  getEcharts(data) {
    var myChart = echarts.init(document.getElementById('main'));
    let option = {
        color: ['#1DC7B5', '#2977F4', '#FD3476', '#FFB81F'],  
        tooltip: {
            trigger: 'item'
        },
        
        series: [
            {
                name: '访问来源',
                type: 'pie',
                radius: '50%',
                data: data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    
    
    myChart.setOption(option);
  }
    
  render() {
    return (
      <div className= "wages">

          <div className="btn">
           {
              this.state.childBtn.map((item, index) => {
                if(item.show) {
                  return (
                    <Button  key={index} type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                  )
                }
              })
            }
           {/* <Button  type={this.state.status==true? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, true)}>证件统计</Button>
           <Button className="setBtn" type={this.state.status==false? 'primary': ''}  onClick={this.changeStatus.bind(this, false)}>证件查询</Button> */}
          </div>
          
        
          <div style={{display: this.state.status==0? 'block': 'none'}}>  
          <div className="gongziNum">

            <div className="tongji">
                <h1>累计持证统计</h1>
                <div className="border"></div>
                <div className="list">
                       <div>
                           <p>特种作业人数</p>
                           <p className="pc pcolor1">{this.state.Statistics.specialOperator}</p> 
                        </div>
                       <div>
                           <p>持证人数</p> 
                           <p className="pc pcolor2">{this.state.Statistics.numberOfHolders}</p>
                       </div>
                       <div>
                            <p>即将逾期人数</p>
                            <p className="pc pcolor3">{this.state.Statistics.expiringSoon}</p>
                       </div>

                       <div>
                            <p>已逾期人数</p>
                            <p className="pc pcolor4">{this.state.Statistics.expired}</p>
                       </div> 
                </div>


                <div id="main" className="echarts">

                </div>


            </div>

            <div className="fafang">
                <h1>各劳务队伍持证情况</h1>
                <div className="border">
                    <div id="main2" className="echarts2">

                    </div>

                </div>
            
            </div>
          </div>
          </div> 


          <div style={{display: this.state.status==1? 'block': 'none'}}>
            {/* <WageEntry /> */}
            <CertifiSerch/>
          </div>
          






      </div>
    );
  }
}
