import React from "react";


export default class Test extends React.Component {


  state = {
    herfEarth: ''
  }

  componentDidMount() {
    console.log(window.location)

    this.setState({
      herfEarth: window.location.origin + '/Earth' 
    })


  }

  toPath() {
    window.open('http://1wx.cn/overview')
  }

  render() {
    return (
      <div className="Test" style={{height: '100vh'}}>
        {/* console.log(1111) */}
        <iframe style={{height:'100%'}} width="100%" height="100vh" src="https://webpro.raykite.com/share/s96Q8NZ6" frameborder="0"></iframe>
      </div>
    );
  }
}
