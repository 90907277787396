import React, { useState, useEffect } from "react";
import { Form, Button, Input, DatePicker, Table, Select } from 'antd';
import { withRouter } from 'react-router-dom';
import './examLog.less'
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { formatTime } from '@/utils/util.js'

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

const { Option } = Select;





let jsonFieldsList =  {
  '0_序号': 'aa',
  '1_姓名': {
    field: 'name',
      callback: value => {
          return '&nbsp;' + value
      }
  },
  "2_公司": 'nameEnterprise',

  "3_部门": 'nameTeam',
  "4_试卷分类":"nameBankExamPaper",
  "5_试卷名称":"nameExamPaper",
  "6_考试日期": {
    field: 'addTime',
        callback: value => {
            return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
        }
    },
  "7_考试分数": 'fraction',
  "8_监考员": "nameIdCardAdd",
}

function ExamLog() {
  let loadExcelRef='';
  const [searchForm] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [tableList, setTableList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })
  const [enterpriseAllList, setEnterpriseAllList] = useState([])

  const [ExcelName, setExcelName] = useState('考试');

  const columns = [
    {
      title: '序号',
      align: 'center',
      render: (text, record, index) => {
        return <span>{index + 1}</span>
      }
    },
    {
      title: '姓名',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      align: 'center',
    },
    {
      title: '身份证号码',
      dataIndex: 'idNum',
      align: 'center',
    },
    {
      title: '单位',
      dataIndex: 'nameEnterprise',
      align: 'center',
    },
    {
      title: '部门',
      dataIndex: 'nameTeam',
      align: 'center',
    },
    {
      title: '试卷分类',
      dataIndex: 'nameBankExamPaper',
      align: 'center',
    },
    {
      title: '试卷名称',
      dataIndex: 'nameExamPaper',
      align: 'center',
    },
    {
      title: '考试日期',
      dataIndex: 'grdOfConcrete',
      align: 'center',
      render: (text, record, index) => {
        return <span>{formatTime(new Date(record.addTime * 1000), "yyyy-MM-dd hh:mm:ss")}</span>
      }
    },
    {
      title: '考试成绩',
      dataIndex: 'fraction',
      align: 'center',
    },
    {
      title: '状态',
      dataIndex: 'submit',
      align: 'center',
      render: (_) => {
        return (
          <p>{_==2? '提交': '未提交'}</p>
        )
      }
    },
    {
      title: '监考员',
      dataIndex: 'nameIdCardAdd',
      align: 'center',
    }
  ];
  useEffect(() => {
    getEnterpriseAll()
    getTrainingUser(true);
  }, []);

  useEffect(() => {
    // console.log(pagination)

    // getTrainingUser();
  }, [pagination])

  function onPaginationChange(page) {
    console.log(page)
    setPagination(page)
    pagination.current = page.current
    pagination.pageSize = page.pageSize

    getTrainingUser();
  }
  // 获取公司 
  function getEnterpriseAll() {
    React.$axios.post('enterpriseAll').then(res => {
      setEnterpriseAllList(res.data.enterpriseAll)
    })
  }
  function getTrainingUser(type) {
    const { keyword, enterpriseId, nameExamPaper, time, realName } = searchForm.getFieldsValue()
    console.log(time)
    const addTimeStart = time ? parseInt(new Date(time[0]._d).getTime() / 1000) : ""
    const addTimeStop = time ? parseInt(new Date(time[1]._d).getTime() / 1000) : ""
    const requestData = {
      projectId: React.$getStorage('projectInfo').id,
      offsetLimit: (pagination.current - 1) * pagination.pageSize,
      lengthLimit: pagination.pageSize,
      logic: 2,
      keyword,
      enterpriseId,
      nameExamPaper,
      addTimeStart,
      addTimeStop,
      realName
    }
    // console.log(requestData)
    setLoading(true)
    React.$axios.post('listEducationExamPaperUser', requestData).then(res => {
      if (type) {
        setPagination({
          ...pagination,
          total: res.data.countList
        })
      }

      setTableList(res.data.list)
      setpage(res)
    }).finally(() => {
      setLoading(false)
    })
  }

  function setpage(res) {
    console.log(res)
    pagination.current = pagination.current;
    pagination.pageSize = pagination.pageSize;
    pagination.total = res.data.countList;
    let json = JSON.parse(JSON.stringify(pagination)); 
    json.onShowSizeChange = function(current, pageSize) {
        pagination.pageSize = pageSize;
        let json = JSON.parse(JSON.stringify(pagination)); 
        setPagination(json)
        onPaginationChange(json)
    }
    json.onChange = function(current) {
        pagination.current = current
        let json = JSON.parse(JSON.stringify(pagination)); 
        setPagination(json)
        onPaginationChange(json)
    }
    json.showTotal = function () {  //设置显示一共几条数据
        return '共 ' + pagination.total + ' 条'; 
    }
    setPagination(json)
}


  function fetchFun(val) {
    setExcelName(formatTime(new Date(), '考试成绩yyyy-MM-dd hh:mm:ss'))

    return new Promise((resolve) => {
    const { keyword, enterpriseId, nameExamPaper, time, realName} = searchForm.getFieldsValue()
    const addTimeStart = time ? parseInt(new Date(time[0]._d).getTime() / 1000) : ""
    const addTimeStop = time ? parseInt(new Date(time[1]._d).getTime() / 1000) : ""


    const requestData = {
      projectId: React.$getStorage('projectInfo').id,
      logic: 3,
      keyword,
      enterpriseId,
      nameExamPaper,
      addTimeStart,
      addTimeStop,
      realName
    }
    React.$axios.post('listEducationExamPaperUser', requestData).then(({ data }) => {
      console.log(data)
      data.list.map((item, index) => {
        item.aa = index+1;
      })

      resolve(data.list)

    }).finally(() => {
      setLoading(false)
    })


    })

  }

  function toSerch() {
    setPagination({
      current: 1,
      pageSize: 10,
      total: 0
    })
    pagination.current = 1
    getTrainingUser(true)
  }


  return (
    <div className="educationLog">

{/* getTrainingUser */}
      <div className="search-wrap">
        <Form form={searchForm} layout="inline" onFinish={toSerch}>
          <Form.Item
            name="keyword"
            label="关键字"
            
          >
            <Input style={{ width: 180}}  placeholder="模糊查询" allowClear={true} />
          </Form.Item>
          <Form.Item
            name="realName"
            label="实名"
          >
            <Select
              style={{ width: 180, }}
              placeholder="请选择"
              allowClear={true}
            >
            <option value="2">已实名</option>
            <option value="3">没有实名</option>
            </Select>
          </Form.Item>
          <Form.Item
            name="enterpriseId"
            label="单位"
          >
            <Select
              style={{ width: 200, }}
              placeholder="请选择"
              allowClear={true}
            >
              {
                enterpriseAllList.map((item, index) => {
                  return (
                    <Option value={item.id} key={index}>{item.name}</Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            name="nameExamPaper"
            label="试卷名称"
          >
            <Input placeholder="请输入试卷名称" allowClear={true} />
          </Form.Item>
          <Form.Item
            name="time"
          >
            <RangePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon={<SearchOutlined />} htmlType="submit">搜索</Button>
          </Form.Item>

          <Form.Item>
          <DownloadExcel ref={(el) => loadExcelRef = el}  name={ExcelName} fields={jsonFieldsList}  fetch={fetchFun} btnName="导出" />
          </Form.Item>    

        </Form>
      </div>
      <div className="table-wrap">
        <Table
          bordered
          loading={loading}
          columns={columns}
          dataSource={tableList}
          pagination={pagination}
        />
      </div>
    </div>
  )
}

export default withRouter(ExamLog);