import React from "react";

import { DatePicker,Input,Select,Button, Table} from "antd";


import {formatTime} from '@/utils/util';

import {VerticalAlignTopOutlined, SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import { Uploadhh } from "@/components/upload/upload";

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

import './WageEntry.less';

const {Option} =  Select;
const { RangePicker } = DatePicker;




export const WageEntry = (
    class WageEntry extends React.Component {
      loadExcelRef=''
      state = {
        // 表格分页属性
        pagination: {
          current: 1,
          pageSize: 10,
          total: 200,
          onShowSizeChange: function(current, pageSize) {
              this.state.pagination.pageSize = pageSize;
              this.handleTableChange(this.state.pagination)
          }.bind(this),
          onChange: function(current) {
              this.state.pagination.current = current
              this.handleTableChange(this.state.pagination)
          }.bind(this),
          showTotal: function () {  //设置显示一共几条数据
              return '共 ' + this.state.pagination.total + ' 条'; 
          }.bind(this),
          showQuickJumper: true,
          },

    loading: false,

    tableData: [
        
    ],

    enterpriseAllList:[],
    teamAllList: [],
    workAllList: [],
    ExcelName: null,
    params: {
      lengthLimit: 10,
      offsetLimit: 0,
      uiEnterpriseId: [],
      uiTeamId: [],
      uiWorkId: [],
      projectId: null,
      logic: 2,
      timeWageStart: null,
      timeWageEnd: null,
      keyword: null,
      statusWage: null
    },

    jsonFieldsList: {
      '0_姓名': 'uicName',
      '1_身份证号码':{
        field: 'idCard',
            callback: value => {
                return '&nbsp;' + value
                // return formatTime(new Date(value * 1000), "yyyy-MM-dd hh:mm:ss")
            }
        },
      "2_发放时间": {
        field: 'time',
            callback: value => {
                return formatTime(new Date(value * 1000), "yyyy-MM-dd")
            }
        },
      "3_状态": {
        field: 'status',
            callback: value => {
                return value==2?'已发放':'发放异常'
            }
        },
      "4_基本工资":"basic",
      "5_实际工资": 'real',
      "6_奖罚工资": 'prizePunish',
      "7_出勤天数": "d",
      "8_备注": "remark",
    },

    openStatus: false,
  }


  columns = [
      {
        title: '姓名',
        dataIndex: 'uicName',
        align: 'center'
      },
      {
        title: '身份证号码',
        dataIndex: 'idCard',
        align: 'center'
      },
      {
        title: '发放时间',
        dataIndex: 'time',
        align: 'center',
        render: (_) => {
          return (
            <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')}</p>
          )
        }
      }, 
      {
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        render:(_) => {
          return (
            <p>{_==2?'已发送':'发放异常'}</p>
          )
        }
      },
      {
        title: '基本工资',
        dataIndex: 'basic',
        align: 'center'
      },
      {
        title: '实际工资',
        dataIndex: 'real',
        align: 'center'
      },
      {
          title: '奖罚工资',
          dataIndex: 'prizePunish',
          align: 'center'
      },
      {
          title: '出勤天数',
          dataIndex: 'd',
          align: 'center'
      },
      {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        },
      
      // {
      //   title: '操作',
      //   dataIndex: 'address',
      //   align: 'center',
      //   render:(_,item) => {
      //     return (
      //       <div className="operation">
      //           <p>详情</p>
      //           <p>编辑</p>  
      //           <p>退场</p>  
      //       </div>
      //     )
      //   }
      // },
    ]
    
    handleTableChange(pagination) {
      this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
      this.state.params.lengthLimit = pagination.pageSize
      this.setState({
        params: this.state.params,
        // pagination: pagination
      })
      
      // 执行更新列表
      this.getList()
    }

    componentDidMount() {
      this.setState({
        ExcelName:  '工资导出' + formatTime(new Date(), 'yyyyMMdd') + '.xls'
      })

      const projectId = React.$getStorage('projectInfo').id
      this.state.params.projectId = projectId
      this.getList()

      this.getEnterpriseAll()
    }

    // 获取工资列表
    getList(type = false) {
      if(type) {
        this.state.params.offsetLimit = 0
        this.state.pagination.current = 1
        this.setState({
          params: this.state.params,
          pagination: this.state.pagination
        })
      }


      this.setState({ loading: true });
      React.$axios.post('userWageList', this.state.params).then(({ data }) => {

        this.state.pagination.total =  data.countList
        
        this.setState({
          loading: false,
          tableData: data.list,
          pagination: this.state.pagination
        })
  
      })
    }


    setInput(name, {target:{value}}) {
      this.state.params[name] = value
      this.setState({
        params: this.state.params
      })
    }


    onChangeT(name, value) {
      this.state.params[name] = value
      this.setState({
        params: this.state.params
      })
    }

    handleFront() {
      this.getList(true)
    }

    setDrapDom() {
      if(this.state.openStatus == true) {
          return (
            <UpOutlined/>
          )
        } else {
          return (
            <DownOutlined />
          )
        }
    }

    drapHeight() {
      this.setState({
        openStatus: !this.state.openStatus
      })
    }

    getTime(value) {
      if(!value) {
        this.state.params.timeWageStart = value
        this.state.params.timeWageEnd = value
      } else {
        this.state.params.timeWageStart =parseInt(+value[0]._d / 1000) 
      this.state.params.timeWageEnd = parseInt(+value[1]._d / 1000) 
      }

      this.setState({
        params: this.state.params
      })
    }


      // 获取公司
   getEnterpriseAll() {
    React.$axios.post('enterpriseAll').then(res => {
      this.setState({
        enterpriseAllList: res.data.enterpriseAll
      })
    })
   }

  // 获取部门
   getTeamAll(id) {

    let params = {
       id: id
     }
    React.$axios.post('teamAll', params).then(res => {
      this.setState({
        teamAllList: res.data.teamAll,
        workAllList: []
      })  
    })
  }

  // 获取工种
  getWorkAll(params) {
    React.$axios.post('workAll', {id: params}).then(res => {
      this.setState({
        workAllList: res.data.workAll
      })
    })
  }

    // 选择公司
    onChangeEnterprise(value, item) {

      this.state.params.uiEnterpriseId = value
      this.state.params.uiTeamId = []
      this.state.params.uiWorkId = []

      this.setState({
        params: this.state.params,
        teamAllList: [],
        workAllList: []
      })

      let arr = []
      item.map(item2 => {
        if(!item2.item.teamId) {

        } else {
          let teamId = item2.item.teamId.split(',')
          arr = [...arr, ...teamId]
        }
        
      })

      this.getTeamAll(arr)
    }

    onChangeTeam(value, item) {
      let arr = []
      item.map(item2 => {
        if(!item2.item.workId) {

        } else {
          let workId = item2.item.workId.split(',')
        arr = [...arr, ...workId]
        }
        
      })

      this.state.params.uiTeamId = value
      this.state.params.uiWorkId = []

      this.setState({
        params: this.state.params,
        workAllList: []
      })

      this.getWorkAll(arr)
    }

    onChangeWorkAll(value) {
      this.state.params.uiWorkId = value
      this.setState({
        params: this.state.params
      })
    }

    serchClick() {
      this.getList(true)
    }


    fetchFun() {
      return new Promise((resolve, reject) => {

        let parmas = {}
        parmas = {...parmas, ...this.state.params}
        parmas.logic = 3


        React.$axios.post('userWageList', parmas).then(({ data }) => {
          resolve(data)
        })

      })
      
      



    }





        render() {
            return (
              <div className="WageEntry">

                 <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>人员名称:</span> <Input onChange={this.setInput.bind(this, 'keyword')}   style={{ width: 200, height:30, marginRight:20,  }}  placeholder="姓名, 身份证号, 籍贯, 手机号" /></li>
                         
                            <li> <span>发放状态:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择角色"
                                    optionFilterProp="children"
                                    onChange={this.onChangeT.bind(this, 'statusWage')}
                                >
                                <Option value=" ">全部</Option>
                                <Option value="2">已发送</Option>
                                <Option value="3">发送异常</Option>
                                </Select>
                            </li>

                            <li><span>发放日期:</span>  
                              <RangePicker style={{ width: 300, height:30, marginRight:20,  }} onChange={this.getTime.bind(this)} />
                            </li>



                            <li> <span>公司:</span> 
                          {/* mode="multiple"  */}
                                <Select
                                    showSearch
                                    style={{ width: 200, marginRight:20, }}
                                    placeholder="请选择公司"
                                    optionFilterProp="children"
                                    mode="multiple"
                                    onChange={this.onChangeEnterprise.bind(this)}
                                >
                                 {
                                   this.state.enterpriseAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }

                                </Select>
                            </li>

                            <Button  className="btn" style={{display: this.state.openStatus?'none':''}} onClick={this.serchClick.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>


                            <p className="setHeight"></p>     

                            <li> <span>部门:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择部门"
                                    optionFilterProp="children"
                                    mode="multiple"
                                    value = {this.state.params.uiTeamId}
                                    onChange={this.onChangeTeam.bind(this)}
                                >                         
                                {
                                   this.state.teamAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }

                                </Select>
                            </li>

                            <li > <span>岗位:</span>  
                                <Select
                                    showSearch
                                    style={{ width: 200, height:30, marginRight:20,  }}
                                    placeholder="请选择公司"
                                    optionFilterProp="children"
                                    mode="multiple"
                                    value = {this.state.params.uiWorkId}
                                    onChange={this.onChangeWorkAll.bind(this)}
                                >
                                {
                                   this.state.workAllList.map(item => {
                                     return (
                                      <Option item={item} value={item.id}>{item.name}</Option>
                                     )
                                   })
                                 }
                             
                                </Select>
                            </li> 

                            <Button  className="btn" style={{display: this.state.openStatus?'':'none'}} onClick={this.serchClick.bind(this)}>
                                <SearchOutlined className="icon"/>
                            </Button>

                    </ul>

                    <p class="drapBottom" onClick={this.drapHeight.bind(this)}>
                        {/* <DownOutlined /> */}
                        {
                          this.setDrapDom()
                        }

                    </p>
                    
                </div>



                <div className="table2">
                <div class="allBtn">
                    <Button className="bt"  type="primary" icon={<BorderLeftOutlined />}>
                    <a href="https://project.1wx.cn/static/excel/import/工资导入模板.xlsx" download>下载模板</a>
                    </Button>
                    <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                        导入
                        <div className="setUPloda">   
                          <Uploadhh accept = '.xlsx' path="userWageImport" pdfStatus={true}  name="file" width={80} height={30}   handleClick={this.handleFront.bind(this)} />
                        </div>    

                    </Button>
                    <DownloadExcel ref={(el) => this.loadExcelRef = el}  name={this.state.ExcelName}   fields={this.state.jsonFieldsList}  fetch={this.fetchFun.bind(this)} >    
                      <Button  className="bt" type="primary"  icon={<VerticalAlignTopOutlined />}>
                          导出
                      </Button>
                    </DownloadExcel>      

                    
                    </div>


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }}  columns={this.columns} dataSource={this.state.tableData}  />
                </div>
              </div>
            );
          }

    }
)

