



import * as echarts from 'echarts';

// 图形1 饼状图
export function main1(data) {
    let chartDom = document.getElementById('main1');

    let myChart = echarts.init(chartDom);
    let option;


    option = {
        color: ['#004EA2','#DEEBF7'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center',
          show: false
        },
        series: [
          {
            name: '偏差情况',
            type: 'pie',
            radius: ['55%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
            //   position: 'center'
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: '40',
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
              show: false
            },
            data: [
              { value: 100, name: '实际' },
              { value: data, name: '偏差' }
            ]
          }
        ]
      };
      
      option && myChart.setOption(option); 
   


} 



export function main2(data) {
    var myChart = echarts.init(document.getElementById('main2'));
    let arr = []
    let name = []
    for(let i in data) {
        name.push(i);
        arr.push(data[i])
    }
    
 

    let option = {
            color: ['#567BFB'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  label: {
                    backgroundColor: '#6a7985'
                  }
                }
              },
            grid: {
              left: '3%',
              right: '30px',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: name
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '验收情况',
                type: 'line',
                stack: '总量',
                itemStyle : {
                    normal : {
                        color:'#004EA2',
                            lineStyle:{
                                color:'#004EA2'
                            }
                        },
                },
                areaStyle: {
                    normal:{
                        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ 
             
                             offset: 0.2,
                             color: '#86A2FF'
                         }, {
                             offset: .6,
                             color: '#BECDFF'
                         },{
                             offset: 1,
                             color: '#ffffff'
                         }])
                     }
                },
                emphasis: {
                  focus: 'series'
                },
                data: arr,
              }
            ]
    };
    
    
    myChart.setOption(option);
  }


  // 月度验收情况
  export function main3(data) {

    // console.log(data);
    let name = []
    let arr1 = []
    let arr2 = []
    for(let i in data) {
        name.push(i);
        arr1.push(data[i].actualWeight);
        arr2.push(data[i].theoreticalWeight);
    }


    var myChart = echarts.init(document.getElementById('main3'));

    let option = {
            color: ['#004EA2'],
            legend: {
                data: ['实际', '理论'],
                right: '20',
                top: '20'
            },
            grid: {
                left: '1%',
                right: '2%',
                bottom: '0%',
                top: '30%',
                containLabel: true
              },
            xAxis: {
                type: 'category',
                data: name,
                // axisLine: {
                //     lineStyle: {
                //         color: '#E0E6F1'
                //     } 
                // },
                label: {
                    formatter: function(val) {
                        console.log(val)
                    }   
                },
                axisLabel: {  
                    interval:0,  
                    rotate:-60
                }, 
            },
            
            yAxis: {
                type: 'value',
                lineStyle:{
                    type:'dashed'//虚线
                },
            },
            series: [{
                name: '实际',
                data: arr1,
                type: 'bar',
                color: ['#0DC076'],
                barWidth: 20,
                barMaxWidth:40,
                label: {
                    formatter: "{c}",
                    show: true,
                    position: 'top',
                    color: '#0DC076'
                }
            },
            {
                name: '理论',
                data: arr2,
                type: 'bar',
                color: ['#DEEBF7'],
                barWidth: 20,
                barMaxWidth:40,
                label: {
                    formatter: "{c}",
                    show: true,
                    position: 'top',
                    color: '#DEEBF7'
              }
            }    
        ]
        };
    
        myChart.setOption(option);

  }


  export function main4(data) {

    data.map(item => {
        item.value = item.actualWeight
        item.name = item.enterprise
    })


    var myChart = echarts.init(document.getElementById('main4'));

    let option = {

        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          show: false,
        },
        grid: {
            top: '10%',
          },
        series: [
          {
            name: '材料供应商情况',
            type: 'pie',
            radius: '70%',
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

    myChart.setOption(option);


  }