

export const Alldata = [
    {
        name: '广东',
        Position: {x: 100 ,y:12, z: 10},
        rotationZ: 12 
    },
    {
        name: '广西',
        Position: {x: 20 ,y:12, z: 25},
        rotationZ: 0 
    },
    {
        name: '云南',
        Position: {x: -90,y:12, z: 30},
        rotationZ: 0 
    },
    {
        name: '湖南',
        Position: {x: 60 ,y:12, z: -35},
        rotationZ: 0 
    },
    {
        name: '江西',
        Position: {x: 128 ,y:12, z: -39},
        rotationZ: 0 
    },
    {
        name: '福建',
        Position: {x: 174 ,y:12, z: -25},
        rotationZ: 7
    },
    {
        name: '浙江',
        Position: {x: 205 ,y:12, z: -80},
        rotationZ: 7
    },
    {
        name: '江苏',
        Position: {x: 207 ,y:12, z: -117},
        rotationZ: 0
    },
    {
        name: '安徽',
        Position: {x: 160 ,y:12, z: -117},
        rotationZ: 7
    },
    {
        name: '湖北',
        Position: {x: 80 ,y:12, z: -90},
        rotationZ: 0
    },
    {
        name: '河南',
        Position: {x: 90 ,y:12, z: -140},
        rotationZ: 5
    },
    {
        name: '陕西',
        Position: {x: 20 ,y:12, z: -140},
        rotationZ: 5
    },
    {
        name: '重庆',
        Position: {x: -10 ,y:12, z: -68},
        rotationZ: 5
    },
    {
        name: '贵州',
        Position: {x: -20 ,y:12, z: -25},
        rotationZ: 5
    },
    {
        name: '四川',
        Position: {x: -70 ,y:12, z: -90},
        rotationZ: 0
    },
    {
        name: '西藏',
        Position: {x: -300 ,y:12, z: -90},
        rotationZ: 5
    },
    {
        name: '新疆',
        Position: {x: -330 ,y:12, z: -280},
        rotationZ: 5
    },
    {
        name: '青海',
        Position: {x: -170 ,y:12, z: -190},
        rotationZ: 7
    },
    {
        name: '甘肃',
        Position: {x: -40 ,y:12, z: -170},
        rotationZ: 7
    },
    {
        name: '宁夏',
        Position: {x: -5,y:12, z: -215},
        rotationZ: 7
    },
    {
        name: '山西',
        Position: {x: 90,y:12, z: -215},
        rotationZ: 10
    },
    {
        name: '河北',
        Position: {x: 140,y:12, z: -245},
        rotationZ: 10
    },
    {
        name: '山东',
        Position: {x: 180,y:12, z: -200},
        rotationZ: 10
    },
    {
        name: '北京',
        Position: {x: 162,y:12, z: -272},
        rotationZ: 10
    },
    {
        name: '天津',
        Position: {x: 177,y:12, z: -258},
        rotationZ: 10
    },
    {
        name: '内蒙古',
        Position: {x: 80,y:12, z: -295},
        rotationZ: 10
    },
    {
        name: '辽宁',
        Position: {x: 270,y:12, z: -300},
        rotationZ: 10
    },
    {
        name: '吉林',
        Position: {x: 320,y:12, z: -350},
        rotationZ: 10
    },
    {
        name: '黑龙江',
        Position: {x: 350,y:12, z: -400},
        rotationZ: 10
    }
]

// 飞线数据
export const FlyData = [
    {
        speed: 3,  //运行速度
        z: -32,
        x: -8,
        rotateY: 5,
        name: '深圳轨道交通十二号线',
        length: 182
    },
    {
        speed: 0.8,  //运行速度
        z: 20,
        x: -35,
        rotateY: 230,
        name: '石鼓水源工程',
        length: 40
    },
    {
        speed: 1.5,  //运行速度
        z: 5,
        x: -40,
        rotateY: 256,
        name: '拉哇水电站c4标项目',
        length: 103
    },
    {
        speed: 1.5,  //运行速度
        z: -20,
        x: -37,
        rotateY: 297,
        name: '成都交通19号线机电4工区',
        length: 110
    },
    {
        speed: 5,  //运行速度
        z: -25,
        x: -30,
        rotateY: 322,
        name: '郑州市轨道交通8号楼一期工程',
        length: 244
    },
    {
        speed: 1.2,  //运行速度
        z: -15,
        x: -35,
        rotateY: 300,
        name: '宜昭高速',
        length: 57
    },
    {
        speed: 0.3,  //运行速度
        z: 0,
        x: 20,
        rotateY: 90,
        name: '滇中引水昆明段',
        length: 30
    },
]