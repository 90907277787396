import React from "react";

import { Table, message, Modal, Input } from "antd";
// 添加furon

import './machineDeviceList.less';


export default class ProjectConfig extends React.Component {
  FormSbmitRef = null
  imageUrl = process.env.REACT_APP_IMG_URL
  state = {
    status: 0,
    loading: false,
    tableData: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    params : {
        lengthLimit: 10,
        offsetLimit: 0
    },
    isModalVisible: false,
    requestParmas: {
        content: null,
        title: null,
        img: [],
        weigh: 0
    },

    projectInfoId: null,
    inputValue: null,
  } 
  


 columns = [
  {
    title: "序号",
    dataIndex: 'title',
    align: 'center',
    render: (_, item, index) => {
      return (
        <p>{index + 1}</p>
      )
    }
  },
    {
      title: '设备名称',
      dataIndex: 'name',
      align: 'center',
    },
    {
      
      title: '设备序列号',
      dataIndex: 'deviceKey',
      align: 'center',
    },
    {
      
        title: '售货机设备同步',
        dataIndex: 'synchronize',
        align: 'center',
        render: (_) => {
            return (
                <p>{_==2?'已同步': '未同步'}</p>
            )
        } 
    },
    {
        title: '售货机同步结果',
        dataIndex: 'curl',
        align: 'center',
        render: (_) => {
            let type = 2
            if(_ == '成功') {
                type = 2
            } else {
                type = 3
            }

            return (
                <p className={'setType' + type}>
                   {_} 
                </p>
            )
        }
    },

    {
      title: '最低兑换积分',
        dataIndex: 'buyPoints',
        align: 'center',
    },

    // {
    //   title: '人员实名制初始化的安全教育积分',
    //     dataIndex: 'safetyEducationScore',
    //     align: 'center',
    // },

    {
      title: '操作',
        dataIndex: 'synchronize',
        align: 'center',
        render: (_, item) => {
            return (
                <p className="setColor" onClick={this.setScore.bind(this, item)}>设置</p>
            )
        } 
    },
  ]


  handleTableChange(pagination) {

    this.state.params.offsetLimit = (pagination.current -1) * pagination.pageSize
    this.state.params.lengthLimit = pagination.pageSize

    this.setState({
      params: this.state.params,
      pagination: pagination
    })
    
    // 执行更新列表
    this.getList(this.state.inputValue)
  }

  setScore(item) {
    this.setState({
      isModalVisible: true,
      inputValue: item.buyPoints,
    })
  }

  handleOk() {
    // console.log(this.state.inputValue)

    // projectEditSave

    React.$axios.post('projectEditSave', {id: this.state.projectInfoId, buyPoints: this.state.inputValue}).then(res => {

      if(res.code == 20000) {
        message.success('保存成功')
        this.handleCancel()
        this.getInfoProject();
      }

    })


  }

  handleCancel() {
    this.setState({
      isModalVisible: false
    })
  }



  componentDidMount() {
    
    this.getInfoProject();
  }


  getInfoProject() {
    React.$axios.post('infoProject').then(res => {
      let item = res.data.infoProject[0]
      this.setState({
        inputValue: item.buyPoints,
        projectInfoId: item.id
      })

      this.getList(item.buyPoints);

    })

  }

    //

  


  getList(buyPoints) {
    this.setState({
        loading: true
    })
    React.$axios.post('machineDeviceAll', this.state.params).then(res => {
        if(!res.data.countList) {
          res.data.countList = res.data.machineDeviceAll.length;
        }

        res.data.machineDeviceAll.map(item => {
            item.buyPoints = buyPoints
        })

         this.state.pagination.total = res.data.countList
        this.setState({
            tableData: res.data.machineDeviceAll,
            loading: false,
            pagination: this.state.pagination
        })

    })
  }

  onInput(name, e ) {

    let input = e.target.value
    
    this.setState({
      [name]: input
    })

  }




  render() {
    return (
      <div className="machineDeviceList">

        <div className="warp1">
                <div className="head">
                    {/* <button onClick={this.add.bind(this)}>新增</button> */}
                </div>

                <div className="table">
                <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                loading={this.state.loading} rowKey={record => record.id}   columns={this.columns} dataSource={this.state.tableData}  />

                </div>
            

            
            </div>


            <Modal title="最低兑换积分设置" width="500px"  visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>    
              <div className="machineDeviceListModal">
                 <div>
                 <span>最低兑换积分</span>
                 <Input placeholder="请输入最低兑换积分" value={this.state.inputValue}  onInput={this.onInput.bind(this, 'inputValue')} />    
                 </div>
                 {/* <div>
                  <span>安全教育积分</span>
                  <Input placeholder="请输入人员实名制初始化安全教育积分" value={this.state.safetyEducationScore}  onInput={this.onInput.bind(this, 'safetyEducationScore')} />    
                 </div> */}
                 
              </div>      
              
            </Modal> 


      </div>

    );
  }
}
