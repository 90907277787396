import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import './earth.less'

// import * as echarts from 'echarts';

import * as THREE from './three.module.js';

import img2 from '@/assets/img/party/2.png'; 

import pointImg from '@/assets/img/party/point.png'; 
import img6 from '@/assets/img/party/6-1.svg'; // 党旗

import * as d3 from 'd3';

import {InitFly} from '../earth/threeSouce/fly';

import {FlyLine} from './method/flyLineVersion1';

import full from './full.json';

function Earth(props) {
    let container  = null
    let map = null,
    renderer = null,
    scene = null,
    camera = null,
    raycaster = null,
    mouse = null,
    eventOffset = null,
    activeInstersect = [],
    circleObj = new THREE.Object3D(),
    clock = new THREE.Clock(),
    linegroup = [],
    lineObj =  new THREE.Object3D()

    const [propsState, setPropsState] = useState(props)

    useEffect(() => {
       getgetThreeEarth()

      }, propsState)

    // three.js 模型 
    function getgetThreeEarth() {
      container = document.getElementById("containerHHback");

      renderer = new THREE.WebGLRenderer();
      
      renderer.setClearColor('#A52021', 1.0);
      
      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);

      scene = new THREE.Scene();
      
      scene.position.x = 0;
      scene.position.y = 10;
      

      scene.add(circleObj)

      lineObj.position.y = 0


      lineObj.scale.x =  lineObj.scale.y = lineObj.scale.z = 0.2

      scene.add(lineObj)

      camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(0, -53, 62);
      camera.lookAt(0, 0, 0);

      //设置控制
      setController()

      // 设置光照
      setLight()

      // 设置射线
      setRaycaster()

      // 设置动画
      animate()

      // 加载地图
      loadMapData(full);

      // 加载图片
      loadImg(img2)

      // 党旗
      let flag1 =  loadImg(img6, 5, 5)
      flag1.scale.set(0.5,0.5,0.5);
      flag1.position.z = 10;
      flag1.position.y = -16

      let flag2 = flag1.clone()
      flag2.position.y = -11
      flag2.position.x = 13
      circleObj.add(flag2)

      let flag3 = flag1.clone()
      flag3.position.y = -27
      flag3.position.x = 13
      circleObj.add(flag3)


      let flag4 = flag1.clone()
      flag4.position.y = -25
      flag4.position.x = -2
      circleObj.add(flag4)


      // 加载一闪一闪的圈
      let circle = scatterCircle(1, 0.2, 0.3, new THREE.Vector3(1, 0, 0), 0.02);
      circle.position.y = -12.5
      circle.position.x = -2.4


      setTimeout(() => {
        let circle2 = scatterCircle(1, 0.2, 0.3, new THREE.Vector3(1, 0, 0), 0.012);
        circle2.position.y = -6.5
        circle2.position.x = 12
      }, 1500)


      setTimeout(() => {
        let circle2 = scatterCircle(1, 0.2, 0.3, new THREE.Vector3(1, 0, 0), 0.012);
        circle2.position.y = -24.5
        circle2.position.x = 11.5
      }, 1500)
      

      // 加载飞线 1
      let mesh =  FlyLine(0, 50)
      lineObj.add(mesh);
      linegroup.push(mesh);
      mesh.rotateY(Math.PI/180 * -80)
      mesh.rotateX(Math.PI/180 * 0)
      mesh.position.z = 60
      mesh.position.y = -142
      mesh.position.x = 12.5


      
      
      // 加载飞线 2
      let mesh2 = FlyLine(0, 83)
      lineObj.add(mesh2);
      linegroup.push(mesh2);
      mesh2.rotateY(Math.PI/180 * -52)
      mesh2.rotateX(Math.PI/180 * 0)
      mesh2.position.z = 60
      mesh2.position.y = -151
      mesh2.position.x = 7

      // 加载飞线 3
      let mesh3 = FlyLine(0, 70)
      lineObj.add(mesh3);
      linegroup.push(mesh3);
      mesh3.rotateY(Math.PI/180 * 10)
      mesh3.position.z = 40
      mesh3.position.y = -145
      mesh3.position.x = -22.5


      setTimeout(() => {
         // 加载飞线 1
          let mesh =  FlyLine(0, 50)
          lineObj.add(mesh);
          linegroup.push(mesh);
          mesh.rotateY(Math.PI/180 * -80)
          mesh.rotateX(Math.PI/180 * 0)
          mesh.position.z = 60
          mesh.position.y = -142
          mesh.position.x = 12.5

          
          // 加载飞线 2
          let mesh2 = FlyLine(0, 83)
          lineObj.add(mesh2);
          linegroup.push(mesh2);
          mesh2.rotateY(Math.PI/180 * -52)
          mesh2.rotateX(Math.PI/180 * 0)
          mesh2.position.z = 60
          mesh2.position.y = -151
          mesh2.position.x = 7

          // 加载飞线 3
          let mesh3 = FlyLine(0, 70)
          lineObj.add(mesh3);
          linegroup.push(mesh3);
          mesh3.rotateY(Math.PI/180 * 10)
          mesh3.position.z = 40
          mesh3.position.y = -145
          mesh3.position.x = -22.5
      }, 1500)

    }


    function loadImg(img, width = 120, height = 200) { // 加载图片
        //
        const geometry = new THREE.CircleGeometry(width, height);

      // 0C2940

      var texture = THREE.ImageUtils.loadTexture(img, {}, function() {
          
      });
      const material = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true,
      });

      const circle = new THREE.Mesh(geometry, material);

      circleObj.add(circle)
      return circle
    }



    function scatterCircle(r, init, ring, color, speed) {
      
      let uniform = {
        u_color: { value: color },
        u_r: { value: init },
        u_ring: {
          value: ring,
        },
      };
    
      var vs = `
        varying vec3 vPosition;
        void main(){
          vPosition=position;
          gl_Position	= projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `;
      var fs = `
        varying vec3 vPosition;
        uniform vec3 u_color;
        uniform float u_r;
        uniform float u_ring;
    
        void main(){
          float pct=distance(vec2(vPosition.x,vPosition.y),vec2(0.0));
          if(pct>u_r || pct<(u_r-u_ring)){
            gl_FragColor = vec4(1.0,0.0,0.0,0);
          }else{
            float dis=(pct-(u_r-u_ring))/(u_r-u_ring);
            gl_FragColor = vec4(u_color,dis);
          }
        }
      `;
      const geometry = new THREE.CircleGeometry(r, 120);
      var material = new THREE.ShaderMaterial({
        vertexShader: vs,
        fragmentShader: fs,
        side: THREE.DoubleSide,
        uniforms: uniform,
        transparent: true,
        depthWrite: false,
      });
      const circle = new THREE.Mesh(geometry, material);
      circle.scale.x = circle.scale.y = circle.scale.z = 2;
      circle.position.z = 5
      // circleObj.rotateX(-Math.PI/180 * 90);
      circleObj.add(circle)
    
      function render() {
        uniform.u_r.value += speed || 0.1;
        if (uniform.u_r.value >= r) {
          uniform.u_r.value = init;
        }
        requestAnimationFrame(render);
      }
      render();
      return circle;
    }


    function loadMapData(chinaJson){

      map = new THREE.Object3D();

      // map.rotateX(-Math.PI/180 * 90);
      
      const projection = d3.geoMercator().center([104.0, 37.5]).scale(80).translate([0, 0]);
      chinaJson.features.forEach(elem => {
          // 定一个省份3D对象
          const province = new THREE.Object3D();
          // 每个的 坐标 数组
          const coordinates = elem.geometry.coordinates;

        let uv = `
           uniform sampler2D baseTexture;
           uniform sampler2D bloomTexture;
           varying vec2 vUv;
           vec4 getTexture( sampler2D texelToLinearTexture ) {
             return mapTexelToLinear( texture2D( texelToLinearTexture , vUv ) );
           }
          void main() {
             gl_FragColor = ( getTexture( baseTexture ) + vec4( 1.0 ) * getTexture( bloomTexture ) );
          }
        `

          // 循环坐标数组
          coordinates.forEach(multiPolygon => {

            multiPolygon.forEach(polygon => {
              const shape = new THREE.Shape();

              const lineMaterial = new THREE.LineBasicMaterial({
                color: '#A52021'
              });
              // const lineMaterial = new THREE.ShaderMaterial({
              //   vertexShader: vs,
              //   fragmentShader: fs,
              //   side: THREE.DoubleSide,
              //   uniforms: uniform,
              //   transparent: true,
              //   depthWrite: false,
              // });

              

              const lineGeometry = new THREE.Geometry();

              for (let i = 0; i < polygon.length; i++) {
                const [x, y] = projection(polygon[i]);
                if (i === 0) {
                  shape.moveTo(x, -y);
                }
                shape.lineTo(x, -y);
                lineGeometry.vertices.push(new THREE.Vector3(x, -y, 4.01));
              }

              const extrudeSettings = {
                depth: 4,
                bevelEnabled: false
              };

              const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
              const material = new THREE.MeshBasicMaterial({
                color: '#FFE8BE',
                transparent: true,
                opacity: 0.6
              });
              const material1 = new THREE.MeshBasicMaterial({
                color: '#FFE8BE',
                transparent: true,
                opacity: 0.5
              });
              const mesh = new THREE.Mesh(geometry, [material, material1]);
              const line = new THREE.Line(lineGeometry, lineMaterial);
              
              province.add(mesh);
              province.add(line)
            })

          })

          // 将geo的属性放到省份模型中
          province.properties = elem.properties;
          if (elem.properties.contorid) {
            const [x, y] = projection(elem.properties.contorid);
            province.properties._centroid = [x, y];
          }

          map.add(province);

        })


        scene.add(map);

    }


    function animate() { // 设置动画

      let time = Date.now() * 0.0005;

      requestAnimationFrame(animate);

      raycaster.setFromCamera(mouse, camera);
  
      let intersects = raycaster.intersectObjects(scene.children, true);

      if (activeInstersect && activeInstersect.length > 0)  {
        activeInstersect.forEach(element => {
          element.object.material[0].color.set('#FFE8BE');
          element.object.material[1].color.set('#FFE8BE');
        });
      }

      activeInstersect = []


      for (var i = 0; i < intersects.length; i++) {
        if (intersects[i].object.material && intersects[i].object.material.length === 2) {
          activeInstersect.push(intersects[i]);
          intersects[i].object.material[0].color.set(0xFFD29B);
          intersects[i].object.material[1].color.set(0xFFD29B);
          break; // 只取第一个
        }
      }




      var delta = clock.getDelta();

      if(linegroup.length){
          for(var i = 0;i<linegroup.length;i++){
              var flyline = linegroup[i];
              if(flyline && flyline.material.uniforms){
                  var time2 = flyline.material.uniforms.time.value;
                  var size = flyline.material.uniforms.size.value;
                  if(time2 > flyline.maxx){
                      flyline.material.uniforms.time.value = flyline.minx - size;
                  }
                  flyline.material.uniforms.time.value += 1.0;
              }
          }
      }








      renderer.render(scene, camera);
    }


    function setRaycaster() {

      raycaster =  new THREE.Raycaster();
      mouse = new THREE.Vector2();
      eventOffset = {};


      function onMouseMove(event) {

        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components

        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        eventOffset.x = event.clientX;
        eventOffset.y = event.clientY;

        
        // this.provinceInfo.style.left = _this.eventOffset.x + 2 + 'px';
        // this.provinceInfo.style.top = _this.eventOffset.y + 2 + 'px';
      }

      window.addEventListener('mousemove', onMouseMove, false)

    }

    function setController() {
      // controller = new THREE.OrbitControls(camera, renderer.domElement);
    //   controls = new OrbitControls(camera, renderer.domElement);


    }

    function setLight() {
      let ambientLight = new THREE.AmbientLight(0xffffff); // 环境光
      scene.add(ambientLight);
    }



  
  return (
    <div className="EarthEchart">


    <div>
        <div id="containerHHback" className="echart">

        </div>
    </div>
 

    </div >
  )
}

export default withRouter(Earth);