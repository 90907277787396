import React from "react";


import { DatePicker,Input,Select,Button, Table,  Image, message, Tag} from "antd";


import { SearchOutlined, DownOutlined, UpOutlined, PlusOutlined, BorderLeftOutlined, UploadOutlined, SyncOutlined} from '@ant-design/icons';

import './waterConservation.less';

import moment from 'moment';

import {formatTime, getCurrentDayLastDay} from '@/utils/util'

import { DownloadExcel } from '@/components/downloadExcel/downloadExcel';

const {Option} =  Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


let imgUrl = process.env.REACT_APP_IMG_URL


let InitialValues = getCurrentDayLastDay(moment)


export default class WaterConservation extends React.Component {
    columns = [
        {
            title: '序号',
            dataIndex: 'monitorTime',
            align: 'center',
            render: (_, item, index) => {
                return (
                    <p>{index + 1}</p>
                )
            } 
        },
        {
            title: '监测日期',
            dataIndex: 'monitorTime',
            align: 'center',
            render: (_) => {
                return (
                    <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')}</p>
                )
            }
        },
        {
            title: '设备地址',
          dataIndex: 'devAddr',
          align: 'center'
        },
        {
          title: '温度(°C)',
          dataIndex: 'temperature',
          align: 'center'
        },
        {
            title: 'PH',
            dataIndex: 'ph',
            align: 'center'
        },

        {
          title: '余氯(mg/L)',
          dataIndex: 'residualChlorine',
          align: 'center'
        },
        {
            title: '臭氧(mg/L)',
            dataIndex: 'ozone',
            align: 'center'
        },
      
        {
            title: '浊度(NTU)',
            dataIndex: 'turbidity',
            align: 'center'
        },
       
        {
            title: '电导率(μs/cm)',
            dataIndex: 'conductivity',
            align: 'center'
        },
        
        {
            title: 'orp',
            dataIndex: 'ORP(mV)',
            align: 'center'
        },
        {
            title: "悬浮物(mg/l)",
            dataIndex: 'suspendedMatter',
            align: 'center'
        },
      ]

      jsonFieldsList =  {
        '0_监测时间': 'monitorTime',
        "2_温度(°C)": 'temperature',
        "3_PH": 'ph',
        "4_余氯(mg/L)": "residualChlorine",
        "5_氧气(%Vol)": "o2",
        "6_臭氧(mg/L)": "ozone",
        "7_浊度(NTU)": "turbidity",
        "8_电导率(μs/cm)": "conductivity",
        "9_ORP(mV)": "orp",
        "悬浮物(mg/l)": "suspendedMatter",
      }
    



  state = {
    herfEarth: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 200,
      onShowSizeChange: function(current, pageSize) {
          this.state.pagination.pageSize = pageSize;
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      onChange: function(current) {
          this.state.pagination.current = current
          this.handleTableChange(this.state.pagination)
      }.bind(this),
      showTotal: function () {  //设置显示一共几条数据
          return '共 ' + this.state.pagination.total + ' 条'; 
      }.bind(this),
      showQuickJumper: true,
      },
    loading: false,

    tableData: [
        
    ],
    deviceList: [],
    TableParams: {
        idCaName: "",
        lengthLimit: 10,
        offsetLimit: 0,
        logic: 2,
        devAddr: null,
        monitorTimeStart: parseInt(new Date(InitialValues[0]._i + ' 00:00:00').getTime() / 1000),
        monitorTimeStop: parseInt(new Date(InitialValues[1]._i + ' 23:59:59').getTime() / 1000),
    },
    ExcelName: '',
  }


 



  componentDidMount() {
    let projectId =   React.$getStorage('projectInfo').id
    this.state.TableParams.projectId = projectId
    
    // this.getTableData()
    this.getDeviceList()

    this.setState({
        ExcelName: formatTime(new Date(), 'yyyyMMdd水保监测')   
    })
  }

  fetchFun() {
    let params = JSON.parse(JSON.stringify(this.state.TableParams)) 

    params.logic = 3

    if(!params.monitorTimeStart) {
        message.error('请选择时间')
        return
    }

    return new Promise((resolve) => {
        React.$axios.post('listWaterQuality', params).then(res => {
            res.data.list.map(item => {
                item.monitorTime  = formatTime(new Date(item.monitorTime * 1000), 'yyyy-MM-dd hh:ss:mm')
            })
            resolve(res.data.list)
        })
    })
  }


  // 获取设备list
  getDeviceList() {
    React.$axios.post('monitoringDeviceAll2', {type: 5, projectId: this.state.TableParams.projectId}).then(res => {
        
        if(res.data.monitoringDeviceAll[0]) {
            this.state.TableParams.devAddr = res.data.monitoringDeviceAll[0].devAddr
        }

        
        this.setState({
            deviceList: res.data.monitoringDeviceAll,
            TableParams: this.state.TableParams
        })

        this.getTableData()
    })
  }


  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       }) 

      React.$axios.post('listWaterQuality', this.state.TableParams).then(res => {
        this.state.pagination.total = res.data.countList
        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    this.state.TableParams.idCaName = value
  }

  onChangeWork(name, value) {
    this.state.TableParams.devAddr = value

    this.setState({
        TableParams: this.state.TableParams
    })

  }

  toSerch2() {
    this.getTableData(true)
  }

  handleTableChange(value) {
    this.state.pagination = value

    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }

  getChangeTime(val) {
    this.state.TableParams.monitorTimeStart = val? parseInt(new Date(val[0]._i + ' 00:00:00').getTime() / 1000): '' 
    this.state.TableParams.monitorTimeStop = val? parseInt(new Date(val[1]._i + ' 23:59:59').getTime() / 1000): ''
    this.setState({
        TableParams: this.state.TableParams
    })
  }

  render() {
    return (
      <div className="WaterConservation">
          <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>监测时间:</span> 
                        
                        <RangePicker defaultValue={InitialValues}  onChange={this.getChangeTime.bind(this)} style={{  height:30, marginRight:20,  }} />

                        </li>
                        <li><span>设备:</span>  
                        <Select
                                    showSearch
                                    style={{ width: 200,  marginRight:20,  }}
                                    placeholder="请选择"
                                    optionFilterProp="children"
                                    value = {this.state.TableParams.devAddr}
                                    onChange={this.onChangeWork.bind(this, 'devAddr')}
                                >
 
                                 {
                                     this.state.deviceList.map(item => {
                                         return (
                                            <Option value={item.devAddr} key={item.id}>{item.name}</Option>
                                         )
                                     })
                                 }           

                                </Select>
                            </li>

                            <Button  className="btn" onClick={this.toSerch2.bind(this)} style={{marginRight: 20}}>
                                <SearchOutlined  className="icon"/>
                            </Button>


                            <DownloadExcel    name={this.state.ExcelName} fields={this.jsonFieldsList}  fetch={this.fetchFun.bind(this)} btnName="导出" />


                    </ul>
                </div>



                <div className="table2">
                <div class="allBtn">
                   
  
                    {/* <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                        导入
                    </Button>
                    <Button  className="bt" type="primary" icon={<UploadOutlined className="translate" />}>
                        导出
                    </Button> */}
                    {/* <Button  className="bt" type="primary" icon={<PlusOutlined />}>
                        新建
                    </Button> */}
                    </div>


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }} columns={this.columns} dataSource={this.state.tableData}  />
                </div>






        


      </div>
    );
  }
}
