import React from "react";


import {Button,   Image, message} from "antd";
// import './home.less';

import AdminList from './adminList/adminList';
import Authority from './Authority/Authority';
import RoleAuthority from './RoleAuthority/RoleAuthority';

export default class Home extends React.Component {


  state = {
    herfEarth: '',
    status: null,
    childBtn: []
  }


  componentWillMount () {
    let childBtn = React.$getStorage('menuchildBtn');
  for(let i = 0; i < childBtn.length; i++) {
    if(childBtn[i].show) {
      let item = childBtn[i];
      this.changeStatus(item.index);
      break;
    }
  }


  this.setState({
    childBtn: childBtn
  })

    let t =  React.$getStorage('jurIndex')
    if(t) {
        this.setState({
            status: t
        })
    }
 }

 // 组件离开生命周期
 componentWillUnmount() {
    localStorage.removeItem('jurIndex');
  }

  componentDidMount() {
    
   
  }


  changeStatus(type) {
    this.setState({
        status: type
    })

    React.$setStorage('jurIndex', type) 

  }



  render() {
    return (
      <div className="Jurisdiction">
          <div className="btn" style={{paddingLeft: 10}}>
          {
              this.state.childBtn.map(item => {
                  if(item.show) {
                      return (
                          <Button  type={this.state.status==item.index? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, item.index)}>{item.name}</Button>
                          ) 
                  }
                  
              })
          }
           {/* <Button  type={this.state.status==1? 'primary': ''}  className="setBtn" onClick={this.changeStatus.bind(this, 1)}>管理列表</Button>
           <Button className="setBtn" type={this.state.status==2? 'primary': ''}  onClick={this.changeStatus.bind(this, 2)}>权限列表</Button>
           <Button className="setBtn" type={this.state.status==3? 'primary': ''}  onClick={this.changeStatus.bind(this, 3)}>角色权限</Button> */}
          </div>



        <div>
            {
                function() {
                    if(this.state.status == 0) {
                        return (
                            <AdminList/>
                        )
                    }

                    
                    if(this.state.status == 1) {
                        return (
                            <Authority/>
                        )
                    }

                    if(this.state.status == 2) {
                        return (
                            <RoleAuthority/>
                        )
                    }

                   
                }.bind(this)()
            
            }
        </div>





      </div>
    );
  }
}
