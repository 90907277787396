import React from "react";
import { withRouter } from 'react-router-dom';
import EZUIKit from "ezuikit-js";
import './videoDetail.less';
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  LoginOutlined,
  SecurityScanOutlined
} from '@ant-design/icons';

import { formatTime } from '@/utils/util.js'



import { DatePicker, Button, Drawer, Calendar} from 'antd';

// const { RangePicker } = DatePicker;

let player = null
class VideoDetail extends React.Component {
  
  timeout= null
  state = {
    videoDetail: {},
    direction: "",
    speed: 1,
    active: 0,
    isModalVisible: false,
    showTimeChange: false, // 切换状态
    timeVal: new Date(),
    clientWidth: 0,  // 屏幕宽度 

    domEvent: {
      mDown: false,
      beginX: 0,
      positionX : 0,
      beginClientX : 0,
      sliderLong : 0
    },

    processWidth: 0,
    arrTime: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]

  }

  componentDidMount() {
    // document.title = '视频播放';
    // let link = document.querySelector('link[rel*="icon"]') ||document.createElement('link')
    // link.type = 'image/x-icon'
    // link.rel = 'shortcut icon'
    // link.href = 'http://www.stackoverflow.com/favicon.ico'
    // document.getElementsByTagName('head')[0].appendChild(link);
      
    const videoDetail = JSON.parse(decodeURIComponent(this.props.location.search.split('detailData=')[1]))

    const _this = this

    this.setState({
      videoDetail: videoDetail,
      clientWidth: document.body.clientWidth - 20,
      timeVal: new Date()
    })



    document.addEventListener('mouseup', function(e) {
      if (e.button == 0) {          
        _this.onMouseUpCirle(e)
        }
    });


    this.playVideo(videoDetail)
  }


  // 加载大华视频
  dahuaVideo(videoDetail, status) {

   
    let url = 'imou://open.lechange.com/'+ videoDetail.deviceSerial +'/'+ videoDetail.channelNo +'/1?streamId=0'
    // 回放
    if(status) {
      url = 'imou://open.lechange.com/'+ videoDetail.deviceSerial +'/'+ videoDetail.channelNo +'/2?streamId=0&beginTime=' + videoDetail.DaHuaTime1 + '&endTime=' + videoDetail.DaHuaTime2
    }

    if(player) { // 存在参数
      
      player.stop();
      player.destroy();

    }

      player = new window.ImouPlayer('#videoDetail');

      player.setup({
        src: [
          {
            url: url, // url拼接说明请见：1.3 轻应用播放地址说明
            kitToken: videoDetail.kitToken, // 播放Token，通过接口getKitToken获取，具体请见：1.4 getKitToken接口协议说明
          }
        ], // 播放地址
        width:window.innerWidth, // 播放器宽度
        height: window.innerHeight, // 播放器高度
        poster: '', // 封面图url
        autoplay: true, // 是否自动播放
        controls: true, // 是否展示控制栏
      });

    player.play();

  }


  playVideo(videoDetail, status) {

   


    let url = `ezopen://open.ys7.com/${videoDetail.deviceSerial}/${videoDetail.channelNo}.hd.live`

    if(status) {
      url = `ezopen://open.ys7.com/${videoDetail.deviceSerial}/${videoDetail.channelNo}.rec?begin=${videoDetail.time1}&end=${videoDetail.time2}`
    
      this.setState({
        showTimeChange: true
      })
    } else {
      this.setState({
        showTimeChange: false
      })
    }

    this.handleCancel()

     // 大华视频
     if(videoDetail.deviceType == 4) {

      this.dahuaVideo(videoDetail, status)
        return
    } 



    if(player) {
      player.stop();
      player.opt.url = url
    } else {
      player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: 'videoDetail',
        accessToken: videoDetail.accessToken,
        url: url,
        template: "standard", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        //plugin: ['talk'],                       // 加载插件，talk-对讲
        // 视频下方底部控件
        // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
        // openSoundCallBack: data => console.log("开启声音回调", data),
        // closeSoundCallBack: data => console.log("关闭声音回调", data),
        // startSaveCallBack: data => console.log("开始录像回调", data),
        // stopSaveCallBack: data => console.log("录像回调", data),
        // capturePictureCallBack: data => console.log("截图成功回调", data),
        // fullScreenCallBack: data => console.log("全屏回调", data),
        // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
        // width: 612,
        // height: 282,
      });
    }


    player.play();

  }


  mousedown(e) {
    let outerCircleRadius = 75;
    let innerCircleRadius = 22.5;
    let originPageX = e.clientX;
    let originPageY = e.clientY;
    document.onmousemove = (e) => {
      let currentDirection = this.state.direction;
      let distanceLeft = e.pageX - originPageX;
      let distanceTop = e.pageY - originPageY;
      let hypotenuseLength = Math.sqrt(
        Math.pow(distanceLeft, 2) + Math.pow(distanceTop, 2)
      );
      if (hypotenuseLength > outerCircleRadius - innerCircleRadius) {
        let ratio = Math.abs(
          (outerCircleRadius - innerCircleRadius) / hypotenuseLength
        );
        distanceLeft = distanceLeft * ratio;
        distanceTop = distanceTop * ratio;
      }
      if (
        distanceLeft >= (-1 * outerCircleRadius) / 2 &&
        distanceLeft <= outerCircleRadius / 2 &&
        distanceTop >= -1 * (outerCircleRadius - innerCircleRadius) &&
        distanceTop <= -1 * innerCircleRadius
      ) {
        // 上
        this.setState({
          direction: 0
        })
        if (currentDirection !== this.state.direction) {
          this.toControlVideo();
        }
      } else if (
        distanceLeft >= (-1 * outerCircleRadius) / 2 &&
        distanceLeft <= outerCircleRadius / 2 &&
        distanceTop <= outerCircleRadius - innerCircleRadius &&
        distanceTop >= innerCircleRadius
      ) {

        // 下
        this.setState({
          direction: 1
        })
        if (currentDirection !== this.state.direction) {
          this.toControlVideo();
        }

      } else if (
        distanceLeft >= -1 * (outerCircleRadius - innerCircleRadius) &&
        distanceLeft <= -1 * innerCircleRadius &&
        distanceTop <= outerCircleRadius / 2 &&
        distanceTop >= (-1 * outerCircleRadius) / 2
      ) {
        // 左
        this.setState({
          direction: 2
        })
        if (currentDirection !== this.state.direction) {
          this.toControlVideo();
        }
      } else if (
        distanceLeft <= outerCircleRadius - innerCircleRadius &&
        distanceLeft >= innerCircleRadius &&
        distanceTop <= outerCircleRadius / 2 &&
        distanceTop >= (-1 * outerCircleRadius) / 2
      ) {
        // 右
        this.setState({
          direction: 3
        })
        if (currentDirection !== this.state.direction) {
          this.toControlVideo();
        }
      }

      this.innerCircle.style.left = distanceLeft + "px";
      this.innerCircle.style.top = distanceTop + "px";
    };
    document.onmouseup = (e) => {
      this.innerCircle.style.left = 0;
      this.innerCircle.style.top = 0;
      document.onmousemove = document.onmouseup = null;
      this.toStopControlVideo();
    };
  }
  toScaleVideo(direction) {
    this.setState({
      direction: direction
    })
    this.toControlVideo(direction);
    document.onmouseup = (e) => {
      document.onmousemove = document.onmouseup = null;
      this.toStopControlVideo();
    };
  }
  toControlVideo(direction = "") {
    console.log(this.state.direction)

    const {deviceType} = this.state.videoDetail;
    if(deviceType == '4') { // 乐橙云
      const {applicationId, channelNo} = this.state.videoDetail;
      let params = {
        applicationId: applicationId,
        channelNo: channelNo,
        operation: direction ? direction : this.state.direction,
        duration: 10000
      }

      React.$axios.post('controlMovePTZDahua', params)
      return
    }


    let requestData = { // 萤石云
      deviceSerial: this.state.videoDetail.deviceSerial,
      channelNo: this.state.videoDetail.channelNo,
      direction: direction ? direction : this.state.direction,
      speed: this.state.speed,
      applicationId: this.state.videoDetail.applicationId
    };
    React.$axios.post("shiVideoControlStart", requestData);
  }
  toStopControlVideo() {
    const {deviceType} = this.state.videoDetail;
    if(deviceType == '4') {
      const {applicationId, channelNo} = this.state.videoDetail;
      React.$axios.post('controlMovePTZDahua', {applicationId: applicationId, channelNo: channelNo, operation:10, duration: 0 }).then(res => {
        this.setState({
          direction: "",
        })
      })
      return
    }


    let requestData = {
      deviceSerial: this.state.videoDetail.deviceSerial,
      channelNo: this.state.videoDetail.channelNo,
      direction: this.state.direction,
      applicationId: this.state.videoDetail.applicationId
    };
    React.$axios.post("shiVideoControlStop", requestData).finally(() => {
      this.setState({
        direction: "",
      })
    });
  }

  toChangeSpeed(n) {
    this.setState({
      speed: n
    })
  }

  liveVideo(val) {

    if(this.state.active == val) {
      return
    }
    
    this.setState({
      active: val,
    })


    if(val == 0 && this.state.showTimeChange) {
      this.playVideo(this.state.videoDetail)
      
      this.setState({
        showTimeChange: false
      })
    
    }
  }

  handleOk() {

  }

  handleCancel() {
    this.setState({
      isModalVisible: false
    })
  }

  showDrawer() {
    this.setState({
      isModalVisible: true
    })
  }

  // 回放改变时间
  changeTime(val, time) {

    let date =  this.state.timeVal 
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let time1 =  y + '' + m + '' + d + '000000'
    let time2 = y + '' + m + '' + d + '235959'

    let DaHuaTime1 = y + '-' + m + '-' + d + ' 00:00:00'
    let DaHuaTime2 = y + '-' + m + '-' + d + ' 23:59:59'


    if(time) {
      time1 = y + '' + m + '' + d + time
      DaHuaTime1 = y + '-' + m + '-' + d + ' ' + time.slice(0, 2) + ':' + time.slice(2, 4) + ':00';
    } else {
      this.setState({
        processWidth: 0
      })
    }

    this.state.videoDetail.time1 = time1
    this.state.videoDetail.time2 = time2

    this.state.videoDetail.DaHuaTime1 = DaHuaTime1
    this.state.videoDetail.DaHuaTime2 = DaHuaTime2
    

    this.setState({
      showTimeChange: true,
    })

    this.playVideo(this.state.videoDetail, true)

  }

  //  选择日期 
  onChangeCalendar(val) {
    this.setState({
      timeVal: val._d
    })
  }



  mousedownCirle(e) {
    console.log(e)
    if(e.button == 0) {
      this.state.domEvent.mDown = true
      this.state.domEvent.beginX = e.offsetX;
      this.state.domEvent.positionX = e.offsetX;
      this.state.domEvent.beginClientX = e.clientX;
    }


  }

  onMouseMoveCirle(e) {

    if(this.state.domEvent.mDown) {
      this.setState({
        processWidth:  (e.clientX / this.state.clientWidth) * 100
      })
    }

  }

  onMouseUpCirle(e) {

    
    if(e.target.classList[0] == 'progress' || e.target.classList[0] == 'progress-bar' && !this.state.domEvent.mDown) {
      this.setState({
        processWidth:  (e.clientX / this.state.clientWidth) * 100
      })
    }

  
    let data = 24 * 60

    let currentTime =  data  * this.state.processWidth  / 100


    let min  = parseInt(currentTime%60)  
    let house = parseInt(currentTime/60) 
    


    if(min < 10) {
      min = '0' + min
    }

    if(house < 10) {
      house = '0' + house
    }
 
     let time = house + '' + min

    if(this.state.domEvent.mDown && this.state.showTimeChange) {
      this.changeTime('a', time)
    }


    if(e.target.classList[0] == 'progress' || e.target.classList[0] == 'progress-bar' && !this.state.domEvent.mDown) {
      if(this.timeout) {
        clearTimeout(this.timeout)
      }
      
       this.timeout = setTimeout(() => {
        this.changeTime('a', time)
       }, 200) 

    }


    this.state.domEvent.mDown = false
    this.setState({
      domEvent: this.state.domEvent
    })


    

  }


  //
  render() {
    return (
      <div className="video-detail" onMouseMove={this.onMouseMoveCirle.bind(this)} onMouseUp={this.onMouseUpCirle.bind(this)}>
        <div className="video-box" id="videoDetail"></div>

        <div className="position" onClick={this.showDrawer.bind(this)}>
          <SecurityScanOutlined />
        </div>


        
        {/* <div id="demo" style={{opacity: this.state.showTimeChange? '100': '0'}}> */}
        <div id="demo" style={{opacity: this.state.showTimeChange? '100': '0'}}>
            {/* <input type="range"  id="inp"></input> */}

            <div className="setDateHH">
              {formatTime(this.state.timeVal, 'yyyy-MM-dd回放')}
            </div>

            <div className="progress">
                <div className="progress-bar" style={{width: this.state.processWidth + '%'}}>
                    <div className="progress-thumb"  onMouseDown={this.mousedownCirle.bind(this)}></div>
                </div>


                <ul>
                  {
                    this.state.arrTime.map(item => {
                      return (
                        <li>{item}</li>
                      )
                    })
                  }
                </ul>

            </div>

           
    
        </div>
        


        <Drawer
          title="操作"
          placement="right"
          closable={true}
          width={500}
          onClose={this.handleCancel.bind(this)}
          visible={this.state.isModalVisible}
          
        >
          <div className="video-detail">
          <div className="video-control">
            <div className="outter-circle">
              <div className="direction-wrap">
                <div  className={'inner-parts ' +   this.state.direction === 0 ? 'active' : ''}></div>
                <div  className={'inner-parts ' +  this.state.direction === 3 ? 'active' : ''}></div>
                <div  className={'inner-parts ' +  this.state.direction === 2 ? 'active' : ''}></div>
                <div  className={'inner-parts ' + this.state.direction === 1 ? 'active' : ''}></div>
              </div >
              <div className="inner-circle" ref={e => (this.innerCircle = e)} onMouseDown={this.mousedown.bind(this)}>
                <div className="ok rotate">
                </div>
              </div>
            </div >
            <div className="speed-wrap">
              <span className={this.state.speed === 0 ? 'active' : ''} onClick={this.toChangeSpeed.bind(this, 0)}>慢</span>
              <span className={this.state.speed === 1 ? 'active' : ''} onClick={this.toChangeSpeed.bind(this, 1)}>适中</span>
              <span className={this.state.speed === 2 ? 'active' : ''} onClick={this.toChangeSpeed.bind(this, 2)}>快</span>
            </div>
            <div className="scale-box">
              <div>
                <ZoomInOutlined   style={{ fontSize: '30px', color: '#CCCCCC' }} className="scale-icon" onMouseDown={this.toScaleVideo.bind(this,8)} />
              </div>
              <div>
                <ZoomOutOutlined  style={{ fontSize: '30px', color: '#CCCCCC' }} className="scale-icon" onMouseDown={this.toScaleVideo.bind(this,9)} />
              </div>
            </div>



            <div style={{marginTop: 30}}>
            <Button  type={this.state.active == 0 ? "primary": ''} onClick={this.liveVideo.bind(this, 0)}>直播</Button>
            <Button  type={this.state.active == 1 ? "primary": ''} onClick={this.liveVideo.bind(this, 1)}>回放</Button>

            </div>

            <div style={{marginTop: 30, width: 444}} className="CalendarStyle">
            <div style={{display: this.state.active == 1? 'block': 'none'}}>
             {/* <RangePicker value={this.state.timeVal} showTime onChange={this.changeTime.bind(this)} /> */}
            
             <Calendar className="CalendarHH" fullscreen={false} onPanelChange={this.onChangeCalendar.bind(this)} onChange={this.onChangeCalendar.bind(this)}  />

             
             <div className="setBtn">
             <Button  type="primary" onClick={this.changeTime.bind(this, 'e', false)}>确定</Button>
             </div>
             

            </div>
            
            </div>
            

          </div >
          </div>

        </Drawer>


        
      </div >
    )
  }
}

export default withRouter(VideoDetail);