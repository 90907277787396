

export  class Measure {


    /**
     * 
     * @param {*} viewer  当前操作对象
     * @param {*} Cesium  Cesium
     */
    constructor(viewer, Cesium)  {
        this._viewer = viewer;
        this.Cesium = Cesium;
        this.speed = 0; // 鼠标滑动速度

        this._drawLayer = new this.Cesium.CustomDataSource('measureLayer')
        viewer && viewer.dataSources.add(this._drawLayer)
    } 


    /**
     *  坐标转换 84转笛卡尔
     *  @param {Object} {lng,lat,alt} 地理坐标
     *  @return {Object} Cartesian3 三维位置坐标
     */
    transformWGS84ToCartesian(position, alt) {
        if (this._viewer) {
            return position
                ? this.Cesium.Cartesian3.fromDegrees(
                    position.lng || position.lon,
                    position.lat,
                    position.alt = alt || position.alt,
                    this.Cesium.Ellipsoid.WGS84
                )
                : this.Cesium.Cartesian3.ZERO
        }
    }
    /**
     *  @param {Object} cartesian 三维位置坐标
     *  @return {Object} {lng,lat,alt} 地理坐标
     */
    transformCartesianToWGS84(cartesian) {
        if (this._viewer && cartesian) {
            var ellipsoid = this.Cesium.Ellipsoid.WGS84
            var cartographic = ellipsoid.cartesianToCartographic(cartesian)
            return {
                lng: this.Cesium.Math.toDegrees(cartographic.longitude),
                lat: this.Cesium.Math.toDegrees(cartographic.latitude),
                alt: cartographic.height
            }
        }
    }
    /**
     * 坐标数组转换 笛卡尔转86
     * @param {Array} cartesianArr 三维位置坐标数组
     * @return {Array} {lng,lat,alt} 地理坐标数组
     */
    // 坐标数组转换 笛卡尔转86
    transformCartesianArrayToWGS84Array(cartesianArr) {
        if (this._viewer) {
            var $this = this
            return cartesianArr
                ? cartesianArr.map(function (item) { return $this.transformCartesianToWGS84(item) })
                : []
        }
    }



    /**
     *  获取84坐标的距离
     *  @param {*} positions 
     */
    getPositionDistance(positions) {
        let distance = 0
        for (let i = 0; i < positions.length - 1; i++) {
            let point1cartographic = this.transformWGS84ToCartographic(positions[i])
            let point2cartographic = this.transformWGS84ToCartographic(positions[i + 1])
            let geodesic = new this.Cesium.EllipsoidGeodesic()
            geodesic.setEndPoints(point1cartographic, point2cartographic)
            let s = geodesic.surfaceDistance



            s = Math.sqrt(
                Math.pow(s, 2) +
                Math.pow(point2cartographic.height - point1cartographic.height, 2)
            )
            distance = distance + s
        }

        return distance.toFixed(3)

    }

    /**
     *  84坐标转弧度坐标
     *  @param {Object} position wgs84
     *  @return {Object} Cartographic 弧度坐标
     */
    transformWGS84ToCartographic(position) {
        return position
            ? this.Cesium.Cartographic.fromDegrees(
                position.lng || position.lon,
                position.lat,
                position.alt
            )
            : this.Cesium.Cartographic.ZERO
    }




    /** 拾取位置点
     *  
     * 
     * @param {*} px  屏幕坐标
     * 
     * @return {Object} Cartesian3 三维坐标
     */
    getCatesian3FromPX(px) {

        if(this._viewer && px) {
            let picks = this._viewer.scene.drillPick(px)
            let cartesian = null;
            let isOn3dtiles = false, isOnTerrain = false;

            for(let i in picks) {
                let pick = picks[i];

                if (pick &&
                    pick.primitive instanceof this.Cesium.Cesium3DTileFeature
                    || pick && pick.primitive instanceof this.Cesium.Cesium3DTileset
                    || pick && pick.primitive instanceof this.Cesium.Model) { //模型上拾取
                    isOn3dtiles = true;
                }

                // 3dtilset
                if(isOn3dtiles) {
                    this._viewer.scene.pick(px) // pick
                    cartesian = this._viewer.scene.pickPosition(px);
                    if(cartesian) {
                        let  cartographic = this.Cesium.Cartographic.fromCartesian(cartesian);
                        if (cartographic.height < 0) cartographic.height = 0;
                        let lon = this.Cesium.Math.toDegrees(cartographic.longitude)
                        let lat = this.Cesium.Math.toDegrees(cartographic.latitude)
                        let height = cartographic.height;
                        cartesian = this.transformWGS84ToCartesian({ lng: lon, lat: lat, alt: height })
                    }
                }
            }

      
            // 地形
            let boolTerrain = this._viewer.terrainProvider instanceof this.Cesium.EllipsoidTerrainProvider;

            // Terrain
            if (!isOn3dtiles && !boolTerrain) {

                var ray = this._viewer.scene.camera.getPickRay(px);
                if (!ray) return null;
                cartesian = this._viewer.scene.globe.pick(ray, this._viewer.scene);
                isOnTerrain = true
            }


            // 地球
            if (!isOn3dtiles && !isOnTerrain && boolTerrain) {
                cartesian = this._viewer.scene.camera.pickEllipsoid(px, this._viewer.scene.globe.ellipsoid);

                if (cartesian) {
                    let position = this.transformCartesianToWGS84(cartesian)
                    if (position.alt < 0) {
                        cartesian = this.transformWGS84ToCartesian(position, 0.1)
                    }
                    return cartesian;
                }

                return false;
            }


            return cartesian;
        }

    }

    /**
    * 计算一组坐标组成多边形的面积
    * @param {*} positions 
    */
    getPositionsArea(positions) {
        let result = 0
        if (positions) {
            let h = 0
            let ellipsoid = this.Cesium.Ellipsoid.WGS84
            positions.push(positions[0])
            for (let i = 1; i < positions.length; i++) {
                let oel = ellipsoid.cartographicToCartesian(
                    this.transformWGS84ToCartographic(positions[i - 1])
                )
                let el = ellipsoid.cartographicToCartesian(
                    this.transformWGS84ToCartographic(positions[i])
                )
                h += oel.x * el.y - el.x * oel.y
            }
            result = Math.abs(h).toFixed(2)
        }
        return result
    }


  
    /**
     *  画三角量测
     *  @param {*} options 
     */

    drawTrianglesMeasureGraphics(options = {}) {
        options.style = options.style ||
        {
            width: 3
            , material: new this.Cesium.Color(199 / 255,29 / 255, 76/ 255 , 1.0)   // this.Cesium.Color.BLUE.withAlpha(0.5)
        }

        if (this._viewer && options) {

            var _trianglesEntity = new this.Cesium.Entity(), _tempLineEntity = new this.Cesium.Entity(), _tempLineEntity2 = new this.Cesium.Entity(),
                _positions = [], _tempPoints = [], _tempPoints2 = [], $this = this,
                _handler = new this.Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);
            // 高度
            function _getHeading(startPosition, endPosition) {
                if (!startPosition && !endPosition) return 0
                if ($this.Cesium.Cartesian3.equals(startPosition, endPosition)) return 0
                let cartographic = $this.Cesium.Cartographic.fromCartesian(startPosition);
                let cartographic2 = $this.Cesium.Cartographic.fromCartesian(endPosition);
                return (cartographic2.height - cartographic.height).toFixed(2)
            }
            // 偏移点
            function _computesHorizontalLine(positions) {
                let cartographic = $this.Cesium.Cartographic.fromCartesian(positions[0]);
                let cartographic2 = $this.Cesium.Cartographic.fromCartesian(positions[1]);
                return $this.Cesium.Cartesian3.fromDegrees(
                    $this.Cesium.Math.toDegrees(cartographic.longitude),
                    $this.Cesium.Math.toDegrees(cartographic.latitude),
                    cartographic2.height
                )
            }
            // left
            _handler.setInputAction(function (movement) {

                var position = $this.getCatesian3FromPX(movement.position);
                if (!position && !position.z) return false
                if (_positions.length == 0) {
                    _positions.push(position.clone())
                    _positions.push(position.clone())
                    _tempPoints.push(position.clone())
                    _tempPoints.push(position.clone())
                } else {
                    _handler.destroy();
                    if (typeof options.callback === 'function') {

                        options.callback({ e: _trianglesEntity, e2: _tempLineEntity, e3: _tempLineEntity2 });
                    }
                }
            }, $this.Cesium.ScreenSpaceEventType.LEFT_CLICK);
            // mouse
            _handler.setInputAction(function (movement) {

                var position = $this.getCatesian3FromPX(movement.endPosition);
                if (position && _positions.length > 0) {
                    //直线
                    _positions.pop()
                    _positions.push(position.clone());
                    let horizontalPosition = _computesHorizontalLine(_positions)
                    //高度
                    _tempPoints.pop()
                    _tempPoints.push(horizontalPosition.clone())
                    //水平线
                    _tempPoints2.pop()
                    _tempPoints2.pop()
                    _tempPoints2.push(position.clone())
                    _tempPoints2.push(horizontalPosition.clone())
                }
            }, $this.Cesium.ScreenSpaceEventType.MOUSE_MOVE)

            // create entity

            //直线
            _trianglesEntity.polyline = {
                positions: new $this.Cesium.CallbackProperty(function () {
                    return _positions
                }, false),
                ...options.style
            }
            _trianglesEntity.position = new $this.Cesium.CallbackProperty(function () {
                return _positions[0]
            }, false)
            _trianglesEntity.point = {
                pixelSize: 5,
                outlineColor: new $this.Cesium.Color(0 / 255,78 / 255, 161/ 255 , 1.0),
                outlineWidth: 5
            }


             

            // new  $this.Cesium.Color(red, green, blue, 0.5),  

            _trianglesEntity.label = {
                text: new $this.Cesium.CallbackProperty(function () {
                    
                    return '直线:' + ($this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_positions))/1).toFixed(2) + '米'
                }, false),
                show: true,
                showBackground: true,
                font: '20px monospace',
                horizontalOrigin: $this.Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: $this.Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new $this.Cesium.Cartesian2(50, -100) //left top
            }
            //高度
            _tempLineEntity.polyline = {
                positions: new $this.Cesium.CallbackProperty(function () {
                    return _tempPoints
                }, false),
                ...options.style
            }
            _tempLineEntity.position = new $this.Cesium.CallbackProperty(function () {
                return _tempPoints2[1]
            }, false)
            _tempLineEntity.point = {
                pixelSize: 5,
                outlineColor: new $this.Cesium.Color(0 / 255,78 / 255, 161/ 255 , 1.0),
                outlineWidth: 5
            }
            _tempLineEntity.label = {
                text: new $this.Cesium.CallbackProperty(function () {
                    return '高度:' + _getHeading(_tempPoints[0], _tempPoints[1]) + '米'
                }, false),
                show: true,
                showBackground: true,
                font: '20px monospace',
                horizontalOrigin: $this.Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: $this.Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new $this.Cesium.Cartesian2(-20, 100) //left top
            }
            //水平
            _tempLineEntity2.polyline = {
                positions: new $this.Cesium.CallbackProperty(function () {
                    return _tempPoints2
                }, false),
                ...options.style
            }
            _tempLineEntity2.position = new $this.Cesium.CallbackProperty(function () {
                return _positions[1]
            }, false)
            _tempLineEntity2.point = {
                pixelSize: 5,
                outlineColor: new $this.Cesium.Color(0 / 255,78 / 255, 161/ 255 , 1.0),
                outlineWidth: 5
            }
            _tempLineEntity2.label = {
                text: new $this.Cesium.CallbackProperty(function () {
                    return '水平距离:' + $this.getPositionDistance($this.transformCartesianArrayToWGS84Array(_tempPoints2)) + '米'
                }, false),
                show: true,
                showBackground: true,
                font: '20px monospace',
                horizontalOrigin: $this.Cesium.HorizontalOrigin.LEFT,
                verticalOrigin: $this.Cesium.VerticalOrigin.BOTTOM,
                pixelOffset: new $this.Cesium.Cartesian2(-150, -20) //left top
            }
            this._drawLayer.entities.add(_tempLineEntity2)
            this._drawLayer.entities.add(_tempLineEntity)
            this._drawLayer.entities.add(_trianglesEntity)
        }
    }


    /**
     *  面积
     */
    drawAreaMeasureGraphics(options = {}) {
        if (this._viewer && options) {

            var positions = [], polygon = new this.Cesium.PolygonHierarchy(), _polygonEntity = new this.Cesium.Entity(), $this = this, polyObj = null, _label = '',
                _handler = new this.Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);
            // left
            _handler.setInputAction(function (movement) {

                var cartesian = $this.getCatesian3FromPX(movement.position);
                if (cartesian && cartesian.x) {
                    if (positions.length == 0) {
                        polygon.positions.push(cartesian.clone())
                        positions.push(cartesian.clone());
                    }
                    positions.push(cartesian.clone());
                    polygon.positions.push(cartesian.clone())

                    if (!polyObj) create()
                }
            }, this.Cesium.ScreenSpaceEventType.LEFT_CLICK);
            // mouse
            _handler.setInputAction(function (movement) {

                var cartesian = $this.getCatesian3FromPX(movement.endPosition);
                // var cartesian = $this._viewer.scene.camera.pickEllipsoid(movement.endPosition, $this._viewer.scene.globe.ellipsoid);
                if (positions.length >= 2) {
                    if (cartesian && cartesian.x) {
                        positions.pop()
                        positions.push(cartesian);
                        polygon.positions.pop()
                        polygon.positions.push(cartesian);
                    }
                }
            }, this.Cesium.ScreenSpaceEventType.MOUSE_MOVE);

            // right
            _handler.setInputAction(function (movement) {
                let cartesian = $this.getCatesian3FromPX(movement.endPosition);

                _handler.destroy();

                positions.push(positions[0]);

                // 添加信息点
                _addInfoPoint(positions[0])
                if (typeof options.callback === 'function') {

                    options.callback($this.transformCartesianArrayToWGS84Array(positions), polyObj);
                }
            }, this.Cesium.ScreenSpaceEventType.RIGHT_CLICK);

            function create() {
                _polygonEntity.polyline = {
                    width: 3
                    , material: new $this.Cesium.Color(199 / 255,29 / 255, 76/ 255 , 1.0)     //$this.Cesium.Color.BLUE.withAlpha(0.8)
                    , clampToGround: options.clampToGround || false
                }

                // new $this.Cesium.Color(199 / 255,29 / 255, 76/ 255 , 1.0)
                // new $this.Cesium.Color(0 / 255,78 / 255, 161/ 255 , 1.0)

                _polygonEntity.polyline.positions = new $this.Cesium.CallbackProperty(function () {
                    return positions
                }, false)

                _polygonEntity.polygon = {

                    hierarchy: new $this.Cesium.CallbackProperty(function () {
                        return polygon
                    }, false),

                    material: $this.Cesium.Color.WHITE.withAlpha(0.1)
                    , clampToGround: options.clampToGround || false
                }

                polyObj = $this._drawLayer.entities.add(_polygonEntity)
            }

            function _addInfoPoint(position) {
                var _labelEntity = new $this.Cesium.Entity()
                _labelEntity.position = position
                _labelEntity.point = {
                    pixelSize: 10,
                    outlineColor: new $this.Cesium.Color(0 / 255,78 / 255, 161/ 255 , 1.0),
                    outlineWidth: 5
                }



                _labelEntity.label = {
                    text: ($this.getPositionsArea($this.transformCartesianArrayToWGS84Array(positions)) / 1000).toFixed(2) + 'm²',
                    show: true,
                    showBackground: true,
                    font: '20px monospace',
                    horizontalOrigin: $this.Cesium.HorizontalOrigin.LEFT,
                    verticalOrigin: $this.Cesium.VerticalOrigin.BOTTOM,
                    pixelOffset: new $this.Cesium.Cartesian2(-20, -50) //left top
                }
                $this._drawLayer.entities.add(_labelEntity)
            }
        }
    }



    /**
     * 测距
     * @param {*} options 
     */
    drawLineMeasureGraphics(options = {}) {
        if(this._viewer && options) {
            let positions = [],  _lineEntity = new this.Cesium.Entity(), $this = this, lineObj, 
                _handlers = new this.Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);


            _handlers.setInputAction(function (movement) {

                const cartesian = $this.getCatesian3FromPX(movement.position);


                if (cartesian && cartesian.x) {
                    if (positions.length == 0) {
                        positions.push(cartesian.clone());
                    }

                    

                    // 添加量测信息点
                    _addInfoPoint(cartesian)
                    positions.push(cartesian);
                }

            }, this.Cesium.ScreenSpaceEventType.LEFT_CLICK);
            
             
            _handlers.setInputAction(function (movement) {

                var cartesian = $this.getCatesian3FromPX(movement.endPosition);
                if (positions.length >= 2) {
                    if (cartesian && cartesian.x) {
                        positions.pop();
                        positions.push(cartesian);
                    }
                }
            }, this.Cesium.ScreenSpaceEventType.MOUSE_MOVE);

            _handlers.setInputAction(function (movement) {

                _handlers.destroy()
                _handlers = null

                let cartesian = $this.getCatesian3FromPX(movement.position);
                _addInfoPoint(cartesian)

                if (typeof options.callback === 'function') {

                    options.callback($this.transformCartesianArrayToWGS84Array(positions), lineObj);
                }
            }, this.Cesium.ScreenSpaceEventType.RIGHT_CLICK);


            _lineEntity.polyline = {
                width: options.width || 5
                , material: options.material ||  new $this.Cesium.Color(199 / 255,29 / 255, 76/ 255 , 1.0) // this.Cesium.Color.BLUE.withAlpha(0.8)
                , clampToGround: options.clampToGround || false
            }
            _lineEntity.polyline.positions = new this.Cesium.CallbackProperty(function () {
                return positions
            }, false)


            lineObj = this._drawLayer.entities.add(_lineEntity)

            //添加坐标点
            function _addInfoPoint(position) {
                let _labelEntity = new $this.Cesium.Entity()
                _labelEntity.position = position
                _labelEntity.point = {
                    pixelSize: 10,
                    outlineColor:  new $this.Cesium.Color(0 / 255,78 / 255, 161/ 255 , 1.0), // $this.Cesium.Color.BLUE,
                    outlineWidth: 5
                }
                
                // console.log($this.getPositionDistance($this.transformCartesianArrayToWGS84Array(positions)))
                _labelEntity.label = {
                    text: (($this.getPositionDistance($this.transformCartesianArrayToWGS84Array(positions))) / 1).toFixed(2)  + 'm',
                    show: true,
                    showBackground: true,
                    font: '20px monospace',
                    horizontalOrigin: $this.Cesium.HorizontalOrigin.LEFT,
                    verticalOrigin: $this.Cesium.VerticalOrigin.BOTTOM,
                    pixelOffset: new $this.Cesium.Cartesian2(-20, -80) //left top
                }

               

                $this._drawLayer.entities.add(_labelEntity)
            }




        }
    }



      /**
     * 测体积
     * @param {*} options 
     */
    drawVolumeMeasureGraphics(options = {})  {

        if (this._viewer && options) {

            var positions = [], polygon = new this.Cesium.PolygonHierarchy(), _polygonEntity = new this.Cesium.Entity(), $this = this, polyObj = null, _label = '',
                _handler = new this.Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);
            // left
            _handler.setInputAction(function (movement) {

                var cartesian = $this.getCatesian3FromPX(movement.position);

                if (cartesian && cartesian.x) {
                    if (positions.length == 0) {
                        polygon.positions.push(cartesian.clone())
                        positions.push(cartesian.clone());
                    }
                    positions.push(cartesian.clone());
                    polygon.positions.push(cartesian.clone())

                    if (!polyObj) create()
                }
            }, this.Cesium.ScreenSpaceEventType.LEFT_CLICK);
            // mouse
            _handler.setInputAction(function (movement) {
                var cartesian = $this.getCatesian3FromPX(movement.endPosition);
                // var cartesian = $this._viewer.scene.camera.pickEllipsoid(movement.endPosition, $this._viewer.scene.globe.ellipsoid);
                if (positions.length >= 2) {
                    if (cartesian && cartesian.x) {
                        positions.pop()
                        positions.push(cartesian);
                        polygon.positions.pop()
                        polygon.positions.push(cartesian);
                    }
                }
            }, this.Cesium.ScreenSpaceEventType.MOUSE_MOVE);

            // right
            _handler.setInputAction(function (movement) {
               
                let cartesian = $this.getCatesian3FromPX(movement.endPosition);
                
                _handler.destroy();
                positions.push(positions[0]);

                // 添加信息点
                _addInfoPoint(positions[0])
                if (typeof options.callback === 'function') {

                    options.callback($this.transformCartesianArrayToWGS84Array(positions), polyObj);
                }
            }, this.Cesium.ScreenSpaceEventType.RIGHT_CLICK);

            function create() {
                _polygonEntity.polyline = {
                    width: 3
                    , material: $this.Cesium.Color.BLUE.withAlpha(0.8)
                    , clampToGround: false
                }

                _polygonEntity.polyline.positions = new $this.Cesium.CallbackProperty(function () {
                    return positions
                }, false)

                _polygonEntity.polygon = {

                    hierarchy: new $this.Cesium.CallbackProperty(function () {
                        return polygon
                    }, false),

                    material: $this.Cesium.Color.WHITE.withAlpha(0.1)
                    , clampToGround: options.clampToGround || false
                }

                polyObj = $this._drawLayer.entities.add(_polygonEntity)
            }

            function _addInfoPoint(position) {
                let minHeight  = 15000, lonlats = [];
                for(let i = 0; i < positions.length; i++) {
                    let cartographic  = $this.Cesium.Cartographic.fromCartesian(positions[i]);
                    let height = $this._viewer.scene.globe.getHeight(cartographic);
                    if (minHeight > height)
                    minHeight = height;

                    var lng = $this.Cesium.Math.toDegrees(cartographic.longitude),
                    lat = $this.Cesium.Math.toDegrees(cartographic.latitude);
                    lonlats.push(lng, lat, 0);
                }

                let granularity = Math.PI / Math.pow(2, 11);
                granularity = granularity / (64);
                //创建多边形平面几何体
                let polygonGeometry = new $this.Cesium.PolygonGeometry.fromPositions(
                    {
                        positions: positions,
                        vertexFormat: $this.Cesium.PerInstanceColorAppearance.FLAT_VERTEX_FORMAT,
                        granularity: granularity
                    }
                );

                // 创建多边形平面几何体
                let geom = new $this.Cesium.PolygonGeometry.createGeometry(polygonGeometry);
                let totalVolume = 0; 
                let maxHeight = 0;  
                let i0, i1, i2;
                let height1, height2, height3;
                let p1, p2, p3;
                let cartesian;
                let cartographic;
                let bottomArea; 
                //循环计算网格的节点, indices顶点索引数据，用于确定几何中的基元 
                for (var i = 0; i < geom.indices.length; i += 3) {
                    i0 = geom.indices[i];
                    i1 = geom.indices[i + 1];
                    i2 = geom.indices[i + 2];
                    
                    //获取几何体三角面第一个面点
                    cartesian = new $this.Cesium.Cartesian3(geom.attributes.position.values[i0 * 3],
                        geom.attributes.position.values[i0 * 3 + 1],
                        geom.attributes.position.values[i0 * 3 + 2]);

                    cartographic = $this.Cesium.Cartographic.fromCartesian(cartesian);    
                    height1 = $this._viewer.scene.globe.getHeight(cartographic);

                    p1 = $this.Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0);
                    if (maxHeight < height1)
                        maxHeight = height1;    
                    
                    //获取几何体三角面第二个面点
                    cartesian = new $this.Cesium.Cartesian3(geom.attributes.position.values[i1 * 3],
                    geom.attributes.position.values[i1 * 3 + 1],
                    geom.attributes.position.values[i1 * 3 + 2]);
                    cartographic = $this.Cesium.Cartographic.fromCartesian(cartesian);

                    height2 = $this._viewer.scene.globe.getHeight(cartographic);    
                    
                    p2 = $this.Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0);
                    if (maxHeight < height2)
                        maxHeight = height2;

                    ////获取几何体三角面第三个面点
                    cartesian = new $this.Cesium.Cartesian3(geom.attributes.position.values[i2 * 3],
                    geom.attributes.position.values[i2 * 3 + 1],
                    geom.attributes.position.values[i2 * 3 + 2]);
                    cartographic = $this.Cesium.Cartographic.fromCartesian(cartesian);

                    height3 = $this._viewer.scene.globe.getHeight(cartographic);    
                    p3 = $this.Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0);
                    if (maxHeight < height3)
                        maxHeight = height3;
                    
                    //计算三角面的面积
                    // bottomArea = computeAreaOfTriangle(p1, p2, p3);    

                }    
                
                // var _labelEntity = new $this.Cesium.Entity()
                // _labelEntity.position = position
                // _labelEntity.point = {
                //     pixelSize: 10,
                //     outlineColor: $this.Cesium.Color.BLUE,
                //     outlineWidth: 5
                // }
                // _labelEntity.label = {
                //     text: ($this.getPositionsArea($this.transformCartesianArrayToWGS84Array(positions)) / 1000000.0).toFixed(4) + '平方公里',
                //     show: true,
                //     showBackground: true,
                //     font: '14px monospace',
                //     horizontalOrigin: $this.Cesium.HorizontalOrigin.LEFT,
                //     verticalOrigin: $this.Cesium.VerticalOrigin.BOTTOM,
                //     pixelOffset: new $this.Cesium.Cartesian2(-20, -50) //left top
                // }
                // $this._drawLayer.entities.add(_labelEntity)
            }
        }





    }



    FreePerspective(options = {}) { // 自由视角 // 获取当前点击的对象
        if(this._viewer && options) {

            let _handler = new this.Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);
            let $this = this;

            _handler.setInputAction(function (movement) {
                var cartesian = $this.getCatesian3FromPX(movement.position);
                 
                if (typeof options.callback === 'function') {
                    options.callback(cartesian, movement.position);
                }
            }, this.Cesium.ScreenSpaceEventType.LEFT_CLICK);

            _handler.setInputAction(function (movement) {
                _handler.destroy();
                options.callback(false);
            }, this.Cesium.ScreenSpaceEventType.RIGHT_CLICK);



        }
    }


    lookFactor(options = {}) { // 从新定义鼠标移动速率

        if(this._viewer && options) {

     
            let _handler = new this.Cesium.ScreenSpaceEventHandler(this._viewer.scene.canvas);
            let $this = this;
            let flags  = { // 
                looking : false, 
            }
            let ellipsoid = this._viewer.scene.globe.ellipsoid;
            let canvas = this._viewer.canvas;
            let startMousePosition;
            let mousePosition;

            _handler.setInputAction(function (movement) {
                flags.looking = true;    
                mousePosition = startMousePosition = $this.Cesium.Cartesian3.clone(movement.position);
            }, this.Cesium.ScreenSpaceEventType.LEFT_DOWN);

            _handler.setInputAction(function (movement) {
                
                if(flags.looking) {
                    mousePosition = movement.endPosition;
                    var width = canvas.clientWidth;
                    var height = canvas.clientHeight;
                    var camera = $this._viewer.camera;

                    // 鼠标滑动的距离的x或y/网页可见区域的宽或者高
                    var x = (mousePosition.x - startMousePosition.x) / width;
                    var y = -(mousePosition.y - startMousePosition.y) / height;
                    var lookFactor = $this.speed;
                    camera.moveLeft(x * lookFactor);
                    camera.moveUp(-y * lookFactor);


                    // var cameraHeight = ellipsoid.cartesianToCartographic(camera.position).height;
                    // var moveRate =  y;

                    // camera.moveForward(-y);
                }

            }, this.Cesium.ScreenSpaceEventType.MOUSE_MOVE);
            
            _handler.setInputAction(function(position) {
                flags.looking = false;
            }, this.Cesium.ScreenSpaceEventType.LEFT_UP);

            _handler.setInputAction(function (movement) {
                _handler.destroy();
                options.callback(false);
            }, this.Cesium.ScreenSpaceEventType.RIGHT_CLICK);



            // this._viewer.clock.onTick.addEventListener(function (clock) {
            //     var camera = $this._viewer.camera;
            //     if (flags.looking) {
            //         var width = canvas.clientWidth;
            //         var height = canvas.clientHeight;
            
                    
        
            //         //相机移动
            //         // camera.lookRight(x * lookFactor);
            //         // camera.lookUp(y * lookFactor);
            //     }
            // })


        }



    }
     


}





