import React from "react";

// 引入编辑器组件
import BraftEditor from 'braft-editor';

// 引入编辑器样式
import 'braft-editor/dist/index.css'

import './newsList.less';
import moment from 'moment';

import { DatePicker,Input,Select,Button, Table,  Image, message, Tag, Modal, Form, Popconfirm } from "antd";


import { SearchOutlined, PlusOutlined} from '@ant-design/icons';


import { Uploadhh } from "@/components/upload/upload";

import {formatTime, BraftEditorIMGUpload} from '@/utils/util'

const {Option} =  Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


let imgUrl = process.env.REACT_APP_IMG_URL




export default class BehaviorManagement extends React.Component {
    FormSbmitRef = null
    editorInstance = null
    columns = [
        {
            title: '序号',
            dataIndex: 'title',
            align:'center',
            render: (_, item, index) => {
                return (
                    <p>{index+1}</p>
                )
            }
        },
        {
            title: '标题',
            dataIndex: 'title',
            align: 'left'
          },
          {
            title: '摘要',
            dataIndex: 'summary',
            align: 'center',
            render: (_) => {
                return (
                    <p className="setWidth">{_}</p>
                )
            }
          },
          {
            title: '新闻类型',
            dataIndex: 'type',
            align: 'center',
            render: (_) => {
                return (
                    <p>{_ == 2? '普通新闻':'党建新闻'}</p>
                )
            }
        },
          {
            title: '权重',
            dataIndex: 'weigh',
            align: 'center'
          },

        

        {
            title: '日期',
            dataIndex: 'addTime',
            align: 'center',
            render: (_) => {
                return (
                    <p>{formatTime(new Date(_ * 1000), 'yyyy-MM-dd hh:mm:ss')}</p>
                )
            }
        },
        
       
        {
            title: '操作',
            dataIndex: 'type',
            align: 'center',
            width: '170px',
            render: (_, item) => {
                return (
                    <div className="operation">
                        <p className="jilu" onClick={this.detail.bind(this, item)}>详情</p> 
                        <p  onClick={this.editFun.bind(this, item)}>编辑</p>
                            <Popconfirm
                                    title="删除新闻?"
                                    onConfirm={this.confirm.bind(this, item)}
                                    // onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                    >
                            <p className="red">
                                
                                删除
                                
                                </p>
                            </Popconfirm>
                        
                    </div>
                  )
            }
        },
      ]



  state = {
    herfEarth: '',
    pagination: {
        current: 1,
        pageSize: 10,
        total: 200,
        onShowSizeChange: function(current, pageSize) {
            this.state.pagination.pageSize = pageSize;
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        onChange: function(current) {
            this.state.pagination.current = current
            this.handleTableChange(this.state.pagination)
        }.bind(this),
        showTotal: function () {  //设置显示一共几条数据
            return '共 ' + this.state.pagination.total + ' 条'; 
        }.bind(this),
        showQuickJumper: true,
        },
    loading: false,

    showDatail: {},

    tableData: [
        
    ],
    TableParams: {
        title: "",
        lengthLimit: 10,
        offsetLimit: 0,
    },
    editorState: BraftEditor.createEditorState(null), // 创建一个空的editorState作为初始值
    title: '新闻',
    isModalVisible: false,
    requestParmas: {
        content: null, // 文本
        cover: null,  // 封面图  
        title: null,  // 标题  
        type: null, // 新闻类型
        weigh: null,
        addTime: null
    },
    isModalVisible2: false
  }



   componentDidMount() {
    this.getTableData()
  }

  confirm(item) {
    // console.log(item)

    this.onFinish(item, true)
  }

  detail(item) {
    this.setState({
        showDatail: item,
        isModalVisible2: true,
    })
  }


  getTableData(type) {
       if(type) {
           this.state.TableParams.offsetLimit = 0
           this.state.pagination.current = 1
       }
       this.setState({
           loading: true
       })  

      React.$axios.post('newsList', this.state.TableParams).then(res => {
        this.state.pagination.total = res.data.countList
        this.setState({
            tableData: res.data.list,
            pagination: this.state.pagination,
            loading: false
        })
      })
  }


  serchCar(name, {target:{value}}) {
    this.state.TableParams.title = value

  }

  onChangeWork(value) {
      console.log(value)
    this.state.TableParams.type = value
  }

  toSerch2() {
    this.getTableData(true)
  }

  handleTableChange(value) {
    this.state.pagination = value

    this.state.TableParams.offsetLimit = (value.current-1) * value.pageSize;
    this.state.TableParams.lengthLimit = value.pageSize;
    
    this.getTableData()
  }

  // 编辑
  handleEditorChange(val) {
    console.log(val)
  }

  submitContent() {

  }

  handleOk() {
    this.state.requestParmas.content = this.editorInstance.getValue().toHTML()
    this.FormSbmitRef.submit();
  }

  handleCancel() {
    this.setState({
        isModalVisible: false,
        isModalVisible2: false
    })
  }

  handleOk2() {
     this.handleCancel() 
  }


  onFinish(value, status) {
    for(let i in value)  {
        this.state.requestParmas[i] = value[i]
    }

    let params = JSON.parse(JSON.stringify(this.state.requestParmas));


   params.addTime = parseInt(new Date(params.addTime).getTime() / 1000);
   
    if(status) {
        params.del = 2;
    }

    React.$axios.post('editSaveNews', params).then(res => {
        if(res.code == 20000) {
            message.success('操作成功')
            this.getTableData()
            this.handleCancel()
        }
    })

  }





  editFun(item) {

   for(let i in this.state.requestParmas) {
    this.state.requestParmas[i] = item[i]
   }

   this.state.requestParmas.id = item.id;
   this.state.requestParmas.addTime = moment(new Date(this.state.requestParmas.addTime * 1000) , 'YYYY/MM/DD')
   this.setState({
    requestParmas: this.state.requestParmas,
    isModalVisible: true,
    editorState: BraftEditor.createEditorState( item.content)
   })

   this.clierFormSbmitRef()

  }

  handleClickUpload(res) {
    this.state.requestParmas.cover = res.data.key[0]
    try {
        this.state.requestParmas.content = this.editorInstance.getValue().toHTML()
    } catch(e) {

    }
    
  
    this.setState({
        requestParmas: this.state.requestParmas,
        editorState: BraftEditor.createEditorState(this.state.requestParmas.content)
    })
  }

  // 富文本上传图片
  myUploadFn(params) {

    BraftEditorIMGUpload(params, {type: 30}).then(res => {
        params.success({
            url: imgUrl + res.data.key[0],
            meta: {
                id: 'xxx',
                title: 'xxx',
                alt: 'xxx',
                loop: true, // 指定音视频是否循环播放
                autoPlay: true, // 指定音视频是否自动播放
                controls: true, // 指定音视频是否显示控制栏
              }
        })

    })
  }

  clierFormSbmitRef() {
    if(!this.FormSbmitRef) {
        setTimeout(() => {
          
            this.FormSbmitRef.resetFields()
        }, 500)
    } else {
        this.FormSbmitRef.resetFields()
    }
  }



  // 添加新闻
  addNews() {

    for(let i in this.state.requestParmas) {
        this.state.requestParmas[i] = null
    }

    delete this.state.requestParmas.id


    

    this.setState({
        requestParmas: this.state.requestParmas,
        isModalVisible: true,
        editorState: BraftEditor.createEditorState(null), 
    })


    this.clierFormSbmitRef()


    }



  render() {
    return (
      <div className="BehaviorManagement newsList">
          <div className="input">
                    <ul className={this.state.openStatus?'addOpen':'removeOpen'}>
                        <li><span>标题:</span> 
                        <Input onChange={this.serchCar.bind(this, 'keyword')}  style={{ width: 200, height:30, marginRight:20,  }} placeholder="模糊搜索" />
                        
                        </li>
                        
                        <li>
                            <span>类型:</span>
                            <Select
                                placeholder="请选择"
                                allowClear={true}
                                style={{ width: 200, height:30, marginRight:20,  }} 
                                onChange = {this.onChangeWork.bind(this)}    
                                >
                                <Option value={2} key='2'>普通新闻</Option>
                                <Option value={3} key='3'>党建新闻</Option>
                            </Select>
                        </li>
                        

                       

                            <Button  className="btn" onClick={this.toSerch2.bind(this)}>
                                <SearchOutlined  className="icon"/>
                            </Button>
                    </ul>

                    <div class="allBtn">
                   
  
                   {/* <Button  className="bt" type="primary" icon={<UploadOutlined />}>
                       导入
                   </Button>
                   <Button  className="bt" type="primary" icon={<UploadOutlined className="translate" />}>
                       导出
                   </Button> */}
                   <Button  className="bt" type="primary" onClick={this.addNews.bind(this)} icon={<PlusOutlined />}>
                       新建
                   </Button>
                   </div>
                </div>


                <div className="table2">
               


                    <Table  align="center" bordered rowClassName="editable-row" pagination={this.state.pagination}
                    loading={this.state.loading} rowKey={record => record.id} expandable={{ expandedRowRender: this.expandedRowRender }} columns={this.columns} dataSource={this.state.tableData}  />
                </div>


                <Modal title={this.state.showDatail.title} footer={null} className='newsListModal' width="60%"  visible={this.state.isModalVisible2} onOk={this.handleOk2.bind(this)} onCancel={this.handleCancel.bind(this)}>
                    <div className="setHeight" dangerouslySetInnerHTML={{__html: this.state.showDatail.content}}>
                            
                    </div>
                </Modal>


                <Modal title={this.state.title}  className='newsListModal' width="50%"  visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                {/* layout="vertical" */}
                    <div className="my-component setHeight">
                        <Form
                            
                            onFinish={this.onFinish.bind(this)}
                            ref={(el) => this.FormSbmitRef = el}
                            {...layout}
                            initialValues = {this.state.requestParmas}
                        >
                        <div className="allInput">
                                <Form.Item   label="标题" name="title"    rules={[{ required: true }]}>
                                        <Input   style={{ width: '60%', height:30, marginRight:20,  }}    placeholder="请输入新闻标题" />
                                    </Form.Item>

                                    <Form.Item label="类型" name="type" rules={[{ required: true }]}>
                                        <Select
                                            placeholder="请选择"
                                            allowClear={true}
                                            style={{ width: '60%', height:30, marginRight:20,  }}
                                            >
                                            <Option value={2} key='2'>普通新闻</Option>
                                            <Option value={3} key='3'>党建新闻</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item   label="权重" name="weigh" >
                                        <Input   style={{ width: '60%', height:30, marginRight:20,  }}    placeholder="请输入权重" />
                                </Form.Item>

                               <Form.Item   label="时间" name="addTime" >
                                        {/* <Input   style={{ width: '60%', height:30, marginRight:20,  }}    placeholder="请输入权重" /> */}

                                        <DatePicker />

                                </Form.Item>     

                        </div>
                            
                       

                            <div className="setImgStyle">

                            <Form.Item >
                                <div className="setIpload">
                                <h1>封面</h1>
                                <Uploadhh    imageUrl={ this.state.requestParmas.cover}   data={{type: 5}}  width={150} height={120} handleClick={this.handleClickUpload.bind(this)} />
                                </div>
                               
                            </Form.Item>

                            </div>
                            


                            <Form.Item   label="内容" >
                                <div className="setNews">
                                <BraftEditor
                                    ref={instance => this.editorInstance = instance}
                                    value={this.state.editorState}
                                    onChange={this.handleEditorChange}
                                    onSave={this.submitContent}
                                    media={{uploadFn: this.myUploadFn}}
                                />
                                </div>

                            </Form.Item>




                    </Form>  
                        
                    </div>

                </Modal>






        


      </div>
    );
  }
}
